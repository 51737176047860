import * as React from "react";
const DocumentImage = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path
      fill="#fff"
      stroke="#F4A540"
      d="M67.764 78.811h-55.53a2.875 2.875 0 0 1-2.875-2.875V3.956a2.875 2.875 0 0 1 2.875-2.875h40.893l17.512 17.324v57.531a2.875 2.875 0 0 1-2.875 2.875Z"
    />
    <path
      fill="#F4A540"
      d="M45.669 12.457h-29.02a1.235 1.235 0 1 1 0-2.47h29.02a1.235 1.235 0 0 1 0 2.47Zm-3.218 7.264a1.235 1.235 0 0 0-1.235-1.235H16.65a1.235 1.235 0 1 0 0 2.47h24.567a1.235 1.235 0 0 0 1.235-1.235Zm19.26 8.5a1.235 1.235 0 0 0-1.234-1.236H16.649a1.235 1.235 0 1 0 0 2.47h43.828a1.235 1.235 0 0 0 1.235-1.235Zm-2.75 8.498a1.235 1.235 0 0 0-1.235-1.235H16.65a1.235 1.235 0 0 0 0 2.47h41.078a1.235 1.235 0 0 0 1.234-1.235Zm-16.51 8.5a1.235 1.235 0 0 0-1.235-1.236H16.65a1.235 1.235 0 1 0 0 2.47h24.567a1.234 1.234 0 0 0 1.235-1.235Zm19.26 8.498a1.235 1.235 0 0 0-1.234-1.234H16.649a1.235 1.235 0 1 0 0 2.47h43.828a1.235 1.235 0 0 0 1.235-1.236Zm0 8.5a1.235 1.235 0 0 0-1.234-1.236H16.649a1.235 1.235 0 1 0 0 2.47h43.828a1.235 1.235 0 0 0 1.235-1.234Zm0 8.498a1.235 1.235 0 0 0-1.234-1.235H16.649a1.235 1.235 0 1 0 0 2.47h43.828a1.235 1.235 0 0 0 1.235-1.235ZM53.334.58l17.615 17.616H57.08a3.744 3.744 0 0 1-3.745-3.744V.58Z"
    />
  </svg>
);
export default DocumentImage;
