import {
  Select,
  MenuItem,
  Typography,
  Grid,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { Controller } from "react-hook-form";

const CustomSelect = ({
  options,
  optionLabel,
  optionValue,
  label,
  containerProps,
  control,
  name,
  placeholder,
  errors,
}) => {
  return (
    <Grid item {...containerProps}>
      <FormControl fullWidth={true} error={errors[name]}>
        <Typography gutterBottom variant="h6" fontWeight={500}>
          {label}
        </Typography>
        <Controller
          name={name}
          control={control}
          defaultValue={0}
          render={({ field }) => (
            <Select sx={{ height: 35 }} size="small" fullWidth {...field}>
              <MenuItem value={0}>
                <Typography variant="h5" disabled>
                  {placeholder}
                </Typography>
              </MenuItem>
              {options?.map((item, index) => {
                return (
                  <MenuItem value={item[optionValue]} key={index}>
                    <Typography variant="h5">{item[optionLabel]}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          )}
        />

        {errors[name] && (
          <FormHelperText sx={{ color: "secondary.main" }}>
            {errors[name]?.message}
          </FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
};

export default CustomSelect;
