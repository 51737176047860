import { Delete, Info } from "@mui/icons-material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const JobDetailsComponent = ({
  theme,
  isEditDetails,
  getValues,
  register,
  setValue,
  details,
  isScheduled,
  errors,
  moment,
  jobCount,
  MobileDatePicker,
  recurringLoading,
  handleShowDateTimeFields,
  Button,
  FormControlLabel,
  Checkbox,
  DateTimePickerTheme,
  handleSelectDateChange,
  MobileTimePicker,
  handleRemoveClickOne,
  dateFormat = "DD/MM/YYYY",
  timeFormat = "HH:mm",
}) => {
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  const { t } = useTranslation();

  return (
    <>
      {isScheduled &&
        (getValues("visits")?.[0]?.ScheduleId < 1 ||
          !getValues("visits")?.[0]?.ScheduleId) &&
        isScheduled && (
          <Grid item xs={12} container spacing={2}>
            <>
              <Grid
                container
                item
                xs={12}
                md={12}
                spacing={2}
                direction={"row"}
              >
                <Grid item xs={3} md={3}>
                  <Typography> {t("textFieldLables.every")}</Typography>
                </Grid>
                <Grid item xs={3} md={4}>
                  <TextField
                    size="small"
                    type="number"
                    name="everyValue"
                    {...register("everyValue")}
                    onChange={(e) => {
                      let numericValue = e.target.value
                        .replace(/[^\d.]/g, "") // Remove any characters that are not digits or a decimal point
                        .replace(/\.(\d*)\./g, ".$1") // Allow only up to two decimal places
                        .replace(/(\.\d\d)\d+$/, "$1") // Remove any additional decimal points;
                        .replace(/^0/, "1"); // Replace leading zeros with 1

                      setValue(`everyValue`, numericValue);
                    }}
                  />
                </Grid>
                <Grid item xs={3} md={4}>
                  <Select
                    size="small"
                    fullWidth
                    name="everyType"
                    value={
                      !!getValues("everyType") ? getValues("everyType") : "days"
                    }
                    onChange={(e) => setValue("everyType", e.target.value)}
                  >
                    <MenuItem value="days">
                      {t("textFieldLables.days")}
                    </MenuItem>
                    <MenuItem value="weeks">
                      {t("textFieldLables.weeks")}
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Grid container item md={12} />
              <Grid
                container
                item
                xs={12}
                md={12}
                spacing={2}
                direction={"row"}
              >
                <Grid item xs={3} md={3}>
                  <Typography>{t("textFieldLables.for")}</Typography>
                </Grid>
                <Grid item xs={3} md={4}>
                  <TextField
                    size="small"
                    type="number"
                    value={getValues("forValue")}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (
                        inputValue === "" ||
                        (/^\d+$/.test(inputValue) && parseInt(inputValue) >= 0)
                      ) {
                        setValue("forValue", inputValue);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={3} md={4}>
                  <Select
                    fullWidth
                    size="small"
                    value={
                      !!getValues("forType") ? getValues("forType") : "weeks"
                    }
                    onChange={(e) => setValue("forType", e.target.value)}
                  >
                    <MenuItem value="days">
                      {t("textFieldLables.days")}
                    </MenuItem>
                    <MenuItem value="weeks">
                      {t("textFieldLables.weeks")}
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Grid container item md={6} />
              <Grid container item xs={12} md={6} spacing={2} direction={"row"}>
                <Grid item xs={6} md={7} />
                <Grid item xs={3} md={3}>
                  <Button
                    variant="contained"
                    fullWidth
                    size="small"
                    sx={{
                      boxSizing: "border-box",
                      color: theme.palette.primary.main,
                      borderRadius: "24px",
                      backgroundColor: theme.palette.secondary.contrastText,
                      border: `1px solid ${theme.palette.primary.main}`,
                      "&:hover": {
                        backgroundColor: theme.palette.secondary.contrastText,
                      },
                    }}
                    disabled={
                      !getValues("everyValue") || !getValues("forValue")
                    }
                    onClick={handleShowDateTimeFields}
                  >
                    {recurringLoading ? "Recurring jobs loading..." : "Submit"}
                  </Button>
                </Grid>
              </Grid>
            </>
          </Grid>
        )}

      <Grid item xs={12}>
        <Typography
          color="textSecondary"
          gutterBottom
          variant="h4"
          sx={{ mb: 1, mt: isEditDetails ? 2 : 0 }}
          fontWeight={theme.typography.fontWeightBold}
        >
          {t("subHeadings.listOfJobs")}
        </Typography>
        <TableContainer
          component={Paper}
          sx={{
            minWidth: 350,
            maxHeight: isDownMd ? 300 : 175,
            // backgroundColor: "red",
          }}
        >
          {isDownSm ? (
            <>
              <Table stickyHeader aria-label="sticky table">
                <TableHead sx={{ minWidth: 650, height: 40 }}>
                  <TableRow>
                    <TableCell align="left">
                      {t("tableHeadings.startDate")}
                    </TableCell>
                    <TableCell align="left">
                      {t("tableHeadings.endDate")}
                    </TableCell>
                    {isEditDetails &&
                      !getValues("IsOneOff") &&
                      getValues("visits")?.some(
                        (item, index) => !item.IsDelete
                      ) && (
                        <TableCell align="left">
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                            fontWeight={theme.typography.fontWeightMedium}
                          >
                            {t("tableHeadings.organize")}
                          </Typography>
                        </TableCell>
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getValues("visits")?.map(
                    (item, index) =>
                      !item.IsDelete && (
                        <TableRow key={item.ScheduleId}>
                          <>
                            <TableCell
                              align="left"
                              sx={{
                                minWidth: 60,
                                maxWidth: 100,
                                height: 50,
                              }}
                            >
                              {isEditDetails ? (
                                <DateTimePickerTheme>
                                  <MobileDatePicker
                                    label={t(`labels.startDate`)}
                                    inputFormat={dateFormat}
                                    onChange={(e) => {
                                      if (jobCount > 1) {
                                        handleSelectDateChange(e?._d, index);
                                      }

                                      if (!e) {
                                        toast.info(
                                          t("toasts.startDateCannotBeEmpty")
                                        );
                                      } else {
                                        setValue(
                                          `visits.${index}.StartDate`,
                                          moment(e).format("YYYY-MM-DDTHH:mm")
                                        );
                                      }
                                    }}
                                    value={getValues(
                                      `visits.${index}.StartDate`
                                    )}
                                    componentsProps={{
                                      actionBar: {
                                        actions: ["clear", "cancel", "accept"],
                                      },
                                    }}
                                    minDate={moment(getValues(`CreatedDate`))}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        size="small"
                                        {...params}
                                        name={`visits.${index}.StartDate`}
                                        variant={
                                          isEditDetails
                                            ? "outlined"
                                            : "standard"
                                        }
                                        error={
                                          !!errors.visits?.[index]?.StartDate
                                        }
                                        helperText={
                                          errors.visits?.[index]?.StartDate
                                            ?.message
                                        }
                                        InputProps={{
                                          readOnly: !isEditDetails,
                                          disableUnderline: true,
                                          sx: {
                                            color:
                                              theme.palette.primary
                                                .contrastText,
                                            fontWeight:
                                              theme.typography.fontWeightMedium,
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                </DateTimePickerTheme>
                              ) : (
                                <TextField
                                  fullWidth
                                  size="small"
                                  variant={
                                    isEditDetails ? "outlined" : "standard"
                                  }
                                  placeholder={t("labels.editToAddDate")}
                                  error={!!errors.startDate}
                                  helperText={errors.startDate?.message}
                                  value={
                                    getValues(`visits.${index}.StartDate`)
                                      ? moment(
                                          getValues(`visits.${index}.StartDate`)
                                        ).format(dateFormat)
                                      : ""
                                  }
                                  sx={{
                                    pointerEvents: "none",
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                    inputProps: {
                                      sx: {
                                        textAlign: isEditDetails
                                          ? "left"
                                          : "left",
                                        color:
                                          theme.palette.primary.contrastText,
                                        fontWeight:
                                          theme.typography.fontWeightMedium,
                                      },
                                    },
                                    sx: {
                                      color: theme.palette.primary.contrastText,
                                      fontWeight:
                                        theme.typography.fontWeightMedium,
                                    },
                                  }}
                                />
                              )}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ minWidth: 60, maxWidth: 100, height: 50 }}
                            >
                              {isEditDetails ? (
                                <DateTimePickerTheme>
                                  <MobileDatePicker
                                    label={t(`labels.endDate`)}
                                    inputFormat={dateFormat}
                                    minDate={moment(
                                      getValues(`visits.${index}.StartDate`)
                                    )}
                                    onChange={(e) => {
                                      if (!e) {
                                        toast.info(
                                          t("toasts.endDateCannotBeEmpty")
                                        );
                                      } else {
                                        setValue(
                                          `visits.${index}.EndDate`,
                                          moment(e).format("YYYY-MM-DDTHH:mm")
                                        );
                                      }
                                    }}
                                    value={getValues(`visits.${index}.EndDate`)}
                                    componentsProps={{
                                      actionBar: {
                                        actions: ["clear", "cancel", "accept"],
                                      },
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        size="small"
                                        {...params}
                                        name={`visits.${index}.EndDate`}
                                        variant={
                                          isEditDetails
                                            ? "outlined"
                                            : "standard"
                                        }
                                        error={
                                          !!errors.visits?.[index]?.EndDate
                                        }
                                        helperText={
                                          errors.visits?.[index]?.EndDate
                                            ?.message
                                        }
                                        InputProps={{
                                          readOnly: !isEditDetails,
                                          disableUnderline: true,
                                          sx: {
                                            color:
                                              theme.palette.primary
                                                .contrastText,
                                            fontWeight:
                                              theme.typography.fontWeightMedium,
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                </DateTimePickerTheme>
                              ) : (
                                <TextField
                                  fullWidth
                                  size="small"
                                  variant={
                                    isEditDetails ? "outlined" : "standard"
                                  }
                                  placeholder={t("labels.editToAddDate")}
                                  error={!!errors.endDate}
                                  helperText={errors.endDate?.message}
                                  value={
                                    getValues(`visits.${index}.EndDate`)
                                      ? moment(
                                          getValues(`visits.${index}.EndDate`)
                                        ).format(dateFormat)
                                      : ""
                                  }
                                  sx={{
                                    pointerEvents: "none",
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                    inputProps: {
                                      sx: {
                                        textAlign: isEditDetails
                                          ? "left"
                                          : "left",
                                        color:
                                          theme.palette.primary.contrastText,
                                        fontWeight:
                                          theme.typography.fontWeightMedium,
                                      },
                                    },
                                    sx: {
                                      color: theme.palette.primary.contrastText,
                                      fontWeight:
                                        theme.typography.fontWeightMedium,
                                    },
                                  }}
                                />
                              )}
                            </TableCell>

                            {isEditDetails && !getValues("IsOneOff") && (
                              <TableCell align="left" key={index}>
                                {!item.IsDelete && (
                                  <>
                                    {index === 0 && jobCount > 1 ? (
                                      <>
                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                name="sameRange"
                                                checked={getValues("sameRange")}
                                                onChange={(e) =>
                                                  setValue(
                                                    "sameRange",
                                                    e.target.checked
                                                  )
                                                }
                                              />
                                            }
                                          />
                                          <Typography
                                            color="textSecondary"
                                            gutterBottom
                                            variant="h6"
                                            fontWeight={
                                              theme.typography.fontWeightMedium
                                            }
                                            ml={-3}
                                          >
                                            {t("labels.synchronized")}
                                          </Typography>
                                        </Stack>
                                      </>
                                    ) : (
                                      <>
                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                        >
                                          <IconButton
                                            aria-label="delete"
                                            onClick={() =>
                                              handleRemoveClickOne(index)
                                            }
                                            sx={{
                                              color:
                                                theme.palette.common.danger,
                                              marginLeft:
                                                jobCount !== 1 ? -1 : 1,
                                            }}
                                            disabled={jobCount === 1}
                                          >
                                            <Delete />
                                          </IconButton>
                                          {jobCount !== 1 && (
                                            <Typography
                                              color="textSecondary"
                                              gutterBottom
                                              variant="h6"
                                              fontWeight={
                                                theme.typography
                                                  .fontWeightMedium
                                              }
                                              ml={-1}
                                            >
                                              {t("labels.remove")}
                                            </Typography>
                                          )}
                                        </Stack>
                                      </>
                                    )}
                                  </>
                                )}
                              </TableCell>
                            )}
                          </>
                        </TableRow>
                      )
                  )}
                </TableBody>
              </Table>

              <Table stickyHeader aria-label="sticky table">
                <TableHead sx={{ minWidth: 650, height: 40 }}>
                  <TableRow>
                    <TableCell align="left">
                      {t("tableHeadings.startTime")}
                    </TableCell>
                    <TableCell align="left">
                      {t("tableHeadings.endTime")}
                    </TableCell>
                    {isEditDetails &&
                      !getValues("IsOneOff") &&
                      getValues("visits")?.some(
                        (item, index) => !item.IsDelete
                      ) && (
                        <TableCell align="left">
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                            fontWeight={theme.typography.fontWeightMedium}
                          >
                            {t("tableHeadings.organize")}
                          </Typography>
                        </TableCell>
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getValues("visits")?.map(
                    (item, index) =>
                      !item.IsDelete && (
                        <TableRow key={item.ScheduleId}>
                          <>
                            <TableCell
                              align="left"
                              sx={{ minWidth: 60, maxWidth: 100, height: 50 }}
                            >
                              {isEditDetails ? (
                                <DateTimePickerTheme>
                                  <MobileTimePicker
                                    label={t(`labels.startTime`)}
                                    inputFormat={timeFormat}
                                    onChange={(e) => {
                                      const startDate = moment(
                                        getValues(`visits.${index}.StartDate`)
                                      );
                                      const endDate = moment(
                                        getValues(`visits.${index}.EndDate`)
                                      );
                                      const endTime = !!getValues(
                                        `visits.${index}.EndTime`
                                      )
                                        ? moment(
                                            getValues(`visits.${index}.EndTime`)
                                          )
                                        : moment();
                                      const startTime =
                                        moment(e).format("YYYY-MM-DDTHH:mm");

                                      const sameDate = startDate.isSame(
                                        endDate,
                                        "day"
                                      );

                                      const startTimeBeforeEndTime = moment(
                                        startTime
                                      ).isSameOrBefore(endTime, "hour");
                                      if (!e) {
                                        toast.info(
                                          t("toasts.startTimeCanNotBeEmpty")
                                        );
                                      } else if (
                                        sameDate &&
                                        !startTimeBeforeEndTime
                                      ) {
                                      } else {
                                        setValue(
                                          `visits.${index}.StartTime`,
                                          moment(e).format("YYYY-MM-DDTHH:mm")
                                        );
                                      }
                                    }}
                                    ampm={false}
                                    value={getValues(
                                      `visits.${index}.StartTime`
                                    )}
                                    disabled={
                                      index != 0 && getValues("sameRange")
                                    }
                                    componentsProps={{
                                      actionBar: {
                                        actions: ["clear", "cancel", "accept"],
                                      },
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        size="small"
                                        {...params}
                                        name={`visits.${index}.StartTime`}
                                        variant={
                                          isEditDetails
                                            ? "outlined"
                                            : "standard"
                                        }
                                        error={
                                          !!errors.visits?.[index]?.StartTime
                                        }
                                        helperText={
                                          errors.visits?.[index]?.StartTime
                                            ?.message
                                        }
                                        InputProps={{
                                          readOnly: !isEditDetails,
                                          disableUnderline: true,
                                          sx: {
                                            color:
                                              theme.palette.primary
                                                .contrastText,
                                            fontWeight:
                                              theme.typography.fontWeightMedium,
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                </DateTimePickerTheme>
                              ) : (
                                <TextField
                                  fullWidth
                                  size="small"
                                  variant={
                                    isEditDetails ? "outlined" : "standard"
                                  }
                                  placeholder={t("labels.editToAddTime")}
                                  error={!!errors.startTime}
                                  helperText={errors.startTime?.message}
                                  value={
                                    getValues(`visits.${index}.StartTime`)
                                      ? moment(
                                          getValues(`visits.${index}.StartTime`)
                                        ).format(timeFormat)
                                      : ""
                                  }
                                  sx={{
                                    pointerEvents: "none",
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                    inputProps: {
                                      sx: {
                                        textAlign: isEditDetails
                                          ? "left"
                                          : "left",
                                        color:
                                          theme.palette.primary.contrastText,
                                        fontWeight:
                                          theme.typography.fontWeightMedium,
                                      },
                                    },
                                    sx: {
                                      color: theme.palette.primary.contrastText,
                                      fontWeight:
                                        theme.typography.fontWeightMedium,
                                    },
                                  }}
                                />
                              )}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                minWidth: 60,
                                maxWidth: 100,
                                height: 50,
                              }}
                            >
                              {isEditDetails ? (
                                <DateTimePickerTheme>
                                  <MobileTimePicker
                                    label={t(`labels.endTime`)}
                                    inputFormat={timeFormat}
                                    onChange={(e) => {
                                      const startDate = moment(
                                        getValues(`visits.${index}.StartDate`)
                                      );
                                      const endDate = moment(
                                        getValues(`visits.${index}.EndDate`)
                                      );
                                      const startTime = moment(
                                        getValues(`visits.${index}.StartTime`)
                                      );
                                      const endTime =
                                        moment(e).format("YYYY-MM-DDTHH:mm");

                                      const sameDate = startDate.isSame(
                                        endDate,
                                        "day"
                                      );
                                      const startTimeBeforeEndTime =
                                        startTime.isSameOrBefore(
                                          endTime,
                                          "hour"
                                        );

                                      if (!e) {
                                        toast.info(
                                          t("toasts.endTimeCanNotBeEmpty")
                                        );
                                      } else if (
                                        sameDate &&
                                        !startTimeBeforeEndTime
                                      ) {
                                      } else {
                                        setValue(
                                          `visits.${index}.EndTime`,
                                          moment(e).format("YYYY-MM-DDTHH:mm")
                                        );
                                      }
                                    }}
                                    ampm={false}
                                    value={getValues(`visits.${index}.EndTime`)}
                                    disabled={
                                      index != 0 && getValues("sameRange")
                                    }
                                    componentsProps={{
                                      actionBar: {
                                        actions: ["clear", "cancel", "accept"],
                                      },
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        size="small"
                                        {...params}
                                        name={`visits.${index}.EndTime`}
                                        variant={
                                          isEditDetails
                                            ? "outlined"
                                            : "standard"
                                        }
                                        error={
                                          !!errors.visits?.[index]?.EndTime
                                        }
                                        helperText={
                                          errors.visits?.[index]?.EndTime
                                            ?.message
                                        }
                                        InputProps={{
                                          readOnly: !isEditDetails,
                                          sx: {
                                            color:
                                              theme.palette.primary
                                                .contrastText,
                                            fontWeight:
                                              theme.typography.fontWeightMedium,
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                </DateTimePickerTheme>
                              ) : (
                                <TextField
                                  fullWidth
                                  size="small"
                                  variant={
                                    isEditDetails ? "outlined" : "standard"
                                  }
                                  placeholder={t("labels.editToAddTime")}
                                  error={!!errors.endTime}
                                  helperText={errors.endTime?.message}
                                  value={
                                    getValues(`visits.${index}.EndTime`)
                                      ? moment(
                                          getValues(`visits.${index}.EndTime`)
                                        ).format(timeFormat)
                                      : ""
                                  }
                                  sx={{
                                    pointerEvents: "none",
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                    inputProps: {
                                      sx: {
                                        textAlign: isEditDetails
                                          ? "left"
                                          : "left",
                                        color:
                                          theme.palette.primary.contrastText,
                                        fontWeight:
                                          theme.typography.fontWeightMedium,
                                      },
                                    },
                                    sx: {
                                      color: theme.palette.primary.contrastText,
                                      fontWeight:
                                        theme.typography.fontWeightMedium,
                                    },
                                  }}
                                />
                              )}
                            </TableCell>

                            {isEditDetails && !getValues("IsOneOff") && (
                              <TableCell align="right" key={index}>
                                {!item.IsDelete && (
                                  <>
                                    {index === 0 && jobCount > 1 ? (
                                      <>
                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                name="sameRange"
                                                checked={getValues("sameRange")}
                                                onChange={(e) =>
                                                  setValue(
                                                    "sameRange",
                                                    e.target.checked
                                                  )
                                                }
                                              />
                                            }
                                          />
                                          <Typography
                                            color="textSecondary"
                                            gutterBottom
                                            variant="h6"
                                            fontWeight={
                                              theme.typography.fontWeightMedium
                                            }
                                            ml={-3}
                                          >
                                            {t("labels.synchronized")}
                                          </Typography>
                                        </Stack>
                                      </>
                                    ) : (
                                      <>
                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                        >
                                          <IconButton
                                            aria-label="delete"
                                            onClick={() =>
                                              handleRemoveClickOne(index)
                                            }
                                            sx={{
                                              color:
                                                theme.palette.common.danger,
                                              marginLeft:
                                                jobCount !== 1 ? -1 : 1,
                                            }}
                                            disabled={jobCount === 1}
                                          >
                                            <Delete />
                                          </IconButton>
                                          {jobCount !== 1 && (
                                            <Typography
                                              color="textSecondary"
                                              gutterBottom
                                              variant="h6"
                                              fontWeight={
                                                theme.typography
                                                  .fontWeightMedium
                                              }
                                              ml={-1}
                                            >
                                              {t("labels.remove")}
                                            </Typography>
                                          )}
                                        </Stack>
                                      </>
                                    )}
                                  </>
                                )}
                              </TableCell>
                            )}
                          </>
                        </TableRow>
                      )
                  )}
                </TableBody>
              </Table>
            </>
          ) : (
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ minWidth: 650 }}>
                <TableRow>
                  <TableCell align="center">
                    {t("tableHeadings.startDate")}
                  </TableCell>
                  <TableCell align="center">
                    {t("tableHeadings.endDate")}
                  </TableCell>
                  <TableCell align="center">
                    {t("tableHeadings.startTime")}
                  </TableCell>
                  <TableCell align="center">
                    {t("tableHeadings.endTime")}
                  </TableCell>
                  {isEditDetails &&
                    !getValues("IsOneOff") &&
                    getValues("visits")?.some(
                      (item, index) => !item.IsDelete
                    ) && (
                      <TableCell align="center">
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h6"
                          fontWeight={theme.typography.fontWeightMedium}
                        >
                          {t("tableHeadings.organize")}
                        </Typography>
                      </TableCell>
                    )}
                </TableRow>
              </TableHead>
              <TableBody>
                {getValues("visits")?.map(
                  (item, index) =>
                    !item.IsDelete && (
                      <TableRow key={item.ScheduleId}>
                        <>
                          <TableCell
                            align="right"
                            sx={{ minWidth: 60, maxWidth: 100 }}
                          >
                            {isEditDetails ? (
                              <DateTimePickerTheme>
                                <MobileDatePicker
                                  label={t(`labels.startDate`)}
                                  inputFormat={dateFormat}
                                  onChange={(e) => {
                                    if (jobCount > 1) {
                                      handleSelectDateChange(e?._d, index);
                                    }

                                    if (!e) {
                                      toast.info(
                                        t("toasts.startDateCannotBeEmpty")
                                      );
                                    } else {
                                      setValue(
                                        `visits.${index}.StartDate`,
                                        moment(e).format("YYYY-MM-DDTHH:mm")
                                      );
                                    }
                                  }}
                                  value={getValues(`visits.${index}.StartDate`)}
                                  componentsProps={{
                                    actionBar: {
                                      actions: ["clear", "cancel", "accept"],
                                    },
                                  }}
                                  minDate={moment(getValues(`CreatedDate`))}
                                  renderInput={(params) => (
                                    <TextField
                                      fullWidth
                                      size="small"
                                      {...params}
                                      name={`visits.${index}.StartDate`}
                                      variant={
                                        isEditDetails ? "outlined" : "standard"
                                      }
                                      error={
                                        !!errors.visits?.[index]?.StartDate
                                      }
                                      helperText={
                                        errors.visits?.[index]?.StartDate
                                          ?.message
                                      }
                                      InputProps={{
                                        readOnly: !isEditDetails,
                                        disableUnderline: true,
                                        sx: {
                                          color:
                                            theme.palette.primary.contrastText,
                                          fontWeight:
                                            theme.typography.fontWeightMedium,
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </DateTimePickerTheme>
                            ) : (
                              <TextField
                                fullWidth
                                size="small"
                                variant={
                                  isEditDetails ? "outlined" : "standard"
                                }
                                placeholder={t("labels.editToAddDate")}
                                error={!!errors.startDate}
                                helperText={errors.startDate?.message}
                                value={
                                  getValues(`visits.${index}.StartDate`)
                                    ? moment(
                                        getValues(`visits.${index}.StartDate`)
                                      ).format(dateFormat)
                                    : ""
                                }
                                sx={{
                                  pointerEvents: "none",
                                }}
                                InputProps={{
                                  disableUnderline: true,
                                  inputProps: {
                                    sx: {
                                      textAlign: isEditDetails
                                        ? "right"
                                        : "center",
                                      color: theme.palette.primary.contrastText,
                                      fontWeight:
                                        theme.typography.fontWeightMedium,
                                    },
                                  },
                                  sx: {
                                    color: theme.palette.primary.contrastText,
                                    fontWeight:
                                      theme.typography.fontWeightMedium,
                                  },
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{ minWidth: 60, maxWidth: 100 }}
                          >
                            {isEditDetails ? (
                              <DateTimePickerTheme>
                                <MobileDatePicker
                                  label={t(`labels.endDate`)}
                                  inputFormat={dateFormat}
                                  minDate={moment(
                                    getValues(`visits.${index}.StartDate`)
                                  )}
                                  onChange={(e) => {
                                    if (!e) {
                                      toast.info(
                                        t("toasts.endDateCannotBeEmpty")
                                      );
                                    } else {
                                      setValue(
                                        `visits.${index}.EndDate`,
                                        moment(e).format("YYYY-MM-DDTHH:mm")
                                      );
                                    }
                                  }}
                                  value={getValues(`visits.${index}.EndDate`)}
                                  componentsProps={{
                                    actionBar: {
                                      actions: ["clear", "cancel", "accept"],
                                    },
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      fullWidth
                                      size="small"
                                      {...params}
                                      name={`visits.${index}.EndDate`}
                                      variant={
                                        isEditDetails ? "outlined" : "standard"
                                      }
                                      error={!!errors.visits?.[index]?.EndDate}
                                      helperText={
                                        errors.visits?.[index]?.EndDate?.message
                                      }
                                      InputProps={{
                                        readOnly: !isEditDetails,
                                        disableUnderline: true,
                                        sx: {
                                          color:
                                            theme.palette.primary.contrastText,
                                          fontWeight:
                                            theme.typography.fontWeightMedium,
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </DateTimePickerTheme>
                            ) : (
                              <TextField
                                fullWidth
                                size="small"
                                variant={
                                  isEditDetails ? "outlined" : "standard"
                                }
                                placeholder={t("labels.editToAddDate")}
                                error={!!errors.endDate}
                                helperText={errors.endDate?.message}
                                value={
                                  getValues(`visits.${index}.EndDate`)
                                    ? moment(
                                        getValues(`visits.${index}.EndDate`)
                                      ).format(dateFormat)
                                    : ""
                                }
                                sx={{
                                  pointerEvents: "none",
                                }}
                                InputProps={{
                                  disableUnderline: true,
                                  inputProps: {
                                    sx: {
                                      textAlign: isEditDetails
                                        ? "right"
                                        : "center",
                                      color: theme.palette.primary.contrastText,
                                      fontWeight:
                                        theme.typography.fontWeightMedium,
                                    },
                                  },
                                  sx: {
                                    color: theme.palette.primary.contrastText,
                                    fontWeight:
                                      theme.typography.fontWeightMedium,
                                  },
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{ minWidth: 60, maxWidth: 100 }}
                          >
                            {isEditDetails ? (
                              <DateTimePickerTheme>
                                <MobileTimePicker
                                  label={t(`labels.startTime`)}
                                  inputFormat={timeFormat}
                                  onChange={(e) => {
                                    const startDate = moment(
                                      getValues(`visits.${index}.StartDate`)
                                    );
                                    const endDate = moment(
                                      getValues(`visits.${index}.EndDate`)
                                    );
                                    const endTime = !!getValues(
                                      `visits.${index}.EndTime`
                                    )
                                      ? moment(
                                          getValues(`visits.${index}.EndTime`)
                                        )
                                      : moment();
                                    const startTime =
                                      moment(e).format("YYYY-MM-DDTHH:mm");

                                    const sameDate = startDate.isSame(
                                      endDate,
                                      "day"
                                    );

                                    const startTimeBeforeEndTime = moment(
                                      startTime
                                    ).isSameOrBefore(endTime, "hour");
                                    if (!e) {
                                      toast.info(
                                        t("toasts.startTimeCanNotBeEmpty")
                                      );
                                    } else if (
                                      sameDate &&
                                      !startTimeBeforeEndTime
                                    ) {
                                    } else {
                                      setValue(
                                        `visits.${index}.StartTime`,
                                        moment(e).format("YYYY-MM-DDTHH:mm")
                                      );
                                    }
                                  }}
                                  ampm={false}
                                  value={getValues(`visits.${index}.StartTime`)}
                                  disabled={
                                    index != 0 && getValues("sameRange")
                                  }
                                  componentsProps={{
                                    actionBar: {
                                      actions: ["clear", "cancel", "accept"],
                                    },
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      fullWidth
                                      size="small"
                                      {...params}
                                      name={`visits.${index}.StartTime`}
                                      variant={
                                        isEditDetails ? "outlined" : "standard"
                                      }
                                      error={
                                        !!errors.visits?.[index]?.StartTime
                                      }
                                      helperText={
                                        errors.visits?.[index]?.StartTime
                                          ?.message
                                      }
                                      InputProps={{
                                        readOnly: !isEditDetails,
                                        disableUnderline: true,
                                        sx: {
                                          color:
                                            theme.palette.primary.contrastText,
                                          fontWeight:
                                            theme.typography.fontWeightMedium,
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </DateTimePickerTheme>
                            ) : (
                              <TextField
                                fullWidth
                                size="small"
                                variant={
                                  isEditDetails ? "outlined" : "standard"
                                }
                                placeholder={t("labels.editToAddTime")}
                                error={!!errors.startTime}
                                helperText={errors.startTime?.message}
                                value={
                                  getValues(`visits.${index}.StartTime`)
                                    ? moment(
                                        getValues(`visits.${index}.StartTime`)
                                      ).format(timeFormat)
                                    : ""
                                }
                                sx={{
                                  pointerEvents: "none",
                                }}
                                InputProps={{
                                  disableUnderline: true,
                                  inputProps: {
                                    sx: {
                                      textAlign: isEditDetails
                                        ? "right"
                                        : "center",
                                      color: theme.palette.primary.contrastText,
                                      fontWeight:
                                        theme.typography.fontWeightMedium,
                                    },
                                  },
                                  sx: {
                                    color: theme.palette.primary.contrastText,
                                    fontWeight:
                                      theme.typography.fontWeightMedium,
                                  },
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              minWidth: 60,
                              maxWidth: 100,
                            }}
                          >
                            {isEditDetails ? (
                              <DateTimePickerTheme>
                                <MobileTimePicker
                                  label={t(`labels.endTime`)}
                                  inputFormat={timeFormat}
                                  onChange={(e) => {
                                    const startDate = moment(
                                      getValues(`visits.${index}.StartDate`)
                                    );
                                    const endDate = moment(
                                      getValues(`visits.${index}.EndDate`)
                                    );
                                    const startTime = moment(
                                      getValues(`visits.${index}.StartTime`)
                                    );
                                    const endTime =
                                      moment(e).format("YYYY-MM-DDTHH:mm");

                                    const sameDate = startDate.isSame(
                                      endDate,
                                      "day"
                                    );
                                    const startTimeBeforeEndTime =
                                      startTime.isSameOrBefore(endTime, "hour");

                                    if (!e) {
                                      toast.info(
                                        t("toasts.endTimeCanNotBeEmpty")
                                      );
                                    } else if (
                                      sameDate &&
                                      !startTimeBeforeEndTime
                                    ) {
                                    } else {
                                      setValue(
                                        `visits.${index}.EndTime`,
                                        moment(e).format("YYYY-MM-DDTHH:mm")
                                      );
                                    }
                                  }}
                                  ampm={false}
                                  value={getValues(`visits.${index}.EndTime`)}
                                  disabled={
                                    index != 0 && getValues("sameRange")
                                  }
                                  componentsProps={{
                                    actionBar: {
                                      actions: ["clear", "cancel", "accept"],
                                    },
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      fullWidth
                                      size="small"
                                      {...params}
                                      name={`visits.${index}.EndTime`}
                                      variant={
                                        isEditDetails ? "outlined" : "standard"
                                      }
                                      error={!!errors.visits?.[index]?.EndTime}
                                      helperText={
                                        errors.visits?.[index]?.EndTime?.message
                                      }
                                      InputProps={{
                                        readOnly: !isEditDetails,
                                        sx: {
                                          color:
                                            theme.palette.primary.contrastText,
                                          fontWeight:
                                            theme.typography.fontWeightMedium,
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </DateTimePickerTheme>
                            ) : (
                              <TextField
                                fullWidth
                                size="small"
                                variant={
                                  isEditDetails ? "outlined" : "standard"
                                }
                                placeholder={t("labels.editToAddTime")}
                                error={!!errors.endTime}
                                helperText={errors.endTime?.message}
                                value={
                                  getValues(`visits.${index}.EndTime`)
                                    ? moment(
                                        getValues(`visits.${index}.EndTime`)
                                      ).format(timeFormat)
                                    : ""
                                }
                                sx={{
                                  pointerEvents: "none",
                                }}
                                InputProps={{
                                  disableUnderline: true,
                                  inputProps: {
                                    sx: {
                                      textAlign: isEditDetails
                                        ? "right"
                                        : "center",
                                      color: theme.palette.primary.contrastText,
                                      fontWeight:
                                        theme.typography.fontWeightMedium,
                                    },
                                  },
                                  sx: {
                                    color: theme.palette.primary.contrastText,
                                    fontWeight:
                                      theme.typography.fontWeightMedium,
                                  },
                                }}
                              />
                            )}
                          </TableCell>

                          {isEditDetails && !getValues("IsOneOff") && (
                            <TableCell align="right" key={index}>
                              {!item.IsDelete && (
                                <>
                                  {index === 0 && jobCount > 1 ? (
                                    <>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                      >
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              name="sameRange"
                                              checked={getValues("sameRange")}
                                              onChange={(e) =>
                                                setValue(
                                                  "sameRange",
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          }
                                        />
                                        <Typography
                                          color="textSecondary"
                                          gutterBottom
                                          variant="h6"
                                          fontWeight={
                                            theme.typography.fontWeightMedium
                                          }
                                          ml={-3}
                                        >
                                          {t("labels.synchronized")}
                                        </Typography>
                                      </Stack>
                                    </>
                                  ) : (
                                    <>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                      >
                                        <IconButton
                                          aria-label="delete"
                                          onClick={() =>
                                            handleRemoveClickOne(index)
                                          }
                                          sx={{
                                            color: theme.palette.common.danger,
                                            marginLeft: jobCount !== 1 ? -1 : 1,
                                          }}
                                          disabled={jobCount === 1}
                                        >
                                          <Delete />
                                        </IconButton>
                                        {jobCount !== 1 && (
                                          <Typography
                                            color="textSecondary"
                                            gutterBottom
                                            variant="h6"
                                            fontWeight={
                                              theme.typography.fontWeightMedium
                                            }
                                            ml={-1}
                                          >
                                            {t("labels.remove")}
                                          </Typography>
                                        )}
                                      </Stack>
                                    </>
                                  )}
                                </>
                              )}
                            </TableCell>
                          )}
                        </>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Grid>
    </>
  );
};

export default JobDetailsComponent;
