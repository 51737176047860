const typography = {
  fontFamily: "'Poppins','Helvetica','Arial', sans-serif",
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontSize: "1.8rem",
    color: "theme.palette.primary.dark",
    fontWeight: 600,
    "@media (min-width:1536px)": {
      fontSize: "2rem",
    },
  },
  h2: {
    fontSize: "1.4rem",
    letterSpacing: "0px",
    fontWeight: 600,
    "@media (min-width:1536px)": {
      fontSize: "1.70rem",
    },
  },
  h3: {
    fontSize: "0.8rem",
    letterSpacing: "0px",
    fontWeight: 600,
    "@media (min-width:1536px)": {
      fontSize: "1.1rem",
    },
  },
  h4: {
    fontSize: "0.9rem",
    letterSpacing: "0px",
    fontWeight: 500,
    "@media (min-width:1536px)": {
      fontSize: "1rem",
    },
  },
  h5: {
    fontSize: "0.86rem",
    fontWeight: 400,
    "@media (min-width:1536px)": {
      fontSize: "0.90rem",
    },
  },
  h6: {
    fontSize: "0.75rem",
    fontWeight: 400,
    "@media (min-width:1536px)": {
      fontSize: "0.8rem",
    },
  },
  body1: {
    fontSize: "0.65rem",
    fontWeight: 200,
    "@media (min-width:1536px)": {
      fontSize: "0.8rem",
    },
  },
  subtitle1: {
    fontSize: "0.8rem",
    color: "theme.palette.secondary.dark",
    "@media (min-width:1536px)": {
      fontSize: "1.1rem",
    },
  },
  subtitle2: {
    fontSize: "0.4rem",
    color: "theme.palette.primary.dark",
    fontWeight: 500,
    opacity: 0.3,
    "@media (min-width:1536px)": {
      fontSize: "0.7rem",
    },
  },
  button: {
    fontSize: "0.9rem",
    fontWeight: 500,
    "@media (min-width:1536px)": {
      fontSize: "1rem",
    },
  },
};

export default typography;
