import React from 'react'
import {
    Skeleton,
    Card,
    Container,
    Grid
} from '@mui/material'


const RequestSkeletonLoader = () => {
    return(
        <Container>
            <Card>
                <Grid container spacing={2} alignItems="center" justify="center" p={2}>
                    <Grid xs={12} item >
                        <Skeleton variant="rectangular" height={40} />
                    </Grid>
                    <Grid xs={6} item >
                        <Skeleton variant="rectangular" height={80} />
                    </Grid>
                    <Grid xs={6} item >
                        <Skeleton variant="rectangular" height={80} />
                    </Grid>
                    <Grid xs={12} item >
                        <Skeleton variant="text" />
                        <Skeleton variant="rectangular" height={40} />
                    </Grid>
                    <Grid xs={12} item >
                        <Skeleton variant="text" />
                        <Skeleton variant="rectangular" height={100} />
                    </Grid>
                    <Grid xs={12} item >
                        <Skeleton variant="text" />
                        <Skeleton variant="rectangular" height={40} />
                    </Grid>
                    <Grid xs={12} item >
                        <Skeleton variant="text" />
                        <Skeleton variant="text" width={'25%'} />
                        <Skeleton variant="text" width={'25%'} />
                        <Skeleton variant="text" width={'25%'} />
                        <Skeleton variant="text" width={'25%'} />
                    </Grid>
                    <Grid xs={12} item >
                        <Skeleton variant="text" />
                        <Skeleton variant="rectangular" height={100} />
                    </Grid>
                    <Grid xs={12} item >
                        <Skeleton variant="rectangular" height={100} />
                    </Grid>
                </Grid>
            </Card>
        </Container>
    )
}
export default RequestSkeletonLoader