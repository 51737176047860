import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllProducts } from "src/api/productApi";

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async ({ token }, { rejectWithValue }) => {
    return getAllProducts(token, { Activation: true })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

const initialState = {
  isLoading: false,
  statusCode: null,
  errorMessage: null,
  products: [],
};

const Slice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.statusCode = action.payload.STATUS_CODE;
        state.errorMessage = action.payload.ERROR_MESSAGE;
        state.products = action.payload.DATA;
        state.isLoading = false;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.statusCode = action.payload.STATUS_CODE;
        state.errorMessage = action.payload.ERROR_MESSAGE;
        state.isLoading = false;
        throw action.payload;
      });
  },
});

export default Slice.reducer;
