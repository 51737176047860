import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllInvoices, getInvoiceById } from "src/api/invoiceApi";

export const fetchInvoices = createAsyncThunk(
  "invoices/fetchInvoices",
  async ({ token }, { rejectWithValue }) => {
    return getAllInvoices(token, {})
      .then((data) => {
        return data.DATA;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const fetchInvoiceById = createAsyncThunk(
  "invoices/fetchInvoiceById",
  async ({ token, clientId, invoiceId }, { rejectWithValue }) => {
    return getInvoiceById(token, clientId, invoiceId)
      .then((data) => {
        return data.DATA;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

const initialState = {
  isLoading: false,
  error: null,
  data: [],
  invoice: null,
  filters: {
    SortBy: "",
    Filter: "",
    Parameter: "",
    Due: "",
    Start: "",
    End: "",
  },
  invoiceLoading: false,
  innvoice: null,
};

const Slice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    filtersToolBar(state, action) {
      state.filters.SortBy = action.payload.SortBy;
      state.filters.Filter = action.payload.Filter;
      state.filters.Parameter = action.payload.Parameter;
      state.filters.Due = action.payload.Due;
      state.filters.End = action.payload.End;
      state.filters.Start = action.payload.Start;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoices.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchInvoices.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchInvoices.rejected, (state, action) => {
        state.error = action.payload.STATUS_CODE;
        state.isLoading = false;
        throw action.payload;
      })
      .addCase(fetchInvoiceById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchInvoiceById.fulfilled, (state, action) => {
        state.invoice = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchInvoiceById.rejected, (state, action) => {
        state.error = action.payload.STATUS_CODE;
        state.isLoading = false;
        throw action.payload;
      });
  },
});

export const { filtersToolBar } = Slice.actions;

export default Slice.reducer;
