import { useSelector } from "react-redux";

export default class AuthService {
  isAuthenticated = () => {
    const token = useSelector((state) => state.auth.userData?.Token);
    return !!token;
  };

  isTokenAuthorized = () => {
    const isAuthorized = useSelector((state) => state.auth.isAuthorized);
    return isAuthorized;
  };

  getAuthToken = () => {
    const token = useSelector((state) => state.auth.userData?.Token);
    if (!!token) {
      return token;
    } else {
      return null;
    }
  };

  getProfile = () => {
    const profile = useSelector((state) => state.auth.userData);

    if (profile?.ClientId && profile?.UserId) {
      return {
        ...profile,
      };
    } else {
      return null;
    }
  };
}
