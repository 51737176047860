import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllWeekTimeEntries,
  postWeekTimeEntry,
} from "src/api/WeekTimeEntryApi";

export const fetchWeekEntries = createAsyncThunk(
  "weekTimeEntry/fetchAllWeekEntries",
  async ({ token, filters }, { rejectWithValue }) => {
    return getAllWeekTimeEntries(token, filters)
      .then((data) => {
        return data?.DATA;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const createWeekEntries = createAsyncThunk(
  "weekTimeEntry/createWeekTimeEntry",
  async ({ token, body }, { rejectWithValue }) => {
    return postWeekTimeEntry(token, body)
      .then((data) => {
        return data?.DATA;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

const initialState = {
  isLoading: false,
  error: null,
  weekEntries: [],
};

const Slice = createSlice({
  name: "weekEntry",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeekEntries.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchWeekEntries.fulfilled, (state, action) => {
        state.weekEntries = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchWeekEntries.rejected, (state, action) => {
        state.error = action.payload.STATUS_CODE;
        state.isLoading = false;
        throw action.payload;
      })
      .addCase(createWeekEntries.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createWeekEntries.fulfilled, (state, action) => {
        action.meta.arg.isNew = true;
        state.weekEntries.push(action.meta.arg);
        state.isLoading = false;
      })
      .addCase(createWeekEntries.rejected, (state, action) => {
        state.error = action.payload.STATUS_CODE;
        state.isLoading = false;
        throw action.payload;
      });
  },
});

export default Slice.reducer;
