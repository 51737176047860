import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getAddress = async (token, customerId, filters) => {
  const url = new URL(`${baseUrl}/Address`);

  url.searchParams.append("CustomerId", customerId);

  for (const [key, value] of Object.entries(filters)) {
    if (value !== null && value !== undefined && value !== "") {
      url.searchParams.append(key, value);
    }
  }

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getAddressById = async (token, AddressId) => {
  const url = new URL(`${baseUrl}/Address/id?AddressId=${AddressId}`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postAddress = async (token, body) => {
  const url = new URL(`${baseUrl}/Address`);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putAddress = async (token, addressId, body) => {
  const url = new URL(`${baseUrl}/Address?AddressId=${addressId}`);

  let options = {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
