import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getAllSchedules = async (token, filters) => {
  const url = new URL(`${baseUrl}/Schedule`);

  for (const [key, value] of Object.entries(filters)) {
    if (value !== null && value !== undefined && value !== "") {
      url.searchParams.append(key, value);
    }
  }

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getScheduleById = async (token, scheduleId) => {
  const url = new URL(`${baseUrl}/Schedule/id?ScheduleId=${scheduleId}`);

  // url.searchParams.append("ClientId", clientId);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postSchedule = async (token, body) => {
  const url = new URL(`${baseUrl}/Schedule`);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putSchedule = async (token, scheduleId, body) => {
  const url = new URL(`${baseUrl}/Schedule/id?ScheduleId=${scheduleId}`);

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const deleteSchedule = async (token, id, updatedDate) => {
  const url = new URL(`${baseUrl}/Schedule/id?id=${id}`);

  let options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ updatedDate }),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getJobScheduleDetails = async (token, jobId) => {
  const url = new URL(`${baseUrl}/Schedule/total?id=${jobId}`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
