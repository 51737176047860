import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: { sm: 3, xs: 1 } }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CustomTab({
  tabs = [],
  panelComponents = [],
  // tab,
  tabUrls = null,
  parentPath = "",
  childPath = "",
  ...rest
}) {
  const theme = useTheme();
  const { tab } = useParams();
  const [value, setValue] = React.useState(
    !!tabUrls ? tabUrls.indexOf(tab) : 0
  );
  const location = useLocation();
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!!tabUrls) {
      setValue(tabUrls.indexOf(tab));
    }
  }, [tab]);

  useEffect(() => {
    if (!!tabUrls && !!childPath) {
      navigate(`/${parentPath}/${tabUrls[value]}/${childPath}`, {
        replace: true,
      });
    } else if (!!tabUrls) {
      navigate(`/${parentPath}/${tabUrls[value]}`, {
        replace: true,
      });
    }
  }, [value]);

  const widthUpSm = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box sx={{ mb: 2 }}>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant={widthUpSm ? "fullWidth" : "fullWidth"} //scrollable"}
          scrollButtons={true}
          TabIndicatorProps={{
            sx: { display: "none" },
          }}
          // sx={{ ...rest }}
          {...rest}
          // orientation="vertical"
          // style={{height: "32px"}}
        >
          {tabs?.map((tab, index) => (
            // <Link to={`/${parentPath}/${tabUrls[index]}`}>
            <Tab
              key={index}
              label={
                <Typography
                  component="span"
                  variant="h5"
                  sx={{ fontWeight: theme.typography.fontWeightBold }}
                >
                  {tab}
                </Typography>
              }
              {...a11yProps(index)}
              sx={{
                backdropFilter: "blur(25px)",
                color:
                  index == value
                    ? "theme.palette.text.secondary, !important"
                    : theme.palette.input.dark,
                boxShadow: 2,
                // width: widthUpSm ? "100%" : "max-content",
                background:
                  index == value
                    ? "linear-gradient(86.91deg,theme.palette.shadow.contrastText 0%,theme.palette.primary.main 100%)"
                    : "linear-gradient(36.42deg, theme.palette.secondary.light 0%, theme.palette.common.black 100%)",
                border: "1px solid #CACACA",
              }}
            />
            // </Link>
          ))}
        </Tabs>
      </Box>
      {panelComponents?.map((panel, index) => (
        <TabPanel
          value={panelComponents.length > 1 ? value : 0}
          index={index}
          key={index}
        >
          {panel}
        </TabPanel>
      ))}
    </Box>
  );
}
