import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getAllTermsAndCons = async (token) => {
  const url = new URL(`${baseUrl}/TermsAndConditions`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getTermsAndConById = async (token, clientId, termsId) => {
  const url = new URL(`${baseUrl}/TermsAndConditions/id?TermsId=${termsId}`);

  url.searchParams.append("ClientId", clientId);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postTermsAndCon = async (token, clientId, body) => {
  const url = new URL(`${baseUrl}/TermsAndConditions`);

  url.searchParams.append("ClientId", clientId);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putTermsAndCon = async (token, clientId, termsId, body) => {
  const url = new URL(`${baseUrl}/TermsAndConditions?TermsId=${termsId}`);

  url.searchParams.append("ClientId", clientId);

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};
