import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Fragment, useRef } from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
import Logo from "src/assets/logo.png";
import RestrictedComponents from "./Permission/RestrictedComponents";

const IconContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

// necessary for content to be below app bar
const ToolbarContainer = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

const MenuLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary.contrastText,
}));

function MobileViewDrawer(props) {
  const { menuItems, open, selectedItem, setSelectedItem, onClose } = props;

  const theme = useTheme();

  const links = useRef([]);

  const toggleDrawer = (event) => {
    if (event.key === "Tab" || event.key === "Shift") {
      return;
    }
    onClose();
  };

  const location = useLocation();

  const match = (path) =>
    path ? !!matchPath({ path, end: false }, location.pathname) : false;

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <ToolbarContainer>
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ cursor: "pointer", mt: 1 }}
        >
          <>
            <IconContainer>
              <img src={Logo} width={"60%"} height="auto%" alt="Logo" />
            </IconContainer>
          </>
        </Box>
      </ToolbarContainer>
      <List
        sx={{
          paddingTop: 16,
          padding: 0,
          overflowY: "auto",
          maxHeight: "calc(100vh - 64px)",
        }}
      >
        {menuItems?.map((element, index) => (
          <RestrictedComponents
            permission={element.permission}
            key={element.link}
          >
            <MenuLink
              to={element.link}
              onClick={element.onClick}
              key={index}
              ref={(node) => {
                links.current[index] = node;
                return node;
              }}
            >
              <Tooltip
                title={element.displayName}
                placement="right"
                key={element.name}
              >
                <ListItemButton
                  selected={match(element.name.toLowerCase())}
                  onClick={() => {
                    links.current[index].click();
                    setSelectedItem(element.name);
                  }}
                  aria-label={`Go to ${element.name}`}
                  sx={{
                    paddingTop: theme.spacing(2),
                    paddingBottom: theme.spacing(2),
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: match(element.name.toLowerCase())
                        ? theme.palette.transparent.contrastText
                        : theme.palette.text.secondary,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {element.icon}
                  </ListItemIcon>

                  <ListItemText
                    sx={{
                      color: `${
                        match(element.name.toLowerCase())
                          ? theme.palette.transparent.contrastText
                          : theme.palette.text.secondary
                      } !important`,
                      paddingLeft: theme.spacing(1),
                    }}
                  >
                    <Typography variant="h4">{element.displayName}</Typography>
                  </ListItemText>
                </ListItemButton>
              </Tooltip>
            </MenuLink>
          </RestrictedComponents>
        ))}
      </List>
    </Box>
  );

  return (
    <Fragment>
      <Drawer
        anchor={"left"}
        open={open}
        onClose={toggleDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            bgcolor: "transparent",
          },
        }}
      >
        {list()}
      </Drawer>
    </Fragment>
  );
}

export default MobileViewDrawer;
