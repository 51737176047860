import {
  Backdrop,
  Grid,
  Stack,
  useMediaQuery,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  Button,
  MenuItem,
  Menu,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { ArrowBack } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getStatusColor,
  getStatusTextColor,
} from "src/components/Common/TabTable/StatusColor";
import MenuIcon from "@mui/icons-material/Menu";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "src/auth/AuthProvider";

// Define your filteredMenuItems based on your requirements

const Header = (props) => {
  const {
    title,
    handletypeChange,
    TypeStatus,
    getValues,
    StyledMenu,
    menuItems,
    filteredMenuItems,
    DeleteConfirmation,
    isEditDetails,
    disabled,
    showButtons,
    isRequestPage,
    openMenu1,
    handleClickMenu1,
    handleCloseMenu1,
    anchorElMenu1,
    setShowButtons,
    viewButton,
    handleClick,
    handleClose,
    anchorEl,
    open,
    conditionalStatus,
    toggleBtn,
  } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("lg"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const profile = useContext(AuthContext).getProfile();

  return (
    <>
      <Grid item container xs={12} lg={12}>
        <Grid item xs={8} md={8} lg={6}>
          <Stack
            direction={"row"}
            justifyContent="flex-start"
            alignItems="center"
            sx={{
              flexWrap: "nowrap",
              marginLeft: { xs: "-10px", md: "-5px" },
            }}
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              sx={{ mr: "1rem" }}
            >
              <IconButton
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.secondary.light,
                  borderRadius: "25%",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
                onClick={() => navigate(-1)}
              >
                <ArrowBack />
              </IconButton>
            </Box>
            <Typography
              variant="h2"
              align="left"
              display="inline"
              style={{
                wordWrap: "break-word",
                marginLeft: "5px",
              }}
            >
              {t(`headings.${title}`)}
            </Typography>

            {!isDownMd && (
              <div>
                <Button
                  disabled={profile.RoleId != 1}
                  id="demo-positioned-button"
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "20px",
                    width: "200px",
                    height: "40px",
                    borderRadius: "12px",
                    boxSizing: "border-box",
                    color: getStatusTextColor(
                      TypeStatus != null ? TypeStatus : getValues("Type"),
                      theme
                    ),
                    backgroundColor:
                      getStatusColor(
                        TypeStatus != null ? TypeStatus : getValues("Type"),
                        theme
                      ) || theme.palette.input.background,

                    cursor: "pointer", // Add this to make it appear clickable
                  }}
                >
                  <Typography
                    variant="h4"
                    display="inline"
                    sx={{
                      wordWrap: "break-word",
                    }}
                  >
                    {TypeStatus != null ? TypeStatus : getValues("Type")}
                  </Typography>
                </Button>
                {conditionalStatus.map(
                  ({ isValid }, index) =>
                    isValid && (
                      <StyledMenu
                        key={index}
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                      >
                        {menuItems?.map(
                          (menuItem, index) =>
                            menuItem.name !== getValues("Type") && (
                              <MenuItem key={index} onClick={handletypeChange}>
                                {menuItem.name}
                              </MenuItem>
                            )
                        )}
                      </StyledMenu>
                    )
                )}
              </div>
            )}
          </Stack>
        </Grid>

        {!isEditDetails && (
          <Grid
            item
            xs={4}
            md={4}
            lg={6}
            sx={{
              position:
                isLargeScreen && isRequestPage == true ? "relative" : "fixed",

              right: isLargeScreen ? 40 : 0,
              width: isLargeScreen ? 500 : 0,
            }}
          >
            {toggleBtn.map(({ fn, isShow }, index) =>
              isShow ? (
                <Grid item xs={12} sx={{ marginTop: "5px" }}>
                  <Stack
                    direction="row"
                    alignItems="flex-end"
                    justifyContent="flex-end"
                    spacing={2}
                  >
                    <Button
                      sx={{
                        width: "50px",
                        height: "30px",
                        borderRadius: "4px",
                        marginTop: "10px",
                      }}
                      onClick={fn}
                    >
                      <MenuIcon />
                    </Button>
                  </Stack>
                </Grid>
              ) : null
            )}
            {isDownMd && showButtons ? (
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Dialog
                  open={showButtons}
                  onClose={() => setShowButtons(false)}
                  maxWidth="sm"
                  fullWidth
                  sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                >
                  <DialogTitle>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="h3"
                      fontWeight="medium"
                    >
                      {t("messages.whatDoYouWantToDo")}
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      alignItems="center"
                      justifyContent="center"
                      spacing={3}
                    >
                      {viewButton.map(
                        ({ label, fn, icon, width, isShown }, index) =>
                          isShown && (
                            <Button
                              key={index}
                              variant="contained"
                              color="primary"
                              fullWidth
                              sx={{
                                width: !width ? "130px" : width,
                                height: "40px",
                                borderRadius: "4px",
                                marginLeft: { xs: "10px", sm: "10px" },
                                marginTop: { xs: "10px", sm: "10px" },
                              }}
                              onClick={() => {
                                fn();
                                setShowButtons(false);
                              }}
                              startIcon={icon}
                              disabled={disabled}
                            >
                              {label}
                            </Button>
                          )
                      )}
                    </Stack>
                  </DialogContent>
                  <DialogActions
                    sx={{
                      justifyContent: "center", // Center the buttons
                    }}
                  >
                    <Button
                      variant="contained"
                      type="button"
                      sx={{
                        boxSizing: "border-box",
                        marginLeft: "10px",
                        color: theme.palette.primary.main,

                        width: "130px",
                        height: "40px",
                        borderRadius: "4px",
                        backgroundColor: theme.palette.secondary.contrastText,
                        border: `1px solid ${theme.palette.primary.main}`,
                        "&:hover": {
                          backgroundColor: theme.palette.secondary.contrastText,
                        },
                      }}
                      onClick={() => setShowButtons(false)}
                    >
                      {t("buttons.cancel")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            ) : !isDownMd ? (
              <Stack
                direction={"row"}
                alignItems="flex-end"
                justifyContent="flex-end"
              >
                {viewButton.map(
                  ({ label, fn, icon, width, isShown }, index) =>
                    isShown && (
                      <Button
                        key={index}
                        variant="contained"
                        color="primary"
                        // fullWidth
                        sx={{
                          width: icon ? "auto" : !width ? "145px" : width,
                          height: "40px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px",
                          marginLeft: { xs: "10px", sm: "10px" },
                          "@media (max-width: 1320px) and (max-height: 950px)":
                            {
                              width: "auto",
                            },
                        }}
                        onClick={fn}
                        // startIcon={icon}
                        disabled={disabled}
                      >
                        {icon && (
                          <span
                            style={{
                              alignItems: "center",
                              marginTop: "10px",
                              marginBottom: "5px",
                            }}
                          >
                            {icon}
                          </span>
                        )}{" "}
                        {/* Add icon if present */}
                        {label}{" "}
                      </Button>
                    )
                )}
              </Stack>
            ) : null}
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        {isDownMd && (
          <div style={{ width: "100%", marginLeft: "-10px" }}>
            <Button
              id="demo-positioned-button"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              style={{
                marginLeft: "10px",
                width: "100%",
                height: "40px",
                paddingRight: "0px !important",
                borderRadius: "12px",
                boxSizing: "border-box",
                color: getStatusTextColor(
                  TypeStatus != null ? TypeStatus : getValues("Type"),
                  theme
                ),
                backgroundColor:
                  getStatusColor(
                    TypeStatus != null ? TypeStatus : getValues("Type"),
                    theme
                  ) || theme.palette.input.background,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer", // Add this to make it appear clickable
              }}
            >
              <Typography
                variant="h4"
                display="inline"
                sx={{
                  wordWrap: "break-word",
                }}
              >
                {TypeStatus != null ? TypeStatus : getValues("Type")}
              </Typography>
            </Button>
            {conditionalStatus.map(
              ({ isValid }, index) =>
                isValid && (
                  <StyledMenu
                    key={index}
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    {menuItems?.map(
                      (menuItem, index) =>
                        menuItem.name !== getValues("Type") && (
                          <MenuItem key={index} onClick={handletypeChange}>
                            {menuItem.name}
                          </MenuItem>
                        )
                    )}
                  </StyledMenu>
                )
            )}
          </div>
        )}
      </Grid>

      <Grid item xs={12} spacing={2} mt={2}>
        {isLargeScreen &&
          Array.isArray(filteredMenuItems) &&
          filteredMenuItems.length > 0 && (
            <>
              <Menu
                anchorEl={anchorElMenu1}
                open={openMenu1}
                onClose={handleCloseMenu1}
              >
                {filteredMenuItems.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      item.fn && item.fn();
                      handleCloseMenu1();
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* <Button
                    variant="text"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "flex-start",
                      textAlign: "left",
                      padding: 0.5,
                    }}
                  > */}
                    <span style={{ marginRight: "8px" }}>{item.icon}</span>{" "}
                    {item.label}
                    {/* </Button> */}
                  </MenuItem>
                ))}
              </Menu>{" "}
            </>
          )}
      </Grid>
    </>
  );
};

export default Header;
