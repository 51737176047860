import { Grid, Typography, Stack, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Countdown from "src/components/Common/TrialCountdown/Countdown";

const NavTrialCountdownTimer = ({ AccountCreatedDate }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [days, hours] = Countdown(AccountCreatedDate);

  return (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <Box
        sx={{
          backgroundColor: theme.palette.secondary.contrastText,
          border: `2px solid ${theme.palette.primary.main}`,
          borderRadius: 2,
          m: 0.5,
          p: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textAlign: "center",
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.primary.main,
          }}
        >
          {t("labels.navFreeTrialSetToExpire")} {days}
          {t("labels.navFreeTrialSetToExpire02")} {hours}
          {t("labels.navFreeTrialSetToExpire03")}
        </Typography>
      </Box>
    </Stack>
  );
};

export default NavTrialCountdownTimer;
