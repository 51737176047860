import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import { Buffer } from "buffer";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { postFiles } from "src/api/fileUploadApi";
import DocumentImage from "./File.js";
import moment from "moment/moment.js";
import { toast } from "react-toastify";
import {
  CloudUpload,
  Delete,
  Download,
  Visibility,
  Info,
  BrokenImage,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

const FileUploadDialog = ({
  Directory,
  FieldId,
  FieldTypeId,
  FieldName,
  token,
  files,
  setIsUpdated,
  open,
  onClose,
  setValue,
}) => {
  const theme = useTheme();
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setAcceptedFiles(files);
  }, [open]);

  const onDrop = (files) => {
    var newFiles = files.map((file) => ({
      ContentType: file.type,
      FileName: file.name,
      Size: file.size,
      File: file,
      IsThumbnail: false,
    }));

    if (FieldName === "Inventory") {
      if (acceptedFiles?.length < 4) {
        setAcceptedFiles((prevFiles) => [...newFiles, ...prevFiles]);
      } else {
        toast.info(t("inventory.toasts.maxImageCountReached"));
      }
    } else {
      setAcceptedFiles((prevFiles) => [...newFiles, ...prevFiles]);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    accept: acceptFileTypes(),
  });

  // Function to determine accepted file types conditionally
  function acceptFileTypes() {
    if (FieldName === "Inventory") {
      return {
        "image/jpeg": [".jpeg", ".png"],
      };
    } else {
      return ""; // Return empty string to accept all file types
    }
  }

  const handleSubmit = () => {
    setLoading(true);

    if (acceptedFiles.length == 0) {
      if (FieldName === "Inventory") {
        setValue("ProductImages", acceptedFiles);
        setIsUpdated((value) => value + 1);
      } else {
        toast.info(t("toasts.emptyFileLength"));
      }

      setLoading(false);
      onClose();
      return;
    }
    const formData = new FormData();

    acceptedFiles.forEach((file, index) => {
      formData.append(`Files[${index}].File`, file.File);
      formData.append(`Files[${index}].FileName`, file.FileName);
      formData.append(`Files[${index}].Size`, file.Size);
      formData.append(`Files[${index}].ContentType`, file.ContentType);
      if (!!file.FileId) {
        formData.append(`Files[${index}].FileId`, file.FileId);
        formData.append(`Files[${index}].IsDelete`, file.IsDelete);
      }
    });
    formData.append("Date", moment().format("YYYY-MM-DDTHH:mm"));
    formData.append("FieldId", FieldId);
    formData.append("FieldTypeId", FieldTypeId);
    formData.append("FieldName", FieldName);
    formData.append("Directory", Directory);

    if (FieldName === "Inventory") {
      setValue("ProductImages", acceptedFiles);
      setLoading(false);
      toast.success(t("toasts.saveDocumentSuccess"));
      onClose();
      setIsUpdated((value) => value + 1);
    } else {
      postFiles(token, formData)
        .then((response) => {
          if (response?.DATA?.FolderId != null) {
            setLoading(false);
            toast.success(t("toasts.saveDocumentSuccess"));
            onClose();
            setIsUpdated((value) => value + 1);
          } else {
            setLoading(false);
            toast.error(response?.ERROR_MESSAGE);
          }
        })
        .catch((ex) => {
          console.log(ex);
          toast.error(t("toasts.saveDocumentFailed"));
          setLoading(false);
        });
    }
  };

  const viewFile = (File) => {
    if (File.FileByte != null) {
      const binaryBuffer = Buffer.from(File.FileByte, "base64");
      const blob = new Blob([binaryBuffer], {
        type: `${File.ContentType};charset=utf-8`,
      });
      window.open(URL.createObjectURL(blob), File.FileName);
    } else {
      window.open(URL.createObjectURL(File.File), File.FileName);
    }
  };

  const downloadFile = (File) => {
    const downloadLink = document.createElement("a");

    if (File.FileByte != null) {
      const binaryBuffer = Buffer.from(File.FileByte, "base64");
      const blob = new Blob([binaryBuffer], {
        type: `${File.ContentType};charset=utf-8`,
      });

      downloadLink.href = URL.createObjectURL(blob);
    } else {
      downloadLink.href = URL.createObjectURL(File.File);
    }

    downloadLink.download = File.FileName;
    downloadLink.click();
  };

  const removeFile = (File, index) => {
    if (!!File.FileByte) {
      const updatedFiles = [...acceptedFiles];
      updatedFiles[index] = { ...acceptedFiles[index], IsDelete: true };
      setAcceptedFiles(updatedFiles);
    } else {
      setAcceptedFiles((prevFiles) =>
        prevFiles.filter((file, i) => i !== index)
      );
    }
  };

  const handleClose = () => {
    toast.info(t("toasts.editCancelled"));
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box>
          <Typography color="textSecondary" gutterBottom variant="h3">
            {FieldName === "Inventory"
              ? t("inventory.subHeadings.addProductImages")
              : t("headings.addDocuments")}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          {...getRootProps({ className: "dropzone" })}
          sx={{
            padding: 3,
            textAlign: "center",
            marginTop: 2,
            border: "2px dashed #000",
          }}
        >
          <input {...getInputProps()} />
          <>
            <CloudUpload />
            <Typography variant="body1">{t("messages.dragAndDrop")}</Typography>
          </>
        </Box>
        <Box sx={{ display: "flex", paddingTop: 1 }}>
          <Info fontSize="small" color="primary" />
          <Typography color={"primary"} sx={{ margin: "1px" }}>
            {t("messages.deleteWarning")}
          </Typography>
        </Box>
        <Box>
          <List
            sx={{
              overflowX: "auto",
              scrollbarColor: "red",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: 3,
            }}
            cols={acceptedFiles?.length}
          >
            {acceptedFiles?.map(
              (file, index) =>
                !file.IsDelete && (
                  <ListItem key={index} style={{ marginBottom: 10 }}>
                    <Card sx={{ width: "100%" }}>
                      <CardContent sx={{ display: "flex" }}>
                        {file.ContentType?.startsWith("image/") ? (
                          file.FileByte || file?.File ? (
                            <img
                              style={{
                                width: 100,
                                height: 100,
                                padding: 10,
                              }}
                              src={
                                file.FileByte
                                  ? `data:image/png;base64,${file.FileByte}`
                                  : URL.createObjectURL(file?.File)
                              }
                              loading="lazy"
                            />
                          ) : (
                            <BrokenImage
                              fontSize="large"
                              sx={{
                                height: 100,
                                width: 100,
                                color: theme.palette.secondary.dark,
                              }}
                            />
                          )
                        ) : (
                          <DocumentImage height={100} width={100} />
                        )}
                        <Box sx={{ width: "100%", paddingLeft: 2 }}>
                          <Typography component="div" variant="h3" gutterBottom>
                            {file.FileName}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                            component="div"
                            gutterBottom
                          >
                            {t("textFieldLables.fileSize")}:{" "}
                            {(file.Size / 1e6).toFixed(2)} MB
                          </Typography>
                          <Stack
                            direction="row"
                            justifyContent="flex-end" // Align items to the right
                            alignItems="center"
                          >
                            <Button
                              onClick={() => viewFile(file)}
                              sx={{
                                "&:hover": {
                                  color: "black",
                                },
                              }}
                            >
                              {t("buttons.view")}
                              <Visibility fontSize="small" />
                            </Button>
                            <Button
                              color="success"
                              onClick={() => downloadFile(file)}
                              sx={{
                                marginLeft: 1,
                                backgroundColor: "green",
                                "&:hover": {
                                  color: "black",
                                  backgroundColor: "green",
                                },
                              }}
                            >
                              {t("buttons.download")}
                              <Download fontSize="small" />
                            </Button>
                            <Button
                              color="error"
                              onClick={() => removeFile(file, index)}
                              sx={{
                                backgroundColor: "red",
                                marginLeft: 1,
                                "&:hover": {
                                  color: "black",
                                  backgroundColor: "red",
                                },
                              }}
                            >
                              {t("buttons.remove")}
                              <Delete fontSize="small" />
                            </Button>
                          </Stack>
                        </Box>
                      </CardContent>
                    </Card>
                  </ListItem>
                )
            )}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        {!loading ? (
          <>
            <Button color="primary" onClick={handleSubmit}>
              {t("buttons.save")}
            </Button>
            <Button onClick={handleClose} color="primary">
              {t("buttons.cancel")}
            </Button>
          </>
        ) : (
          <Button>{t("buttons.loading")}</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FileUploadDialog;
