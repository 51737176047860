import { Grid } from "@mui/material";
import DataTable from "src/components/Common/TabTable/DataTable";
import { useEffect, useState, useContext } from "react";
import { getAllPayments } from "src/api/paymentApi";
import { AuthContext } from "src/auth/AuthProvider";
import { useTranslation } from "react-i18next";

const ViewDeposits = ({ FieldId, TypeId, CustomerId }) => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const auth = useContext(AuthContext);
  const token = auth.getAuthToken();
  const { t } = useTranslation();

  const renderColumns = [
    {
      name: "Currency",
      isView: true,
      label: t("tableHeadings.currency"),
    },
    {
      name: "Amount",
      isView: true,
      label: t("tableHeadings.amount"),
      fieldRenderType: "currency",
    },
    {
      name: "TaxAmount",
      isView: true,
      label: t("tableHeadings.taxAmount"),
      fieldRenderType: "currency",
    },
    {
      name: "TotalAmount",
      isView: true,
      label: t("tableHeadings.totalAmount"),
      fieldRenderType: "currency",
    },
    {
      name: "TransactionDate",
      isView: true,
      label: t("tableHeadings.transactionDate"),
      fieldRenderType: "date",
    },
    {
      name: "Details",
      isView: true,
      label: t("tableHeadings.details"),
    },
  ];

  const fetchpayments = async (FieldId, TypeId) => {
    setLoading(true);

    await getAllPayments(token, {
      FieldId: FieldId,
      TypeId: TypeId,
    })
      .then((response) => {
        setLoading(false);
        setPayments(response.Result);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchpayments(FieldId, TypeId);
  }, [FieldId, TypeId]);

  return (
    <Grid container>
      <Grid item>
        <DataTable
          columns={renderColumns}
          rows={payments}
          isLoading={loading}
          tableHeading={null}
          isCommonView={true}
          pagination={false}
        />
      </Grid>
    </Grid>
  );
};

export default ViewDeposits;
