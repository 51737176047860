import { useMemo, useState } from "react";
import { CssBaseline } from "@mui/material";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import palette from "./palette";
import typography from "./typography";
import { useSelector } from "src/redux/Store";
import { useEffect } from "react";
import themeDefault from "./theme";
import themeLight from "./themeLight";
import themeDark from "./theme.dark";

export default function ThemeProvider({ children }) {
  const switchTheme = useSelector(
    (state) => state.theme.switchTheme || "default"
  );

  const themeOptions = useMemo(
    () => ({
      palette:
        switchTheme == "default"
          ? palette.default
          : switchTheme == "light"
          ? palette.light
          : palette.dark,
      typography,
    }),
    [switchTheme]
  );

  const _t = createTheme(themeOptions);

  const [theme, setTheme] = useState(_t);

  useEffect(() => {
    if (switchTheme === "default") {
      setTheme(themeDefault);
    } else if (switchTheme === "light") {
      setTheme(themeLight);
    } else {
      setTheme(themeDark);
    }
  }, [switchTheme]);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
