import PropTypes from "prop-types";
// import { noCase } from "change-case";
import { useContext, useEffect, useState } from "react";
// @muiuseContext
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  CircularProgress,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButtonAnimate from "./IconButtonAnimate";
import {
  AccessTimeOutlined,
  ArticleOutlined,
  AssessmentOutlined,
  CalendarTodayOutlined,
  ChatBubbleOutlineOutlined,
  ConstructionOutlined,
  DashboardOutlined,
  FileDownloadOutlined,
  LocalPhoneOutlined,
  MapOutlined,
  NotificationsNoneOutlined,
  AddBoxOutlined,
  PeopleOutlined,
  RequestPageOutlined,
  SettingsOutlined,
  SearchOutlined,
  ArrowBack,
  EngineeringOutlined,
  LockResetOutlined,
  LogoutOutlined,
  PersonOutlined,
  GridViewOutlined,
  EmailOutlined,
} from "@mui/icons-material";
import { Tab, Tabs } from "@mui/material";
import moment from "moment";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  getAllNotifications,
  putNotificationMarkRead,
  getAllNotificationsCount,
  putNotificationDelete,
  putNotificationMarkallAsRead,
} from "src/api/notificationsApi";
import { AuthContext } from "src/auth/AuthProvider";
import { useDispatch } from "src/redux/Store";
import Iconify from "./Iconify";
import MenuPopover from "./MenuPopover";
import Scrollbar from "./Scrollbar";
import CloseIcon from "@mui/icons-material/Close";
import UndoIcon from "@mui/icons-material/Undo";
import Job from "../Job/Job";

export default function NotificationsPopover({
  open,
  handleClose,
  // isCountChange,
  setIsCountChange,
}) {
  const auth = useContext(AuthContext);
  const profile = auth.getProfile();
  const token = auth.getAuthToken();

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [notificationsData, setNotificationsData] = useState({});

  const getNotifications = async () => {
    let notifications = await getNotifications(token);

    if (notifications?.STATUS_CODE !== "200") {
      // toast.error("toasts.leadDetailsLoadingFailed")
    } else {
      setNotificationsData(notifications?.DATA);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    setLoading(true);
    await getAllNotifications(token, {
      Page: 1,
      Limit: 20,
    })
      .then((response) => {
        setNotifications(response?.DATA);
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
      });
    setLoading(false);
  };

  const [notificationsCount, setNotificationsCount] = useState([]);

  // const getNotificationsCount = async () => {
  //   let notificationsCount = await getNotificationsCount(token);

  //   if (notificationsCount?.STATUS_CODE !== "200") {
  //     // toast.error("toasts.leadDetailsLoadingFailed")
  //   } else {
  //     setNotificationsCount(notificationsCount?.DATA);
  //   }
  // };

  const notificationDetails = {
    NotificationId: 0,
    IsRead: false,
    Title: "",
    Details: "",
    CreatedAt: "",
    CreatedUserId: 0,
    CreatedUserName: "",
    CreatedUserFullName: "",
    SourceId: 0,
    SourceTypeId: 0,
    SourceTypeName: "",
    ActionType: 0,
    ActionTypeName: "",
    CreatedBy: "",
    UpdatedBy: "",
    UpdatedDate: "",
    CreatedDate: "",
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: notificationDetails,
    mode: "onBlur",
  });
  watch();

  const [values, setValues] = useState("");

  const handleChange = (newValue) => {
    setValues(newValue);
  };

  const fetchNotificationsByTab = async (tab) => {
    setLoading(true);
    let flag = "";
    if (tab === "unread") {
      flag = "unread";
    } else if (tab === "read") {
      flag = "read";
    }

    try {
      const response = await getAllNotifications(token, {
        Page: 1,
        Limit: 20,
        Flag: tab,
      });

      setNotifications(response?.DATA);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (values === "") fetchNotificationsByTab("");
    if (values === "unread") fetchNotificationsByTab("unread");
    if (values === "read") fetchNotificationsByTab("read");
  }, [values]);

  const [isReadAll, setIsReadAll] = useState(false);

  const handleMarkAllAsRead = () => {
    setIsReadAll(!isReadAll);
    setNotifications(
      notifications?.map((notification) => ({
        ...notification,
        IsRead: !isReadAll,
      }))
    );
    putNotificationMarkallAsRead(token, {
      CreatedDate: moment(),
    })
      .then(async (response) => {
        if (response?.STATUS_CODE === "200") {
          setLoading(true);
          setValues(0);
          fetchNotifications();
          fetchNotificationsCount();
          setValues("");
        } else {
          console.log("Error");
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  function a11yPropsType(indexType) {
    return {
      notificationId: `simple-tab-${indexType}`,
      "aria-controls": `simple-tabpanel-${indexType}`,
    };
  }
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const isDownLg = useMediaQuery(theme.breakpoints.down("xl"));

  const [workIndexValue, setworkIndexValue] = useState(0);

  const handleWorkTabChange = (event, newWorkValue) => {
    setworkIndexValue(newWorkValue);
  };

  const handleTitleClick = (notificationId, tab = "") => {
    // console.log("Clicked on notification with ID:", notificationId);

    const notification = notifications.find(
      (notification) => notification.NotificationId === notificationId
    );

    putNotificationMarkRead(token, notificationId, {})
      .then(async (response) => {
        if (response?.STATUS_CODE === "200") {
          setLoading(true);
          // fetchNotifications();
        } else {
          console.log("Error");
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });

    if (notification) {
      const jobType = Job.IsOneOff ? "oneoff-job" : "recurring-job";

      const sourceTypeToUrl = {
        Request: `/leads/view-lead/${notification.SourceId}`,
        Quote: `/quotes/view-quote/${notification.SourceId}`,
        // Job: `/jobs/${jobType}/${notification.SourceId}`,
        Job:
          `/jobs/oneoff-job/${notification.SourceId}` ||
          `/jobs/recurring-job/${notification.SourceId}`,
        Invoice: `/invoices/view-invoice/${notification.SourceId}`,
        Payment: `/urlForPayment`,
      };

      const url = sourceTypeToUrl[notification.SourceTypeName];

      if (url) {
        navigate(url);
        handleClose();
      } else {
        fetchNotificationsByTab(tab);
      }
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////
  const [isUndoButtonClicked, setIsUndoButtonClicked] = useState(true);

  const [showUndoButton, setShowUndoButton] = useState({});

  const toggleUndoButtonClick = (notificationId) => {
    setShowUndoButton({
      ...showUndoButton,
      [notificationId]: !showUndoButton[notificationId],
    });
  };

  // let timeOut = null;
  const [selectedId, setSelectedId] = useState(null);

  const [isDeleteClicked, setIsDeleteClicked] = useState(false);

  useEffect(() => {
    let timeout;
    let deleteTimeOut;
    if (!isUndoButtonClicked) {
      setIsDeleteClicked(true);
      timeout = setTimeout(() => {
        putNotificationDelete(token, selectedId, {})
          .then(async (response) => {
            if (response?.STATUS_CODE === "200") {
              setLoading(true);
              fetchNotificationsByTab(values);
              setIsUndoButtonClicked(true);
              setIsDeleteClicked(false);
            } else {
              console.log("Error");
              setLoading(false);
              setIsUndoButtonClicked(true);
              setIsDeleteClicked(false);
            }
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
            setIsUndoButtonClicked(true);
            setIsDeleteClicked(false);
          });
        // deleteTimeOut = setTimeout(() => {
        // setIsDeleteClicked(false);
        // }, 1000);
        // clearTimeout(deleteTimeOut);
      }, 2000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isUndoButtonClicked]);

  const handleUndoButtonClicked = (notificationId) => {
    setIsDeleteClicked(false);
    setIsUndoButtonClicked(true);
    toggleUndoButtonClick(notificationId);
    // clearTimeout(timeOut);
  };

  const handleDeletebuttonAll = (notificationId) => {
    setIsDeleteClicked(true);
    setSelectedId(notificationId);
    const notification = notifications.find(
      (notification) => notification.NotificationId === notificationId
    );
    // console.log({ showUndoButton });
    setShowUndoButton({
      ...showUndoButton,
      [notificationId]: true,
    });
    setIsUndoButtonClicked(false);
  };

  useEffect(() => {
    fetchNotificationsCount();
  }, [isUndoButtonClicked]);

  const fetchNotificationsCount = async () => {
    await getAllNotificationsCount(token)
      .then((response) => {
        setIsCountChange((val) => !val);
        // setValue(isCountChange, true);
        setNotificationsCount(response?.DATA);
      })
      .catch((ex) => {});
  };

  const totalAll = notificationsCount.TotalCount;

  const totalUnRead = notificationsCount.UnreadCount;

  const totalRead = notificationsCount.ReadCount;

  //7536
  return (
    <>
      <MenuPopover
        open={open}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 6, ml: 0.75 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h2" mb={1}>
              {t("headings.notifications")}
            </Typography>
            {values === "" && (
              <div>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {t("messages.youHave")} {totalAll} {t("messages.messages")}
                </Typography>
              </div>
            )}
            {values === "unread" && (
              <div>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {t("messages.youHave")} {totalUnRead}{" "}
                  {t("messages.unreadMessages")}
                </Typography>
              </div>
            )}
            {values === "read" && (
              <div>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {t("messages.youHave")} {totalRead}{" "}
                  {t("messages.readMessages")}
                </Typography>
              </div>
            )}
          </Box>

          <Tooltip
            Title={
              totalUnRead > 0 ? " Mark all as unread" : " Mark all as unread"
            }
          >
            <IconButton
              color={totalUnRead > 0 ? "primary" : "secondary"}
              onClick={handleMarkAllAsRead}
            >
              <Iconify icon="eva:done-all-fill" width={20} height={20} />
            </IconButton>
          </Tooltip>
        </Box>
        <Divider sx={{ borderStyle: "dashed" }} />
        <Box sx={{ width: "100%", p: 2 }}>
          <Tabs
            value={values}
            onChange={handleChange}
            centered
            variant="fullWidth"
            sx={{
              "& .MuiTabs-root": {
                alignItems: "center",
                justifyContent: "center",
              },
            }}
            // textColor="secondary"
            // indicatorColor="secondary"
            // aria-label="secondary tabs example"
          >
            <Tab
              value={""}
              label={
                <Typography
                  color="textSecondary"
                  variant="h5"
                  sx={{
                    fontWeight: theme.typography.fontWeightBold,
                    textTransform: "uppercase",
                  }}
                >
                  {t("labels.all")}
                </Typography>
              }
              {...a11yPropsType("")}
              onClick={() => {
                setValues("");
              }}
              sx={{
                boxShadow: 2,
                width: "100%",
                background: values === "" ? "#F4A540" : "",
                border: "1px solid #CACACA",
              }}
            />
            <Tab
              value={"unread"}
              label={
                <Typography
                  color="textSecondary"
                  variant="h5"
                  sx={{
                    fontWeight: theme.typography.fontWeightBold,
                    textTransform: "uppercase",
                  }}
                >
                  {t("labels.unRead")}
                </Typography>
              }
              {...a11yPropsType("unread")}
              onClick={() => {
                setValues("unread");
              }}
              sx={{
                boxShadow: 2,
                width: "100%",
                background: values === "unread" ? "#F4A540" : "",
                border: "1px solid #CACACA",
              }}
            />
            <Tab
              value={"read"}
              label={
                <Typography
                  color="textSecondary"
                  variant="h5"
                  sx={{
                    fontWeight: theme.typography.fontWeightBold,
                    textTransform: "uppercase",
                  }}
                >
                  {t("labels.read")}
                </Typography>
              }
              {...a11yPropsType("read")}
              onClick={() => {
                setValues("read");
              }}
              sx={{
                boxShadow: 2,
                width: "100%",
                background: values === "read" ? "#F4A540" : "",
                border: "1px solid #CACACA",
              }}
            />
          </Tabs>
        </Box>
        <Scrollbar
          sx={{ height: { xs: 340, sm: "auto", md: "50dvh", lg: "55dvh" } }}
        >
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                {/* <Typography
                  color="textSecondary"
                  variant="h5"
                  sx={{
                    fontWeight: theme.typography.fontWeightBold,
                    textTransform: "uppercase",
                  }}
                >
                  All
                </Typography> */}
              </ListSubheader>
            }
          >
            {values === "" && (
              <div>
                <Typography
                  color="textSecondary"
                  variant="h5"
                  sx={{
                    fontWeight: theme.typography.fontWeightBold,
                    textTransform: "uppercase",
                    ml: 3,
                  }}
                >
                  {t("headings.allNotifications")}
                </Typography>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <CircularProgress color="primary" size={30} />
                  </div>
                ) : (
                  notifications?.map((notification) => (
                    <NotificationItem
                      key={notification.NotificationId}
                      notification={notification}
                      onTitleClick={() =>
                        handleTitleClick(notification.NotificationId)
                      }
                      onDeleteClick={() => {
                        handleDeletebuttonAll(notification.NotificationId);
                      }}
                      onUndoClick={() => {
                        handleUndoButtonClicked(notification.NotificationId);
                      }}
                      showUndoButton={
                        showUndoButton[notification.NotificationId] || false
                      }
                      isDeleteClicked={isDeleteClicked}
                    />
                  ))
                )}
              </div>
            )}
            {values === "unread" && (
              <div>
                <Typography
                  color="textSecondary"
                  variant="h5"
                  sx={{
                    fontWeight: theme.typography.fontWeightBold,
                    textTransform: "uppercase",
                    ml: 3,
                  }}
                >
                  {t("headings.unReadNotifications")}
                </Typography>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <CircularProgress color="primary" size={30} />
                  </div>
                ) : (
                  notifications?.map((notification) => (
                    <NotificationItem
                      key={notification.NotificationId}
                      notification={notification}
                      onTitleClick={() =>
                        handleTitleClick(notification.NotificationId, "unread")
                      }
                      onDeleteClick={() => {
                        handleDeletebuttonAll(notification.NotificationId);
                      }}
                      onUndoClick={() => {
                        handleUndoButtonClicked(notification.NotificationId);
                      }}
                      showUndoButton={
                        showUndoButton[notification.NotificationId] || false
                      }
                      isDeleteClicked={isDeleteClicked}
                    />
                  ))
                )}
              </div>
            )}
            {values === "read" && (
              <div>
                <Typography
                  color="textSecondary"
                  variant="h5"
                  sx={{
                    fontWeight: theme.typography.fontWeightBold,
                    textTransform: "uppercase",
                    ml: 3,
                  }}
                >
                  {t("headings.readNotifications")}
                </Typography>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <CircularProgress color="primary" size={30} />
                  </div>
                ) : (
                  notifications?.map((notification) => (
                    <NotificationItem
                      key={notification.NotificationId}
                      notification={notification}
                      onTitleClick={() =>
                        handleTitleClick(notification.NotificationId, "read")
                      }
                      onDeleteClick={() => {
                        handleDeletebuttonAll(notification.NotificationId);
                      }}
                      onUndoClick={() => {
                        handleUndoButtonClicked(notification.NotificationId);
                      }}
                      showUndoButton={
                        showUndoButton[notification.NotificationId] || false
                      }
                      isDeleteClicked={isDeleteClicked}
                    />
                  ))
                )}
              </div>
            )}
          </List>
        </Scrollbar>
        <Divider sx={{ borderStyle: "dashed" }} />
        <Box sx={{ p: 1 }}>
          <Button
            component={Link}
            to={"/notifications"}
            onClick={handleClose}
            fullWidth
            // disableRipple
            sx={{
              color: theme.palette.primary.input,
              backgroundColor: theme.palette.secondary.primary,
            }}
          >
            {t("buttons.viewAll")}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    NotificationId: PropTypes.number,
    IsRead: PropTypes.bool,
    Title: PropTypes.string,
    description: PropTypes.string,
    ActionTypeName: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({
  notification,
  onTitleClick,
  onDeleteClick,
  onUndoClick,
  showUndoButton,
  toggleUndoButtonClick,
  isDeleteClicked,
}) {
  const { avatar, Title } = renderContent(
    notification,
    onDeleteClick,
    onUndoClick
  );

  return (
    <>
      <div
        style={{
          position: "absolute",
          right: 0,
          zIndex: 300,
        }}
      >
        {showUndoButton ? (
          <IconButton onClick={() => onUndoClick(notification.NotificationId)}>
            <UndoIcon style={{ color: "blue", fontSize: 20 }} />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => {
              onDeleteClick(notification.NotificationId);
              // if (!showUndoButton) {
              //   toggleUndoButtonClick(notification.NotificationId);
              // }
            }}
            disabled={isDeleteClicked}
          >
            <CloseIcon style={{ color: "red", fontSize: 20 }} />
          </IconButton>
        )}
      </div>
      <ListItemButton
        sx={{
          py: 1.5,
          px: 2.5,
          mt: "1px",
          ...(notification.IsRead
            ? {
                bgcolor: "none",
              }
            : // : { bgcolor: "#3FF4A540" }),
              { bgcolor: "#FFF1D7" }),
        }}
        onClick={() => onTitleClick(notification.NotificationId)} // Call onTitleClick when the item is clicked
      >
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={Title}
          secondary={
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: "flex",
                alignItems: "center",
                color: "text.disabled",
              }}
            >
              <Iconify
                icon="eva:clock-outline"
                sx={{ mr: 0.5, width: 16, height: 16 }}
              />
              {moment(notification.CreatedAt).fromNow()}
            </Typography>
          }
        />
      </ListItemButton>
    </>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const splitDescription = (description, maxLength) => {
    const result = [];
    for (let i = 0; i < description.length; i += maxLength) {
      result.push(description.substr(i, maxLength));
    }
    return result;
  };

  const Title = (
    <div>
      <Stack direction={"row"}>
        <Typography variant="h4" style={{ fontWeight: 500 }}>
          {notification.Title}
        </Typography>
        <Typography variant="h5" color={"#CACACA"} ml={1}>
          #{notification.SourceId}
        </Typography>
      </Stack>
      <Typography
        component="span"
        variant="h5"
        sx={{ color: "text.secondary" }}
      >
        <div>
          {splitDescription(notification.Details, 60).map((line, index) => (
            <div key={index}>{line}</div>
          ))}
        </div>
      </Typography>
    </div>
  );

  if (notification.SourceTypeName === "Request") {
    return {
      avatar: <FileDownloadOutlined />,
      Title,
    };
  } else if (notification.SourceTypeName === "Quote") {
    return {
      avatar: <RequestPageOutlined />,
      Title,
    };
  } else if (notification.SourceTypeName === "Job") {
    return {
      avatar: <ConstructionOutlined />,
      Title,
    };
  } else if (notification.SourceTypeName === "Invoice") {
    return {
      avatar: <ArticleOutlined />,
      Title,
    };
  } else if (notification.SourceTypeName === "Email") {
    return {
      avatar: <EmailOutlined />,
      Title,
    };
  }

  return {
    avatar: notification.avatar ? (
      <img alt={notification.Title} src={notification.avatar} />
    ) : null,
    Title,
  };
}
