import { useMemo } from "react";
import { CssBaseline } from "@mui/material";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

export default function DateTimePickerTheme({ children }) {
  const mainTheme = useTheme();

  const themeOption = createTheme({
    palette: {
      primary: {
        main: mainTheme.palette.primary.main,
        dark: mainTheme.palette.primary.dark,
      },
    },
    typography: {
      fontFamily: "'Poppins','Helvetica','Arial', sans-serif",
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 600,
      body1: {
        fontSize: "0.65rem",
        fontWeight: 200,
        "@media (min-width:1536px)": {
          fontSize: "0.8rem",
        },
      },
      button: {
        fontSize: "0.7rem",
        fontWeight: 500,
        "@media (min-width:1536px)": {
          fontSize: "0.8rem",
        },
      },
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          input: () => ({
            fontWeight: "500",
            borderColor: "none",
            color: mainTheme.palette.primary.contrastText,
          }),
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: "0.8rem",
            color: mainTheme.palette.input.border,
            fontWeight: "400",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "&:hover": {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: mainTheme.palette.primary.main,
                },
              },
            },
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                border: `1px solid ${mainTheme.palette.input.border}`,
                borderRadius: "10px",
              },
            },
            "& .MuiOutlinedInput-root:focus": {
              "& > fieldset": {
                border: `1px solid ${mainTheme.palette.input.border}`,
                borderRadius: "10px",
              },
            },
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            backgroundColor: mainTheme.palette.primary.main,
            color: mainTheme.palette.secondary.contrastText,
            "&:disabled": {
              backgroundColor: mainTheme.palette.input.background,
            },
            "&:focus": {
              backgroundColor: mainTheme.palette.primary.main,
            },
            "&:hover": {
              backgroundColor: mainTheme.palette.primary.dark,
            },
            "&:selected": {
              backgroundColor: mainTheme.palette.primary.dark,
              color: mainTheme.palette.primary.contrastText,
            },
          },
        },
      },
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={themeOption}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
