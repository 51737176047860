const { usePermission } = require("src/context/PermissionContext");

const RestrictedComponents = ({ permission, children }) => {
  const hasPermission = usePermission(permission);

  if (!permission) return children;

  return hasPermission ? children : null;
};

export default RestrictedComponents;
