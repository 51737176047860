import React, { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";

//layouts
import Layout from "./components/Common/Layouts/Layout";
import ViewRequest from "./pages/Request/ViewRequest";
import ViewJob from "./pages/Job/ViewJob";
import OneTimeJobOverView from "./pages/Job/OneTimeJobOverView";
import ViewEvent from "./pages/Job/ViewEvent";
import FirstLoginLayout from "./components/Common/Layouts/FirstLoginLayout";
import ResetPassword from "./components/Setting/ResetPassword";
import Container from "./components/Setting/Container";
// import ViewEmployee from "./pages/Employee/ViewEmployee";

//pages
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Customer = lazy(() => import("./pages/Customer/Customers"));
const NewCustomer = lazy(() => import("./pages/Customer/AddCustomer"));
const ViewCustomer = lazy(() => import("./pages/Customer/ViewCustomer"));
const Quote = lazy(() => import("./pages/Quote/Quote"));
const NewQuote = lazy(() => import("./pages/Quote/NewQuote"));
const ViewQuote = lazy(() => import("./pages/Quote/ViewQuote"));
const Request = lazy(() => import("./pages/Request/Request"));
const NewRequest = lazy(() => import("./pages/Request/NewRequest"));
const Schedule = lazy(() => import("./pages/Schedule"));
const Invoice = lazy(() => import("./pages/Invoice/Invoice"));
const NewInvoice = lazy(() => import("./pages/Invoice/NewInvoice"));
const ViewInvoice = lazy(() => import("./pages/Invoice/ViewInvoice"));
const PreviewPDF = lazy(() => import("./pages/Invoice/PreviewPDF/PreviewPDF"));
const Job = lazy(() => import("./pages/Job/Job"));
const NewJob = lazy(() => import("./pages/Job/NewJob"));
const Login = lazy(() => import("./pages/Login"));
const Track = lazy(() => import("./pages/Track/Track"));
const SessionTimeout = lazy(() => import("./pages/SessionTimeout"));
const Settings = lazy(() => import("./pages/Settings"));
const Employee = lazy(() => import("./pages/Employee/Employee"));
const ViewEmployee = lazy(() => import("./pages/Employee/ViewEmployee"));
const NewEmployee = lazy(() => import("./pages/Employee/AddEmployee"));
const Reports = lazy(() => import("./pages/Report/Reports"));
const WorkOrderSummary = lazy(() => import("./pages/Report/WorkOrderSummary"));
const JobsOverTime = lazy(() => import("./pages/Report/JobsOverTime"));
const QuoteSummary = lazy(() => import("./pages/Report/QuoteSummary"));
const TotalJobSummary = lazy(() => import("./pages/Report/TotalJobsSummary"));
const SalesSummary = lazy(() => import("./pages/Report/SalesSummary"));
const FinanceSummary = lazy(() => import("./pages/Report/FinanceSummary"));
const Dispatch = lazy(() => import("./pages/Dispatch"));
const Notifications = lazy(() =>
  import("./pages/Notification/NotificationPage")
);
const Inventory = lazy(() => import("./pages/Inventory/Inventory"));
const InventoryForm = lazy(() => import("./pages/Inventory/InventoryForm"));

const AddProduct = lazy(() => import("./pages/Onboarding/AddProduct"));
const ProductList = lazy(() => import("./pages/Onboarding/ProductList"));
const AddService = lazy(() => import("./pages/Onboarding/AddService"));
const ServiceList = lazy(() => import("./pages/Onboarding/ServiceList"));
// const AddEmployee = lazy(() => import("./pages/Onboarding/AddEmployee"));
// const EmployeeList = lazy(() => import("./pages/Onboarding/EmployeeList"));
const AddLogo = lazy(() => import("./pages/Onboarding/AddLogo"));
const ClientSettings = lazy(() =>
  import("./components/Setting/ClientSettings/index")
);

const TrialExpired = lazy(() =>
  import("src/components/Common/TrialCountdown/TrialExpired")
);

const Router = () => {
  let Routes = useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "", element: <Navigate to="dashboard" replace /> },

        { path: "dashboard", element: <Dashboard /> },

        {
          path: "customers",
          children: [
            { index: true, element: <Customer /> },
            { path: "new", element: <NewCustomer /> },
            { path: "view-customer/:customerId", element: <ViewCustomer /> },
          ],
        },

        {
          path: "leads",
          children: [
            { index: true, element: <Request /> },
            { path: "new-lead", element: <NewRequest /> },
            { path: "view-lead/:requestId", element: <ViewRequest /> },
          ],
        },

        {
          path: "quotes",
          children: [
            { index: true, element: <Quote /> },
            { path: "new-quote", element: <NewQuote /> },
            { path: "view-quote/:quoteId", element: <ViewQuote /> },
          ],
        },

        {
          path: "jobs",
          children: [
            { index: true, element: <Job /> },
            { path: "new-job", element: <NewJob /> },
            { path: "oneoff-job/:jobId", element: <OneTimeJobOverView /> },
            { path: "recurring-job/:jobId", element: <ViewJob /> },

            {
              path: "view-job/:jobId/view-event/:eventId",
              element: <ViewEvent />,
            },
            {
              path: "view-job/:jobId/edit-event/:eventId",
              element: <ViewEvent />,
            },
          ],
        },

        {
          path: "invoices",
          children: [
            { index: true, element: <Invoice /> },
            { path: "new-invoice", element: <NewInvoice /> },
            { path: "view-invoice/:invoiceId", element: <ViewInvoice /> },
            // { path: "print-pdf/:invoiceId", element: <PrintInvoicePDF /> },
          ],
        },

        {
          path: "employees",
          children: [
            { index: true, element: <Employee /> },
            { path: "new-employee", element: <NewEmployee /> },
            { path: "view-employee/:employeeId", element: <ViewEmployee /> },
          ],
        },

        {
          path: "reports",
          children: [
            { index: true, element: <Reports /> },
            { path: "work-order-summary/:tab", element: <WorkOrderSummary /> },
            { path: "jobs-over-time", element: <JobsOverTime /> },
            { path: "quote-summary", element: <QuoteSummary /> },
            { path: "total-job-summary", element: <TotalJobSummary /> },
            { path: "sales-summary", element: <SalesSummary /> },
            { path: "finance-summary", element: <FinanceSummary /> },
          ],
        },

        {
          path: "inventory",
          children: [
            { index: true, element: <Inventory /> },
            { path: "new-inventory", element: <InventoryForm /> },
            { path: "view-inventory/:productId", element: <InventoryForm /> },
          ],
        },

        { path: "track", element: <Track /> },

        // { path: "schedule", element: <Schedule /> },

        { path: "settings/:tab/:verticalTab", element: <Settings /> },

        { path: "dispatch", element: <Dispatch /> },

        { path: "notifications", element: <Notifications /> },
      ],
    },
    { path: "/", element: <Navigate to="dashboard" replace /> },
    { path: "*", element: <Navigate to="dashboard" replace /> },
  ]);
  return Routes;
};

const AuthRouter = () => {
  let AuthRoutes = useRoutes([
    { path: "/login", element: <Login /> },
    { path: "/", element: <Navigate to="login" /> },
    { path: "*", element: <Navigate to="login" /> },
  ]);
  return AuthRoutes;
};

const SessionRouter = () => {
  let SessionRoutes = useRoutes([
    { path: "/session-timeout", element: <SessionTimeout /> },
    { path: "/", element: <Navigate to="session-timeout" /> },
    { path: "*", element: <Navigate to="session-timeout" /> },
  ]);
  return SessionRoutes;
};

const InitialRouter = () => {
  let InitialRoutes = useRoutes([
    {
      path: "/",
      element: <FirstLoginLayout />,
      children: [
        { path: "", element: <Navigate to="initial-setup" replace /> },

        { path: "initial-setup", element: <AddLogo /> },
        // { path: "initial-setup/add-employee", element: <AddEmployee /> },
        // { path: "initial-setup/employees", element: <EmployeeList /> },
        { path: "initial-setup/add-product", element: <AddProduct /> },
        { path: "initial-setup/products", element: <ProductList /> },
        { path: "initial-setup/add-service", element: <AddService /> },
        { path: "initial-setup/services", element: <ServiceList /> },
        { path: "initial-setup/settings", element: <ClientSettings /> },
      ],
    },
    { path: "/", element: <Navigate to="initial-setup" replace /> },
    { path: "*", element: <Navigate to="initial-setup" replace /> },
  ]);
  return InitialRoutes;
};

const TrialExpiredRouter = () => {
  let AuthRoutes = useRoutes([
    { path: "/trial-expired", element: <TrialExpired /> },
    { path: "/", element: <Navigate to="trial-expired" /> },
    { path: "*", element: <Navigate to="trial-expired" /> },
  ]);
  return AuthRoutes;
};

const EmployeeRouter = () => {
  let EmployeeRoutes = useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "", element: <Navigate to="dashboard" replace /> },

        { path: "dashboard", element: <Dashboard /> },

        {
          path: "leads",
          children: [
            { index: true, element: <Request /> },
            { path: "view-lead/:requestId", element: <ViewRequest /> },
          ],
        },

        {
          path: "jobs",
          children: [
            { index: true, element: <Job /> },
            { path: "oneoff-job/:jobId", element: <OneTimeJobOverView /> },
            { path: "recurring-job/:jobId", element: <ViewJob /> },

            {
              path: "view-job/:jobId/view-event/:eventId",
              element: <ViewEvent />,
            },
            {
              path: "view-job/:jobId/edit-event/:eventId",
              element: <ViewEvent />,
            },
          ],
        },
        {
          path: "employees",
          children: [
            { index: true, element: <Employee /> },
            { path: "new-employee", element: <NewEmployee /> },
            { path: "view-employee/:employeeId", element: <ViewEmployee /> },
          ],
        },

        {
          path: "reset-password",
          element: <Container />,
        },
        { path: "dispatch", element: <Dispatch /> },
        { path: "notifications", element: <Notifications /> },
      ],
    },
    { path: "/", element: <Navigate to="dashboard" replace /> },
    { path: "*", element: <Navigate to="dashboard" replace /> },
  ]);
  return EmployeeRoutes;
};

export {
  Router,
  AuthRouter,
  InitialRouter,
  SessionRouter,
  TrialExpiredRouter,
  EmployeeRouter,
};
