import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postLogin } from "src/api/authApi";
import { jwtDecode } from "jwt-decode";

export const fetchLoginDeatils = createAsyncThunk(
  "auth/fetchLoginDeatils",
  async (requestData, { rejectWithValue }) => {
    return postLogin(requestData)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        signOut();
        return rejectWithValue(error);
      });
  }
);

export const tokenExpired = createAsyncThunk(
  "auth/tokenExpired",
  async () => {}
);

export const signOut = createAsyncThunk("auth/signOut", async () => {});

const initialState = {
  isLoading: false,
  isAuthorized: null,
  userData: {
    UserId: 0,
    ClientId: 0,
    UserName: "",
    UserRole: "",
    Token: null,
    RefreshToken: "",
    RoleId: 0,
    RFTokenExpDate: "",
    DateFormat: "DD/MM/YYYY",
    TimeFormat: "HH:mm",
    Language: "en-US",
    CurrencySymbol: "$",
    MaxEventCount: 20,
    NotificationReloadTime: 600,
    Latitude: 0.0,
    Longitude: 0.0,
    AccountCreatedDate: null,
    IsFirstDashboardRedirect: false,
    IsServiceAccount: false,
    Permissions: [],
  },
  statusCode: null,
  errorMessage: null,
};

const Slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clientLoginSuccess(state, action) {
      state.userData = action.payload.DATA;
      state.userData.IsFirstDashboardRedirect = true;
      state.isAuthorized = Boolean(action.payload.DATA?.Token);
      const decodedToken = jwtDecode(action.payload.DATA.Token);
      const data = action.payload.DATA;
      state.userData.Permissions = JSON.parse(decodedToken?.Permissions) || [];
      state.userData.RoleId = JSON.parse(decodedToken?.RoleId);

      state.userData.IsFirstLogin =
        JSON.parse(decodedToken?.RoleId) === 1 ? data?.IsFirstLogin : false;
    },
    FirstDashboardRedirect(state, action) {
      state.userData.IsFirstDashboardRedirect = false;
    },
    FinishSetup(state, action) {
      state.userData.IsFirstLogin = false;
    },
    RefreshTokenSuccess(state, action) {
      state.isAuthorized = action.payload?.Token ? true : false;
      //state.userData.Token = action.payload.Token;
      //state.userData.RefreshToken = action.payload.RefreshToken;
      state.userData = action.payload;
    },
    ChangeClientSettings(state, action) {
      state.userData.DateFormat = action.payload.DateFormat;
      state.userData.TimeFormat = action.payload.TimeFormat;
      state.userData.Language = action.payload.Language;
      state.userData.CurrencySymbol = action.payload.Currency;
      state.userData.CurrencyCode = action.payload.CurrencyCode;
      state.userData.MaxEventCount = action.payload.MaxEventCount;
      state.userData.NotificationReloadTime =
        action.payload.NotificationReloadTime;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoginDeatils.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchLoginDeatils.fulfilled, (state, action) => {
        state.statusCode = action.payload.STATUS_CODE;
        state.errorMessage = action.payload.ERROR_MESSAGE;
        state.isLoading = false;
      })
      .addCase(fetchLoginDeatils.rejected, (state, action) => {
        state.statusCode = action.payload.STATUS_CODE;
        state.errorMessage = action.payload.ERROR_MESSAGE;
        state.isLoading = false;
        throw action.payload;
      })
      .addCase(tokenExpired.fulfilled, (state) => {
        state.isAuthorized = false;
        state.isLoading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.userData = initialState.userData;
        state.statusCode = initialState.statusCode;
        state.errorMessage = initialState.errorMessage;
        state.isAuthorized = initialState.isAuthorized;
        state.isLoading = false;
      });
  },
});

export default Slice.reducer;

export const {
  clientLoginSuccess,
  FirstDashboardRedirect,
  FinishSetup,
  RefreshTokenSuccess,
  ChangeClientSettings,
} = Slice.actions;
