import {
  AddBoxOutlined,
  ArticleOutlined,
  AssessmentOutlined,
  ConstructionOutlined,
  DashboardOutlined,
  EngineeringOutlined,
  FileDownloadOutlined,
  GridViewOutlined,
  LockResetOutlined,
  LogoutOutlined,
  NotificationsNoneOutlined,
  PeopleOutlined,
  RequestPageOutlined,
  SettingsOutlined,
  StoreOutlined,
} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Drawer,
  Grow,
  Hidden,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Link, matchPath, useLocation, useNavigate } from "react-router-dom";
import { postLogout } from "src/api/authApi";
import { getAllNotificationsCount } from "src/api/notificationsApi";
import Logo from "src/assets/logo.png";
import LogoHead from "src/assets/logo_head.png";
import profileImg from "src/assets/profile.jpg";
import { AuthContext } from "src/auth/AuthProvider";
import NavTrialCountdownTimer from "src/components/Common/TrialCountdown/NavTrialCountdownTimer";
import Permissions from "src/constants/Permissions";
import NotificationsPopover from "src/pages/Notification/NotificationsPopover";
import { signOut } from "src/redux/Slices/Auth";
import { useDispatch, useSelector } from "src/redux/Store";
import MobileViewDrawer from "./MobileViewDrawer";
import RestrictedComponents from "./Permission/RestrictedComponents";
import { fetchfeatures } from "src/redux/Slices/Common";
const drawerWidth = 240;

const IconContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

// necessary for content to be below app bar
const ToolbarContainer = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

const MenuLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary.contrastText,
}));

function Navbar({ user, IsFirstLogin = false }) {
  const theme = useTheme();
  const auth = useContext(AuthContext);
  const profile = auth.getProfile();
  const ReloadTime = auth.getProfile().NotificationReloadTime || 600;
  const token = auth.getAuthToken();
  const clientProfile = auth.getProfile();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const matchLgDown = useMediaQuery(theme.breakpoints.down("lg"));

  const matchMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const matchSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const links = useRef([]);

  const [selectedTab, setSelectedTab] = useState("Dashboard");
  const [open, setOpen] = useState(!IsFirstLogin);

  useEffect(() => {
    setOpen(!IsFirstLogin);
  }, [IsFirstLogin]);

  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [openAccount, setOpenAccount] = useState(false);
  const anchorRef = useRef(null);
  const { features } = useSelector((state) => state.common);

  const INVENTORY_FEATURE = features?.data.find(
    (feature) => feature.FeatureName === "INVENTORY_MODULE"
  );

  const match = (path) =>
    path ? !!matchPath({ path, end: false }, location.pathname) : false;

  const handleAccountToggle = () => {
    setOpenAccount((prevOpen) => !prevOpen);
  };

  const handleAccountClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenAccount(false);
  };

  const handleAccountListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenAccount(false);
    }
  };

  const prevOpen = useRef(openAccount);

  useEffect(() => {
    if (prevOpen.current === true && openAccount === false) {
      anchorRef?.current?.focus();
    }

    prevOpen.current = openAccount;
  }, [openAccount]);

  useEffect(() => {
    dispatch(fetchfeatures({ token }));
  }, []);
  const handleDrawerOpenClose = () => {
    setOpen(!open);
  };

  const openMobileDrawer = useCallback(() => {
    setIsMobileOpen(true);
  }, [setIsMobileOpen]);

  const closeMobileDrawer = useCallback(() => {
    setIsMobileOpen(false);
  }, [setIsMobileOpen]);

  const baseMenuItems = [
    {
      link: "/dashboard",
      name: "Dashboard",
      displayName: t("menu.dashboard"),
      onClick: closeMobileDrawer,
      icon: <DashboardOutlined />,
      permission: [
        Permissions.JOBS_COUNT_TABS,
        Permissions.FORCAST_CHART,
        Permissions.SALES_CHART,
        Permissions.QUOTE_CONVERTION,
        Permissions.JOB_STATUS,
      ],
    },
    {
      link: "/customers",
      name: "Customers",
      displayName: t("menu.customers"),
      onClick: closeMobileDrawer,
      icon: <PeopleOutlined />,
      permission: Permissions.GET_ALL_CUSTOMERS,
    },
    {
      link: "/leads",
      name: "Leads",
      displayName: t("menu.leads"),
      onClick: closeMobileDrawer,
      icon: <FileDownloadOutlined />,
      permission: Permissions.GET_ALL_REQUESTS,
    },
    {
      link: "/quotes",
      name: "Quotes",
      displayName: t("menu.quotes"),
      onClick: closeMobileDrawer,
      icon: <RequestPageOutlined />,
      permission: Permissions.GET_ALL_QUOTES,
    },
    {
      link: "/jobs",
      name: "Jobs",
      displayName: t("menu.jobs"),
      onClick: closeMobileDrawer,
      icon: <ConstructionOutlined />,
      permission: Permissions.GET_ALL_JOBS,
    },
    {
      link: "/invoices",
      name: "Invoices",
      displayName: t("menu.invoices"),
      onClick: closeMobileDrawer,
      icon: <ArticleOutlined />,
      permission: Permissions.GET_ALL_INVOICES,
    },
    {
      link: "/employees",
      name: "Employees",
      displayName: t("menu.employees"),
      onClick: closeMobileDrawer,
      icon: <EngineeringOutlined />,
      permission: Permissions.GET_ALL_EMPLOYEES,
    },
    {
      link: "/dispatch",
      name: "Dispatch",
      displayName: t("menu.dispatch"),
      onClick: closeMobileDrawer,
      icon: <GridViewOutlined />,
      permission: [
        Permissions.DISPATCH_BOARD,
        Permissions.VIEW_MAP,
        Permissions.UNASSIGNED_INSPECTION_TAB,
        Permissions.UNASSIGNED_JOBS_TAB,
        Permissions.JOB_TABLE,
      ],
    },
    // {
    //   link: "/schedule",
    //   name: "Schedule",
    //   displayName: t("menu.schedule"),
    //   onClick: closeMobileDrawer,
    //   icon: <CalendarTodayOutlined />,
    // },
    {
      link: "/reports",
      name: "Reports",
      displayName: t("menu.reports"),
      onClick: closeMobileDrawer,
      icon: <AssessmentOutlined />,
      permission: 1000,
    },

    // {
    //   link: "/track",
    //   name: "Track",
    //   displayName: t("menu.track"),
    //   onClick: closeMobileDrawer,
    //   icon: <AccessTimeOutlined />,
    // },\
    ...(profile.RoleId === 1
      ? [
          {
            link: "/settings/app-config/reset-password",
            name: "Settings",
            displayName: t("menu.settings"),
            onClick: closeMobileDrawer,
            icon: <SettingsOutlined />,
          },
        ]
      : [
          {
            link: "/reset-password",
            name: "Reset Password",
            displayName: t("menu.resetPassword"),
            onClick: closeMobileDrawer,
            icon: <SettingsOutlined />,
          },
        ]),
  ];
  const menuItems = [
    ...baseMenuItems,
    ...(INVENTORY_FEATURE
      ? [
          {
            link: "/inventory",
            name: "Inventory",
            displayName: t("menu.inventory"),
            onClick: closeMobileDrawer,
            icon: <StoreOutlined />,
          },
        ]
      : []),
  ];

  const location = useLocation();
  const url = location.pathname;

  useEffect(() => {
    if ([`/customer`].includes(url)) {
      setSelectedTab("Customer");
    } else if ([`/product`].includes(url)) {
      setSelectedTab("Product");
    } else if ([`/category`].includes(url)) {
      setSelectedTab("Category");
    } else if ([`/order`].includes(url)) {
      setSelectedTab("Order");
    }
  }, [url]);

  const dispatch = useDispatch();
  const switchTheme = useSelector((state) => state.theme.switchTheme);

  const logout = async () => {
    await postLogout(token, {
      TodayDateTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
    });
    dispatch(signOut());
    navigate("/");
  };
  const currentTheme = useSelector((state) => state.theme.theme);

  const [viewMobSearch, setViewMobSearch] = useState(false);

  useEffect(() => {
    if (!matchMdDown) {
      setViewMobSearch(false);
    }
  }, [matchMdDown]);

  const [openAddNew, setOpenAddNew] = useState(false);
  const addNewRef = useRef(null);

  const handleAddNewToggle = () => {
    setOpenAddNew((addNewPrevOpen) => !addNewPrevOpen);
  };

  const handleAddNewClose = (event) => {
    if (addNewRef.current && addNewRef.current.contains(event.target)) {
      return;
    }

    setOpenAddNew(false);
  };

  const handleAddNewListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenAddNew(false);
    }
  };

  const addNewPrevOpen = useRef(openAddNew);

  useEffect(() => {
    if (addNewPrevOpen.current === true && openAddNew === false) {
      anchorRef?.current?.focus();
    }

    addNewPrevOpen.current = openAddNew;
  }, [openAddNew]);

  const addNewList = [
    {
      link: "/customers/new",
      displayName: t("labels.addNewCustomer"),
      onClick: closeMobileDrawer,
      icon: <PeopleOutlined />,
    },
    {
      link: "/leads/new-lead",
      displayName: t("labels.addNewLead"),
      onClick: closeMobileDrawer,
      icon: <FileDownloadOutlined />,
    },
    {
      link: "/quotes/new-quote",
      displayName: t("labels.addNewQuote"),
      onClick: closeMobileDrawer,
      icon: <RequestPageOutlined />,
    },
    {
      link: "/jobs/new-job",
      displayName: t("labels.addNewJob"),
      onClick: closeMobileDrawer,
      icon: <ConstructionOutlined />,
    },
    {
      link: "/invoices/new-invoice",
      displayName: t("labels.addNewInvoice"),
      onClick: closeMobileDrawer,
      icon: <ArticleOutlined />,
    },
    {
      link: "/employees/new-employee",
      displayName: t("labels.addNewEmployee"),
      onClick: closeMobileDrawer,
      icon: <EngineeringOutlined />,
    },
  ];

  const settingsList = IsFirstLogin
    ? [
        {
          displayName: t("labels.logOut"),
          onClick: logout,
          icon: <LogoutOutlined />,
        },
      ]
    : profile.RoleId === 1
    ? [
        {
          link: "/settings/app-config/reset-password",
          displayName: t("labels.resetPassword"),
          icon: <LockResetOutlined />,
        },
        {
          displayName: t("labels.logOut"),
          onClick: logout,
          icon: <LogoutOutlined />,
        },
      ]
    : [
        {
          link: "/reset-password",
          displayName: t("labels.resetPassword"),
          icon: <LockResetOutlined />,
        },
        {
          displayName: t("labels.logOut"),
          onClick: logout,
          icon: <LogoutOutlined />,
        },
      ];

  const [notificationClicked, setNotificationclicked] = useState(false);
  const handleNotificationClose = (event) => {
    if (addNewRef.current && addNewRef.current.contains(event.target)) {
      return;
    }

    setNotificationclicked(false);
  };

  const [openNotification, setOpennotification] = React.useState(false);

  const handleClick = () => {
    setOpennotification((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpennotification(false);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [notifications, setNotificaions] = useState(null);

  const [len, setLen] = useState(0);
  // const totalNoOfNotifications = () => {
  //   const totalNotifications = notifications?.filter(
  //     (item) => item.IsRead === false
  //   )?.length;
  //   return {
  //     totalNotificationsBatchNo: totalNotifications,
  //   };
  // };

  // useEffect(() => {
  //   totalNoOfNotifications();
  // }, [len]);

  const [notificationsCount, setNotificationsCount] = useState([]);

  const getNotificationsCount = async () => {
    let data = await getNotificationsCount(token);

    if (data?.STATUS_CODE !== "200") {
      // toast.error("toasts.leadDetailsLoadingFailed")
    } else {
      setNotificationsCount(notificationsCount?.DATA);
    }
  };

  const fetchNotificationsCount = async () => {
    await getAllNotificationsCount(token)
      .then((response) => {
        setNotificationsCount(response?.DATA);
      })
      .catch((ex) => {});
  };

  {
    /** ------------------------ In the first 10th second ---------------------------- */
  }
  useEffect(() => {
    if (profile.RoleId != 1) return;
    const timeout = setTimeout(() => {
      fetchNotificationsCount();
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  {
    /** ------------------------ In every 10 min ---------------------------- */
  }

  useEffect(() => {
    if (profile.RoleId != 1) return;
    const interval = setInterval(() => {
      fetchNotificationsCount();
    }, ReloadTime * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [ReloadTime]);

  {
    /** ------------------------ If unread notifications count changes ---------------------------- */
  }

  const [isCountChange, setIsCountChange] = useState(false);

  useEffect(() => {
    fetchNotificationsCount();
  }, [isCountChange]);

  const totalUnRead = notificationsCount.UnreadCount;

  return (
    <Fragment>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "transparent",
          marginLeft: theme.spacing(7) + 1,
          width: `calc(100% - ${theme.spacing(7) + 1}px)`,
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            marginLeft: 0,
          },
          ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            boxShadow: theme.shadow,
            backgroundColor: "transparent",
            transition: theme.transitions.create(["width", "margin"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              marginLeft: 0,
            },
          }),
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            [theme.breakpoints.up("sm")]: {
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
            },
            [theme.breakpoints.up("md")]: {
              paddingLeft: theme.spacing(3),
              paddingRight: theme.spacing(3),
            },
            [theme.breakpoints.up("lg")]: {
              paddingLeft: theme.spacing(4),
              paddingRight: theme.spacing(4),
            },
            backgroundColor: "transperant",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            sx={{ marginLeft: open || !matchSmUp ? 0 : "5rem" }}
          >
            <Hidden smUp>
              <Box mr={1}>
                <IconButton
                  aria-label="Open Navigation"
                  onClick={openMobileDrawer}
                >
                  <MenuIcon
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                  />
                </IconButton>
              </Box>
            </Hidden>

            {!clientProfile.IsDemo && (
              <NavTrialCountdownTimer
                AccountCreatedDate={clientProfile.AccountCreatedDate}
              />
            )}
          </Box>

          {/* {viewMobSearch ? (
            <Box
              display="flex"
              width="100%"
              sx={{
                      marginRight: theme.spacing(3),
                    }}
              style={{ marginLeft: "20%" }}
            >
              <GlobalSearch />
            </Box>
          ) : (
            <Box
              display="flex"
              width="30%"
              sx={{
                      marginRight: theme.spacing(3),
                    }}
              style={{ marginLeft: matchLgDown && !open ? "15%" : "0px" }}
            >
              <Hidden mdDown>
                <GlobalSearch />
              </Hidden>
            </Box>
          )} */}

          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width={matchLgDown ? "100%" : viewMobSearch ? "20%" : "50%"}
          >
            {!viewMobSearch && (
              <>
                {/* <Hidden mdUp>
                  {!IsFirstLogin ? (
                    <Avatar variant="rounded" sx={{
                      marginRight: theme.spacing(3),
                    }}>
                      <IconButton
                        onClick={() => {
                          setViewMobSearch(!viewMobSearch);
                        }}
                      >
                        <SearchOutlined
                          sx={{ color: theme.palette.primary.contrastText }}
                        />
                      </IconButton>
                    </Avatar>
                  ) : null}
                </Hidden> */}
                {!IsFirstLogin ? (
                  <Avatar
                    variant="rounded"
                    sx={{
                      marginRight: theme.spacing(3),
                    }}
                  >
                    {profile.RoleId === 1 && (
                      <IconButton
                        onClick={(e) => {
                          handleAddNewToggle();
                          if (openAddNew) {
                            setOpenAddNew(false);
                          } else {
                            addNewRef.current = e.currentTarget;
                          }
                        }}
                        ref={addNewRef}
                        aria-controls={open ? "menu-list-grow" : undefined}
                        aria-haspopup="true"
                      >
                        <AddBoxOutlined
                          sx={{
                            color: theme.palette.primary.contrastText,
                            fontSize: "1.8rem",
                          }}
                        />
                      </IconButton>
                    )}
                  </Avatar>
                ) : null}
                {profile.RoleId === 1 && (
                  <Popper
                    open={openAddNew}
                    anchorEl={addNewRef?.current}
                    role={undefined}
                    transition
                    disablePortal
                    placement="left-end"
                  >
                    {({ TransitionProps }) => (
                      <Grow
                        {...TransitionProps}
                        sx={{
                          transformOrigin: "center bottom",
                        }}
                      >
                        <Paper sx={{ width: "15rem" }}>
                          <ClickAwayListener onClickAway={handleAddNewClose}>
                            <MenuList
                              autoFocusItem={openAddNew}
                              id="menu-list-grow"
                              // onKeyDown={handleAddNewListKeyDown}
                            >
                              {addNewList?.map((element, index) => (
                                <MenuLink
                                  to={element.link}
                                  key={index}
                                  onClick={handleAddNewClose}
                                >
                                  <ListItemButton>
                                    <ListItemIcon
                                      sx={{
                                        color: theme.palette.text.secondary,
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {element.icon}
                                    </ListItemIcon>

                                    <ListItemText
                                      sx={{
                                        color: theme.palette.text.secondary,
                                        paddingLeft: theme.spacing(1),
                                      }}
                                    >
                                      <Typography variant="h4">
                                        {element.displayName}
                                      </Typography>
                                    </ListItemText>
                                  </ListItemButton>
                                </MenuLink>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                )}

                {/* <Avatar variant="rounded" sx={{
                      marginRight: theme.spacing(3),
                    }}>
                  <LocalPhoneOutlined />
                </Avatar> 
                <Avatar variant="rounded" sx={{
                      marginRight: theme.spacing(3),
                    }}>
                  <ChatBubbleOutlineOutlined />
                </Avatar> */}
                {/* <Link to="/settings/app-config/permission-management"> */}
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div>
                    <Avatar
                      variant="rounded"
                      sx={{
                        marginRight: theme.spacing(3),
                      }}
                    >
                      {profile.RoleId === 1 && (
                        <IconButton
                          // onClick={() =>
                          //   setNotificationclicked(!notificationClicked)
                          // }
                          onClick={handleClick}
                        >
                          <div>
                            <Badge
                              badgeContent={totalUnRead}
                              color="primary"
                              sx={{
                                "& .MuiBadge-badge": {
                                  top: 7,
                                  right: 6,
                                  width: 20,
                                  height: 20,
                                  fontSize: 10,
                                  borderRadius: "50%",
                                  padding: "0 4px",
                                },
                              }}
                            >
                              <NotificationsNoneOutlined
                                sx={{
                                  color: theme.palette.primary.contrastText,
                                  fontSize: "1.8rem",
                                }}
                              />
                            </Badge>
                          </div>
                        </IconButton>
                      )}
                    </Avatar>

                    {openNotification ? (
                      <NotificationsPopover
                        open={openNotification}
                        handleClose={handleClickAway}
                        // isCountChange={isCountChange}
                        setIsCountChange={setIsCountChange}
                      />
                    ) : null}
                  </div>
                </ClickAwayListener>

                {/* </Link> */}
              </>
            )}

            <Hidden mdDown>
              <Button
                variant="contained"
                color="primary"
                endIcon={<ExpandMoreIcon />}
                sx={{
                  paddingRight: theme.spacing(4),
                }}
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={(e) => {
                  handleAccountToggle();
                  if (openAccount) {
                    setOpenAccount(false);
                  } else {
                    anchorRef.current = e.currentTarget;
                  }
                }}
              >
                {`${t("buttons.hello")}, ${user?.displayName || ""}`}
              </Button>
            </Hidden>
            <IconButton
              onClick={(e) => {
                handleAccountToggle();
                if (openAccount) {
                  setOpenAccount(false);
                } else {
                  anchorRef.current = e.currentTarget;
                }
              }}
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
            >
              <Avatar
                alt={user?.displayName?.toUpperCase()}
                src={profileImg}
                sx={{
                  marginLeft: theme.spacing(-2.6),
                  [theme.breakpoints.down("md")]: {
                    marginLeft: theme.spacing(-1.6),
                  },
                }}
              />
            </IconButton>
            <Popper
              open={openAccount}
              anchorEl={anchorRef?.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  sx={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper sx={{ width: "14rem" }}>
                    <ClickAwayListener onClickAway={handleAccountClose}>
                      <MenuList
                        autoFocusItem={openAccount}
                        id="menu-list-grow"
                        // onKeyDown={handleAccountListKeyDown}
                      >
                        {settingsList?.map((element, index) => {
                          if (element.displayName !== t("labels.logOut")) {
                            return (
                              <MenuLink
                                to={element.link}
                                key={index}
                                onClick={handleAccountClose}
                              >
                                <ListItemButton>
                                  <ListItemIcon
                                    sx={{
                                      color: theme.palette.text.secondary,
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {element.icon}
                                  </ListItemIcon>

                                  <ListItemText
                                    sx={{
                                      color: theme.palette.text.secondary,
                                      paddingLeft: theme.spacing(1),
                                    }}
                                  >
                                    <Typography variant="h4">
                                      {element.displayName}
                                    </Typography>
                                  </ListItemText>
                                </ListItemButton>
                              </MenuLink>
                            );
                          } else {
                            return (
                              <ListItemButton
                                key={index}
                                onClick={() => {
                                  element.onClick();
                                }}
                              >
                                <ListItemIcon
                                  sx={{
                                    color: theme.palette.text.secondary,
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {element.icon}
                                </ListItemIcon>

                                <ListItemText
                                  sx={{
                                    color: theme.palette.text.secondary,
                                    paddingLeft: theme.spacing(1),
                                  }}
                                >
                                  <Typography variant="h4">
                                    {element.displayName}
                                  </Typography>
                                </ListItemText>
                              </ListItemButton>
                            );
                          }
                        })}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </Toolbar>
      </AppBar>
      {!IsFirstLogin ? (
        <>
          <Hidden smDown>
            <Drawer //  both drawers can be combined into one for performance
              variant="permanent"
              open={true}
              anchor="left"
              sx={{
                zIndex: theme.zIndex.appBar + 1,
                minWidth: theme.spacing(10),
                flexShrink: 0,
                ...(open
                  ? {
                      width: drawerWidth,
                      transition: theme.transitions.create("width", {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                      }),
                      overflowX: "hidden",
                    }
                  : {
                      transition: theme.transitions.create("width", {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                      }),
                      overflowX: "hidden",
                      width: theme.spacing(7) + 1,
                      [theme.breakpoints.up("sm")]: {
                        width: theme.spacing(8) + 1,
                      },
                    }),
                "& .MuiDrawer-paper": {
                  bgcolor: "#f0f0f0",
                  ...(open
                    ? {
                        width: drawerWidth,
                        transition: theme.transitions.create("width", {
                          easing: theme.transitions.easing.sharp,
                          duration: theme.transitions.duration.enteringScreen,
                        }),
                        overflowX: "hidden",
                      }
                    : {
                        transition: theme.transitions.create("width", {
                          easing: theme.transitions.easing.sharp,
                          duration: theme.transitions.duration.leavingScreen,
                        }),
                        overflowX: "hidden",
                        width: theme.spacing(7) + 1,
                        [theme.breakpoints.up("sm")]: {
                          width: theme.spacing(8) + 1,
                        },
                      }),
                },
              }}
            >
              <ToolbarContainer>
                {/* <Tooltip
                  title={
                    open ? (
                      <>
                        JobArmer
                        <CloseFullscreenIcon fontSize={"15px"} />
                      </>
                    ) : (
                      <>
                        JobArmer
                        <OpenInFullIcon fontSize={"15px"} />
                      </>
                    )
                  }
                  placement="right"
                > */}
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={handleDrawerOpenClose}
                  sx={{ cursor: "pointer" }}
                >
                  <>
                    <IconContainer>
                      <img
                        src={open ? Logo : LogoHead}
                        width={open ? "60%" : "50px"}
                        height="auto%"
                        alt="Logo"
                      />
                    </IconContainer>
                  </>
                </Box>
                {/* </Tooltip> */}
              </ToolbarContainer>
              <List
                sx={{
                  overflowY: "auto",
                  maxHeight: "calc(100vh - 64px)",
                }}
              >
                {menuItems?.map((element, index) => (
                  <RestrictedComponents
                    permission={element.permission}
                    key={element.link}
                  >
                    <MenuLink
                      to={element.link}
                      onClick={element.onClick}
                      ref={(node) => {
                        links.current[index] = node;
                        return node;
                      }}
                    >
                      <Tooltip
                        title={element.displayName}
                        placement="right"
                        key={element.name}
                      >
                        <ListItemButton
                          selected={match(element.name.toLowerCase())}
                          onClick={() => {
                            links.current[index].click();
                            setSelectedTab(element.name);
                          }}
                          aria-label={`Go to ${element.name}`}
                          sx={{
                            paddingTop: theme.spacing(2),
                            paddingBottom: theme.spacing(2),
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              color: match(element.name.toLowerCase())
                                ? theme.palette.transparent.contrastText
                                : theme.palette.text.secondary,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {element.icon}
                          </ListItemIcon>
                          {open && (
                            <ListItemText
                              sx={{
                                color: `${
                                  match(element.name.toLowerCase())
                                    ? theme.palette.transparent.contrastText
                                    : theme.palette.text.secondary
                                } !important`,
                                paddingLeft: theme.spacing(1),
                              }}
                            >
                              <Typography variant="h4">
                                {element.displayName}
                              </Typography>
                            </ListItemText>
                          )}
                        </ListItemButton>
                      </Tooltip>
                    </MenuLink>
                  </RestrictedComponents>
                ))}
              </List>
            </Drawer>
          </Hidden>

          <MobileViewDrawer
            menuItems={menuItems.map((element) => ({
              link: element.link,
              name: element.name,
              displayName: element.displayName,
              icon: element.icon,
              onClick: element.onClick,
              permission: element.permission,
            }))}
            open={isMobileOpen}
            selectedItem={selectedTab}
            setSelectedItem={setSelectedTab}
            onClose={closeMobileDrawer}
          />
        </>
      ) : null}
    </Fragment>
  );
}

export default Navbar;
