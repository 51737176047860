import { ArrowBack } from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { alpha, styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { getAllLabelConfiguration } from "src/api/clientApi";
import { getAllJobQuestions, getJobById, getJobDetails } from "src/api/jobApi";
import { getEmployeeBySkillSet } from "src/api/skillSetApi";
import { AuthContext } from "src/auth/AuthProvider";
import RequestSkeletonLoader from "src/components/Common/Loader/RequestSkeletonLoader";
import RecurringJobOverView from "./RecurringJobOverView";
import RecurringJobs from "./RecurringJobs";

const MainContainer = styled("main")(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2.5),
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(2),
  },
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(5),
  },
}));

// necessary for content to be below app bar
const ToolbarContainer = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

const DeleteConfirmation = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "10px",
  padding: "40px",
  flexDirection: "column",
  alignItems: "center",
  boxShadow: "10px",
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.textSecondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const ViewJob = ({ types, employees }) => {
  const auth = useContext(AuthContext);
  const profile = auth.getProfile();
  const token = auth.getAuthToken();

  const dateFormat = auth.getProfile().DateFormat;
  const timeFormat = auth.getProfile().TimeFormat;
  const maxEventCount = auth.getProfile().MaxEventCount;

  const theme = useTheme();
  const { t } = useTranslation();

  const { jobId } = useParams();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [isEditDetails, setIsEditDetails] = useState(false);

  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const isDownLg = useMediaQuery(theme.breakpoints.down("xl"));

  const [showButtons, setShowButtons] = useState(false);

  const toggleButtons = () => {
    setShowButtons(!showButtons);
  };

  const jobDetails = {
    JobId: 0,
    CustomerId: 0,
    QuoteId: null,
    RequestId: null,
    AddressId: 0,
    JobStatusId: 0,
    Title: "",
    Description: "",
    StartDate: moment().format("YYYY-MM-DDTHH:mm"),
    StartTime: moment().format("YYYY-MM-DDTHH:mm"),
    EndDate: moment().format("YYYY-MM-DDTHH:mm"),
    EndTime: moment().format("YYYY-MM-DDTHH:mm"),
    Instruction: "",
    IsScheduled: false,
    IsOneOff: true,
    Total: 0,
    Discount: 0,
    CustomerMessage: "",
    UpdatedBy: "",
    IsActive: true,
    IsDelete: false,
    Schedule: null,
    BillingFrequency: null,
    IsFixed: null,
    JobLineItems: [
      {
        Title: "",
        LineItemId: 0,
        FieldId: 0,
        LineItemTypeName: "",
        IsService: true,
        ProductId: 0,
        ServiceId: 0,
        Qty: 0,
        Description: "",
        UnitPrice: 0,
        MarkUp: 0,
        Total: 0,
        Tax: 0,
        UpdatedBy: "",
        IsActive: true,
        IsDelete: false,
        PermissionId: 0,
        RoleId: 0,
      },
    ],
    EditCustomFieldValues: [],
    ClientId: 0,
    Employees: [
      {
        EmployeeId: 0,
        ScheduleId: 0,
        IsCompleted: true,
        UpdatedBy: "",
        IsDelete: false,
      },
    ],
    visits: [
      {
        ClientId: 0,
        ScheduleId: 0,
        ScheduleType: 0,
        FieldId: 0,
        Title: "",
        Instructions: "",
        IsScheduled: true,
        StartDate: moment().format("YYYY-MM-DDTHH:mm"),
        EndDate: moment().format("YYYY-MM-DDTHH:mm"),
        IsAnytime: false,
        StartTime: moment().format("YYYY-MM-DDTHH:mm"),
        EndTime: moment().format("YYYY-MM-DDTHH:mm"),
        UpdatedBy: "",
        IsDelete: false,
        Employees: [
          {
            EmployeeId: 0,
            ScheduleId: 0,
            IsCompleted: true,
            UpdatedBy: "",
            IsDelete: false,
          },
        ],
        TypeName: null,
      },
    ],
    VisitDates: [moment().format("YYYY-MM-DDTHH:mm")],
    InvoiceDates: [moment().format("YYYY-MM-DDTHH:mm")],
    AddressLine1: "",
    CountryId: 0,
    ZipCode: "",
    StateId: 0,
    City: "",
    Latitude: 0,
    Longitude: 0,
    RoleId: 0,
    PermissionId: 0,
    NewEmployees: [],
    NewLineItems: [],
    NewProductLineItems: [],
    isRecurred: false,
    everyValue: "",
    everyType: "days",
    forValue: "",
    forType: "weeks",
    sameRange: false,
    QuestionResponses: [
      {
        QuestionText: "string",
        QuestionTypeName: "string",
        AnswersList: [
          {
            AnswerId: 0,
            AnswerText: "string",
            QuestionId: 0,
          },
        ],
      },
    ],
    IsFilterBySkillSet: false,
    Type: "",
    Overdue: 0,
    Completed: 0,
    Upcoming: 0,
  };

  const [jobData, setJobData] = useState(jobDetails);
  const roleId = profile.RoleId;
  const getJob = async () => {
    setJobLoading(true);
    let job = await getJobById(
      token,
      jobId,
      moment().format("YYYY-MM-DDTHH:mm:ss"),
      roleId
    );

    if (job?.STATUS_CODE !== "200") {
      toast.error(t("toasts.jobDetailsLoadingFailed"));
      setJobLoading(false);
    } else {
      setJobData({
        ...job?.DATA,
        JobLineItems: job?.DATA?.JobLineItems?.filter(
          (item) => item.Total !== 0
        ),
      });
      setValue("NewLineItems", []);
      setValue("NewProductLineItems", []);
      setJobLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: jobData,
    mode: "onChange",
    // resolver: yupResolver(validationSchema),
  });
  watch();

  const [jobLoading, setJobLoading] = useState(true);

  useEffect(() => {
    jobId > 0 && getJob();
  }, [jobId, loading]);

  /** ---------------------------- Recurring Job ------------------------------ */

  const [values, setValues] = useState(0);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  function a11yPropsType(indexType) {
    return {
      id: `simple-tab-${indexType}`,
      "aria-controls": `simple-tabpanel-${indexType}`,
    };
  }

  const [showQuestions, setShowQuestions] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [QuestionAnswers, setQuestionAnswers] = useState(false);
  const questionResponses = getValues("QuestionResponses");
  const [editedResponses, setEditedResponses] = useState([]);

  const onChangeJobData = (e) => {
    setValue(e.target.name, e.target.value);
    //   // setJobDetails((jobDetails) => ({
    //   //   ...jobDetails,
    //   //   [e.target.name]: e.target.value,
    //   // }));
  };

  const handleEditDetails = () => {
    setValue(`NewLineItems`, []);
    setValue(`NewProductLineItems`, []);
    setValue(`NewEmployees`, []);
    // setIsCrossedOut([]);
    // setEmployeeIds([]);
    setIsEditDetails(!isEditDetails);
    // setEditedResponses([...questionResponses]);
  };

  const onSaveDetails = () => {
    setIsEditDetails(false);
  };

  const handleCancelButtonClick = () => {
    setIsEditDetails(false);
    // setJobData({});
    reset(jobData);
    toast.info("Edit Cancelled!");
    setValue(`NewLineItems`, []);
    setValue(`NewProductLineItems`, []);
    setValue(`NewEmployees`, []);
    // setIsCrossedOut([]);
    // setEmployeeIds([]);
    setRecError("");
    // setValue("IsScheduled", false);
    // setIsScheduled(false);
  };

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleDeleteButtonClick = () => {
    toast.error("Job Deleted.");
    setValue("IsDelete", true);
  };

  const handleConvertToInvoice = () => {
    setTimeout(() => {
      navigate("/invoices/new-invoice", {
        state: {
          isConvert: true,
          isFromJob: !!parseInt(jobId) ? parseInt(jobId) : 0,
        },
      });
    }, 1000);
  };

  /*==========================================================================*/

  const [showDateTimeFields, setShowDateTimeFields] = useState(false);
  const [recurringLoading, setRecurringLoading] = useState(false);
  const [recError, setRecError] = useState("");

  const handleShowDateTimeFields = (date = null) => {
    if (
      getValues("everyValue") === "" ||
      getValues("forValue") === "" ||
      !getValues("everyValue") ||
      !getValues("forValue")
    ) {
      return;
    }
    setRecurringLoading(true);
    const dates = [];
    const everyValue = parseInt(getValues("everyValue"));
    const forValue = parseInt(getValues("forValue"));

    let currentDate = !date ? moment() : moment(date);

    const lastDate = (!date ? moment() : moment(date)).add(
      forValue,
      getValues("forType")
    );

    const startdate = moment(currentDate);
    let val = 0;

    let enddate = moment(currentDate);
    while (currentDate <= lastDate) {
      if (enddate <= lastDate && val < maxEventCount) {
        val++;
        dates.push({
          StartDate: currentDate.format("YYYY-MM-DDTHH:mm"),
          EndDate: currentDate.format("YYYY-MM-DDTHH:mm"),
          StartTime: moment().format("YYYY-MM-DDTHH:mm"),
          EndTime: moment().format("YYYY-MM-DDTHH:mm"),
        });
      }
      if (val < maxEventCount) {
        setRecError("");
      } else {
        val === maxEventCount
          ? setRecError(
              `You have reached the maximum job limit of ${maxEventCount}!`
            )
          : setRecError("");
        break;
      }
      enddate = moment(currentDate).add(everyValue - 1, getValues("everyType"));
      currentDate.add(everyValue, getValues("everyType"));
    }

    setValue("visits", dates);

    if (dates.length === 0) {
      toast.error(t("toasts.pleaseChooseValidDataForRecurringJobs"));
    }
    // setJobCount(dates.length);
    setRecurringLoading(false);

    setShowDateTimeFields(true);
  };

  const handleSameTimeRange = (val) => {
    // if (jobCount < 2) return;
    const prevData = getValues("visits");

    const startTime = prevData?.[0]?.StartTime;
    const endTime = prevData?.[0]?.EndTime;

    const updatedDates = prevData.map((date, index) => {
      if (index !== 0) {
        return { ...date, StartTime: startTime, EndTime: endTime };
      }
      return date;
    });
    setValue("visits", updatedDates);
  };

  useEffect(() => {
    if (!getValues("sameRange")) return;
    handleSameTimeRange(getValues("sameRange"));
  }, [
    getValues("visits")?.[0]?.StartTime,
    getValues("visits")?.[0]?.EndTime,
    getValues("sameRange"),
  ]);

  const handleSelectDateChange = (date, index) => {
    const selectedDate = moment(date);
    setValue(
      `visits.${index}.EndDate`,
      selectedDate.format("YYYY-MM-DDTHH:mm")
    );

    if (
      moment(getValues(`visits.${index + 1}.StartDate`)).isSameOrBefore(
        selectedDate
      )
    ) {
      const updatedDates = getValues("visits").map((date, i) => {
        if (index < i) {
          selectedDate.add(getValues("everyValue"), getValues("everyType"));
          return {
            ...date,
            StartDate: selectedDate.format("YYYY-MM-DDTHH:mm"),
            EndDate: selectedDate.format("YYYY-MM-DDTHH:mm"),
          };
        } else if (index === i) {
          return {
            ...date,
            EndDate: selectedDate.format("YYYY-MM-DDTHH:mm"),
          };
        }
        return date;
      });
      setValue("visits", updatedDates);
    }
  };

  const handleRemoveClickOne = (index) => {
    const prevData = getValues("visits");

    const updatedVisitDates = prevData
      .map((item, ind) => {
        if (item.ScheduleId > 0 && index === ind) {
          return { ...item, IsDelete: true };
        } else if ((item.ScheduleId < 1 || !item.ScheduleId) && index === ind) {
          return null;
        }
        return item;
      })
      .filter(Boolean);
    setValue("visits", updatedVisitDates);
    // setJobCount((count) => count - 1);
    setRecError("");
  };

  const CheckDateTime = (index) => {
    const startDate = moment(getValues(`visits.${index}.StartDate`));
    const endDate = moment(getValues(`visits.${index}.EndDate`));
    const startTime = moment(getValues(`visits.${index}.StartTime`));
    const endTime = moment(getValues(`visits.${index}.EndTime`));

    const sameDate = startDate.isSame(endDate, "day");
    const startTimeBeforeEndTime = startTime.isSameOrBefore(endTime, "hour");

    return [sameDate, startTimeBeforeEndTime];
  };

  {
    /** -----------------------LineItemComponent----------------------- */
  }

  const [jobLineItemsLength, setJobLineItemsLength] = useState(1);

  useEffect(() => {
    setJobLineItemsLength(getValues(`JobLineItems`)?.length);
  }, [getValues(`JobLineItems`)]);

  const [isHovering, setIsHovering] = useState(false);

  {
    /** -----------------------LineItemComponentFilter------------------------ */
  }

  /*=============================QUESTIONNAIRES=============================================*/
  const [groupedResponses, setGroupedResponses] = useState([]);

  useEffect(() => {
    if (showQuestions) {
      getAllJobQuestions(token, {
        RequestId: getValues("RequestId"),
        QuoteId: getValues("QuoteId"),
      })
        .then((res) => {
          setGroupedResponses(res?.DATA);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [showQuestions]);

  useEffect(() => {
    var arr = [];
    questions.map((q, i) => {
      arr.push({
        SurveyId: q.SurveyId,
        QuestionId: q.QuestionId,
        QuestionText: q.QuestionText,
        AnswerId: null,
        AnswerText: null,
      });
    });

    setValue("QuestionnaireResponses", arr);
  }, [QuestionAnswers]);

  /** ----------------------Skill Set ------------------------------ */
  const [employeeList, setEmployeeList] = useState(null);

  const fetchEmployees = (dataRecord) => {
    getEmployeeBySkillSet(token, dataRecord)
      .then((response) => {
        setEmployeeList(response?.DATA);
      })
      .catch((e) => {
        setEmployeeList([]);
      });
  };

  useEffect(() => {
    let serviceLineItems =
      [...getValues(`JobLineItems`), ...getValues("NewLineItems")]
        ?.filter((obj) => parseInt(obj?.ServiceId) > 0 && !obj.IsDelete)
        ?.map((item) => String(item.ServiceId)) || [];

    if (
      getValues("IsScheduled") &&
      getValues("IsFilterBySkillSet") &&
      !getValues("isRecurred") &&
      getValues(`visits.${0}.StartDate`) !== "" &&
      getValues(`visits.${0}.StartTime`) !== "" &&
      getValues(`visits.${0}.EndDate`) !== "" &&
      getValues(`visits.${0}.EndTime`) !== "" &&
      serviceLineItems?.length > 0
    ) {
      fetchEmployees({
        ServiceIdList: String(serviceLineItems.join(",")),
        StartDate: moment(
          moment(getValues(`visits.${0}.StartDate`)).format("YYYY-MM-DD") +
            " " +
            moment(getValues(`visits.${0}.StartTime`)).format("HH:mm:ss")
        ).format("YYYY-MM-DDTHH:mm:ss"),
        EndDate: moment(
          moment(getValues(`visits.${0}.EndDate`)).format("YYYY-MM-DD") +
            " " +
            moment(getValues(`visits.${0}.EndTime`)).format("HH:mm:ss")
        ).format("YYYY-MM-DDTHH:mm:ss"),
        IsFilterBySkillSet: true,
      });
    } else if (getValues("IsScheduled") && getValues("isRecurred")) {
      setEmployeeList(employees);
    } else if (
      getValues("IsScheduled") &&
      !getValues("IsFilterBySkillSet") &&
      getValues(`visits.${0}.StartDate`) !== "" &&
      getValues(`visits.${0}.StartTime`) !== "" &&
      getValues(`visits.${0}.EndDate`) !== "" &&
      getValues(`visits.${0}.EndTime`) !== ""
    ) {
      fetchEmployees({
        ServiceIdList: "",
        StartDate: moment(
          moment(getValues(`visits.${0}.StartDate`)).format("YYYY-MM-DD") +
            " " +
            moment(getValues(`visits.${0}.StartTime`)).format("HH:mm:ss")
        ).format("YYYY-MM-DDTHH:mm:ss"),
        EndDate: moment(
          moment(getValues(`visits.${0}.EndDate`)).format("YYYY-MM-DD") +
            " " +
            moment(getValues(`visits.${0}.EndTime`)).format("HH:mm:ss")
        ).format("YYYY-MM-DDTHH:mm:ss"),
        IsFilterBySkillSet: false,
      });
    } else {
      setEmployeeList(null);
    }
  }, [
    watch("IsFilterBySkillSet"),
    watch(`visits.${0}.StartDate`),
    watch(`visits.${0}.StartTime`),
    watch(`visits.${0}.EndDate`),
    watch(`visits.${0}.EndTime`),
    watch("isRecurred"),
    watch("IsScheduled"),
    // serviceTotal,
  ]);

  /**------------------------- Progress Bar ---------------------------------------*/

  /** ------------------------------ Percentage ----------------------------------- */

  const [progress, setProgress] = useState(0);
  const typeCounts = jobData?.visits?.reduce(
    (acc, visit) => {
      const typeId = visit.TypeId || 80;
      const matchingType = types.find((type) => type.TypeId === typeId);

      if (matchingType) {
        const typeName = matchingType.TypeName;
        acc[typeName] = (acc[typeName] || 0) + 1;
      }

      return acc;
    },
    { Completed: 20, Ongoing: 20, Overdue: 0, Upcoming: 0 }
  );

  const totalEvents = jobData?.visits?.length;

  // const [TypeStatus, setTypeStatus] = useState(null);

  const formatWithLeadingZero = (value) => {
    return value < 10 ? "0".concat(value) : value;
  };

  const OverAllValue =
    jobData?.Completed + jobData?.Overdue + (jobData?.Ongoing || 0);

  const CurrencySymbol = auth.getProfile().CurrencySymbol;

  {
    /** ---------------------------- Schedule Duration Total ------------------------------ */
  }

  const [jobOverDetails, setJobOverDetails] = useState({});

  const fetchScheduleDetails = async () => {
    setLoading(true);
    await getJobDetails(token, jobId)
      .then((response) => {
        if (response?.STATUS_CODE !== "200") {
          toast.error(t("toasts.detailsLoadingFailed"));
          setLoading(false);
        } else {
          setJobOverDetails(response?.DATA);
          setLoading(false);
        }
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchScheduleDetails();
  }, []);

  /** ------------------------------ Job Duration ----------------------------------- */

  const getJobDuration = () => {
    let durationInDays = jobOverDetails?.TotalDuration;

    if (durationInDays >= 30) {
      return `${Math.floor(durationInDays / 30)} ${
        Math.floor(durationInDays / 30) === 1 ? "month" : "months"
      }`;
    } else {
      return `${durationInDays} ${durationInDays === 1 ? "day" : "days"}`;
    }
  };

  /** ---------------------------------- Recurring Job Labels ----------------------  */

  const [eventName, setEventName] = useState("Events");

  useEffect(() => {
    fetchLabelConfiguration();
  }, []);

  const fetchLabelConfiguration = async () => {
    await getAllLabelConfiguration(token, {
      ModuleName: `Jobs`,
      PortionName: `Schedule`,
      DefaultName: "Event",
      Page: 1,
      limit: 10,
    }).then((response) => {
      if (Array.isArray(response?.DATA)) {
        const foundEvent = response?.DATA?.find(
          (name) => name.DefaultName === "Event"
        );
        if (foundEvent) {
          setEventName(foundEvent.CurrentName);
        }
      }
    });
    // setLoading(false);
  };

  /** ---------------------------------- Setting Search Params ----------------------  */

  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();

  useEffect(() => {
    if (location.search.includes("type=table")) {
      setValues(1);
    } else {
      setValues(0);
    }
  }, []);
  const OverAllText = t(`labels.overAll`);
  const truncatedText =
    t(`labels.overAll`).length > 7
      ? `${t(`labels.overAll`).slice(0, 7)}...`
      : t(`labels.overAll`);

  /** ----------------------------------------------------------------------- */

  return (
    <MainContainer>
      <ToolbarContainer />

      {loading || jobLoading ? (
        <RequestSkeletonLoader />
      ) : (
        <>
          <Grid container spacing={1}>
            <>
              <Grid item container mt={-2}>
                <Grid item xs={12} md={12} lg={5}>
                  <Stack direction={"row"} spacing={2}>
                    <IconButton
                      sx={{
                        color: theme.palette.primary.contrastText,
                        backgroundColor: theme.palette.secondary.light,
                        borderRadius: "25%",
                        ":hover": {
                          backgroundColor: theme.palette.primary.main,
                        },
                      }}
                      onClick={() => navigate(`/jobs`)}
                    >
                      <ArrowBack />
                    </IconButton>
                    <Box
                      sx={{
                        flexGrow: 1,
                      }}
                    >
                      <Tabs
                        value={values}
                        onChange={(e) => handleChange(e)}
                        variant={isDownMd ? "scrollable" : "fullWidth"}
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        selectionFollowsFocus={true}
                      >
                        <Tab
                          label={
                            <Typography
                              color="textSecondary"
                              variant="h5"
                              sx={{
                                fontWeight: theme.typography.fontWeightBold,
                                textTransform: "uppercase",
                              }}
                            >
                              {t(`tabs.jobDetails`)}
                            </Typography>
                          }
                          value={0}
                          {...a11yPropsType(0)}
                          onClick={() => {
                            setValues(0);
                            setSearchParams({ type: "summary" });
                          }}
                          sx={{
                            boxShadow: 2,
                            width: isDownMd ? "100%" : "",
                            background:
                              values === 0 ? theme.palette.primary.main : "",
                            border: theme.components.Box.border,
                          }}
                        />
                        <Tab
                          label={
                            <Typography
                              color="textSecondary"
                              variant="h5"
                              sx={{
                                fontWeight: theme.typography.fontWeightBold,
                                textTransform: "uppercase",
                              }}
                            >
                              {eventName}
                            </Typography>
                          }
                          value={1}
                          {...a11yPropsType(1)}
                          onClick={() => {
                            setValues(1);
                            setSearchParams({ type: "table" });
                          }}
                          sx={{
                            boxShadow: 2,
                            width: isDownMd ? "100%" : "",
                            background:
                              values === 1 ? theme.palette.primary.main : "",
                            border: theme.components.Box.border,
                          }}
                        />
                      </Tabs>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12} lg={1} />
                {profile.RoleId === 1 && (
                  <Grid item xs={12} md={12} lg={6}>
                    <Box
                      sx={{
                        width: "100%",
                        border: `0px solid ${theme.palette.primary.main}`,
                        p: 1,
                        borderRadius: 3,
                        // display: "flex",
                      }}
                    >
                      <Stack
                        direction={isDownMd ? "column" : "row"}
                        sx={{ display: "flex" }}
                      >
                        <Grid item container xs={12} md={5}>
                          {/* Overall Progress */}
                          <Grid
                            item
                            xs={6}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Box
                              sx={{
                                position: "relative",
                                display: "inline-flex",
                              }}
                            >
                              <CircularProgress
                                variant="determinate"
                                value={(OverAllValue / totalEvents) * 100 || 0}
                                // value={90}
                                size={100}
                                // disableShrink
                                thickness={6}
                              />
                              <Box
                                sx={{
                                  top: 0,
                                  left: 0,
                                  bottom: 0,
                                  right: 0,
                                  position: "absolute",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  component="div"
                                  color="textSecondary"
                                  fontWeight={theme.typography.fontWeightBold}
                                >
                                  {`${Math.round(
                                    (OverAllValue / totalEvents) * 100 || 0
                                  )}%`}
                                </Typography>
                                <Tooltip title={OverAllText}>
                                  <Typography
                                    variant="body2"
                                    fontWeight={theme.typography.fontWeightBold}
                                    color="textSecondary"
                                  >
                                    {truncatedText}
                                  </Typography>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Grid>

                          {/* Finished Progress */}
                          <Grid
                            item
                            xs={6}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Box
                              sx={{
                                position: "relative",
                                display: "inline-flex",
                              }}
                            >
                              <CircularProgress
                                variant="determinate"
                                value={
                                  (jobData?.Completed / totalEvents) * 100 || 0
                                }
                                // value={80}
                                size={100}
                                thickness={6}
                                color="success"
                              />
                              <Box
                                sx={{
                                  top: 0,
                                  left: 0,
                                  bottom: 0,
                                  right: 0,
                                  position: "absolute",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  component="div"
                                  color="textSecondary"
                                  fontWeight={theme.typography.fontWeightBold}
                                >
                                  {`${Math.round(
                                    (jobData?.Completed / totalEvents) * 100 ||
                                      0
                                  )}%`}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  fontWeight={theme.typography.fontWeightBold}
                                  color="textSecondary"
                                >
                                  {t(`labels.finished`)}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid item container xs={12} md={7}>
                          {/* Overdue and Upcoming Jobs */}
                          <Grid
                            item
                            xs={6}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Stack
                              direction={isDownLg ? "column" : "row"}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                minWidth: "100%",
                              }}
                            >
                              <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                                sx={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                  textAlign: "center",
                                }}
                              >
                                <Typography
                                  variant="body3"
                                  color="textSecondary"
                                  fontWeight={theme.typography.fontWeightBold}
                                >
                                  {t(`labels.overDueJobs`)}
                                </Typography>
                                <Box
                                  sx={{
                                    border: `1px solid ${theme.palette.primary.main}`,
                                    p: 1,
                                    borderRadius: 3,
                                    width: `60%`,
                                    margin: `auto`,
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                    component="div"
                                    color="textSecondary"
                                    fontWeight={theme.typography.fontWeightBold}
                                  >
                                    {formatWithLeadingZero(jobData?.Overdue) ||
                                      0}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                                sx={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                  textAlign: "center",
                                }}
                              >
                                <Typography
                                  variant="body3"
                                  color="textSecondary"
                                  fontWeight={theme.typography.fontWeightBold}
                                >
                                  {t(`labels.upcomingJobs`)}
                                </Typography>
                                <Box
                                  sx={{
                                    border: `1px solid ${theme.palette.primary.main}`,
                                    p: 1,
                                    borderRadius: 3,
                                    width: `60%`,
                                    margin: `auto`,
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                    component="div"
                                    color="textSecondary"
                                    fontWeight={theme.typography.fontWeightBold}
                                  >
                                    {formatWithLeadingZero(jobData?.Upcoming) ||
                                      0}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Stack>
                          </Grid>

                          {/* Total Revenue */}
                          <Grid
                            item
                            xs={6}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Box
                              sx={{
                                border: `1px solid ${theme.palette.primary.main}`,
                                p: 2,
                                borderRadius: 4,
                                minWidth: "120px",
                                textAlign: "center",
                              }}
                            >
                              <AccessTimeIcon
                                fontSize="large"
                                color="primary"
                              />
                              <Typography
                                variant="h6"
                                fontWeight={theme.typography.fontWeightBold}
                                sx={{
                                  textAlign: "center",
                                  textTransform: "uppercase",
                                }}
                              >
                                {t(`labels.totalDuration`)}
                              </Typography>
                              <Typography
                                variant="h6"
                                fontWeight={theme.typography.fontWeightBold}
                                sx={{ textAlign: "center" }}
                              >
                                {getJobDuration()}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Stack>
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} mt={profile.RoleId === 1 ? -7 : 0}>
                {values === 0 && (
                  <RecurringJobOverView
                    jobDetails={jobData}
                    eventName={eventName}
                  />
                )}
              </Grid>
              <Grid item xs={12} mt={profile.RoleId === 1 ? -7 : 0}>
                {values === 1 && (
                  <RecurringJobs
                    jobData={jobData}
                    loading={loading}
                    setLoading={setLoading}
                    eventName={eventName}
                    detailsApi={fetchScheduleDetails}
                  />
                )}
              </Grid>
            </>
          </Grid>
        </>
      )}
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    // customers: state.customer.dataRecords,
    // services: state.service.services,
    // products: state.product.products,
    // taxes: state.tax.taxes,
    employees: state.employee.employees,
    types: state.type.types,
  };
};

export default connect(mapStateToProps, null)(ViewJob);
