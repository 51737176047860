import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import Menu from "@mui/material/Menu";
import Select from "@mui/material/Select";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { useLocation, useSearchParams } from "react-router-dom";
import { AuthContext } from "src/auth/AuthProvider";
import { fDateShort } from "src/components/Controls/formatUtils";

const CustomToolbar = (props, formControl) => {
  const {
    menuItems,
    printDetails,
    setPrintDetails,
    filterList,
    vals,
    setVals,
    placeholderValue,
    setIsArrow,
  } = props;

  const auth = useContext(AuthContext);
  const profile = auth.getProfile();
  const theme = useTheme();
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const handleReset = () => {
    const newObj = {};
    Object.keys(printDetails).forEach((key) => (newObj[key] = false));
    setPrintDetails(newObj);
    setVals({});
    setSearchParams(new URLSearchParams());
    setIsArrow({ SortBy: null, IsAsc: null });
  };

  const [Parameter, setSearchTerm] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(false);
  const [dataTab, setDataTab] = React.useState(false);
  const [columnVisibility, setColumnVisibility] = useState({});
  const open = Boolean(anchorEl, dataTab);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const handleClse = () => {
    setDataTab(false);
  };

  const isMediumOrLargeScreen = useMediaQuery("(min-width: 960px)"); // Customize the breakpoint as needed

  const handleC = (event) => {
    const option = event.target.value;
    setPrintDetails({ ...printDetails, [option]: !printDetails[option] });
    if (!event.target.checked) {
      setSearchParams((prevParams) => {
        prevParams.delete(event.target.name);
        return prevParams;
      });

      setVals((data) => ({ ...data, [event.target.name]: null }));
    } else {
      searchParams.set(option, option === "status" ? 0 : "");
    }
  };

  const location = useLocation();
  React.useEffect(() => {
    setVals((data) => {
      const obj = {};
      for (let [key, value] of searchParams) {
        if (key != "Due" && key != "IssueDate") {
          obj[key] = value;
        } else {
          obj["TodayDateTime"] = moment().format("YYYY-MM-DDTHH:mm:ss");
          obj[key] = value;
        }
      }
      return obj;
    });

    setPrintDetails((data) => {
      const keyData = Array.from(searchParams.keys());
      const pKeys = Object.keys(data);
      for (let key of pKeys) {
        data[key] = keyData.includes(key);
      }
      return data;
    });
  }, [location]);

  const forbiddenChars = /[^a-zA-Z0-9\s&/\-,\.]/g;

  const handleFilterChange = (key, value) => {
    const filteredValue = value.toString().replace(forbiddenChars, "");

    setSearchParams((prevParams) => {
      let values = prevParams.get(key);
      if (!values) {
        prevParams.set(key, filteredValue);
      } else {
        if (!filteredValue) {
          prevParams.delete(key);
        } else {
          prevParams.set(key, filteredValue);
        }
      }
      return prevParams;
    });
  };

  const handleChnge = (e) => {
    handleFilterChange(e.target.name, e.target.value);
  };

  const ITEM_HEIGHT = 38;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const options = ["JobRequest", "Status", "Date"];

  const [showColumnViewer, setShowColumnViewer] = useState(false);

  const handleColumnViewerClick = () => {
    setShowColumnViewer(!showColumnViewer);
  };

  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  const OverAllText = placeholderValue;
  const truncatedText =
    placeholderValue.length > 7
      ? placeholderValue.slice(0, 13)
      : placeholderValue;

  return (
    <>
      <Grid item container spacing={0} xs={12} md={12}>
        <Box
          sx={{
            fullwidth: "true",
            width: "100%",
            borderRadius: 4,
            backgroundColor: " #F6F6F6",
          }}
        >
          <>
            <Grid item container md={12}>
              {!isDownMd ? (
                <>
                  <Grid
                    item
                    container
                    xs={12}
                    md={7}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Tooltip title={OverAllText}>
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                          sx: { height: "40px" },
                        }}
                        name="Parameter"
                        id="outlined-basic"
                        type="search"
                        placeholder={
                          !isDownMd ? placeholderValue : truncatedText
                        }
                        onChange={handleChnge}
                        value={vals?.Parameter || ""}
                        sx={{
                          height: "40px",
                          width: "100%",
                          margin: "15px",
                          borderRadius: "15px",
                          backgroundColor: "white",
                          borderColor: "1px  rgb(242, 242, 243)",
                        }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      {profile?.RoleId === 1 && (
                        <Grid item xs={5} md={4}>
                          <Button
                            onClick={handleClick}
                            aria-controls={open ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            variant="contained"
                            sx={{
                              boxSizing: "border-box",
                              background: "white",
                              border: "1px solid #F4A540",
                              "&:hover": {
                                background: "white",
                              },
                              color: theme.palette.primary.main,
                              ml: 1,
                              borderRadius: "14px",
                              height: "40px",
                              width: "100%",
                              margin: "15px",
                            }}
                          >
                            <FilterAltOutlinedIcon sx={{ color: "#F4A540" }} />
                            {t("buttons.filters")}
                          </Button>
                        </Grid>
                      )}

                      <Grid item xs={5} md={4}>
                        <Button
                          onClick={handleReset}
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          variant="contained"
                          sx={{
                            boxSizing: "border-box",
                            background: "white",
                            border: "1px solid #F4A540",
                            "&:hover": {
                              background: "white",
                            },
                            color: theme.palette.primary.main,
                            ml: 1,
                            borderRadius: "14px",
                            height: "40px",
                            width: "100%",
                            margin: "15px",
                          }}
                        >
                          <ReplayOutlinedIcon sx={{ color: "#F4A540" }} />
                          {t("buttons.reset")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : isDownSm ? (
                <Stack
                  direction={"row"}
                  alignItems="center"
                  justifyContent="space-evenly"
                  margin={"5px"}
                >
                  <Grid
                    item
                    container
                    xs={12}
                    md={7}
                    alignItems="center"
                    justifyContent="space-evenly"
                  >
                    <Tooltip title={OverAllText}>
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                          sx: { height: "40px", width: "100%" },
                        }}
                        name="Parameter"
                        id="outlined-basic"
                        type="search"
                        placeholder={
                          !isDownMd ? placeholderValue : truncatedText
                        }
                        onChange={handleChnge}
                        value={vals?.Parameter || ""}
                        sx={{
                          height: "40px",
                          width: "100%",
                          margin: { xs: "5px", sm: "15px" },
                          borderRadius: "15px",
                          backgroundColor: "white",
                          borderColor: "1px  rgb(242, 242, 243)",
                        }}
                      />
                    </Tooltip>
                  </Grid>

                  <Grid item>
                    <Button
                      onClick={handleClick}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      variant="contained"
                      sx={{
                        boxSizing: "border-box",
                        background: "white",
                        border: "1px solid #F4A540",
                        "&:hover": {
                          background: "white",
                        },
                        color: theme.palette.primary.main,
                        ml: 1,
                        borderRadius: "14px",
                        height: "40px",
                        width: { sm: "80%", md: "100%" },
                        margin: { xs: "5px", sm: "15px" },
                      }}
                    >
                      <FilterAltOutlinedIcon sx={{ color: "#F4A540" }} />
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      onClick={handleReset}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      variant="contained"
                      sx={{
                        boxSizing: "border-box",
                        background: "white",
                        border: "1px solid #F4A540",
                        "&:hover": {
                          background: "white",
                        },
                        color: theme.palette.primary.main,
                        ml: 1,
                        borderRadius: "14px",
                        height: "40px",
                        width: { sm: "80%", md: "100%" },
                        margin: { xs: "5px", sm: "15px" },
                      }}
                    >
                      <ReplayOutlinedIcon sx={{ color: "#F4A540" }} />
                    </Button>
                  </Grid>
                </Stack>
              ) : (
                <>
                  <Grid
                    item
                    container
                    xs={12}
                    md={7}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Tooltip title={OverAllText}>
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                          sx: { height: "40px" },
                        }}
                        name="Parameter"
                        id="outlined-basic"
                        type="search"
                        placeholder={
                          !isDownMd ? placeholderValue : truncatedText
                        }
                        onChange={handleChnge}
                        value={vals?.Parameter || ""}
                        sx={{
                          height: "40px",
                          width: "100%",
                          margin: "10px",
                          borderRadius: "15px",
                          backgroundColor: "white",
                          borderColor: "1px  rgb(242, 242, 243)",
                        }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={5} md={4}>
                        <Button
                          onClick={handleClick}
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          variant="contained"
                          sx={{
                            boxSizing: "border-box",
                            background: "white",
                            border: "1px solid #F4A540",
                            "&:hover": {
                              background: "white",
                            },
                            color: theme.palette.primary.main,
                            ml: 1,
                            borderRadius: "14px",
                            height: "40px",
                            width: "100%",
                            marginTop: "10px",
                            margin: "2px",
                          }}
                        >
                          <FilterAltOutlinedIcon sx={{ color: "#F4A540" }} />
                          {t("buttons.filters")}
                        </Button>
                      </Grid>

                      <Grid item xs={5} md={4}>
                        <Button
                          onClick={handleReset}
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          variant="contained"
                          sx={{
                            boxSizing: "border-box",
                            background: "white",
                            border: "1px solid #F4A540",
                            "&:hover": {
                              background: "white",
                            },
                            color: theme.palette.primary.main,
                            ml: 1,
                            borderRadius: "14px",
                            height: "40px",
                            width: "100%",
                            margin: "10px",
                          }}
                        >
                          <ReplayOutlinedIcon sx={{ color: "#F4A540" }} />
                          {t("buttons.reset")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onChange={handleC}
              PaperProps={{
                elevation: 0,
                sx: {
                  borderRadius: "15px",
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {menuItems?.map((menuItem, index) => (
                <MenuItem key={index}>
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        checked={menuItem.checked} //|| !!vals?.[menuItem.name]
                        onChange={handleC}
                        name={menuItem.name}
                      />
                    }
                    label={menuItem.label}
                    value={menuItem.name}
                  />
                </MenuItem>
              ))}
            </Menu>

            {/* <Menu
              anchorEl={dataTab}
              open={Boolean(dataTab)}
              onClose={handleClse}
              onChange={handleC}
              PaperProps={{
                elevation: 0,
                sx: {
                  borderRadius: "15px",
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {headCells.map((headCell, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={columnVisibility[headCell.name]}
                      onChange={() =>
                        handleColumnVisibilityChange(headCell.name)
                      }
                    />
                  }
                  label={headCell.label}
                  label={headCell.label}
                />
              ))}
            </Menu> */}

            <Stack
              spacing={2}
              direction={isMediumOrLargeScreen ? "row" : "column"}
            >
              {filterList?.map((formControl, index) => {
                // let isLabelTrue = Object.keys(printDetails).includes(formControl.label);

                if (
                  printDetails[formControl.name] ||
                  !!vals?.[formControl.name]
                ) {
                  return (
                    <Grid key={index}>
                      <FormControl
                        sx={{
                          m: 2,
                          width: 250,
                          backgroundColor: "#ffffff",
                          borderColor: " #F6F6F6",
                        }}
                      >
                        <InputLabel
                          id={`demo-multiple-checkbox-label-${index}`}
                        >
                          {formControl.label}
                        </InputLabel>
                        {formControl.name === "amount" ? (
                          <TextField
                            type="number" // You can change the input type as needed
                            value={vals[formControl.name]}
                            name={formControl.name}
                            onChange={handleChnge}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        ) : (
                          <Select
                            labelId={`demo-multiple-checkbox-label-${index}`}
                            id={`demo-multiple-checkbox-${index}`}
                            value={vals[formControl.name] || ``}
                            name={formControl.name}
                            onChange={handleChnge}
                            input={<OutlinedInput label={formControl.label} />}
                            // renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                          >
                            {formControl?.list?.map((option) => (
                              <MenuItem
                                key={option.TypeId}
                                value={option.TypeId}
                              >
                                {option.TypeName}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </FormControl>
                    </Grid>
                  );
                }
              })}

              {(vals?.Due === "DUE_CUSTOM" ||
                vals?.Issue === "ISSUE_CUSTOM") && (
                <React.Fragment>
                  <Grid
                    item
                    lg={filterList.length > 4 ? 3 : 10 / filterList.length}
                    md={filterList.length > 3 ? 4 : 10 / filterList.length}
                    sm={filterList.length > 2 ? 6 : 10 / filterList.length}
                    xs={12}
                    marginTop={2}
                  >
                    <DesktopDatePicker
                      inputFormat="yyyy-MM-DD"
                      label={t("labels.startDate")}
                      value={vals["Start"] || ""}
                      name={"Start"}
                      onChange={(e) => {
                        setVals(({ ...values }) => {
                          values["Start"] = fDateShort(e);
                          return values;
                        });
                      }}
                      renderInput={(params) => (
                        <TextField size="small" fullWidth {...params} />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={filterList.length > 4 ? 3 : 10 / filterList.length}
                    md={filterList.length > 3 ? 4 : 10 / filterList.length}
                    sm={filterList.length > 2 ? 6 : 10 / filterList.length}
                    xs={12}
                    marginTop={2}
                  >
                    <DesktopDatePicker
                      inputFormat="yyyy-MM-DD"
                      label={t("labels.endDate")}
                      value={vals["End"] || ""}
                      name={"End"}
                      onChange={(e) => {
                        setVals(({ ...values }) => {
                          values["End"] = fDateShort(e);
                          return values;
                        });
                      }}
                      renderInput={(params) => (
                        <TextField size="small" fullWidth {...params} />
                      )}
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Stack>
          </>
        </Box>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    types: state.type.types,
  };
};
export default connect(mapStateToProps, null)(CustomToolbar);
