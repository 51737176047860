import React, { useState, useEffect, Fragment } from "react";
import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "src/redux/Store";
import { FinishSetup } from "src/redux/Slices/Auth";
import { useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

const MainContainer = styled("main")(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(3),
  display: "flex",
  justifyContent: "center",
  marginTop: "90px",
}));

// necessary for content to be below app bar
const ToolbarContainer = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

const steps = ["", "add-product", "add-service", "settings"];

const FirstLoginLayout = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const matchMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleFinish = () => {
    dispatch(FinishSetup());
    navigate("/dashboard");
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    navigate(`/initial-setup/${steps[activeStep]}`);
  }, [activeStep]);

  return (
    <MainContainer>
      <ToolbarContainer />
      <Card sx={{ width: matchMdDown ? "90%" : "60%", p: matchMdDown ? 0 : 5 }}>
        <CardContent>
          <Stepper activeStep={activeStep} sx={{ mb: 3 }}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps} />
                  {/* {label}</StepLabel> */}
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re redirected to dashboard
              </Typography>
            </Fragment>
          ) : (
            <Fragment>
              <Outlet>{children}</Outlet>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  {t("buttons.back")}
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {activeStep < steps.length - 1 ? (
                  <Button onClick={handleNext}>{t("buttons.next")}</Button>
                ) : (
                  <Button onClick={handleFinish}>{t("buttons.finish")}</Button>
                )}
              </Box>
            </Fragment>
          )}
        </CardContent>
      </Card>
    </MainContainer>
  );
};

export default FirstLoginLayout;
