import { Delete } from "@mui/icons-material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import {
  Alert,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CursorType } from "@syncfusion/ej2-react-pdfviewer";
import {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { AuthContext } from "src/auth/AuthProvider";
import RestrictedComponents from "../Permission/RestrictedComponents";
import Permissions from "src/constants/Permissions";
import { usePermission } from "src/context/PermissionContext";

const LineItemsComponent = ({
  theme,
  CurrencySymbol,
  isEditDetails,
  getValues,
  register,
  setValue,
  lineItems,
  details = [],
  newServiceLineItems = [],
  newProductLineItems = [],
  newPackageLineItems = [],
  toggleCrossedOut,
  services = [],
  handleRemoveClick,
  products = [],
  packages = [],
  addServiceRecord,
  handleRemoveProductClick,
  addProductRecord,
  isPackage,
  loading,
  addPackageRecord,
  handleRemovePackageClick,
  row = [],
}) => {
  const isDownMd = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const profile = auth.getProfile();
  const tableRef = useRef(null);
  const [scroll, setScroll] = useState(true);
  const scrollToBottom = () => {
    setScroll((val) => !val);
  };

  const isServiceCountOne =
    getValues(lineItems)?.filter((item) => item.ServiceId > 0)?.length +
      getValues(newServiceLineItems)?.filter((item) => item.ServiceId > 0)
        ?.length +
      getValues(lineItems)?.filter((item) => item.ServiceId > 0)?.length +
      getValues(newPackageLineItems)?.filter(
        (item) =>
          item.LineItemPackageDetail?.some(
            (items) => items.ServiceId > 0 || items.ProductId > 0
          ) > 0
      )?.length ===
    1;

  const isPakcageItemsCount =
    getValues(lineItems)?.filter((item) => !item?.IsDelete)?.length +
    getValues(newServiceLineItems)?.filter((item) => !item?.IsDelete)?.length +
    getValues(newProductLineItems)?.filter((item) => !item?.IsDelete)?.length;

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTop = tableRef.current.scrollHeight;
    }
  }, [scroll]);

  /**--------------------------------------------------------------------------------------------- */

  const isServiceCount = getValues(lineItems)?.filter(
    (item) => !item?.IsDelete && item.ServiceId > 0
  )?.length;
  const isProductCount = getValues(lineItems)?.filter(
    (item) => !item?.IsDelete && item.ProductId > 0
  )?.length;
  const isPackageCount = getValues(lineItems)?.filter(
    (item) => !item?.IsDelete && item.PackageId > 0
  )?.length;

  /**------------------------------------------------------------------------------------------- */

  const [ErrorService, setErrorService] = useState(false);

  const [ErrorProduct, setErrorProduct] = useState(false);

  const [ErrorPackage, setErrorPackage] = useState(false);

  const trueValuesCountServices =
    services
      ?.map(
        (option) =>
          !!(
            getValues(newServiceLineItems)?.find(
              (p) => !p.IsDelete && p.ServiceId === option.ServiceId
            ) ||
            getValues(lineItems)?.find(
              (p) => !p.IsDelete && p.ServiceId === option.ServiceId
            )
          )
      )
      ?.filter((value) => value === true)?.length || 0;

  const trueValuesCountProducts =
    products
      ?.map(
        (option) =>
          !!(
            getValues(newProductLineItems)?.find(
              (p) => !p.IsDelete && p.ProductId === option.ProductId
            ) ||
            getValues(lineItems)?.find(
              (p) => !p.IsDelete && p.ProductId === option.ProductId
            )
          )
      )
      ?.filter((value) => value === true)?.length || 0;

  const trueValuesCountPackages =
    packages
      ?.map(
        (option) =>
          !!(
            getValues(newPackageLineItems)?.find(
              (p) => !p.IsDelete && p.PackageId === option.PackageId
            ) ||
            getValues(lineItems)?.find(
              (p) => !p.IsDelete && p.PackageId === option.PackageId
            )
          )
      )
      ?.filter((value) => value === true)?.length || 0;

  /** ---------------------------------- useEffect for the max count ----------------------- */

  const useErrorEffect = (
    trueValuesCount,
    itemsLength,
    isEditDetails,
    setError
  ) => {
    useEffect(() => {
      if (trueValuesCount === itemsLength && itemsLength > 0 && isEditDetails) {
        setError(true);
      } else {
        setError(false);
        setError(false);
      }
    }, [trueValuesCount, itemsLength, isEditDetails, setError]);
  };

  // Usage of the useErrorEffect for services
  useErrorEffect(
    trueValuesCountServices,
    services?.length,
    isEditDetails,
    setErrorService
  );

  // Usage of the useErrorEffect for products
  useErrorEffect(
    trueValuesCountProducts,
    products?.length,
    isEditDetails,
    setErrorProduct
  );

  // Usage of the useErrorEffect for packages
  useErrorEffect(
    trueValuesCountPackages,
    packages?.length,
    isEditDetails,
    setErrorPackage
  );

  /** ---------------------------------- Table Headers ----------------------- */

  const permission = usePermission([
    Permissions.ADD_JOB,
    Permissions.ADD_QUOTE,
    Permissions.ADD_INVOICE,
  ]);
  const tableColumns = [
    { columnName: t("tableHeadings.service/product") },
    { columnName: t("tableHeadings.type") },
    { columnName: t("tableHeadings.qty/hour") },
    ...(permission
      ? [
          { columnName: t("tableHeadings.unitPrice") },
          ...(isPackage ? [{ columnName: t("tableHeadings.markUp") }] : []),
          { columnName: t("tableHeadings.price") },
        ]
      : []),
  ];

  /** ---------------------------------- Service Product Package changes ----------------------- */

  const handleServiceChange = (itemsArrays, item, e, selectedObj, index) => {
    setValue(`${itemsArrays}[${index}].ServiceId`, e.target.value);
    setValue(
      `${itemsArrays}[${index}].UnitPrice`,
      parseFloat(selectedObj?.Price).toFixed(2) || 0
    );
    setValue(`${itemsArrays}[${index}].MarkUp`, 0);

    setValue(
      `${itemsArrays}[${index}].Total`,
      parseFloat(getValues(`${itemsArrays}[${index}].Qty`)) *
        parseFloat(getValues(`${itemsArrays}[${index}].UnitPrice`)) || 0
    );

    if (isPackage) {
      setValue(
        `${itemsArrays}[${index}].LineItemName`,
        selectedObj?.ServiceName || ""
      );
      setValue(
        `${itemsArrays}[${index}].LineItemDescription`,
        selectedObj?.ServiceDescription || ""
      );
    } else {
      setValue(
        `${itemsArrays}[${index}].Title`,
        selectedObj?.ServiceName || ""
      );
      setValue(
        `${itemsArrays}[${index}].Description`,
        selectedObj?.ServiceDescription || ""
      );

      const previousTaxLineDetails =
        details
          ?.filter(
            (obj) =>
              parseInt(obj.LineItemId) === parseInt(item.LineItemId) &&
              obj?.LineItemTaxDetail?.length > 0
          )[0]
          ?.LineItemTaxDetail?.map((obj) => ({
            ...obj,
            IsDelete: true,
          })) || [];

      getValues(itemsArrays)[index].DeleteLineItemTaxDetail =
        previousTaxLineDetails;
    }
  };

  const handleProductChange = (itemsArrays, item, e, selectedObj, index) => {
    const cost =
      parseFloat(selectedObj?.UnitPrice) + parseFloat(selectedObj?.MarkUp) ||
      0.0;

    setValue(`${itemsArrays}.[${index}].ProductId`, e.target.value);

    const unitPrice = !isPackage
      ? parseFloat(selectedObj?.UnitPrice) + parseFloat(selectedObj?.MarkUp) ||
        0
      : parseFloat(selectedObj?.UnitPrice) || 0;

    setValue(`${itemsArrays}.[${index}].UnitPrice`, unitPrice);

    setValue(
      `${itemsArrays}.[${index}].MarkUp`,
      parseFloat(selectedObj?.MarkUp) || 0
    );

    setValue(
      `${itemsArrays}.[${index}].MarkUpShow`,
      parseFloat(selectedObj?.MarkUp) || 0
    );

    setValue(
      `${itemsArrays}.[${index}].Total`,
      parseFloat(getValues(`${itemsArrays}.[${index}].Qty`) * cost) || 0
    );

    if (isPackage) {
      setValue(
        `${itemsArrays}[${index}].LineItemName`,
        selectedObj?.Name || ""
      );
      setValue(
        `${itemsArrays}.[${index}].LineItemDescription`,
        selectedObj?.Description || ""
      );
    } else {
      setValue(`${itemsArrays}[${index}].Title`, selectedObj?.Name || "");
      setValue(
        `${itemsArrays}.[${index}].Description`,
        selectedObj?.Description || ""
      );

      const previousTaxLineDetails =
        details
          ?.filter(
            (obj) =>
              parseInt(obj.LineItemId) === parseInt(item.LineItemId) &&
              obj?.LineItemTaxDetail?.length > 0
          )[0]
          ?.LineItemTaxDetail?.map((obj) => ({
            ...obj,
            IsDelete: true,
          })) || [];

      getValues(itemsArrays)[index].DeleteLineItemTaxDetail =
        previousTaxLineDetails;
    }
  };

  const handlePackageChange = (item, e, selectedObj, index) => {
    setValue(`${lineItems}.[${index}].PackageId`, e.target.value);
    setValue(
      `${lineItems}.[${index}].UnitPrice`,
      parseFloat(selectedObj?.PackagePrice) || 0
    );
    setValue(`${lineItems}.[${index}].MarkUp`, 0);

    const totalCost =
      parseFloat(selectedObj?.PackagePrice || 0).toFixed(2) *
      getValues(`${lineItems}.[${index}].Qty`);
    setValue(`${lineItems}.[${index}].Total`, parseFloat(totalCost) || 0);

    setValue(`${lineItems}[${index}].Title`, selectedObj?.PackageName || "");
    setValue(
      `${lineItems}.[${index}].Description`,
      selectedObj?.PackageDescription || ""
    );

    const combinedLineItemPackageDetail = [
      ...(selectedObj?.PackageLineItems || []),
    ];

    const previousLineDetails =
      details?.filter(
        (obj) =>
          parseInt(obj.LineItemId) === parseInt(item.LineItemId) &&
          obj?.LineItemPackageDetail?.length > 0
      )[0]?.LineItemPackageDetail || [];

    if (previousLineDetails?.length > 0) {
      previousLineDetails?.forEach((obj) => {
        const previousObj = {
          ...obj,
          IsDelete: true,
        };
        combinedLineItemPackageDetail.push(previousObj);
      });
    }

    setValue(
      `${lineItems}.[${index}].LineItemPackageDetail`,
      combinedLineItemPackageDetail || []
    );

    const previousTaxLineDetails =
      details
        ?.filter(
          (obj) =>
            parseInt(obj.LineItemId) === parseInt(item.LineItemId) &&
            obj?.LineItemTaxDetail?.length > 0
        )[0]
        ?.LineItemTaxDetail?.map((obj) => ({
          ...obj,
          IsDelete: true,
        })) || [];

    getValues(lineItems)[index].DeleteLineItemTaxDetail =
      previousTaxLineDetails;
  };

  /** ---------------------------------- Qty changes ----------------------- */

  const handleQuantityChange = (itemsArrays, e, index) => {
    let value = e.target.value
      .replace(/[^\d.]/g, "") // Remove any characters that are not digits or a decimal point
      .replace(/\.(\d*)\./g, ".$1") // Allow only one decimal point
      .replace(/(\.\d\d)\d+$/, "$1"); // Remove any additional decimal points;

    const regex = /^\d*(\.\d{0,2})?$/;
    // const regex =  /^\d+\.?\d{1,2}$/;
    const regex_2 = /^\d+\.0{0,2}$/;

    if (!regex.test(value)) {
      return; // Invalid input, handle error or validation as needed
    } else if (regex_2.test(value)) {
      return;
    }

    value = parseFloat(value);

    // Parse as float for ServiceId and as int otherwise
    const parsedValue =
      getValues(`${itemsArrays}.[${index}].ServiceId`) > 0
        ? parseFloat(value) || 0
        : parseInt(value, 10) || 0;

    // Ensure positive value
    if (parsedValue < 0) {
      setValue(`${itemsArrays}.[${index}].Qty`, 0);
    } else {
      setValue(`${itemsArrays}.[${index}].Qty`, parsedValue);
    }

    const newQty = parsedValue;
    const unitPrice =
      parseFloat(getValues(`${itemsArrays}.[${index}].UnitPrice`)) || 0;
    const markUp =
      parseFloat(getValues(`${itemsArrays}.[${index}].MarkUp`)) || 0;
    if (isPackage) {
      setValue(
        `${itemsArrays}.[${index}].Total`,
        parseFloat((unitPrice + markUp) * value)
      );
    } else {
      setValue(
        `${itemsArrays}.[${index}].Total`,
        parseFloat(unitPrice * value)
      );
    }

    if (isPackage) {
      const selectedObj =
        products?.find(
          (option) =>
            option.ProductId ===
            getValues(`${itemsArrays}.[${index}].ProductId`)
        ) || {};

      setValue(`${itemsArrays}.[${index}].MarkUp`, selectedObj?.MarkUp || 0);
    }
  };

  /** ---------------------------------- UnitPrice changes ----------------------- */

  const handleUnitPriceChange = (itemsArrays, e, index) => {
    let value = e.target.value
      .replace(/[^\d.?\d{1,2}$]/g, "") // Remove any characters that are not digits or a decimal point
      .replace(/(\.\d{2})\d+$/, "$1") // Allow only up to two decimal places
      .replace(/^\\d+\\.\\d+$/);

    // Allow only up to two decimal places

    const regex = /^\d*\.?\d{1,2}$/g;

    if (!regex.test(value)) {
      // Invalid input, handle error or validation as needed
      return;
    }

    // Format the value to two decimal places
    value = parseFloat(value);
    // Update the state or form library value
    setValue(`${itemsArrays}.[${index}].UnitPrice`, parseFloat(value));

    const markUp = parseFloat(
      getValues(`${itemsArrays}.[${index}].MarkUp`) || 0
    );

    const qty = parseFloat(getValues(`${itemsArrays}.[${index}].Qty`) || 0);

    // Example of validation or error handling
    if (parseFloat(value) <= 0) {
      if (!toast.isActive("unitPriceError")) {
        toast.error(t("toasts.unitPriceMustBeGreaterThan0"), {
          toastId: "unitPriceError",
        });
      }
    } else {
      toast.dismiss("unitPriceError");
    }
    if (isPackage) {
      setValue(
        `${itemsArrays}[${index}].Total`,
        parseFloat(((value + markUp) * qty).toFixed(2))
      );
    } else {
      setValue(
        `${itemsArrays}[${index}].Total`,
        parseFloat((value * qty).toFixed(2))
      );
    }
  };

  /** ---------------------------------- MarkUp changes ----------------------- */

  const handleMarkUpChange = (itemsArrays, e, index) => {
    const userInput = e.target.value;

    let numericValue = userInput.replace(/[^\d.]/g, ""); // Remove any characters that are not digits or a decimal point
    //.replace(/\.(\d*)\./g, ".$1") // Allow only up to two decimal places
    //.replace(/(\.\d\d)\d+$/, "$1"); // Remove any additional decimal points

    setValue(`${itemsArrays}.[${index}].MarkUp`, numericValue);

    if (numericValue < 0 || numericValue === null) {
      setValue(`${itemsArrays}.[${index}].MarkUp`, 0);
    }

    const newMarkUp = parseFloat(numericValue) || 0;
    const unitPrice =
      parseFloat(getValues(`${itemsArrays}.[${index}].UnitPrice`)) || 0;
    const qty = parseFloat(getValues(`${itemsArrays}.[${index}].Qty`)) || 0;

    setValue(`${itemsArrays}.[${index}].Total`, (unitPrice + newMarkUp) * qty);
  };
  /** ---------------------------------- Add Record Button ----------------------- */

  const AddRecordButton = ({ label, onClick }) => (
    <>
      <IconButton
        variant="contained"
        sx={{
          color: theme.palette.primary.main,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "white",
          },
        }}
        onClick={onClick}
      >
        <AddBoxIcon />
      </IconButton>
      <Typography
        color="textSecondary"
        variant="h4"
        fontWeight={theme.typography.fontWeightBold}
        sx={{
          marginLeft: "8px",
          marginRight: "5px",
        }}
      >
        {label}
      </Typography>
    </>
  );

  const renderMenuItem = (
    selectArray,
    itemsList,
    valueKey,
    labelKey,
    disableItem
  ) =>
    selectArray?.map((option) => {
      return (
        <MenuItem
          key={option[valueKey]}
          value={option[valueKey]}
          disabled={
            !!(
              getValues(itemsList)?.find(
                (p) => !p.IsDelete && p[option[valueKey]] === option[valueKey]
              ) ||
              getValues(disableItem)?.find(
                (p) => !p.IsDelete && p[option[valueKey]] === option[valueKey]
              )
            )
          }
        >
          {option[labelKey]}
        </MenuItem>
      );
    });

  const getTypeOptions = () => {
    const lineItemsValues = getValues(lineItems) || [];
    const newProductLineItemsValues = getValues(newProductLineItems) || [];
    const newServiceLineItemsValues = getValues(newServiceLineItems) || [];
    const newPackageLineItemsValues = getValues(newPackageLineItems) || [];

    const item =
      lineItemsValues[0] ||
      newProductLineItemsValues[0] ||
      newServiceLineItemsValues[0] ||
      newPackageLineItemsValues[0]; // Assuming the first item is sufficient to determine the type

    if (item?.ServiceId > 0 || (isPackage && item?.IsService)) {
      return {
        options: services,
        valueKey: "ServiceId",
        labelKey: "ServiceName",
      };
    } else if (item?.ProductId > 0 || (isPackage && !item?.IsService)) {
      return {
        options: products,
        valueKey: "ProductId",
        labelKey: "Name",
      };
    } else {
      return {
        options: packages,
        valueKey: "PackageId",
        labelKey: "PackageName",
      };
    }
  };
  const disabled = true;
  const { options, valueKey, labelKey } = getTypeOptions();

  const CustomEditableTableCell = ({
    item,
    isPackage,
    isEditDetails,
    t,
    theme,
  }) => {
    const cellValue = () => {
      if (item.ServiceId > 0 || item.IsService) {
        return t("labels.service");
      } else if ((item.PackageId > 0 || item.IsPackage) && !item.ProductId) {
        return t("labels.package"); //here !item.ProductId addes for prevent package lineitems isses
      } else if (item.ProductId > 0 || (!item.IsService && !item.IsPackage)) {
        return t("labels.product");
      }
    };

    const inputProps = {
      style: {
        textAlign: isDownMd ? "right" : "center",
        // marginRight: isDownMd ? "10px" : "0px",
        color: theme.palette.primary.contrastText,
        fontWeight: theme.typography.fontWeightMedium,
        disabled,
        padding: "10px",
      },
    };

    const inputStyle = {
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightMedium,
    };

    return (
      <>
        <Tooltip
          title={
            item &&
            item.LineItemPackageDetail &&
            item.LineItemPackageDetail.filter((obj) => !obj.IsDelete).length >
              0 ? (
              <RestrictedComponents
                permission={[
                  Permissions.ADD_JOB,
                  Permissions.ADD_QUOTE,
                  Permissions.ADD_INVOICE,
                ]}
              >
                <Typography>
                  {item.LineItemPackageDetail.filter(
                    (obj) => !obj.IsDelete
                  ).map((obj, key) => (
                    <span key={key}>
                      {obj.LineItemName} - {obj.ProductId > 0 && "("}
                      {obj.UnitPrice}
                      {obj.ProductId > 0 && " + " + obj.MarkUp}
                      {obj.ProductId > 0 && ")"}
                      {" * "}
                      {obj.Qty} = {obj.Total}
                      <br />
                    </span>
                  ))}
                </Typography>
              </RestrictedComponents>
            ) : null
          }
          placement="right"
        >
          <TableCell
            scope={isDownMd && "row"}
            align="right"
            sx={{
              width: { xs: 120 },
              minWidth: 60,
              maxWidth: 90,
            }}
          >
            <TextField
              id="outlined-basic"
              type="text"
              variant={isEditDetails ? "outlined" : "standard"}
              size="small"
              fullWidth
              value={cellValue()}
              disabled={!isEditDetails}
              InputProps={{
                disableUnderline: true,
                inputProps: inputProps,
                style: inputStyle,
              }}
            />
          </TableCell>
        </Tooltip>
      </>
    );
  };

  const CustomSelectTableCell = ({
    item,
    index,
    nameProp,
    registerProp,
    onChangeProps,
    TitleProps,
    MenuItemProps,
  }) => {
    return (
      <TableCell
        scope="row"
        style={{ minWidth: 120, maxWidth: 140 }}
        marginRight={{ xs: "10px" }}
      >
        <Stack direction="row">
          {isEditDetails ? (
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-required-label" size="small">
                {(() => {
                  if (item.ServiceId > 0 || item.IsService) {
                    return t("labels.service");
                  } else if (
                    (item.PackageId > 0 || item.IsPackage) &&
                    !item.ProductId
                  ) {
                    return t("labels.package");
                  } else if (
                    item.ProductId > 0 ||
                    (!item.IsService && !item.IsPackage)
                  ) {
                    return t("labels.product");
                  }
                })()}
              </InputLabel>
              <Select
                name={nameProp}
                {...register(registerProp)}
                label={
                  item.ServiceId > 0 || item.IsService
                    ? t("labels.selectService")
                    : item.ProductId > 0 || (!item.IsService && !item.IsPackage)
                    ? t("labels.selectProduct")
                    : t("labels.selectPackage")
                }
                variant="outlined"
                size="small"
                value={getValues(registerProp) || ""}
                onChange={onChangeProps}
                title={TitleProps}
              >
                {MenuItemProps}
              </Select>
            </FormControl>
          ) : (
            <TextField
              variant={"standard"}
              size="small"
              value={
                item.ServiceId > 0 || (isPackage && item.IsService)
                  ? services?.find(
                      (option) =>
                        option.ServiceId ===
                        getValues(`${lineItems}.[${index}].ServiceId`)
                    )?.ServiceName || t("textFieldLables.serviceName")
                  : item.ProductId > 0 || (isPackage && !item.IsService)
                  ? products?.find(
                      (option) =>
                        option.ProductId ===
                        getValues(`${lineItems}.${index}.ProductId`)
                    )?.Name || t("textFieldLables.productName")
                  : packages?.find(
                      (option) =>
                        option.PackageId ===
                        getValues(`${lineItems}.${index}.PackageId`)
                    )?.PackageName || t("textFieldLables.packageName")
              }
              title={
                item.ServiceId > 0 || (isPackage && item.IsService)
                  ? services?.find(
                      (option) =>
                        option.ServiceId ===
                        getValues(`${lineItems}.[${index}].ServiceId`)
                    )?.ServiceName || t("textFieldLables.serviceName")
                  : item.ProductId > 0 || (isPackage && !item.IsService)
                  ? products?.find(
                      (option) =>
                        option.ProductId ===
                        getValues(`${lineItems}.${index}.ProductId`)
                    )?.Name || t("textFieldLables.productName")
                  : packages?.find(
                      (option) =>
                        option.PackageId ===
                        getValues(`${lineItems}.${index}.PackageId`)
                    )?.PackageName || t("textFieldLables.packageName")
              }
              InputProps={{
                inputProps: {
                  style: {
                    textAlign: isDownMd ? "right" : "left",
                    color: theme.palette.primary.contrastText,
                    fontWeight: theme.typography.fontWeightMedium,
                    padding: "10PX",
                  },
                },
                disableUnderline: true,
              }}
              sx={{
                pointerEvents: isEditDetails ? "auto" : "initial",
                color: theme.palette.primary.contrastText,
                fontWeight: theme.typography.fontWeightMedium,
              }}
            />
          )}
        </Stack>
      </TableCell>
    );
  };

  return (
    <>
      <Grid container item xs={12} mt={1} alignItems="center">
        {!isDownMd ? (
          <Grid item xs={12} md={isEditDetails ? 4 : 8}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h4"
              fontWeight={theme.typography.fontWeightBold}
            >
              {t("subHeadings.ServiceproductDetails")}
            </Typography>
          </Grid>
        ) : null}

        {isEditDetails ? (
          <Grid item xs={12} md={8}>
            <Stack
              direction={"row"}
              md={6}
              justifyContent="flex-end"
              alignItems="center"
            >
              {services?.length - isServiceCount !==
                getValues(newServiceLineItems).filter((item) => !item.IsDelete)
                  ?.length && (
                <AddRecordButton
                  label={t("buttons.service")}
                  onClick={(event) => {
                    event.preventDefault();
                    addServiceRecord();
                    scrollToBottom();
                  }}
                />
              )}

              {products?.length - isProductCount !==
                getValues(newProductLineItems).filter((item) => !item.IsDelete)
                  ?.length && (
                <AddRecordButton
                  label={t("buttons.product")}
                  onClick={() => {
                    addProductRecord();
                    scrollToBottom();
                  }}
                />
              )}
              {packages?.length - isPackageCount !==
                getValues(newPackageLineItems).filter((item) => !item.IsDelete)
                  ?.length &&
                !isPackage && (
                  <AddRecordButton
                    label={t("buttons.package")}
                    onClick={() => {
                      addPackageRecord();
                      scrollToBottom();
                    }}
                  />
                )}
            </Stack>
          </Grid>
        ) : null}
      </Grid>

      {!isDownMd ? (
        <TableContainer
          component={Paper}
          sx={{
            minWidth: 350,
            maxHeight: isDownMd ? 155 : 300,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)",
            borderRadius: "none",
          }}
          ref={tableRef}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead sx={{ minWidth: 650 }}>
              <TableRow>
                {tableColumns.map((column, index) => (
                  <TableCell align={index === 0 ? "left" : "center"}>
                    {column.columnName}
                  </TableCell>
                ))}
                {isEditDetails && <TableCell align="center" />}
              </TableRow>
            </TableHead>

            <TableBody>
              {getValues(lineItems)?.map(
                (item, index) =>
                  !item.IsDelete && (
                    <TableRow key={index}>
                      <>
                        <CustomSelectTableCell
                          item={item}
                          index={index}
                          nameProp={`${lineItems}.${index}.${valueKey}`}
                          registerProp={
                            (item.ServiceId !== null && item.ServiceId > 0) ||
                            (isPackage && item.IsService)
                              ? `${lineItems}.${index}.ServiceId`
                              : (item.ProductId !== null &&
                                  item.ProductId > 0) ||
                                (isPackage && !item.IsService)
                              ? `${lineItems}.${index}.ProductId`
                              : `${lineItems}.${index}.PackageId`
                          }
                          onChangeProps={(e) =>
                            item.ServiceId > 0 || (isPackage && item.IsService)
                              ? handleServiceChange(
                                  lineItems,
                                  item,
                                  e,
                                  services?.find(
                                    (option) =>
                                      option.ServiceId === e.target.value
                                  ),
                                  index
                                )
                              : item.ProductId > 0 ||
                                (isPackage && !item.IsService)
                              ? handleProductChange(
                                  lineItems,
                                  item,
                                  e,
                                  products?.find(
                                    (option) =>
                                      option.ProductId === e.target.value
                                  ),
                                  index
                                )
                              : handlePackageChange(
                                  item,
                                  e,
                                  packages?.find(
                                    (option) =>
                                      option.PackageId === e.target.value
                                  ),
                                  index
                                )
                          }
                          TitleProps={
                            item.ServiceId > 0 || (isPackage && item.IsService)
                              ? services?.find(
                                  (option) =>
                                    option.ServiceId ===
                                    getValues(
                                      `${lineItems}.[${index}].ServiceId`
                                    )
                                )?.ServiceName ||
                                t("textFieldLables.serviceName")
                              : item.ProductId > 0 ||
                                (isPackage && !item.IsService)
                              ? products?.find(
                                  (option) =>
                                    option.ProductId ===
                                    getValues(`${lineItems}.${index}.ProductId`)
                                )?.Name || t("textFieldLables.productName")
                              : packages?.find(
                                  (option) =>
                                    option.PackageId ===
                                    getValues(`${lineItems}.${index}.PackageId`)
                                )?.PackageName ||
                                t("textFieldLables.packageName")
                          }
                          MenuItemProps={renderMenuItem(
                            item.ServiceId > 0 || (isPackage && item.IsService)
                              ? services
                              : item.ProductId > 0 ||
                                (isPackage && item.IsProduct)
                              ? products
                              : packages,
                            lineItems,
                            item.ServiceId > 0 || (isPackage && item.IsService)
                              ? `ServiceId`
                              : item.ProductId > 0 ||
                                (isPackage && item.IsProduct)
                              ? `ProductId`
                              : `PackageId`,
                            item.ServiceId > 0 || (isPackage && item.IsService)
                              ? `ServiceName`
                              : item.ProductId > 0 ||
                                (isPackage && item.IsProduct)
                              ? `Name`
                              : `PackageName`,
                            item.ServiceId > 0 || (isPackage && item.IsService)
                              ? newServiceLineItems
                              : newProductLineItems
                          )}
                        />

                        <CustomEditableTableCell
                          style={{ minWidth: 40, maxWidth: 60 }}
                          item={item}
                          isPackage={isPackage}
                          isEditDetails={isEditDetails}
                          t={t}
                          theme={theme}
                        />

                        <TableCell
                          align="right"
                          style={{ minWidth: 40, maxWidth: 60 }}
                        >
                          <TextField
                            required
                            name={`${lineItems}.[${index}].Qty`}
                            {...register(`${lineItems}.[${index}].Qty`)}
                            id="outlined-basic"
                            placeholder={t("textFieldLables.enterQuantity")}
                            variant={isEditDetails ? "outlined" : "standard"}
                            size="small"
                            // type="number"
                            fullWidth
                            InputProps={{
                              inputProps: {
                                style: {
                                  textAlign: isEditDetails ? "right" : "center",
                                  color: theme.palette.primary.contrastText,
                                  fontWeight: theme.typography.fontWeightMedium,
                                },
                              },
                              style: {
                                color: theme.palette.primary.contrastText,
                                fontWeight: theme.typography.fontWeightMedium,
                              },
                              disableUnderline: true,
                            }}
                            sx={{
                              pointerEvents: isEditDetails ? "auto" : "none",
                            }}
                            onChange={(e) =>
                              handleQuantityChange(lineItems, e, index)
                            }
                          />
                        </TableCell>
                        <RestrictedComponents
                          permission={[
                            Permissions.ADD_JOB,
                            Permissions.ADD_QUOTE,
                            Permissions.ADD_INVOICE,
                          ]}
                        >
                          <TableCell
                            style={{ minWidth: 80, maxWidth: 140 }}
                            align="right"
                          >
                            <TextField
                              required
                              name={`${lineItems}.[${index}].UnitPrice`}
                              {...register(`${lineItems}.[${index}].UnitPrice`)}
                              id="outlined-basic"
                              placeholder={t("textFieldLables.enterUnitPrice")}
                              variant={isEditDetails ? "outlined" : "standard"}
                              size="small"
                              fullWidth
                              InputProps={{
                                inputProps: {
                                  style: {
                                    textAlign: isEditDetails
                                      ? "right"
                                      : "center",
                                    color: theme.palette.primary.contrastText,
                                    fontWeight:
                                      theme.typography.fontWeightMedium,
                                  },
                                },
                                style: {
                                  color: theme.palette.primary.contrastText,
                                  fontWeight: theme.typography.fontWeightMedium,
                                },
                                disableUnderline: true,
                              }}
                              sx={{
                                pointerEvents: isEditDetails ? "auto" : "none",
                              }}
                              onChange={(e) =>
                                handleUnitPriceChange(lineItems, e, index)
                              }
                            />
                          </TableCell>
                        </RestrictedComponents>
                        {isPackage && (
                          <RestrictedComponents
                            permission={[
                              Permissions.ADD_JOB,
                              Permissions.ADD_QUOTE,
                              Permissions.ADD_INVOICE,
                            ]}
                          >
                            <TableCell
                              style={{ minWidth: 60, maxWidth: 120 }}
                              align="right"
                            >
                              {item?.IsService ? (
                                <TextField
                                  id="outlined-basic"
                                  placeholder={t("textFieldLables.enterMarkUp")}
                                  value={"-"}
                                  variant={
                                    isEditDetails ? "outlined" : "standard"
                                  }
                                  size="small"
                                  fullWidth
                                  disabled={true}
                                  InputProps={{
                                    inputProps: {
                                      style: {
                                        textAlign: "center",
                                        color:
                                          theme.palette.primary.contrastText,
                                        fontWeight:
                                          theme.typography.fontWeightMedium,
                                      },
                                    },
                                    style: {
                                      color: theme.palette.primary.contrastText,
                                      fontWeight:
                                        theme.typography.fontWeightMedium,
                                    },
                                    disableUnderline: true,
                                  }}
                                  sx={{
                                    pointerEvents: "none",
                                  }}
                                />
                              ) : (
                                <TextField
                                  id="outlined-basic"
                                  placeholder={t("textFieldLables.enterMarkUp")}
                                  variant={
                                    isEditDetails ? "outlined" : "standard"
                                  }
                                  defaultValue={getValues(
                                    `${lineItems}[${index}].MarkUp`
                                  )}
                                  size="small"
                                  fullWidth
                                  disabled={item?.ServiceId > 0 ? true : false}
                                  InputProps={{
                                    inputProps: {
                                      style: {
                                        textAlign: isEditDetails
                                          ? "right"
                                          : "center",
                                        color:
                                          theme.palette.primary.contrastText,
                                        fontWeight:
                                          theme.typography.fontWeightMedium,
                                      },
                                    },
                                    style: {
                                      color: theme.palette.primary.contrastText,
                                      fontWeight:
                                        theme.typography.fontWeightMedium,
                                    },
                                    disableUnderline: true,
                                  }}
                                  sx={{
                                    pointerEvents: "none",
                                  }}
                                  onChange={(e) =>
                                    handleMarkUpChange(lineItems, e, index)
                                  }
                                />
                              )}
                            </TableCell>
                          </RestrictedComponents>
                        )}
                        <RestrictedComponents
                          permission={[
                            Permissions.ADD_JOB,
                            Permissions.ADD_QUOTE,
                            Permissions.ADD_INVOICE,
                          ]}
                        >
                          <TableCell
                            align="right"
                            style={{ minWidth: 110, maxWidth: 150 }}
                          >
                            <TextField
                              name={`${lineItems}.${index}.Total`}
                              {...register(`${lineItems}.${index}.Total`)}
                              id="outlined-basic"
                              placeholder={t("textFieldLables.enterTotal")}
                              type="text"
                              variant={isEditDetails ? "outlined" : "standard"}
                              size="small"
                              InputProps={{
                                inputProps: {
                                  style: {
                                    textAlign: isEditDetails
                                      ? "right"
                                      : "center",
                                    color: theme.palette.primary.contrastText,
                                    fontWeight:
                                      theme.typography.fontWeightMedium,
                                  },
                                },
                                style: {
                                  color: theme.palette.primary.contrastText,
                                  fontWeight: theme.typography.fontWeightMedium,
                                },
                                disableUnderline: true,
                              }}
                              disabled={true}
                              sx={{
                                pointerEvents: "none",
                              }}
                            />
                          </TableCell>
                        </RestrictedComponents>
                        {isEditDetails && (
                          <TableCell align="center">
                            <IconButton
                              aria-label="delete"
                              disable={
                                (isPackage && isPakcageItemsCount < 3) ||
                                (!isPackage &&
                                  item?.ServiceId > 0 &&
                                  isServiceCountOne)
                              }
                              onClick={() => {
                                toggleCrossedOut(index);
                              }}
                              sx={{
                                pointerEvents:
                                  (isPackage && isPakcageItemsCount < 3) ||
                                  (!isPackage &&
                                    item?.ServiceId > 0 &&
                                    isServiceCountOne)
                                    ? "none"
                                    : "auto",
                                color:
                                  (isPackage && isPakcageItemsCount < 3) ||
                                  (!isPackage &&
                                    item?.ServiceId > 0 &&
                                    isServiceCountOne)
                                    ? theme.palette.text.disabled
                                    : item.IsService && isServiceCountOne
                                    ? theme.palette.text.disabled
                                    : theme.palette.common.danger,
                                marginTop: "-10px",
                                // marginLeft: "10px",
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                        )}
                      </>
                    </TableRow>
                  )
              )}

              {/* Services Line Items */}
              {isEditDetails
                ? getValues(newServiceLineItems)?.map(
                    (item, index1) =>
                      !item.IsDelete && (
                        <TableRow key={index1}>
                          <>
                            <CustomSelectTableCell
                              item={item}
                              index={index1}
                              nameProp={`${newServiceLineItems}.${index1}.ServiceId`}
                              registerProp={`${newServiceLineItems}.${index1}.ServiceId`}
                              onChangeProps={(e) =>
                                handleServiceChange(
                                  newServiceLineItems,
                                  item,
                                  e,
                                  services?.find(
                                    (option) =>
                                      option.ServiceId === e.target.value
                                  ),
                                  index1
                                )
                              }
                              TitleProps={
                                services?.find(
                                  (option) =>
                                    option.ServiceId ===
                                    getValues(
                                      `${newServiceLineItems}.${index1}.ServiceId`
                                    )
                                )?.ServiceName ||
                                t(`textFieldLables.${labelKey}`)
                              }
                              // MenuItemProps={renderMenuItem(
                              //   services,
                              //   newServiceLineItems,
                              //   "ServiceId",
                              //   "ServiceName",
                              //   lineItems
                              // )}
                              //>
                              MenuItemProps={services?.map((option) => (
                                <MenuItem
                                  key={option.ServiceId}
                                  value={option.ServiceId}
                                  disabled={
                                    !!(
                                      getValues(newServiceLineItems)?.find(
                                        (p) =>
                                          !p.IsDelete &&
                                          p.ServiceId === option.ServiceId
                                      ) ||
                                      getValues(lineItems)?.find(
                                        (p) =>
                                          !p.IsDelete &&
                                          p.ServiceId === option.ServiceId
                                      )
                                    )
                                  }
                                >
                                  {option.ServiceName}
                                </MenuItem>
                              ))}
                            />

                            <CustomEditableTableCell
                              item={item}
                              isPackage={isPackage}
                              isEditDetails={isEditDetails}
                              t={t}
                              theme={theme}
                            />

                            <TableCell
                              align="right"
                              style={{ minWidth: 40, maxWidth: 60 }}
                            >
                              <TextField
                                required
                                name={`${newServiceLineItems}.[${index1}].Qty`}
                                {...register(
                                  `${newServiceLineItems}.[${index1}].Qty`
                                )}
                                id="outlined-basic"
                                placeholder={t("textFieldLables.enterQuantity")}
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputProps={{
                                  inputProps: {
                                    style: { textAlign: "right" },
                                  },
                                  step: "0.01",
                                }}
                                sx={{
                                  pointerEvents: "auto",
                                }}
                                onChange={(e) =>
                                  handleQuantityChange(
                                    newServiceLineItems,
                                    e,
                                    index1
                                  )
                                }
                              />
                            </TableCell>
                            <RestrictedComponents
                              permission={[
                                Permissions.ADD_JOB,
                                Permissions.ADD_QUOTE,
                                Permissions.ADD_INVOICE,
                              ]}
                            >
                              <TableCell
                                align="right"
                                style={{ minWidth: 80, maxWidth: 150 }}
                              >
                                <TextField
                                  required
                                  name={`${newServiceLineItems}.[${index1}].UnitPrice`}
                                  {...register(
                                    `${newServiceLineItems}.[${index1}].UnitPrice`
                                  )}
                                  id="outlined-basic"
                                  placeholder={t(
                                    "textFieldLables.enterUnitPrice"
                                  )}
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  InputProps={{
                                    inputProps: {
                                      style: { textAlign: "right" },
                                    },
                                  }}
                                  sx={{
                                    pointerEvents: "auto",
                                  }}
                                  disabled={item?.ServiceId > 0 ? false : true}
                                  onChange={(e) => {
                                    handleUnitPriceChange(
                                      newServiceLineItems,
                                      e,
                                      index1
                                    );
                                  }}
                                />
                              </TableCell>
                            </RestrictedComponents>
                            {isPackage && (
                              <RestrictedComponents
                                permission={[
                                  Permissions.ADD_JOB,
                                  Permissions.ADD_QUOTE,
                                  Permissions.ADD_INVOICE,
                                ]}
                              >
                                <TableCell
                                  align="right"
                                  style={{ minWidth: 60, maxWidth: 120 }}
                                >
                                  <TextField
                                    value={"-"}
                                    id="outlined-basic"
                                    placeholder={t(
                                      "textFieldLables.enterMarkUp"
                                    )}
                                    disabled
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    InputProps={{
                                      inputProps: {
                                        style: { textAlign: "center" },
                                      },
                                    }}
                                    sx={{
                                      pointerEvents: "none",
                                    }}
                                  />
                                </TableCell>
                              </RestrictedComponents>
                            )}
                            <RestrictedComponents
                              permission={[
                                Permissions.ADD_JOB,
                                Permissions.ADD_QUOTE,
                                Permissions.ADD_INVOICE,
                              ]}
                            >
                              <TableCell
                                align="right"
                                style={{ minWidth: 110, maxWidth: 140 }}
                              >
                                <TextField
                                  name={`${newServiceLineItems}.${index1}.Total`}
                                  {...register(
                                    `${newServiceLineItems}.${index1}.Total`
                                  )}
                                  id="outlined-basic"
                                  placeholder={t("textFieldLables.enterTotal")}
                                  type="text"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  InputProps={{
                                    inputProps: {
                                      style: { textAlign: "right" },
                                    },
                                  }}
                                  disabled={true}
                                  sx={{
                                    pointerEvents: "none",
                                  }}
                                />
                              </TableCell>
                            </RestrictedComponents>
                            <TableCell align="center">
                              {isEditDetails && (
                                <IconButton
                                  aria-label="delete"
                                  disable={
                                    (isPackage && isPakcageItemsCount < 3) ||
                                    (!isPackage &&
                                      item?.ServiceId > 0 &&
                                      isServiceCountOne)
                                  }
                                  onClick={() => {
                                    handleRemoveClick(index1);
                                  }}
                                  sx={{
                                    pointerEvents:
                                      (isPackage && isPakcageItemsCount < 3) ||
                                      (!isPackage &&
                                        item?.ServiceId > 0 &&
                                        isServiceCountOne)
                                        ? "none"
                                        : "auto",
                                    color:
                                      (isPackage && isPakcageItemsCount < 3) ||
                                      (!isPackage &&
                                        item?.ServiceId > 0 &&
                                        isServiceCountOne)
                                        ? theme.palette.text.disabled
                                        : item.IsService && isServiceCountOne
                                        ? theme.palette.text.disabled
                                        : theme.palette.common.danger,
                                    marginTop: "-10px",
                                    // marginLeft: "10px",
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              )}
                            </TableCell>
                          </>
                        </TableRow>
                      )
                  )
                : null}

              {/* Products Line Items */}
              {isEditDetails
                ? getValues(newProductLineItems)?.map(
                    (item, index2) =>
                      !item.IsDelete && (
                        <TableRow key={index2}>
                          <>
                            <CustomSelectTableCell
                              item={item}
                              index={index2}
                              nameProp={`${newProductLineItems}.${index2}.ProductId`}
                              registerProp={`${newProductLineItems}.${index2}.ProductId`}
                              onChangeProps={(e) =>
                                handleProductChange(
                                  newProductLineItems,
                                  item,
                                  e,
                                  products?.find(
                                    (option) =>
                                      option.ProductId === e.target.value
                                  ),
                                  index2
                                )
                              }
                              TitleProps={
                                products?.find(
                                  (option) =>
                                    option.ProductId ===
                                    getValues(
                                      `${newProductLineItems}.[${index2}].ProductId`
                                    )
                                )?.Name || t(`textFieldLables.productName`)
                              }
                              //   MenuItemProps={renderMenuItem(
                              //     products,
                              //     newProductLineItems,
                              //     "ProductId",
                              //     "Name",
                              //     lineItems
                              //   )}
                              // />
                              MenuItemProps={products?.map((option) => (
                                <MenuItem
                                  key={option.ProductId}
                                  value={option.ProductId}
                                  disabled={
                                    !!(
                                      getValues(newProductLineItems)?.find(
                                        (p) =>
                                          !p.IsDelete &&
                                          p.ProductId === option.ProductId
                                      ) ||
                                      getValues(lineItems)?.find(
                                        (p) =>
                                          !p.IsDelete &&
                                          p.ProductId === option.ProductId
                                      )
                                    )
                                  }
                                >
                                  {option.Name}
                                </MenuItem>
                              ))}
                            />

                            <CustomEditableTableCell
                              item={item}
                              isPackage={isPackage}
                              isEditDetails={true}
                              t={t}
                              theme={theme}
                            />

                            <TableCell
                              align="right"
                              style={{ minWidth: 40, maxWidth: 60 }}
                            >
                              <TextField
                                required
                                name={`${newProductLineItems}.[${index2}].Qty`}
                                {...register(
                                  `${newProductLineItems}.[${index2}].Qty`
                                )}
                                id="outlined-basic"
                                placeholder={t("textFieldLables.enterQuantity")}
                                variant={"outlined"}
                                size="small"
                                fullWidth
                                InputProps={{
                                  inputProps: {
                                    style: {
                                      textAlign: "right",
                                      color: theme.palette.primary.contrastText,
                                      fontWeight:
                                        theme.typography.fontWeightMedium,
                                    },
                                  },
                                  style: {
                                    color: theme.palette.primary.contrastText,
                                    fontWeight:
                                      theme.typography.fontWeightMedium,
                                  },
                                  disableUnderline: true,
                                }}
                                sx={{
                                  pointerEvents: "auto",
                                }}
                                onChange={(e) =>
                                  handleQuantityChange(
                                    newProductLineItems,
                                    e,
                                    index2
                                  )
                                }
                              />
                            </TableCell>
                            <RestrictedComponents
                              permission={[
                                Permissions.ADD_JOB,
                                Permissions.ADD_QUOTE,
                                Permissions.ADD_INVOICE,
                              ]}
                            >
                              <TableCell
                                style={{ minWidth: 80, maxWidth: 160 }}
                                align="right"
                              >
                                <TextField
                                  required
                                  name={`${newProductLineItems}.[${index2}].UnitPrice`}
                                  {...register(
                                    `${newProductLineItems}.[${index2}].UnitPrice`
                                  )}
                                  id="outlined-basic"
                                  placeholder={t(
                                    "textFieldLables.enterUnitPrice"
                                  )}
                                  variant={"outlined"}
                                  size="small"
                                  fullWidth
                                  InputProps={{
                                    inputProps: {
                                      style: {
                                        textAlign: "right",
                                        color:
                                          theme.palette.primary.contrastText,
                                        fontWeight:
                                          theme.typography.fontWeightMedium,
                                      },
                                    },
                                    style: {
                                      color: theme.palette.primary.contrastText,
                                      fontWeight:
                                        theme.typography.fontWeightMedium,
                                    },
                                    disableUnderline: true,
                                  }}
                                  sx={{
                                    pointerEvents: "auto",
                                  }}
                                  disabled={
                                    item.ProductId > 0 || item.PackageId > 0
                                      ? false
                                      : true
                                  }
                                  onChange={(e) =>
                                    handleUnitPriceChange(
                                      newProductLineItems,
                                      e,
                                      index2
                                    )
                                  }
                                />
                              </TableCell>
                            </RestrictedComponents>
                            {isPackage && (
                              <RestrictedComponents
                                permission={[
                                  Permissions.ADD_JOB,
                                  Permissions.ADD_QUOTE,
                                  Permissions.ADD_INVOICE,
                                ]}
                              >
                                <TableCell
                                  style={{ minWidth: 60, maxWidth: 120 }}
                                  align="right"
                                >
                                  <TextField
                                    name={`${newProductLineItems}.[${index2}].MarkUpShow`}
                                    {...register(
                                      `${newProductLineItems}.[${index2}].MarkUpShow`
                                    )}
                                    id="outlined-basic"
                                    placeholder={t(
                                      "textFieldLables.enterMarkUp"
                                    )}
                                    variant={"outlined"}
                                    size="small"
                                    fullWidth
                                    InputProps={{
                                      inputProps: {
                                        style: {
                                          textAlign: "right",
                                          color:
                                            theme.palette.primary.contrastText,
                                          fontWeight:
                                            theme.typography.fontWeightMedium,
                                        },
                                      },
                                      style: {
                                        color:
                                          theme.palette.primary.contrastText,
                                        fontWeight:
                                          theme.typography.fontWeightMedium,
                                      },
                                      disableUnderline: true,
                                    }}
                                    sx={{
                                      pointerEvents: "none",
                                    }}
                                    onChange={(e) =>
                                      handleMarkUpChange(
                                        newProductLineItems,
                                        e,
                                        index2
                                      )
                                    }
                                  />
                                </TableCell>
                              </RestrictedComponents>
                            )}
                            <RestrictedComponents
                              permission={[
                                Permissions.ADD_JOB,
                                Permissions.ADD_QUOTE,
                                Permissions.ADD_INVOICE,
                              ]}
                            >
                              <TableCell
                                align="right"
                                style={{ minWidth: 110, maxWidth: 140 }}
                              >
                                <TextField
                                  name={`${newProductLineItems}.${index2}.Total`}
                                  {...register(
                                    `${newProductLineItems}.${index2}.Total`
                                  )}
                                  id="outlined-basic"
                                  placeholder={t("textFieldLables.enterTotal")}
                                  type="text"
                                  variant={"outlined"}
                                  size="small"
                                  InputProps={{
                                    inputProps: {
                                      style: {
                                        textAlign: "right",
                                        color:
                                          theme.palette.primary.contrastText,
                                        fontWeight:
                                          theme.typography.fontWeightMedium,
                                      },
                                    },
                                    style: {
                                      color: theme.palette.primary.contrastText,
                                      fontWeight:
                                        theme.typography.fontWeightMedium,
                                    },
                                    disableUnderline: true,
                                  }}
                                  disabled={true}
                                  sx={{
                                    pointerEvents: "none",
                                  }}
                                />
                              </TableCell>
                            </RestrictedComponents>

                            <TableCell align="center">
                              {isEditDetails && (
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => {
                                    handleRemoveProductClick(index2);
                                  }}
                                  disabled={
                                    isPackage && isPakcageItemsCount < 3
                                  }
                                  sx={{
                                    pointerEvents:
                                      isPackage && isPakcageItemsCount < 3
                                        ? "none"
                                        : "auto",
                                    color:
                                      isPackage && isPakcageItemsCount < 3
                                        ? theme.palette.text.disabled
                                        : theme.palette.common.danger,
                                    marginTop: "-10px",
                                    // marginLeft: "10px",
                                    "&:hover": {
                                      backgroundColor:
                                        theme.palette.common.danger,
                                      color: "white",
                                    },
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              )}
                            </TableCell>
                          </>
                        </TableRow>
                      )
                  )
                : null}

              {/* Packages Line Items */}
              {isEditDetails
                ? getValues(newPackageLineItems)?.map(
                    (item, index) =>
                      !item.IsDelete && (
                        <TableRow key={index}>
                          <>
                            <TableCell
                              scope="row"
                              style={{ minWidth: 130, maxWidth: 150 }}
                            >
                              <Stack direction="row">
                                <FormControl fullWidth>
                                  <InputLabel
                                    id="demo-simple-select-required-label"
                                    size="small"
                                  >
                                    {t("labels.package")}
                                  </InputLabel>
                                  <Select
                                    name={`${newPackageLineItems}.${index}.PackageId`}
                                    {...register(
                                      `${newPackageLineItems}.${index}.PackageId`
                                    )}
                                    label={t("labels.selectPackage")}
                                    variant="outlined"
                                    size="small"
                                    value={
                                      getValues(
                                        `${newPackageLineItems}.[${index}].PackageId`
                                      ) || ""
                                    }
                                    onChange={(e) => {
                                      let selectedObj = packages?.find(
                                        (option) =>
                                          option.PackageId === e.target.value
                                      );

                                      setValue(
                                        `${newPackageLineItems}.[${index}].PackageId`,
                                        e.target.value
                                      );

                                      setValue(
                                        `${newPackageLineItems}.[${index}].UnitPrice`,
                                        parseFloat(selectedObj?.PackagePrice) ||
                                          0
                                      );

                                      setValue(
                                        `${newPackageLineItems}.[${index}].MarkUp`,
                                        0
                                      );

                                      setValue(
                                        `${newPackageLineItems}.[${index}].Total`,
                                        parseFloat(
                                          parseFloat(
                                            selectedObj?.PackagePrice || 0
                                          ).toFixed(2) *
                                            getValues(
                                              `${newPackageLineItems}.[${index}].Qty`
                                            )
                                        ) || 0
                                      );

                                      setValue(
                                        `${newPackageLineItems}[${index}].Title`,
                                        selectedObj?.PackageName || ""
                                      );

                                      setValue(
                                        `${newPackageLineItems}.[${index}].Description`,
                                        selectedObj?.PackageDescription || ""
                                      );

                                      setValue(
                                        `${newPackageLineItems}.[${index}].LineItemPackageDetail`,
                                        selectedObj?.PackageLineItems || []
                                      );
                                    }}
                                    title={
                                      packages?.find(
                                        (option) =>
                                          option.PackageId ===
                                          getValues(
                                            `${newPackageLineItems}.${index}.PackageId`
                                          )
                                      )?.PackageName ||
                                      t("textFieldLables.packageName")
                                    }
                                  >
                                    {packages?.map((option) => (
                                      <MenuItem
                                        key={option.PackageId}
                                        value={option.PackageId}
                                        disabled={
                                          !!(
                                            getValues(
                                              newPackageLineItems
                                            )?.find(
                                              (p) =>
                                                !p.IsDelete &&
                                                p.PackageId === option.PackageId
                                            ) ||
                                            getValues(lineItems)?.find(
                                              (p) =>
                                                !p.IsDelete &&
                                                p.PackageId === option.PackageId
                                            )
                                          )
                                        }
                                      >
                                        {option.PackageName}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Stack>
                            </TableCell>

                            <CustomEditableTableCell
                              item={item}
                              isPackage={isPackage}
                              isEditDetails={isEditDetails}
                              t={t}
                              theme={theme}
                              cursor="none"
                            />

                            <TableCell
                              align="right"
                              style={{ minWidth: 40, maxWidth: 60 }}
                            >
                              <TextField
                                required
                                name={`${newPackageLineItems}.[${index}].Qty`}
                                {...register(
                                  `${newPackageLineItems}.[${index}].Qty`
                                )}
                                id="outlined-basic"
                                placeholder={t("textFieldLables.enterQuantity")}
                                variant="outlined"
                                size="small"
                                // type="number"
                                fullWidth
                                InputProps={{
                                  inputProps: {
                                    style: { textAlign: "right" },
                                  },
                                }}
                                sx={{
                                  pointerEvents: isEditDetails
                                    ? "auto"
                                    : "none",
                                }}
                                onChange={(e) => {
                                  const userInput = e.target.value;

                                  let numericValue = e.target.value
                                    .replace(/[^\d.]/g, "") // Remove any characters that are not digits or a decimal point
                                    .replace(/\.(\d*)\./g, ".$1") // Allow only up to two decimal places
                                    .replace(/(\.\d\d)\d+$/, "$1") // Remove any additional decimal points;
                                    .replace(/[^0-9]/g, "");
                                  setValue(
                                    `${newPackageLineItems}.[${index}].Qty`,
                                    numericValue
                                  );

                                  if (userInput < 0 || userInput === null) {
                                    setValue(
                                      `${newPackageLineItems}.[${index}].Qty`,
                                      0
                                    );
                                  }

                                  const newQty = parseFloat(numericValue) || 0;

                                  const unitPrice =
                                    parseFloat(
                                      getValues(
                                        `${newPackageLineItems}.[${index}].UnitPrice`
                                      )
                                    ) || 0;

                                  const markUp =
                                    parseFloat(
                                      getValues(
                                        `${newPackageLineItems}.[${index}].MarkUp`
                                      )
                                    ) || 0;

                                  setValue(
                                    `${newPackageLineItems}.[${index}].Total`,
                                    (unitPrice + markUp) * newQty
                                  );
                                }}
                              />
                            </TableCell>
                            <RestrictedComponents
                              permission={[
                                Permissions.ADD_JOB,
                                Permissions.ADD_QUOTE,
                                Permissions.ADD_INVOICE,
                              ]}
                            >
                              <TableCell
                                align="right"
                                style={{ minWidth: 80, maxWidth: 150 }}
                              >
                                <TextField
                                  required
                                  name={`${newPackageLineItems}.[${index}].UnitPrice`}
                                  {...register(
                                    `${newPackageLineItems}.[${index}].UnitPrice`
                                  )}
                                  id="outlined-basic"
                                  placeholder={t(
                                    "textFieldLables.enterUnitPrice"
                                  )}
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  InputProps={{
                                    inputProps: {
                                      style: { textAlign: "right" },
                                    },
                                  }}
                                  sx={{
                                    pointerEvents: isEditDetails
                                      ? "auto"
                                      : "none",
                                  }}
                                  disabled={
                                    item.PackageId > 0 && !item.ProductId
                                      ? false
                                      : true
                                  }
                                  onChange={(e) => {
                                    const userInput = e.target.value;

                                    let numericValue = userInput
                                      .replace(/[^\d.]/g, "") // Remove any characters that are not digits or a decimal point
                                      .replace(/\.(\d*)\./g, ".$1") // Allow only up to two decimal places
                                      .replace(/(\.\d\d)\d+$/, "$1"); // Remove any additional decimal points

                                    // Convert empty string to '0' to display when input is cleared
                                    if (numericValue === "") {
                                      numericValue = "0";
                                    }

                                    // Replace '0' if the input starts with '0' followed by a digit
                                    if (/^0\d/.test(numericValue)) {
                                      numericValue = numericValue.substring(1);
                                    }

                                    setValue(
                                      `${newPackageLineItems}.[${index}].UnitPrice`,
                                      numericValue
                                    );

                                    const newUnitPrice =
                                      parseFloat(numericValue) || 0;
                                    const markUp =
                                      parseFloat(
                                        getValues(
                                          `${newPackageLineItems}.[${index}].MarkUp`
                                        )
                                      ) || 0;
                                    const qty =
                                      parseFloat(
                                        getValues(
                                          `${newPackageLineItems}.[${index}].Qty`
                                        )
                                      ) || 0;

                                    // Display toast message if unit price is less than or equal to 0
                                    if (newUnitPrice <= 0) {
                                      if (!toast.isActive("unitPriceError")) {
                                        toast.error(
                                          t(
                                            "toasts.unitPriceMustBeGreaterThan0"
                                          ),
                                          { toastId: "unitPriceError" } // Add toastId to prevent duplicate toasts
                                        );
                                      }
                                    } else {
                                      toast.dismiss("unitPriceError"); // Dismiss any existing toast with the same message
                                    }

                                    setValue(
                                      `${newPackageLineItems}.[${index}].Total`,
                                      (newUnitPrice + markUp) * qty
                                    );
                                  }}
                                />
                              </TableCell>
                            </RestrictedComponents>
                            <RestrictedComponents
                              permission={[
                                Permissions.ADD_JOB,
                                Permissions.ADD_QUOTE,
                                Permissions.ADD_INVOICE,
                              ]}
                            >
                              <TableCell
                                align="right"
                                style={{ minWidth: 110, maxWidth: 140 }}
                              >
                                <TextField
                                  name={`${newPackageLineItems}.${index}.Total`}
                                  {...register(
                                    `${newPackageLineItems}.${index}.Total`
                                  )}
                                  id="outlined-basic"
                                  placeholder={t("textFieldLables.enterTotal")}
                                  type="text"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  InputProps={{
                                    inputProps: {
                                      style: { textAlign: "right" },
                                    },
                                  }}
                                  disabled={true}
                                  sx={{
                                    pointerEvents: "none",
                                  }}
                                />
                              </TableCell>
                            </RestrictedComponents>
                            <TableCell align="center">
                              {isEditDetails && (
                                <IconButton
                                  aria-label="delete"
                                  disabled={
                                    isPackage
                                      ? isPakcageItemsCount < 3
                                      : isServiceCountOne
                                  }
                                  onClick={() => {
                                    handleRemovePackageClick(index);
                                  }}
                                  sx={{
                                    pointerEvents: (
                                      isPackage
                                        ? isPakcageItemsCount < 3
                                        : isServiceCountOne
                                    )
                                      ? "none"
                                      : "auto",
                                    color: (
                                      isPackage
                                        ? isPakcageItemsCount < 3
                                        : isServiceCountOne
                                    )
                                      ? theme.palette.text.disabled
                                      : theme.palette.common.danger,
                                    marginTop: "-10px",
                                    // marginLeft: "10px",
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              )}
                            </TableCell>
                          </>
                        </TableRow>
                      )
                  )
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: 550,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)",
          }}
          ref={tableRef}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableBody>
              {getValues(lineItems)?.map(
                (item, index) =>
                  !item.IsDelete && (
                    <Fragment key={index}>
                      <TableRow>
                        <TableCell>
                          {t("tableHeadings.service/product")}
                        </TableCell>

                        <CustomSelectTableCell
                          item={item}
                          index={index}
                          nameProp={`${lineItems}.${index}.${valueKey}`}
                          registerProp={
                            item.ServiceId > 0 || (isPackage && item.IsService)
                              ? `${lineItems}.${index}.ServiceId`
                              : item.ProductId > 0 ||
                                (isPackage && !item.IsService)
                              ? `${lineItems}.${index}.ProductId`
                              : `${lineItems}.${index}.PackageId`
                          }
                          onChangeProps={(e) =>
                            item.ServiceId > 0 || (isPackage && item.IsService)
                              ? handleServiceChange(
                                  lineItems,
                                  item,
                                  e,
                                  services?.find(
                                    (option) =>
                                      option.ServiceId === e.target.value
                                  ),
                                  index
                                )
                              : item.ProductId > 0 ||
                                (isPackage && !item.IsService)
                              ? handleProductChange(
                                  lineItems,
                                  item,
                                  e,
                                  products?.find(
                                    (option) =>
                                      option.ProductId === e.target.value
                                  ),
                                  index
                                )
                              : handlePackageChange(
                                  item,
                                  e,
                                  packages?.find(
                                    (option) =>
                                      option.PackageId === e.target.value
                                  ),
                                  index
                                )
                          }
                          TitleProps={
                            item.ServiceId > 0 || (isPackage && item.IsService)
                              ? services?.find(
                                  (option) =>
                                    option.ServiceId ===
                                    getValues(
                                      `${lineItems}.[${index}].ServiceId`
                                    )
                                )?.ServiceName ||
                                t("textFieldLables.serviceName")
                              : item.ProductId > 0 ||
                                (isPackage && !item.IsService)
                              ? products?.find(
                                  (option) =>
                                    option.ProductId ===
                                    getValues(`${lineItems}.${index}.ProductId`)
                                )?.Name || t("textFieldLables.productName")
                              : packages?.find(
                                  (option) =>
                                    option.PackageId ===
                                    getValues(`${lineItems}.${index}.PackageId`)
                                )?.PackageName ||
                                t("textFieldLables.packageName")
                          }
                          MenuItemProps={renderMenuItem(
                            item.ServiceId > 0 || (isPackage && item.IsService)
                              ? services
                              : products,
                            lineItems,
                            item.ServiceId > 0 || (isPackage && item.IsService)
                              ? "ServiceId"
                              : "ProductId",
                            item.ServiceId > 0 || (isPackage && item.IsService)
                              ? "ServiceName"
                              : "Name",
                            item.ServiceId > 0 || (isPackage && item.IsService)
                              ? newServiceLineItems
                              : newProductLineItems
                          )}
                        />
                      </TableRow>

                      <TableRow>
                        <TableCell>{t("tableHeadings.type")}</TableCell>

                        <CustomEditableTableCell
                          style={{ minWidth: 40, maxWidth: 60 }}
                          item={item}
                          isPackage={isPackage}
                          isEditDetails={isEditDetails}
                          t={t}
                          theme={theme}
                        />
                      </TableRow>

                      <TableRow>
                        <TableCell>{t("tableHeadings.qty/hour")}</TableCell>

                        <TableCell
                          scope="row"
                          style={{ minWidth: 40, maxWidth: 60 }}
                        >
                          <TextField
                            required
                            name={`${lineItems}.[${index}].Qty`}
                            {...register(`${lineItems}.[${index}].Qty`)}
                            id="outlined-basic"
                            placeholder={t("textFieldLables.enterQuantity")}
                            variant={isEditDetails ? "outlined" : "standard"}
                            size="small"
                            fullWidth={isEditDetails ? true : false}
                            InputProps={{
                              inputProps: {
                                style: {
                                  textAlign: isEditDetails ? "right" : "right",
                                  color: theme.palette.primary.contrastText,
                                  fontWeight: theme.typography.fontWeightMedium,
                                  padding: "10px",
                                },
                              },
                              style: {
                                color: theme.palette.primary.contrastText,
                                fontWeight: theme.typography.fontWeightMedium,
                              },
                              disableUnderline: true,
                            }}
                            sx={{
                              pointerEvents: isEditDetails ? "auto" : "none",
                            }}
                            onChange={(e) =>
                              handleQuantityChange(lineItems, e, index)
                            }
                          />
                        </TableCell>
                      </TableRow>

                      <RestrictedComponents
                        permission={[
                          Permissions.ADD_JOB,
                          Permissions.ADD_QUOTE,
                          Permissions.ADD_INVOICE,
                        ]}
                      >
                        <TableRow>
                          <TableCell>
                            {t("tableHeadings.unitPrice")}&nbsp;(
                            {CurrencySymbol})
                          </TableCell>

                          <TableCell
                            scope="row"
                            style={{ minWidth: 80, maxWidth: 150 }}
                          >
                            <TextField
                              required
                              name={`${lineItems}.[${index}].UnitPrice`}
                              {...register(`${lineItems}.[${index}].UnitPrice`)}
                              id="outlined-basic"
                              placeholder={t("textFieldLables.enterUnitPrice")}
                              variant={isEditDetails ? "outlined" : "standard"}
                              size="small"
                              fullWidth={isEditDetails ? true : false}
                              InputProps={{
                                inputProps: {
                                  style: {
                                    textAlign: isEditDetails
                                      ? "right"
                                      : "right",
                                    color: theme.palette.primary.contrastText,
                                    fontWeight:
                                      theme.typography.fontWeightMedium,
                                    padding: "10px",
                                  },
                                },
                                style: {
                                  color: theme.palette.primary.contrastText,
                                  fontWeight: theme.typography.fontWeightMedium,
                                },
                                disableUnderline: true,
                              }}
                              sx={{
                                pointerEvents: isEditDetails ? "auto" : "none",
                              }}
                              onChange={(e) =>
                                handleUnitPriceChange(lineItems, e, index)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      </RestrictedComponents>

                      {isPackage && (
                        <RestrictedComponents
                          permission={[
                            Permissions.ADD_JOB,
                            Permissions.ADD_QUOTE,
                            Permissions.ADD_INVOICE,
                          ]}
                        >
                          <TableRow>
                            <TableCell>
                              {t("tableHeadings.markUp")}&nbsp;({CurrencySymbol}
                              )
                            </TableCell>

                            <TableCell
                              style={{ minWidth: 60, maxWidth: 120 }}
                              align="right"
                            >
                              {item?.IsService ? (
                                <TextField
                                  id="outlined-basic"
                                  placeholder={t("textFieldLables.enterMarkUp")}
                                  value={"-"}
                                  variant={
                                    isEditDetails ? "outlined" : "standard"
                                  }
                                  size="small"
                                  fullWidth
                                  disabled={true}
                                  InputProps={{
                                    inputProps: {
                                      style: {
                                        textAlign: "center",
                                        color:
                                          theme.palette.primary.contrastText,
                                        fontWeight:
                                          theme.typography.fontWeightMedium,
                                      },
                                    },
                                    style: {
                                      color: theme.palette.primary.contrastText,
                                      fontWeight:
                                        theme.typography.fontWeightMedium,
                                    },
                                    disableUnderline: true,
                                  }}
                                  sx={{
                                    pointerEvents: "none",
                                  }}
                                />
                              ) : (
                                <TextField
                                  name={`${lineItems}.[${index}].MarkUpShow`}
                                  {...register(
                                    `${lineItems}.[${index}].MarkUpShow`
                                  )}
                                  id="outlined-basic"
                                  placeholder={t("textFieldLables.enterMarkUp")}
                                  variant={
                                    isEditDetails ? "outlined" : "standard"
                                  }
                                  size="small"
                                  fullWidth
                                  disabled={item?.IsService ? true : false}
                                  InputProps={{
                                    inputProps: {
                                      style: {
                                        textAlign: isEditDetails
                                          ? "right"
                                          : "center",
                                        color:
                                          theme.palette.primary.contrastText,
                                        fontWeight:
                                          theme.typography.fontWeightMedium,
                                      },
                                    },
                                    style: {
                                      color: theme.palette.primary.contrastText,
                                      fontWeight:
                                        theme.typography.fontWeightMedium,
                                    },
                                    disableUnderline: true,
                                  }}
                                  sx={{
                                    pointerEvents: "none",
                                  }}
                                  onChange={(e) =>
                                    handleMarkUpChange(lineItems, e, index)
                                  }
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        </RestrictedComponents>
                      )}

                      <RestrictedComponents
                        permission={[
                          Permissions.ADD_JOB,
                          Permissions.ADD_QUOTE,
                          Permissions.ADD_INVOICE,
                        ]}
                      >
                        <TableRow>
                          <TableCell>
                            {t("tableHeadings.price")}&nbsp;({CurrencySymbol})
                          </TableCell>

                          <TableCell
                            scope="row"
                            align="right"
                            style={{ minWidth: 110, maxWidth: 140 }}
                          >
                            <TextField
                              name={`${lineItems}.${index}.Total`}
                              {...register(`${lineItems}.${index}.Total`)}
                              id="outlined-basic"
                              placeholder={t("textFieldLables.enterTotal")}
                              type="text"
                              variant={isEditDetails ? "outlined" : "standard"}
                              size="small"
                              fullWidth={isEditDetails ? true : false}
                              InputProps={{
                                inputProps: {
                                  style: {
                                    textAlign: isEditDetails
                                      ? "right"
                                      : "right",
                                    color: theme.palette.primary.contrastText,
                                    fontWeight:
                                      theme.typography.fontWeightMedium,
                                    padding: "10px",
                                  },
                                },
                                style: {
                                  color: theme.palette.primary.contrastText,
                                  fontWeight: theme.typography.fontWeightMedium,
                                },
                                disableUnderline: true,
                              }}
                              disabled={true}
                              sx={{
                                pointerEvents: "none",
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      </RestrictedComponents>

                      {isEditDetails && (
                        <>
                          <TableRow>
                            <TableCell />

                            <TableCell scope="row" align="right">
                              <IconButton
                                aria-label="delete"
                                disabled={
                                  isPackage
                                    ? isPakcageItemsCount < 3
                                    : (item?.ServiceId > 0 ||
                                        item?.PackageId > 0) &&
                                      isServiceCountOne
                                }
                                onClick={() => {
                                  toggleCrossedOut(index);
                                }}
                                sx={{
                                  pointerEvents: (
                                    isPackage
                                      ? isPakcageItemsCount < 3
                                      : (item?.ServiceId > 0 ||
                                          item?.PackageId > 0) &&
                                        isServiceCountOne
                                  )
                                    ? "none"
                                    : "auto",
                                  color: (
                                    isPackage
                                      ? isPakcageItemsCount < 3
                                      : (item?.ServiceId > 0 ||
                                          item?.PackageId > 0) &&
                                        isServiceCountOne
                                  )
                                    ? theme.palette.text.disabled
                                    : theme.palette.common.danger,
                                  marginTop: "-10px",
                                  marginLeft: "10px",
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </>
                      )}

                      {isEditDetails ? null : <TableRow />}
                    </Fragment>
                  )
              )}

              {/* Services Line Items */}
              {isEditDetails
                ? getValues(newServiceLineItems)?.map(
                    (item, index1) =>
                      !item.IsDelete && (
                        <Fragment key={index1}>
                          <TableRow>
                            <TableCell>
                              {t("tableHeadings.service/product")}
                            </TableCell>

                            <CustomSelectTableCell
                              item={item}
                              index={index1}
                              nameProp={`${newServiceLineItems}.${index1}.ServiceId`}
                              registerProp={`${newServiceLineItems}.${index1}.ServiceId`}
                              onChangeProps={(e) =>
                                handleServiceChange(
                                  newServiceLineItems,
                                  item,
                                  e,
                                  services?.find(
                                    (option) =>
                                      option.ServiceId === e.target.value
                                  ),
                                  index1
                                )
                              }
                              TitleProps={
                                services?.find(
                                  (option) =>
                                    option.ServiceId ===
                                    getValues(
                                      `${newServiceLineItems}.${index1}.ServiceId`
                                    )
                                )?.ServiceName ||
                                t(`textFieldLables.${labelKey}`)
                              }
                              MenuItemProps={renderMenuItem(
                                services,
                                newServiceLineItems,
                                "ServiceId",
                                "ServiceName",
                                lineItems
                              )}
                            />
                          </TableRow>

                          <TableRow>
                            <TableCell>{t("tableHeadings.type")}</TableCell>
                            <CustomEditableTableCell
                              style={{ minWidth: 40, maxWidth: 60 }}
                              item={item}
                              isPackage={isPackage}
                              isEditDetails={true}
                              t={t}
                              theme={theme}
                            />
                          </TableRow>

                          <TableRow>
                            <TableCell>{t("tableHeadings.qty/hour")}</TableCell>

                            <TableCell
                              scope="row"
                              style={{ minWidth: 40, maxWidth: 60 }}
                              align="right"
                            >
                              <TextField
                                name={`${newServiceLineItems}.[${index1}].Qty`}
                                {...register(
                                  `${newServiceLineItems}.[${index1}].Qty`
                                )}
                                id="outlined-basic"
                                placeholder={t("textFieldLables.enterQuantity")}
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputProps={{
                                  inputProps: {
                                    style: { textAlign: "right" },
                                  },
                                }}
                                sx={{
                                  pointerEvents: isEditDetails
                                    ? "auto"
                                    : "none",
                                }}
                                onChange={(e) =>
                                  handleQuantityChange(
                                    newServiceLineItems,
                                    e,
                                    index1
                                  )
                                }
                              />
                            </TableCell>
                          </TableRow>

                          <RestrictedComponents
                            permission={[
                              Permissions.ADD_JOB,
                              Permissions.ADD_QUOTE,
                              Permissions.ADD_INVOICE,
                            ]}
                          >
                            <TableRow>
                              <TableCell>
                                {t("tableHeadings.unitPrice")}&nbsp;(
                                {CurrencySymbol})
                              </TableCell>

                              <TableCell
                                scope="row"
                                align="right"
                                style={{ minWidth: 80, maxWidth: 150 }}
                              >
                                <TextField
                                  name={`${newServiceLineItems}.[${index1}].UnitPrice`}
                                  {...register(
                                    `${newServiceLineItems}.[${index1}].UnitPrice`
                                  )}
                                  // value={getValues(
                                  //   `${newServiceLineItems}.[${index1}].Total`
                                  // )}
                                  id="outlined-basic"
                                  placeholder={t(
                                    "textFieldLables.enterUnitPrice"
                                  )}
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  InputProps={{
                                    inputProps: {
                                      style: { textAlign: "right" },
                                    },
                                  }}
                                  sx={{
                                    pointerEvents: isEditDetails
                                      ? "auto"
                                      : "none",
                                  }}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    handleUnitPriceChange(
                                      newServiceLineItems,
                                      e,
                                      index1
                                    );
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          </RestrictedComponents>

                          {isPackage && (
                            <RestrictedComponents
                              permission={[
                                Permissions.ADD_JOB,
                                Permissions.ADD_QUOTE,
                                Permissions.ADD_INVOICE,
                              ]}
                            >
                              <TableRow>
                                <TableCell>
                                  {t("tableHeadings.markUp")}&nbsp;(
                                  {CurrencySymbol})
                                </TableCell>

                                <TableCell
                                  align="right"
                                  style={{ minWidth: 60, maxWidth: 120 }}
                                >
                                  <TextField
                                    value={"-"}
                                    id="outlined-basic"
                                    placeholder={t(
                                      "textFieldLables.enterMarkUp"
                                    )}
                                    disabled
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    InputProps={{
                                      inputProps: {
                                        style: { textAlign: "center" },
                                      },
                                    }}
                                    sx={{
                                      pointerEvents: "none",
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            </RestrictedComponents>
                          )}

                          <RestrictedComponents
                            permission={[
                              Permissions.ADD_JOB,
                              Permissions.ADD_QUOTE,
                              Permissions.ADD_INVOICE,
                            ]}
                          >
                            <TableRow>
                              <TableCell>
                                {t("tableHeadings.price")}&nbsp;(
                                {CurrencySymbol})
                              </TableCell>

                              <TableCell
                                scope="row"
                                align="right"
                                style={{ minWidth: 110, maxWidth: 140 }}
                              >
                                <TextField
                                  name={`${newServiceLineItems}.${index1}.Total`}
                                  {...register(
                                    `${newServiceLineItems}.${index1}.Total`
                                  )}
                                  id="outlined-basic"
                                  placeholder={t("textFieldLables.enterTotal")}
                                  type="text"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  InputProps={{
                                    inputProps: {
                                      style: { textAlign: "right" },
                                    },
                                  }}
                                  disabled={true}
                                  sx={{
                                    pointerEvents: "none",
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          </RestrictedComponents>

                          <TableRow>
                            <TableCell />

                            <TableCell scope="row" align="right">
                              {isEditDetails && (
                                <IconButton
                                  aria-label="delete"
                                  disabled={
                                    isPackage
                                      ? isPakcageItemsCount < 3
                                      : isServiceCountOne
                                  }
                                  onClick={() => {
                                    handleRemoveClick(index1);
                                  }}
                                  sx={{
                                    pointerEvents: (
                                      isPackage
                                        ? isPakcageItemsCount < 3
                                        : isServiceCountOne
                                    )
                                      ? "none"
                                      : "auto",
                                    color: (
                                      isPackage
                                        ? isPakcageItemsCount < 3
                                        : isServiceCountOne
                                    )
                                      ? theme.palette.text.disabled
                                      : theme.palette.common.danger,
                                    marginTop: "-10px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      )
                  )
                : null}

              {/* Products Line Items */}
              {isEditDetails
                ? getValues(newProductLineItems)?.map(
                    (item, index2) =>
                      !item.IsDelete && (
                        <Fragment key={index2}>
                          <TableRow>
                            <TableCell>
                              {t("tableHeadings.service/product")}
                            </TableCell>
                            <CustomSelectTableCell
                              item={item}
                              index={index2}
                              nameProp={`${newProductLineItems}.${index2}.ProductId`}
                              registerProp={`${newProductLineItems}.${index2}.ProductId`}
                              onChangeProps={(e) =>
                                handleProductChange(
                                  newProductLineItems,
                                  item,
                                  e,
                                  products?.find(
                                    (option) =>
                                      option.ProductId === e.target.value
                                  ),
                                  index2
                                )
                              }
                              TitleProps={
                                products?.find(
                                  (option) =>
                                    option.ProductId ===
                                    getValues(
                                      `${newProductLineItems}.[${index2}].ProductId`
                                    )
                                )?.Name || t(`textFieldLables.productName`)
                              }
                              MenuItemProps={renderMenuItem(
                                products,
                                newProductLineItems,
                                "ProductId",
                                "Name",
                                lineItems
                              )}
                            />
                          </TableRow>

                          <TableRow>
                            <TableCell>{t("tableHeadings.type")}</TableCell>

                            <CustomEditableTableCell
                              style={{ minWidth: 40, maxWidth: 60 }}
                              item={item}
                              isPackage={isPackage}
                              isEditDetails={true}
                              t={t}
                              theme={theme}
                            />
                          </TableRow>

                          <TableRow>
                            <TableCell>{t("tableHeadings.qty/hour")}</TableCell>

                            <TableCell
                              scope="row"
                              align="right"
                              style={{ minWidth: 40, maxWidth: 60 }}
                            >
                              <TextField
                                name={`${newProductLineItems}.[${index2}].Qty`}
                                {...register(
                                  `${newProductLineItems}.[${index2}].Qty`
                                )}
                                id="outlined-basic"
                                placeholder={t("textFieldLables.enterQuantity")}
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputProps={{
                                  inputProps: {
                                    style: { textAlign: "right" },
                                  },
                                }}
                                sx={{
                                  pointerEvents: isEditDetails
                                    ? "auto"
                                    : "none",
                                }}
                                onChange={(e) =>
                                  handleQuantityChange(
                                    newProductLineItems,
                                    e,
                                    index2
                                  )
                                }
                              />
                            </TableCell>
                          </TableRow>

                          <RestrictedComponents
                            permission={[
                              Permissions.ADD_JOB,
                              Permissions.ADD_QUOTE,
                              Permissions.ADD_INVOICE,
                            ]}
                          >
                            <>
                              <TableRow>
                                <TableCell>
                                  {t("tableHeadings.unitPrice")}&nbsp;(
                                  {CurrencySymbol})
                                </TableCell>

                                <TableCell
                                  scope="row"
                                  align="right"
                                  style={{ minWidth: 80, maxWidth: 150 }}
                                >
                                  <TextField
                                    name={`${newProductLineItems}.[${index2}].UnitPrice`}
                                    {...register(
                                      `${newProductLineItems}.[${index2}].UnitPrice`
                                    )}
                                    id="outlined-basic"
                                    placeholder={t(
                                      "textFieldLables.enterUnitPrice"
                                    )}
                                    type="text"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    InputProps={{
                                      inputProps: {
                                        style: { textAlign: "right" },
                                      },
                                    }}
                                    sx={{
                                      pointerEvents: isEditDetails
                                        ? "auto"
                                        : "none",
                                    }}
                                    onChange={(e) =>
                                      handleUnitPriceChange(
                                        newProductLineItems,
                                        e,
                                        index2
                                      )
                                    }
                                  />
                                </TableCell>
                              </TableRow>

                              {isPackage(
                                <TableRow>
                                  <TableCell>
                                    {t("tableHeadings.markUp")}&nbsp;(
                                    {CurrencySymbol})
                                  </TableCell>

                                  <TableCell
                                    align="right"
                                    style={{ minWidth: 60, maxWidth: 120 }}
                                  >
                                    <TextField
                                      name={`${newProductLineItems}.[${index2}].MarkUpShow`}
                                      {...register(
                                        `${newProductLineItems}.[${index2}].MarkUpShow`
                                      )}
                                      id="outlined-basic"
                                      placeholder={t(
                                        "textFieldLables.enterMarkUp"
                                      )}
                                      type="text"
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      InputProps={{
                                        inputProps: {
                                          style: { textAlign: "right" },
                                        },
                                      }}
                                      sx={{
                                        pointerEvents: "none",
                                      }}
                                      onChange={(e) =>
                                        handleMarkUpChange(
                                          newProductLineItems,
                                          e,
                                          index2
                                        )
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              )}

                              <TableRow>
                                <TableCell>
                                  {t("tableHeadings.price")}&nbsp;(
                                  {CurrencySymbol})
                                </TableCell>

                                <TableCell
                                  scope="row"
                                  align="right"
                                  style={{ minWidth: 110, maxWidth: 140 }}
                                >
                                  <TextField
                                    name={`${newProductLineItems}.${index2}.Total`}
                                    {...register(
                                      `${newProductLineItems}.${index2}.Total`
                                    )}
                                    id="outlined-basic"
                                    placeholder={t(
                                      "textFieldLables.enterTotal"
                                    )}
                                    type="text"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    InputProps={{
                                      inputProps: {
                                        style: { textAlign: "right" },
                                      },
                                    }}
                                    disabled={true}
                                    sx={{
                                      pointerEvents: "none",
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            </>
                          </RestrictedComponents>

                          <TableRow>
                            <TableCell />

                            <TableCell scope="row" align="right">
                              {isEditDetails && (
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => {
                                    handleRemoveProductClick(index2);
                                  }}
                                  disabled={
                                    isPackage && isPakcageItemsCount < 3
                                  }
                                  sx={{
                                    pointerEvents:
                                      isPackage && isPakcageItemsCount < 3
                                        ? "none"
                                        : "auto",
                                    color:
                                      isPackage && isPakcageItemsCount < 3
                                        ? theme.palette.text.disabled
                                        : theme.palette.common.danger,
                                    marginTop: "-10px",
                                    marginLeft: "10px",
                                    "&:hover": {
                                      backgroundColor:
                                        theme.palette.common.danger,
                                      color: "white",
                                    },
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      )
                  )
                : null}

              {/* Packages Line Items */}
              {isEditDetails
                ? getValues(newPackageLineItems)?.map(
                    (item, index) =>
                      !item.IsDelete && (
                        <Fragment key={index}>
                          <TableRow>
                            <TableCell>
                              {t("tableHeadings.service/product")}
                            </TableCell>

                            <TableCell scope="row">
                              <Stack direction="row">
                                <FormControl fullWidth>
                                  <InputLabel
                                    id="demo-simple-select-required-label"
                                    size="small"
                                  >
                                    {t("labels.package")}
                                  </InputLabel>
                                  <Select
                                    name={`${newPackageLineItems}.${index}.PackageId`}
                                    {...register(
                                      `${newPackageLineItems}.${index}.PackageId`
                                    )}
                                    label={t("labels.selectPackage")}
                                    variant="outlined"
                                    size="small"
                                    value={
                                      getValues(
                                        `${newPackageLineItems}.[${index}].PackageId`
                                      ) || ""
                                    }
                                    onChange={(e) => {
                                      let selectedObj = packages?.find(
                                        (option) =>
                                          option.PackageId === e.target.value
                                      );

                                      setValue(
                                        `${newPackageLineItems}.[${index}].PackageId`,
                                        e.target.value
                                      );

                                      setValue(
                                        `${newPackageLineItems}.[${index}].UnitPrice`,
                                        parseFloat(selectedObj?.PackagePrice) ||
                                          0
                                      );

                                      setValue(
                                        `${newPackageLineItems}.[${index}].MarkUp`,
                                        0
                                      );

                                      setValue(
                                        `${newPackageLineItems}.[${index}].Total`,
                                        parseFloat(
                                          parseFloat(
                                            selectedObj?.PackagePrice || 0
                                          ).toFixed(2) *
                                            getValues(
                                              `${newPackageLineItems}.[${index}].Qty`
                                            )
                                        ) || 0
                                      );

                                      setValue(
                                        `${newPackageLineItems}[${index}].Title`,
                                        selectedObj?.PackageName || ""
                                      );

                                      setValue(
                                        `${newPackageLineItems}.[${index}].Description`,
                                        selectedObj?.PackageDescription || ""
                                      );

                                      setValue(
                                        `${newPackageLineItems}.[${index}].LineItemPackageDetail`,
                                        selectedObj?.PackageLineItems || []
                                      );
                                    }}
                                    title={
                                      packages?.find(
                                        (option) =>
                                          option.PackageId ===
                                          getValues(
                                            `${newPackageLineItems}.${index}.PackageId`
                                          )
                                      )?.PackageName ||
                                      t("textFieldLables.packageName")
                                    }
                                  >
                                    {packages?.map((option) => (
                                      <MenuItem
                                        key={option.PackageId}
                                        value={option.PackageId}
                                        disabled={
                                          !!(
                                            getValues(
                                              newPackageLineItems
                                            )?.find(
                                              (p) =>
                                                !p.IsDelete &&
                                                p.PackageId === option.PackageId
                                            ) ||
                                            getValues(lineItems)?.find(
                                              (p) =>
                                                !p.IsDelete &&
                                                p.PackageId === option.PackageId
                                            )
                                          )
                                        }
                                      >
                                        {option.PackageName}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Stack>
                            </TableCell>
                          </TableRow>

                          {item?.LineItemPackageDetail?.length > 0 ? (
                            <Tooltip
                              title={item?.LineItemPackageDetail?.map(
                                (obj, key) => {
                                  return (
                                    <Typography key={key}>
                                      {obj.LineItemName}
                                      {" - "}
                                      {obj.ProductId > 0 && "("}
                                      {obj.UnitPrice}
                                      {obj.ProductId > 0 &&
                                        "  +  " + obj.MarkUp}
                                      {obj.ProductId > 0 && ")"}
                                      {"  "}*{"  "}
                                      {obj.Qty}
                                      {"  "}={"  "}
                                      {obj.Total}
                                    </Typography>
                                  );
                                }
                              )}
                              placement="bottom"
                            >
                              <TableRow>
                                <TableCell>{t("tableHeadings.type")}</TableCell>

                                <TableCell
                                  scope="row"
                                  align="right"
                                  style={{ minWidth: 40, maxWidth: 60 }}
                                >
                                  <TextField
                                    id="outlined-basic"
                                    type="text"
                                    variant="outlined"
                                    size="small"
                                    value={t("labels.package")}
                                    disabled={!isEditDetails}
                                    sx={{
                                      pointerEvents: "none",
                                    }}
                                    fullWidth
                                    InputProps={{
                                      disableUnderline: true,
                                      inputProps: {
                                        style: {
                                          textAlign: isEditDetails
                                            ? "center"
                                            : "right",
                                          color:
                                            theme.palette.primary.contrastText,
                                          fontWeight:
                                            theme.typography.fontWeightMedium,
                                        },
                                      },
                                      style: {
                                        color:
                                          theme.palette.primary.contrastText,
                                        fontWeight:
                                          theme.typography.fontWeightMedium,
                                      },
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            </Tooltip>
                          ) : (
                            <TableRow>
                              <TableCell>{t("tableHeadings.type")}</TableCell>

                              <TableCell
                                scope="row"
                                align="right"
                                style={{ minWidth: 40, maxWidth: 60 }}
                              >
                                <TextField
                                  id="outlined-basic"
                                  type="text"
                                  variant="outlined"
                                  size="small"
                                  value={t("labels.package")}
                                  disabled={!isEditDetails}
                                  sx={{
                                    pointerEvents: "none",
                                  }}
                                  fullWidth
                                  InputProps={{
                                    disableUnderline: true,
                                    inputProps: {
                                      style: {
                                        textAlign: isEditDetails
                                          ? "center"
                                          : "right",
                                        color:
                                          theme.palette.primary.contrastText,
                                        fontWeight:
                                          theme.typography.fontWeightMedium,
                                      },
                                    },
                                    style: {
                                      color: theme.palette.primary.contrastText,
                                      fontWeight:
                                        theme.typography.fontWeightMedium,
                                    },
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          )}

                          <TableRow>
                            <TableCell>{t("tableHeadings.qty/hour")}</TableCell>

                            <TableCell
                              scope="row"
                              align="right"
                              style={{ minWidth: 40, maxWidth: 60 }}
                            >
                              <TextField
                                name={`${newPackageLineItems}.[${index}].Qty`}
                                {...register(
                                  `${newPackageLineItems}.[${index}].Qty`
                                )}
                                id="outlined-basic"
                                placeholder={t("textFieldLables.enterQuantity")}
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputProps={{
                                  inputProps: {
                                    style: { textAlign: "right" },
                                  },
                                }}
                                sx={{
                                  pointerEvents: isEditDetails
                                    ? "auto"
                                    : "none",
                                }}
                                onChange={(e) => {
                                  const userInput = e.target.value;

                                  let numericValue = e.target.value
                                    .replace(/[^\d.]/g, "") // Remove any characters that are not digits or a decimal point
                                    .replace(/\.(\d*)\./g, ".$1") // Allow only up to two decimal places
                                    .replace(/(\.\d\d)\d+$/, "$1") // Remove any additional decimal points;
                                    .replace(/^0/, ""); // Replace leading zeros with 1

                                  setValue(
                                    `${newPackageLineItems}.[${index}].Qty`,
                                    numericValue
                                  );

                                  if (userInput < 0 || userInput === null) {
                                    setValue(
                                      `${newPackageLineItems}.[${index}].Qty`,
                                      0
                                    );
                                  }

                                  const newQty = parseFloat(numericValue) || 0;

                                  const unitPrice =
                                    parseFloat(
                                      getValues(
                                        `${newPackageLineItems}.[${index}].UnitPrice`
                                      )
                                    ) || 0;

                                  const markUp =
                                    parseFloat(
                                      getValues(
                                        `${newPackageLineItems}.[${index}].MarkUp`
                                      )
                                    ) || 0;

                                  setValue(
                                    `${newPackageLineItems}.[${index}].Total`,
                                    (unitPrice + markUp) * newQty
                                  );
                                }}
                              />
                            </TableCell>
                          </TableRow>

                          <RestrictedComponents
                            permission={[
                              Permissions.ADD_JOB,
                              Permissions.ADD_QUOTE,
                              Permissions.ADD_INVOICE,
                            ]}
                          >
                            <>
                              <TableRow>
                                <TableCell>
                                  {t("tableHeadings.unitPrice")}&nbsp;(
                                  {CurrencySymbol})
                                </TableCell>

                                <TableCell
                                  scope="row"
                                  align="right"
                                  style={{ minWidth: 80, maxWidth: 150 }}
                                >
                                  <TextField
                                    name={`${newPackageLineItems}.[${index}].UnitPrice`}
                                    {...register(
                                      `${newPackageLineItems}.[${index}].UnitPrice`
                                    )}
                                    id="outlined-basic"
                                    placeholder={t(
                                      "textFieldLables.enterUnitPrice"
                                    )}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    InputProps={{
                                      inputProps: {
                                        style: { textAlign: "right" },
                                      },
                                    }}
                                    sx={{
                                      pointerEvents: isEditDetails
                                        ? "auto"
                                        : "none",
                                    }}
                                    onChange={(e) => {
                                      const userInput = e.target.value;

                                      let numericValue = userInput
                                        .replace(/[^\d.]/g, "") // Remove any characters that are not digits or a decimal point
                                        .replace(/\.(\d*)\./g, ".$1") // Allow only up to two decimal places
                                        .replace(/(\.\d\d)\d+$/, "$1"); // Remove any additional decimal points

                                      // Convert empty string to '0' to display when input is cleared
                                      if (numericValue === "") {
                                        numericValue = "0";
                                      }

                                      // Replace '0' if the input starts with '0' followed by a digit
                                      if (/^0\d/.test(numericValue)) {
                                        numericValue =
                                          numericValue.substring(1);
                                      }

                                      setValue(
                                        `${newPackageLineItems}.[${index}].UnitPrice`,
                                        numericValue
                                      );

                                      const newUnitPrice =
                                        parseFloat(numericValue) || 0;
                                      const markUp =
                                        parseFloat(
                                          getValues(
                                            `${newPackageLineItems}.[${index}].MarkUp`
                                          )
                                        ) || 0;
                                      const qty =
                                        parseFloat(
                                          getValues(
                                            `${newPackageLineItems}.[${index}].Qty`
                                          )
                                        ) || 0;

                                      // Display toast message if unit price is less than or equal to 0
                                      if (newUnitPrice <= 0) {
                                        if (!toast.isActive("unitPriceError")) {
                                          toast.error(
                                            t(
                                              "toasts.unitPriceMustBeGreaterThan0"
                                            ),
                                            { toastId: "unitPriceError" } // Add toastId to prevent duplicate toasts
                                          );
                                        }
                                      } else {
                                        toast.dismiss("unitPriceError"); // Dismiss any existing toast with the same message
                                      }

                                      setValue(
                                        `${newPackageLineItems}.[${index}].Total`,
                                        (newUnitPrice + markUp) * qty
                                      );
                                    }}
                                  />
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>
                                  {t("tableHeadings.price")}&nbsp;(
                                  {CurrencySymbol})
                                </TableCell>

                                <TableCell
                                  scope="row"
                                  align="right"
                                  style={{ minWidth: 110, maxWidth: 140 }}
                                >
                                  <TextField
                                    name={`${newPackageLineItems}.${index}.Total`}
                                    {...register(
                                      `${newPackageLineItems}.${index}.Total`
                                    )}
                                    id="outlined-basic"
                                    placeholder={t(
                                      "textFieldLables.enterTotal"
                                    )}
                                    type="text"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    InputProps={{
                                      inputProps: {
                                        style: { textAlign: "right" },
                                      },
                                    }}
                                    disabled={true}
                                    sx={{
                                      pointerEvents: "none",
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            </>{" "}
                          </RestrictedComponents>

                          <TableRow>
                            <TableCell />

                            <TableCell scope="row" align="right">
                              {isEditDetails && (
                                <IconButton
                                  aria-label="delete"
                                  disabled={
                                    isPackage
                                      ? isPakcageItemsCount < 3
                                      : isServiceCountOne
                                  }
                                  onClick={() => {
                                    handleRemovePackageClick(index);
                                  }}
                                  sx={{
                                    pointerEvents: (
                                      isPackage
                                        ? isPakcageItemsCount < 3
                                        : isServiceCountOne
                                    )
                                      ? "none"
                                      : "auto",
                                    color: (
                                      isPackage
                                        ? isPakcageItemsCount < 3
                                        : isServiceCountOne
                                    )
                                      ? theme.palette.text.disabled
                                      : theme.palette.common.danger,
                                    marginTop: "-10px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      )
                  )
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Stack direction={isDownMd ? "column" : "row"}>
        {!loading && ErrorService ? (
          <Grid
            container
            key={"error-rec1"}
            item
            md={6}
            xs={12}
            sx={{ mt: 2, mr: isDownMd ? 0 : 1 }}
          >
            <Alert severity="error">{t("alerts.maximumService")}</Alert>
          </Grid>
        ) : null}

        {!loading && ErrorProduct ? (
          <Grid
            container
            key={"error-rec2"}
            item
            md={6}
            xs={12}
            sx={{ mt: 2, ml: isDownMd ? 0 : 1 }}
          >
            <Alert severity="error">{t("alerts.maximumProduct")}</Alert>
          </Grid>
        ) : null}

        {!loading && ErrorPackage ? (
          <Grid
            container
            key={"error-rec3"}
            item
            md={6}
            xs={12}
            sx={{ mt: 2, ml: isDownMd ? 0 : 1 }}
          >
            <Alert severity="error">{t("alerts.maximumPackage")}</Alert>
          </Grid>
        ) : null}
      </Stack>
    </>
  );
};

export default LineItemsComponent;
