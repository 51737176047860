import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowBack, Info } from "@mui/icons-material";
import AddSharpIcon from "@mui/icons-material/AddSharp";

import {
  Alert,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  Menu,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { alpha, styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteJob,
  getAllJobQuestions,
  getJobById,
  putJob,
} from "src/api/jobApi";
import { changeStatus } from "src/api/requestApi";
import { getEmployeeBySkillSet } from "src/api/skillSetApi";
import { AuthContext } from "src/auth/AuthProvider";
import CustomerInfoGrid from "src/components/Common/CustomerInfoGrid";
import DeleteConfirmationDialog from "src/components/Common/DeleteConfirmationDialog";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordion from "@mui/material/Accordion";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import InvoiceSummary from "src/components/Common/InvoiceSummary";
import LineItemsComponent from "src/components/Common/LineItemsComponent";
import { calculateTotalAndNewTotal } from "src/components/Controls/Calculator";
import DateTimePickerTheme from "src/components/Controls/DateTimePickerTheme";
import FileUpload from "src/components/DropZone/FileUpload";
import {
  JOBIN_DOCUMENT_ID,
  JOBOUT_DOCUMENT_ID,
} from "src/components/DropZone/TypeConstant_config";
import JobDetailsComponent from "src/components/Job/JobDetailsComponent";
import * as yup from "yup";
import { ViewTaxCalculator } from "src/components/Controls/ViewTaxCalculator";
import SaveEditButton from "src/components/Common/Button/SaveEditButton";
import Header from "src/components/Header";
import EmployeesShowComponent from "src/components/Common/EmployeesShowComponent";
import { usePermission } from "src/context/PermissionContext";
import Permissions from "src/constants/Permissions";
import { JOB_DEPOSIT_ID } from "src/components/Payment/TypeConstant_config";

// necessary for content to be below app bar
const MainContainer = styled("main")(({ theme }) => ({
  // flexGrow: 1,
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2.2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5),
  },
}));

// necessary for content to be below app bar
const ToolbarContainer = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

const DeleteConfirmation = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "10px",
  padding: "40px",
  flexDirection: "column",
  alignItems: "center",
  boxShadow: "10px",
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
const getAssignedEmployeesText = (employees) => {
  if (!employees || employees.length === 0) {
    return "";
  }

  return employees
    .map((employeeDetails) => {
      const { EmployeeName, Phone } = employeeDetails;
      const phoneNumber = Phone ? ` - ${Phone}` : "";
      return `${EmployeeName}${phoneNumber}`;
    })
    .join("\n");
};

const OneTimeJobOV = ({
  customers,
  types,
  employees,
  services,
  products,
  taxes,
  questionnaireResponses,
  setQuestionnaireResponses,
  packages,
}) => {
  const auth = useContext(AuthContext);
  const token = auth.getAuthToken();
  const profile = auth.getProfile();
  const roleId = profile.RoleId;
  const dateFormat = auth.getProfile().DateFormat;
  const timeFormat = auth.getProfile().TimeFormat;
  const maxEventCount = auth.getProfile().MaxEventCount;

  const theme = useTheme();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);

  const [jobData, setJobData] = useState({});

  const { jobId } = useParams();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [isEditDetails, setIsEditDetails] = useState(false);

  const isDownMd = useMediaQuery(theme.breakpoints.down("lg"));
  const [showButtons, setShowButtons] = useState(false);

  const toggleButtons = () => {
    setShowButtons(!showButtons);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };
  const handleAccordionChange2 = (event, isExpanded2) => {
    setExpanded2(isExpanded2);
  };
  const handleAccordionChange3 = (event, isExpanded3) => {
    setShowQuestions(!showQuestions);
    setExpanded3(isExpanded3);
  };

  const jobDetails = {
    JobId: 0,
    CustomerId: 0,
    QuoteId: null,
    RequestId: null,
    AddressId: 0,
    JobStatusId: 0,
    Title: "",
    Description: "",
    StartDate: moment().format("YYYY-MM-DDTHH:mm"),
    StartTime: moment().format("YYYY-MM-DDTHH:mm"),
    EndDate: moment().format("YYYY-MM-DDTHH:mm"),
    EndTime: moment().format("YYYY-MM-DDTHH:mm"),
    Instruction: "",
    IsScheduled: false,
    IsOneOff: true,
    Total: 0,
    Discount: 0,
    CustomerMessage: "",
    UpdatedBy: "",
    IsActive: true,
    IsDelete: false,
    Schedule: null,
    BillingFrequency: null,
    IsFixed: null,
    JobLineItems: [
      {
        Title: "",
        LineItemId: 0,
        FieldId: 0,
        LineItemTypeName: "",
        ProductId: 0,
        ServiceId: 0,
        Qty: 0,
        Description: "",
        UnitPrice: 0,
        MarkUp: 0,
        Total: 0,
        Tax: 0,
        UpdatedBy: "",
        IsActive: true,
        IsDelete: false,
        PermissionId: 0,
        RoleId: 0,
        LineItemTaxDetail: [],
      },
    ],
    EditCustomFieldValues: [],
    ClientId: 0,
    Employees: [
      {
        EmployeeId: 0,
        ScheduleId: 0,
        IsCompleted: true,
        UpdatedBy: "",
        IsDelete: false,
      },
    ],
    visits: [
      {
        ClientId: 0,
        ScheduleId: 0,
        ScheduleType: 0,
        FieldId: 0,
        Title: "",
        Instructions: "",
        IsScheduled: true,
        StartDate: moment().format("YYYY-MM-DDTHH:mm"),
        EndDate: moment().format("YYYY-MM-DDTHH:mm"),
        IsAnytime: false,
        StartTime: moment().format("YYYY-MM-DDTHH:mm"),
        EndTime: moment().format("YYYY-MM-DDTHH:mm"),
        UpdatedBy: "",
        IsDelete: false,
        Employees: [
          {
            EmployeeId: 0,
            ScheduleId: 0,
            IsCompleted: true,
            UpdatedBy: "",
            IsDelete: false,
          },
        ],
        TypeName: null,
      },
    ],
    VisitDates: [moment().format("YYYY-MM-DDTHH:mm")],
    InvoiceDates: [moment().format("YYYY-MM-DDTHH:mm")],
    AddressLine1: "",
    CountryId: 0,
    ZipCode: "",
    StateId: 0,
    City: "",
    Latitude: 0,
    Longitude: 0,
    RoleId: 0,
    PermissionId: 0,
    NewEmployees: [],
    NewLineItems: [],
    NewProductLineItems: [],
    NewPackageLineItems: [],
    isRecurred: false,
    everyValue: "",
    everyType: "days",
    forValue: "",
    forType: "weeks",
    sameRange: false,
    QuestionResponses: [
      {
        QuestionText: "string",
        QuestionTypeName: "string",
        AnswersList: [
          {
            AnswerId: 0,
            AnswerText: "string",
            QuestionId: 0,
          },
        ],
      },
    ],
    IsFilterBySkillSet: false,
    Type: "",
  };

  const [IsItScheduled, setIsItScheduled] = useState(false);

  const validationSchema = yup.object().shape({
    // JobTypeId: yup.string().required("Required"),
    Description: yup
      .string()
      .max(500, t("validations.characterLimitExceeded"))
      .required(t("validations.required")),
    Employee: yup.string(),
    CustomerMessage: yup
      .string()
      .max(500, t("validations.characterLimitExceeded")),
    // Notes: yup.string().max(500, t("validations.characterLimitExceeded")),
    Instruction: yup.string().max(500, t("validations.characterLimitExceeded")),
    // .required("Required!"),
    IsScheduled: yup.bool(),
    visits: yup.array().of(
      yup.object().shape({
        StartDate: yup
          .string()
          .test(
            "test",
            t("validations.startDateLessThanEndDate"),
            (value, testContext) => {
              if (!IsItScheduled) {
                return true;
              } else {
                if (value > testContext.parent.EndDate) {
                  return false;
                } else {
                  return true;
                }
              }
            }
          )
          .test("test2", t("validations.dateRequired"), (value2) => {
            if (!IsItScheduled) {
              return true;
            } else {
              if (value2 !== null || !!value2) {
                return true;
              } else {
                return false;
              }
            }
          }),
        EndDate: yup
          .string()
          // .required("Date Required!")
          .test(
            "test",
            t("validations.endDateGreaterThanStartDate"),
            (value, testContext) => {
              if (!IsItScheduled) {
                return true;
              } else {
                if (value < testContext.parent.StartDate) {
                  return false;
                } else {
                  return true;
                }
              }
            }
          )
          .test("test2", t("validations.dateRequired"), (value2) => {
            if (!IsItScheduled) {
              return true;
            } else {
              if (value2 !== null || !!value2) {
                return true;
              } else {
                return false;
              }
            }
          }),
        StartTime: yup
          .string()
          // .required("Time Required")
          // .test(
          //   "test",
          //   t("validations.StartTimeLessThanEndTime"),
          //   (value, testContext) => {
          //     if (!IsItScheduled) {
          //       return true;
          //     } else {
          //       if (
          //         (testContext.parent.StartDate ===
          //           testContext.parent.EndDate &&
          //           value >= testContext.parent.EndTime) ||
          //         value === testContext.parent.EndTime
          //       ) {
          //         return false;
          //       } else {
          //         return true;
          //       }
          //     }
          //   }
          // )
          .test("test2", t("validations.timeRequired"), (value2) => {
            if (!IsItScheduled) {
              return true;
            } else {
              if (value2 !== null || !!value2) {
                return true;
              } else {
                return false;
              }
            }
          }),
        EndTime: yup
          .string()
          // .required("Time Required")
          // .test(
          //   "test",
          //   t("validations.EndTimeGreaterThanStartTime"),
          //   (value, testContext) => {
          //     if (!IsItScheduled) {
          //       return true;
          //     } else {
          //       if (
          //         (testContext.parent.StartDate ===
          //           testContext.parent.EndDate &&
          //           value <= testContext.parent.StartTime) ||
          //         value === testContext.parent.StartTime
          //       ) {
          //         return false;
          //       } else {
          //         return true;
          //       }
          //     }
          //   }
          // )
          .test("test2", t("validations.timeRequired"), (value2) => {
            if (!IsItScheduled) {
              return true;
            } else {
              if (value2 !== null || !!value2) {
                return true;
              } else {
                return false;
              }
            }
          }),
      })
    ),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: jobDetails,
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  watch();

  const [isScheduled, setIsScheduled] = useState(getValues(`IsScheduled`));

  useEffect(() => {
    setIsScheduled(getValues(`IsScheduled`));
  }, [getValues(`IsScheduled`)]);

  {
    /** -----------------------Employees----------------------------- */
  }

  const [isCrossedOut, setIsCrossedOut] = useState([]);

  const toggleCrossedOut = (index) => {
    const updatedCrossedOut = [...isCrossedOut];
    updatedCrossedOut[index] = !updatedCrossedOut[index];
    setIsCrossedOut(updatedCrossedOut);
    if (isCrossedOut[index]) {
      setValue(`visits.[0].Employees[${index}].IsDelete`, false);
    } else {
      setValue(`visits.[0].Employees[${index}].IsDelete`, true);
    }
  };

  const addEmployeeRecord = () => {
    getEmployeeIds();
    let newArray = [
      ...getValues(`NewEmployees`),
      {
        EmployeeId: 0,
        ScheduleId: 0,
        IsCompleted: true,
        UpdatedBy: "",
        IsDelete: false,
      },
    ];
    setValue(`NewEmployees`, newArray);
  };

  const handleRemoveEmployee = (index) => {
    getEmployeeIds();
    setEmployeeIds((pre) =>
      pre.filter(
        (data) => data !== getValues(`NewEmployees[${index}].EmployeeId`)
      )
    );
    setValue(`NewEmployees[${index}].IsDelete`, true);
  };

  const [employeeIds, setEmployeeIds] = useState([]);

  const getEmployeeIds = () => {
    const filteredIds = !!getValues(`visits[0].Employees`)
      ? getValues(`visits[0].Employees`)
          .filter((obj) => obj.IsDelete === false)
          .map((obj) => obj.EmployeeId)
      : [];
    setEmployeeIds((pre) => [...pre, ...filteredIds]);
  };

  {
    /** -----------------------LineItemComponent----------------------- */
  }
  const [isLineItemsCrossedOut, setIsLineItemsCrossedOut] = useState([]);

  const jobLineItems = getValues(`JobLineItems`);

  {
    /** -----------------------LineItems----------------------------- */
  }

  const [TypeStatus, setTypeStatus] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

    // setOpen(!open);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let toastShown = false;

  const handletypeChange = (event) => {
    setAnchorEl(null);

    // Flag to track if toast has been shown
    let toastShown = false;

    const handleSuccess = () => {
      if (!toastShown) {
        toast.success(t("toasts.successfullyChangedTheStatus"));
        toastShown = true; // Set flag to true after showing the toast
      }
      setTypeStatus(event.target.outerText);
      if (event.target.outerText === "Cancel") {
        setJobData({ ...jobData, IsConvertable: false });
        setTypeStatus("Cancelled");
      }
    };

    const handleError = () => {
      if (!toastShown) {
        toast.error(t("toasts.failedToChangeTheStatus"));
        toastShown = true; // Set flag to true after showing the toast
      }
    };

    // First API call
    changeStatus(token, parseInt(jobId), event.target.outerText, "3")
      .then((response) => {
        if (response?.STATUS_CODE === "200") {
          handleSuccess();
        } else {
          handleError();
        }
      })
      .catch((error) => {
        handleError();
      });

    // Second API call
    const visitScheduleId = parseInt(getValues(`visits.[0].ScheduleId`));
    if (!isNaN(visitScheduleId)) {
      changeStatus(token, visitScheduleId, event.target.outerText, "5")
        .then((response) => {
          if (response?.STATUS_CODE === "200") {
            handleSuccess();
          } else {
            handleError();
          }
        })
        .catch((error) => {
          handleError();
        });
    }
  };

  const serviceItemsCount =
    getValues(`JobLineItems`)?.filter(
      (item) => item.ServiceId > 0 && !item?.IsDelete
    )?.length +
    getValues(`JobLineItems`)?.filter(
      (item) =>
        parseInt(item?.PackageId) > 0 &&
        !item?.IsDelete &&
        item?.LineItemPackageDetail?.some(
          (lineItem) => lineItem.ServiceId > 0 || lineItem.ProductId > 0
        )
    )?.length +
    getValues(`NewLineItems`)?.filter((item) => !item?.IsDelete)?.length +
    getValues(`NewPackageLineItems`)?.filter(
      (item) =>
        !item?.IsDelete &&
        item?.LineItemPackageDetail?.some(
          (lineItem) => lineItem.ServiceId > 0 || lineItem.ProductId > 0
        )
    )?.length;

  const toggleLineItemsCrossedOut = (index) => {
    const isService = getValues(`JobLineItems[${index}].ServiceId`) > 0;
    const isPackage = getValues(`JobLineItems[${index}].PackageId`) > 0;

    const updatedLineItemsCrossedOut = [...isLineItemsCrossedOut];
    updatedLineItemsCrossedOut[index] = !updatedLineItemsCrossedOut[index];
    setIsLineItemsCrossedOut(updatedLineItemsCrossedOut);

    if (isService || isPackage) {
      if (serviceItemsCount > 1) {
        if (isLineItemsCrossedOut[index]) {
          setValue(`JobLineItems[${index}].IsDelete`, false);
        } else {
          setValue(`JobLineItems[${index}].IsDelete`, true);
        }
      } else {
        updatedLineItemsCrossedOut.splice(index, 1);
        setIsLineItemsCrossedOut(updatedLineItemsCrossedOut);

        setValue(`JobLineItems[${index}].IsDelete`, false);
        toast.dismiss();
        toast.error(t("toasts.thereMustBeAtleastOneServiceLeft"));
      }
    } else {
      if (isLineItemsCrossedOut[index]) {
        setValue(`JobLineItems[${index}].IsDelete`, false);
      } else {
        setValue(`JobLineItems[${index}].IsDelete`, true);
      }
    }
  };

  const addServiceRecord = () => {
    let newArray = [
      ...getValues(`NewLineItems`),
      {
        ServiceId: 0,
        Qty: 1,
        Title: "",
        Description: "",
        UnitPrice: 0,
        MarkUp: 0,
        Total: 0,
        Tax: 0,
        IsService: true,
        IsActive: true,
      },
    ];
    setValue(`NewLineItems`, newArray);
  };

  const addProductRecord = () => {
    let newProductArray = [
      ...getValues(`NewProductLineItems`),
      {
        ProductId: 0,
        Qty: 1,
        Title: "",
        Description: "",
        UnitPrice: 0,
        MarkUp: 0,
        Total: 0,
        Tax: 0,
        IsPackage: false,
        IsService: false,
        IsActive: true,
      },
    ];
    setValue(`NewProductLineItems`, newProductArray);
  };

  const handleRemoveClick = (index) => {
    if (serviceItemsCount > 1) {
      setValue(`NewLineItems[${index}].IsDelete`, true);
    } else {
      setValue(`NewLineItems[${index}].IsDelete`, false);
      toast.dismiss();
      toast.error(t("toasts.thereMustBeAtleastOneServiceLeft"));
    }
  };

  const handleRemoveProductClick = (index) => {
    setValue(`NewProductLineItems[${index}].IsDelete`, true);
  };

  const addPackageRecord = () => {
    let newPackageArray = [
      ...getValues(`NewPackageLineItems`),
      {
        PackageId: 0,
        Qty: 1,
        Title: "",
        Description: "",
        UnitPrice: 0,
        MarkUp: 0,
        Total: 0,
        Tax: 0,
        IsPackage: true,
        IsActive: true,
        LineItemPackageDetail: [],
      },
    ];
    setValue(`NewPackageLineItems`, newPackageArray);
  };

  const handleRemovePackageClick = (index) => {
    if (serviceItemsCount > 1) {
      setValue(`NewPackageLineItems[${index}].IsDelete`, true);
    } else {
      setValue(`NewPackageLineItems[${index}].IsDelete`, false);
      toast.dismiss();
      toast.error(t("toasts.thereMustBeAtleastOneServiceLeft"));
    }
  };

  {
    /** --------------------------------------------------------- */
  }

  const menuItems = [
    {
      name: "Cancel",
      label: "Cancel",
      // checked: printDetails.Filter,
    },
    {
      name: "Ongoing",
      label: "Ongoing",
      // checked: printDetails.Filter,
    },
    {
      name: "Completed",
      label: "Completed",
      // checked: printDetails.Filter,
    },
    // {
    //   name: "sortBy",
    //   label: "SortBy",
    //   checked: printDetails.sortBy,
    // },
  ];

  const [jobLoading, setJobLoading] = useState(false);

  const getJob = async () => {
    setJobLoading(true);
    let job = await getJobById(
      token,
      jobId,
      moment().format("YYYY-MM-DDTHH:mm:ss"),
      roleId
    );

    if (job?.STATUS_CODE !== "200") {
      toast.error(t("toasts.jobDetailsLoadingFailed"));
      setJobLoading(false);
    } else {
      setJobData({
        ...job?.DATA,
        JobLineItems: job?.DATA?.JobLineItems?.filter(
          (item) => item.Total !== 0
        ),
      });
      setValue("NewLineItems", []);
      setValue("NewProductLineItems", []);
      setJobLoading(false);
    }
  };

  useEffect(() => {
    jobId > 0 && getJob();
  }, [jobId]);

  // const [questions, setQuestions] = useState([]);
  const [jobCount, setJobCount] = useState(0);
  useEffect(() => {
    if (jobId > 0 && !!jobData) {
      for (const [key, value] of Object.entries(jobData)) {
        setValue(key, value);
        if (key === "visits") {
          !value?.length > 0
            ? setValue("visits", [
                {
                  ScheduleId: 0,
                  IsScheduled: true,
                  StartDate: "",
                  EndDate: "",
                  StartTime: "",
                  EndTime: "",
                },
              ])
            : setValue("visits", value);
          setJobCount(value?.length);
        }

        if (key in ["StartDate", "EndDate"]) {
          setValue(key, moment(value).format("YYYY-MM-DDTHH:mm"));
        }
        if (key === "StartTime") {
          setValue(key, jobData?.StartDate);
        }
        if (key === "EndTime") {
          setValue(key, jobData?.EndDate);
        }
        if (key === "ForValue") {
          setValue("forValue", value?.split(",")?.[0]);
          setValue("forType", value?.split(",")?.[1]);
        }
        if (key === "EveryValue") {
          setValue("everyValue", value?.split(",")?.[0]);
          setValue("everyType", value?.split(",")?.[1]);
        }

        if (key === "IsScheduled") {
          setIsItScheduled(jobData?.IsScheduled);
        }
      }
    }
  }, [jobData, jobId]);
  watch();

  const CurrencySymbol = auth.getProfile().CurrencySymbol;

  /** -------------------- Tax Calculator --------------------------------- */

  const { taxTotal, taxDetails } = ViewTaxCalculator({
    lineItems: "JobLineItems",
    serviceItems: "NewLineItems",
    productItems: "NewProductLineItems",
    packageItems: "NewPackageLineItems",
    services: services,
    products: products,
    taxes: taxes,
    getValues: getValues,
    details: jobData?.JobLineItems,
  });

  /** --------------------------------------------------------------------- */

  {
    /**------------------------Calculations----------------------- */
  }

  const {
    subNewTotal,
    totalNewTotal,
    serviceTotal,
    productTotal,
    packageTotal,
  } = calculateTotalAndNewTotal({
    lineItems: "JobLineItems",
    newServiceItems: "NewLineItems",
    newProductItems: "NewProductLineItems",
    newPackageItems: "NewPackageLineItems",
    taxTotal: taxTotal,
    getValues: getValues,
  });

  const [discount, setDiscount] = useState(``);

  const handleDiscountChange = (event) => {
    const inputValue = event.target.value;
    setDiscount(inputValue);
  };

  const inputWidth = (discount.length + 1) * 10 + `px`;

  const Totaltotal = totalNewTotal - getValues("Discount");

  const allItems = [
    ...getValues("NewLineItems"),
    ...getValues("NewProductLineItems"),
    ...getValues("NewPackageLineItems"),
  ];

  const NoTotal = allItems.find((item) => !item.IsDelete && item.Total == 0);

  const hasNoTotal = Boolean(NoTotal);

  const onSubmit = async (values) => {
    toast.dismiss();

    const arrVisit = values.visits.filter((data) => data.IsDelete != true);
    const lenVisits = arrVisit.length;

    let startDate = moment(arrVisit?.[0]?.StartDate).format("YYYY-MM-DD");
    let startTime = moment(arrVisit?.[0]?.StartTime).format("HH:mm:ss");

    let endDate = moment(arrVisit?.[lenVisits - 1]?.EndDate).format(
      "YYYY-MM-DD"
    );
    let endTime = moment(arrVisit?.[lenVisits - 1]?.EndTime).format("HH:mm:ss");

    const lineItemArr = [
      ...getValues("NewLineItems").filter((lineItem) => !lineItem.IsDelete),
      ...getValues("JobLineItems").filter((lineItem) => !lineItem.IsDelete),
      ...getValues("NewProductLineItems").filter(
        (lineItem) => !lineItem.IsDelete
      ),
    ];

    getValues("NewEmployees")?.forEach((employeeId) => {
      if (employeeId.EmployeeId > 0) {
        const currentEmployees = getValues("visits.[0].Employees");

        if (!currentEmployees || currentEmployees?.length === 0) {
          setValue("visits.[0].Employees", []);
        }

        const updatedEmployees = getValues("visits.[0].Employees");

        updatedEmployees?.push(employeeId);
        setValue("visits.[0].Employees", updatedEmployees);
      }
    });

    const v = values.JobLineItems.map((option) => {
      return {
        ...option,
        ServiceId: option?.ServiceId !== "" ? option?.ServiceId : 0,
        ProductId: option?.ProductId !== "" ? option?.ProductId : 0,
        PackageId: option?.PackageId !== "" ? option?.PackageId : 0,
        Qty:
          option.Qty === null || option.Qty === ""
            ? 0
            : option?.ServiceId > 0
            ? parseFloat(option.Qty)
            : parseInt(option.Qty),

        UnitPrice:
          option.UnitPrice === null || option.UnitPrice === ""
            ? 0
            : parseFloat(option.UnitPrice),

        LineItemTaxDetail: [
          ...(option?.LineItemTaxDetail || []),
          ...(option?.DeleteLineItemTaxDetail || []),
        ],
      };
    })?.map((option) => {
      const { DeleteLineItemTaxDetail, ...objects } = option;
      return objects;
    });

    const NewV = values.NewLineItems.filter((options) => !options.IsDelete).map(
      (options) => {
        return {
          ...options,
          ServiceId: options?.ServiceId !== "" ? options?.ServiceId : 0,
          ProductId: null,
          PackageId: null,
          Qty:
            options.Qty === null || options.Qty === ""
              ? 0
              : parseFloat(options.Qty),
          UnitPrice:
            options.UnitPrice === null || options.UnitPrice === ""
              ? 0
              : parseFloat(options.UnitPrice),
        };
      }
    );

    const NewProductV = values.NewProductLineItems.filter(
      (options) => !options.IsDelete
    ) // Filter items where IsDelete is false
      .map((options) => {
        return {
          ...options,
          ServiceId: null,
          ProductId: options?.ProductId !== "" ? options?.ProductId : 0,
          PackageId: null,
          Qty:
            options.Qty === null || options.Qty === ""
              ? 0
              : parseInt(options.Qty),
          UnitPrice:
            options.UnitPrice === null || options.UnitPrice === ""
              ? 0
              : parseFloat(options.UnitPrice),
        };
      });

    const NewPackage = values.NewPackageLineItems?.filter(
      (options) => !options.IsDelete
    )?.map((options) => {
      return {
        ...options,
        ServiceId: null,
        ProductId: null,
        PackageId: options?.PackageId !== "" ? options?.PackageId : 0,
        Qty:
          options.Qty === null || options.Qty === ""
            ? 0
            : parseFloat(options.Qty),
        UnitPrice:
          options.UnitPrice === null || options.UnitPrice === ""
            ? 0
            : parseFloat(options.UnitPrice),
      };
    });

    const updatedV = v.concat(NewV, NewProductV, NewPackage);

    setValue(`IsScheduled`, isScheduled);
    const lenItemsArr = lineItemArr.length;
    const dataRecord = {
      ...values,
      JobLineItems: updatedV,
      TaxTotal: taxTotal,
      Total:
        Totaltotal === null || Totaltotal === "" ? 0.0 : parseFloat(Totaltotal),
      Discount:
        getValues("Discount") == null ? 0.0 : parseFloat(getValues("Discount")),
      TaxTotal: taxTotal,

      RequestStatusId: parseInt(values.RequestStatusId),
      AddressId: parseInt(values.AddressId),

      ...(isScheduled && lenVisits >= 1
        ? {
            StartDate: moment(`${startDate} ${startTime}`).format(
              "YYYY-MM-DDTHH:mm"
            ),

            EndDate: moment(`${endDate} ${endTime}`).format("YYYY-MM-DDTHH:mm"),

            StartTime: moment(`${startDate} ${startTime}`).format(
              "YYYY-MM-DDTHH:mm"
            ),

            EndTime: moment(`${endDate} ${endTime}`).format("YYYY-MM-DDTHH:mm"),
            Visits: values.visits,
            visits: values.visits,
            IsScheduled: isScheduled,
            Employees: !!values.visits?.[0]?.Employees
              ? values.visits?.[0]?.Employees
              : [],
          }
        : {
            StartDate: null,
            EndDate: null,
            EndTime: null,
            StartTime: null,
            Visits: [],
            Employees: [],
            IsScheduled: false,
            visits: [],
          }),
      IsOneOff: values.visits.length > 1 ? false : true,
      ForValue: values.forValue + "," + values.forType,
      EveryValue: values.everyValue + "," + values.everyType,
      UpdatedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
    };

    if (jobId > 0 && !values.IsDelete && lenItemsArr > 0) {
      if (moment(dataRecord?.EndDate).isBefore(moment(dataRecord?.StartDate))) {
        toast.error(t("toasts.endDateShouldBeFutureDateThanTheStartDate"));
      } else if (getValues("Discount") > subNewTotal) {
        toast.error(t("toasts.discountValueIsTooLarge"));
      } else if (serviceItemsCount === 0) {
        toast.error(t("toasts.thereMustBeAtleastOneServiceLeft"));
      } else if (hasNoTotal) {
        toast.error(
          t("toasts.eachServiceProductPackageNeedsToHavePriceAndTotal")
        );
      } else {
        if (!dataRecord?.IsScheduled) {
          dataRecord.VisitDates = [];
          dataRecord.InvoiceDates = [];
        }
        setLoading(true);

        await putJob(token, jobId, dataRecord)
          .then((response) => {
            if (response?.STATUS_CODE === "200") {
              getJob();
              toast.success(t("toasts.jobUpdatedSuccessfully"));
              setValue(`NewLineItems`, []);
              setValue(`NewProductLineItems`, []);
              setValue(`NewPackageLineItems`, []);
              setValue(`NewEmployees`, []);
              setValue("visits", []);
              setIsCrossedOut([]);
              setEmployeeIds([]);
              setLoading(false);
              setIsEditDetails(false);
              if (dataRecord.IsOneOff === false) {
                navigate(`/jobs/recurring-job/${jobId}`);
              }
              // getSchedule();
            } else {
              toast.error(t("toasts.failedToUpdateJob"));
              setLoading(false);
            }
          })
          .catch((e) => {
            toast.error(t("toasts.failedToUpdateJob"));
            setLoading(false);
          });
      }
    } else if (values.IsDelete) {
      await putJob(token, jobId, {
        ...dataRecord,
        IsDelete: true,
        QuestionResponses: values?.QuestionResponses,
      })
        .then((response) => {
          if (response?.STATUS_CODE === "200") {
            toast.success(t("toasts.jobDeletedSuccessfully"));
            setLoading(false);
            navigate("/jobs");
          } else {
            toast.error(t("toasts.failedToDeleteJob"));
            setValue("IsDelete", false);
            setLoading(false);
          }
        })
        .catch((e) => {
          toast.error(t("toasts.failedToDeleteJob"));
          setLoading(false);
        });
    }
  };

  const [showQuestions, setShowQuestions] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [QuestionAnswers, setQuestionAnswers] = useState(false);

  const onChangeJobData = (e) => {
    setValue(e.target.name, e.target.value);
  };

  const handleEditDetails = () => {
    setValue(`NewLineItems`, []);
    setValue(`NewProductLineItems`, []);
    setValue(`NewPackageLineItems`, []);
    setValue(`NewEmployees`, []);
    setIsCrossedOut([]);
    setEmployeeIds([]);
    setIsEditDetails(!isEditDetails);
    // setEditedResponses([...questionResponses]);
  };

  const onSaveDetails = () => {
    setIsEditDetails(false);
  };

  const handleCancelButtonClick = () => {
    setIsEditDetails(false);
    // setJobData({});
    reset(jobData);
    toast.info("Edit canceled!");
    setValue(`NewLineItems`, []);
    setValue(`NewProductLineItems`, []);
    setValue(`NewPackageLineItems`, []);
    setValue(`NewEmployees`, []);
    setIsCrossedOut([]);
    setEmployeeIds([]);
    setRecError("");
    // setValue("IsScheduled", false);
    // setIsScheduled(false);
  };

  const handleConvertToInvoice = () => {
    setTimeout(() => {
      navigate("/invoices/new-invoice", {
        state: {
          isConvert: true,
          isFromJob: !!parseInt(jobId) ? parseInt(jobId) : 0,
        },
      });
    }, 1000);
  };

  /*==========================================================================*/

  const [recurringLoading, setRecurringLoading] = useState(false);
  const [recError, setRecError] = useState("");

  const handleShowDateTimeFields = (date = null) => {
    if (
      getValues("everyValue") === "" ||
      getValues("forValue") === "" ||
      !getValues("everyValue") ||
      !getValues("forValue")
    ) {
      return;
    }
    setRecurringLoading(true);
    const dates = [];
    const everyValue = parseInt(getValues("everyValue"));
    const forValue = parseInt(getValues("forValue"));

    let currentDate = !date ? moment() : moment(date);

    const lastDate = (!date ? moment() : moment(date)).add(
      forValue,
      getValues("forType")
    );

    const startdate = moment(currentDate);
    let val = 0;

    let enddate = moment(currentDate);
    while (currentDate <= lastDate) {
      if (enddate <= lastDate && val < maxEventCount) {
        val++;
        dates.push({
          StartDate: currentDate.format("YYYY-MM-DDTHH:mm"),
          EndDate: currentDate.format("YYYY-MM-DDTHH:mm"),
          StartTime: moment().format("YYYY-MM-DDTHH:mm"),
          EndTime: moment().format("YYYY-MM-DDTHH:mm"),
        });
      }
      if (val < maxEventCount) {
        setRecError("");
      } else {
        val === maxEventCount
          ? setRecError(
              `You have reached the maximum job limit of ${maxEventCount}!`
            )
          : setRecError("");
        break;
      }
      enddate = moment(currentDate).add(everyValue - 1, getValues("everyType"));
      currentDate.add(everyValue, getValues("everyType"));
    }

    setValue("visits", dates);

    if (dates.length === 0) {
      toast.error(t("toasts.pleaseChooseValidDataForRecurringJobs"));
    }
    setJobCount(dates.length);
    setRecurringLoading(false);
  };

  const handleSameTimeRange = (val) => {
    // if (jobCount < 2) return;
    const prevData = getValues("visits");

    const startTime = prevData?.[0]?.StartTime;
    const endTime = prevData?.[0]?.EndTime;

    const updatedDates = prevData.map((date, index) => {
      if (index !== 0) {
        return { ...date, StartTime: startTime, EndTime: endTime };
      }
      return date;
    });
    setValue("visits", updatedDates);
  };

  useEffect(() => {
    if (!getValues("sameRange")) return;
    handleSameTimeRange(getValues("sameRange"));
  }, [
    getValues("visits")?.[0]?.StartTime,
    getValues("visits")?.[0]?.EndTime,
    getValues("sameRange"),
  ]);

  const handleSelectDateChange = (date, index) => {
    const selectedDate = moment(date);
    setValue(
      `visits.${index}.EndDate`,
      selectedDate.format("YYYY-MM-DDTHH:mm")
    );

    if (
      moment(getValues(`visits.${index + 1}.StartDate`)).isSameOrBefore(
        selectedDate
      )
    ) {
      const updatedDates = getValues("visits").map((date, i) => {
        if (index < i) {
          selectedDate.add(getValues("everyValue"), getValues("everyType"));
          return {
            ...date,
            StartDate: selectedDate.format("YYYY-MM-DDTHH:mm"),
            EndDate: selectedDate.format("YYYY-MM-DDTHH:mm"),
          };
        } else if (index === i) {
          return {
            ...date,
            EndDate: selectedDate.format("YYYY-MM-DDTHH:mm"),
          };
        }
        return date;
      });
      setValue("visits", updatedDates);
    }
  };

  const handleRemoveClickOne = (index) => {
    const prevData = getValues("visits");

    const updatedVisitDates = prevData
      .map((item, ind) => {
        if (item.ScheduleId > 0 && index === ind) {
          return { ...item, IsDelete: true };
        } else if ((item.ScheduleId < 1 || !item.ScheduleId) && index === ind) {
          return null;
        }
        return item;
      })
      .filter(Boolean);
    setValue("visits", updatedVisitDates);
    setJobCount((count) => count - 1);
    setRecError("");
  };

  const CheckDateTime = (index) => {
    const startDate = moment(getValues(`visits.${index}.StartDate`));
    const endDate = moment(getValues(`visits.${index}.EndDate`));
    const startTime = moment(getValues(`visits.${index}.StartTime`));
    const endTime = moment(getValues(`visits.${index}.EndTime`));

    const sameDate = startDate.isSame(endDate, "day");
    const startTimeBeforeEndTime = startTime.isSameOrBefore(endTime, "hour");

    return [sameDate, startTimeBeforeEndTime];
  };

  {
    /** -----------------------LineItemComponent----------------------- */
  }

  const [jobLineItemsLength, setJobLineItemsLength] = useState(1);

  useEffect(() => {
    setJobLineItemsLength(getValues(`JobLineItems`)?.length);
  }, [getValues(`JobLineItems`)]);

  const [isHovering, setIsHovering] = useState(false);

  {
    /** -----------------------LineItemComponentFilter------------------------ */
  }

  /*=============================QUESTIONNAIRES=============================================*/
  const [groupedResponses, setGroupedResponses] = useState([]);
  useEffect(() => {
    if (showQuestions) {
      getAllJobQuestions(token, {
        RequestId: getValues("RequestId"),
        QuoteId: getValues("QuoteId"),
      })
        .then((res) => {
          setGroupedResponses(res?.DATA);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [showQuestions]);

  useEffect(() => {
    var arr = [];
    questions.map((q, i) => {
      arr.push({
        SurveyId: q.SurveyId,
        QuestionId: q.QuestionId,
        QuestionText: q.QuestionText,
        AnswerId: null,
        AnswerText: null,
      });
    });

    setValue("QuestionnaireResponses", arr);
  }, [QuestionAnswers]);

  // const groupedResponses = questionRseponses.reduce((acc, response) => {
  //   const { QuestionId, AnswerText } = response;
  //   if (!acc[QuestionId]) {
  //     acc[QuestionId] = { QuestionText: response.QuestionText, answers: [] };
  //   }
  //   acc[QuestionId].answers.push(AnswerText);
  //   return acc;
  // }, {});

  /** ----------------------Skill Set ------------------------------ */
  const [employeeList, setEmployeeList] = useState(null);

  const fetchEmployees = (dataRecord) => {
    getEmployeeBySkillSet(token, dataRecord)
      .then((response) => {
        setEmployeeList(response?.DATA);
      })
      .catch((e) => {
        setEmployeeList([]);
      });
  };

  useEffect(() => {
    let jobLineItems = getValues("JobLineItems") || [];
    let newLineItems = getValues("NewLineItems") || [];
    let serviceLineItems = [...jobLineItems, ...newLineItems]
      .filter((obj) => parseInt(obj?.ServiceId) > 0 && !obj.IsDelete)
      .map((item) => String(item.ServiceId));
    if (
      getValues("IsScheduled") &&
      getValues("IsFilterBySkillSet") &&
      !getValues("isRecurred") &&
      getValues(`visits.${0}.StartDate`) !== "" &&
      getValues(`visits.${0}.StartTime`) !== "" &&
      getValues(`visits.${0}.EndDate`) !== "" &&
      getValues(`visits.${0}.EndTime`) !== "" &&
      serviceLineItems?.length > 0
    ) {
      fetchEmployees({
        ServiceIdList: String(serviceLineItems.join(",")),
        StartDate: moment(
          moment(getValues(`visits.${0}.StartDate`)).format("YYYY-MM-DD") +
            " " +
            moment(getValues(`visits.${0}.StartTime`)).format("HH:mm:ss")
        ).format("YYYY-MM-DDTHH:mm:ss"),
        EndDate: moment(
          moment(getValues(`visits.${0}.EndDate`)).format("YYYY-MM-DD") +
            " " +
            moment(getValues(`visits.${0}.EndTime`)).format("HH:mm:ss")
        ).format("YYYY-MM-DDTHH:mm:ss"),
        IsFilterBySkillSet: true,
      });
    } else if (getValues("IsScheduled") && getValues("isRecurred")) {
      setEmployeeList(employees);
    } else if (
      getValues("IsScheduled") &&
      !getValues("IsFilterBySkillSet") &&
      getValues(`visits.${0}.StartDate`) !== "" &&
      getValues(`visits.${0}.StartTime`) !== "" &&
      getValues(`visits.${0}.EndDate`) !== "" &&
      getValues(`visits.${0}.EndTime`) !== ""
    ) {
      fetchEmployees({
        ServiceIdList: "",
        StartDate: moment(
          moment(getValues(`visits.${0}.StartDate`)).format("YYYY-MM-DD") +
            " " +
            moment(getValues(`visits.${0}.StartTime`)).format("HH:mm:ss")
        ).format("YYYY-MM-DDTHH:mm:ss"),
        EndDate: moment(
          moment(getValues(`visits.${0}.EndDate`)).format("YYYY-MM-DD") +
            " " +
            moment(getValues(`visits.${0}.EndTime`)).format("HH:mm:ss")
        ).format("YYYY-MM-DDTHH:mm:ss"),
        IsFilterBySkillSet: false,
      });
    } else {
      setEmployeeList(null);
    }
  }, [
    watch("IsFilterBySkillSet"),
    watch(`visits.${0}.StartDate`),
    watch(`visits.${0}.StartTime`),
    watch(`visits.${0}.EndDate`),
    watch(`visits.${0}.EndTime`),
    watch("isRecurred"),
    watch("IsScheduled"),
    serviceTotal,
  ]);

  /** ---------------------------- Recurring Job ------------------------------ */

  const [values, setValues] = useState(0);

  const handleChange = (newValue) => {
    setValues(newValue);
  };

  function a11yPropsType(indexType) {
    return {
      id: `simple-tab-${indexType}`,
      "aria-controls": `simple-tabpanel-${indexType}`,
    };
  }

  /** ----------------------------------------------------------------------- */
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={
        <ArrowForwardIosSharpIcon
          sx={{
            fontSize: "0.9rem",
            fontWeight: 600,
            "@media (min-width:1536px)": {
              fontSize: "1.1rem",
            },
          }}
        />
      }
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));
  const [anchorElMenu1, setAnchorElMenu1] = useState(null);
  const handleClickMenu1 = (event) => {
    setAnchorElMenu1(event.currentTarget);
  };

  const handleCloseMenu1 = () => {
    setAnchorElMenu1(null);
  };

  const openMenu1 = Boolean(anchorElMenu1);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const createInvoice = usePermission(Permissions.ADD_INVOICE);
  const editJob = usePermission(Permissions.EDIT_JOB);

  const viewButton = [
    {
      label: t("buttons.convertToInvoice"),
      fn: handleConvertToInvoice,
      width: isDownMd ? "150px" : "170px",
      isShown: jobData.IsConvertable && createInvoice,
    },
    {
      label: t("buttons.edit"),
      fn: handleEditDetails,
      isShown: jobData.IsEditable && editJob,
    },
  ];

  const conditionalStatus = [
    {
      isValid:
        getValues("Type") !== "Converted To Jobs" &&
        TypeStatus !== "Cancelled" &&
        getValues("Type") !== "Cancelled" &&
        editJob,
    },
  ];

  const toggleBtn = [
    {
      fn: toggleButtons,
      isShow: isDownMd && jobData.IsConvertable,
    },
  ];

  return (
    <MainContainer>
      <ToolbarContainer />

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <>
          <Grid container spacing={{ xs: 2, lg: 1 }}>
            <Header
              title={jobId ? "viewJob" : "addNewJob"}
              handleClick={handleClick}
              handleClose={handleClose}
              handletypeChange={handletypeChange}
              TypeStatus={TypeStatus}
              getValues={getValues}
              menuItems={menuItems}
              StyledMenu={StyledMenu}
              DeleteConfirmation={DeleteConfirmation}
              isEditDetails={isEditDetails}
              toggleButtons={toggleButtons}
              showButtons={showButtons}
              disabled={Object.keys(jobData).length === 0}
              setShowButtons={setShowButtons}
              viewButton={viewButton}
              open={open}
              anchorEl={anchorEl}
              conditionalStatus={conditionalStatus}
              toggleBtn={toggleBtn}
            />
            <Grid
              item
              xs={12}
              justifyContent={"end"}
              marginRight={{ md: "20px", xs: "0px" }}
              sx={{
                position: isLargeScreen ? "fixed" : "relative", // Adjust position based on screen size
                right: isLargeScreen ? 20 : 0,
                marginBottom: isLargeScreen ? 0 : 2,
              }}
            >
              <div>
                <Stack
                  direction="row"
                  alignItems={isLargeScreen ? "flex-end" : "center"}
                  justifyContent={isLargeScreen ? "flex-end" : "center"}
                  marginBottom={isLargeScreen ? 0 : 2}
                  spacing={2}
                >
                  {isEditDetails ? (
                    <>
                      <SaveEditButton
                        isAddPage={false}
                        loading={loading}
                        title={"Add New"}
                        onClickFunc={handleCancelButtonClick}
                        width={{ xs: "70px", sm: "100px", md: "auto" }}
                      />

                      <DeleteConfirmationDialog
                        deleteApiCall={deleteJob}
                        id={jobId}
                        token={token}
                        width={{ xs: "70px", sm: "170px", md: "auto" }}
                      />
                    </>
                  ) : (
                    jobData.IsEditable && <></>
                  )}
                </Stack>
              </div>
            </Grid>
            <Grid
              container
              spacing={{ xs: 1 }}
              padding={{ xl: 3, md: 2 }}
              marginLeft={{ xs: 2, md: 0 }}
              marginTop={{ xs: -3, md: -1, lg: -3 }}
              variant="outlined"
            >
              <Grid item xs={12}>
                <Stack direction={isDownMd ? "column" : "row"}>
                  <Grid
                    item
                    md={12}
                    lg={8}
                    marginBottom={isLargeScreen ? 0 : 2}
                    spacing={2}
                    marginRight={{
                      md: "0px",
                      lg: "10px",
                      xl: "10px",
                    }}
                  >
                    <CustomerInfoGrid
                      getValues={getValues}
                      theme={theme}
                      totalNewTotal={totalNewTotal}
                      CurrencySymbol={CurrencySymbol}
                      Title={"Title"}
                      Name={"CustomerName"}
                      Phone={"Phone"}
                      Email={"Email"}
                      AddressLine1={"AddressLine1"}
                      City={"City"}
                      StateName={"StateName"}
                      CountryName={"CountryName"}
                      PostCode={"ZipCode"}
                      Id={"JobNumber"}
                      Latitude={`Latitude`}
                      Longitude={`Longitude`}
                      Description={`Description`}
                      dateFormat={dateFormat}
                      register={register}
                      isEditDetails={isEditDetails}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    lg={4}
                    // mt={isEditDetails ? 1 : 0}
                    sx={{
                      position: isLargeScreen ? "fixed" : "relative", // Adjust position based on screen size
                      // top: isLargeScreen ? 160 : "relative",
                      right: isLargeScreen ? 40 : "relative",
                      // width: { xl: 410, lg: 300, xs: "relative" },
                      maxHeight: "calc(100vh - 64px)", // Adjust based on header height
                    }}
                  >
                    <InvoiceSummary
                      t={t}
                      theme={theme}
                      CurrencySymbol={CurrencySymbol}
                      isEditDetails={isEditDetails}
                      subNewTotal={subNewTotal}
                      taxTotal={taxTotal}
                      taxDetails={taxDetails}
                      totalNewTotal={totalNewTotal}
                      serviceTotal={serviceTotal}
                      productTotal={productTotal}
                      packageTotal={packageTotal}
                      getValues={getValues}
                      register={register}
                      setValue={setValue}
                      handleDiscountChange={handleDiscountChange}
                      inputWidth={inputWidth}
                      FieldId={jobId}
                      TypeId={JOB_DEPOSIT_ID}
                      CustomerId={getValues("CustomerId")}
                    />
                  </Grid>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2} marginBottom={"20px"}>
                  <Grid item xs={12}>
                    <Grid container spacing={0}>
                      <Grid item container spacing={0}>
                        <Grid item xs={12}>
                          <Stack direction={isDownMd ? "column" : "row"}>
                            <Grid
                              item
                              md={12}
                              lg={7.8}
                              mb={2}
                              marginRight={{
                                md: "0px",
                                lg: "10px",
                                xl: "10px",
                              }}
                            >
                              <Grid item md={12}>
                                <LineItemsComponent
                                  theme={theme}
                                  CurrencySymbol={CurrencySymbol}
                                  isEditDetails={isEditDetails}
                                  getValues={getValues}
                                  register={register}
                                  setValue={setValue}
                                  details={jobData?.JobLineItems}
                                  lineItems={"JobLineItems"}
                                  newServiceLineItems={"NewLineItems"}
                                  newProductLineItems={"NewProductLineItems"}
                                  newPackageLineItems={"NewPackageLineItems"}
                                  toggleCrossedOut={toggleLineItemsCrossedOut}
                                  services={services}
                                  handleRemoveClick={handleRemoveClick}
                                  products={products}
                                  packages={packages}
                                  addServiceRecord={addServiceRecord}
                                  handleRemoveProductClick={
                                    handleRemoveProductClick
                                  }
                                  addProductRecord={addProductRecord}
                                  addPackageRecord={addPackageRecord}
                                  handleRemovePackageClick={
                                    handleRemovePackageClick
                                  }
                                />
                              </Grid>
                              {/* <Grid item xs={12} mt={1}>
                                <Typography
                                  color="textSecondary"
                                  gutterBottom
                                  variant="h6"
                                  fontWeight={theme.typography.fontWeightMedium}
                                >
                                  {t("textFieldLables.customerMessage")}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} mb={{ xs: 2, sm: "auto" }}>
                                <TextField
                                  name="CustomerMessage"
                                  {...register("CustomerMessage")}
                                  placeholder={t("labels.enterCustomerMessage")}
                                  fullWidth
                                  type="text"
                                  variant={
                                    isEditDetails ? "outlined" : "standard"
                                  }
                                  size="small"
                                  multiline
                                  rows={3}
                                  disabled={!isEditDetails}
                                  InputProps={{
                                    readOnly: !isEditDetails,
                                    disableUnderline: true,
                                    style: {
                                      color: theme.palette.primary.contrastText,
                                      fontWeight:
                                        theme.typography.fontWeightMedium,
                                    },
                                  }}
                                />
                              </Grid> */}
                            </Grid>
                          </Stack>

                          <Stack direction="column" spacing={1} gap={3}>
                            {isEditDetails && !isScheduled && (
                              <Grid item xs={12}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="IsScheduled"
                                      {...register("IsScheduled")}
                                      value={getValues(`IsScheduled`)}
                                      onChange={(e) => {
                                        setIsItScheduled(e.target.checked);
                                        setValue(
                                          "IsScheduled",
                                          e.target.checked
                                        );
                                        setValue(`visits`, [
                                          {
                                            StartDate:
                                              moment().format(
                                                "YYYY-MM-DDTHH:mm"
                                              ),
                                            EndDate:
                                              moment().format(
                                                "YYYY-MM-DDTHH:mm"
                                              ),
                                            StartTime:
                                              moment().format(
                                                "YYYY-MM-DDTHH:mm"
                                              ),
                                            EndTime:
                                              moment().format(
                                                "YYYY-MM-DDTHH:mm"
                                              ),
                                          },
                                        ]);
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontWeight:
                                          theme.typography.fontWeightRegular,
                                      }}
                                    >
                                      {t("textFieldLables.scheduleNow")}
                                    </Typography>
                                  }
                                />
                              </Grid>
                            )}
                            {isScheduled && (
                              <Grid item container xs={12} md={6} lg={7.8}>
                                {!!recError && (
                                  <Grid
                                    container
                                    key={"error-rec"}
                                    item
                                    md={12}
                                    xs={12}
                                  >
                                    <Alert severity="error" auto>
                                      {recError}
                                    </Alert>
                                  </Grid>
                                )}
                                <JobDetailsComponent
                                  theme={theme}
                                  isEditDetails={isEditDetails}
                                  getValues={getValues}
                                  register={register}
                                  setValue={setValue}
                                  details={jobDetails}
                                  isScheduled={isScheduled}
                                  errors={errors}
                                  moment={moment}
                                  jobCount={jobCount}
                                  MobileDatePicker={MobileDatePicker}
                                  recurringLoading={recurringLoading}
                                  handleShowDateTimeFields={
                                    handleShowDateTimeFields
                                  }
                                  Button={Button}
                                  FormControlLabel={FormControlLabel}
                                  Checkbox={Checkbox}
                                  DateTimePickerTheme={DateTimePickerTheme}
                                  handleSelectDateChange={
                                    handleSelectDateChange
                                  }
                                  MobileTimePicker={MobileTimePicker}
                                  handleRemoveClickOne={handleRemoveClickOne}
                                  dateFormat={dateFormat}
                                  timeFormat={timeFormat}
                                />
                              </Grid>
                            )}

                            <Grid item xs={12} container md={6} lg={7.8}>
                              {isScheduled && (
                                <Grid item container xs={12} lg={12}>
                                  {recError === "" && (
                                    <>
                                      <Grid
                                        container
                                        item
                                        xs={12}
                                        alignItems="center"
                                      >
                                        <Grid item xs={12} md={4}>
                                          <Typography
                                            color="textSecondary"
                                            gutterBottom
                                            variant="h4"
                                            fontWeight={
                                              theme.typography.fontWeightBold
                                            }
                                          >
                                            {t("subHeadings.assignedEmployees")}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                          <Stack
                                            direction={"row"}
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            mr={2}
                                          >
                                            {isEditDetails && (
                                              <>
                                                <IconButton
                                                  variant="contained"
                                                  sx={{
                                                    color:
                                                      theme.palette.primary
                                                        .main,
                                                    "&:hover": {
                                                      backgroundColor:
                                                        theme.palette.primary
                                                          .main,
                                                      color: "white",
                                                    },
                                                  }}
                                                  onClick={() =>
                                                    addEmployeeRecord()
                                                  }
                                                >
                                                  <AddSharpIcon />
                                                </IconButton>
                                                <Typography
                                                  color="textSecondary"
                                                  variant="h4"
                                                  fontWeight={
                                                    theme.typography
                                                      .fontWeightBold
                                                  }
                                                  sx={{
                                                    // marginTop: "12px",
                                                    marginLeft: "5px",
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  {t("buttons.addEmployees")}
                                                </Typography>
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      name="IsFilterBySkillSet"
                                                      {...register(
                                                        "IsFilterBySkillSet",
                                                        {
                                                          onChange: (e) => {
                                                            setValue(
                                                              `IsFilterBySkillSet`,
                                                              e.target.checked
                                                            );
                                                          },
                                                        }
                                                      )}
                                                      value={getValues(
                                                        `IsFilterBySkillSet`
                                                      )}
                                                    />
                                                  }
                                                  label={
                                                    <Typography
                                                      variant="h4"
                                                      sx={{
                                                        fontWeight:
                                                          theme.typography
                                                            .fontWeightBold,
                                                      }}
                                                    >
                                                      {t(
                                                        "textFieldLables.filterBySkillSet"
                                                      )}
                                                    </Typography>
                                                  }
                                                />
                                              </>
                                            )}
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                      <EmployeesShowComponent
                                        visitsArray={getValues("visits")}
                                        isEditDetails={isEditDetails}
                                        getValues={getValues}
                                        register={register}
                                        watch={watch}
                                        employeeList={employeeList}
                                        setValue={setValue}
                                        isDownMd={isDownMd}
                                        isCrossedOut={isCrossedOut}
                                        setIsHovering={setIsHovering}
                                        toggleCrossedOut={toggleCrossedOut}
                                        newEmployeesArray={getValues(
                                          `NewEmployees`
                                        )}
                                        jobLineItems={getValues(`JobLineItems`)}
                                        newLineItems={getValues(`NewLineItems`)}
                                        handleRemoveEmployee={
                                          handleRemoveEmployee
                                        }
                                      />
                                    </>
                                  )}
                                </Grid>
                              )}
                            </Grid>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={7.8} sm={12} mt={2} mb={2}>
                        <Divider />
                      </Grid>

                      {/* <Grid container mt={1} mb={2}>
                        <Grid item xs={12} sm={12} lg={7.8}>
                          <Accordion
                            sx={{ borderRadius: "10px" }}
                            expanded={expanded}
                            onChange={handleAccordionChange}
                          >
                            <AccordionSummary>
                              <Typography
                                color="textSecondary"
                                variant="h6"
                                fontWeight="medium"
                              >
                                {t("headings.bilingSummary")}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid item xs={12} mt={2}>
                                <Typography
                                  color="textSecondary"
                                  gutterBottom
                                  variant="h6"
                                  fontWeight="medium"
                                  marginBottom={"5px"}
                                >
                                  {t("textFieldLables.customerMessage")}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} lg={12} sm={12} mt={1}>
                                <Divider />
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Grid> */}
                      <Grid item xs={12}>
                        <Grid container mt={1} mb={2}>
                          <Grid item xs={12} sm={12} lg={7.8}>
                            <Accordion
                              sx={{ borderRadius: "10px" }}
                              expanded={expanded}
                              onChange={handleAccordionChange}
                            >
                              <AccordionSummary>
                                <Typography
                                  color="textSecondary"
                                  variant="h6"
                                  fontWeight="medium"
                                >
                                  {t("headings.customersummary")}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {/* Customer Message Section */}
                                <Grid item xs={12} mt={2}>
                                  <Typography
                                    color="textSecondary"
                                    gutterBottom
                                    variant="h6"
                                    fontWeight="medium"
                                    marginBottom={"5px"}
                                  >
                                    {t("textFieldLables.customerMessage")}
                                  </Typography>
                                  <TextField
                                    name="CustomerMessage"
                                    {...register("CustomerMessage")}
                                    id="outlined-basic"
                                    placeholder={t("labels.enterMessage")}
                                    type="text"
                                    variant={
                                      isEditDetails ? "outlined" : "standard"
                                    }
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={2}
                                    error={!!errors?.CustomerMessage}
                                    helperText={errors.CustomerMessage?.message}
                                    disabled={!isEditDetails}
                                    InputProps={{
                                      readOnly: !isEditDetails,
                                      disableUnderline: true,
                                      style: {
                                        color:
                                          theme.palette.primary.contrastText,
                                        fontWeight:
                                          theme.typography.fontWeightMedium,
                                      },
                                    }}
                                  />
                                </Grid>

                                {/* <Grid item xs={12} lg={12} sm={12} mt={1}>
                                  <Divider />
                                </Grid> */}

                                {/* Notes Section */}
                                <Grid item xs={12} mt={1} mb={1}>
                                  <Grid item md={12} lg={6}>
                                    <Stack direction={"row"}>
                                      <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="h6"
                                        fontWeight={
                                          theme.typography.fontWeightBold
                                        }
                                        sx={{ display: "inline-block" }}
                                      >
                                        {t("textFieldLables.notes")}
                                      </Typography>

                                      <Tooltip
                                        title={
                                          "Use this field to provide any additional information, comments, or context related to the current task."
                                        }
                                        placement="right"
                                      >
                                        <Info
                                          fontSize="small"
                                          sx={{
                                            color: theme.palette.secondary.dark,
                                            ml: "1rem",
                                          }}
                                        />
                                      </Tooltip>
                                    </Stack>
                                    <Grid item xs={12} md={11.6}>
                                      <TextField
                                        name="Instruction"
                                        {...register("Instruction")}
                                        placeholder={t("messages.enterNotes")}
                                        fullWidth
                                        type="text"
                                        variant={
                                          isEditDetails
                                            ? "outlined"
                                            : "standard"
                                        }
                                        size="small"
                                        multiline
                                        rows={3}
                                        error={!!errors.Instruction}
                                        helperText={errors.Instruction?.message}
                                        disabled={!isEditDetails}
                                        InputProps={{
                                          readOnly: !isEditDetails,
                                          disableUnderline: true,
                                          style: {
                                            color:
                                              theme.palette.primary
                                                .contrastText,
                                            fontWeight:
                                              theme.typography.fontWeightMedium,
                                          },
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          </Grid>

                          <Grid xs={12} lg={7.8} mt={2} mb={2}>
                            {!isEditDetails && (
                              <>
                                <Accordion
                                  sx={{ borderRadius: "10px" }}
                                  expanded={expanded2}
                                  onChange={handleAccordionChange2}
                                >
                                  <AccordionSummary>
                                    <Typography
                                      color="textSecondary"
                                      variant="h6"
                                      fontWeight="medium"
                                    >
                                      {t("headings.documents")}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {expanded2 && (
                                      <Grid container spacing={3}>
                                        <Grid item md={6} xs={12} lg={12}>
                                          <FileUpload
                                            Directory={`Job/JobId_${jobId}/JobIn`}
                                            isFirst={false}
                                            FieldId={jobId}
                                            FieldTypeId={JOBIN_DOCUMENT_ID}
                                            FieldName="Job"
                                            Title={t("headings.jobInDocument")}
                                            IsEditable={jobData?.IsEditable}
                                          />
                                        </Grid>

                                        <Grid item md={6} xs={12} lg={12}>
                                          <FileUpload
                                            Directory={`Job/JobId_${jobId}/JobOut`}
                                            isFirst={false}
                                            FieldId={jobId}
                                            FieldTypeId={JOBOUT_DOCUMENT_ID}
                                            FieldName="Job"
                                            Title={t("headings.jobOutDocument")}
                                            IsEditable={jobData?.IsEditable}
                                          />
                                        </Grid>
                                      </Grid>
                                    )}
                                  </AccordionDetails>
                                </Accordion>
                              </>
                            )}
                          </Grid>

                          <Grid xs={12} lg={7.8}>
                            {!isEditDetails && (
                              <Accordion
                                sx={{ borderRadius: "10px" }}
                                expanded={expanded3}
                                onChange={handleAccordionChange3}
                              >
                                <AccordionSummary>
                                  <Typography
                                    color="textSecondary"
                                    variant="h6"
                                    fontWeight="medium"
                                  >
                                    {t("headings.showQuestions")}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {showQuestions &&
                                    (groupedResponses.length > 0 ? (
                                      <Typography sx={{ mt: 4 }}>
                                        {groupedResponses?.map(
                                          (QuestionId, index) => (
                                            <div key={index}>
                                              <Grid item xs={11} md={10}>
                                                <Typography
                                                  color="textSecondary"
                                                  gutterBottom
                                                  variant="h4"
                                                  fontWeight={
                                                    theme.typography
                                                      .fontWeightMedium
                                                  }
                                                  sx={{
                                                    marginTop: { sm: "20px" },
                                                  }}
                                                >
                                                  {`${index + 1}. ${
                                                    QuestionId.QuestionText ||
                                                    "No Question"
                                                  }`}
                                                </Typography>
                                              </Grid>

                                              <List>
                                                {QuestionId.AnswerText}
                                              </List>
                                            </div>
                                          )
                                        )}
                                      </Typography>
                                    ) : (
                                      "No Questions"
                                    ))}
                                </AccordionDetails>
                              </Accordion>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      </form>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    customers: state.customer.dataRecords,
    services: state.service.services,
    products: state.product.products,
    taxes: state.tax.taxes,
    employees: state.employee.employees,
    types: state.type.types,
    packages: state.package.packages,
  };
};

export default connect(mapStateToProps, null)(OneTimeJobOV);

//
