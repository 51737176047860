import { alpha } from "@mui/material";

const { createTheme } = require("@mui/material");
const primary = "#ffffff";
const black = "#000000";
const darkBlack = "rgb(36, 40, 44)";
const background = "#F9F9F9";
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "rgba(253, 200, 69, 1)";
const warningDark = "rgba(253, 200, 69, .7)";
const danger = "#FF3D3D";
const lightBackground = "#f2f2f2";
const focusBackground = "#E9ECFF";

// border
const borderWidth = 2;
const borderColor = "rgba(0, 0, 0, 0.13)";

// spacing
const spacing = 8;
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  // 900: '#161C24',
  900: "#000000",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};
const themeDefault = createTheme({
  palette: {
    primary: {
      light: "#39426e",
      default: "#F4A540",
      main: "#F4A540",
      dark: "#FB8122",
      contrastText: "#000",
      inheritTextColor: "#818EA1",
    },
    secondary: {
      light: lightBackground,
      main: "#457CCE",
      default: "#144061",
      dark: "#8B8B8B",
      contrastText: "#fff",
      inheritTextColor: "#F4A540",
    },
    input: {
      light: "#545454",
      default: "#1D2228",
      dark: "#D9D9D9",
      contrastText: "#fff",
      inheritTextColor: "#F4A540",
      border: "#D9D9D9",
      background: "#F6F6F6",
    },
    shadow: {
      shadow: "#B9B7BD",
      inheritTextColor: "#F4A540",
      contrastText: "orange",
    },
    transparent: {
      main: "transparent",
      contrastText: "#FB8122",
      inheritTextColor: "#F4A540",
    },
    dispatch: [
      "#fab65c",
      "#3ac9a0",
      "#d65aca",
      "#c24242",
      "#3da86f",
      "#486cfa",
      "#ab8235",
      "#875837",
      "#8f48c7",
      "#48a1c7",
    ],

    common: {
      black: black,
      darkBlack: darkBlack,
      danger: danger,
    },
    warning: {
      light: warningLight,
      main: warningMain,
      dark: warningDark,
      default: warningLight,
    },

    tonalOffset: 0.2,
    spacing,
    revenue: {
      increase: "rgba(10, 201, 98, 0.15)",
      decrease: "rgba(201, 10, 10, 0.15)",
      contrastText: "#808080",
      increasetext: "#0AC962",
      decreasetext: "#C90A0A",
    },
    mode: "light",
    text: {
      primary: "#fff",
      secondary: "#000",
      disabled: GREY[500],
      selectedTxtColor: "#000",
      header: "#AAAD9F",
    },

    appBar: {
      backgroundColor: "white",
      backdropFilter: "blur(25px)",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    },
    appBarShift: {
      backgroundColor: "white",
      backdropFilter: "blur(25px)",

      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
    },
    searchbar: {
      backgroundColor: "transparent",
      backdropFilter: "blur(10px)",
      background:
        "linear-gradient(89.89deg, rgba(178, 178, 178, 0.3) 0%, rgba(209, 209, 209, 0.3) 100%)",
      "border-radius": "6px",
      textColor: "Black",
    },
    columnbar: {
      backgroundColor: "transparent",
      backdropFilter: "blur(10px)",
      background:
        "linear-gradient(89.89deg, rgba(178, 178, 178, 0.3) 0%, rgba(209, 209, 209, 0.3) 100%)",
      "border-radius": "6px",
      // textColor: "red",
      textColor: "#AAAD9F",
    },
    toolBar: {
      background:
        "linear-gradient(89.89deg, rgba(222, 222, 222, 0.8) 0%, rgba(244, 244, 244, 0.8) 100%)",
      borderRadius: "6px",
    },
    dropDown: {
      background: "#F6F6F6",
      color: "#1D2228",
    },
    card: {
      background: "#F0F0F0",
      color: "#1D2228",
    },
    background: {
      color: "#F4A540",
    },
    customToolbarTextfield: {
      color: "#F4F4F4",
      background: "#ffffff",
    },
    skelton: {
      rootBg:
        "linear-gradient(89.78deg, rgba(178, 178, 178, 0.3) 0%, rgba(244, 244, 244, 0.1) 100%)",
    },
    customerIds: {
      color: "#CACACA",
    },
    statusChips: {
      active: {
        color: "#0AC962",
        background: "#D4F6E4",
      },
      inactive: {
        color: "#C90A0A",
        background: "#FFD2D2",
      },
      delete: {
        color: "#C90A0A",
        background: "#FFD2D2",
      },
      onlineBooking: {
        color: "#0086E8",
        background: "#C4E4FB",
      },
      convertedQuote: {
        color: "#F4A540",
        background: "#F8E6CF",
      },
      new: {
        color: "#0AC962",
        background: "#D4F6E4",
      },
      cancelled: {
        color: "#1C979F",
        background: "#C4FBFF",
      },
      convertedJob: {
        color: "#2578BB",
        background: "#B9E0FF",
      },
      approved: {
        color: "#4BB17A",
        background: "#DBFFEC",
      },
      pending: {
        color: "#FDD935",
        background: "#FFF7D4",
      },
      denied: {
        color: "#C90A0A",
        background: "#FFD2D2",
      },
      overdue: {
        color: "#C90A0A",
        background: "#FFD2D2",
      },
      completed: {
        color: "#4BB17A",
        background: "#DBFFEC",
      },
      ongoing: {
        color: "#FF6B00",
        background: "#FFE0C9",
      },
      convertedInvoice: {
        color: "#9B008C",
        background: "#FFDBFB",
      },
      closed: {
        color: "#F4A540",
        background: "#F8E6CF",
      },
      void: {
        color: "#0CC0CC",
        background: "#C8FCFF",
      },
      emailSent: {
        color: "#102F48",
        background: "#8CC2ED",
      },
      inStock: {
        color: "#ffffff",
        background: "#0AC962",
      },
      outOfStock: {
        color: "#ffffff",
        background: "#C90A0A",
      },
      lowStock: {
        color: "#ffffff",
        background: "#F4A540",
      },
    },
    calender: {
      color: "#F4A540",
      backgroundColor: "#FDEBD5",
      border: "#ddd",
    },
  },

  typography: {
    fontFamily: "'Poppins','Helvetica','Arial', sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontSize: "1.8rem",
      color: "theme.palette.primary.dark",
      fontWeight: 600,
      "@media (min-width:1536px)": {
        fontSize: "2rem",
      },
    },
    h1Summary: {
      fontSize: "1.5rem",
      color: "theme.palette.primary.dark",
      fontWeight: 600,
      "@media (min-width:1536px)": {
        fontSize: "1.8rem",
      },
    },
    h2: {
      fontSize: "1.2rem",
      letterSpacing: "0px",
      fontWeight: 600,
      color: "#000",
      "@media (min-width:1536px)": {
        fontSize: "1.30rem",
      },
    },
    h3: {
      fontSize: "0.9rem", //
      letterSpacing: "0px",
      fontWeight: 600,
      "@media (min-width:1536px)": {
        fontSize: "1.1rem",
      },
    },
    h4: {
      fontSize: "0.85rem",
      letterSpacing: "0px",
      fontWeight: 500,
      "@media (min-width:1536px)": {
        fontSize: "0.85rem",
      },
    },
    h5: {
      fontSize: "0.80rem",
      fontWeight: 400,
      "@media (min-width:1536px)": {
        fontSize: "0.80rem",
      },
    },
    h6: {
      fontSize: "0.75rem",
      fontWeight: 400,
      "@media (min-width:1536px)": {
        fontSize: "0.75rem",
      },
    },
    h3Summary: {
      fontSize: "0.9rem", //
      letterSpacing: "0px",
      fontWeight: 600,
      "@media (min-width:1536px)": {
        fontSize: "1rem",
      },
    },
    h2Summary: {
      fontSize: "0.9rem", //
      letterSpacing: "0px",
      fontWeight: 500,
      "@media (min-width:1536px)": {
        fontSize: "0.9rem",
      },
    },
    body1: {
      fontSize: "0.65rem",
      fontWeight: 200,
      "@media (min-width:1536px)": {
        fontSize: "0.80rem",
      },
    },
    tableCName: {
      color: "#393939",
      fontSize: "0.75rem",
    },
    tableCId: {
      color: "#AAAD9F",
      fontSize: "0.75rem",
    },
    tableHeaders: {
      fontSize: "0.95rem",
      letterSpacing: "0px",
      // color: "#FB8122",  //test1
      //  backgroundColor: "#AAAD9F",  //test1
      fontWeight: 500,
      "@media (min-width:1536px)": {
        fontSize: "1.00rem",
      },
      // color: "#AAAD9F", //original
    },
    // tableHeaders: {
    //   fontSize: "0.95rem",
    //   letterSpacing: "0px",
    //   fontWeight: 500,
    //   paddingLeft: "20px",
    //   paddingTop: "20px",
    //   paddingBottom: "20px",
    //   backgroundColor: "#AAAD9F", // Change to your desired background color
    //   color: "#FFFFFF", // Change text color to ensure readability
    //   // borderBottom: "1px solid #FB8122", // Add a bottom border
    //   borderRadius: "10px",
    //   transition: "background-color 0.3s ease", // Add a transition for smooth hover effect
    //   cursor: "pointer", // Change cursor on hover to indicate interactivity

    //   "&:hover": {
    //     backgroundColor: "#FFFFFF", // Change to a different color on hover
    //     color: "#AAAD9F", // Change text color to ensure readability
    //   },

    //   "@media (min-width:1536px)": {
    //     fontSize: "0.95rem",
    //   },
    // },

    label: {
      fontWeight: "500",
      color: "#666666",
    },
    subtitle1: {
      fontSize: "0.8rem",
      color: "theme.palette.secondary.dark",
      "@media (min-width:1536px)": {
        fontSize: "1.1rem",
      },
    },
    subtitle2: {
      fontSize: "0.4rem",
      color: "theme.palette.primary.dark",
      fontWeight: 500,
      opacity: 0.3,
      "@media (min-width:1536px)": {
        fontSize: "0.7rem",
      },
    },
    button: {
      fontSize: "0.7rem",
      fontWeight: 500,
      "@media (min-width:1536px)": {
        fontSize: "0.8rem",
      },
    },
  },

  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: () => ({
          fontWeight: "500",
          borderColor: "none",
          color: black,
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: "0.8rem",
          color: "#D9D9D9",
          fontWeight: "400",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "&:hover": {
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#F4A540",
              },
            },
            "& .MuiInput-underline:hover:before": {
              borderBottomColor: "#F4A540",
            },
          },
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              border: "1px solid #D9D9D9",
              borderRadius: "10px",
            },
          },
          "& .MuiOutlinedInput-root:focus": {
            "& > fieldset": {
              border: "1px solid #D9D9D9",
              borderRadius: "10px",
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "0.9rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: "none",

          background: "#F4A540",
          variant: "contained",
          color: theme.palette.text.primary,
          "&:hover": {
            background: theme.palette.primary.main,
            variant: "contained",
          },

          // "&.MuiButton-text": {
          //   color: theme.palette.primary.main,
          //   background: theme.palette.secondary.contrastText,
          //   variant: "text",
          //   "&:hover": {
          //     backgroundColor: theme.palette.secondary.light,
          //   },
          // },
        }),
        outlined: ({ theme }) => ({
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
          background: theme.palette.secondary.contrastText,
          "&:hover": {
            borderColor: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.light,
          },
        }),
        text: ({ theme }) => ({
          color: theme.palette.primary.main,
          background: theme.palette.secondary.contrastText,
          variant: "text",
          "&:hover": {
            backgroundColor: theme.palette.secondary.light,
          },
        }),
      },
    },
    MuiExpansionPanel: {
      styleOverrides: {
        root: {
          position: "static",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: borderColor,
          height: borderWidth,
        },
      },
    },
    MuiPrivateNotchedOutline: {
      styleOverrides: {
        root: {
          borderWidth: borderWidth,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "#F4A540",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        divider: {
          borderBottom: `${0}px solid ${borderColor}`,
        },
        root: {
          "&:hover": {
            background:
              "linear-gradient(86.91deg, theme.palette.shadow.contrastText 0%, theme.palette.primary.main 100%)",
          },
          "&:selected": {
            backgroundColor: "red",
            color: "red",
            borderLeft: "4px solid #30D0B6",
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        divider: {
          borderBottom: `${0}px solid ${borderColor}`,
        },

        root: {
          "&:hover": {
            background: "rgba(244, 165, 64, 0.22) ",
            borderRadius: "15px",
          },
          "&.Mui-selected": {
            background: " rgba(251, 129, 34, 0.22)",
          },
          borderRadius: "15px",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          width: "100%",
          // maxWidth: 430,
          marginLeft: spacing,
          marginRight: spacing,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background:
            "linear-gradient(86.91deg, theme.palette.shadow.contrastText 0%, theme.palette.primary.main 100%)",
          fontSize: "12px",
        },
        arrow: {
          color: black,
        },
      },
    },
    MuiDropzoneArea: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.dropZoneAttach.color,
          border: `2px solid ${borderColor}`,
        }),
        icon: {
          color: "#DFE3E8",
        },
        text: {
          padding: "3rem",
          fontSize: "1.1rem !important",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: { boxShadow: "none" },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: primary,
          borderRadius: "4px",
          flexDirection: "row-reverse",
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
          },
          "& .MuiAccordionSummary-content": {
            marginLeft: spacing,
          },
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        root: {
          fontSize: 15,
          color: "#D9D9D9",
          "&$selected > $content $label": {
            backgroundColor: "transparent",
          },
          "&$selected > $content $label:hover, &$selected:focus > $content $label":
            {
              backgroundColor: "transparent",
            },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#FB8122",
          "&.Mui-checked": {
            color: "#F4A540",
          },
        },
        colorSecondary: {
          "&$checked": {
            color: "#F4A540",
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#FB8122",
          "&.Mui-checked": {
            color: "#F4A540",
          },
        },
        colorSecondary: {
          "&$checked": {
            color: "#F4A540",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: "0.8rem",
          borderRadius: "10px",
          fontWeight: "500",
          "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #F4A540",
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #D9D9D9",
          },
          "& .MuiOutlinedInput-notchedOutline:focus": {
            border: "1px solid #D9D9D9",
          },
        },
      },
    },

    MuiNativeSelect: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          fontWeight: 450,
        },
      },
    },

    TableContainer: {
      styleOverrides: {
        root: {
          "@media print": {
            overflow: "visible !important",
          },
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          background: theme.palette.appBar.backgroundColor,
          color: theme.palette.dropDown.color,
          backdropFilter: "blur(50px)",
        }),
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          // backdropFilter:'blur(10px)',
          height: "30px", //for check saranya sarma
          width: "flex",
          fontSize: "20px",
          // borderSpacing: "10px",
          // background:"red",

          // borderRadius: "15px !important",
          "& td:first-of-type": {
            // borderRadius:"red",
            [theme.breakpoints.up("md")]: {
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
            },
          },

          "& td:last-child": {
            [theme.breakpoints.up("md")]: {
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            },
          },

          // backdropFilter: "blur(10px)",
          boxSizing: "border-box",
          // background: "#0000ff",
          // border: "1px solid #EBEBEC",
          borderRadius: "10px",
          // borderLeft:"20px"
        }),
        head: ({ ownerState, theme }) => ({
          height: "45px",
          borderRadius: "6px",
          border: "none",

          "@media print": {
            backgroundColor: "#fff",
          },

          // background:
          //   "linear-gradient(89.89deg, rgba(89, 89, 89, 0.3) 2%, rgba(89, 89, 89, 0.3) 60%)",
          color: "##AAAD9F !important",
        }),
      },
    },

    centeredCell: {
      textAlign: "center",
      // backgroundColor: "#F5F5F5",
      // color: "#AAAD9F",

      backgroundColor: "#F4A540",
      color: "#000",

      // backgroundColor: "#39426e",
      // color: "#F4A540",

      // background: `linear-gradient(to bottom, #F4A540, #FFC97E)`,
      // color: "#fff",

      // backgroundColor: "transparent",
      // border: "1px solid #F4A540",
      // color: "#F4A540",

      // borderBottom: "1px solid #FB8122",

      borderTop: "1px solid #F4A540",
      borderBottom: "1px solid #F4A540",
      "& td:first-of-type": {
        // borderRadius:"red",
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: "10px",
        borderLeft: "1px solid #F4A540",
      },

      "& td:last-child": {
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",
        borderRight: "1px solid #F4A540",
      },
      transition: "background-color 0.3s ease",
      cursor: "auto",

      // "&:hover": {
      //   backgroundColor: "#FFFFFF",
      //   color: "#AAAD9F",
      // },

      "@media (min-width:1536px)": {
        fontSize: "0.95rem",
      },
    },

    deleteConfirmation: {
      backgroundColor: "#fff",
      borderRadius: "10px",
      padding: "40px",
      // display: "flex",
      flexDirection: "column",
      alignItems: "center",
      boxShadow: "10px",
    },

    Backdrop: {
      color: "#fff",
    },

    NotificationsCountIcon: {
      "& .MuiBadge-badge": {
        right: 4,
        top: 7,
        border: `2px solid "#fff"`,
        padding: "0 4px",
      },
    },

    Box: {
      border: "1px solid #CACACA",
    },
    Divider: {
      border: "1px solid #D9D9D9",
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.up("md")]: {
            boxSizing: "border-box",
            borderBottom: "none",
            fontSize: "0.80rem",
            fontWeight: 550,
            padding: "0px 0px 0px 16px",
            // marginBottom: "20px",
            borderCollapse: "separate",
            borderSpacing: "0",
            border: "1px solid #EBEBEC",
            borderLeft: "0px",
            borderRight: "0px",
            color: "#393939",
            // width: "1490px",
            height: "50px",

            // "&: first-child": {
            "&: first-of-type": {
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
              border: "1px solid #EBEBEC",
              borderRight: "0px",
            },
            // "&: last-child": {
            "&: last-of-type": {
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
              border: "1px solid #EBEBEC",
              borderLeft: "0px",
            },
          },
          boxSizing: "border-box",
          borderBottom: "none",
          fontSize: "0.75rem",
          fontWeight: 550,
          padding: "0px 0px 0px 16px",
          borderCollapse: "separate",
          borderSpacing: "0",
          border: "1px solid #EBEBEC",
          // "&: first-child": {
          "&: first-of-type": {
            border: "1px solid #EBEBEC",
            borderRight: "0px",
          },
          // "&: last-child": {
          "&: last-of-type": {
            border: "1px solid #EBEBEC",
            borderLeft: "0px",
          },
          color: "#393939",
        }),
        head: {
          "@media print": {
            color: "#000 !important",
          },
          color: "#707070",
        },
        body: {
          color: "#000",
        },
      },
    },

    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          // color: "#fff !important",
          "@media print": {
            color: "#000  !important",
          },
        },
        icon: {
          color: "#fff !important",
          "@media print": {
            color: "#000  !important",
          },
        },
      },
    },

    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          color: "#8B8B8B",
        },
        selectLabel: {
          color: "#8B8B8B",
        },
        select: { color: black },
      },
    },

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: "#FB8122",
        },
      },
    },

    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: "#F4A540",
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          background: theme.palette.card.background,
          backdropFilter: "blur(10px)",
        }),
      },
    },

    MuiFab: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          color: "#F4F4F4",
          background:
            "linear-gradient(86.91deg, theme.palette.shadow.contrastText 0%, theme.palette.primary.main 100%)",
        }),
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          background: theme.palette.appBar.backgroundColor,
          color: theme.palette.dropDown.color,
          backdropFilter: "blur(25px)",
          //display: "inline-block"
        }),
      },
    },

    MuiSkeleton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          background: theme.palette.skelton.rootBg,
          // color: theme.palette.dropDown.color,
          backdropFilter: "blur(25px)",
        }),
      },
    },

    MuiPickersClock: {
      styleOverrides: {
        root: {
          backgroundColor: "#F4A540",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#FB8122",
          },
        },
      },
    },

    MuiPickersClockNumber: {
      styleOverrides: {
        root: {
          backgroundColor: "#F4A540",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#FB8122",
          },
        },
      },
    },

    MuiPickersDay: {
      styleOverrides: {
        root: {
          backgroundColor: "#F4A540",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#FB8122",
          },
          "&:selected": {
            backgroundColor: "#FB8122",
            color: "#000",
          },
        },
      },
    },

    MuiGrid: {
      styleOverrides: {
        root: {
          color: "black",
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          background: "#181818",
        }),
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          // color: theme.palette.columnbar.textColor,
          color: "#AAAD9F",
        }),
      },
    },

    MuiAvatar: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          color: "black",
          background: "white",
        }),
      },
    },

    MuiLink: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          color: "black",
        }),
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          width: "100%",
          boxShadow: 2,
          border: `1px solid ${theme.palette.input.border}`,
          borderRadius: "10px",
        }),
      },
    },

    "MuiSwitch-thumb": {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          background: "#0AC962 !important",
        }),
      },
    },

    "MuiList-root": {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          display: "inline-block",
        }),
      },
    },
  },
});

export default themeDefault;
