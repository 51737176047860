import { baseUrl, handleResponse, handleError } from "./apiUtils";
import { addingParams } from "./apiUtils";

export const getAllRequests = async (token, filters, roleId) => {
  const url =
    roleId === 1
      ? new URL(`${baseUrl}/Request`)
      : new URL(`${baseUrl}/Employee/EmployeeLeads`);

  for (const [key, value] of Object.entries(filters)) {
    if (value !== null && value !== undefined && value !== "") {
      url.searchParams.append(key, value);
    }
  }

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getRequestById = async (token, requestId) => {
  const url = new URL(`${baseUrl}/Request/id?RequestId=${requestId}`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getRequestByCustomerId = async (
  token,
  clientId,
  customerId,
  filters
) => {
  const url = addingParams(
    filters,
    `${baseUrl}/Request/Customer/${customerId}`
  );
  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postRequest = async (token, body) => {
  const url = new URL(`${baseUrl}/Request`);

  if (body.QuestionnaireResponses && body.QuestionnaireResponses.length > 0) {
    body.QuestionnaireResponses = Object.values(body.QuestionnaireResponses);
  }

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putRequest = async (token, requestId, body) => {
  const url = new URL(`${baseUrl}/Request`);
  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getAllRequestsQuestionaire = async (token, Survetype) => {
  const url = new URL(`${baseUrl}/Request/Questionnaire`);
  url.searchParams.append("SurveyTypeName", Survetype);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getAllRequestsPriority = async (token) => {
  const url = new URL(`${baseUrl}/Type?Module=Request Priority`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const changePriority = async (token, RequestId, PriorityId) => {
  const url = new URL(
    `${baseUrl}/Request/ChangePriority?RequestId=${RequestId}&PriorityId=${PriorityId}`
  );

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const deleteRequest = async (token, id, updatedDate) => {
  const url = new URL(`${baseUrl}/Request/${id}`);

  let options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ updatedDate }),
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const changeStatus = async (token, RequestId, status, RefMode) => {
  const url = new URL(
    `${baseUrl}/Job/ChangeStatusByModule?RefId=${RequestId}&status=${status}&RefMode=${RefMode}`
  );

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
