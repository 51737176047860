import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { postPayment } from "src/api/paymentApi";
import { AuthContext } from "src/auth/AuthProvider";
import CustomInput from "src/components/Common/FormElements/CustomInput";
import CustomSelect from "src/components/Common/FormElements/CustomSelect";
import CustomDateInput from "src/components/Payment/CustomDateInput";
import * as yup from "yup";

const AddDepositDialog = ({
  types,
  FieldId,
  TypeId,
  CustomerId,
  handlePayment,
  balance,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const auth = useContext(AuthContext);
  const CurrencySymbol = auth.getProfile()?.CurrencySymbol || "$";
  const token = auth.getAuthToken();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const defaultValues = {
    FieldId: FieldId,
    TypeId: TypeId,
    CustomerId: CustomerId,
    TransactionDate: moment().format("YYYY-MM-DD"),
    TotalAmount: 0,
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const schema = yup.object().shape({
    FieldId: yup.number().required(),
    TypeId: yup.number().required(),
    CustomerId: yup.number().required(),
    Method: yup.number().min(1, t("message.validPaymentMethod")),
    TotalAmount: yup
      .number()
      .typeError(t("messages.validAmount"))
      .min(1, t("messages.validAmount"))
      .max(balance, t("messages.validAmount"))
      .required(t("messages.validAmount")),
    Details: yup.string(),
    TransactionDate: yup.date().required(),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    setLoading(true);
    await postPayment(token, values)
      .then((response) => {
        setLoading(false);
        if (response.StatusCode == 200) {
          toast.success(t("toasts.paymentCreatedSuccessfully"));
          handlePayment(values.TotalAmount);
          reset();
          handleClose();
        } else {
          toast.error(t("toasts.paymentFailedToCreate"));
        }
      })
      .catch((error) => {
        toast.error(t("toasts.paymentFailedToCreate"));
        setLoading(false);
      });
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Box>
            <Typography
              color="textSecondary"
              gutterBottom
              fontWeight={theme.typography.fontWeightBold}
              variant="h3"
            >
              {t("subHeadings.addPayment")}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <CustomDateInput
              label={t("labels.transactionDate")}
              name={"TransactionDate"}
              containerProps={{ xs: 12, mb: 3 }}
              control={control}
            />
            <CustomSelect
              label={t("labels.paymentMethod")}
              placeholder={t("placeholder.paymentMethod")}
              name={"Method"}
              options={types.filter((type) => type.Module == "Payment Method")}
              optionLabel={"TypeName"}
              optionValue={"TypeId"}
              containerProps={{
                xs: 12,
                mx: 2,
              }}
              control={control}
              error={errors?.Method}
              errors={errors}
              helperText={errors?.Method?.message}
            />
            <CustomInput
              label={t("labels.paymentAmount")}
              placeholder={t("placeholder.paymentAmount")}
              containerProps={{
                xs: 12,
                mx: 2,
              }}
              type="number"
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {CurrencySymbol}
                  </InputAdornment>
                ),
              }}
              errors={errors}
              name={"TotalAmount"}
              register={register}
            />

            <CustomInput
              label={t("labels.paymentDetails")}
              placeholder={t("placeholder.paymentDetails")}
              containerProps={{
                xs: 12,
                mx: 2,
              }}
              props={{ multiline: true, rows: 4 }}
              name={"Details"}
              register={register}
              errors={errors}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t("buttons.cancel")}
          </Button>
          {!loading ? (
            <Button
              variant="contained"
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              {t("buttons.submit")}
            </Button>
          ) : (
            <Button variant="contained" disabled>
              {t("buttons.loading")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Button onClick={handleOpen} sx={{ color: "#000000" }}>
        <Typography>{t("labels.deposit")}</Typography>
      </Button>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    types: state.type.types,
  };
};

export default connect(mapStateToProps, null)(AddDepositDialog);
