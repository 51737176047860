import { Info } from "@mui/icons-material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import {
  Box,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DateTimePickerTheme from "src/components/Controls/DateTimePickerTheme";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import Customers from "src/pages/Customer/Customers";
import { AuthContext } from "src/auth/AuthProvider";

const CustomerInfoGrid = ({
  getValues,
  theme,
  totalNewTotal,
  errors,
  setValue,
  isEditDetails,
  CurrencySymbol,
  Title,
  Name,
  Phone,
  Email,
  AddressLine1,
  City,
  StateName,
  Id,
  PostCode,
  Latitude,
  Longitude,
  indexx = 0,
  types,
  register,
  dateFormat = "DD/MM/YYYY",
}) => {
  const [boxHeight, setBoxHeight] = useState(null);

  useEffect(() => {
    const resizeObserverBox = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentBoxSize) {
          const height = entry.contentBoxSize[0].blockSize;
          setBoxHeight(height);
        }
      }
    });

    return () => {
      resizeObserverBox.disconnect();
    };
  }, []);

  const isDownMd = useMediaQuery(theme.breakpoints.down("lg"));
  const { t } = useTranslation();

  const [minHeight, setMinHeight] = useState("150px");

  /** --------------------------- Error Message --------------------------------------------- */

  const [issueDateError, setIssueDateError] = useState(false);

  /** --------------------------------------------------------------------------------------- */
  const [isHovered, setIsHovered] = useState(false);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const auth = useContext(AuthContext);
  const profile = auth.getProfile();
  const customerId = getValues("CustomerId");
  return (
    <Stack direction={isDownMd ? "column" : "row"} sx={{ display: "flex" }}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          marginRight: { md: "0px", lg: "20px", xl: "20px" },
        }}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              border: "1px solid #EBEBEC",
              borderRadius: 3,
              minHeight: boxHeight ? `${boxHeight}px` : "250px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)", // Adjust shadow parameters as needed
              flex: 1,
            }}
          >
            <Typography variant="h3">
              <Box
                sx={{
                  border: "1px solid #EBEBEC",
                  p: 0.5,
                  borderTopLeftRadius: 8,
                  minHeight: "20px",
                  width: "90px",
                  flex: 1,
                  backgroundColor: "#badc58",
                }}
              >
                <div style={{ alignItems: "end", color: "White" }}>
                  #
                  {getValues("ScheduleLineItems")
                    ? `${getValues(Id) || 0}.${indexx + 1}`
                    : getValues(Id)}
                </div>
              </Box>
            </Typography>
            <Grid
              container
              spacing={2}
              alignItems="center"
              p={3}
              // style={{ margin: "10px" }}
            >
              <Grid item xs={12} sm={12} md={12} lg={11} xl={11}>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={12} sx={{ marginBottom: "5px" }}>
                    <Typography
                      variant={
                        getValues(Title) && getValues(Title)?.length > 30
                          ? "h3"
                          : "h1"
                      }
                      // variant="h2"
                      align="left"
                      display="inline"
                      color="#84817a"
                      sx={{
                        wordWrap: "break-word",
                      }}
                    >
                      {!!getValues(Title)
                        ? getValues(Title).toLowerCase()
                        : "Title"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} p={2}>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} mt={2}>
                        <Grid container spacing={0} alignItems="center">
                          <Grid item xs={12} md={12} lg={12} xl={12}>
                            <Stack direction="row" spacing={1}>
                              <PersonIcon />
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "5px",
                                  // Adjust marginLeft for different breakpoints if needed
                                }}
                              >
                                <Typography
                                  color="textSecondary"
                                  gutterBottom
                                  variant="h4"
                                  fontWeight={theme.typography.fontWeightMedium}
                                  marginLeft={"10px"}
                                >
                                  {!!getValues(Name) ? (
                                    isEditDetails && customerId > 0 ? (
                                      getValues(Name)
                                    ) : (
                                      <Link
                                        to={
                                          profile.RoleId === 1
                                            ? `/customers/view-customer/${customerId}`
                                            : "#"
                                        }
                                        style={{
                                          cursor: "pointer",
                                          textDecoration: isHovered
                                            ? "underline"
                                            : "none",
                                          textDecorationColor: isHovered
                                            ? "#000"
                                            : "none",
                                          textDecorationThickness: isHovered
                                            ? "2px"
                                            : "1px",
                                          color: "inherit",
                                        }}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}
                                      >
                                        {getValues(Name)}
                                      </Link>
                                    )
                                  ) : (
                                    t("messages.noCustomerName")
                                  )}
                                </Typography>
                              </div>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} mt={2}>
                        <Grid container spacing={0} alignItems="center">
                          <Grid item xs={12} md={12} lg={12} xl={12}>
                            <Stack direction={"row"} spacing={2}>
                              <LocationOnOutlinedIcon />
                              <div
                                style={{
                                  marginLeft: isMobileScreen ? "0px" : "18px",
                                  display: "flex",
                                  flexDirection: isMobileScreen
                                    ? "column"
                                    : "row", // Stack vertically on mobile
                                  alignItems: isMobileScreen
                                    ? "flex-start"
                                    : isLargeScreen
                                    ? "flex-end"
                                    : "center", // Align items on large screens to the end, center on medium screens
                                }}
                              >
                                <Typography
                                  color="textSecondary"
                                  variant="h4"
                                  fontWeight={theme.typography.fontWeightMedium}
                                  style={{
                                    wordBreak: isMobileScreen
                                      ? "break-word"
                                      : "normal", // Break address to the next line on mobile screens
                                    overflowWrap: isMobileScreen
                                      ? "break-word"
                                      : "normal", // Handle long words on mobile screens
                                  }}
                                >
                                  <a
                                    href={`https://www.google.com/maps?q=${getValues(
                                      Latitude
                                    )},${getValues(Longitude)}`}
                                    target="_blank"
                                    style={{
                                      color: theme.palette.secondary.main,
                                      textDecoration: "none", // Optional: Remove underline from link
                                      display: "block", // Ensures address breaks properly on mobile
                                    }}
                                  >
                                    {isMobileScreen ? (
                                      <>
                                        {getValues(AddressLine1) ||
                                          t("messages.noAddress")}
                                        {getValues(
                                          "CustomerAddress.AddressLine2"
                                        ) && (
                                          <>
                                            <br />
                                            {getValues(
                                              "CustomerAddress.AddressLine2"
                                            )}
                                          </>
                                        )}
                                        {getValues(City) && (
                                          <>
                                            <br />
                                            {getValues(City)}
                                          </>
                                        )}
                                        {getValues(StateName) && (
                                          <>
                                            <br />
                                            {getValues(StateName)}
                                          </>
                                        )}
                                        {getValues(PostCode) && (
                                          <>
                                            <br />
                                            {getValues(PostCode)}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {getValues(AddressLine1) ||
                                          t("messages.noAddress")}
                                        {getValues(
                                          "CustomerAddress.AddressLine2"
                                        ) &&
                                          `, ${getValues(
                                            "CustomerAddress.AddressLine2"
                                          )}`}
                                        {getValues(City) &&
                                          `, ${getValues(City)}`}
                                        {getValues(StateName) &&
                                          `, ${getValues(StateName)}`}
                                        {getValues(PostCode) &&
                                          `, ${getValues(PostCode)}`}
                                      </>
                                    )}
                                  </a>
                                </Typography>
                                {/* 
                                <Typography
                                  color="textSecondary"
                                  variant="h4"
                                  fontWeight={theme.typography.fontWeightMedium}
                                  marginLeft={"10px"}
                                >
                                  <a
                                    href={`https://www.google.com/maps?q=${getValues(
                                      Latitude
                                    )},${getValues(Longitude)}`}
                                    target="_blank"
                                    style={{
                                      color: theme.palette.secondary.main,
                                    }}
                                  >
                                    {!!getValues(City)
                                      ? getValues(City)
                                      : t("messages.noCity")}
                                    {!!getValues(StateName) &&
                                      `, ${getValues(StateName)}`}
                                    {!!getValues(PostCode) &&
                                      `, ${getValues(PostCode)}`}
                                  </a>
                                </Typography> */}
                              </div>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} mt={2}>
                        <Grid container spacing={0} alignItems="center">
                          <Grid item xs={12} md={12} lg={12}>
                            <Stack direction={"row"}>
                              <LocalPhoneOutlinedIcon />
                              <div
                                style={{
                                  marginTop: "5px",
                                }}
                              >
                                {getValues(Phone) ? (
                                  <Typography
                                    color="textSecondary"
                                    gutterBottom
                                    variant="h4"
                                    fontWeight={theme.typography.fontWeightBold}
                                    marginLeft={"18px"}
                                  >
                                    {!!getValues(Phone)
                                      ? getValues(Phone)
                                      : t("messages.noPhoneNumber")}
                                  </Typography>
                                ) : (
                                  <Typography
                                    color="textSecondary"
                                    gutterBottom
                                    variant="h4"
                                    fontWeight={theme.typography.fontWeightBold}
                                    marginLeft={"18px"}
                                  >
                                    {!!getValues("PhoneNumber")
                                      ? getValues("PhoneNumber")
                                      : t("messages.noPhoneNumber")}
                                  </Typography>
                                )}
                              </div>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} mt={2}>
                        <Grid container spacing={0} alignItems="center">
                          <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            // sx={{ ml: { xs: 0, sm: 0, md: -2, lg: 0 } }}
                          >
                            <Stack direction={"row"}>
                              <EmailOutlinedIcon />

                              <div
                                style={{
                                  marginLeft: {
                                    xs: "10px",
                                    md: "-10px",
                                  },
                                  marginTop: "5px",
                                }}
                              >
                                {getValues(Email) ? (
                                  <Typography
                                    color="textSecondary"
                                    gutterBottom
                                    variant="h4"
                                    fontWeight={theme.typography.fontWeightBold}
                                    marginLeft={"18px"}
                                  >
                                    {!!getValues(Email)
                                      ? getValues(Email)
                                      : t("messages.noEmail")}
                                  </Typography>
                                ) : (
                                  <Typography
                                    color="textSecondary"
                                    gutterBottom
                                    variant="h4"
                                    fontWeight={theme.typography.fontWeightBold}
                                    marginLeft={"18px"}
                                  >
                                    {!!getValues("EmailAddress")
                                      ? getValues("EmailAddress")
                                      : t("messages.noEmail")}
                                  </Typography>
                                )}
                              </div>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                      {getValues("IssueDate") ? (
                        <Grid item xs={12} md={12} mt={2}>
                          <Grid container spacing={0}>
                            <Grid
                              item
                              xs={12}
                              md={5.8}
                              lg={5.8}
                              sx={{
                                marginRight: {
                                  xs: "0px",
                                  sm: "0px",
                                },
                                marginLeft: isMobileScreen ? "0px" : "6px",
                              }}
                            >
                              <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="h2Summary"
                                fontWeight={theme.typography.fontWeightBold}
                              >
                                {t("textFieldLables.paymentIssue")}
                              </Typography>

                              {isEditDetails ? (
                                <TextField
                                  size="small"
                                  name="IssueDate"
                                  variant="outlined"
                                  placeholder={t("labels.editToAddDate")}
                                  error={!!errors?.IssueDate}
                                  helperText={errors?.IssueDate?.message}
                                  value={
                                    getValues(`IssueDate`)
                                      ? moment(getValues(`IssueDate`)).format(
                                          dateFormat
                                        )
                                      : ""
                                  }
                                  style={{
                                    pointerEvents: "auto",
                                    fontWeight:
                                      theme.typography.fontWeightMedium,
                                  }}
                                />
                              ) : (
                                <Typography
                                  size="small"
                                  variant="body1"
                                  color="textSecondary"
                                  style={{
                                    pointerEvents: "none",
                                    fontWeight:
                                      theme.typography.fontWeightMedium,
                                  }}
                                >
                                  {getValues(`IssueDate`)
                                    ? moment(getValues(`IssueDate`)).format(
                                        dateFormat
                                      )
                                    : ""}
                                </Typography>
                              )}
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={5.8}
                              lg={5.8}
                              sx={{
                                marginRight: {
                                  xs: "0px",
                                  sm: "0px",
                                },
                                marginLeft: isMobileScreen ? "0px" : "7px",
                              }}
                            >
                              <Stack direction="column" spacing={0}>
                                <Typography
                                  color="textSecondary"
                                  gutterBottom
                                  variant="h2Summary"
                                  fontWeight={theme.typography.fontWeightBold}
                                  sx={{ mb: 0, mt: 0 }}
                                >
                                  {t("textFieldLables.paymentDue")}
                                </Typography>

                                {isEditDetails ? (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <DateTimePickerTheme>
                                      <MobileDatePicker
                                        inputFormat={dateFormat}
                                        onChange={(e) => {
                                          if (!e) {
                                            toast.info(
                                              t(
                                                "toasts.paymentDueDateCannotBeEmpty"
                                              )
                                            );
                                          } else {
                                            const selectedDate = moment(e);
                                            const currentDate = moment();
                                            if (
                                              selectedDate?.isSameOrAfter(
                                                currentDate,
                                                "day"
                                              )
                                            ) {
                                              setValue(
                                                "DueDate",
                                                moment(e).format(
                                                  "YYYY-MM-DDTHH:mm"
                                                )
                                              );
                                              setIssueDateError(false);
                                            } else {
                                              setValue(
                                                "DueDate",
                                                moment(e).format(
                                                  "YYYY-MM-DDTHH:mm"
                                                )
                                              );
                                              setIssueDateError(true);
                                            }
                                          }
                                        }}
                                        value={getValues("DueDate")}
                                        componentsProps={{
                                          actionBar: {
                                            actions: [
                                              "clear",
                                              "cancel",
                                              "accept",
                                            ],
                                          },
                                        }}
                                        minDate={moment(getValues("IssueDate"))}
                                        renderInput={(params) => (
                                          <TextField
                                            fullWidth
                                            size="small"
                                            {...params}
                                            name={"DueDate"}
                                            variant="outlined"
                                            error={
                                              issueDateError ? true : false
                                            }
                                            helperText={
                                              errors?.DueDate?.message
                                            }
                                          />
                                        )}
                                      />
                                    </DateTimePickerTheme>
                                    {issueDateError && (
                                      <Tooltip
                                        placement="right"
                                        title={t(
                                          "validations.invalidDatePleaseSelectFutureDate"
                                        )}
                                      >
                                        <Info
                                          fontSize="small"
                                          sx={{
                                            color: theme.palette.secondary.dark,
                                            mt: "0.5rem",
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </Stack>
                                ) : (
                                  <Typography
                                    size="small"
                                    variant="outlined"
                                    style={{
                                      pointerEvents: "none",
                                      fontWeight:
                                        theme.typography.fontWeightMedium,
                                    }}
                                  >
                                    {getValues("DueDate")
                                      ? moment(getValues("DueDate")).format(
                                          dateFormat
                                        )
                                      : ""}
                                  </Typography>
                                )}
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : null}
                      {/* this for future implement 

                      {getValues("JobNumber") ||
                      (getValues("ScheduleLineItems") &&
                        (getValues(`Instructions`) !== "" || isEditDetails)) ? (
                        <Grid item md={12} lg={12}>
                          <Grid item xs={12} mt={1}>
                            <Typography
                              color="textSecondary"
                              gutterBottom
                              variant="h6"
                              fontWeight={theme.typography.fontWeightMedium}
                            >
                              {getValues("ScheduleLineItems") &&
                                isEditDetails &&
                                t("textFieldLables.scheduleDescription")}
                            </Typography>
                          </Grid>
                          <Grid item xs={11}>
                            <TextField
                              // required
                              name={Description}
                              {...register(Description)}
                              placeholder={t("messages.enterDescription")}
                              fullWidth
                              // value={getValues(Description)}
                              // onChange={(e) => {
                              // setValue(Description, e.target.value);
                              // }}
                              type="text"
                              variant={isEditDetails ? "outlined" : "standard"}
                              size="small"
                              multiline
                              rows={2}
                              error={!!errors?.Instructions}
                              helperText={errors?.Instructions?.message}
                              disabled={!isEditDetails}
                              InputProps={{
                                readOnly: !isEditDetails,
                                disableUnderline: true,
                                style: {
                                  color: theme.palette.primary.contrastText,
                                  fontWeight: theme.typography.fontWeightMedium,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      ) : null} */}
                      {getValues("RequestNumber") ? (
                        <Grid item xs={12} mt={1}>
                          <Grid container spacing={1}>
                            <Grid
                              item
                              xs={6}
                              md={5}
                              lg={5.8}
                              sx={{
                                marginRight: {
                                  xs: "0px",
                                  sm: "0px",
                                },
                                marginLeft: isMobileScreen ? "0px" : "8px",
                                "@media (max-width: 1320px) and (max-height: 950px)":
                                  {
                                    lg: "4",
                                  },
                              }}
                            >
                              <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="h2Summary"
                                fontWeight={theme.typography.fontWeightBold}
                                sx={{ mb: 1 }}
                              >
                                {t("labels.createdDate")}
                              </Typography>
                              <br />
                              {isEditDetails ? (
                                <TextField
                                  size="small"
                                  name={"CreatedDate"}
                                  variant="outlined"
                                  placeholder={t("labels.editToAddDate")}
                                  error={!!errors?.CreatedDate}
                                  helperText={errors?.CreatedDate?.message}
                                  value={
                                    getValues(`CreatedDate`)
                                      ? moment(getValues(`CreatedDate`)).format(
                                          dateFormat
                                        )
                                      : ""
                                  }
                                  style={{
                                    pointerEvents: "none",
                                  }}
                                />
                              ) : (
                                <Typography variant="body2">
                                  {getValues(`CreatedDate`)
                                    ? moment(getValues(`CreatedDate`)).format(
                                        dateFormat
                                      )
                                    : t("labels.noDate")}
                                </Typography>
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              md={5}
                              lg={5.8}
                              sx={{
                                display: "inline-block",
                                mb: "auto",
                                ml: "auto",
                                marginLeft: isMobileScreen ? "0px" : "8px",
                                "@media (max-width: 1320px) and (max-height: 950px)":
                                  {
                                    lg: "4",
                                  },
                              }}
                            >
                              <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="h2Summary"
                                fontWeight={theme.typography.fontWeightBold}
                                sx={{ mb: 1 }}
                              >
                                {t("labels.bookingType")}
                              </Typography>
                              {isEditDetails ? (
                                <FormControl fullWidth>
                                  <Select
                                    size="small"
                                    name={"BookingType"}
                                    variant="outlined"
                                    error={!!errors?.BookingType}
                                    helperText={errors?.BookingType?.message}
                                    value={getValues("BookingType")}
                                    disabled={getValues("BookingType") === 122}
                                    onChange={(e) => {
                                      setValue("BookingType", e.target.value);
                                    }}
                                    sx={{
                                      pointerEvents: isEditDetails
                                        ? "auto"
                                        : "none",
                                      "& .MuiSelect-icon": {
                                        display: isEditDetails
                                          ? "inline"
                                          : "none", // Hide the icon when not editable
                                      },
                                    }}
                                  >
                                    {types
                                      ?.filter(
                                        (res) => res?.Module === "Lead Booking"
                                      )
                                      ?.map(
                                        (option) =>
                                          !(
                                            getValues("BookingType") !== 122 &&
                                            option?.TypeId === 122
                                          ) && (
                                            <MenuItem
                                              key={option?.TypeId || 0}
                                              value={option?.TypeId || 0}
                                            >
                                              {option?.TypeName || ""}
                                            </MenuItem>
                                          )
                                      )}
                                  </Select>
                                </FormControl>
                              ) : (
                                <Typography variant="body2">
                                  {types?.find(
                                    (option) =>
                                      option?.TypeId ===
                                      getValues("BookingType")
                                  )?.TypeName || t("labels.noBookingType")}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : null}

                      {/* This for future implement
                      
                      {!getValues(`RequestNumber`) && (
                        <Grid
                          item
                          lg={4}
                          xs={3}
                          marginBottom={{ lg: "0px", xs: "120px" }}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Box
                            sx={{
                              border: "1px solid #F4A540",
                              p: 1,
                              borderRadius: 3,
                              alignItems: "center",
                              justifyContent: "center",
                              minWidth: "90px",
                              minHeight: "90px",
                              width: "auto",
                              height: "auto",
                              display: "flex",
                              flexDirection: "column", // Center content vertically
                            }}
                          >
                            <Typography
                              variant="h4"
                              fontWeight={theme.typography.fontWeightBold}
                              sx={{ textAlign: "center" }}
                            >
                              {t("labels.totalCapital")}
                            </Typography>

                            <Typography
                              variant="h4"
                              fontWeight={theme.typography.fontWeightBold}
                              sx={{ textAlign: "center" }}
                            >
                              {`${CurrencySymbol} ${(
                                totalNewTotal - getValues("Discount")
                              ).toFixed(2)}`}
                            </Typography>
                          </Box>
                        </Grid>
                      )} */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default CustomerInfoGrid;
