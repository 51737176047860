import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const postLogin = async (body) => {
  const url = new URL(`${baseUrl}/Login`);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse);
};

export const postRefresh = async (token, body) => {
  const url = new URL(`${baseUrl}/refresh`);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postLogout = async (token, body) => {
  const url = new URL(`${baseUrl}/logout?TodayDateTime=${body.TodayDateTime}`);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postForgetPassword = async (token, body) => {
  const url = new URL(`${baseUrl}/ForgetPassword`);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
