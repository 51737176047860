import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "src/auth/AuthProvider";
import CustomTab from "src/components/Common/CustomTab";
import ViewDeposits from "src/components/Payment/ViewDeposits";
import ViewPendingPayments from "src/components/Payment/ViewPendingPayments";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "80%",
    maxWidth: "none", // This ensures that the width does not get constrained by the default maxWidth setting
  },
}));

const ViewDepositsDialog = ({
  FieldId,
  TypeId,
  CustomerId,
  handlePayment,
  balance,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const auth = useContext(AuthContext);
  const CurrencySymbol = auth.getProfile()?.CurrencySymbol || "$";
  const token = auth.getAuthToken();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <CustomDialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Box>
            <Typography
              color="textSecondary"
              gutterBottom
              fontWeight={theme.typography.fontWeightBold}
              variant="h3"
            >
              {t("subHeadings.billingHistory")}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <CustomTab
            tabs={[t("tabs.deposits"), t("tabs.pendingPayment")]}
            panelComponents={[
              <ViewDeposits
                FieldId={FieldId}
                TypeId={TypeId}
                CustomerId={CustomerId}
              />,
              <ViewPendingPayments
                FieldId={FieldId}
                TypeId={TypeId}
                CustomerId={CustomerId}
              />,
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            {t("buttons.close")}
          </Button>
        </DialogActions>
      </CustomDialog>
      <Button onClick={handleOpen} sx={{ color: "#000000" }}>
        <Typography>{t("labels.billingHistory")}</Typography>
      </Button>
    </>
  );
};

export default ViewDepositsDialog;
