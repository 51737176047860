import {
  Pagination,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  useTheme,
} from "@mui/material";

const CustomPagination = ({
  rowsPerPageOptions,
  count,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  isTablePagination = true,
  isPagination = true,
}) => {
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const theme = useTheme();

  return (
    <TableFooter
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        mb: 2,

        // marginBottom: "20px",
      }}
      component="div"
    >
      <TableRow
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
          display: "flex",
          justifyContent: "flex-end",
          height: "100%",
          // marginBottom: "20px",
        }}
        component="div"
        className="tableBlur"
      >
        {isTablePagination && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              "& .MuiTablePagination-actions": {
                display: "none",
              },
            }}
            component="div"
          />
        )}
        {isPagination && (
          <TableCell
            component="div"
            sx={{ p: 1, marginTop: { xs: "0px", md: "10px" } }}
            style={{ border: "0px" }}
          >
            <Pagination
              count={Math.ceil(count / rowsPerPage) || 1}
              showFirstButton
              showLastButton
              onChange={handleChangePage}
              page={page + 1}
              // color={"primary"}
            />
          </TableCell>
        )}
      </TableRow>
    </TableFooter>
  );
};
export default CustomPagination;
