import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getAllCountries = async (token) => {
  const url = new URL(`${baseUrl}/country`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getCountryById = async (token, id) => {
  const url = new URL(`${baseUrl}/country/id?id=${id}`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postCountry = async (token, body) => {
  const url = new URL(`${baseUrl}/country`);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putCountry = async (token, id, body) => {
  const url = new URL(`${baseUrl}/country/id?id=${id}`);

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const deleteCountry = async (token, id) => {
  const url = new URL(`${baseUrl}/country/id?id=${id}`);

  let options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
