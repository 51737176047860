import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllTermsAndCons } from "src/api/termsAndConApi";

export const fetchTerms = createAsyncThunk(
  "terms/fetchTerms",
  async ({ token }, { rejectWithValue }) => {
    return getAllTermsAndCons(token)
      .then((data) => {
        return data.DATA;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

const initialState = {
  isLoading: false,
  error: null,
  data: [],
  filters: [],
};

const Slice = createSlice({
  name: "terms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTerms.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchTerms.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchTerms.rejected, (state, action) => {
        state.error = action.payload.STATUS_CODE;
        state.isLoading = false;
        throw action.payload;
      });
  },
});

export default Slice.reducer;
