import React from "react";
import { Skeleton, Card, Container, Grid, Button } from "@mui/material";

const QuoteSkeletonLoader = () => {
  return (
    <Container>
      <Card>
        <Grid container spacing={2} alignItems="center" justify="center" p={2}>
          <Grid item xs={12} md={12}>
            <Skeleton variant="rectangular" height={60} />
          </Grid>

          <Grid item xs={12} md={6}>
            <Skeleton variant="rectangular" height={160} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rectangular" height={160} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rectangular" height={160} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rectangular" height={160} />
          </Grid>

          <Grid item xs={12} container justifyContent="flex-end">
            <Grid item>
              <Skeleton variant="rectangular" width={80} height={36} />
            </Grid>
            <Grid item>
              <Skeleton variant="rectangular" width={80} height={36} />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default QuoteSkeletonLoader;
