import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Menu,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthContext } from "src/auth/AuthProvider";

const ColumnViewer = ({
  columns,
  setAnchorEl,
  anchorEl,
  setRenderColumns,
  page = "customer",
}) => {
  const theme = useTheme();
  const auth = useContext(AuthContext);
  const profile = auth.getProfile();
  const { t } = useTranslation();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const [viewColumns, setViewColumns] = useState(columns);

  const getStoredData = () => {
    const storedData = localStorage.getItem("columns");
    return storedData ? JSON.parse(storedData) : null;
  };

  const updateInvoiceDataInLocalStorage = (updatedInvoiceData) => {
    setInitialSettings(updatedInvoiceData);
    const storedData = getStoredData();
    const newData = !storedData
      ? { [page]: updatedInvoiceData }
      : { ...storedData, [page]: updatedInvoiceData };
    localStorage.setItem("columns", JSON.stringify(newData));
  };
  const settingsChange = (col) => {
    return col.reduce((acc, cell, index) => {
      if (cell.isView !== undefined && cell.isView === true) {
        return acc + "1";
      } else {
        return acc + "0";
      }
    }, "");
  };

  const [initialSettings, setInitialSettings] = useState(null);

  useEffect(() => {
    const allSelectableColumnsSelected = viewColumns
      .filter((column) => column.name !== "CustomerName")
      .every((column) => column.isView);

    !!getStoredData()?.[page]
      ? setInitialSettings(getStoredData()?.[page])
      : setInitialSettings(settingsChange(columns));
  }, []);

  const handleChange = (e, index) => {
    const updatedViewColumns = [...viewColumns];
    updatedViewColumns[index].isView = e.target.checked;

    // Ensure CustomerName is always selected
    if (updatedViewColumns[index].name === "CustomerName") {
      updatedViewColumns[index].isView = true;
    }

    // setViewColumns(updatedViewColumns);

    // Check if all selectable columns are selected
    const allSelectableColumnsSelected = updatedViewColumns
      .filter((column) => column.name !== "CustomerName")
      .every((column) => column.isView);

    const settingsArray = initialSettings.split("");

    settingsArray[index] = e.target.checked ? "1" : "0";
    updateInvoiceDataInLocalStorage(settingsArray.join(""));
  };

  const handleSelectAllChange = (e) => {
    const updatedViewColumns = viewColumns.map((column) => ({
      ...column,
      isView:
        column.name === "CustomerName" ||
        e.target.checked ||
        column.name === "EmployeeName" ||
        e.target.checked ||
        column.name === "ProductName" ||
        e.target.checked,

      // isView: column.name === "EmployeeName" || e.target.checked,
    }));
    // setViewColumns(updatedViewColumns);
    updateInvoiceDataInLocalStorage(settingsChange(updatedViewColumns));
  };

  useEffect(() => {
    setRenderColumns(
      columns.filter((col, index) =>
        typeof col.isView === "boolean"
          ? !!initialSettings
            ? initialSettings.split("")[index] === "1"
              ? true
              : false
            : col.isView
          : true
      )
    );
  }, [initialSettings]);
  // if (profile?.roleId != 1) return;
  return (
    <Box sx={{ display: "flex", maxHeight: "200px" }}>
      <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
        <FormGroup>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            // onClick={handleClose}
            component="ul"
            PaperProps={{
              elevation: 0,
              sx: {
                maxHeight: "400px",
                overflow: "body",
                borderRadius: 3,
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 110,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={/^1+$/.test(initialSettings)}
                    onChange={handleSelectAllChange}
                    name="SELECT ALL"
                    sx={{ color: theme.palette.primary.main }}
                  />
                }
                label={t("labels.selectAll")}
              />
            </MenuItem>
            {viewColumns.map((column, index) => (
              <MenuItem key={index} disabled={!!column.isDefault}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        typeof column.isView === "boolean"
                          ? !!initialSettings
                            ? initialSettings.split("")[index] === "1"
                              ? true
                              : false
                            : column.isView
                          : true
                      }
                      onChange={(e) => {
                        handleChange(e, index);
                      }}
                      name={column.name}
                      sx={{ color: theme.palette.primary.main }}
                    />
                  }
                  label={column.label}
                />
              </MenuItem>
            ))}
          </Menu>
        </FormGroup>
      </FormControl>
    </Box>
  );
};
export default ColumnViewer;
