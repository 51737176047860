import { yupResolver } from "@hookform/resolvers/yup";
import { Info } from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { alpha, styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteJob, putJob } from "src/api/jobApi";
import { changeStatus } from "src/api/requestApi";
import { AuthContext } from "src/auth/AuthProvider";
import EditButton from "src/components/Common/Button/EditButton";
import SaveEditButton from "src/components/Common/Button/SaveEditButton";
import CustomerInfoGrid from "src/components/Common/CustomerInfoGrid";
import DeleteConfirmationDialog from "src/components/Common/DeleteConfirmationDialog";
import {
  getStatusColor,
  getStatusTextColor,
} from "src/components/Common/TabTable/StatusColor";
import FileUpload from "src/components/DropZone/FileUpload";
import { JOB_DOCUMENT_ID } from "src/components/DropZone/TypeConstant_config";
import CancelDialog from "src/components/Job/CancelDialog";

import * as yup from "yup";

// necessary for content to be below app bar
const ToolbarContainer = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

const DeleteConfirmation = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "10px",
  padding: "40px",
  flexDirection: "column",
  alignItems: "center",
  boxShadow: "10px",
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.textSecondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const RecurringJobOV = ({
  customers,
  types,
  employees,
  services,
  products,
  taxes,
  questionnaireResponses,
  setQuestionnaireResponses,
  jobDetails,
  eventName,
}) => {
  const auth = useContext(AuthContext);
  const token = auth.getAuthToken();
  const profile = auth.getProfile();
  const dateFormat = auth.getProfile().DateFormat;
  const timeFormat = auth.getProfile().TimeFormat;
  const maxEventCount = auth.getProfile().MaxEventCount;

  const theme = useTheme();
  const { t } = useTranslation();

  const [jobData, setJobData] = useState(jobDetails);

  const { jobId } = useParams();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [isEditDetails, setIsEditDetails] = useState(false);

  const isDownMd = useMediaQuery(theme.breakpoints.down("lg"));
  const [showButtons, setShowButtons] = useState(false);

  const toggleButtons = () => {
    setShowButtons(!showButtons);
  };

  const validationSchema = yup.object().shape({
    // JobTypeId: yup.string().required("Required"),
    Description: yup
      .string()
      .max(500, t("validations.characterLimitExceeded"))
      .required(t("validations.required")),
    Employee: yup.string(),
    CustomerMessage: yup
      .string()
      .max(500, t("validations.characterLimitExceeded")),
    Notes: yup.string().max(500, t("validations.characterLimitExceeded")),
    Instruction: yup.string().max(500, t("validations.characterLimitExceeded")),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: jobDetails,
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  watch();

  {
    /** -----------------------Employees----------------------------- */
  }

  {
    /** -----------------------LineItemComponent----------------------- */
  }

  const [TypeStatus, setTypeStatus] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

    // setOpen(!open);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handletypeChange = (event) => {
    setAnchorEl(null);
    if (
      (getValues("Type") === "Ongoing" || getValues("Type") === "Completed") &&
      event.target.outerText === "Cancel"
    ) {
      setIsCancelPopup(true);
    } else {
      handleChangeStatus(event.target.outerText);
    }
  };

  const handleChangeStatus = (status) => {
    changeStatus(token, parseInt(jobId), status, "3")
      .then((response) => {
        if (response?.STATUS_CODE === "200") {
          toast.success(t("toasts.successfullyChangedTheStatus"));
          setValue("Type", status);
          setTypeStatus(status);
          if (status == "Cancel") {
            setJobData({ ...jobData, IsConvertable: false });
            setValue("Type", status);
            setTypeStatus("Cancelled");
          }
        }
      })
      .catch((error) => {
        toast.error(t("toasts.failedToChangeTheStatus"));
      });
  };
  /** ---------------------Ongoing Complete Popup---------------------- */
  const [isCancelPopup, setIsCancelPopup] = useState(false);
  /** --------------------------------------------------------- */

  {
    /** --------------------------------------------------------- */
  }

  const menuItems = [
    {
      name: "Cancel",
      label: "Cancel",
      // checked: printDetails.Filter,
    },
    // {
    //   name: "Ongoing",
    //   label: "Ongoing",
    //   // checked: printDetails.Filter,
    // },
    {
      name: "Completed",
      label: "Completed",
      // checked: printDetails.Filter,
    },
    // {
    //   name: "sortBy",
    //   label: "SortBy",
    //   checked: printDetails.sortBy,
    // },
  ];

  const [jobLoading, setJobLoading] = useState(false);

  // const getJob = async () => {
  //   setJobLoading(true);
  //   let job = await getJobById(token, jobId);

  //   if (job?.STATUS_CODE !== "200") {
  //     toast.error("Job Details Loading Failed!!");
  //     setJobLoading(false);
  //   } else {
  //     setJobData(job?.DATA);
  //     setValue("NewLineItems", []);
  //     setValue("NewProductLineItems", []);
  //     setJobLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   jobId > 0 && getJob();
  // }, []);

  useEffect(() => {
    if (jobId > 0 && !!jobData) {
      for (const [key, value] of Object.entries(jobData)) {
        setValue(key, value);
      }
    }
  }, [jobData, jobId]);
  watch();

  const CurrencySymbol = auth.getProfile().CurrencySymbol;

  {
    /**------------------------Calculations----------------------- */
  }

  const [discount, setDiscount] = useState(``);

  const Totaltotal = getValues("Total");

  const onSubmit = async (values) => {
    toast.dismiss();

    const arrVisit = values.visits?.filter((data) => data.IsDelete != true);
    const lenVisits = arrVisit.length;

    let startDate = moment(arrVisit?.[0]?.StartDate).format("YYYY-MM-DD");
    let startTime = moment(arrVisit?.[0]?.StartTime).format("HH:mm:ss");

    let endDate = moment(arrVisit?.[lenVisits - 1]?.EndDate).format(
      "YYYY-MM-DD"
    );
    let endTime = moment(arrVisit?.[lenVisits - 1]?.EndTime).format("HH:mm:ss");

    setValue(`IsScheduled`, true);
    const dataRecord = {
      ...values,

      Discount:
        getValues("Discount") == null ? 0.0 : parseFloat(getValues("Discount")),

      RequestStatusId: parseInt(values.RequestStatusId),
      AddressId: parseInt(values.AddressId),

      ...(true && lenVisits >= 1
        ? {
            StartDate: moment(`${startDate} ${startTime}`).format(
              "YYYY-MM-DDTHH:mm"
            ),

            EndDate: moment(`${endDate} ${endTime}`).format("YYYY-MM-DDTHH:mm"),

            StartTime: moment(`${startDate} ${startTime}`).format(
              "YYYY-MM-DDTHH:mm"
            ),

            EndTime: moment(`${endDate} ${endTime}`).format("YYYY-MM-DDTHH:mm"),
            Visits: values.visits,
            visits: values.visits,
            IsScheduled: true,
            Employees: !!values.visits?.[0]?.Employees
              ? values.visits?.[0]?.Employees
              : [],
          }
        : {
            StartDate: null,
            EndDate: null,
            EndTime: null,
            StartTime: null,
            Visits: [],
            Employees: [],
            IsScheduled: false,
            visits: [],
          }),
      IsOneOff: values.visits.length > 1 ? false : true,
      ForValue: values.forValue + "," + values.forType,
      EveryValue: values.everyValue + "," + values.everyType,
      UpdatedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
    };

    if (jobId > 0 && !values.IsDelete) {
      if (moment(dataRecord?.EndDate).isBefore(moment(dataRecord?.StartDate))) {
        toast.error(t("toasts.endDateShouldBeFutureDateThanTheStartDate"));
      } else {
        setLoading(true);

        await putJob(token, jobId, dataRecord)
          .then((response) => {
            if (response?.STATUS_CODE === "200") {
              // getJob();
              toast.success(t("toasts.jobUpdatedSuccessfully"));
              setLoading(false);
              setIsEditDetails(false);
              // reset();
              // getSchedule();
            } else {
              toast.error(t("toasts.failedToUpdateJob"));
              setLoading(false);
            }
          })
          .catch((e) => {
            toast.error(t("toasts.failedToUpdateJob"));
            setLoading(false);
          });
      }
    } else if (values.IsDelete) {
      await putJob(token, jobId, {
        ...dataRecord,
        IsDelete: true,
        QuestionResponses: values?.QuestionResponses,
      })
        .then((response) => {
          if (response?.STATUS_CODE === "200") {
            toast.success(t("toasts.jobDeletedSuccessfully"));
            setLoading(false);
            setIsEditDetails(false);
            // reset();
            // navigate("/jobs");
          } else {
            toast.error(t("toasts.failedToDeleteJob"));
            setValue("IsDelete", false);
            setLoading(false);
          }
        })
        .catch((e) => {
          toast.error(t("toasts.failedToDeleteJob"));
          setLoading(false);
        });
    }
  };

  const handleEditDetails = () => {
    setValue(`NewLineItems`, []);
    setValue(`NewProductLineItems`, []);
    setValue(`NewEmployees`, []);
    setIsEditDetails(!isEditDetails);
    // setEditedResponses([...questionResponses]);
  };

  const handleCancelButtonClick = () => {
    setIsEditDetails(false);
    // setJobData({});
    reset(jobData);
    toast.info("Edit canceled!");
    setValue(`NewLineItems`, []);
    setValue(`NewProductLineItems`, []);
    setValue(`NewEmployees`, []);
    // setValue("IsScheduled", false);
    // setIsScheduled(false);
  };

  const handleConvertToInvoice = () => {
    setTimeout(() => {
      navigate("/invoices/new-invoice", {
        state: {
          isConvert: true,
          isFromJob: !!parseInt(jobId) ? parseInt(jobId) : 0,
        },
      });
    }, 1000);
  };

  /*=============================QUESTIONNAIRES=============================================*/

  /** ----------------------Skill Set ------------------------------ */

  /** ---------------------------- Recurring Job ------------------------------ */

  const [values, setValues] = useState(0);

  const handleChange = (newValue) => {
    setValues(newValue);
  };

  function a11yPropsType(indexType) {
    return {
      id: `simple-tab-${indexType}`,
      "aria-controls": `simple-tabpanel-${indexType}`,
    };
  }

  const [isHovering, setIsHovering] = useState(false);

  // const employeeVisitCountMap = new Map();

  // const calculateVisitCounts = () => {
  //   const result = [];
  //   console.log(employees);

  //   employees?.forEach((employee) => {
  //     const uniqueVisitDates = [...new Set(employee.StartDate)];
  //     const count = uniqueVisitDates.length;

  //     if (employee.EmployeeNames) {
  //       employee.EmployeeNames?.forEach((employeeName) => {
  //         const currentCount = employeeVisitCountMap.get(employeeName) || 0;
  //         employeeVisitCountMap?.set(employeeName, currentCount + count);
  //       });
  //     }

  //     result?.push({
  //       employeeNames: employee.EmployeeNames,
  //       counts: Array?.from(employeeVisitCountMap),
  //     });
  //   });

  //   return result;
  // };

  function mapEmployeeVisitCounts(visitDates, employees, employeeId) {
    const employeeVisitCounts = {};
    let visitCount = 0;
    const counts = {
      ongoing: 0,
      overdue: 0,
    };

    visitDates?.forEach((visitDate) => {
      const type = visitDate.Type || "OnGoing"; //"Cancel" // "Completed"

      visitDate.Employees?.forEach((employee) => {
        const currentEmployeeId = employee.EmployeeId;

        const matchingEmployee = employees.find(
          (e) => e.EmployeeId === currentEmployeeId
        );

        if (matchingEmployee) {
          if (!employeeVisitCounts[currentEmployeeId]) {
            employeeVisitCounts[currentEmployeeId] = 0;
          }

          employeeVisitCounts[currentEmployeeId]++;
          if (visitDate.Type === "OnGoing") {
            counts.ongoing++;
          } else if (visitDate.Type === "OverDue") {
            counts.overdue++;
          }
        }

        if (employee.EmployeeId === employeeId) {
          visitCount++;
        }
      });
    });

    return { employeeVisitCounts, visitCount, counts };
  }

  const result = mapEmployeeVisitCounts(getValues(`visits`), employees);

  /** ------------------------------ Percentage ----------------------------------- */

  /** ------------------------------ VisitCard ----------------------------------- */

  /** --------------------------------------------------------------------------- */
  const [anchorElMenu1, setAnchorElMenu1] = useState(null);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <>
          <Grid container spacing={3} marginTop={{ xs: 1, sm: 1, md: -1 }}>
            <Grid item container xs={12} lg={12}>
              <Grid item xs={12} md={12} lg={12}>
                <Stack
                  direction={isDownMd ? "column" : "row"}
                  justifyContent="flex-start"
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={5.9}
                    sx={{
                      display: "flex",
                      // justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack direction={isDownMd ? "column" : "row"}>
                      <Typography
                        variant="h2"
                        align="left"
                        // display="inline"
                        style={{
                          // wordWrap: "break-word",
                          marginLeft: isDownMd ? "0px" : "10px",
                          marginTop: "10px",
                        }}
                      >
                        {getValues(`Title`)}
                      </Typography>
                      {!isDownMd && (
                        <div>
                          <Button
                            disabled={profile.RoleId != 1}
                            id="demo-positioned-button"
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                            style={{
                              marginLeft: isDownMd ? "0px" : "20px",
                              width: isDownMd ? "80%" : "200px",
                              height: "40px",
                              borderRadius: "12px",
                              boxSizing: "border-box",
                              color: getStatusTextColor(
                                TypeStatus != null
                                  ? TypeStatus
                                  : getValues("Type")
                                  ? getValues("Type")
                                  : "New",
                                theme
                              ),
                              backgroundColor:
                                getStatusColor(
                                  TypeStatus != null
                                    ? TypeStatus
                                    : getValues("Type")
                                    ? getValues("Type")
                                    : "New",
                                  theme
                                ) || theme.palette.input.background,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer", // Add this to make it appear clickable
                            }}
                          >
                            <Typography
                              variant="h4"
                              display="inline"
                              sx={{
                                wordWrap: "break-word",
                              }}
                            >
                              {TypeStatus != null
                                ? TypeStatus
                                : getValues("Type")
                                ? getValues("Type")
                                : "New"}
                            </Typography>
                          </Button>
                          {getValues("Type") !== "Converted To Jobs" &&
                            TypeStatus !== "Cancelled" &&
                            getValues("Type") !== "Cancelled" && (
                              <StyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                  "aria-labelledby": "demo-customized-button",
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                              >
                                {menuItems?.map(
                                  (menuItem, index) =>
                                    menuItem.name !== getValues("Type") && (
                                      <MenuItem
                                        key={index}
                                        onClick={handletypeChange}
                                      >
                                        {menuItem.name}
                                      </MenuItem>
                                    )
                                )}
                              </StyledMenu>
                            )}
                        </div>
                      )}
                    </Stack>
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={-2}>
              {isDownMd && (
                <div style={{ width: "100%", marginLeft: "-10px" }}>
                  <Button
                    id="demo-positioned-button"
                    // aria-controls={open ? 'demo-positioned-menu' : undefined}
                    // backgroundColor="#0AC962"
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    // onClick={handleClick}
                    style={{
                      marginLeft: "10px",
                      width: "100%",
                      height: "40px",
                      borderRadius: "12px",
                      boxSizing: "border-box",
                      color: getStatusTextColor(
                        TypeStatus != null
                          ? TypeStatus
                          : getValues("Type")
                          ? getValues("Type")
                          : "New",
                        theme
                      ),
                      backgroundColor:
                        getStatusColor(
                          TypeStatus != null
                            ? TypeStatus
                            : getValues("Type")
                            ? getValues("Type")
                            : "New",
                          theme
                        ) || theme.palette.input.background,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer", // Add this to make it appear clickable
                    }}
                  >
                    <Typography
                      variant="h4"
                      display="inline"
                      sx={{
                        wordWrap: "break-word",
                      }}
                    >
                      {TypeStatus != null
                        ? TypeStatus
                        : getValues("Type")
                        ? getValues("Type")
                        : "New"}
                    </Typography>
                  </Button>
                  {getValues("Type") !== "Converted To Jobs" &&
                    TypeStatus !== "Cancelled" &&
                    getValues("Type") !== "Cancelled" && (
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                      >
                        {menuItems?.map(
                          (menuItem, index) =>
                            menuItem.name !== getValues("Type") && (
                              <MenuItem key={index} onClick={handletypeChange}>
                                {menuItem.name}
                              </MenuItem>
                            )
                        )}
                      </StyledMenu>
                    )}
                </div>
              )}
            </Grid>

            <Grid
              item
              container
              // mt={-4}
              spacing={{ xs: 1 }}
              padding={{ xl: 3, md: 2, xs: 1 }}
              variant="outlined"
            >
              <Grid item xs={12} lg={12}>
                <CustomerInfoGrid
                  getValues={getValues}
                  setValue={setValue}
                  errors={errors}
                  theme={theme}
                  totalNewTotal={Totaltotal}
                  CurrencySymbol={CurrencySymbol}
                  isEditDetails={isEditDetails}
                  Title={"Title"}
                  Name={"CustomerName"}
                  Phone={"Phone"}
                  Email={"Email"}
                  AddressLine1={"AddressLine1"}
                  City={"City"}
                  StateName={"StateName"}
                  CountryName={"CountryName"}
                  PostCode={"ZipCode"}
                  Id={"JobNumber"}
                  Latitude={`Latitude`}
                  Longitude={`Longitude`}
                  Description={"Description"}
                  dateFormat={dateFormat}
                  register={register}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2} marginBottom={"20px"}>
                  <Grid item xs={12}>
                    <Grid container spacing={0}>
                      <Grid item container spacing={0}>
                        <Grid item xs={12}>
                          <Stack
                            direction={isDownMd ? "column" : "row"}
                            spacing={6}
                          >
                            <Grid item md={12} lg={6}>
                              <Grid item xs={12} mt={1}>
                                <Typography
                                  color="textSecondary"
                                  gutterBottom
                                  variant="h6"
                                  fontWeight={theme.typography.fontWeightMedium}
                                >
                                  {t("textFieldLables.customerMessage")}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  // required
                                  name="CustomerMessage"
                                  {...register("CustomerMessage")}
                                  placeholder={t("labels.enterCustomerMessage")}
                                  fullWidth
                                  type="text"
                                  variant={
                                    isEditDetails ? "outlined" : "standard"
                                  }
                                  size="small"
                                  multiline
                                  rows={3}
                                  // // error={!!errors.Description}
                                  // helperText={errors.Description?.message}
                                  disabled={!isEditDetails}
                                  InputProps={{
                                    readOnly: !isEditDetails,
                                    disableUnderline: true,
                                    style: {
                                      color: theme.palette.primary.contrastText,
                                      fontWeight:
                                        theme.typography.fontWeightMedium,
                                    },
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid item md={12} lg={6}>
                              <Grid item xs={12}>
                                <Typography
                                  color="textSecondary"
                                  gutterBottom
                                  variant="h6"
                                  fontWeight={theme.typography.fontWeightBold}
                                  sx={{ display: "inline-block" }}
                                >
                                  {t("textFieldLables.notes")}
                                </Typography>

                                <Tooltip
                                  title={
                                    "Use this field to provide any additional information, comments, or context related to the current task."
                                  }
                                  placement="right"
                                >
                                  <Info
                                    fontSize="small"
                                    sx={{
                                      color: theme.palette.secondary.dark,
                                      ml: "1rem",
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  name="Instruction"
                                  {...register("Instruction")}
                                  placeholder={t("messages.enterNotes")}
                                  fullWidth
                                  type="text"
                                  variant={
                                    isEditDetails ? "outlined" : "standard"
                                  }
                                  size="small"
                                  multiline
                                  rows={3}
                                  error={!!errors.Instruction}
                                  helperText={errors.Instruction?.message}
                                  disabled={!isEditDetails}
                                  InputProps={{
                                    readOnly: !isEditDetails,
                                    disableUnderline: true,
                                    style: {
                                      color: theme.palette.primary.contrastText,
                                      fontWeight:
                                        theme.typography.fontWeightMedium,
                                    },
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} mt={1} mb={1}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Stack
                            direction={isDownMd ? "column" : "row"}
                            spacing={5}
                          >
                            <Grid item md={12} lg={6}>
                              <FileUpload
                                Directory={`Job/JobId_${jobId}/JobOverAll`}
                                isFirst={false}
                                FieldId={jobId}
                                FieldTypeId={JOB_DOCUMENT_ID}
                                FieldName="Job"
                                Title={t("headings.attachments")}
                                IsEditable={jobData?.IsEditable}
                              />
                            </Grid>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} mt={1} mb={1}>
                          <Divider />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>

        <Grid
          item
          xs={12}
          justifyContent={"end"}
          marginTop={"-30px"}
          marginRight={"30px"}
        >
          <div>
            <Stack
              direction="row"
              alignItems="flex-end"
              justifyContent="flex-end"
              spacing={2}
            >
              {isEditDetails ? (
                <>
                  <SaveEditButton
                    isAddPage={false}
                    loading={loading}
                    title={"Add New"}
                    onClickFunc={handleCancelButtonClick}
                    width={{ xs: "70px", sm: "100px", md: "auto" }}
                  />
                  {!(
                    getValues("Type") === "Ongoing" ||
                    getValues("Type") === "Completed"
                  ) && (
                    <DeleteConfirmationDialog
                      deleteApiCall={deleteJob}
                      id={jobId}
                      token={token}
                      width={{ xs: "70px", sm: "100px", md: "auto" }}
                    />
                  )}
                </>
              ) : (
                jobData.IsEditable && (
                  <EditButton handleClick={handleEditDetails} />
                )
              )}
            </Stack>
          </div>
        </Grid>
      </form>

      {isCancelPopup && (
        <CancelDialog
          open={isCancelPopup}
          setOpen={setIsCancelPopup}
          handleChangeStatus={handleChangeStatus}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // customers: state.customer.dataRecords,
    // services: state.service.services,
    // products: state.product.products,
    // taxes: state.tax.taxes,
    employees: state.employee.employees,
    types: state.type.types,
  };
};

export default connect(mapStateToProps, null)(RecurringJobOV);
