import { baseUrl, handleResponse, handleError } from "./apiUtils";
import { addingParams } from "./apiUtils";

export const postFiles = async (token, body) => {
  const url = new URL(`${baseUrl}/FileUpload`);

  let options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: body,
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getAllFiles = async (token, filters) => {
  const url = new URL(`${baseUrl}/FileUpload`);

  for (const [key, value] of Object.entries(filters)) {
    if (value !== null && value !== undefined && value !== "") {
      url.searchParams.append(key, value);
    }
  }

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const deleteFile = async (token, FileId, filters) => {
  const url = new URL(`${baseUrl}/FileUpload/${FileId}`);

  for (const [key, value] of Object.entries(filters)) {
    if (value !== null && value !== undefined && value !== "") {
      url.searchParams.append(key, value);
    }
  }

  let options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
