import { useTheme } from "@mui/material/styles";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";

const DialogContainer = ({
  open,
  handleClose,
  title = "Add new",
  editTitle = "Update",
  btnTitle = "",
  btnEditTitle = "update",
  isEdit = false,
  loading = false,
  submitLoading = false,
  Loader,
  children,
  handleSubmit,
  onSubmit,
  maxWidth = "md",
  scroll = "body",
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      scroll={scroll}
      maxWidth={maxWidth}
    >
      {loading ? (
        Loader
      ) : (
        <React.Fragment>
          <DialogTitle
            variant="h3"
            onClose={handleClose}
            sx={{
              textTransform: "uppercase",
              fontWeight: theme.typography.fontWeightBold,
            }}
          >
            {isEdit ? editTitle : title}
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            {children}
            <DialogActions>
              <Button
                onClick={handleClose}
                disabled={submitLoading}
                color="inherit"
                sx={{
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.secondary.contrastText,
                  border: `1px solid ${theme.palette.primary.main}`,
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.contrastText,
                  },
                  // textTransform: "uppercase",
                }}
              >
                {t("buttons.cancel")}
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={submitLoading}
                disabled={submitLoading}
              >
                {btnTitle ||
                  (isEdit ? t(`buttons.saveChanges`) : t(`buttons.save`))}
              </LoadingButton>
            </DialogActions>
          </form>
        </React.Fragment>
      )}
    </Dialog>
  );
};

export default DialogContainer;
