import { useRef, useState } from "react";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SwapVertIcon from "@mui/icons-material/SwapVert";

const TableHeader = ({ columns, setRenderColumns, isArrow, setIsArrow }) => {
  const dragColumn = useRef();
  const theme = useTheme();

  const dragOverColumn = useRef();

  const dragStart = (e, position) => {
    dragColumn.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverColumn.current = position;
  };

  const drop = (e) => {
    const copyRenderColumns = [...columns];
    const dragItemContent = copyRenderColumns[dragColumn.current];
    copyRenderColumns.splice(dragColumn.current, 1);
    copyRenderColumns.splice(dragOverColumn.current, 0, dragItemContent);
    dragColumn.current = null;
    dragOverColumn.current = null;
    setRenderColumns(copyRenderColumns);
  };

  {
    /** ---------------------- Sorting ---------------------- */
  }

  const createSortHandler = (name) => {
    setIsArrow((data) => ({
      SortBy: name,
      IsAsc: data.SortBy === name ? !data.IsAsc : true,
    }));
  };

  {
    /** ----------------------------------------------------- */
  }

  return (
    <TableHead>
      <TableRow>
        {columns?.map(
          (
            {
              name,
              label,
              headerLabelAlign,
              disablePadding,
              isSortable,
              sortName,
            },
            index
          ) => {
            return (
              <TableCell
                sx={{
                  color: theme.components.centeredCell?.color,
                  border: theme.components.centeredCell?.border,
                  backgroundColor:
                    theme.components.centeredCell?.backgroundColor,
                }}
                padding={disablePadding ? "none" : "normal"}
                align={!!headerLabelAlign ? headerLabelAlign : "left"}
                key={index}
                // onDragStart={(e) => dragStart(e, index)}
                // onDragEnter={(e) => dragEnter(e, index)}
                // onDragEnd={drop}
                // draggable
              >
                {isSortable ? (
                  <TableSortLabel
                    active={sortName === isArrow?.SortBy}
                    direction={
                      sortName === isArrow.SortBy
                        ? isArrow.IsAsc
                          ? "asc"
                          : "desc"
                        : "asc"
                    }
                    sx={{
                      ".MuiTableSortLabel-icon": {
                        visibility:
                          sortName === isArrow?.SortBy ? "visible" : "hidden",
                      },
                      ".MuiDataGrid-sortIcon": {
                        opacity: sortName === isArrow?.SortBy ? 1 : 0,
                      },
                      flexDirection:
                        headerLabelAlign === "left" ? "row-reverse" : "row",
                      mr: sortName !== isArrow?.SortBy ? -2 : 0.4,
                    }}
                    hideSortIcon={false}
                    onClick={() => {
                      createSortHandler(sortName);
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        cursor: "auto",
                        fontStyle: "normal",
                        fontWeight: 900,
                        lineHeight: "16px",
                        fontSize: columns?.length > 5 ? 12.5 : "0.95rem",
                        display: "flex",
                        alignItems: "center",
                        letterSpacing: "0.03em",
                        whiteSpace: "nowrap",
                      }}
                      variant="tableHeaders"
                    >
                      {label}
                      {!(sortName === isArrow?.SortBy) ? (
                        <SwapVertIcon
                          fontSize="small"
                          sx={{ marginLeft: -0.25 }}
                        />
                      ) : null}
                    </Typography>
                  </TableSortLabel>
                ) : (
                  <TableSortLabel
                    sx={{
                      ".MuiTableSortLabel-icon": {
                        visibility: "hidden",
                      },
                      marginRight: !!headerLabelAlign
                        ? headerLabelAlign === "right"
                          ? 2
                          : 0
                        : 0,
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        cursor: "auto",
                        fontStyle: "normal",
                        fontWeight: 900,
                        lineHeight: "16px",
                        display: "flex",
                        alignItems: "center",
                        letterSpacing: "0.03em",
                        whiteSpace: "nowrap",
                      }}
                      variant="tableHeaders"
                    >
                      {label}
                    </Typography>
                  </TableSortLabel>
                )}
              </TableCell>
            );
          }
        )}
      </TableRow>
    </TableHead>
  );
};
export default TableHeader;
