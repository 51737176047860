import moment from "moment";

export function createFullName({ firstName, middleName, lastName }) {
  let name =
    (firstName || "") + " " + (middleName || "") + " " + (lastName || "");
  return name?.trim();
}

export function buildAddress({
  AddressLine1 = null,
  AddressLine2 = null,
  City = null,
  StateName = null,
  CountryName = null,
  ZipCode = null,
  isTextField = false,
}) {
  if (
    !AddressLine1 &&
    !AddressLine2 &&
    !City &&
    !StateName &&
    !CountryName &&
    !ZipCode
  ) {
    return isTextField ? "" : "-";
  }
  let data = [
    AddressLine1,
    AddressLine2,
    City,
    StateName,
    CountryName,
    ZipCode,
  ];
  const address = data.filter((value) => !!value);
  return address.join(", ");
}

export function formatText(text) {
  return text
    ?.replace(/(_|-)/g, " ")
    .trim()
    .replace(/\w\S*/g, function (str) {
      return str.charAt(0).toUpperCase() + str.substr(1);
    })
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
}

export function removeSpecialCharacters(data) {
  return data?.replace(/[^a-zA-Z]+/g, "");
}

export function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function fDate(date) {
  return moment(date).format("DD MMMM yyyy");
}

export function fDateShort(date, format = "yyyy-MM-DD") {
  return moment(date).format(format);
}

export function fTimeShort(time) {
  return moment(time).format("HH:mm");
}

export function fTimeShort_12h(time) {
  return moment(time).format("hh:mm a");
}

export function dateTimeFormat(d, t) {
  return moment(fDate(d) + " " + fTimeShort_12h(t)).format("yyyy-MM-DDTHH:mm");
}

export function fDateShortTime(dateTime, format = "yyyy-MM-DDTHH:mm") {
  return moment(dateTime).format(format);
}

export function fTimeFormat(val) {
  return moment(val).format("yyyy-MM-ddTHH:'mm:ss");
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + " " + sizes[i]
  );
}

export function fTimeDifference(startTime, endTime) {
  const start = moment(startTime).format("YYYY-MM-DD HH:mm");
  const end = moment(moment(endTime).format("YYYY-MM-DD HH:mm"));
  const diff = end.diff(start);

  return moment.utc(diff).format("HH:mm");
}

export function fTimeValidate(value) {
  return moment(moment(value).format("HH:mm"), "HH:mm", true).isValid();
}

export function fTimeDifferenceDate(startTime, endTime) {
  const value = fTimeDifference(startTime, endTime);
  const init = moment(startTime).format("YYYY-MM-DD");

  return moment(moment(init).add(moment.duration(value)));
}

export function fTimeAdd(startTime, time) {
  const start = moment(time).format("YYYY-MM-DD");
  const diff = fTimeDifference(start, time);
  return moment(moment(startTime).add(moment.duration(diff)));
}

export function fTimeSpan(TotalMilliseconds) {
  return moment.utc(TotalMilliseconds);
}

export function fDateTime(date) {
  return moment.utc(date).format("YYYY-MM-DDTHH:mm:ss");
}

export function fDateTime1(date) {
  return moment(date).format("YYYY-MM-DDTHH:mm:ss");
}

export function fInitTime() {
  const time = moment().toDate();
  time.setHours(0);
  time.setMinutes(0);
  time.setSeconds(0);

  return moment(time).format("YYYY-MM-DDTHH:mm:ss");
}

export function formatCurrency(currency, value, decimalPoint = 2) {
  return currency + " " + value?.toFixed(decimalPoint);
}
