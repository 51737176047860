import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Menu,
  MenuItem,
  IconButton,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { AuthContext } from "src/auth/AuthProvider";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { styled } from "@mui/material/styles";
import ArticleIcon from "@mui/icons-material/Article";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import AddDepositDialog from "../Payment/AddDepositDialog";
import RequestDepositDialog from "../Payment/RequestDepositDialog";
import ViewDepositsDialog from "../Payment/ViewDepositsDialog";

const InvoiceSummary = ({
  t,
  theme,
  CurrencySymbol,
  isEditDetails,
  subNewTotal,
  taxTotal,
  taxDetails,
  totalNewTotal,
  serviceTotal,
  productTotal,
  packageTotal,
  getValues,
  register,
  setValue,
  handleDiscountChange,
  inputWidth,
  discount,
  FieldId,
  TypeId,
  CustomerId,
  isAdd,
}) => {
  const location = useLocation();
  let { state } = location;
  const auth = useContext(AuthContext);
  const profile = auth.getProfile();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const [deposit, setDeposit] = useState(parseFloat(getValues("Deposit") || 0));
  const [balance, setBalance] = useState(
    parseFloat(totalNewTotal - getValues("Discount") - getValues("Deposit")) ||
      0
  );

  useEffect(() => {
    setDeposit(parseFloat(getValues("Deposit") || 0));
    setBalance(totalNewTotal - getValues("Discount") - getValues("Deposit"));
  }, [totalNewTotal]);

  // Generate tooltip content
  const tooltipContent = (
    <div>
      {taxDetails.map((obj, key) => (
        <Typography key={key}>
          {obj?.ItemName} - {obj?.itemPrice} * {obj?.TaxRate} ({obj?.TaxName}) ={" "}
          {obj?.TaxPrice}
        </Typography>
      ))}
    </div>
  );
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  // Generate tax details for Typography
  const typographyContent = (
    <div>
      {taxDetails.map((obj, key) => (
        <Typography key={key}>
          {obj?.ItemName} - {obj?.itemPrice} * {obj?.TaxRate} ({obj?.TaxName}) ={" "}
          {obj?.TaxPrice}
        </Typography>
      ))}
    </div>
  );
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 0,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "#010101" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      backgroundColor: theme.palette.mode === "light" ? "#f0a643" : "black",
    },
  }));

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Handle click event to open the menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle menu close event
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePayment = (value) => {
    setValue("Deposit", deposit + value);
    setBalance((prev) => prev - value);
    setDeposit((prev) => prev + value);
  };

  if (profile?.RoleId != 1) return;
  return (
    <Grid
      item
      xs={12}
      lg={12}
      sx={{ marginLeft: state?.isView && isLargeScreen ? 12 : 0 }}
    >
      {state?.isView && (
        <Box
          sx={{
            p: 2,
            right: 40,
            borderRadius: 2,
            alignItems: "center",
            color: "white",
            backgroundColor: "#f0f0f0",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
            display: "flex",
            "@media (max-width: 1320px) and (max-height: 950px)": {
              padding: "14px",
            },
          }}
        >
          <Grid
            container
            spacing={0}
            justifyContent={"space-between"}
            gap={"5px"}
          >
            <Grid
              container
              sx={{
                ml: -0.5,
                mt: 1,
                top: 700,
                alignItems: "center",
                padding: 0,
              }}
            >
              <ArticleIcon sx={{ marginRight: "5px" }} />

              <Typography
                variant="h3"
                color="black"
                fontWeight={theme.typography.fontWeightBold}
                sx={{
                  "@media (max-width: 1320px) and (max-height: 950px)": {
                    fontSize: "16px",
                  },
                }}
              >
                {t("labels.balance")}
              </Typography>

              <IconButton
                onClick={handleClick}
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  color: "black",
                  margin: "10px",
                }}
              >
                <MoreHorizIcon />
              </IconButton>

              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                  sx={{ alignItems: "center", justifyContent: "center" }}
                >
                  <RequestDepositDialog
                    FieldId={FieldId}
                    TypeId={TypeId}
                    CustomerId={CustomerId}
                    balance={balance}
                  />
                </MenuItem>
                <MenuItem
                  sx={{ alignItems: "center", justifyContent: "center" }}
                >
                  <AddDepositDialog
                    FieldId={FieldId}
                    TypeId={TypeId}
                    CustomerId={CustomerId}
                    handlePayment={handlePayment}
                    balance={balance}
                  />
                </MenuItem>
                <MenuItem
                  sx={{ alignItems: "center", justifyContent: "center" }}
                >
                  <ViewDepositsDialog
                    FieldId={FieldId}
                    TypeId={TypeId}
                    CustomerId={CustomerId}
                  />
                </MenuItem>
              </Menu>
            </Grid>
            <Grid>
              <Typography
                variant="h2"
                color="black"
                sx={{
                  fontWeight: theme.typography.fontWeightBold,
                  mr: 1,
                  mt: 0,
                  "@media (max-width: 1320px) and (max-height: 950px)": {
                    fontSize: "15px",
                  },
                }}
              >
                {balance
                  ? `${CurrencySymbol} ${parseFloat(balance).toFixed(2)}`
                  : `${CurrencySymbol} ${parseFloat(0).toFixed(2)}`}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Typography variant="h2" color="black">
                <BorderLinearProgress
                  variant="determinate"
                  value={(deposit / totalNewTotal) * 100}
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "100%",
                      lg: "100%",
                      xl: "100%",
                    },
                    maxWidth: 600,
                    margin: "0 auto",
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box
        sx={{
          //  border: "1px solid #CACACA",
          p: isAdd ? 2 : 3,
          borderRadius: 3,
          backgroundColor: "#f0f0f0",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)", // Adjust shadow parameters as needed
          display: "flex",
          marginTop: "12px",
          height: isAdd ? "350px" : "100%",
        }}
      >
        <Grid
          container
          spacing={0}
          justifyContent={"space-between"}
          gap={"5px"}
        >
          <Grid
            container
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h2"
              fontWeight={theme.typography.fontWeightBold}
              sx={{
                "@media  (max-width: 1320px) and (max-height: 950px)": {
                  fontSize: "16px", // Reduce variant by 2 when screen size is small
                },
              }}
            >
              {t("subHeadings.invoicesummary")}
            </Typography>
          </Grid>
          <Grid
            container
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h3Summary"
              // textTransform={"uppercase"}
              fontWeight={theme.typography.fontWeightLight}
              sx={{
                "@media (max-width: 1320px) and (max-height: 950px)": {
                  fontSize: "13px",
                },
              }}
            >
              {t("labels.serviceTotal")}
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: theme.typography.fontWeightLight,
                display: "inline",
                mr: 1,
                "@media (max-width: 1320px) and (max-height: 950px)": {
                  fontSize: "12px",
                },
              }}
            >
              {`${CurrencySymbol} ${
                serviceTotal
                  ?.toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0
              }`}
            </Typography>
          </Grid>
          <Grid
            container
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h3Summary"
              // textTransform={"uppercase"}
              fontWeight={theme.typography.fontWeightLight}
              sx={{
                "@media (max-width: 1320px) and (max-height: 950px)": {
                  fontSize: "13px",
                },
              }}
            >
              {t("labels.productTotal")}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontWeight: theme.typography.fontWeightLight,
                display: "inline",
                mr: 1,
                "@media (max-width: 1320px) and (max-height: 950px)": {
                  fontSize: "12px",
                },
              }}
            >
              {`${CurrencySymbol} ${
                productTotal
                  ?.toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0
              }`}
            </Typography>
          </Grid>

          <Grid
            container
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h3Summary"
              fontWeight={theme.typography.fontWeightLight}
              sx={{
                "@media (max-width: 1320px) and (max-height: 950px)": {
                  fontSize: "13px",
                },
              }}
            >
              {t("labels.packageTotal")}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontWeight: theme.typography.fontWeightLight,
                display: "inline",
                mr: 1,
                "@media (max-width: 1320px) and (max-height: 950px)": {
                  fontSize: "12px",
                },
              }}
            >
              {`${CurrencySymbol} ${
                packageTotal
                  ?.toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0.0
              }`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              marginBottom: "15px",
              marginTop: "15px",
              "@media (max-width: 1320px) and (max-height: 950px)": {
                marginBottom: "8px",
                marginTop: "8px",
              },
            }}
          >
            <Divider />
          </Grid>
          <Grid
            container
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h3"
              textTransform={"uppercase"}
              fontWeight={theme.typography.fontWeightBold}
              sx={{
                "@media (max-width: 1320px) and (max-height: 950px)": {
                  fontSize: "14px",
                },
              }}
            >
              {t("labels.subtotal")}
            </Typography>
            <Typography
              variant="h3"
              sx={{
                fontWeight: theme.typography.fontWeightBold,
                display: "inline",
                mr: 1,
              }}
            >
              {`${CurrencySymbol} ${
                subNewTotal?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                (0.0).toFixed(2)
              }`}
            </Typography>
          </Grid>

          <Grid
            container
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h3Summary"
              fontWeight={theme.typography.fontWeightMedium}
              color="#eb2f06"
              sx={{
                "@media (max-width: 1320px) and (max-height: 950px)": {
                  fontSize: "13px",
                },
              }}
            >
              {t("labels.discount")}
            </Typography>
            {isEditDetails ? (
              <TextField
                id="outlined-basic"
                variant="outlined"
                name="Discount"
                {...register("Discount")}
                placeholder={t("labels.enterDiscount")}
                sx={{
                  // width: { sm: 40, md: 100 },
                  width: inputWidth,
                  minWidth: { xs: 120, md: 100 }, //
                  "& .MuiInputBase-root": {
                    height: 30,
                    display: "flex",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      - {CurrencySymbol}
                    </InputAdornment>
                  ),
                  inputProps: {
                    style: { textAlign: "right" },
                  },
                }}
                // onChange={handleDiscountChange}
                onChange={(e) => {
                  let numericValue = e.target.value
                    .replace(/[^\d.]/g, "") // Remove any characters that are not digits or a decimal point
                    .replace(/\.(\d*)\./g, ".$1") // Allow only up to two decimal places
                    .replace(/(\.\d\d)\d+$/, "$1"); // Remove any additional decimal points;

                  const totalValueIs = parseFloat(subNewTotal);
                  numericValue = parseFloat(numericValue);

                  if (!isNaN(numericValue) && numericValue > totalValueIs) {
                    numericValue = totalValueIs - 0.01;
                  }

                  if (isNaN(numericValue)) {
                    setValue("Discount", 0.0);
                  } else {
                    setValue("Discount", parseFloat(numericValue));
                  }

                  handleDiscountChange(e);
                }}
                style={{
                  pointerEvents: isEditDetails ? "auto" : "none",
                }}
              />
            ) : (
              <Typography
                variant="h5"
                color="#eb2f06"
                sx={{
                  fontWeight: theme.typography.fontWeightMedium,
                  display: "inline",
                  mr: 1,
                }}
              >
                {isAdd
                  ? `- ${CurrencySymbol} ${discount || (0.0).toFixed(2)}`
                  : `- ${CurrencySymbol} ${
                      getValues("Discount") || (0.0).toFixed(2)
                    }`}
              </Typography>
            )}
          </Grid>

          <Grid
            container
            sx={{
              justifyContent: "space-between",
            }}
          >
            {taxDetails?.length > 0 ? (
              <Tooltip
                title={taxDetails?.map((obj, key) => {
                  return (
                    <Typography key={key}>
                      {obj?.ItemName}
                      {" - "}
                      {obj?.itemPrice}
                      {"  "}*{"  "}
                      {obj?.TaxRate}
                      {" ("}
                      {obj?.TaxName}
                      {")"}
                      {"  "}={"  "}
                      {obj?.TaxPrice.toFixed(2).replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}
                    </Typography>
                  );
                })}
                placement="right"
              >
                <Typography
                  variant="h3Summary"
                  fontWeight={theme.typography.fontWeightLight}
                  sx={{
                    cursor: taxDetails?.length > 0 ? "pointer" : "default",
                    "@media (max-width: 1320px) and (max-height: 950px)": {
                      fontSize: "13px",
                    },
                  }}
                >
                  {t("labels.tax")}
                </Typography>
              </Tooltip>
            ) : (
              <Typography variant="h4">{t("labels.tax")}</Typography>
            )}

            <Typography
              variant="h6"
              sx={{
                fontWeight: theme.typography.fontWeightLigth,
                display: "inline",
                mr: 1,
                "@media (max-width: 1320px) and (max-height: 950px)": {
                  fontSize: "13px",
                },
              }}
            >
              {`${CurrencySymbol} ${taxTotal?.toFixed(2) || (0.0).toFixed(2)}`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: "15px",
              marginBottom: "15px",
              "@media (max-width: 1320px) and (max-height: 950px)": {
                marginTop: "8px",
                marginBottom: "8px",
              },
            }}
          >
            <Divider />
          </Grid>

          <Grid
            container
            sx={{
              justifyContent: "space-between",
              marginTop: "8px",
              "@media (max-width: 1320px) and (max-height: 950px)": {
                marginTop: "4px",
              },
            }}
          >
            <Typography
              variant="h2"
              textTransform={"uppercase"}
              fontWeight={theme.typography.fontWeightBold}
            >
              {t("labels.total")}
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontWeight: theme.typography.fontWeightBold,
                display: "inline",
                mr: 1,
              }}
            >
              {isAdd
                ? `${CurrencySymbol} ${totalNewTotal
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                : `${CurrencySymbol} ${(
                    totalNewTotal - getValues("Discount") || 0.0
                  )
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
            </Typography>
          </Grid>
          {state?.isView && (
            <Grid
              container
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h3Summary"
                fontWeight={theme.typography.fontWeightMedium}
                sx={{ color: "#eb2f06" }}
              >
                {t("labels.paidAmount")}
              </Typography>
              <Typography
                variant="h3Summary"
                sx={{
                  fontWeight: theme.typography.fontWeightMedium,
                  display: "inline",
                  color: "#eb2f06",
                  mr: 1,
                }}
              >
                {`- ${CurrencySymbol} ${parseFloat(deposit || 0).toFixed(2)}`}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  );
};

export default InvoiceSummary;
