import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getAllQuestions = async (token, filters = {}) => {
  const url = new URL(`${baseUrl}/Questionnaire`);

  for (const [key, value] of Object.entries(filters)) {
    if (value !== null && value !== undefined && value !== "") {
      url.searchParams.append(key, value);
    }
  }

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getAllSurvey = async (token) => {
  const url = new URL(`${baseUrl}/Questionnaire/Survey`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getQuestionById = async (token, clientId, serviceId) => {
  const url = new URL(`${baseUrl}/Service/id?ServiceId=${serviceId}`);

  url.searchParams.append("ClientId", clientId);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postQuestion = async (token, body) => {
  const url = new URL(`${baseUrl}/Questionnaire`);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putQuestion = async (token, body) => {
  const url = new URL(`${baseUrl}/Questionnaire`);

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const deleteQuestion = async (token, id, updatedDate) => {
  const url = new URL(`${baseUrl}/Questionnaire/${id}`);

  let options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ updatedDate }),
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
