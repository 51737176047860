import { Help, Undo as UndoIcon } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllQuestions } from "src/api/QuestionApi";

const EditQuestions = ({
  token,
  questionResponses,
  editQuestionResponses,
  setEditQuestionResponses,
  setNewQuestionResponses,
  newQuestionResponses,
}) => {
  const [questionsWithAnswers, setQuestionsWithAnswers] = useState([]);

  const theme = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    setEditQuestionResponses(questionResponses);
  }, []);
  const handleDeleteResponse = (responseId) => {
    const updatedResponses = editQuestionResponses.map((response) => {
      if (response.ResponseId === responseId) {
        return { ...response, IsDelete: !response.IsDelete };
      }
      return response;
    });
    setEditQuestionResponses(updatedResponses);
  };

  const handleEditResponse = (
    answerId,
    answerText,
    questionTypeName = "other",
    questionId
  ) => {
    const updatedResponses = editQuestionResponses.map((response) => {
      if (
        response.QuestionTypeName === "WA" &&
        response.QuestionId === questionId
      ) {
        return {
          ...response,
          AnswerText: answerText,
        };
      } else if (
        response.QuestionTypeName === "DD" &&
        response.QuestionId === questionId
      ) {
        return {
          ...response,
          AnswerId: answerId.toString(),
          AnswerText: answerText,
        };
      } else if (
        response.QuestionTypeName === "RB" &&
        response.QuestionId === questionId
      ) {
        return {
          ...response,
          AnswerId: answerId.toString(),
          AnswerText: answerText,
        };
      } else if (
        response.AnswerId.indexOf(answerId.toString()) > -1 &&
        response.QuestionTypeName === "CB" &&
        response.QuestionId === questionId
      ) {
        const regex = new RegExp(`(^|,)${answerId.toString()}($|,)`, "g");
        const textRegex = new RegExp(`(^|,)${answerText}($|,)`, "g");
        return {
          ...response,
          AnswerId: response.AnswerId.replace(regex, ""),
          AnswerText: response.AnswerText.replace(textRegex, ""),
        };
      } else if (
        response.AnswerId.indexOf(answerId.toString()) === -1 &&
        response.QuestionTypeName === "CB" &&
        response.QuestionId === questionId
      ) {
        return {
          ...response,
          AnswerId: response.AnswerId + "," + answerId.toString(),
          AnswerText: response.AnswerText + "," + answerText,
        };
      } else {
        return {
          ...response,
        };
      }
    });
    setEditQuestionResponses(updatedResponses);
  };
  useEffect(() => {
    getAllQuestions(token)
      .then((data) => {
        setQuestionsWithAnswers(data.DATA);
      })
      .catch((err) => {
        toast.error("failed to fetch");
        return [];
      });
  }, []);

  const notEditableQuestions =
    editQuestionResponses?.filter(({ IsNotEditable }) => IsNotEditable) || [];

  const unansweredQuestions =
    questionsWithAnswers?.filter(
      (question) =>
        !questionResponses.some(
          (response) => response.QuestionId === question.QuestionId
        )
    ) || [];

  const answeredQuestions =
    questionsWithAnswers.filter((question) =>
      questionResponses.some(
        (response) => response.QuestionText === question.QuestionText
      )
    ) || [];

  const halfLengthansweredQuestions = Math.ceil(answeredQuestions.length / 2);
  const firstColumnResponsesAnsweredQuestions = answeredQuestions.slice(
    0,
    halfLengthansweredQuestions
  );
  const secondColumnResponsesAnsweredQuestions = answeredQuestions.slice(
    halfLengthansweredQuestions
  );

  const halfLength = Math.ceil(notEditableQuestions.length / 2);
  const firstColumnResponses = notEditableQuestions.slice(0, halfLength);
  const secondColumnResponses = notEditableQuestions.slice(halfLength);

  const halfLengthUnansweredQuestions = Math.ceil(
    unansweredQuestions?.length / 2
  );
  const firstColumnResponsesUnansweredQuestions = unansweredQuestions.slice(
    0,
    halfLengthUnansweredQuestions
  );
  const secondColumnResponsesUnansweredQuestions = unansweredQuestions.slice(
    halfLengthUnansweredQuestions
  );

  const handleResponseChange = (question, answer, id) => {
    const updatedResponses = [...newQuestionResponses];

    if (
      question.QuestionTypeName === "WA" ||
      question.QuestionTypeName === "DD" ||
      question.QuestionTypeName === "RB"
    ) {
      // if (question.QuestionTypeName === "WA") {
      //   // Clear the previous error for this question
      //   setErrors((prevErrors) => ({
      //     ...prevErrors,
      //     [question.QuestionId]: "",
      //   }));

      //   answer.length > 100
      //     ? setErrors((prevErrors) => ({
      //         ...prevErrors,
      //         [question.QuestionId]: "Limit Exceeded",
      //       }))
      //     : setErrors((prevErrors) => ({
      //         ...prevErrors,
      //         [question.QuestionId]: "",
      //       }));
      // }

      // For single-choice question types (WA, RB, DD), handle as before
      const existingResponseIndex = updatedResponses.findIndex(
        (response) => response.QuestionId === question.QuestionId
      );

      if (existingResponseIndex !== -1) {
        updatedResponses[existingResponseIndex] = {
          SurveyId: question.SurveyId,
          QuestionId: question.QuestionId,
          QuestionText: question.QuestionText,
          AnswerId: id?.toString(),
          AnswerText: answer,
        };
      } else {
        updatedResponses.push({
          SurveyId: question.SurveyId,
          QuestionId: question.QuestionId,
          QuestionText: question.QuestionText,
          AnswerId: id?.toString(),
          AnswerText: answer,
        });
      }
    } else if (question.QuestionTypeName === "CB") {
      const existingResponseIndex = updatedResponses.findIndex(
        (response) => response.QuestionId === question.QuestionId
      );

      if (existingResponseIndex !== -1) {
        const selectedAnswerIndex =
          updatedResponses[existingResponseIndex].AnswerText.indexOf(answer);

        if (selectedAnswerIndex === -1) {
          updatedResponses[existingResponseIndex].AnswerText.push(answer);

          updatedResponses[existingResponseIndex].AnswerId.push(id);
        } else if (
          updatedResponses[existingResponseIndex].AnswerText.length === 0
        ) {
          updatedResponses.splice(existingResponseIndex, 1);
        } else {
          updatedResponses[existingResponseIndex].AnswerText.splice(
            selectedAnswerIndex,
            1
          );
          updatedResponses[existingResponseIndex].AnswerId.splice(
            selectedAnswerIndex,
            1
          );
        }
      } else {
        updatedResponses.push({
          SurveyId: question.SurveyId,
          QuestionId: question.QuestionId,
          QuestionText: question.QuestionText,
          QuestionTypeName: question.QuestionTypeName,
          AnswerId: [id],
          AnswerText: [answer], // Use an array to store multiple selected answers
        });
      }
    }

    setNewQuestionResponses(updatedResponses);
  };

  const responeFn = (question) => {
    const response = editQuestionResponses.find(
      (response) => response.QuestionText === question.QuestionText
    );
    return response;
  };

  return (
    <>
      {answeredQuestions.length > 0 && (
        <Grid container spacing={3} mt={2}>
          <Grid item xs={12}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h4"
              fontWeight={theme.typography.fontWeightBold}
            >
              {"Editable Questionnaires"}
            </Typography>
          </Grid>
          {/* <Grid item xs={12} sm={12} lg={6}> */}
          {firstColumnResponsesAnsweredQuestions.map((question, index) => (
            <Grid
              container
              item
              xs={12}
              sm={12}
              lg={6}
              key={question.QuestionId}
              //
            >
              <Grid item xs={12} mt={1}>
                <Stack direction="row">
                  <Help
                    fontSize="small"
                    sx={{
                      color: theme.palette.secondary.dark,
                      // mb: "0.2rem",
                      mr: "0.5rem",
                    }}
                  />

                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h4"
                    fontWeight={theme.typography.fontWeightBold}
                    // sx={{ marginTop: 2, display: "inline-block" }}
                  >
                    {question.QuestionText}
                  </Typography>
                </Stack>
              </Grid>
              {question.QuestionTypeName === "WA" && (
                <TextField
                  size="small"
                  fullWidth
                  defaultValue={responeFn(question).AnswerText || ""}
                  // value={question.AnswerText}
                  // error={!!errors[question.QuestionId]}
                  // helperText={errors[question.QuestionId]}
                  onChange={(e) =>
                    handleEditResponse(
                      0,
                      e.target.value,
                      "WA",
                      question.QuestionId
                    )
                  }
                  sx={{ width: "100%" }}
                />
              )}
              {question.QuestionTypeName === "DD" && (
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel sx={{ paddingTop: 0.5 }}>
                    {t("labels.select")}
                  </InputLabel>
                  <Select
                    sx={{ marginTop: 0.5 }}
                    defaultValue={responeFn(question).AnswerId || ""}
                  >
                    {question.AnswersList &&
                      question.AnswersList.map((answer) => (
                        <MenuItem
                          key={answer.AnswerId}
                          value={answer.AnswerId}
                          onClick={(e) =>
                            handleEditResponse(
                              answer.AnswerId,
                              answer.AnswerText,
                              "DD",
                              question.QuestionId
                            )
                          }
                        >
                          {answer.AnswerText}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
              {question.QuestionTypeName === "CB" && (
                <FormControl component="fieldset">
                  {/* <FormLabel component="legend">{question.QuestionText}</FormLabel> */}
                  <RadioGroup>
                    {question.AnswersList &&
                      question.AnswersList.map((answer) => (
                        <FormControlLabel
                          onChange={(e) =>
                            handleEditResponse(
                              answer.AnswerId,
                              e.target.value,
                              "CB",
                              question.QuestionId
                            )
                          }
                          key={answer.AnswerId}
                          value={answer.AnswerText}
                          control={
                            <Checkbox
                              defaultChecked={responeFn(
                                question
                              ).AnswerIdsList.includes(answer.AnswerId)}
                            />
                          }
                          label={answer.AnswerText}
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              )}
              {question.QuestionTypeName === "RB" && (
                <FormControl component="fieldset">
                  <RadioGroup
                    // value={question.AnswerText}
                    defaultValue={responeFn(question).AnswerId || ""}
                  >
                    {question.AnswersList &&
                      question.AnswersList.map((answer) => (
                        <FormControlLabel
                          onChange={(e) =>
                            handleEditResponse(
                              e.target.value,
                              answer.AnswerText,
                              "RB",
                              question.QuestionId
                            )
                          }
                          key={answer.AnswerId}
                          value={answer.AnswerId}
                          control={<Radio />}
                          label={answer.AnswerText}
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              )}
            </Grid>
          ))}
          {/* </Grid> */}
          {/* <Grid item xs={12} sm={12} lg={6}> */}
          {secondColumnResponsesAnsweredQuestions.map((question, index) => (
            <Grid
              container
              item
              xs={12}
              sm={12}
              lg={6}
              key={question.QuestionId}
              //
            >
              <Grid item xs={12} mt={1}>
                <Stack direction="row">
                  <Help
                    fontSize="small"
                    sx={{
                      color: theme.palette.secondary.dark,
                      mb: "0.2rem",
                      mr: "0.5rem",
                    }}
                  />

                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h4"
                    fontWeight={theme.typography.fontWeightBold}
                    // sx={{ marginTop: 2, display: "inline-block" }}
                  >
                    {question.QuestionText}
                  </Typography>
                </Stack>
              </Grid>

              {question.QuestionTypeName === "WA" && (
                <TextField
                  size="small"
                  fullWidth
                  // value={question.AnswerText}
                  defaultValue={responeFn(question).AnswerText || ""}
                  // error={!!errors[question.QuestionId]}
                  // helperText={errors[question.QuestionId]}
                  onChange={(e) =>
                    handleEditResponse(
                      0,
                      e.target.value,
                      "WA",
                      question.QuestionId
                    )
                  }
                  sx={{ width: "100%" }}
                />
              )}
              {question.QuestionTypeName === "DD" && (
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel sx={{ paddingTop: 0.5 }}>
                    {t("labels.select")}
                  </InputLabel>
                  <Select
                    sx={{ marginTop: 0.5 }}
                    defaultValue={responeFn(question).AnswerId || ""}
                  >
                    {question.AnswersList &&
                      question.AnswersList.map((answer) => (
                        <MenuItem
                          key={answer.AnswerId}
                          value={answer.AnswerId}
                          onClick={(e) =>
                            handleEditResponse(
                              answer.AnswerId,
                              answer.AnswerText,
                              "DD",
                              question.QuestionId
                            )
                          }
                        >
                          {answer.AnswerText}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
              {question.QuestionTypeName === "CB" && (
                <FormControl component="fieldset">
                  {/* <FormLabel component="legend">{question.QuestionText}</FormLabel> */}

                  <RadioGroup>
                    {question.AnswersList &&
                      question.AnswersList.map((answer) => (
                        <>
                          <FormControlLabel
                            onChange={(e) =>
                              handleEditResponse(
                                e.target.value,
                                answer.AnswerText,
                                "CB",
                                question.QuestionId
                              )
                            }
                            key={answer.AnswerId}
                            value={answer.AnswerId}
                            control={
                              <Checkbox
                                defaultChecked={responeFn(
                                  question
                                ).AnswerIdsList.includes(answer.AnswerId)}
                              />
                            }
                            label={answer.AnswerText}
                          />
                        </>
                      ))}
                  </RadioGroup>
                </FormControl>
              )}
              {question.QuestionTypeName === "RB" && (
                <FormControl component="fieldset">
                  <RadioGroup
                    // value={question.AnswerText}
                    defaultValue={responeFn(question).AnswerId || ""}
                  >
                    {question.AnswersList &&
                      question.AnswersList.map((answer) => (
                        <FormControlLabel
                          onChange={(e) =>
                            handleEditResponse(
                              e.target.value,
                              answer.AnswerText,
                              "RB",
                              question.QuestionId
                            )
                          }
                          key={answer.AnswerId}
                          value={answer.AnswerId}
                          control={<Radio />}
                          label={answer.AnswerText}
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              )}
            </Grid>
          ))}
          {/* </Grid> */}
        </Grid>
      )}
      {notEditableQuestions.length > 0 && (
        <Grid container spacing={3} mt={2}>
          <Grid item xs={12}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h4"
              fontWeight={theme.typography.fontWeightBold}
              // sx={{ marginTop: 2, display: "inline-block" }}
            >
              {"Non-editable Questionnaires"}
            </Typography>
          </Grid>
          <Grid item md={11} xs={12}>
            <Alert severity="error">
              Unable to Edit Answer: This questions has been modified or removed
              from the system.
            </Alert>
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            {firstColumnResponses.map(
              ({
                ResponseId,
                QuestionText,
                AnswerText,
                IsNotEditable,
                IsDelete,
              }) => (
                <Grid key={ResponseId} container justifyContent="space-between">
                  <Grid item xs={12} mt={1}>
                    <Stack direction="row">
                      <Help
                        fontSize="small"
                        sx={{
                          color: theme.palette.secondary.dark,
                          mb: "0.2rem",
                          mr: "0.5rem",
                        }}
                      />

                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h4"
                        fontWeight={theme.typography.fontWeightBold}
                        // sx={{ marginTop: 2, display: "inline-block" }}
                      >
                        {QuestionText}
                        {IsNotEditable && (
                          <IconButton
                            aria-label="delete"
                            color="error"
                            onClick={() => handleDeleteResponse(ResponseId)}
                          >
                            {IsDelete ? <UndoIcon /> : <DeleteIcon />}
                          </IconButton>
                        )}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} ml={3}>
                    <Typography variant="body1">{AnswerText}</Typography>
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            {secondColumnResponses.map(
              ({
                ResponseId,
                QuestionText,
                AnswerText,
                IsNotEditable,
                IsDelete,
              }) => (
                <Grid key={ResponseId} container justifyContent="space-between">
                  <Grid item xs={12} mt={1}>
                    <Stack direction="row">
                      <Help
                        fontSize="small"
                        sx={{
                          color: theme.palette.secondary.dark,
                          mb: "0.2rem",
                          mr: "0.5rem",
                        }}
                      />

                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h4"
                        fontWeight={theme.typography.fontWeightBold}
                        // sx={{ marginTop: 2, display: "inline-block" }}
                      >
                        {QuestionText}
                        {IsNotEditable && (
                          <IconButton
                            aria-label="delete"
                            color="error"
                            onClick={() => handleDeleteResponse(ResponseId)}
                          >
                            {IsDelete ? <UndoIcon /> : <DeleteIcon />}
                          </IconButton>
                        )}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} ml={3}>
                    <Typography variant="body1">{AnswerText}</Typography>
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      )}
      {unansweredQuestions.length > 0 && (
        <Grid container spacing={3} mt={2}>
          <Grid item xs={12}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h4"
              fontWeight={theme.typography.fontWeightBold}
              // sx={{ marginTop: 2, display: "inline-block" }}
            >
              {"Unanswered Questionnaires"}
            </Typography>
          </Grid>

          {/* <Grid item xs={12} sm={12} lg={6}> */}
          {firstColumnResponsesUnansweredQuestions.map((question, index) => (
            <Grid
              container
              item
              xs={12}
              sm={12}
              lg={6}
              key={question.QuestionId}
            >
              <Grid item xs={12} mt={1}>
                <Stack direction="row">
                  <Help
                    fontSize="small"
                    sx={{
                      color: theme.palette.secondary.dark,
                      mb: "0.2rem",
                      mr: "0.5rem",
                    }}
                  />

                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h4"
                    fontWeight={theme.typography.fontWeightBold}
                    // sx={{ marginTop: 2, display: "inline-block" }}
                  >
                    {question.QuestionText}
                  </Typography>
                </Stack>
              </Grid>

              {question.QuestionTypeName === "WA" && (
                <TextField
                  size="small"
                  fullWidth
                  value={question.AnswerText}
                  // error={!!errors[question.QuestionId]}
                  // helperText={errors[question.QuestionId]}
                  onChange={(e) =>
                    handleResponseChange(question, e.target.value, 0)
                  }
                  sx={{ width: "100%" }}
                />
              )}
              {question.QuestionTypeName === "DD" && (
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel sx={{ paddingTop: 0.5 }}>
                    {t("labels.select")}
                  </InputLabel>
                  <Select sx={{ marginTop: 0.5 }} value={question.AnswerText}>
                    {question.AnswersList &&
                      question.AnswersList.map((answer) => (
                        <MenuItem
                          key={answer.AnswerId}
                          value={answer.AnswerText}
                          onClick={(e) =>
                            handleResponseChange(
                              question,
                              answer.AnswerText,
                              answer.AnswerId
                            )
                          }
                        >
                          {answer.AnswerText}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
              {question.QuestionTypeName === "CB" && (
                <FormControl component="fieldset">
                  {/* <FormLabel component="legend">{question.QuestionText}</FormLabel> */}
                  <RadioGroup value={question.AnswerText}>
                    {question.AnswersList &&
                      question.AnswersList.map((answer) => (
                        <FormControlLabel
                          onChange={(e) =>
                            handleResponseChange(
                              question,
                              e.target.value,
                              answer.AnswerId
                            )
                          }
                          key={answer.AnswerId}
                          value={answer.AnswerText}
                          control={<Checkbox />}
                          label={answer.AnswerText}
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              )}
              {question.QuestionTypeName === "RB" && (
                <FormControl component="fieldset">
                  <RadioGroup value={question.AnswerText}>
                    {question.AnswersList &&
                      question.AnswersList.map((answer) => (
                        <FormControlLabel
                          onChange={(e) =>
                            handleResponseChange(
                              question,
                              e.target.value,
                              answer.AnswerId
                            )
                          }
                          key={answer.AnswerId}
                          value={answer.AnswerText}
                          control={<Radio />}
                          label={answer.AnswerText}
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              )}
            </Grid>
          ))}
          {/* </Grid> */}
          {/* <Grid item xs={12} sm={12} lg={6}> */}
          {secondColumnResponsesUnansweredQuestions.map((question, index) => (
            <Grid
              container
              item
              xs={12}
              sm={12}
              lg={6}
              key={question.QuestionId}
            >
              <Grid item xs={12} mt={1}>
                <Stack direction="row">
                  <Help
                    fontSize="small"
                    sx={{
                      color: theme.palette.secondary.dark,
                      mb: "0.2rem",
                      mr: "0.5rem",
                    }}
                  />

                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h4"
                    fontWeight={theme.typography.fontWeightBold}
                    // sx={{ marginTop: 2, display: "inline-block" }}
                  >
                    {question.QuestionText}
                  </Typography>
                </Stack>
              </Grid>

              {question.QuestionTypeName === "WA" && (
                <TextField
                  size="small"
                  fullWidth
                  value={question.AnswerText}
                  // error={!!errors[question.QuestionId]}
                  // helperText={errors[question.QuestionId]}
                  onChange={(e) =>
                    handleResponseChange(question, e.target.value, 0)
                  }
                  sx={{ width: "100%" }}
                />
              )}
              {question.QuestionTypeName === "DD" && (
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel sx={{ paddingTop: 0.5 }}>
                    {t("labels.select")}
                  </InputLabel>
                  <Select sx={{ marginTop: 0.5 }} value={question.AnswerText}>
                    {question.AnswersList &&
                      question.AnswersList.map((answer) => (
                        <MenuItem
                          key={answer.AnswerId}
                          value={answer.AnswerText}
                          onClick={(e) =>
                            handleResponseChange(
                              question,
                              answer.AnswerText,
                              answer.AnswerId
                            )
                          }
                        >
                          {answer.AnswerText}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
              {question.QuestionTypeName === "CB" && (
                <FormControl component="fieldset">
                  {/* <FormLabel component="legend">{question.QuestionText}</FormLabel> */}
                  <RadioGroup value={question.AnswerText}>
                    {question.AnswersList &&
                      question.AnswersList.map((answer) => (
                        <FormControlLabel
                          onChange={(e) =>
                            handleResponseChange(
                              question,
                              e.target.value,
                              answer.AnswerId
                            )
                          }
                          key={answer.AnswerId}
                          value={answer.AnswerText}
                          control={<Checkbox />}
                          label={answer.AnswerText}
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              )}
              {question.QuestionTypeName === "RB" && (
                <FormControl component="fieldset">
                  <RadioGroup value={question.AnswerText}>
                    {question.AnswersList &&
                      question.AnswersList.map((answer) => (
                        <FormControlLabel
                          onChange={(e) =>
                            handleResponseChange(
                              question,
                              e.target.value,
                              answer.AnswerId
                            )
                          }
                          key={answer.AnswerId}
                          value={answer.AnswerText}
                          control={<Radio />}
                          label={answer.AnswerText}
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              )}
            </Grid>
          ))}
          {/* </Grid> */}
        </Grid>
      )}
    </>
  );
};

export default EditQuestions;
