const Permissions = {
  // Customer permissions
  ADD_CUSTOMER: 21,
  EDIT_CUSTOMER: 22,
  GET_CUSTOMER: 23,
  GET_ALL_CUSTOMERS: 24,
  DELETE_CUSTOMER: 25,

  // Request permissions
  ADD_REQUEST: 6,
  EDIT_REQUEST: 7,
  GET_REQUEST: 8,
  GET_ALL_REQUESTS: 9,
  DELETE_REQUEST: 10,

  // Quote permissions
  ADD_QUOTE: 11,
  EDIT_QUOTE: 12,
  GET_QUOTE: 13,
  GET_ALL_QUOTES: 14,
  DELETE_QUOTE: 15,

  // Job permissions
  ADD_JOB: 16,
  EDIT_JOB: 17,
  GET_JOB: 18,
  GET_ALL_JOBS: 19,
  DELETE_JOB: 20,

  // Invoice permissions
  ADD_INVOICE: 1,
  EDIT_INVOICE: 2,
  GET_INVOICE: 3,
  GET_ALL_INVOICES: 4,
  DELETE_INVOICE: 5,

  // LineItem permissions
  ADD_LINEITEM: 26,
  EDIT_LINEITEM: 27,
  GET_LINEITEM: 28,
  GET_ALL_LINEITEMS: 29,
  DELETE_LINEITEM: 30,

  // Report permissions
  GET_WORKORDER: 31,
  GET_JOBOVERTIME: 32,
  GET_SALESDETAILBYJOB: 33,
  GET_SALESDETAILBYSTATUS: 34,
  GET_PAYMENTSUMMARY: 35,
  GET_FINANCESUMMARY: 36,
  GET_QUOTECOUNT: 37,

  // Notification permissions
  ADD_NOTIFICATION_JOB: 38,
  EDIT_NOTIFICATION_JOB: 39,
  DELETE_NOTIFICATION_JOB: 40,
  ADD_NOTIFICATION_REQUEST: 41,
  EDIT_NOTIFICATION_REQUEST: 42,
  ADD_NOTIFICATION_QUOTE: 43,
  EDIT_NOTIFICATION_QUOTE: 44,
  SENT_EMAIL_QUOTE: 45,
  TIME_ENTRY: 46,

  // Inventory permissions
  CREATE_INVENTORY: 47,
  EDIT_INVENTORY: 48,
  GETALL_INVENTORY: 49,
  GET_INVENTORY: 50,
  DELETE_INVENTORY: 51,

  // Dashboard permissions
  JOBS_COUNT_TABS: 52,
  FORCAST_CHART: 53,
  SALES_CHART: 54,
  QUOTE_CONVERTION: 55,
  JOB_STATUS: 56,

  // Dispatch permissions
  DISPATCH_BOARD: 57,
  VIEW_MAP: 58,
  UNASSIGNED_JOBS_TAB: 59,
  UNASSIGNED_INSPECTION_TAB: 60,
  JOB_TABLE: 61,

  // Package permissions
  ADD_PACKAGE: 62,
  EDIT_PACKAGE: 63,
  GET_PACKAGE: 64,
  GET_ALL_PACKAGES: 65,
  DELETE_PACKAGE: 66,

  // Product permissions
  ADD_PRODUCT: 67,
  EDIT_PRODUCT: 68,
  GET_PRODUCT: 69,
  GET_ALL_PRODUCT: 70,
  DELETE_PRODUCT: 71,

  // Service permissions
  ADD_SERVICE: 72,
  EDIT_SERVICE: 73,
  GET_SERVICE: 74,
  GET_ALL_SERVICE: 75,
  DELETE_SERVICE: 76,

  //Employee permissions
  ADD_EMPLOYEE: 77,
  EDIT_EMPLOYEE: 78,
  GET_EMPLOYEE: 79,
  GET_ALL_EMPLOYEES: 80,
  DELETE_EMPLOYEE: 81,

  //
  SENT_EMAIL: 82,
  PRINT_PDF: 83,
};

Object.freeze(Permissions);

export default Permissions;
