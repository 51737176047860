import { baseUrl, handleResponse, handleError, addingParams } from "./apiUtils";

export const postLogo = async (token, body) => {
  const url = new URL(`${baseUrl}/Client/Upload/Logo`);

  let options = {
    method: "PUT",
    headers: {
      //   "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: body, //JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getLogo = async (token) => {
  const url = new URL(`${baseUrl}/Client/Logo`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putClientSettings = async (token, body) => {
  const url = new URL(`${baseUrl}/Client/Settings`);

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getCurrencies = async (token) => {
  const url = new URL(`${baseUrl}/Client/Currencies`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putLabelConfiguration = async (token, body) => {
  const url = new URL(`${baseUrl}/Client/label-configuration`);

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postLabelConfiguration = async (token, body) => {
  const url = new URL(`${baseUrl}/Client/label-configuration`);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getAllLabelConfiguration = async (token, filters = {}) => {
  const url = addingParams(filters, `${baseUrl}/Client/label-configuration`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getConfigureNumbers = async (token) => {
  const url = new URL(`${baseUrl}/Client/configure-numbers`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
export const getFeatures = async (token) => {
  const url = new URL(`${baseUrl}/Client/Features-List`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
