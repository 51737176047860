import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllCustomers, getCustomerById } from "src/api/customerApi";

export const fetchCustomers = createAsyncThunk(
  "clients/fetchCustomers",
  async ({ token }, { rejectWithValue }) => {
    return getAllCustomers(token, {})
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const fetchFilterCustomers = createAsyncThunk(
  "clients/fetchFilterCustomers",
  async ({ token, filters }, { rejectWithValue }) => {
    return getAllCustomers(token, filters)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const GetClient = createAsyncThunk(
  "client/fetchClient",
  async ({ token, customerId }, { rejectWithValue }) => {
    return getCustomerById(token, customerId)
      .then((data) => {
        return data.DATA;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

const initialState = {
  isLoading: false,
  dataRecords: [],
  getClientLoading: false,
  statusCode: null,
  errorMessage: null,
  filters: {
    SortBy: "",
    Filter: "",
    Parameter: "",
  },
  customer: {},
};

const Slice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    filtersToolBar(state, action) {
      state.filters.SortBy = action.payload.SortBy;
      state.filters.Filter = action.payload.Filter;
      state.filters.Parameter = action.payload.Parameter;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.statusCode = action.payload.STATUS_CODE;
        state.errorMessage = action.payload.ERROR_MESSAGE;
        state.dataRecords = action.payload.DATA;
        state.isLoading = false;
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.statusCode = action.payload.STATUS_CODE;
        state.errorMessage = action.payload.ERROR_MESSAGE;
        state.isLoading = false;
        throw action.payload;
      })
      .addCase(GetClient.pending, (state) => {
        state.getClientLoading = true;
      })
      .addCase(GetClient.fulfilled, (state, action) => {
        state.getClientLoading = false;
        state.customer = action.payload;
      })
      .addCase(GetClient.rejected, (state, action) => {
        state.getClientLoading = false;
        throw action.payload;
      })
      .addCase(fetchFilterCustomers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFilterCustomers.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchFilterCustomers.rejected, (state, action) => {
        state.isLoading = false;
        throw action.payload;
      });
  },
});

export default Slice.reducer;

export const { filtersToolBar } = Slice.actions;
