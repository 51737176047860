import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowBack, Help } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { alpha, styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  changeStatus,
  deleteRequest,
  getAllRequestsQuestionaire,
  getRequestById,
  putRequest,
} from "src/api/requestApi";
import { AuthContext } from "src/auth/AuthProvider";
import CustomerInfoGrid from "src/components/Common/CustomerInfoGrid";
import DeleteConfirmationDialog from "src/components/Common/DeleteConfirmationDialog";
import QuoteSkeletonLoader from "src/components/Common/Loader/QuoteSkeletonLoader";
import {
  getStatusColor,
  getStatusTextColor,
} from "src/components/Common/TabTable/StatusColor";
import DateTimePickerTheme from "src/components/Controls/DateTimePickerTheme";
import FileUpload from "src/components/DropZone/FileUpload";
import { REQUEST_DOCUMENT_ID } from "src/components/DropZone/TypeConstant_config";
import Assessment from "src/components/Request/Assessment";
import EditQuestions from "src/components/Request/EditQuestions";
import ScheduleComponentCustom from "src/components/Request/ScheduleComponentCustom";
import { changePageHeading } from "src/redux/Slices/Common";
import { useDispatch } from "src/redux/Store";
import * as yup from "yup";
import EditButton from "src/components/Common/Button/EditButton";
import SaveEditButton from "src/components/Common/Button/SaveEditButton";
import Header from "src/components/Header";
import { usePermission } from "src/context/PermissionContext";
import Permissions from "src/constants/Permissions";

const MainContainer = styled("main")(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2.2),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(5),
  },
}));

// necessary for content to be below app bar
const ToolbarContainer = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

const DeleteConfirmation = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "10px",
  padding: "40px",
  flexDirection: "column",
  alignItems: "center",
  boxShadow: "10px",
}));
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "&.MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const ViewRequest = ({
  customers,
  customer,
  address,
  props,
  taxes,
  employees,
  types,
}) => {
  const auth = useContext(AuthContext);
  const profile = auth.getProfile();
  const token = auth.getAuthToken();
  const [questionnaireResponses, setQuestionnaireResponses] = useState([]);
  const dateFormat = auth.getProfile().DateFormat;
  const timeFormat = auth.getProfile().TimeFormat;

  const { requestId } = useParams();
  const location = useLocation();
  const isNew = new URLSearchParams(location.search).get("new");

  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const requestDetails = {
    RequestId: 0,
    CustomerId: 0,
    AddressId: 0,
    RequestStatusId: 0,
    Title: "",
    Description: "",
    BookingType: 0,

    AppointmentDate: "",
    AppointmentTime: "",
    Notes: "",
    IsAssessmentRequired: false,
    IsActive: true,
    IsDelete: false,
    UpdatedDate: moment().format("YYYY-MM-DDTHH:mm"),
    AssessmentSummary: "",
    EditRequestAssessmentRequest: {
      ScheduleId: 0,
      ScheduleType: 0,
      FieldId: 0,
      Title: "string",
      Instructions: "string",
      IsScheduled: true,
      StartDate: "2023-11-15T08:08:08.381Z",
      EndDate: "2023-11-15T08:08:08.381Z",
      IsAnytime: true,
      StartTime: "2023-11-15T08:08:08.381Z",
      EndTime: "2023-11-15T08:08:08.381Z",
      IsDelete: true,
      Employees: [
        {
          EmployeeId: 0,
          ScheduleId: 0,
          IsCompleted: true,
          IsDelete: true,
        },
      ],
      TypeName: "",
    },
    EditCustomFieldValueRequest: [],
    PriorityId: null,
    QuestionResponses: [
      {
        QuestionText: "string",
        QuestionTypeName: "string",
        SurveyTypeName: "Request",
        AnswersList: [
          {
            AnswerId: 0,
            AnswerText: "string",
            QuestionId: 0,
          },
        ],
      },
    ],
  };

  const [requestData, setRequestData] = useState(requestDetails);

  const navigate = useNavigate();

  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const [showButtons, setShowButtons] = useState(false);
  const [requestLoading, setRequestLoading] = useState(true);
  const toggleButtons = () => {
    setShowButtons(!showButtons);
  };

  useEffect(() => {
    dispatch(changePageHeading(!!requestId ? "Edit Lead" : "New Lead"));
  }, [dispatch]);

  const getRequest = async () => {
    setRequestLoading(true);
    await getRequestById(token, requestId)
      .then((response) => {
        if (response?.STATUS_CODE !== "200") {
          toast.error(t("toasts.leadDetailsLoadingFailed"));
          setRequestLoading(false);
        } else {
          setRequestData(response?.DATA);
          setRequestLoading(false);
        }
      })
      .catch((ex) => {
        console.log(ex);
        setRequestLoading(false);
      });
  };

  //Employees -------------------------------------

  const [isCrossedOut, setIsCrossedOut] = useState([]);

  const toggleCrossedOut = (index) => {
    const updatedCrossedOut = [...isCrossedOut];
    updatedCrossedOut[index] = !updatedCrossedOut[index];
    setIsCrossedOut(updatedCrossedOut);
    if (isCrossedOut[index]) {
      setValue(
        `EditRequestAssessmentRequest[0].Employees[${index}].IsDelete`,
        false
      );
    } else {
      setValue(
        `EditRequestAssessmentRequest[0].Employees[${index}].IsDelete`,
        true
      );
    }
  };

  const addEmployeeRecord = () => {
    getEmployeeIds();
    let newArray = [
      ...getValues(`NewEmployees`),
      {
        EmployeeId: 0,
        ScheduleId: 0,
        IsCompleted: true,
        UpdatedBy: "",
        IsDelete: false,
      },
    ];
    setValue(`NewEmployees`, newArray);
  };

  const handleRemoveEmployee = (index) => {
    getEmployeeIds();
    setEmployeeIds((pre) =>
      pre.filter(
        (data) => data !== getValues(`NewEmployees[${index}].EmployeeId`)
      )
    );
    setValue(`NewEmployees[${index}].IsDelete`, true);
  };

  const [employeeIds, setEmployeeIds] = useState([]);

  const getEmployeeIds = () => {
    const filteredIds = !!getValues(`EditRequestAssessmentRequest[0].Employees`)
      ? getValues(`EditRequestAssessmentRequest[0].Employees`)
          .filter((obj) => obj.IsDelete === false)
          ?.map((obj) => obj.EmployeeId)
      : [];
    setEmployeeIds((pre) => [...pre, ...filteredIds]);
  };

  useEffect(() => {
    requestId > 0 && getRequest();
  }, [requestId]);

  const validationSchema = yup.object().shape({
    Description: yup
      .string()
      .max(500, t("validations.characterLimitExceeded"))
      .required(t("validations.required")),
    AssessmentSummary: yup
      .string()
      .max(500, t("validations.characterLimitExceeded")),
    Notes: yup.string().max(500, t("validations.characterLimitExceeded")),
  });

  const [shouldValidateSchema, setshouldValidateSchema] = useState(true);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    clearErrors,
    setError,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: requestData,
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (requestId > 0 && !!requestData) {
      for (const [key, value] of Object.entries(requestData)) {
        if (key === "Assessment" && value === null) {
          setValue("Assessment", [
            {
              ScheduleId: 0,
              ScheduleType: 0,
              FieldId: 0,
              Title: "string",
              Instructions: "string",
              IsScheduled: true,
              StartDate:
                requestData["AvailableFirstDate"] ||
                requestData["AvailableSecondDate"],
              EndDate: "2023-11-15T08:08:08.381Z",
              IsAnytime: true,
              StartTime:
                requestData["AvailableFirstDateStart"] ||
                requestData["AvailableSecondDateStart"],
              EndTime:
                moment(
                  requestData["AvailableFirstDateEnd"] ||
                    requestData["AvailableSecondDateEnd"]
                ).diff(
                  moment(
                    requestData["AvailableFirstDateStart"] ||
                      requestData["AvailableSecondDateStart"]
                  ),
                  "hours"
                ) >= 1
                  ? moment(
                      requestData["AvailableFirstDateStart"] ||
                        requestData["AvailableSecondDateStart"]
                    )
                      .add(1, "hour")
                      .format("YYYY-MM-DDTHH:mm")
                  : moment(
                      requestData["AvailableFirstDateEnd"] ||
                        requestData["AvailableSecondDateEnd"]
                    ).format("YYYY-MM-DDTHH:mm"),
              IsDelete: true,
              Employees: [
                // {
                //   EmployeeId: 0,
                //   ScheduleId: 0,
                //   IsCompleted: true,
                //   IsDelete: true,
                // },
              ],
              TypeName: "",
            },
          ]);
        } else if (key === "BookingType") {
          setValue("BookingType", value);
        } else {
          setValue(key, value);
        }
      }
    }
  }, [requestData, requestId]);

  /** ---------------------------------- Available Date Edit ----------------------- */

  const initialAvailableDate1 = requestData?.AvailableFirstDate;
  const initialAvailableDate2 = requestData?.AvailableSecondDate;

  const initialAvailableDate1Start = requestData?.AvailableFirstDateStart;

  const initialAvailableDate1End = requestData?.AvailableFirstDateEnd;

  const initialAvailableDate2Start = requestData?.AvailableSecondDateStart;
  const initialAvailableDate2End = requestData?.AvailableSecondDateEnd;

  /** ------------------------------------------------------------------------------- */

  watch();

  useEffect(() => {
    if (getValues("Assessment")?.[0].ScheduleId > 0) {
      return;
    } else {
      setValue("Assessment", [
        {
          ScheduleId: 0,
          ScheduleType: 0,
          FieldId: 0,
          Title: "string",
          Instructions: "string",
          IsScheduled: true,
          StartDate:
            getValues("AvailableFirstDate") || getValues("AvailableSecondDate"),
          EndDate: "2023-11-15T08:08:08.381Z",
          IsAnytime: true,
          StartTime:
            getValues("AvailableFirstDateStart") ||
            getValues("AvailableSecondDateStart"),
          EndTime:
            moment(
              getValues("AvailableFirstDateEnd") ||
                getValues("AvailableSecondDateEnd")
            ).diff(
              moment(
                getValues("AvailableFirstDateStart") ||
                  getValues("AvailableSecondDateStart")
              ),
              "hours"
            ) >= 1
              ? moment(
                  getValues("AvailableFirstDateStart") ||
                    getValues("AvailableSecondDateStart")
                )
                  .add(1, "hour")
                  .format("YYYY-MM-DDTHH:mm")
              : moment(
                  getValues("AvailableFirstDateEnd") ||
                    getValues("AvailableSecondDateEnd")
                ).format("YYYY-MM-DDTHH:mm"),
          IsDelete: true,
          Employees: [
            // {
            //   EmployeeId: 0,
            //   ScheduleId: 0,
            //   IsCompleted: true,
            //   IsDelete: true,
            // },
          ],
          TypeName: "",
        },
      ]);
    }
  }, [
    getValues("AvailableFirstDate"),
    getValues("AvailableSecondDate"),
    getValues("AvailableFirstDateStart"),
    getValues("AvailableFirstDateEnd"),
    getValues("AvailableSecondDateStart"),
    getValues("AvailableSecondDateEnd"),
  ]);
  // const [loading, setLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const questionResponses = getValues("QuestionResponses");
  const [editQuestionResponses, setEditQuestionResponses] =
    useState(questionResponses);
  const [newQuestionResponses, setNewQuestionResponses] = useState([]);

  const onSubmit = async (values) => {
    getValues("NewEmployees")?.forEach((employeeId) => {
      if (
        !getValues("EditRequestAssessmentRequest[0].Employees")?.some(
          (employee) => employee.EmployeeId === employeeId.EmployeeId
        )
      ) {
        !getValues("EditRequestAssessmentRequest[0].Employees") &&
          setValue("EditRequestAssessmentRequest[0].Employees", []);
        !employeeId.IsDelete &&
          getValues("EditRequestAssessmentRequest[0].Employees").push(
            employeeId
          );
      }
    });
    const newEmployeeIds = getValues("NewEmployees")?.map(
      (item) => item.EmployeeId
    );

    setLoading(true);
    if (requestId > 0 && !values.IsDelete) {
      if (
        getValues("StartDate") !== "" &&
        moment(getValues("StartDate")).format("YYYY-MM-DD") <
          moment().format("YYYY-MM-DD")
      ) {
        toast.error(t("toasts.startDateShouldBeCurrent/FutureDate"));
        setLoading(false);
      } else if (
        getValues("EndDate") !== "" &&
        getValues("StartDate") !== "" &&
        moment(getValues("EndDate")).format("YYYY-MM-DD") <
          moment(getValues("StartDate")).format("YYYY-MM-DD")
      ) {
        toast.error(t("toasts.endDateShouldBeFutureDateThanTheStartDate"));
        setLoading(false);
      } else if (
        getValues("EndDate") !== "" &&
        getValues("StartDate") === "" &&
        moment(getValues("EndDate")).format("YYYY-MM-DD") <
          moment().format("YYYY-MM-DD")
      ) {
        toast.error(t("toasts.endDateShouldBeFutureDateThanTheCurrentDate"));
        setLoading(false);
      } else if (
        !values?.AvailableFirstDate &&
        values?.AvailableFirstDate === "" &&
        (values?.AvailableFirstDateStart !== "" ||
          values?.AvailableFirstDateEnd !== "")
      ) {
        toast.error(t("toasts.availableDate01Required"));
        setLoading(false);
      } else if (
        ((values?.AvailableFirstDate !== initialAvailableDate1 ||
          values?.AvailableFirstDateStart !== initialAvailableDate1Start ||
          values?.AvailableFirstDateEnd !== initialAvailableDate1End) &&
          moment(values?.AvailableFirstDate).isBefore(moment(), "day")) ||
        ((values?.AvailableSecondDate !== initialAvailableDate2 ||
          values?.AvailableSecondDateStart !== initialAvailableDate2Start ||
          values?.AvailableSecondDateEnd !== initialAvailableDate2End) &&
          moment(values?.AvailableSecondDate).isBefore(moment(), "day"))
      ) {
        // Check if AvailableFirstDate is invalid
        if (
          values?.AvailableFirstDate !== initialAvailableDate1 &&
          moment(values?.AvailableFirstDate).isBefore(moment(), "day")
        ) {
          toast.error(t("toasts.availableDate1"));
        }

        // Check if AvailableSecondDate is invalid
        if (
          values?.AvailableSecondDate !== initialAvailableDate2 &&
          moment(values?.AvailableSecondDate).isBefore(moment(), "day")
        ) {
          toast.error(t("toasts.availableDate2"));
        }

        setLoading(false);
      } else if (
        values?.AvailableFirstDate !== "" &&
        values?.AvailableFirstDateStart === "" &&
        values?.AvailableFirstDateEnd === ""
      ) {
        toast.error(t("toasts.availableDate01StartAndEndTimeRequired"));
        setLoading(false);
      } else if (
        !values?.AvailableSecondDate &&
        values?.AvailableSecondDate === "" &&
        (values?.AvailableSecondDateStart !== "" ||
          values?.AvailableSecondDateEnd !== "")
      ) {
        toast.error(t("toasts.availableDate02Required"));
        setLoading(false);
      } else if (
        values?.AvailableSecondDate !== "" &&
        values?.AvailableSecondDateStart === "" &&
        values?.AvailableSecondDateEnd === ""
      ) {
        toast.error(t("toasts.availableDate02StartAndEndTimeRequired"));
        setLoading(false);
      } else if (
        values?.AvailableFirstDateStart === "" &&
        values?.AvailableFirstDateEnd !== ""
      ) {
        toast.error(t("toasts.availableDate01StartTimeRequired"));
        setLoading(false);
      } else if (
        values?.AvailableFirstDateStart !== "" &&
        values?.AvailableFirstDateEnd === ""
      ) {
        toast.error(t("toasts.availableDate01EndTimeRequired"));
        setLoading(false);
      } else if (
        values?.AvailableSecondDateStart === "" &&
        values?.AvailableSecondDateEnd !== ""
      ) {
        toast.error(t("toasts.availableDate02StartTimeRequired"));
        setLoading(false);
      } else if (
        values?.AvailableSecondDateStart !== "" &&
        values?.AvailableSecondDateEnd === ""
      ) {
        toast.error(t("toasts.availableDate02EndTimeRequired"));
        setLoading(false);
      } else if (
        moment(values?.AvailableSecondDateEnd).format("THH:mm") <
        moment(values?.AvailableSecondDateStart).format("THH:mm")
      ) {
        toast.error(t("toasts.availableDate02Range"));
        setLoading(false);
      } else if (
        moment(values?.AvailableFirstDateEnd).format("THH:mm") <
        moment(values?.AvailableFirstDateStart).format("THH:mm")
      ) {
        toast.error(t("toasts.availableDate01Range"));
        setLoading(false);
      } else if (
        values?.AvailableFirstDate !== null &&
        values?.AvailableFirstDateStart === values?.AvailableFirstDateEnd &&
        values?.AvailableFirstDateStart !== "" &&
        values?.AvailableFirstDateEnd !== ""
      ) {
        toast.error(
          t("toasts.pleaseSelectDifferentTimeForAvailableDate01StartAndEnd")
        );
        setLoading(false);
      } else if (
        values?.AvailableSecondDate !== null &&
        values?.AvailableSecondDateStart === values?.AvailableSecondDateEnd &&
        values?.AvailableSecondDateStart !== "" &&
        values?.AvailableSecondDateEnd !== "" &&
        !!values?.AvailableSecondDateStart &&
        values?.AvailableSecondDateEnd
      ) {
        toast.error(
          t("toasts.pleaseSelectDifferentTimeForAvailableDate02StartAndEnd")
        );
        setLoading(false);
      } else if (
        moment(values?.AvailableSecondDate).format("YYYY-MM-DD") ==
          moment(values?.AvailableFirstDate).format("YYYY-MM-DD") &&
        ((moment(values?.AvailableSecondDateEnd).format("THH:mm") >=
          moment(values?.AvailableFirstDateEnd).format("THH:mm") &&
          moment(values?.AvailableFirstDateStart).format("THH:mm") >=
            moment(values?.AvailableSecondDateStart).format("THH:mm")) ||
          (moment(values?.AvailableSecondDateEnd).format("THH:mm") <=
            moment(values?.AvailableFirstDateEnd).format("THH:mm") &&
            moment(values?.AvailableFirstDateStart).format("THH:mm") <=
              moment(values?.AvailableSecondDateStart).format("THH:mm"))) &&
        (!!values?.AvailableSecondDate || !!values?.AvailableFirstDate)
      ) {
        toast.error(t("toasts.chooseDifferentTimeGaps"));
        setLoading(false);
      } else if (
        ((initialAvailableDate1 !== null &&
          (values?.AvailableFirstDate !== initialAvailableDate1 ||
            values?.AvailableFirstDateStart !== initialAvailableDate1Start ||
            values?.AvailableFirstDateEnd !== initialAvailableDate1End)) ||
          (initialAvailableDate2 !== null &&
            values?.AvailableSecondDate !== initialAvailableDate2) ||
          values?.AvailableSecondDateStart !== initialAvailableDate2Start ||
          values?.AvailableSecondDateEnd !== initialAvailableDate2End) &&
        (values?.AvailableFirstDate !== initialAvailableDate1 ||
          values?.AvailableSecondDate !== initialAvailableDate2) &&
        ((values?.AvailableFirstDate !== null &&
          moment(values?.AvailableFirstDate).format("YYYY-MM-DD") <
            moment().format("YYYY-MM-DD") &&
          moment(values?.AvailableFirstDateStart).format("THH:mm") <
            moment().format("THH:mm")) ||
          (values?.AvailableSecondDate !== null &&
            moment(values?.AvailableSecondDate).format("YYYY-MM-DD") <
              moment().format("YYYY-MM-DD") &&
            moment(values?.AvailableSecondDateStart).format("THH:mm") <
              moment().format("THH:mm")))
      ) {
        toast.error(t("toasts.selectAvailableTimeAsFutureTime"));
        setLoading(false);
      } else if (
        initialAvailableDate1 !== null &&
        (values?.AvailableFirstDate !== initialAvailableDate1 ||
          values?.AvailableFirstDateStart !== initialAvailableDate1Start ||
          values?.AvailableFirstDateEnd !== initialAvailableDate1End) &&
        moment(values?.AvailableFirstDate).isBefore(moment(), "day")
      ) {
        // Notify user about attempting to update time of a past date
        toast.error(t("toasts.pastTimeCannotBeUpdated"));
        setLoading(false);
      } else if (
        initialAvailableDate2 !== null &&
        (values?.AvailableSecondDate !== initialAvailableDate2 ||
          values?.AvailableSecondDateStart !== initialAvailableDate2Start ||
          values?.AvailableSecondDateEnd !== initialAvailableDate2End) &&
        moment(values?.AvailableSecondDate).isBefore(moment(), "day")
      ) {
        // Notify user about attempting to update time of a past date
        toast.error(t("toasts.pastTimeCannotBeUpdated"));
        setLoading(false);
      } else if (!!err) {
        toast.error(t("toasts.rangeOutside"));
        setLoading(false);
      } else {
        const nRes = newQuestionResponses?.map((data, i) => {
          return data.QuestionTypeName === "CB"
            ? {
                ...data,
                AnswerText: data.AnswerText.join(", "),
                AnswerId: data.AnswerId.join(", "),
              }
            : data;
        });

        await putRequest(token, requestId, {
          ...values,
          UpdatedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
          IsAssessmentRequired: values.IsAssessmentRequired,
          RequestStatusId: parseInt(values.RequestStatusId),
          AddressId: parseInt(values.AddressId),
          AppointmentDate: values?.AppointmentDate
            ? moment(values?.AppointmentDate).format("YYYY-MM-DDTHH:mm")
            : null,
          AppointmentTime: values?.AppointmentTime
            ? moment(values?.AppointmentTime).format("YYYY-MM-DDTHH:mm")
            : null,

          AvailableFirstDateEnd:
            values?.AvailableFirstDate && values?.AvailableFirstDateEnd
              ? moment(values?.AvailableFirstDate).format("YYYY-MM-DD") +
                moment(values?.AvailableFirstDateEnd).format("THH:mm")
              : null,
          AvailableFirstDateStart:
            values?.AvailableFirstDate && values?.AvailableFirstDateStart
              ? moment(values?.AvailableFirstDate).format("YYYY-MM-DD") +
                moment(values?.AvailableFirstDateStart).format("THH:mm")
              : null,

          AvailableSecondDateStart:
            values?.AvailableSecondDate && values?.AvailableSecondDateStart
              ? moment(values?.AvailableSecondDate).format("YYYY-MM-DD") +
                moment(values?.AvailableSecondDateStart).format("THH:mm")
              : null,
          AvailableSecondDateEnd:
            values?.AvailableSecondDate && values?.AvailableSecondDateEnd
              ? moment(values?.AvailableSecondDate).format("YYYY-MM-DD") +
                moment(values?.AvailableSecondDateEnd).format("THH:mm")
              : null,

          EditRequestAssessmentRequest:
            values.IsAssessmentRequired || values?.Assessment[0]?.ScheduleId > 0
              ? {
                  ...values.Assessment[0],
                  Title: values.Title,
                  Instructions: values.Description,
                }
              : null,
          Assessment: {
            ...values.Assessment[0],
            Employees: !!values?.Assessment[0].Employees
              ? values?.Assessment[0].Employees
              : [],
          },

          EditQuestionnaireResponses: [...editQuestionResponses, ...nRes],
        })
          .then((response) => {
            if (response?.STATUS_CODE === "200") {
              toast.dismiss();
              setNewQuestionResponses([]);
              toast.success(t("toasts.leadUpdatedSuccessfully"));
              setLoading(false);
              setIsEditDetails(false);
              getRequest();
            } else {
              toast.dismiss();
              toast.error(t("toasts.failedToUpdateLead"));
              setLoading(false);
            }
          })
          .catch((e) => {
            toast.dismiss();
            toast.error(t("toasts.failedToUpdateLead"));
            setLoading(false);
          });
      }
    } else {
      // setError({});
      await putRequest(token, requestId, {
        ...values,
        IsDelete: true,
        RequestStatusId: parseInt(values.RequestStatusId),
        AddressId: parseInt(values.AddressId),
        PriorityId: values?.PriorityId,
        QuestionResponses: values?.QuestionResponses,
        EditRequestAssessmentRequest: values?.EditRequestAssessmentRequest,
      })
        .then((response) => {
          if (response?.STATUS_CODE === "200") {
            toast.dismiss();
            toast.success(t("toasts.leadDeletedSuccessfully"));
            setLoading(false);
            setNewQuestionResponses([]);
            setTimeout(() => {
              navigate("/leads");
            }, 500); // Link = "/leads";
          } else {
            navigate("/leads");
            toast.dismiss();
            toast.error(t("toasts.failedToDeleteLead"));
            setLoading(false);
          }
        })
        .catch((e) => {
          toast.dismiss();
          toast.error(t("toasts.failedToDeleteLead"));
          setLoading(false);
        });
    }
  };

  const [showQuestions, setShowQuestions] = useState(false);
  const handleCheckboxChange = () => {
    setShowQuestions(!showQuestions);
  };
  const [isEditing, setIsEditing] = useState(false);
  const [editedResponses, setEditedResponses] = useState([]);

  const setQuestionResponses = useState(questionResponses);

  // const initialQuestionResponses = getValues("QuestionResponses");
  // const [questionResponses, setQuestionResponses] = useState(
  //   initialQuestionResponses
  // );

  const [editedAnswers, setEditedAnswers] = React.useState({});
  const CurrencySymbol = auth.getProfile().CurrencySymbol;
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [QuestionAnswers, setQuestionAnswers] = useState(false);
  const [isEditDetails, setIsEditDetails] = useState(false);
  const tystatus = getValues("Type");
  const [TypeStatus, setTypeStatus] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isStatOpen, setIsStatOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    const tystatus = getValues("Type");
    if (tystatus === "Converted to Jobs" || tystatus === "Converted to Quote") {
      setIsStatOpen(true);
    }
    // setOpen(!open);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handletypeChange = () => {
    setAnchorEl(null);
    changeStatus(token, parseInt(requestId), "Cancel", "2")
      .then((response) => {
        if (response?.STATUS_CODE === "200") {
          toast.success(t("toasts.successfullyChangedTheStatus"));
          setTypeStatus("Cancelled");
          setRequestData({ ...requestData, IsConvertable: false });
        }
      })
      .catch((error) => {
        toast.error(t("toasts.failedToChangeTheStatus"));
      });
  };
  const handleEditDetails = () => {
    setShowQuestions(false);
    setIsEditDetails(!isEditDetails);

    setEditedResponses([...questionResponses]);
  };

  const [showError, setShowError] = useState(false);
  const [showSecondDateError, setShowSecondDateError] = useState(false);

  const handleCancelButtonClick = () => {
    reset(requestData);
    setShowQuestions(false);
    setIsEditDetails(false);
    toast.info(t("toasts.editCancelled"));
    setEditedResponses([]);

    clearErrors("AvailableFirstDate");
    clearErrors("AvailableSecondDate");
    setShowError(false);
    setShowSecondDateError(false);
  };

  useEffect(() => {
    if (errors.AvailableFirstDate && showError) {
      setShowError(false);
    }
  }, [errors, showError]);

  useEffect(() => {
    if (errors.AvailableSecondDate && showSecondDateError) {
      setShowSecondDateError(false);
    }
  }, [errors, showSecondDateError]);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleDeleteButtonClick = () => {
    // toast.error("Lead Deleted.");
    setshouldValidateSchema(false);
  };

  const onChangeRequestData = (e) => {
    setValue(e.target.name, e.target.value);
  };

  const handleConvertToQuote = () => {
    navigate("/quotes/new-quote", {
      state: {
        isConvert: true,
        isFromRequest: !!parseInt(requestId) ? parseInt(requestId) : 0,
      },
    });
  };

  const handleConvertToJob = () => {
    navigate("/jobs/new-job", {
      state: {
        isConvert: true,
        isFromRequest: !!parseInt(requestId) ? parseInt(requestId) : 0,
      },
    });
  };

  // ... (other state variables and functions)

  useEffect(() => {
    if (isEditDetails && showQuestions) {
      getAllRequestsQuestionaire(token, "Request")
        .then((data) => {
          setQuestions(data?.DATA);
          setQuestionAnswers(true);
        })
        .catch((error) => console.error(error));
    }
  }, [showQuestions, isEditDetails]);

  useEffect(() => {
    var arr = [];
    questions?.map((q, i) => {
      arr.push({
        SurveyId: q.SurveyId,
        QuestionId: q.QuestionId,
        QuestionText: q.QuestionText,
        AnswerId: null,
        AnswerText: null,
      });
    });

    setValue("QuestionnaireResponses", arr);
  }, [QuestionAnswers]);

  useEffect(() => {
    setTaxPercentage(
      taxes?.length > 0 ? parseFloat(taxes[0]?.TaxRate || 0) : 0
    );
  }, [taxes]);

  // useMemo to group responses for rendering, if needed
  const groupedResponses = useMemo(() => {
    // Ensure questionResponses is an array before calling reduce
    if (!Array.isArray(questionResponses)) {
      console.error("questionResponses is not an array:", questionResponses);
      return {};
    }

    return questionResponses.reduce((acc, response) => {
      const { QuestionId, AnswerText, QuestionText } = response;
      if (!acc[QuestionId]) {
        acc[QuestionId] = { questionText: QuestionText, answers: [AnswerText] };
      } else {
        acc[QuestionId].answers.push(AnswerText);
      }
      return acc;
    }, {});
  }, [questionResponses]);

  const existingMessage = getValues("Description");
  const maxLength = 500;

  const [message, setMessage] = useState(existingMessage);

  const handleTextChange = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= maxLength) {
      setMessage(inputText);
    }
  };

  useEffect(() => {
    setMessage(existingMessage);
  }, [existingMessage]);

  const remainingCharacters = maxLength - message.length;

  const existingNotes = getValues("Notes");
  const maxNoteLength = 500;

  const [note, setNote] = useState(existingNotes);

  const handleNoteChange = (event) => {
    const inputNote = event.target.value;
    if (inputNote.length <= maxNoteLength) {
      setNote(inputNote);
    }
  };

  useEffect(() => {
    setNote(existingNotes);
  }, [existingNotes]);

  const remainingNoteCharacters = maxLength - note.length;
  const isOverLimit = remainingCharacters == 0;

  const isOverLimitNote = remainingNoteCharacters == 0;
  const menuItems = [
    {
      name: "Cancel",
      label: "Status",
      // checked: printDetails.Filter,
    },
    // {
    //   name: "sortBy",
    //   label: "SortBy",
    //   checked: printDetails.sortBy,
    // },
  ];

  const getAnswerText = (question) => {
    if (question.QuestionTypeName === "CB") {
      // Return all answer texts for checkboxes
      // Modify this logic based on how you track selected answers
      return question.AnswersList?.map((ans) => ans.AnswerText);
    } else {
      // For WA, DD, RB, return the first answer text
      // You need to modify this based on your logic for tracking selected answers
      const selectedAnswer = question.AnswersList?.[0];
      return selectedAnswer ? selectedAnswer.AnswerText : "";
    }
  };

  const handleAnswerChange = (questionId, updatedAnswer) => {
    setRequestData((prevData) => {
      const updatedQuestionResponses = prevData.QuestionResponses?.map(
        (question) => {
          if (question.QuestionId === questionId) {
            return { ...question, AnswerText: updatedAnswer };
          }
          return question;
        }
      );

      return { ...prevData, QuestionResponses: updatedQuestionResponses };
    });
  };

  const renderAnswerField = (question, handleAnswerChange) => {
    const handleChange = (event, answerId) => {
      handleAnswerChange(question.QuestionId, event.target.value, answerId);
    };
    switch (question.QuestionTypeName) {
      case "WA": // Written Answer
        return (
          <TextField
            fullWidth
            defaultValue={question.AnswerText}
            onChange={(e) => handleChange(e)}
          />
        );

      case "DD": // Dropdown
        return (
          <FormControl fullWidth>
            <InputLabel id={`label-${question.QuestionId}`}>Select</InputLabel>
            <Select
              labelId={`label-${question.QuestionId}`}
              value={question.AnswerText}
              onChange={handleChange}
            >
              {question.AnswersList?.map((answer) => (
                <MenuItem key={answer.AnswerId} value={answer.AnswerText}>
                  {answer.AnswerText}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );

      case "CB": // Checkbox
        return (
          <FormGroup>
            {question.AnswersList?.map((answer) => (
              <FormControlLabel
                key={answer.AnswerId}
                control={
                  <Checkbox
                    checked={question.AnswerText?.includes(answer.AnswerText)}
                    onChange={(e) =>
                      handleAnswerChange(
                        question.QuestionId,
                        answer.AnswerText,
                        e.target.checked
                      )
                    }
                  />
                }
                label={answer.AnswerText}
              />
            ))}
          </FormGroup>
        );

      case "RB": // Radio Button
        return (
          <RadioGroup
            value={question.AnswerText}
            onChange={(e) =>
              handleAnswerChange(question.QuestionId, e.target.value)
            }
          >
            {question.AnswersList?.map((answer) => (
              <FormControlLabel
                key={answer.AnswerId}
                value={answer.AnswerText}
                control={<Radio />}
                label={answer.AnswerText}
              />
            ))}
          </RadioGroup>
        );

      default:
        return null;
    }
  };

  const gridRef = useRef(null);
  const [heightVal, setHeightVal] = useState("12");

  useEffect(() => {
    if (gridRef.current && getValues("IsAssessmentRequired")) {
      const heightInPx = gridRef.current.getBoundingClientRect().height;
      const rootFontSize = parseFloat(
        getComputedStyle(document.documentElement).fontSize
      );
      const heightInRem = heightInPx / rootFontSize;
      setHeightVal(heightInRem);
    }

    if (!isEditDetails) {
      setShowQuestions(false);
    }
  }, [
    getValues("IsAssessmentRequired"),
    getValues("Assessment")?.[0],
    isEditDetails,
  ]);

  const [err, setErr] = useState("");
  useEffect(() => {
    setErr("");
    if (!getValues("IsAssessmentRequired") || !getValues("Assessment")?.[0])
      return;
    const { StartDate, StartTime, EndTime } = getValues("Assessment")?.[0];

    const selectedStartDateTime = moment(StartDate)
      .startOf("day")
      .add(moment(StartTime).format("HH:mm"));

    const selectedEndDateTime = moment(StartDate)
      .startOf("day")
      .add(moment(EndTime).format("HH:mm"));

    const availableRanges = [
      {
        start: moment(getValues("AvailableFirstDate"))
          .startOf("day")
          .add(moment(getValues("AvailableFirstDateStart")).format("HH:mm")),
        end: moment(getValues("AvailableFirstDate"))
          .startOf("day")
          .add(moment(getValues("AvailableFirstDateEnd")).format("HH:mm")),
      },
      {
        start: moment(getValues("AvailableSecondDate"))
          .startOf("day")
          .add(moment(getValues("AvailableSecondDateStart")).format("HH:mm")),
        end: moment(getValues("AvailableSecondDate"))
          .startOf("day")
          .add(moment(getValues("AvailableSecondDateEnd")).format("HH:mm")),
      },
    ];

    const isInAnyRange = availableRanges.some(
      (range) =>
        selectedStartDateTime.isBetween(range.start, range.end, null, "[]") &&
        selectedEndDateTime.isBetween(range.start, range.end, null, "[]")
    );

    if (selectedStartDateTime.isSameOrAfter(selectedEndDateTime)) {
      setErr(t("validations.startTimeMustBeBeforeEndTime"));
    } else if (!isInAnyRange) {
      setErr("Selected time range is outside the previous ranges.");
    } else {
      setErr("");
    }
  }, [
    getValues("IsAssessmentRequired"),
    getValues("Assessment")?.[0].StartDate,
    getValues("Assessment")?.[0].StartTime,
    getValues("Assessment")?.[0].EndTime,
    getValues("AvailableFirstDate"),
    getValues("AvailableSecondDate"),
    getValues("AvailableFirstDateStart"),
    getValues("AvailableFirstDateEnd"),
    getValues("AvailableSecondDateStart"),
    getValues("AvailableSecondDateEnd"),
  ]);

  /** --------------------------- Error Message --------------------------------------------- */

  const [issueDateError1, setIssueDateError1] = useState(false);

  const [issueDateError2, setIssueDateError2] = useState(false);

  /** --------------------------------------------------------------------------------------- */

  const halfLength = Math.ceil(getValues("QuestionResponses").length / 2);
  const firstColumnResponses = getValues("QuestionResponses").slice(
    0,
    halfLength
  );
  const secondColumnResponses =
    getValues("QuestionResponses").slice(halfLength);
  /***********************************************************/
  const [anchorElMenu1, setAnchorElMenu1] = useState(null);
  const handleClickMenu1 = (event) => {
    setAnchorElMenu1(event.currentTarget);
  };

  const handleCloseMenu1 = () => {
    setAnchorElMenu1(null);
  };

  const openMenu1 = Boolean(anchorElMenu1);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const editRequestPermission = usePermission(Permissions.EDIT_REQUEST);
  const createJob = usePermission(Permissions.ADD_JOB);
  const createQuote = usePermission(Permissions.ADD_QUOTE);

  const viewButton = [
    {
      label: t("buttons.convertToQuote"),
      fn: handleConvertToQuote,
      isShown: requestData.IsConvertable && createQuote,
    },

    {
      label: t("buttons.convertToJob"),
      fn: handleConvertToJob,
      isShown: requestData.IsConvertable && createJob,
    },
    {
      label: t("buttons.edit"),
      fn: handleEditDetails,
      isShown: requestData.IsEditable && editRequestPermission,
    },
  ];

  const conditionalStatus = [
    {
      isValid:
        !isStatOpen &&
        TypeStatus !== "Cancelled" &&
        getValues("Type") !== "Cancelled",
    },
  ];

  const toggleBtn = [
    {
      fn: toggleButtons,
      isShow: isDownMd && requestData.IsConvertable,
    },
  ];

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isRequestPage = false;
  const [scroll, setScroll] = useState(true);
  const scrollToBottom = () => {
    // const element = document.getElementById("scrollable-container");
    // if (element) {
    //   element.scrollIntoView({ behavior: "smooth", block: "end" });
    // }

    setScroll((val) => !val);
  };

  useEffect(() => {
    scrollToBottom(); // Call the function when the component mounts or updates
  }, []);

  const renderEditFields = () => {
    return (
      <MainContainer>
        <ToolbarContainer />

        <Grid container spacing={{ xs: 2, lg: 1 }}>
          <Header
            title={"viewLead"}
            handleClick={handleClick}
            handleClose={handleClose}
            handleCloseMenu1={handleCloseMenu1}
            handleClickMenu1={handleClickMenu1}
            handletypeChange={handletypeChange}
            TypeStatus={TypeStatus}
            getValues={getValues}
            menuItems={menuItems}
            StyledMenu={StyledMenu}
            DeleteConfirmation={DeleteConfirmation}
            isEditDetails={isEditDetails}
            toggleButtons={toggleButtons}
            showButtons={showButtons}
            disabled={Object.keys(requestData).length === 0}
            setShowButtons={setShowButtons}
            viewButton={viewButton}
            isRequestPage={true}
            isStatOpen={isStatOpen}
            open={open}
            openMenu1={openMenu1}
            anchorEl={anchorEl}
            anchorElMenu1={anchorElMenu1}
            conditionalStatus={conditionalStatus}
            toggleBtn={toggleBtn}
          />
          <Grid
            item
            xs={12}
            justifyContent={"flex-end"}
            marginRight={{ md: "20px", xs: "0px" }}
            sx={{
              position: "relative", // Adjust position based on screen size
              // right: isLargeScreen ? 20 : 0,
              top: "-80px",
              marginBottom: isLargeScreen ? 0 : 2,
            }}
          >
            <div>
              <Stack
                direction="row"
                alignItems={isLargeScreen ? "flex-end" : "center"}
                justifyContent={isLargeScreen ? "flex-end" : "center"}
                marginBottom={isLargeScreen ? 0 : 2}
                spacing={2}
              >
                {isEditDetails ? (
                  <>
                    <SaveEditButton
                      isAddPage={false}
                      loading={loading}
                      title={"Add New"}
                      onClickFunc={handleCancelButtonClick}
                      width={{ xs: "70px", sm: "100px", md: "auto" }}
                    />

                    <DeleteConfirmationDialog
                      deleteApiCall={deleteRequest}
                      id={requestId}
                      token={token}
                      width={{ xs: "70px", sm: "100px", md: "auto" }}
                    />
                  </>
                ) : (
                  requestData.IsEditable && <></>
                )}
              </Stack>
            </div>
          </Grid>

          <Grid
            container
            spacing={{ xs: 1 }}
            padding={{ xl: 3, md: 2 }}
            marginLeft={{ xs: 2, md: 0 }}
            marginTop={{ xs: 2, md: -1, lg: -3 }}
            variant="outlined"
          >
            <Grid item xs={12}>
              <CustomerInfoGrid
                getValues={getValues}
                theme={theme}
                totalNewTotal={0}
                CurrencySymbol={CurrencySymbol}
                errors={errors}
                setValue={setValue}
                isEditDetails={isEditDetails}
                Title={"Title"}
                Name={"CustomerName"}
                Phone={"PhoneNumber"}
                Email={"Email"}
                AddressLine1={"AddressLine1"}
                City={"City"}
                StateName={"StateName"}
                CountryName={"CountryName"}
                PostCode={"ZipCode"}
                Id={"RequestNumber"}
                Latitude={`Latitude`}
                Longitude={`Longitude`}
                dateFormat={dateFormat}
                types={types}
                BookingType={"BookingType"}
                register={register}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} marginTop={{ xs: -1, md: -1, lg: 2 }}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      border: "1px solid #CACACA",
                      p: 2,
                      borderRadius: 3,
                    }}
                  >
                    <Grid
                      item
                      container
                      spacing={0}
                      md={12}
                      sx={{ ml: { xs: 0, md: 2 } }}
                    >
                      <Grid item xs={12} md={6}>
                        <Grid item xs={12} md={11.5} mt={1}>
                          <Stack direction={"row"}>
                            <Typography
                              color="textSecondary"
                              gutterBottom
                              variant="h6"
                              fontWeight={theme.typography.fontWeightMedium}
                              marginBottom={"10px"}
                            >
                              {t("textFieldLables.serviceDetails")}
                            </Typography>
                          </Stack>

                          <TextField
                            name="Description"
                            {...register("Description")}
                            placeholder={t(
                              "labels.enterServiceDetailsPlaceholder"
                            )}
                            fullWidth
                            type="text"
                            variant={isEditDetails ? "outlined" : "standard"}
                            size="small"
                            multiline
                            rows={3}
                            error={!!errors.Description}
                            helperText={errors.Description?.message}
                            disabled={!isEditDetails}
                            InputProps={{
                              readOnly: !isEditDetails,
                              disableUnderline: true,
                              style: {
                                color: theme.palette.primary.contrastText,
                                fontWeight: theme.typography.fontWeightMedium,
                              },
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={11.5} mt={1}>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                            fontWeight={theme.typography.fontWeightMedium}
                          >
                            {t("textFieldLables.assessment")}
                          </Typography>

                          <TextField
                            name={"AssessmentSummary"}
                            {...register("AssessmentSummary")}
                            placeholder={
                              isEditDetails
                                ? t("labels.enterAssessmentSummary")
                                : t("labels.assessmentDetailsNotProvided")
                            }
                            fullWidth
                            type="text"
                            variant={isEditDetails ? "outlined" : "standard"}
                            size="small"
                            multiline
                            rows={3}
                            error={!!errors?.AssessmentSummary}
                            helperText={errors?.AssessmentSummary?.message}
                            disabled={!isEditDetails}
                            InputProps={{
                              // endAdornment: (
                              //   <div style={{ display: "flex" }}>
                              //     {getRunningDots()}
                              //   </div>
                              // ),

                              readOnly: !isEditDetails,
                              disableUnderline: true,
                              style: {
                                color: theme.palette.primary.contrastText,
                                fontWeight: theme.typography.fontWeightMedium,
                              },
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={11.5} mt={1}>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                            fontWeight={theme.typography.fontWeightMedium}
                            sx={{ display: "inline-block" }}
                          >
                            {t("textFieldLables.notes")}
                          </Typography>
                          <TextField
                            name="Notes"
                            {...register("Notes")}
                            placeholder={
                              isEditDetails
                                ? t("labels.enterNotes")
                                : t("labels.notesNotProvided")
                            }
                            align="center"
                            fullWidth
                            type="text"
                            variant={isEditDetails ? "outlined" : "standard"}
                            size="small"
                            multiline
                            rows={3}
                            error={!!errors.Notes}
                            helperText={errors.Notes?.message}
                            disabled={!isEditDetails}
                            InputProps={{
                              readOnly: !isEditDetails,
                              disableUnderline: true,
                              style: {
                                color: theme.palette.primary.contrastText,
                                fontWeight: theme.typography.fontWeightMedium,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} md={6} mt={2}>
                        <Box
                          sx={{
                            border: "1px solid #CACACA",
                            p: 5,
                            borderRadius: 3,
                            marginRight: { xs: 0, md: 3 },
                          }}
                        >
                          <Grid item xs={12}>
                            <Grid item xs={12}>
                              <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="h3"
                                fontWeight={theme.typography.fontWeightBold}
                              >
                                {t("subHeadings.customerAvailability")}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} container spacing={3} mt={1}>
                              <Grid item lg={4} xs={12}>
                                <Typography
                                  color="textSecondary"
                                  gutterBottom
                                  variant="h6"
                                  fontWeight={theme.typography.fontWeightMedium}
                                >
                                  {t("textFieldLables.availableDate01")}
                                </Typography>

                                <DateTimePickerTheme>
                                  <MobileDatePicker
                                    label={t("labels.availableDate01")}
                                    inputFormat={dateFormat}
                                    onChange={(e) => {
                                      if (!e) {
                                        setValue("AvailableFirstDate", "");
                                        setValue("AvailableFirstDateStart", "");
                                        setValue("AvailableFirstDateEnd", "");
                                        setIssueDateError1(false);
                                        setShowError(false); // Hide the error when the date is cleared
                                      } else {
                                        const selectedDate = moment(e);
                                        const currentDate = moment();
                                        if (
                                          selectedDate.isSameOrAfter(
                                            currentDate,
                                            "day"
                                          )
                                        ) {
                                          setValue(
                                            "AvailableFirstDate",
                                            moment(e).format("YYYY-MM-DD")
                                          );
                                          setIssueDateError1(false);
                                          setShowError(false); // Hide the error when a valid date is selected
                                        } else {
                                          setValue(
                                            "AvailableFirstDate",
                                            moment(e).format("YYYY-MM-DD")
                                          );
                                          setIssueDateError1(true);
                                          setShowError(true); // Show the error when an invalid date is selected
                                        }
                                      }
                                    }}
                                    value={
                                      getValues("AvailableFirstDate") || ""
                                    }
                                    componentsProps={{
                                      actionBar: {
                                        actions: ["clear", "cancel", "accept"],
                                      },
                                    }}
                                    minDate={moment()}
                                    renderInput={(params) => (
                                      <>
                                        <TextField
                                          fullWidth
                                          size="small"
                                          {...params}
                                          name={"AvailableFirstDate"}
                                          label={t("labels.enterDate")}
                                          variant="outlined"
                                          error={showError} // Show the error based on showError state
                                          helperText={
                                            showError
                                              ? t(
                                                  "validations.invalidDatePleaseSelectFutureDate"
                                                )
                                              : errors.Assessment?.[0]
                                                  ?.StartDate?.message || ""
                                          }
                                          sx={{
                                            pointerEvents: isEditDetails
                                              ? "auto"
                                              : "none",
                                          }}
                                        />
                                      </>
                                    )}
                                  />
                                </DateTimePickerTheme>
                              </Grid>

                              <Grid item lg={4} xs={12}>
                                <Typography
                                  color="textSecondary"
                                  gutterBottom
                                  variant="h6"
                                  fontWeight={theme.typography.fontWeightMedium}
                                >
                                  {t("textFieldLables.startTime")}
                                </Typography>

                                <DateTimePickerTheme>
                                  <MobileTimePicker
                                    inputFormat={timeFormat}
                                    label={t("labels.startTime")}
                                    ampm={false}
                                    onChange={(e) => {
                                      if (!e) {
                                        setValue("AvailableFirstDateStart", "");
                                      } else {
                                        setValue(
                                          "AvailableFirstDateStart",
                                          moment(e).format("YYYY-MM-DDTHH:mm")
                                        );
                                      }
                                    }}
                                    value={
                                      getValues("AvailableFirstDateStart") || ""
                                    }
                                    componentsProps={{
                                      actionBar: {
                                        actions: ["clear", "cancel", "accept"],
                                      },
                                    }}
                                    minTime={
                                      moment(
                                        getValues("AvailableFirstDate")
                                      ).isSame(moment(), "day")
                                        ? moment()
                                        : undefined
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        size="small"
                                        {...params}
                                        name={"AvailableFirstDateStart"}
                                        label={t("labels.enterTime")}
                                        variant="outlined"
                                        error={
                                          !!errors.Assessment?.[0]?.StartTime
                                        }
                                        helperText={
                                          errors.Assessment?.[0]?.StartTime
                                            ?.message
                                        }
                                        sx={{
                                          pointerEvents: isEditDetails
                                            ? "auto"
                                            : "none",
                                        }}
                                      />
                                    )}
                                  />
                                </DateTimePickerTheme>
                              </Grid>

                              <Grid item lg={4} xs={12}>
                                <Typography
                                  color="textSecondary"
                                  gutterBottom
                                  variant="h6"
                                  fontWeight={theme.typography.fontWeightMedium}
                                >
                                  {t("textFieldLables.endTime")}
                                </Typography>

                                <DateTimePickerTheme>
                                  <MobileTimePicker
                                    inputFormat={timeFormat}
                                    label={t("labels.endTime")}
                                    ampm={false}
                                    onChange={(e) => {
                                      if (!e) {
                                        setValue("AvailableFirstDateEnd", "");
                                      } else {
                                        setValue(
                                          "AvailableFirstDateEnd",
                                          moment(e).format("YYYY-MM-DDTHH:mm")
                                        );
                                      }
                                    }}
                                    value={
                                      getValues("AvailableFirstDateEnd") || ""
                                    }
                                    componentsProps={{
                                      actionBar: {
                                        actions: ["clear", "cancel", "accept"],
                                      },
                                    }}
                                    minTime={
                                      moment(
                                        getValues("AvailableFirstDate")
                                      ).isSame(moment(), "day")
                                        ? moment(
                                            getValues(`AvailableFirstDateStart`)
                                          )
                                        : undefined
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        size="small"
                                        {...params}
                                        name={"AvailableFirstDateEnd"}
                                        label={t("labels.enterTime")}
                                        variant="outlined"
                                        error={
                                          !!errors.Assessment?.[0]?.StartTime
                                        }
                                        helperText={
                                          errors.Assessment?.[0]?.StartTime
                                            ?.message
                                        }
                                        sx={{
                                          pointerEvents: isEditDetails
                                            ? "auto"
                                            : "none",
                                        }}
                                      />
                                    )}
                                  />
                                </DateTimePickerTheme>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} container spacing={3} mt={0}>
                              <Grid item lg={4} xs={12}>
                                <Typography
                                  color="textSecondary"
                                  gutterBottom
                                  variant="h6"
                                  fontWeight={theme.typography.fontWeightMedium}
                                >
                                  {t("textFieldLables.availableDate02")}
                                </Typography>

                                <DateTimePickerTheme>
                                  <MobileDatePicker
                                    label={t("labels.availableDate02")}
                                    inputFormat={dateFormat}
                                    onChange={(e) => {
                                      if (!e) {
                                        setValue("AvailableSecondDate", "");
                                        setValue(
                                          "AvailableSecondDateStart",
                                          ""
                                        );
                                        setValue("AvailableSecondDateEnd", "");
                                        setIssueDateError2(false);
                                        setShowSecondDateError(false); // Reset showError state when date is cleared
                                      } else {
                                        const selectedDate = moment(e);
                                        const currentDate = moment();
                                        if (
                                          selectedDate.isSameOrAfter(
                                            currentDate,
                                            "day"
                                          )
                                        ) {
                                          setValue(
                                            "AvailableSecondDate",
                                            moment(e).format("YYYY-MM-DD")
                                          );
                                          setIssueDateError2(false);
                                          setShowSecondDateError(false); // Reset showError state when valid date is selected
                                        } else {
                                          setValue(
                                            "AvailableSecondDate",
                                            moment(e).format("YYYY-MM-DD")
                                          );
                                          setIssueDateError2(true);
                                          setShowSecondDateError(true); // Show the error when an invalid date is selected
                                        }
                                      }
                                    }}
                                    value={
                                      getValues("AvailableSecondDate") || ""
                                    }
                                    componentsProps={{
                                      actionBar: {
                                        actions: ["clear", "cancel", "accept"],
                                      },
                                    }}
                                    minDate={moment()}
                                    renderInput={(params) => (
                                      <>
                                        <TextField
                                          fullWidth
                                          size="small"
                                          {...params}
                                          name={"AvailableSecondDate"}
                                          label={t("labels.enterDate")}
                                          variant="outlined"
                                          error={
                                            !!errors.Assessment?.[0]?.EndDate ||
                                            showSecondDateError // Use showSecondDateError to determine error display
                                          }
                                          helperText={
                                            showSecondDateError
                                              ? t(
                                                  "validations.invalidDatePleaseSelectFutureDate"
                                                )
                                              : errors.Asessment?.[0]?.EndDate
                                                  ?.message || ""
                                          }
                                          sx={{
                                            pointerEvents: isEditDetails
                                              ? "auto"
                                              : "none",
                                          }}
                                        />
                                      </>
                                    )}
                                  />
                                </DateTimePickerTheme>
                              </Grid>
                              <Grid item lg={4} xs={12}>
                                <Typography
                                  color="textSecondary"
                                  gutterBottom
                                  variant="h6"
                                  fontWeight={theme.typography.fontWeightMedium}
                                >
                                  {t("textFieldLables.startTime")}
                                </Typography>

                                <DateTimePickerTheme>
                                  <MobileTimePicker
                                    inputFormat={timeFormat}
                                    label={t("labels.startTime")}
                                    ampm={false}
                                    onChange={(e) => {
                                      if (!e) {
                                        setValue(
                                          "AvailableSecondDateStart",
                                          ""
                                        );
                                      } else {
                                        setValue(
                                          "AvailableSecondDateStart",
                                          moment(e).format("YYYY-MM-DDTHH:mm")
                                        );
                                      }
                                    }}
                                    value={
                                      getValues("AvailableSecondDateStart") ||
                                      ""
                                    }
                                    componentsProps={{
                                      actionBar: {
                                        actions: ["clear", "cancel", "accept"],
                                      },
                                    }}
                                    minTime={
                                      moment(
                                        getValues("AvailableSecondDate")
                                      ).isSame(moment(), "day")
                                        ? moment()
                                        : undefined
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        size="small"
                                        {...params}
                                        name={"AvailableSecondDateStart"}
                                        label={t("labels.enterTime")}
                                        variant="outlined"
                                        error={
                                          !!errors.Assessment?.[1]?.EndTime
                                        }
                                        helperText={
                                          errors.Assessment?.[1]?.EndTime
                                            ?.message
                                        }
                                        sx={{
                                          pointerEvents: isEditDetails
                                            ? "auto"
                                            : "none",
                                        }}
                                      />
                                    )}
                                  />
                                </DateTimePickerTheme>
                              </Grid>
                              <Grid item lg={4} xs={12}>
                                <Typography
                                  color="textSecondary"
                                  gutterBottom
                                  variant="h6"
                                  fontWeight={theme.typography.fontWeightMedium}
                                >
                                  {t("textFieldLables.endTime")}
                                </Typography>

                                <DateTimePickerTheme>
                                  <MobileTimePicker
                                    inputFormat={timeFormat}
                                    label={t("labels.endTime")}
                                    ampm={false}
                                    onChange={(e) => {
                                      if (!e) {
                                        setValue("AvailableSecondDateEnd", "");
                                      } else {
                                        setValue(
                                          "AvailableSecondDateEnd",
                                          moment(e).format("YYYY-MM-DDTHH:mm")
                                        );
                                      }
                                    }}
                                    value={
                                      getValues("AvailableSecondDateEnd") || ""
                                    }
                                    componentsProps={{
                                      actionBar: {
                                        actions: ["clear", "cancel", "accept"],
                                      },
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        size="small"
                                        {...params}
                                        name={"AvailableSecondDateEnd"}
                                        label={t("labels.enterTime")}
                                        variant="outlined"
                                        error={
                                          !!errors.Assessment?.[1]?.EndTime
                                        }
                                        helperText={
                                          errors.Assessment?.[1]?.EndTime
                                            ?.message
                                        }
                                        sx={{
                                          pointerEvents: isEditDetails
                                            ? "auto"
                                            : "none",
                                        }}
                                      />
                                    )}
                                  />
                                </DateTimePickerTheme>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                        {isEditDetails && (
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="IsAssessmentRequired"
                                  checked={getValues("IsAssessmentRequired")}
                                  onChange={(e) => {
                                    setValue(
                                      "IsAssessmentRequired",
                                      e.target.checked
                                    );
                                    setErr("");
                                  }}
                                />
                              }
                              disabled={
                                !(
                                  getValues("AvailableFirstDate") &&
                                  getValues("AvailableFirstDateStart") &&
                                  getValues("AvailableFirstDateEnd")
                                ) &&
                                !(
                                  getValues("AvailableSecondDate") &&
                                  getValues("AvailableSecondDateStart") &&
                                  getValues("AvailableSecondDateEnd")
                                )
                              }
                              label={
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight:
                                      theme.typography.fontWeightRegular,
                                  }}
                                >
                                  {t("checkbox.assessmentRequired")}
                                </Typography>
                              }
                            />
                          </Grid>
                        )}
                      </Grid>

                      {getValues("IsAssessmentRequired") ? (
                        <>
                          <Grid
                            item
                            xs={12}
                            container
                            padding={{ xs: 1 }}
                            sx={{ display: "inline-block" }}
                            mt={2}
                          >
                            <Divider />
                          </Grid>

                          <Grid
                            item
                            md={4}
                            xs={12}
                            container
                            sx={{ display: "inline-block" }}
                            padding={{ md: 2, xs: 1 }}
                            mt={{ md: -1 }}
                          >
                            <Assessment
                              gridRef={gridRef}
                              isView={true}
                              setRequestDetails={setValue}
                              IsAssessmentRequired={getValues(
                                "IsAssessmentRequired"
                              )}
                              AvailableFirstDate={getValues(
                                "AvailableFirstDate"
                              )}
                              AvailableSecondDate={getValues(
                                "AvailableSecondDate"
                              )}
                              createRequestAssessmentRequest={
                                getValues("Assessment")?.[0]
                              }
                              errors={
                                getValues("Assessment")?.[0]?.Employees
                                  ?.length < 1
                              }
                              err={err}
                              dateFormat={dateFormat}
                              timeFormat={timeFormat}
                              AvailableFirstDateStart={getValues(
                                "AvailableFirstDateStart"
                              )}
                              AvailableSecondDateStart={getValues(
                                "AvailableSecondDateStart"
                              )}
                              AvailableFirstDateEnd={getValues(
                                "AvailableFirstDateEnd"
                              )}
                              AvailableSecondDateEnd={getValues(
                                "AvailableSecondDateEnd"
                              )}
                              setErr={setErr}
                              employeeList={employees}
                              Employees={
                                getValues("Assessment")?.[0]?.Employees
                              }
                              isViewable={!isEditDetails}
                            />
                          </Grid>
                          <Grid
                            item
                            md={8}
                            xs={12}
                            container
                            sx={{ display: "inline-block" }}
                            padding={{ md: 2, xs: 1 }}
                            mt={{ md: -1 }}
                          >
                            <ScheduleComponentCustom
                              employeesData={employees}
                              height={heightVal}
                              datePicker={{
                                startDate: moment(
                                  getValues("Assessment")?.[0]?.StartDate ||
                                    getValues("AvailableFirstDate") ||
                                    getValues("AvailableSecondDate")
                                ).format("YYYY-MM-DD"),
                                endDate: moment(
                                  getValues("Assessment")?.[0]?.StartDate ||
                                    getValues("AvailableFirstDate") ||
                                    getValues("AvailableSecondDate")
                                ).format("YYYY-MM-DD"),
                              }}
                              isEdit={isEditDetails}
                            />
                          </Grid>
                        </>
                      ) : null}

                      <Grid item xs={12} md={11.8} mt={2}>
                        <Divider />
                      </Grid>
                      {isEditDetails || halfLength > 0 ? (
                        <Grid item xs={12}>
                          <FormControlLabel
                            sx={{ margin: 0 }}
                            labelPlacement="start"
                            label={
                              <Typography
                                color="textSecondary"
                                variant="h4"
                                fontWeight={theme.typography.fontWeightBold}
                              >
                                {t("textFieldLables.showQuestions")}
                              </Typography>
                            }
                            control={
                              <Switch
                                checked={showQuestions}
                                onChange={handleCheckboxChange}
                                color="primary"
                              />
                            }
                          />
                        </Grid>
                      ) : null}
                      {isEditDetails && showQuestions ? (
                        <Grid item container>
                          <Grid
                            item
                            container
                            // spacing={{ xs: -25, lg: 2, md: 2, sm: -40 }}
                            xs={12}
                          >
                            <EditQuestions
                              token={token}
                              questionResponses={getValues("QuestionResponses")}
                              editQuestionResponses={editQuestionResponses}
                              setEditQuestionResponses={
                                setEditQuestionResponses
                              }
                              setNewQuestionResponses={setNewQuestionResponses}
                              newQuestionResponses={newQuestionResponses}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        showQuestions && (
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} lg={6}>
                              {firstColumnResponses.map(
                                ({
                                  ResponseId,
                                  QuestionText,
                                  AnswerText,
                                  IsNotEditable,
                                  IsDelete,
                                }) => (
                                  <Grid
                                    key={ResponseId}
                                    container
                                    justifyContent="space-between"
                                  >
                                    <Grid item xs={12} mt={1}>
                                      <Stack direction="row">
                                        <Help
                                          fontSize="small"
                                          sx={{
                                            color: theme.palette.secondary.dark,
                                            mb: "0.2rem",
                                            mr: "0.5rem",
                                          }}
                                        />

                                        <Typography
                                          color="textSecondary"
                                          gutterBottom
                                          variant="h4"
                                          fontWeight={
                                            theme.typography.fontWeightBold
                                          }
                                          // sx={{ marginTop: 2, display: "inline-block" }}
                                        >
                                          {QuestionText}
                                        </Typography>
                                      </Stack>
                                    </Grid>
                                    <Grid item xs={12} ml={3}>
                                      <Typography variant="body1">
                                        {AnswerText}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                )
                              )}
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                              {secondColumnResponses.map(
                                ({
                                  ResponseId,
                                  QuestionText,
                                  AnswerText,
                                  IsNotEditable,
                                  IsDelete,
                                }) => (
                                  <Grid
                                    key={ResponseId}
                                    container
                                    justifyContent="space-between"
                                  >
                                    <Grid item xs={12} mt={1}>
                                      <Stack direction="row">
                                        <Help
                                          fontSize="small"
                                          sx={{
                                            color: theme.palette.secondary.dark,
                                            mb: "0.2rem",
                                            mr: "0.5rem",
                                          }}
                                        />

                                        <Typography
                                          color="textSecondary"
                                          gutterBottom
                                          variant="h4"
                                          fontWeight={
                                            theme.typography.fontWeightBold
                                          }
                                          // sx={{ marginTop: 2, display: "inline-block" }}
                                        >
                                          {QuestionText}
                                        </Typography>
                                      </Stack>
                                    </Grid>
                                    <Grid item xs={12} ml={3}>
                                      <Typography variant="body1">
                                        {AnswerText}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                )
                              )}
                            </Grid>
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Box>
                  {!isEditDetails && (
                    <Grid item xs={12} md={6}>
                      <FileUpload
                        Directory={`Request/RequestId_${requestId}`}
                        // isFirst={isNew == "true" ? true : false}
                        FieldId={requestId}
                        FieldTypeId={REQUEST_DOCUMENT_ID}
                        FieldName="Request"
                        Title={t("headings.requestDocument")}
                        IsEditable={requestData?.IsEditable}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainContainer>
    );
  };

  return (
    <>
      {requestLoading ? (
        <MainContainer>
          <ToolbarContainer />
          <QuoteSkeletonLoader />
        </MainContainer>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          {/* {isEditDetails ? renderEditFields() : renderViewFields()} */}
          {renderEditFields()}
          <Grid
            item
            xs={12}
            justifyContent={"end"}
            marginTop={"5px"}
            marginRight={isEditDetails ? "40px" : "25px"}
            marginBottom={"30px"}
          >
            <div>
              <Stack
                direction="row"
                alignItems="flex-end"
                justifyContent="flex-end"
                spacing={2}
                sx={{
                  marginTop: {
                    xs: "2px",
                    sm: "auto",
                  },
                }}
              ></Stack>
            </div>
          </Grid>
        </form>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    customers: state.customer.dataRecords,
    taxes: state.tax.taxes,
    employees: state.employee.employees,
    types: state.type.types,
  };
};

export default connect(mapStateToProps, null)(ViewRequest);
