import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getAllStates = async (token, countryId) => {
  const url = new URL(`${baseUrl}/State?CountryId=${countryId}`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getStateById = async (token, id) => {
  const url = new URL(`${baseUrl}/State/id?id=${id}`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postState = async (token, body) => {
  const url = new URL(`${baseUrl}/State`);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const deleteState = async (token, id) => {
  const url = new URL(`${baseUrl}/State/id?id=${id}`);

  let options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
