import { Stack, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useTranslation } from "react-i18next";

const AddNewButton = (props) => {
  const theme = useTheme();
  const {
    title = "Add New",
    redirectPath = "#",
    handleClick,
    icon = <AddCircleIcon />,
    width = "10rem",
    isRecurringTable = false,
    ...rest
  } = props;
  const { t } = useTranslation();

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={2}>
      <Button
        variant="contained"
        // variant={isRecurringTable ? "outlined" : "contained"}
        component={redirectPath != "#" ? Link : Button}
        to={redirectPath}
        sx={{
          textDecoration: "none",
          borderRadius: "4px",
          width: `${width}`,
          border: isRecurringTable ? "0px" : "1px",
        }}
        size="medium"
        onClick={handleClick}
        startIcon={icon}
        {...rest}
      >
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: theme.typography.fontWeightMedium,
          }}
        >
          {title}
        </Typography>
      </Button>
    </Stack>
  );
};
export default AddNewButton;
