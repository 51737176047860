import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import moment from "moment";
import { useContext } from "react";
import { Controller } from "react-hook-form";
import { AuthContext } from "src/auth/AuthProvider";
import DateTimePickerTheme from "src/components/Controls/DateTimePickerTheme";

const CustomDateInput = ({
  label,
  placeholder,
  name,
  containerProps,
  control,
}) => {
  const theme = useTheme();
  const auth = useContext(AuthContext);
  const token = auth.getAuthToken();

  const dateFormat = auth.getProfile()?.DateFormat;

  return (
    <Grid
      item
      {...containerProps}
      sx={{
        backgroundColor: theme.palette.primary.main,
        py: 2,
        justifyContent: "space-between",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={5}
          md={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            color="#FFFFFF"
            gutterBottom
            variant="h6"
            fontWeight={theme.typography.fontWeightBold}
          >
            {label}
          </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <DateTimePickerTheme>
                <MobileDatePicker
                  placeholder={placeholder}
                  inputFormat={dateFormat}
                  onChange={(event) => {}}
                  maxDate={moment()}
                  componentsProps={{
                    actionBar: {
                      actions: ["cancel", "accept"],
                    },
                  }}
                  {...field}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      size="small"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: theme.palette.secondary.contrastText,
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      }}
                      {...params}
                      name={name}
                      placeholder={placeholder}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CalendarTodayIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </DateTimePickerTheme>
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomDateInput;
