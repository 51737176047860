import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllQuotes, getQuoteById } from "src/api/quoteApi";

export const fetchQuotes = createAsyncThunk(
  "quotes/fetchQuotes",
  async ({ token }, { rejectWithValue }) => {
    return getAllQuotes(token, {})
      .then((data) => {
        return data.DATA;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const fetchQuoteById = createAsyncThunk(
  "quotes/fetchQuoteById",
  async ({ token, quoteId }, { rejectWithValue }) => {
    return getQuoteById(token, quoteId)
      .then((data) => {
        return data.DATA;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const fetchFilterQuotes = createAsyncThunk(
  "quotes/fetchFilterQuotes",
  async ({ token, filters }, { rejectWithValue }) => {
    return getAllQuotes(token, filters)
      .then((data) => {
        return data.DATA;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

const initialState = {
  isLoading: false,
  error: null,
  quote: null,
  data: [],
  filters: {
    SortBy: "",
    Filter: "",
    Parameter: "",
    Due: "",
    Start: "",
    End: "",
  },
};

const Slice = createSlice({
  name: "quotes",
  initialState,
  reducers: {
    filtersToolBar(state, action) {
      state.filters.SortBy = action.payload.SortBy;
      state.filters.Filter = action.payload.Filter;
      state.filters.Parameter = action.payload.Parameter;
      state.filters.Due = action.payload.Due;
      state.filters.End = action.payload.End;
      state.filters.Start = action.payload.Start;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuotes.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchQuotes.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchQuotes.rejected, (state, action) => {
        state.error = action.payload.STATUS_CODE;
        state.isLoading = false;
        throw action.payload;
      })
      .addCase(fetchQuoteById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchQuoteById.fulfilled, (state, action) => {
        state.quote = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchQuoteById.rejected, (state, action) => {
        state.error = action.payload.STATUS_CODE;
        state.isLoading = false;
        throw action.payload;
      })
      .addCase(fetchFilterQuotes.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchFilterQuotes.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchFilterQuotes.rejected, (state, action) => {
        state.error = action.payload.STATUS_CODE;
        state.isLoading = false;
        throw action.payload;
      });
  },
});

export const { filtersToolBar } = Slice.actions;

export default Slice.reducer;
