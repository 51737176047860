export const REQUEST_DOCUMENT_ID = 94;
export const JOBIN_DOCUMENT_ID = 97;
export const INVOICE_DOCUMENT_ID = 96;
export const CUSTOMER_DOCUMENT_ID = 98;
export const EMPLOYEE_DOCUMENT_ID = 99;
export const JOBOUT_DOCUMENT_ID = 100;
export const QUOTE_DOCUMENT_ID = 101;
export const JOB_DOCUMENT_ID = 103;
export const VISIT_IN_DOCUMENT_ID = 106;
export const VISIT_OUT_DOCUMENT_ID = 107;
export const INVENTORY_ID = 137;
