import AddCircleIcon from "@mui/icons-material/AddCircle";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import {
  Button,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getAllJobs } from "src/api/jobApi";
import { AuthContext } from "src/auth/AuthProvider";
import AddNewButton from "src/components/Common/Button/AddNewButton";
import ColumnViewer from "src/components/Common/DataTable/ColumnViewer";
import CsvExport from "src/components/Common/TabTable/CsvExport";
import CustomToolbar from "src/components/Common/TabTable/CustomToolbar";
import DataTable from "src/components/Common/TabTable/DataTable";
import { buildAddress } from "src/components/Controls/formatUtils";
import RestrictedComponents from "src/components/Permission/RestrictedComponents";
import Permissions from "src/constants/Permissions";
import { usePermission } from "src/context/PermissionContext";
import { changePageHeading } from "src/redux/Slices/Common";
import { useDispatch } from "src/redux/Store";

const MainContainer = styled("main")(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(3),
}));

// necessary for content to be below app bar
const ToolbarContainer = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

const Job = ({ types }) => {
  const auth = useContext(AuthContext);
  const profile = auth.getProfile();
  const token = auth.getAuthToken();

  const [searchTerm, setSearchTerm] = useState("");

  const [values, setValues] = useState({});

  const [selectedOption, setSelectedOption] = useState("");

  const handleChange = (e) => {
    const {
      target: { value, values },
    } = e;

    setSelectedOption(e.target.value);

    setfilters(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split("") : value
    );

    setValues(
      // eslint-disable-next-line no-unused-expressions
      e.target.value
    );
  };

  const theme = useTheme();

  const { t } = useTranslation();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();
  const [clientId, setClientId] = useState(null);
  const [alertBody, setalertBody] = useState("");
  const [openDialog, setopenDialog] = useState(false);
  const [popupTitle, setpopupTitle] = useState("");
  const [jobForDelete, setJobForDelete] = useState(null);
  //////////////////////////
  const [jobs, setJobs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [searchParams] = useSearchParams();
  const hanldeSetValues = () => {
    const obj = {};
    for (let [key, value] of searchParams) {
      if (key != "Due") {
        obj[key] = value;
      } else {
        obj["TodayDateTime"] = moment().format("YYYY-MM-DDTHH:mm:ss");
        obj[key] = value;
      }
    }
    return obj;
  };
  const [vals, setVals] = useState(hanldeSetValues);

  const [filters, setfilters] = useState({
    Due: "",
    Status: "",
    amount: "",
    SortBy: "",
  });

  const handleClose = () => {
    setopenDialog(false);
  };
  const [selectedJobRequest, setSelectedJobRequest] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedAmountRange, setSelectedAmountRange] = useState("");

  const handleChnge = (e) => {
    setVals(({ ...values }) => {
      values[e.target.name] = e.target.value;
      return values;
    });
  };

  const handleAmountRangeChange = (range) => {
    setSelectedAmountRange(range);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleJobRequestChange = (event) => {
    setSelectedJobRequest(event.target.value);
  };

  useEffect(() => {
    dispatch(changePageHeading("Jobs"));
  }, [dispatch]);
  const handleJobDelete = (client) => {
    setJobForDelete(client);
    setalertBody(` Do you really want to delete ${client.CustomerName} ? This process cannot be
    undone`);
    setpopupTitle("Are you sure you want to delete this Job ?");

    setopenDialog(true);
  };

  const sortByOptions = [
    { label: "Job Id", TypeId: "JOBNUM", TypeName: "Job Id" },
    {
      label: t("tableHeadings.customerName"),
      TypeId: "CUSTOMER_NAME",
      TypeName: "Customer Name",
    },
    {
      label: t("tableHeadings.startDate"),
      TypeId: "START_DATE",
      TypeName: "start Date",
    },
    {
      label: t("tableHeadings.endDate"),
      TypeId: "END_DATE",
      TypeName: "end Date",
    },
    {
      label: t("tableHeadings.recentlyCreated"),
      TypeId: "RECENT",
      TypeName: "recently created",
    },
  ];

  const dueOptions = [
    { label: "All", TypeId: "All", TypeName: t("labels.all") },
    {
      label: "Last 30 Days",
      TypeId: "LAST30DAYS ",
      TypeName: t("labels.last30Days"),
    },
    {
      label: "Current Month",
      TypeId: "CURMONTH",
      TypeName: t("labels.currentMonth"),
    },
    {
      label: "Previous Month",
      TypeId: "PREMONTH",
      TypeName: t("labels.previousMonth"),
    },
    { label: "Today", TypeId: "TODAY", TypeName: t("labels.today") },
  ];

  const handleClck = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const renderColumnsChange = (val) => {
    setRenderColumns(val);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const headCells =
    profile.RoleId === 1
      ? [
          {
            name: "CustomerName",
            label: t("tableHeadings.customerName"),
            isSortable: true,
            sortName: "CUSTOMER_NAME",
            isDefault: true,
            isView: true,
            fieldRenderType: "linkName",
            secondName: "CustomerName",
            idName: "JobId",

            toLink: (row, index) => {
              return !row.IsOneOff
                ? `/jobs/recurring-job/${row.JobId}`
                : `/jobs/oneoff-job/${row.JobId}`;
            },
            toLinkState: { isView: true },
          },
          {
            name: "Title",
            isView: true,
            label: t("tableHeadings.jobTitle"),
            // label: "Job Request",
            isSortable: true,
            sortName: "TITLE",
            render: (row, index) => {
              const maxCharacters = 60;
              const trunacatedText =
                row.Title.length > maxCharacters
                  ? `${row.Title.slice(0, maxCharacters)}...`
                  : row.Title;
              return row.Title.length > maxCharacters ? (
                <Tooltip title={row.Title} placement="top">
                  <div>{trunacatedText}</div>
                </Tooltip>
              ) : (
                <div>{trunacatedText}</div>
              );
            },
          },
          {
            name: "PhoneNumber",
            label: t("tableHeadings.phoneNumber"),
            isSortable: true,
            sortName: "PHONE",
            isView: true,
          },
          {
            name: "AddressLine1",
            isView: true,
            label: t("tableHeadings.street"),
            render: (row, index) => {
              const addressMaxCharacters = 20;
              const addressTruncatedText =
                row.AddressLine1?.length > addressMaxCharacters
                  ? `${row.AddressLine1.slice(0, addressMaxCharacters)}...`
                  : row.AddressLine1;
              return (
                <div key={index}>
                  {row.AddressLine1?.length > addressMaxCharacters ? (
                    <Tooltip title={row.AddressLine1} placement="top">
                      <div>
                        {buildAddress({
                          AddressLine1: addressTruncatedText,
                        })}
                      </div>
                    </Tooltip>
                  ) : (
                    <div>
                      {buildAddress({
                        AddressLine1: addressTruncatedText,
                      })}
                    </div>
                  )}
                </div>
              );
            },
          },
          {
            name: "City",
            isView: true,
            label: t("tableHeadings.city"),
            isSortable: true,
            sortName: "CITY",
            // label: "City",
          },
          {
            name: "StateName",
            isView: true,
            label: t("tableHeadings.state"),
            isSortable: true,
            sortName: "STATE",
            // label: "State",
          },
          {
            name: "ZipCode",
            isView: true,
            label: t("tableHeadings.zipCode"),
            // label: "ZipCode",
          },
          {
            name: "CreatedDate",
            label: t("tableHeadings.createdDate"),
            isSortable: false,
            sortName: "CREATED_DATE",
            isView: false,
            fieldRenderType: "date",
          },
          {
            name: "JobType",
            label: t("tableHeadings.jobType"),
            isSortable: false,
            sortName: "JOB_TYPE",
            isView: false,
            render: (row, index) => {
              return !row.IsScheduled
                ? "Not Scheduled"
                : row.IsOneOff
                ? "One Off Job"
                : "Recurring Job";
            },
          },
          {
            name: "Events",
            label: t("tableHeadings.events"),
            isSortable: true,
            sortName: "EVENTS",
            isView: false,
          },
          {
            name: "Total",
            isView: false,
            label: t("tableHeadings.totalAmount"),
            fieldRenderType: "currency",
            isSortable: true,
            sortName: "TOTAL",
            bodyDataAlign: "right",
            headerLabelAlign: "right",
          },
          {
            name: "Total",
            isView: false,
            label: t("tableHeadings.balance"),
            fieldRenderType: "currency",
            isSortable: true,
            sortName: "BALANCE",
            bodyDataAlign: "right",
            headerLabelAlign: "right",
          },
          // {
          //   name: "Due",
          //   label: "next visit / schedule",
          //   fieldRenderType: "Due",
          // },
          // {
          //   name: "JobAssignee",
          //   label: "Job Assignee",
          // },
          // {
          //   name: "Total",
          //   label: "Total Amount",
          //   render: (row, index) => {
          //     return row.Total.toString();
          //   },
          // },
          {
            name: "Type",
            isView: true,
            label: t("tableHeadings.status"),
            fieldRenderType: "type",
          },
        ]
      : [
          {
            name: "CustomerName",
            label: t("tableHeadings.customerName"),
            isSortable: false,
            sortName: "CUSTOMER_NAME",
            isDefault: true,
            isView: true,
            fieldRenderType: "linkName",
            secondName: "CustomerName",
            idName: "JobId",

            toLink: (row, index) => {
              return !row.IsOneOff
                ? `/jobs/recurring-job/${row.JobId}`
                : `/jobs/oneoff-job/${row.JobId}`;
            },
            toLinkState: { isView: true },
          },
          {
            name: "Title",
            isView: true,
            label: t("tableHeadings.jobTitle"),
            // label: "Job Request",
            isSortable: false,
            sortName: "TITLE",
            render: (row, index) => {
              const maxCharacters = 60;
              const trunacatedText =
                row.Title.length > maxCharacters
                  ? `${row.Title.slice(0, maxCharacters)}...`
                  : row.Title;
              return row.Title.length > maxCharacters ? (
                <Tooltip title={row.Title} placement="top">
                  <div>{trunacatedText}</div>
                </Tooltip>
              ) : (
                <div>{trunacatedText}</div>
              );
            },
          },
          {
            name: "PhoneNumber",
            isView: true,
            label: t("tableHeadings.phoneNumber"),
            isSortable: false,
          },
          {
            name: "Instruction",
            isView: true,
            label: t("tableHeadings.instructions"),
            isSortable: false,
          },

          {
            name: "JobType",
            label: t("tableHeadings.jobType"),
            isSortable: false,
            sortName: "JOB_TYPE",
            isView: true,
            render: (row, index) => {
              return !row.IsScheduled
                ? "Not Scheduled"
                : row.IsOneOff
                ? "One Off Job"
                : "Recurring Job";
            },
          },
          {
            name: "Type",
            isView: true,
            label: t("tableHeadings.status"),
            fieldRenderType: "type",
          },
        ];

  const [renderColumns, setRenderColumns] = useState(
    headCells.filter((col) =>
      typeof col.isView === "boolean" ? col.isView : true
    )
  );

  const filterList = [
    {
      label: t("labels.status"),
      name: "Status",
      onChange: handleChnge,
      list: types?.filter((res) => res?.Module === "Job Status") || [],
    },

    {
      label: t("labels.due"),
      name: "Due",
      list: dueOptions,
    },
  ];

  const [printDetails, setPrintDetails] = useState({
    Due: false,
    Status: false,
  });

  const menuItems = [
    {
      name: "Status",
      label: t("labels.status"),
      checked: printDetails.Status,
    },
    {
      name: "Due",
      label: t("labels.date"),
      checked: printDetails.Due,
    },
  ];

  {
    /** ---------------------- sorting ----------------------- */
  }

  const [isArrow, setIsArrow] = useState({
    SortBy: null,
    IsAsc: null,
  });

  {
    /** ------------------------------------------------------ */
  }

  useEffect(() => {
    fetchJobs();
  }, [
    page,
    rowsPerPage,
    filters,
    vals.Status,
    vals.amount,
    vals.Due,
    vals.SortBy,
    vals.Parameter,
    selectedJobRequest,
    selectedStatus,
    selectedAmountRange,
    isArrow.SortBy,
    isArrow.IsAsc,
  ]);

  const roleId = profile.RoleId;
  const fetchJobs = async () => {
    setLoading(true);
    await getAllJobs(
      token,
      {
        Page: page + 1,
        Limit: rowsPerPage,
        TodayDateTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
        ...{
          ...vals,
          ...isArrow,
        },
      },
      roleId
    ).then((response) => {
      setJobs(response?.DATA);
    });
    setLoading(false);
  };

  const addJob = usePermission(Permissions.ADD_JOB);
  return (
    <MainContainer>
      <ToolbarContainer />

      <Grid container>
        {/* Jobs Heading */}
        <Grid item container md={5} xs={12}>
          <Typography variant="h2" align="left" gutterBottom>
            {t("headings.jobs")}
          </Typography>
        </Grid>

        {!isDownMd ? (
          <Grid
            container
            alignContent="flex-end"
            justifyContent="flex-end"
            item
            xs={12}
            mt={-9}
          >
            <Stack
              direction="row"
              alignItems="flex-end"
              justifyContent="flex-end"
              spacing={2}
            >
              <CsvExport
                renderColumns={{
                  CustomerName: t("tableHeadings.customerName"),
                  Title: t("tableHeadings.jobTitle"),
                  PhoneNumber: t("tableHeadings.phoneNumber"),
                  AddressLine1: t("tableHeadings.street"),
                  City: t("tableHeadings.city"),
                  StateName: t("tableHeadings.state"),
                  ZipCode: t("tableHeadings.zipCode"),
                  CreatedDate: t("tableHeadings.createdDate"),
                  JobType: t("tableHeadings.jobType"),
                  Events: t("tableHeadings.events"),
                  ...(roleId === 1 && {
                    Total: t("tableHeadings.totalAmount"),
                  }),
                  Type: t("tableHeadings.status"),
                }}
                filename={t("headings.jobs")}
                CsvDataFn={getAllJobs}
                token={token}
                disabled={!token || !getAllJobs || !renderColumns}
              />
              <RestrictedComponents permissions={Permissions.ADD_JOB}>
                {addJob && (
                  <AddNewButton
                    title={t("buttons.addJob")}
                    redirectPath={"/jobs/new-job"}
                    icon={<AddCircleIcon />}
                  />
                )}
              </RestrictedComponents>

              {profile?.RoleId === 1 && (
                <Button
                  variant="contained"
                  onClick={handleClck}
                  size="small"
                  sx={{ textDecoration: "none", borderRadius: "4px" }}
                  // startIcon={<VerticalSplitIcon />}
                >
                  <VerticalSplitIcon />
                </Button>
              )}
            </Stack>
          </Grid>
        ) : (
          <Grid
            container
            alignItems="center"
            spacing={2}
            justifyContent="flex-end"
          >
            <Grid item>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
              >
                <CsvExport
                  renderColumns={{
                    CustomerName: t("tableHeadings.customerName"),
                    Title: t("tableHeadings.jobTitle"),
                    PhoneNumber: t("tableHeadings.phoneNumber"),
                    AddressLine1: t("tableHeadings.street"),
                    City: t("tableHeadings.city"),
                    StateName: t("tableHeadings.state"),
                    ZipCode: t("tableHeadings.zipCode"),
                    CreatedDate: t("tableHeadings.createdDate"),
                    JobType: t("tableHeadings.jobType"),
                    Events: t("tableHeadings.events"),
                    Total: t("tableHeadings.totalAmount"),
                    Type: t("tableHeadings.status"),
                  }}
                  filename={t("headings.jobs")}
                  CsvDataFn={getAllJobs}
                  token={token}
                  disabled={!token || !getAllJobs || !renderColumns}
                  width={"100px"}
                />
                <RestrictedComponents permissions={Permissions.ADD_JOB}>
                  {addJob && (
                    <AddNewButton
                      title={t("buttons.new")}
                      redirectPath={"/jobs/new-job"}
                      icon={<AddCircleIcon />}
                      width={"100px"}
                    />
                  )}
                </RestrictedComponents>

                <Button
                  variant="contained"
                  onClick={handleClck}
                  size="small"
                  sx={{
                    textDecoration: "none",
                    borderRadius: "4px",
                    marginLeft: "30px",
                  }}
                >
                  <VerticalSplitIcon />
                </Button>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Grid>

      <ColumnViewer
        columns={headCells}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        setRenderColumns={setRenderColumns}
        page="job"
      />

      <CustomToolbar
        menuItems={menuItems}
        printDetails={printDetails}
        setPrintDetails={setPrintDetails}
        filterList={filterList}
        setfilters={setfilters}
        filters={filters}
        selectedJobRequest={selectedJobRequest}
        selectedStatus={selectedStatus}
        selectedAmountRange={selectedAmountRange}
        setSelectedStatus={setSelectedStatus}
        setSelectedJobRequest={setSelectedJobRequest}
        setSelectedAmountRange={setSelectedAmountRange}
        vals={vals}
        setVals={setVals}
        headCells={headCells}
        placeholderValue={t("labels.searchByCustomer")}
        setIsArrow={setIsArrow}
      />

      <Grid
        container
        spacing={1}
        style={{
          marginTop: theme.spacing(1),
        }}
      >
        <Grid item xs={12}>
          {/* <div style={{ overflowX: "auto", flexGrow: 1, maxWidth: 1180 }}> */}
          <DataTable
            columns={renderColumns}
            rows={jobs}
            rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
            isLoading={loading}
            tableHeading={null}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            isArrow={isArrow}
            setIsArrow={setIsArrow}
            isCommonView={true}
          />
          {/* </div> */}
        </Grid>
      </Grid>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    types: state.type.types,
  };
};

export default connect(mapStateToProps, null)(Job);
