import BadgeIcon from "@mui/icons-material/Badge";
import ClearIcon from "@mui/icons-material/Clear";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "src/auth/AuthProvider";

const EmployeesShowComponent = ({
  visitsArray = [],
  isEditDetails,
  getValues,
  register,
  watch,
  employeeList,
  setValue,
  isDownMd,
  isCrossedOut,
  setIsHovering,
  toggleCrossedOut,
  newEmployeesArray = [],
  jobLineItems,
  newLineItems,
  handleRemoveEmployee,
}) => {
  const auth = useContext(AuthContext);
  const token = auth.getAuthToken();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        border: "1px solid #CACACA",
        p: 3,
        borderRadius: 3,
        minWidth: "100%",
        maxHeight: isDownMd ? 350 : 175,
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container spacing={0}>
        <Grid item container xs={12}>
          {visitsArray?.[0]?.Employees?.length > 0
            ? visitsArray?.[0]?.Employees?.map(
                (employee, index) =>
                  !isCrossedOut[index] && (
                    <React.Fragment key={employee?.EmployeeId}>
                      <Grid item xs={6}>
                        <Stack direction="column">
                          {Math.ceil(visitsArray?.[0]?.Employees.length / 2) >=
                          index ? (
                            <Grid item xs={12}>
                              <Stack direction="row">
                                <Tooltip
                                  title={
                                    <Typography variant="body2">
                                      <LocalPhoneOutlinedIcon fontSize="medium" />
                                      {getValues(
                                        `visits.[0].Employees[${index}].Phone`
                                      )}
                                    </Typography>
                                  }
                                  placement="right"
                                >
                                  <Paper
                                    elevation={3}
                                    sx={{
                                      p: 1,
                                      borderRadius: 3,
                                      boxShadow:
                                        "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                      display: "flex",
                                      marginBottom: "10px",
                                      alignItems: "center",
                                      width: "100%",
                                      textDecoration: isCrossedOut[index]
                                        ? "line-through red"
                                        : "none",
                                    }}
                                    onMouseOver={() => setIsHovering(true)}
                                    onMouseOut={() => setIsHovering(false)}
                                  >
                                    <BadgeIcon fontSize="medium" />
                                    <Typography
                                      color="textSecondary"
                                      gutterBottom
                                      variant="h6"
                                      margin={"5px 5px 0px 10px"}
                                      fontWeight={
                                        theme.typography.fontWeightMedium
                                      }
                                    >
                                      {getValues(
                                        `visits.[0].Employees[${index}].EmployeeName`
                                      )}
                                    </Typography>
                                  </Paper>
                                </Tooltip>
                                {isEditDetails && (
                                  <>
                                    <IconButton
                                      variant="contained"
                                      sx={{
                                        "&:hover": {
                                          backgroundColor: "none",
                                        },
                                        color: theme.palette.primary.main,
                                      }}
                                      onClick={() => toggleCrossedOut(index)}
                                    >
                                      <ClearIcon />
                                    </IconButton>

                                    {isCrossedOut[index] && (
                                      <Typography
                                        color={theme.palette.common.danger}
                                        gutterBottom
                                        variant="h7"
                                        margin={"15px 5px 0px 10px"}
                                        fontWeight={
                                          theme.typography.fontWeightMedium
                                        }
                                      >
                                        {t("labels.removed")}
                                      </Typography>
                                    )}
                                  </>
                                )}
                              </Stack>
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <Stack direction="row">
                                <Tooltip
                                  title={
                                    <Typography variant="body2">
                                      <LocalPhoneOutlinedIcon fontSize="medium" />
                                      {getValues(
                                        `visits.[0].Employees[${index}].Phone`
                                      )}
                                    </Typography>
                                  }
                                  placement="right"
                                >
                                  <Paper
                                    elevation={3}
                                    sx={{
                                      p: 1,
                                      borderRadius: 3,
                                      boxShadow:
                                        "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                      display: "flex",
                                      marginBottom: "10px",
                                      alignItems: "center",
                                      width: "90%",
                                      textDecoration: isCrossedOut[index]
                                        ? "line-through red"
                                        : "none",
                                    }}
                                    onMouseOver={() => setIsHovering(true)}
                                    onMouseOut={() => setIsHovering(false)}
                                  >
                                    <BadgeIcon fontSize="medium" />
                                    <Typography
                                      color="textSecondary"
                                      gutterBottom
                                      variant="h6"
                                      margin={"5px 5px 0px 10px"}
                                      fontWeight={
                                        theme.typography.fontWeightMedium
                                      }
                                    >
                                      {getValues(
                                        `visits.[0].Employees[${index}].EmployeeName`
                                      )}
                                    </Typography>
                                  </Paper>
                                </Tooltip>

                                {isEditDetails && (
                                  <>
                                    <IconButton
                                      variant="contained"
                                      sx={{
                                        "&:hover": {
                                          backgroundColor: "none",
                                        },
                                        color: theme.palette.primary.main,
                                      }}
                                      onClick={() => toggleCrossedOut(index)}
                                    >
                                      <ClearIcon />
                                    </IconButton>

                                    {isCrossedOut[index] && (
                                      <Typography
                                        color={theme.palette.common.danger}
                                        gutterBottom
                                        variant="h7"
                                        margin={"15px 5px 0px 10px"}
                                        fontWeight={
                                          theme.typography.fontWeightMedium
                                        }
                                      >
                                        {t("labels.removed")}
                                      </Typography>
                                    )}
                                  </>
                                )}
                              </Stack>
                            </Grid>
                          )}
                        </Stack>
                      </Grid>
                    </React.Fragment>
                  )
              )
            : null}
        </Grid>
        {isEditDetails && (
          <Grid item xs={12}>
            {newEmployeesArray?.map((item, index) =>
              !item.IsDelete ? (
                <>
                  <Stack direction={"row"}>
                    <Grid item xs={10} mt={2}>
                      <FormControl fullWidth>
                        <InputLabel
                          id="demo-simple-select-required-label"
                          size="small"
                        >
                          {t("labels.employee")}
                        </InputLabel>
                        <Select
                          name={`NewEmployees[${index}].EmployeeId`}
                          {...register(`NewEmployees[${index}].EmployeeId`)}
                          label="Select Employee"
                          variant="outlined"
                          size="small"
                          onChange={(e) => {
                            setValue(
                              `NewEmployees[${index}].EmployeeId`,
                              e.target.value
                            );
                          }}
                        >
                          {watch(`visits.${0}.StartDate`) === "" ||
                          watch(`visits.${0}.StartTime`) === "" ||
                          watch(`visits.${0}.EndDate`) === "" ||
                          watch(`visits.${0}.EndTime`) === "" ? (
                            <MenuItem
                              key={"employeeEmpty-id"}
                              value={null}
                              disabled
                            >
                              {t("menuItem.jobScheduleNoRecords")}
                            </MenuItem>
                          ) : watch("IsFilterBySkillSet") &&
                            ([...jobLineItems, ...newLineItems]
                              ?.filter((obj) => parseInt(obj?.ServiceId) > 0)
                              ?.map((item) => String(item.ServiceId)) ===
                              null ||
                              [...jobLineItems, ...newLineItems]
                                ?.filter((obj) => parseInt(obj?.ServiceId) > 0)
                                ?.map((item) => String(item.ServiceId))
                                ?.length === 0) ? (
                            <MenuItem
                              key={"employeeEmpty-id"}
                              value={null}
                              disabled
                            >
                              {t("menuItem.filterJobServiceNoRecords")}
                            </MenuItem>
                          ) : employeeList === null ||
                            employeeList?.length === 0 ? (
                            <MenuItem
                              key={"employeeEmpty-id"}
                              value={null}
                              disabled
                            >
                              {t("menuItem.noRecords")}
                            </MenuItem>
                          ) : (
                            employeeList?.map((option) => (
                              <MenuItem
                                key={option.EmployeeId}
                                value={option.EmployeeId}
                                disabled={
                                  !!(
                                    newEmployeesArray?.find(
                                      (p) =>
                                        !p.IsDelete &&
                                        p.EmployeeId === option.EmployeeId
                                    ) ||
                                    visitsArray?.[0]?.Employees?.find(
                                      (p) =>
                                        !p.IsDelete &&
                                        p.EmployeeId === option.EmployeeId
                                    )
                                  )
                                }
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {option.EmployeeName}
                                <span>
                                  {` #`}
                                  {option.EmployeeId}
                                </span>
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2} mt={2}>
                      <IconButton
                        variant="contained"
                        sx={{
                          color: theme.palette.primary.main,
                        }}
                        onClick={() => handleRemoveEmployee(index)}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Grid>
                  </Stack>
                </>
              ) : null
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default EmployeesShowComponent;
