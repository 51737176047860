import { alpha } from "@mui/material";
import Bg_Light from "src/assets/light-login-background-concept.png";

//linear-gradient(107.87deg, #000000 -0.58%, #24231F 70.93%)
const primary = "#ffffff";
const black = "#08134A";
const darkBlack = "rgb(36, 40, 44)";
const background = "#F9F9F9";
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "rgba(253, 200, 69, 1)";
const warningDark = "rgba(253, 200, 69, .7)";
const danger = "#FF3D3D";
const darkBackground = "#f2f2f2";
const focusBackground = "#E9ECFF";

// border
const borderWidth = 2;
const borderColor = "rgba(0, 0, 0, 0.13)";

// spacing
const spacing = 8;

const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  // 900: '#161C24',
  900: "#000000",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};

const COMMON = {
  primary: {
    light: "#39426e",
    // main: "#08134A",
    default: "#f59b27",
    main: "#f59b27",
    dark: "#050d33",
    contrastText: "#fff",
    inheritTextColor: "#818EA1",
  },
  secondary: {
    light: "#545454",
    main: "#457CCE",
    default: "#144061",
    // dark: "#31568f",
    dark: "#8B8B8B",
    contrastText: "#fff",
    inheritTextColor: "#f59b27",
  },
  input: {
    light: "#545454",
    // main: "#457CCE",
    default: "#1D2228",
    dark: "#8B8B8B",
    contrastText: "#fff",
    inheritTextColor: "#f59b27",
  },
  shadow: {
    shadow: "#B9B7BD",
    // inheritTextColor: "magenta",
    contrastText: "orange",
  },
  transparent: {
    main: "transparent",
  },

  common: {
    black: black,
    darkBlack: darkBlack,
    danger: danger,
  },
  warning: {
    light: warningLight,
    main: warningMain,
    dark: warningDark,
    default: warningLight,
  },
  // Used to shift a color's luminance by approximately
  // two indexes within its tonal palette.
  // E.g., shift from Red 500 to Red 300 or Red 700.
  tonalOffset: 0.2,
  background: {
    default: background,
    dark: darkBackground,
    button: focusBackground,
  },
  spacing,
  dropDown: {
    light: "black",
    dark: "white",
    default: "white",
  },
  revenue: {
    increase: "#38BC18",
    decrease: "red",
    contrastText: "#808080",
  },
};

const palette = {
  default: {
    ...COMMON,
    mode: "light",
    // text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
    text: {
      primary: "#F7BE75",
      secondary: "#000",
      disabled: GREY[500],
      selectedTxtColor: "#fff",
    },
    background: { paper: "#fff", default: "#fff", neutral: GREY[200] },

    appBar: {
      backgroundColor: "white",
      background:
        "linear-gradient(89.78deg, rgba(178, 178, 178, 0.3) 0%, rgba(244, 244, 244, 0.1) 100%)",
      backdropFilter: "blur(25px)",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    },
    appBarShift: {
      backgroundColor: "black",
      backdropFilter: "blur(25px)",
      background:
        "linear-gradient(89.78deg, rgba(77, 77, 77, 0.3) 0%, rgba(24, 24, 24, 0.1) 100%)",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
    },
    searchbar: {
      backgroundColor: "transparent",
      backdropFilter: "blur(10px)",
      background:
        "linear-gradient(89.89deg, rgba(178, 178, 178, 0.3) 0%, rgba(209, 209, 209, 0.3) 100%)",
      "border-radius": "6px",
      textColor: "Black",
    },
    toolBar: {
      background:
        "linear-gradient(89.89deg, rgba(178, 178, 178, 0.3) 0%, rgba(209, 209, 209, 0.3) 100%)",
      borderRadius: "6px",
    },
    dropDown: {
      background:
        "linear-gradient(89.89deg, rgba(222, 222, 222, 0.8) 0%, rgba(244, 244, 244, 0.8) 100%)",
      color: "#181818",
    },
    background: {
      color: "#f59b27",
    },
    customToolbarTextfield: {
      color: "#F4F4F4",
      background: "#181818",
    },
    skelton: {
      rootBg:
        "linear-gradient(89.78deg, rgba(178, 178, 178, 0.3) 0%, rgba(244, 244, 244, 0.1) 100%)",
    },
  },
  light: {
    ...COMMON,
    mode: "light",
    // text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    text: {
      primary: "#F4F4F4",
      secondary: "#181818",
      disabled: GREY[500],
      selectedTxtColor: "#fff",
    },
    background: { paper: "#fff", default: "#fff", neutral: GREY[200] },
    backgroundImage: `url(${Bg_Light})`,
    appBar: {
      backgroundColor: "white",
      background:
        "linear-gradient(89.78deg, rgba(178, 178, 178, 0.3) 0%, rgba(244, 244, 244, 0.1) 100%)",
      backdropFilter: "blur(25px)",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
    },
    appBarShift: {
      backgroundColor: "black",
      backdropFilter: "blur(25px)",
      background:
        "linear-gradient(89.78deg, rgba(77, 77, 77, 0.3) 0%, rgba(24, 24, 24, 0.1) 100%)",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
    },
    searchbar: {
      backgroundColor: "transparent",
      backdropFilter: "blur(10px)",
      background:
        "linear-gradient(89.89deg, rgba(178, 178, 178, 0.3) 0%, rgba(209, 209, 209, 0.3) 100%)",
      "border-radius": "6px",
      textColor: "Black",
    },
    toolBar: {
      background:
        "linear-gradient(89.89deg, rgba(178, 178, 178, 0.3) 0%, rgba(209, 209, 209, 0.3) 100%)",
      borderRadius: "6px",
    },
    dropDown: {
      background:
        "linear-gradient(89.89deg, rgba(222, 222, 222, 0.8) 0%, rgba(244, 244, 244, 0.8) 100%)",
      color: "#181818",
    },
    background: {
      color: "#f59b27",
    },
    customToolbarTextfield: {
      color: "#F4F4F4",
      background: "#181818",
    },
    skelton: {
      rootBg:
        "linear-gradient(89.78deg, rgba(178, 178, 178, 0.3) 0%, rgba(244, 244, 244, 0.1) 100%)",
    },
  },
  dark: {
    ...COMMON,
    mode: "dark",
    // text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
    text: {
      primary: "#181818",
      secondary: "#fff",
      disabled: GREY[600],
      selectedTxtColor: GREY[800],
    },
    background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
    backgroundImage: `url(${Bg_Light})`,
    appBar: {
      backgroundColor: "black",
      background:
        "linear-gradient(89.78deg, rgba(77, 77, 77, 0.3) 0%, rgba(24, 24, 24, 0.1) 100%)",
      backdropFilter: "blur(25px)",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
    },
    appBarShift: {
      backgroundColor: "black",
      backdropFilter: "blur(25px)",
      background:
        "linear-gradient(89.78deg, rgba(77, 77, 77, 0.3) 0%, rgba(24, 24, 24, 0.1) 100%)",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
    },
    searchbar: {
      backgroundColor: "transperant",
      backdropFilter: "blur(10px)",
      background:
        "linear-gradient(89.89deg, rgba(40, 40, 40, 0.3) 0%, rgba(24, 24, 24, 0.3) 100%)",
      "border-radius": "6px",
      textColor: "#ffffff",
    },
    toolBar: {
      background:
        "linear-gradient(89.89deg, rgba(178, 178, 178, 0.3) 0%, rgba(209, 209, 209, 0.3) 100%)",
      borderRadius: "6px",
    },
    dropDown: {
      background:
        "linear-gradient(89.89deg, rgba(40, 40, 40, 0.8) 0%, rgba(24, 24, 24, 0.8) 100%)",
      color: "#F4F4F4",
    },
    background: {
      color: "#f59b27",
    },
    customToolbarTextfield: {
      color: "#181818",
      background: "#F4F4F4",
    },
    skelton: {
      rootBg:
        "linear-gradient(89.78deg, rgba(77, 77, 77, 0.3) 0%, rgba(24, 24, 24, 0.1) 100%)",
    },
  },
};

export default palette;
