import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Delete } from "@mui/icons-material";

const DeleteConfirmationDialog = ({
  deleteApiCall,
  id,
  token,
  callback,
  isTable = false,
  setDeleteLoading,
  viewDialog = false,
  isFileDelete = false,
  cancelCallBack,
  width,
}) => {
  const [open, setOpen] = useState(viewDialog);
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const UpdatedDate = isFileDelete
    ? { UpdatedDate: moment().format("YYYY-MM-DDTHH:mm:ss") }
    : moment().format("YYYY-MM-DDTHH:mm:ss");

  const handleDelete = () => {
    isTable && setDeleteLoading(false);
    setOpen(false);
    const toastId = toast.loading(t("toasts.pleaseWait"));
    deleteApiCall(token, id, UpdatedDate)
      .then((res) => {
        toast.dismiss(toastId);
        if (res?.STATUS_CODE === "200" && res?.DATA > 0) {
          toast.success(t("messages.successfullyDeleted"));
          callback && callback();
          if (!isTable) {
            navigate(-1);
          }
          isTable && setDeleteLoading(true);
        } else if (isFileDelete && !!res.DATA.FileId) {
          toast.success(t("toasts.deleteDocumentSuccess"));
          callback && callback();
        } else {
          toast.error(t("messages.failedToDelete"));
          isTable && setDeleteLoading(false);
        }
      })
      .catch((error) => {
        toast.dismiss(toastId);
        toast.error(t("messages.failedToDelete"));
        isTable && setDeleteLoading(false);
      });
  };

  return (
    <>
      {isTable && (
        <IconButton
          variant="contained"
          aria-label="delete"
          onClick={() => {
            setOpen(true);
          }}
          disabled={open}
          sx={{
            color: theme.palette.common.danger,
          }}
          size="medium"
        >
          <Delete />
        </IconButton>
      )}

      {!isFileDelete && !isTable && (
        <Button
          variant="contained"
          onClick={() => {
            setOpen(true);
          }}
          sx={{
            width: {
              xs: width ? width : "40vw",
              sm: width ? width : "40vw",
              md: "140px",
            },
            height: "40px",
            borderRadius: "4px",
            boxSizing: "border-box",
            color: "white",
            backgroundColor: "red",
            border: `1px solid red`,
            // marginRight: { sm: "50px", md: "-50px" },
            "&:hover": {
              color: "black",
              backgroundColor: "red",
            },
            "@media (max-width: 1320px) and (max-height: 950px)": {
              width: "90px",
            },
          }}
          disabled={open}
        >
          {open ? (
            <CircularProgress
              size={24}
              sx={{
                color: theme.palette.primary.main,
                position: "absolute",
              }}
            />
          ) : (
            t("buttons.delete")
          )}
        </Button>
      )}

      {open && (
        <Dialog open={open} maxWidth="sm" fullWidth>
          <DialogTitle>
            <Typography sx={theme.typography.h2}>
              {t("headings.deleteConfirmation")}
            </Typography>
          </DialogTitle>
          <Box position="absolute" top={0} right={0}>
            <IconButton onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </Box>
          <DialogContent>
            <Typography sx={theme.typography.h4}>
              {t("messages.pleaseConfirmTheDeletion")}{" "}
              <b>{t("messages.itCannotBeRecovered")}</b>.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setOpen(false);
                cancelCallBack && cancelCallBack();
              }}
              sx={{
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.secondary.contrastText,
                border: `1px solid ${theme.palette.primary.main}`,
                "&:hover": {
                  backgroundColor: theme.palette.secondary.contrastText,
                },
              }}
            >
              {t("buttons.cancel")}
            </Button>
            <Button
              sx={{
                backgroundColor: "red",
                "&:hover": {
                  backgroundColor: "red",
                },
              }}
              variant="contained"
              onClick={handleDelete}
              autoFocus
            >
              {t("buttons.confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

DeleteConfirmationDialog.propType = {
  deleteApiCall: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
};

export default DeleteConfirmationDialog;
