import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllJobSchedules, getJobDetails } from "src/api/jobApi";
import { deleteSchedule } from "src/api/scheduleApi";
import { AuthContext } from "src/auth/AuthProvider";
import AddNewButton from "src/components/Common/Button/AddNewButton";
import DeleteConfirmationDialog from "src/components/Common/DeleteConfirmationDialog";
import DataTable from "src/components/Common/TabTable/DataTable";
import EditSchedule from "src/components/Job/EditSchedule";
import Permissions from "src/constants/Permissions";
import { usePermission } from "src/context/PermissionContext";
import { changePageHeading } from "src/redux/Slices/Common";
import { useDispatch } from "src/redux/Store";

const Events = ({ types, jobData, eventName, total, detailsApi }) => {
  const auth = useContext(AuthContext);
  const profile = auth.getProfile();
  const roleId = profile.RoleId;
  const token = auth.getAuthToken();
  const timeFormat = auth.getProfile().TimeFormat || "HH:mm";
  const CurrencySymbol = auth.getProfile().CurrencySymbol;

  const { jobId } = useParams();

  const [values, setValues] = useState({});

  const theme = useTheme();

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openScheduler, setOpenScheduler] = useState(false);

  const [searchParams] = useSearchParams();
  const hanldeSetValues = () => {
    const obj = {};
    for (let [key, value] of searchParams) {
      if (key != "Due") {
        obj[key] = value;
      } else {
        obj["TodayDateTime"] = moment().format("YYYY-MM-DDTHH:mm:ss");
        obj[key] = value;
      }
    }
    return obj;
  };

  const [vals, setVals] = useState(hanldeSetValues);

  const handleChnge = (e) => {
    setVals(({ ...values }) => {
      values[e.target.name] = e.target.value;
      return values;
    });
  };

  useEffect(() => {
    dispatch(changePageHeading("Jobs"));
  }, [dispatch]);

  const [selectedIndex, setSelectedindex] = useState(-1);

  {
    /** ----------------------- Schedules -------------------------------- */
  }

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [SchedulesData, setScheduleData] = useState([]);

  useEffect(() => {
    fetchAllSchedules();
  }, [deleteLoading]);

  const fetchAllSchedules = async () => {
    setLoading(true);
    await getAllJobSchedules(
      token,
      {
        FieldId: jobId,
        TodayDateTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
      },
      roleId
    ).then((response) => {
      setScheduleData(response?.DATA);
    });
    setLoading(false);
  };

  const ScheduleTypeId = types.find(
    (item) => item.Module === "Schedule" && item.TypeName === "Visit"
  )?.TypeId;

  {
    /** ---------------------------- Delete Schedule ------------------------------ */
  }

  {
    /** ---------------------------- Edit Schedule ------------------------------ */
  }
  const [isEdit, setIsEdit] = useState(true);

  const handleEdit = (id, date) => {
    setIsEdit(true);

    if (moment(date).isBefore(moment(), "day")) {
      setIsSecondDialog(true);
    } else {
      setIsSecondDialog(false);
    }
    setScheduleId(id);
    setOpenScheduler(true);
  };
  {
    /** ---------------------------- Create Schedule ------------------------------ */
  }

  const handleAdd = () => {
    setIsEdit(false);
    setOpenScheduler(true);
  };

  {
    /** ---------------------------- Schedule Total ------------------------------ */
  }
  const [jobOverDetails, setJobOverDetails] = useState({});

  const fetchScheduleDetails = async () => {
    setLoading(true);
    await getJobDetails(token, jobId)
      .then((response) => {
        if (response?.STATUS_CODE !== "200") {
          toast.error(t("toasts.detailsLoadingFailed"));
          setLoading(false);
        } else {
          setJobOverDetails(response?.DATA);
          setLoading(false);
        }
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchScheduleDetails();
  }, []);

  {
    /** ------------------------------------------------------------------- */
  }

  const [scheduleId, setScheduleId] = useState(0);

  const [isSecondDialog, setIsSecondDialog] = useState(false);
  const editJob = usePermission(Permissions.EDIT_JOB);
  const deleteJob = usePermission(Permissions.DELETE_JOB);

  const headCells = [
    {
      name: "Title",
      label: t("tableHeadings.title"),
      isDefault: true,
      isView: true,
      idName: "JobId",
      toLink: "/jobs/view-job",
      toLinkState: { isView: true },

      render: (row, rowIndex) => {
        let indexToFind = -1;
        for (let i = 0; i < SchedulesData.length; i++) {
          if (SchedulesData[i].ScheduleId === row?.ScheduleId) {
            indexToFind = i;
            break;
          }
        }
        return (
          <>
            {row?.Title}
            <Typography variant="h4" color={theme.palette.customerIds}>
              #
              {`${row?.JobNumber || 0}.${
                indexToFind !== -1 ? indexToFind + 1 : rowIndex + 1
              }`}
            </Typography>
          </>
        );
      },
    },

    {
      name: "StartDate",
      label: t("textFieldLables.startDate"),
      isSortable: false,
      sortName: "CREATED_DATE",
      isView: true,
      fieldRenderType: "date",
    },
    {
      name: "EndDate",
      label: t("textFieldLables.endDate"),
      isSortable: false,
      sortName: "CREATED_DATE",
      isView: true,
      fieldRenderType: "date",
    },
    {
      name: "StartTime",
      label: t("textFieldLables.startTime"),
      isSortable: false,
      sortName: "CREATED_DATE",
      isView: true,
      render: (row, index) => {
        return row.StartTime ? moment(row.StartTime).format(timeFormat) : "";
      },
    },
    {
      name: "EndTime",
      label: t("textFieldLables.endTime"),
      isSortable: false,
      sortName: "CREATED_DATE",
      isView: true,
      render: (row, index) => {
        return row.EndTime ? moment(row.EndTime).format(timeFormat) : "";
      },
    },

    {
      name: "Actions",
      isView: true,
      label: t("tableHeadings.actions"),
      render: (row, index) => {
        return (
          <Stack direction="row">
            <Tooltip title={t("toolTips.view")}>
              <IconButton
                variant="contained"
                sx={{
                  color: theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: theme.palette.input.background,
                  },
                }}
                onClick={() => {
                  navigate(
                    `/jobs/view-job/${jobId}/view-event/${row.ScheduleId}`,
                    {
                      state: { isView: true },
                    }
                  );
                }}
              >
                <RemoveRedEyeIcon fontSize="medium" />
              </IconButton>
            </Tooltip>

            {editJob && (
              <React.Fragment key={row.ScheduleId}>
                <Tooltip title={t("toolTips.edit")}>
                  <IconButton
                    variant="contained"
                    sx={{
                      color: theme.palette.primary.main,
                      "&:hover": {
                        backgroundColor: theme.palette.input.background,
                      },
                    }}
                    disabled={
                      moment(row?.EndDate).isBefore(moment(), "day") ||
                      !row?.IsMutable
                    }
                    onClick={() => {
                      navigate(
                        `/jobs/view-job/${jobId}/edit-event/${row.ScheduleId}`,
                        {
                          state: { isView: true },
                        }
                      );
                    }}
                  >
                    <DriveFileRenameOutlineIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>

                <Tooltip title={t("toolTips.reschedule")}>
                  <IconButton
                    variant="contained"
                    sx={{
                      color: theme.palette.primary.main,
                      "&:hover": {
                        backgroundColor: theme.palette.input.background,
                      },
                    }}
                    onClick={() => {
                      handleEdit(row.ScheduleId, row.StartDate);
                    }}
                  >
                    <ManageHistoryIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </React.Fragment>
            )}
            {deleteJob && (
              <DeleteConfirmationDialog
                deleteApiCall={deleteSchedule}
                id={row.ScheduleId}
                token={token}
                callback={detailsApi}
                isTable={true}
                setDeleteLoading={setDeleteLoading}
              />
            )}
          </Stack>
        );
      },
    },
  ];

  const [renderColumns, setRenderColumns] = useState(
    headCells.filter((col) =>
      typeof col.isView === "boolean" ? col.isView : true
    )
  );

  {
    /** ---------------------- sorting ----------------------- */
  }

  const [isArrow, setIsArrow] = useState({
    SortBy: null,
    IsAsc: null,
  });

  {
    /** -------------------------------------------------------------- */
  }

  return (
    <>
      <Grid container spacing={1} marginTop={{ xs: 5, sm: 5, md: 1 }}>
        <Grid item container md={6} xs={12} gap={2} alignItems={"center"}>
          <Typography variant="h2" align="left" gutterBottom>
            {t(`subHeadings.listof`)} {eventName}
          </Typography>
          {profile.RoleId === 1 && (
            <AddNewButton
              isRecurringTable={true}
              title={`${t(`buttons.add`)} ${eventName}`}
              width={"10rem"}
              handleClick={() => {
                handleAdd();
              }}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <DataTable
            columns={renderColumns}
            rows={SchedulesData}
            isLoading={loading}
            tableHeading={null}
            pagination={false}
            isArrow={isArrow}
            setIsArrow={setIsArrow}
            rowLastData={
              profile.RoleId === 1
                ? {
                    Title: t(`tableHeadings.totalRevenue`),
                    Actions:
                      `${CurrencySymbol} ${jobOverDetails?.ScheduleTotal?.toFixed(
                        2
                      )}` || 0,
                  }
                : {}
            }
            isCommonView={true}
          />
        </Grid>

        {openScheduler ? (
          <EditSchedule
            open={openScheduler}
            setOpen={setOpenScheduler}
            scheduleId={scheduleId}
            index={selectedIndex}
            isEdit={isEdit}
            // events={question}
            jobId={jobId}
            jobDetails={jobData}
            allschedules={SchedulesData}
            // setQuestion={setQuestion}
            // loadData={fetchQuestions}
            ScheduleTypeId={ScheduleTypeId}
            loading={loading}
            setLoading={setDeleteLoading}
            isSecondDialog={isSecondDialog}
            setIsSecondDialog={setIsSecondDialog}
            detailsApi={detailsApi}
          />
        ) : null}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    types: state.type.types,
  };
};

export default connect(mapStateToProps, null)(Events);
