import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const CancelDialog = ({ open, setOpen, handleChangeStatus }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    handleChangeStatus("Cancel");
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
      <DialogTitle>
        <Box>
          <Typography
            color="textSecondary"
            fontWeight={theme.typography.fontWeightBold}
            variant="h3"
          >
            {t("messages.ongoingCompleteJob")}
          </Typography>
        </Box>
      </DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography sx={theme.typography.h4}>
          {t("messages.cancelJob")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
          sx={{
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.contrastText,
            border: `1px solid ${theme.palette.primary.main}`,
            "&:hover": {
              backgroundColor: theme.palette.secondary.contrastText,
            },
          }}
        >
          {t("buttons.cancel")}
        </Button>
        <Button
          variant="contained"
          color="inherit"
          sx={{
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.contrastText,
            border: `1px solid ${theme.palette.primary.main}`,
            "&:hover": {
              backgroundColor: theme.palette.secondary.contrastText,
            },
          }}
          onClick={handleSubmit}
          autoFocus
        >
          {t("buttons.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelDialog;
