import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import WifiIcon from "@mui/icons-material/Wifi";

const Layout = ({ children }) => {
  const { t } = useTranslation();
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnlineStatus = () => {
      const newIsOnline = navigator.onLine;
      if (!isOnline && newIsOnline) {
        toast.dismiss();
        toast(
          <div style={{ display: "flex", alignItems: "center" }}>
            <WifiIcon sx={{ color: "green", mr: 1 }} />
            <span>{t("toasts.onlineMsg")}</span>
          </div>,
          {
            position: "bottom-left",
            autoClose: 2000, // Automatically close after 2 seconds
            hideProgressBar: true,
          }
        );
      } else if (isOnline && !newIsOnline) {
        toast.dismiss();
        toast(
          <div style={{ display: "flex", alignItems: "center" }}>
            <WifiOffIcon sx={{ mr: 1 }} />
            <span>{t("toasts.offlineMsg")}</span>
          </div>,
          {
            position: "bottom-left",
            autoClose: false, // Keep toast until online status is restored
            hideProgressBar: true,
          }
        );
      }
      setIsOnline(newIsOnline);
    };

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, [isOnline]);

  return (
    <>
      <Outlet>{children}</Outlet>
    </>
  );
};

export default Layout;
