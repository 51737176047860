import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { CsvBuilder } from "../CsvBuilder";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { Close, Download } from "@mui/icons-material";
import AddNewButton from "../Button/AddNewButton";

function Csv(rows, renderColumns, filename = "file", delimiter, setCsvLoading) {
  try {
    CsvBuilder(
      Array.from(rows),
      `${filename}-${moment().format("LLL")}` + ".csv",
      renderColumns,
      setCsvLoading
    );
  } catch (err) {
    console.error(`error in ExportCsv : ${err}`);
  }
}

const CsvExport = ({
  renderColumns,
  filename,
  CsvDataFn,
  token,
  disabled,
  delimiter = ",",
  width,
  title,
}) => {
  const [csvLoading, setCsvLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleClick = () => {
    setCsvLoading(true);
    setOpen(false);

    CsvDataFn(token, {})
      .then((data) => {
        Csv(data.DATA, renderColumns, filename, delimiter, setCsvLoading);
      })
      .catch(() => {
        return "error";
      });
  };
  return (
    <>
      <AddNewButton
        title={title ? title : t("buttons.export")}
        handleClick={() => setOpen(true)}
        icon={<Download />}
        disabled={csvLoading || disabled}
        width={width ? width : "10rem"}
      />
      <Dialog open={open} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Typography sx={theme.typography.h2}>
            {t("headings.exportConfirmation")}
          </Typography>
        </DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography sx={theme.typography.h4}>
            {t("messages.exportCSVConfirmation")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setOpen(false)}
            sx={{
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.secondary.contrastText,
              border: `1px solid ${theme.palette.primary.main}`,
              "&:hover": {
                backgroundColor: theme.palette.secondary.contrastText,
              },
            }}
          >
            {t("buttons.cancel")}
          </Button>
          <Button variant="contained" onClick={handleClick} autoFocus>
            {t("buttons.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CsvExport;

//i have this for testing purpose once finished i will remove it
// await fetch("https://jsonplaceholder.typicode.com/photos")
//       .then((da) => {
//         return da.json();
//       })
//       .then((data) => {
//         Csv(data, renderColumns, filename, delimiter);
//       })
//       .catch(() => {
//         return "error";
//       });
