import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { requestPayment } from "src/api/paymentApi";
import { AuthContext } from "src/auth/AuthProvider";
import CustomInput from "src/components/Common/FormElements/CustomInput";
import * as yup from "yup";

const RequestDepositDialog = ({ FieldId, TypeId, CustomerId, balance }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const auth = useContext(AuthContext);
  const CurrencySymbol = auth.getProfile()?.CurrencySymbol || "$";
  const token = auth.getAuthToken();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const defaultValues = {
    FieldId: FieldId,
    TypeId: TypeId,
    CustomerId: CustomerId,
    TotalAmount: 0,
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const schema = yup.object().shape({
    FieldId: yup.number().required(),
    TypeId: yup.number().required(),
    CustomerId: yup.number().required(),
    TotalAmount: yup
      .number()
      .typeError(t("messages.validAmount")) // Error if the value is not a number
      .min(1, t("messages.validAmount"))
      .max(balance, t("messages.validAmount"))
      .required(t("messages.validAmount")),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    setLoading(true);
    await requestPayment(token, values)
      .then((response) => {
        setLoading(false);

        if (response.STATUS_CODE == "200" && response.DATA == true) {
          toast.success(t("toasts.paymentRequestCreatedSuccessfully"));

          reset();
          handleClose();
        } else {
          toast.error(t("toasts.paymentRequestFailedToCreate"));
        }
      })
      .catch((error) => {
        toast.error(t("toasts.paymentRequestFailedToCreate"));
        setLoading(false);
      });
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Box>
            <Typography
              color="textSecondary"
              gutterBottom
              fontWeight={theme.typography.fontWeightBold}
              variant="h3"
            >
              {t("subHeadings.requestPayment")}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <CustomInput
              label={t("labels.paymentAmount")}
              placeholder={t("placeholder.paymentAmount")}
              containerProps={{
                xs: 12,
                mx: 2,
              }}
              type="number"
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {CurrencySymbol}
                  </InputAdornment>
                ),
              }}
              errors={errors}
              name={"TotalAmount"}
              register={register}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t("buttons.cancel")}
          </Button>
          {!loading ? (
            <Button
              variant="contained"
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              {t("buttons.submit")}
            </Button>
          ) : (
            <Button variant="contained" disabled>
              {t("buttons.loading")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Button onClick={handleOpen} sx={{ color: "#000000" }}>
        <Typography>{t("labels.requestDeposit")}</Typography>
      </Button>
    </>
  );
};

export default RequestDepositDialog;
