import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllEmployees } from "src/api/employeeApi";

export const fetchEmployees = createAsyncThunk(
  "employees/fetchEmployees",
  async ({ token }, { rejectWithValue }) => {
    return getAllEmployees(token, { Activation: true })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

const initialState = {
  isLoading: false,
  employees: [],
  statusCode: null,
  errorMessage: null,
  selectedEmployeeModalOpen: false,
  checked: [],
  selectedEmployee: [],
};

const Slice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    handleDialogOpen(state, action) {
      state.selectedEmployeeModalOpen = action.payload;
    },
    handleChecked(state, action) {
      state.checked = action.payload;
    },
    handleSelected(state, action) {
      state.selectedEmployee = action.payload;
    },
    updateChecked(state, action) {
      state.checked.push(action.payload);
    },
    updateSelected(state, action) {
      state.selectedEmployee.push(action.payload);
    },
    handleDeleteSelected(state, action) {
      state.selectedEmployee.splice(action.payload, 1);
    },
    handleDeleteChecked(state, action) {
      state.checked.splice(action.payload, 1);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployees.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        state.statusCode = action.payload.STATUS_CODE;
        state.errorMessage = action.payload.ERROR_MESSAGE;
        state.employees = action.payload.DATA;
        state.isLoading = false;
      })
      .addCase(fetchEmployees.rejected, (state, action) => {
        state.statusCode = action.payload.STATUS_CODE;
        state.errorMessage = action.payload.ERROR_MESSAGE;
        state.isLoading = false;
        throw action.payload;
      });
  },
});

export default Slice.reducer;

export const {
  handleDialogOpen,
  handleChecked,
  handleSelected,
  updateChecked,
  updateSelected,
  handleDeleteSelected,
  handleDeleteChecked,
} = Slice.actions;
