import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllStates } from "src/api/stateApi";
import { getAllCountries } from "src/api/countryApi";
import { getAllUserRoles } from "src/api/userRoleApi";

import { getFeatures } from "src/api/clientApi";

export const fetchContries = createAsyncThunk(
  "counrties/fetchContries",
  async (token, { rejectWithValue }) => {
    return getAllCountries(token)
      .then((data) => {
        return data.DATA;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const fetchStatebyCounrty = createAsyncThunk(
  "states/fetchStatebyCounrty",
  async ({ token, countryId, callBack }, { rejectWithValue }) => {
    return getAllStates(token, countryId)
      .then((data) => {
        callBack && callBack(data.DATA);
        return data.DATA;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const fetchStatebyCounrtyForBilling = createAsyncThunk(
  "states/fetchStatebyCounrtyForBilling",
  async ({ token, countryId, callBack }, { rejectWithValue }) => {
    return getAllStates(token, countryId)
      .then((data) => {
        callBack && callBack(data.DATA);
        return data.DATA;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const fetchRoles = createAsyncThunk(
  "roles/fetchRoles",
  async ({ token, clientId }, { rejectWithValue }) => {
    return getAllUserRoles(token, clientId)
      .then((data) => {
        return data.DATA;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const fetchfeatures = createAsyncThunk(
  "features/fetchFeatures",
  async ({ token }, { rejectWithValue }) => {
    return getFeatures(token)
      .then((data) => {
        return data.DATA;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

const initialState = {
  heading: "Dashboard",
  states: {
    data: [],
    error: "",
    isLoading: false,
  },
  billingStates: {
    data: [],
    error: "",
    isLoading: false,
  },
  countries: {
    data: [],
    error: "",
    isLoading: false,
  },
  roles: {
    data: [],
    error: "",
    isLoading: false,
  },
  selectedClientId: null,
  drawerOpen: false,
  features: {
    data: [],
    error: "",
    isLoading: false,
  },
};

const Slice = createSlice({
  name: "common",
  initialState,
  reducers: {
    changePageHeading(state, action) {
      state.heading = action.payload;
    },
    selectClientId(state, action) {
      state.selectedClientId = action.payload;
    },
    removeSelectedClientId(state, action) {
      state.selectedClientId = null;
    },
    changeDrawer(state, action) {
      state.drawerOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContries.pending, (state, action) => {
        state.countries.isLoading = true;
      })
      .addCase(fetchContries.fulfilled, (state, action) => {
        state.countries.data = action.payload;
        state.countries.isLoading = false;
      })
      .addCase(fetchContries.rejected, (state, action) => {
        state.countries.error = action.payload.STATUS_CODE;
        state.countries.isLoading = false;
        throw action.payload;
      })
      .addCase(fetchRoles.pending, (state, action) => {
        state.roles.isLoading = true;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.roles.data = action.payload;
        state.roles.isLoading = false;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.roles.error = action.payload.STATUS_CODE;
        state.roles.isLoading = false;
        throw action.payload;
      })
      .addCase(fetchStatebyCounrty.pending, (state, action) => {
        state.states.isLoading = true;
      })
      .addCase(fetchStatebyCounrty.fulfilled, (state, action) => {
        state.states.data = action.payload;
        state.states.isLoading = false;
      })
      .addCase(fetchStatebyCounrty.rejected, (state, action) => {
        state.states.error = action.payload.STATUS_CODE;
        state.states.isLoading = false;
        throw action.payload;
      })
      .addCase(fetchStatebyCounrtyForBilling.pending, (state, action) => {
        state.billingStates.isLoading = true;
      })
      .addCase(fetchStatebyCounrtyForBilling.fulfilled, (state, action) => {
        state.billingStates.data = action.payload;
        state.billingStates.isLoading = false;
      })
      .addCase(fetchStatebyCounrtyForBilling.rejected, (state, action) => {
        state.billingStates.error = action.payload.STATUS_CODE;
        state.billingStates.isLoading = false;
        throw action.payload;
      })
      .addCase(fetchfeatures.pending, (state, action) => {
        state.features.isLoading = true;
      })
      .addCase(fetchfeatures.fulfilled, (state, action) => {
        state.features.data = action.payload;
        state.features.isLoading = false;
      })
      .addCase(fetchfeatures.rejected, (state, action) => {
        state.features.error = action.payload.DATA.STATUS_CODE;
        state.features.isLoading = false;
        throw action.payload;
      });
  },
});

export default Slice.reducer;

export const {
  changePageHeading,
  selectClientId,
  removeSelectedClientId,
  changeDrawer,
} = Slice.actions;
