import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowBack } from "@mui/icons-material";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import BadgeIcon from "@mui/icons-material/Badge";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ClearIcon from "@mui/icons-material/Clear";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import ScheduleIcon from "@mui/icons-material/Schedule";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { alpha, styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllLabelConfiguration } from "src/api/clientApi";
import { getAllJobSchedules } from "src/api/jobApi";
import { changeStatus } from "src/api/requestApi";
import {
  deleteSchedule,
  getScheduleById,
  putSchedule,
} from "src/api/scheduleApi";
import { getEmployeeBySkillSet } from "src/api/skillSetApi";
import { AuthContext } from "src/auth/AuthProvider";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordion from "@mui/material/Accordion";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CustomerInfoGrid from "src/components/Common/CustomerInfoGrid";
import DeleteConfirmationDialog from "src/components/Common/DeleteConfirmationDialog";
import InvoiceSummary from "src/components/Common/InvoiceSummary";
import LineItemsComponent from "src/components/Common/LineItemsComponent";
import RequestSkeletonLoader from "src/components/Common/Loader/RequestSkeletonLoader";
import {
  getStatusColor,
  getStatusTextColor,
} from "src/components/Common/TabTable/StatusColor";
import { calculateTotalAndNewTotal } from "src/components/Controls/Calculator";
import DateTimePickerTheme from "src/components/Controls/DateTimePickerTheme";
import FileUpload from "src/components/DropZone/FileUpload";
import {
  VISIT_IN_DOCUMENT_ID,
  VISIT_OUT_DOCUMENT_ID,
} from "src/components/DropZone/TypeConstant_config";
import * as yup from "yup";
import { ViewTaxCalculator } from "src/components/Controls/ViewTaxCalculator";
import { usePermission } from "src/context/PermissionContext";
import Permissions from "src/constants/Permissions";

// necessary for content to be below app bar
const MainContainer = styled("main")(({ theme }) => ({
  // flexGrow: 1,
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2.2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5),
  },
}));

// necessary for content to be below app bar
const ToolbarContainer = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
}));
const DeleteConfirmation = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "10px",
  padding: "40px",
  flexDirection: "column",
  alignItems: "center",
  boxShadow: "10px",
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
const getAssignedEmployeesText = (employees) => {
  if (!employees || employees.length === 0) {
    return "";
  }

  return employees
    .map((employeeDetails) => {
      const { EmployeeName, Phone } = employeeDetails;
      const phoneNumber = Phone ? ` - ${Phone}` : "";
      return `${EmployeeName}${phoneNumber}`;
    })
    .join("\n");
};

const ViewEvent = ({
  types,
  employees,
  services,
  products,
  taxes,
  packages,
}) => {
  const auth = useContext(AuthContext);
  const profile = auth.getProfile();
  const token = auth.getAuthToken();

  const dateFormat = auth.getProfile().DateFormat;
  const timeFormat = auth.getProfile().TimeFormat;
  const maxEventCount = auth.getProfile().MaxEventCount;

  const theme = useTheme();

  const { t } = useTranslation();

  const { eventId } = useParams();

  const { jobId } = useParams();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  /*==========================================================================*/

  const location = useLocation();

  const disableButton = location.pathname.includes("view-event");

  const [scheduleData, setScheduleData] = useState({});

  const [fetchLoading, setFetchLoading] = useState(false);

  {
    /** ----------------------------------------------------------------------- */
  }
  const [expanded, setExpanded] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const isDownMd = useMediaQuery(theme.breakpoints.down("lg"));
  const [showButtons, setShowButtons] = useState(false);
  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };
  const handleAccordionChange2 = (event, isExpanded2) => {
    setExpanded2(isExpanded2);
  };

  const toggleButtons = () => {
    setShowButtons(!showButtons);
  };

  const ScheduleTypeId = types.find(
    (item) => item.Module === "Schedule" && item.TypeName === "Visit"
  )?.TypeId;

  const scheduleDetails = {
    FieldId: parseInt(jobId),
    NumberOfEmployee: 0,
    ScheduleType: ScheduleTypeId,
    Comment: "",
    TypeName: "Visit",
    TypeId: ScheduleTypeId,
    Module: "Schedule",
    Title: "",
    Instructions: "",
    IsScheduled: true,
    StartDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
    StartTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
    EndDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
    EndTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
    IsDelete: false,
    IsAnytime: false,
    Employees: [],
    ScheduleLineItems: [
      {
        ServiceId: 0,
        Qty: 1,
        Description: "",
        UnitPrice: 0,
        MarkUp: 0,
        Total: 0,
        Tax: 0,
        IsActive: true,
        LineItemTaxDetail: [],
      },
    ],
    NewEmployees: [],
    NewLineItems: [],
    NewProductLineItems: [],
    NewPackageLineItems: [],
    StatusId: 108,
    IsFilterBySkillSet: false,
  };

  const fetchSchedule = async () => {
    setFetchLoading(true);
    await getScheduleById(token, eventId)
      .then((response) => {
        if (response?.STATUS_CODE !== "200") {
          toast.error(t("toasts.leadDetailsLoadingFailed"));
          setFetchLoading(false);
        } else {
          setScheduleData({
            ...response?.DATA,
            ScheduleLineItems: response?.DATA?.ScheduleLineItems?.filter(
              (item) => item.Total !== 0
            ),
          });
          setFetchLoading(false);
        }
      })
      .catch((ex) => {
        console.log(ex);
        setFetchLoading(false);
      });
  };

  useEffect(() => {
    fetchSchedule();
  }, []);

  useEffect(() => {
    if (!!scheduleData) {
      for (const [key, value] of Object.entries(scheduleData)) {
        setValue(key, value);
      }
    }
  }, [scheduleData]);

  const validationSchema = yup.object().shape({
    // JobTypeId: yup.string().required("Required"),
    Comment: yup
      .string()
      .max(500, t("validations.characterLimitExceeded"))
      .notRequired(),
    Employee: yup.string(),
    CustomerMessage: yup
      .string()
      .max(500, t("validations.characterLimitExceeded")),
    Notes: yup.string().max(500, t("validations.characterLimitExceeded")),
    Instructions: yup
      .string()
      .max(500, t("validations.characterLimitExceeded"))
      .notRequired(),
    StartDate: yup
      .string()
      .required(t("validations.dateRequired"))
      .test("is valid date-time", "Invalid Date", (value) => {
        return moment(value).isValid();
      }),
    // .test(
    //   "start-date-test",
    //   t("validations.startDateLessThanEndDate"),
    //   function (value) {
    //     const { EndDate } = this.parent;
    //     return moment(value).isBefore(moment(EndDate));
    //   }
    // ),

    EndDate: yup
      .string()
      .required(t("validations.dateRequired"))
      .test("is valid date-time", "Invalid Date", (value) => {
        return moment(value).isValid();
      }),
    // .test(
    //   "end-date-test",
    //   t("validations.endDateGreaterThanStartDate"),
    //   function (value) {
    //     const { StartDate } = this.parent;
    //     return moment(value).isSameOrAfter(moment(StartDate));
    //   }
    // ),

    StartTime: yup.string().required(t("validations.timeRequired")),
    // .test(
    //   "start-time-test",
    //   t("validations.StartTimeLessThanEndTime"),
    //   function (value) {
    //     const { StartDate, EndDate, EndTime } = this.parent;
    //     const time = moment(getValues(`StartTime`)).format("THH:mm");

    //     if (
    //       StartDate === EndDate && // Dates are the same
    //       value !== time && // Current value is not the initial value
    //       EndTime !== time && // End time is not the initial end time
    //       value === EndTime // Start time and end time are the same
    //     ) {
    //       return false;
    //     }
    //     return true;
    //   }
    // ),
    EndTime: yup.string().required(t("validations.timeRequired")),
    // .test(
    //   "end-time-test",
    //   t("validations.EndTimeGreaterThanStartTime"),
    //   function (value) {
    //     const { StartDate, EndDate, StartTime } = this.parent;
    //     if (
    //       StartDate === EndDate && // Dates are the same
    //       value !== initialEndTime && // Current value is not the initial value
    //       StartTime !== initialStartTime && // Start time is not the initial start time
    //       value === StartTime // End time and start time are the same
    //     ) {
    //       return false;
    //     }
    //     return true;
    //   }
    // ),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: scheduleDetails,
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  watch();

  const [isEditDetails, setIsEditDetails] = useState(
    disableButton ? false : !getValues(`IsMutable`)
  );

  const initialStartDate = moment(getValues(`StartDate`)).format("YYYY-MM-DD");

  const initialEndDate = moment(getValues(`EndDate`)).format("YYYY-MM-DD");

  const initialStartTime = moment(getValues(`StartTime`)).format("THH:mm");

  const initialEndTime = moment(getValues(`EndTime`)).format("THH:mm");

  const getSchedule = async () => {
    setJobLoading(true);
    await getScheduleById(token, eventId)
      .then((response) => {
        if (response?.STATUS_CODE !== "200") {
          toast.error(t("toasts.jobDetailsLoadingFailed"));
          setJobLoading(false);
        } else {
          setScheduleData({
            ...response?.DATA,
            ScheduleLineItems: response?.DATA?.ScheduleLineItems?.filter(
              (item) => item.Total !== 0
            ),
          });
          setJobLoading(false);
        }
      })
      .catch((ex) => {
        console.log(ex);
        setJobLoading(false);
      });
  };

  useEffect(() => {
    getSchedule();
  }, []);

  useEffect(() => {
    if (!!scheduleData) {
      for (const [key, value] of Object.entries(scheduleData)) {
        setValue(key, value);
      }
    }
  }, [scheduleData]);
  /** ------------------------------- Event Id ------------------------------- */

  const [schedules, setSchedules] = useState([]);
  const [indexx, setIndexx] = useState(0);

  useEffect(() => {
    fetchAllSchedules();
  }, []);

  const fetchAllSchedules = async () => {
    setLoading(true);
    await getAllJobSchedules(token, {
      FieldId: jobId,
    }).then((response) => {
      setSchedules(response?.DATA);
      const newIndex =
        response?.DATA.findIndex(
          (row) => row.ScheduleId === parseInt(eventId)
        ) || 0;
      setIndexx(newIndex);
    });
    setLoading(false);
  };

  /** ---------------------------------------------------------------------- */

  {
    /** -----------------------Employees----------------------------- */
  }

  const [isCrossedOut, setIsCrossedOut] = useState([]);

  const toggleCrossedOut = (index) => {
    const updatedCrossedOut = [...isCrossedOut];
    updatedCrossedOut[index] = !updatedCrossedOut[index];
    setIsCrossedOut(updatedCrossedOut);
    if (isCrossedOut[index]) {
      setValue(`Employees[${index}].IsDelete`, false);
    } else {
      setValue(`Employees[${index}].IsDelete`, true);
    }
  };

  const addEmployeeRecord = () => {
    getEmployeeIds();
    let newArray = [
      ...getValues(`NewEmployees`),
      {
        EmployeeId: 0,
        ScheduleId: 0,
        IsCompleted: true,
        UpdatedBy: "",
        IsDelete: false,
      },
    ];
    setValue(`NewEmployees`, newArray);
  };

  const handleRemoveEmployee = (index) => {
    getEmployeeIds();
    setEmployeeIds((pre) =>
      pre.filter(
        (data) => data !== getValues(`NewEmployees[${index}].EmployeeId`)
      )
    );
    setValue(`NewEmployees[${index}].IsDelete`, true);
  };

  const [employeeIds, setEmployeeIds] = useState([]);

  const getEmployeeIds = () => {
    const filteredIds = !!getValues(`Employees`)
      ? getValues(`Employees`)
          .filter((obj) => obj.IsDelete === false)
          .map((obj) => obj.EmployeeId)
      : [];
    setEmployeeIds((pre) => [...pre, ...filteredIds]);
  };

  {
    /** -----------------------LineItemComponent----------------------- */
  }
  const [isLineItemsCrossedOut, setIsLineItemsCrossedOut] = useState([]);

  const jobLineItems = getValues(`ScheduleLineItems`) || [];

  {
    /** -----------------------LineItems----------------------------- */
  }

  const tystatus = getValues("Type");
  const [TypeStatus, setTypeStatus] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // setOpen(!open);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handletypeChange = (event) => {
    setAnchorEl(null);

    // Flag to track if toast has been shown
    let toastShown = false;

    const handleSuccess = () => {
      if (!toastShown) {
        toast.success(t("toasts.successfullyChangedTheStatus"));
        toastShown = true; // Set flag to true after showing the toast
      }
      setTypeStatus(event.target.outerText);
      if (event.target.outerText === "Cancel") {
        setScheduleData({ ...scheduleData, IsConvertable: false });
        setTypeStatus("Cancelled");
      }
    };

    const handleError = () => {
      if (!toastShown) {
        toast.error(t("toasts.failedToChangeTheStatus"));
        toastShown = true; // Set flag to true after showing the toast
      }
    };

    // First API call
    changeStatus(token, eventId, event.target.outerText, "5")
      .then((response) => {
        if (response?.STATUS_CODE === "200") {
          handleSuccess();
        } else {
          handleError();
        }
      })
      .catch((error) => {
        handleError();
      });

    // Second API call
    if (!isNaN(jobId) && event.target.outerText === `Ongoing`) {
      changeStatus(token, parseInt(jobId), event.target.outerText, "3")
        .then((response) => {
          if (response?.STATUS_CODE === "200") {
            handleSuccess();
          } else {
            handleError();
          }
        })
        .catch((error) => {
          handleError();
        });
    }
  };

  const serviceItemsCount =
    getValues(`ScheduleLineItems`)?.filter(
      (item) => item.ServiceId > 0 && !item?.IsDelete
    )?.length +
    getValues(`ScheduleLineItems`)?.filter(
      (item) =>
        parseInt(item?.PackageId) > 0 &&
        !item?.IsDelete &&
        item?.LineItemPackageDetail?.some(
          (lineItem) => lineItem.ServiceId > 0 || lineItem.ProductId > 0
        )
    )?.length +
    getValues(`NewLineItems`)?.filter((item) => !item?.IsDelete)?.length +
    getValues(`NewPackageLineItems`)?.filter(
      (item) =>
        !item?.IsDelete &&
        item?.LineItemPackageDetail?.some(
          (lineItem) => lineItem.ServiceId > 0 || lineItem.ProductId > 0
        )
    )?.length;

  const toggleLineItemsCrossedOut = (index) => {
    const isService = getValues(`ScheduleLineItems[${index}].ServiceId`) > 0;
    const isPackage = getValues(`ScheduleLineItems[${index}].PackageId`) > 0;

    const updatedLineItemsCrossedOut = [...isLineItemsCrossedOut];
    updatedLineItemsCrossedOut[index] = !updatedLineItemsCrossedOut[index];
    setIsLineItemsCrossedOut(updatedLineItemsCrossedOut);

    if (isService || isPackage) {
      if (serviceItemsCount > 1) {
        if (isLineItemsCrossedOut[index]) {
          setValue(`ScheduleLineItems[${index}].IsDelete`, false);
        } else {
          setValue(`ScheduleLineItems[${index}].IsDelete`, true);
        }
      } else {
        updatedLineItemsCrossedOut.splice(index, 1);
        setIsLineItemsCrossedOut(updatedLineItemsCrossedOut);

        setValue(`ScheduleLineItems[${index}].IsDelete`, false);
        toast.dismiss();
        toast.error("There must be at least one Service left!");
      }
    } else {
      if (isLineItemsCrossedOut[index]) {
        setValue(`ScheduleLineItems[${index}].IsDelete`, false);
      } else {
        setValue(`ScheduleLineItems[${index}].IsDelete`, true);
      }
    }
  };

  const addServiceRecord = () => {
    const newRecord = {
      ServiceId: 0,
      Qty: 1,
      Title: "",
      Description: "",
      IsService: true,
      UnitPrice: 0,
      MarkUp: 0,
      Total: 0,
      Tax: 0,
      IsActive: true,
    };

    const currentLineItems = getValues(`NewLineItems`) || [];
    const updatedLineItems = [...currentLineItems, newRecord];

    setValue(`NewLineItems`, updatedLineItems);
  };

  const addProductRecord = () => {
    const newRecord = {
      ProductId: 0,
      Qty: 1,
      Title: "",
      Description: "",
      UnitPrice: 0,
      MarkUp: 0,
      Total: 0,
      Tax: 0,
      IsPackage: false,
      IsService: false,
      IsActive: true,
    };

    const currentProductItems = getValues(`NewProductLineItems`) || [];
    const updatedProductLineITems = [...currentProductItems, newRecord];

    setValue(`NewProductLineItems`, updatedProductLineITems);
  };

  const handleRemoveClick = (index) => {
    if (serviceItemsCount > 1) {
      if (isCrossedOut[index]) {
        setValue(`NewLineItems[${index}].IsDelete`, false);
      } else {
        setValue(`NewLineItems[${index}].IsDelete`, true);
      }
    } else {
      setValue(`NewLineItems[${index}].IsDelete`, false);
      toast.dismiss();
      toast.error("There must be at least one Service left!");
    }
  };

  const handleRemoveProductClick = (index) => {
    setValue(`NewProductLineItems[${index}].IsDelete`, true);
  };

  const addPackageRecord = () => {
    let newPackageArray = [
      ...getValues(`NewPackageLineItems`),
      {
        PackageId: 0,
        Qty: 1,
        Title: "",
        Description: "",
        UnitPrice: 0,
        MarkUp: 0,
        Total: 0,
        Tax: 0,
        IsPackage: true,
        IsActive: true,
        LineItemPackageDetail: [],
      },
    ];
    setValue(`NewPackageLineItems`, newPackageArray);
  };

  const handleRemovePackageClick = (index) => {
    if (serviceItemsCount > 1) {
      setValue(`NewPackageLineItems[${index}].IsDelete`, true);
    } else {
      setValue(`NewPackageLineItems[${index}].IsDelete`, false);
      toast.dismiss();
      toast.error(t("toasts.thereMustBeAtleastOneServiceLeft"));
    }
  };

  {
    /** --------------------------------------------------------- */
  }

  const menuItems = [
    {
      name: "Cancel",
      label: "Cancel",
      // checked: printDetails.Filter,
    },
    {
      name: "Ongoing",
      label: "Ongoing",
      // checked: printDetails.Filter,
    },
    {
      name: "Completed",
      label: "Completed",
      // checked: printDetails.Filter,
    },
    // {
    //   name: "sortBy",
    //   label: "SortBy",
    //   checked: printDetails.sortBy,
    // },
  ];

  const [jobLoading, setJobLoading] = useState(false);

  const CurrencySymbol = auth.getProfile().CurrencySymbol;

  // const employeeNames = scheduleDetails.Employees.map(
  //   (employee) => employee.EmployeeName
  // ).join(", ");

  /** -------------------- Tax Calculator --------------------------------- */

  const { taxTotal, taxDetails } = ViewTaxCalculator({
    lineItems: "ScheduleLineItems",
    serviceItems: "NewLineItems",
    productItems: "NewProductLineItems",
    packageItems: "NewPackageLineItems",
    services: services,
    products: products,
    taxes: taxes,
    getValues: getValues,
    details: scheduleData?.ScheduleLineItems,
  });

  /** --------------------------------------------------------------------- */

  {
    /**------------------------Calculations----------------------- */
  }

  const {
    subNewTotal,
    totalNewTotal,
    serviceTotal,
    productTotal,
    packageTotal,
  } = calculateTotalAndNewTotal({
    lineItems: "ScheduleLineItems",
    newServiceItems: "NewLineItems",
    newProductItems: "NewProductLineItems",
    newPackageItems: "NewPackageLineItems",
    taxTotal: taxTotal,
    getValues: getValues,
  });

  const [discount, setDiscount] = useState(``);

  const handleDiscountChange = (event) => {
    const inputValue = event.target.value;
    setDiscount(inputValue);
  };

  const inputWidth = (discount.length + 1) * 10 + `px`;

  const Totaltotal = totalNewTotal - getValues("Discount");

  const onSubmit = async (values) => {
    toast.dismiss();

    let startDate = moment(getValues(`StartDate`)).format("YYYY-MM-DD");
    let startTime = moment(getValues(`StartTime`)).format("HH:mm:ss");

    let endDate = moment(getValues(`EndDate`)).format("YYYY-MM-DD");
    let endTime = moment(getValues(`EndTime`)).format("HH:mm:ss");

    const allItems = [
      ...getValues("NewLineItems"),
      ...getValues("NewProductLineItems"),
      ...getValues("NewPackageLineItems"),
    ];

    const NoTotal = allItems.find((item) => !item.IsDelete && item.Total == 0);
    const hasNoTotal = Boolean(NoTotal);
    getValues("NewEmployees")?.forEach((employeeId) => {
      // if (
      //   !getValues("visits[0].Employees")?.some(
      //     (employee) => employee.EmployeeId === employeeId.EmployeeId
      //   )
      // ) {
      //   !getValues("visits[0].Employees") &&
      //     setValue("visits[0].Employees", []);
      //   !employeeId.IsDelete &&'
      if (!getValues("Employees")) {
        setValue("Employees", []);
      }
      getValues("Employees")?.push(employeeId);
      // }
    });
    const newEmployeeIds = getValues("NewEmployees")?.map(
      (item) => item.EmployeeId
    );

    const v = values.ScheduleLineItems?.map((option) => {
      return {
        ...option,
        ServiceId: option?.ServiceId !== "" ? option?.ServiceId : 0,
        ProductId: option?.ProductId !== "" ? option?.ProductId : 0,
        PackageId: option?.PackageId !== "" ? option?.PackageId : 0,
        // IsService: option?.ServiceId> 0,
        Qty:
          option.Qty === null || option.Qty === ""
            ? 0
            : option?.ServiceId > 0
            ? parseFloat(option.Qty)
            : parseInt(option.Qty),
        UnitPrice:
          option.UnitPrice === null || option.UnitPrice === ""
            ? 0
            : parseFloat(option.UnitPrice),

        LineItemTaxDetail: [
          ...(option?.LineItemTaxDetail || []),
          ...(option?.DeleteLineItemTaxDetail || []),
        ],
      };
    })?.map((option) => {
      const { DeleteLineItemTaxDetail, ...objects } = option;
      return objects;
    });

    const NewV = values.NewLineItems?.filter(
      (options) =>
        !options.IsDelete && options.ServiceId !== 0 && options.Total !== 0
    ).map((options) => {
      return {
        ...options,
        ServiceId: options?.ServiceId !== "" ? options?.ServiceId : 0,
        ProductId: null,
        PackageId: null,
        Qty:
          options.Qty === null || options.Qty === ""
            ? 0
            : parseFloat(options.Qty),
        UnitPrice:
          options.UnitPrice === null || options.UnitPrice === ""
            ? 0
            : parseFloat(options.UnitPrice),
      };
    });

    const NewProductV = values.NewProductLineItems?.filter(
      (options) =>
        !options.IsDelete && options.ProductId !== 0 && options.Total !== 0
    ).map((options) => {
      return {
        ...options,
        ServiceId: null,
        ProductId: options?.ProductId !== "" ? options?.ProductId : 0,
        PackageId: null,
        Qty:
          options.Qty === null || options.Qty === ""
            ? 0
            : parseInt(options.Qty),
        UnitPrice:
          options.UnitPrice === null || options.UnitPrice === ""
            ? 0
            : parseFloat(options.UnitPrice),
      };
    });

    const NewPackage = values.NewPackageLineItems?.filter(
      (options) => !options.IsDelete
    )?.map((options) => {
      return {
        ...options,
        ServiceId: null,
        ProductId: null,
        PackageId: options?.PackageId !== "" ? options?.PackageId : 0,
        Qty:
          options.Qty === null || options.Qty === ""
            ? 0
            : parseFloat(options.Qty),
        UnitPrice:
          options.UnitPrice === null || options.UnitPrice === ""
            ? 0
            : parseFloat(options.UnitPrice),
      };
    });

    const updatedV = v.concat(NewV, NewProductV, NewPackage);

    const dataRecord = {
      ...values,
      ScheduleLineItems: updatedV,
      TaxTotal: taxTotal,
      Total:
        Totaltotal === null || Totaltotal === "" ? 0.0 : parseFloat(Totaltotal),
      Discount:
        getValues("Discount") == null ? 0.0 : parseFloat(getValues("Discount")),
      TaxTotal: taxTotal,
      RequestStatusId: parseInt(values.RequestStatusId),
      AddressId: parseInt(values.AddressId),

      StartDate: moment(`${startDate} ${startTime}`).format("YYYY-MM-DDTHH:mm"),

      EndDate: moment(`${endDate} ${endTime}`).format("YYYY-MM-DDTHH:mm"),

      StartTime: moment(`${startDate} ${startTime}`).format("YYYY-MM-DDTHH:mm"),

      EndTime: moment(`${endDate} ${endTime}`).format("YYYY-MM-DDTHH:mm"),

      Employees: !!values?.Employees ? values?.Employees : [],
      UpdatedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
    };

    if (updatedV?.length < 1) {
      toast.dismiss();
      toast.error(t("toasts.atleastOneProductOrServiceRequired"));
    } else if (
      moment(dataRecord?.EndDate).isBefore(moment(dataRecord?.StartDate)) ||
      (moment(dataRecord?.EndDate).isSame(moment(dataRecord?.StartDate)) &&
        moment(dataRecord?.EndTime).isSameOrBefore(
          moment(dataRecord?.StartTime)
        ))
    ) {
      toast.dismiss();
      toast.error(t("toasts.endDateShouldBeFutureDateThanTheStartDate"));
    } else if (getValues("Discount") > subNewTotal) {
      toast.dismiss();
      toast.error(t("toasts.discountValueIsTooLarge"));
    } else if (serviceItemsCount === 0) {
      toast.dismiss();
      toast.error(t("toasts.thereMustBeAtleastOneServiceLeft"));
    } else if (hasNoTotal) {
      toast.error(
        t("toasts.eachServiceProductPackageNeedsToHavePriceAndTotal")
      );
    } else {
      setLoading(true);

      await putSchedule(token, eventId, {
        ...dataRecord,
        UpdatedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
      })
        .then((response) => {
          if (response?.STATUS_CODE === "200") {
            getSchedule();
            toast.success(t("toasts.jobUpdatedSuccessfully"));
            setLoading(false);
            navigate(-1);
          } else {
            toast.error(t("toasts.failedToUpdateJob"));
            setLoading(false);
          }
        })
        .catch((e) => {
          toast.error(t("toasts.failedToUpdateJob"));
          setLoading(false);
        });
    }
  };

  const handleEditDetails = () => {
    setValue(`NewLineItems`, []);
    setValue(`NewProductLineItems`, []);
    setValue(`NewPackageLineItems`, []);
    setValue(`NewEmployees`, []);
    setIsCrossedOut([]);
    setEmployeeIds([]);
    setIsEditDetails(!isEditDetails);
    // setEditedResponses([...questionResponses]);
  };

  const handleCancelButtonClick = () => {
    setIsEditDetails(false);
    toast.info("Edit canceled!");
    // setJobData({});

    reset(scheduleData);
    navigate(-1); //navigate part
    // setValue(`NewLineItems`, []);
    // setValue(`NewProductLineItems`, []);
    //  setValue(`NewPackageLineItems`, []);
    // setValue(`NewEmployees`, []);
    // setIsCrossedOut([]);
    // setEmployeeIds([]);

    // setRecError("");s
    // setValue("IsScheduled", false);
  };

  const handleConvertToInvoice = () => {
    setTimeout(() => {
      navigate("/invoices/new-invoice", {
        state: {
          isConvert: true,
          isFromJob: !!parseInt(jobId) ? parseInt(jobId) : 0,
        },
      });
    }, 1000);
  };

  /*==========================================================================*/

  {
    /** -----------------------LineItemComponent----------------------- */
  }

  const [jobLineItemsLength, setJobLineItemsLength] = useState(1);

  useEffect(() => {
    setJobLineItemsLength(getValues(`ScheduleLineItems`)?.length);
  }, [getValues(`ScheduleLineItems`)]);

  const [isHovering, setIsHovering] = useState(false);

  {
    /** -----------------------LineItemComponentFilter------------------------ */
  }

  /** ----------------------Skill Set ------------------------------ */
  const [employeeList, setEmployeeList] = useState(null);

  const fetchEmployees = (dataRecord) => {
    getEmployeeBySkillSet(token, dataRecord)
      .then((response) => {
        setEmployeeList(response?.DATA);
      })
      .catch((e) => {
        setEmployeeList([]);
      });
  };

  useEffect(() => {
    //   let serviceLineItems =
    //     [...getValues(`ScheduleLineItems`), ...getValues("NewLineItems")]
    //       ?.filter((obj) => parseInt(obj?.ServiceId) > 0 && !obj.IsDelete)
    //       ?.map((item) => String(item.ServiceId)) || [];

    if (
      //     getValues("IsScheduled") &&
      getValues("IsFilterBySkillSet") &&
      //     !getValues("isRecurred") &&
      getValues(`StartDate`) !== "" &&
      getValues(`StartTime`) !== "" &&
      getValues(`EndDate`) !== "" &&
      getValues(`EndTime`) !== ""
      // &&
      //     serviceLineItems?.length > 0
    ) {
      fetchEmployees({
        // ServiceIdList: String(serviceLineItems.join(",")),
        StartDate: moment(
          moment(getValues(`StartDate`)).format("YYYY-MM-DD") +
            " " +
            moment(getValues(`StartTime`)).format("HH:mm:ss")
        ).format("YYYY-MM-DDTHH:mm:ss"),
        EndDate: moment(
          moment(getValues(`EndDate`)).format("YYYY-MM-DD") +
            " " +
            moment(getValues(`EndTime`)).format("HH:mm:ss")
        ).format("YYYY-MM-DDTHH:mm:ss"),
        IsFilterBySkillSet: true,
      });
    } else if (
      !getValues("IsFilterBySkillSet") &&
      getValues(`StartDate`) !== "" &&
      getValues(`StartTime`) !== "" &&
      getValues(`EndDate`) !== "" &&
      getValues(`EndTime`) !== ""
    ) {
      fetchEmployees({
        ServiceIdList: "",
        StartDate: moment(
          moment(getValues(`StartDate`)).format("YYYY-MM-DD") +
            " " +
            moment(getValues(`StartTime`)).format("HH:mm:ss")
        ).format("YYYY-MM-DDTHH:mm:ss"),
        EndDate: moment(
          moment(getValues(`EndDate`)).format("YYYY-MM-DD") +
            " " +
            moment(getValues(`EndTime`)).format("HH:mm:ss")
        ).format("YYYY-MM-DDTHH:mm:ss"),
        IsFilterBySkillSet: false,
      });
    } else {
      setEmployeeList(null);
    }
  }, [
    watch("IsFilterBySkillSet"),
    watch(`StartDate`),
    watch(`StartTime`),
    watch(`EndDate`),
    watch(`EndTime`),
    //   watch("isRecurred"),
    //   watch("IsScheduled"),
    //   serviceTotal,
  ]);

  /** ---------------------------- Recurring Job ------------------------------ */

  const [values, setValues] = useState(0);

  const handleChange = (newValue) => {
    setValues(newValue);
  };

  function a11yPropsType(indexType) {
    return {
      id: `simple-tab-${indexType}`,
      "aria-controls": `simple-tabpanel-${indexType}`,
    };
  }

  const [valuess, setValuess] = useState(0);

  const handleTabChange = (newValue) => {
    setValuess(newValue);
  };

  /** ---------------------------------- Recurring Job Labels ----------------------  */

  const [eventName, setEventName] = useState("Events");

  useEffect(() => {
    fetchLabelConfiguration();
  }, []);

  const fetchLabelConfiguration = async () => {
    // setLoading(true);
    await getAllLabelConfiguration(token, {
      ModuleName: `Jobs`,
      PortionName: `Schedule`,
      DefaultName: "Event",
      Page: 1,
      limit: 10,
    }).then((response) => {
      if (Array.isArray(response?.DATA)) {
        const foundEvent = response?.DATA?.find(
          (name) => name.DefaultName === "Event"
        );
        if (foundEvent) {
          setEventName(foundEvent.CurrentName);
        }
      }
    });
    // setLoading(false);
  };

  // const title = scheduleData.Title;

  /** ----------------------------------------------------------------------- */

  /** ----------------------------------------------------------------------- */
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={
        <ArrowForwardIosSharpIcon
          sx={{
            fontSize: "0.9rem",
            fontWeight: 600,
            "@media (min-width:1536px)": {
              fontSize: "1.1rem",
            },
          }}
        />
      }
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));
  const [anchorElMenu1, setAnchorElMenu1] = useState(null);
  const handleClickMenu1 = (event) => {
    setAnchorElMenu1(event.currentTarget);
  };

  const handleCloseMenu1 = () => {
    setAnchorElMenu1(null);
  };

  const openMenu1 = Boolean(anchorElMenu1);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const createInvoice = usePermission(Permissions.ADD_INVOICE);
  const editJob = usePermission(Permissions.EDIT_JOB);
  return (
    <MainContainer>
      <ToolbarContainer />

      {loading || jobLoading ? (
        <>
          <Grid container spacing={2}>
            <Grid item container xs={12}>
              <RequestSkeletonLoader />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <>
              <Grid container spacing={0} xs={12}>
                <Grid item container xs={12} lg={12}>
                  <Grid
                    item
                    xs={8}
                    md={8}
                    lg={7.8}
                    sx={{ mb: "0.5rem", mt: "1.0rem" }}
                  >
                    <Stack direction={"row"} justifyContent="flex-start">
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{ mr: "1rem" }}
                      >
                        <IconButton
                          sx={{
                            color: theme.palette.primary.contrastText,
                            backgroundColor: theme.palette.secondary.light,
                            borderRadius: "25%",
                            ":hover": {
                              backgroundColor: theme.palette.primary.main,
                            },
                          }}
                          onClick={() => navigate(-1)}
                        >
                          <ArrowBack />
                        </IconButton>
                      </Box>
                      <Typography
                        variant="h2"
                        align="left"
                        display="inline"
                        style={{
                          wordWrap: "break-word",
                          marginLeft: "10px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {`${eventName} ${t("headings.details")}`}
                      </Typography>
                      {!isDownMd && (
                        <div>
                          <Button
                            disabled={profile.RoleId != 1}
                            id="demo-positioned-button"
                            // aria-controls={open ? 'demo-positioned-menu' : undefined}
                            // backgroundColor="#0AC962"
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                            style={{
                              marginLeft: "20px",
                              marginBottom: "10px",
                              width: "200px",
                              height: "40px",
                              borderRadius: "12px",
                              boxSizing: "border-box",
                              color: getStatusTextColor(
                                TypeStatus != null
                                  ? TypeStatus
                                  : getValues("Type")
                                  ? getValues("Type")
                                  : "New",
                                theme
                              ),
                              backgroundColor:
                                getStatusColor(
                                  TypeStatus != null
                                    ? TypeStatus
                                    : getValues("Type")
                                    ? getValues("Type")
                                    : "New",
                                  theme
                                ) || theme.palette.input.background,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer", // Add this to make it appear clickable
                            }}
                          >
                            <Typography
                              variant="h4"
                              display="inline"
                              sx={{
                                wordWrap: "break-word",
                              }}
                            >
                              {TypeStatus != null
                                ? TypeStatus
                                : getValues("Type")
                                ? getValues("Type")
                                : "New"}
                            </Typography>
                          </Button>
                          {getValues("Type") !== "Converted To Jobs" &&
                            TypeStatus !== "Cancelled" &&
                            getValues("Type") !== "Cancelled" && (
                              <StyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                  "aria-labelledby": "demo-customized-button",
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                              >
                                {menuItems?.map(
                                  (menuItem, index) =>
                                    menuItem.name !== getValues("Type") && (
                                      <MenuItem
                                        key={index}
                                        onClick={handletypeChange}
                                      >
                                        {menuItem.name}
                                      </MenuItem>
                                    )
                                )}
                              </StyledMenu>
                            )}
                        </div>
                      )}
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    {isDownMd && (
                      <div style={{ width: "100%", marginLeft: "-10px" }}>
                        <Button
                          id="demo-positioned-button"
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                          style={{
                            marginLeft: "10px",
                            width: "100%",
                            marginBottom: "20px",
                            marginTop: "10px",
                            height: "40px",
                            borderRadius: "12px",
                            boxSizing: "border-box",
                            color: getStatusTextColor(
                              TypeStatus != null
                                ? TypeStatus
                                : getValues("Type"),
                              theme
                            ),
                            backgroundColor:
                              getStatusColor(
                                TypeStatus != null
                                  ? TypeStatus
                                  : getValues("Type"),
                                theme
                              ) || theme.palette.input.background,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer", // Add this to make it appear clickable
                          }}
                        >
                          <Typography
                            variant="h4"
                            display="inline"
                            sx={{
                              wordWrap: "break-word",
                            }}
                          >
                            {TypeStatus != null
                              ? TypeStatus
                              : getValues("Type")}
                          </Typography>
                        </Button>
                      </div>
                    )}
                  </Grid>
                  {false && (
                    <Grid item xs={4} md={4} lg={6} sx={{ mb: "0.5rem" }}>
                      {isDownMd ? (
                        <Grid
                          item
                          xs={12}
                          sx={{ marginLeft: { xs: "90px", md: "60px" } }}
                        >
                          <Stack
                            direction="row"
                            alignItems="flex-end"
                            justifyContent="flex-end"
                            spacing={2}
                          >
                            <Button
                              sx={{
                                width: "50px",
                                height: "30px",
                                borderRadius: "14px",
                              }}
                              onClick={toggleButtons}
                            >
                              <MenuIcon />
                            </Button>
                          </Stack>
                        </Grid>
                      ) : null}
                      {isDownMd && showButtons && createInvoice ? (
                        <Grid item xs={12} sx={{ textAlign: "center" }}>
                          <Backdrop
                            open={showButtons}
                            sx={{
                              zIndex: (theme) => theme.zIndex.drawer + 1,
                              color: theme.components.Backdrop.color,
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="center"
                              spacing={3}
                            >
                              <DeleteConfirmation>
                                <Typography
                                  color="textSecondary"
                                  gutterBottom
                                  variant="h3"
                                  marginBottom={3}
                                  fontWeight={theme.typography.fontWeightMedium}
                                >
                                  {t("messages.whatDoYouWantToDo")}
                                </Typography>
                                {createInvoice && (
                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      handleConvertToInvoice();
                                    }}
                                    disabled={
                                      Object.keys(scheduleData).length === 0
                                    }
                                    sx={{
                                      width: "170px",
                                      height: "40px",
                                      borderRadius: "24px",
                                      marginLeft: { xs: "10px", sm: "10px" },
                                    }}
                                  >
                                    {t("buttons.convertToInvoice")}
                                  </Button>
                                )}
                                <Button
                                  variant="contained"
                                  type="button"
                                  sx={{
                                    boxSizing: "border-box",
                                    marginLeft: "10px",
                                    color: theme.palette.primary.main,
                                    width: "120px",
                                    height: "40px",
                                    borderRadius: "24px",
                                    backgroundColor:
                                      theme.palette.secondary.contrastText,
                                    border: `1px solid ${theme.palette.primary.main}`,
                                    "&:hover": {
                                      backgroundColor:
                                        theme.palette.secondary.contrastText,
                                    },
                                  }}
                                  onClick={() => setShowButtons(false)}
                                >
                                  {t("buttons.cancel")}
                                </Button>
                              </DeleteConfirmation>
                            </Stack>
                          </Backdrop>
                        </Grid>
                      ) : !isDownMd ? (
                        <Grid
                          item
                          xs={12}
                          sx={{ marginLeft: { xs: "90px", md: "60px" } }}
                        >
                          <div
                            style={{
                              // position: "fixed",
                              top: 100,
                              right: 50,
                              zIndex: 100,
                            }}
                          >
                            {scheduleData.IsConvertable && createInvoice && (
                              <Stack
                                direction="row"
                                alignItems="flex-end"
                                justifyContent="flex-end"
                                spacing={2}
                              >
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    handleConvertToInvoice();
                                  }}
                                  disabled={
                                    Object.keys(scheduleData).length === 0
                                  }
                                  sx={{
                                    width: "170px",
                                    height: "40px",
                                    borderRadius: "24px",
                                  }}
                                >
                                  {t("buttons.convertToInvoice")}
                                </Button>
                              </Stack>
                            )}
                          </div>
                        </Grid>
                      ) : null}
                    </Grid>
                  )}
                  {!disableButton && (
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      justifyContent={"end"}
                      marginRight={{ md: "20px", xs: "0px" }}
                      marginTop={3}
                      sx={{
                        position: isLargeScreen ? "fixed" : "relative", // Adjust position based on screen size
                        right: isLargeScreen ? 20 : 0,
                        marginBottom: isLargeScreen ? 0 : 5,
                      }}
                      //marginTop={"-30px"}
                    >
                      <div>
                        <Stack
                          direction="row"
                          alignItems="flex-end"
                          justifyContent="flex-end"
                          spacing={2}
                        >
                          {isEditDetails ? (
                            <>
                              <Button
                                variant="contained"
                                onClick={handleCancelButtonClick}
                                type="button"
                                sx={{
                                  boxSizing: "border-box",
                                  color: theme.palette.primary.main,
                                  width: "120px",
                                  height: "40px",
                                  borderRadius: "4px",
                                  backgroundColor:
                                    theme.palette.secondary.contrastText,
                                  border: `1px solid ${theme.palette.primary.main}`,
                                  "&:hover": {
                                    backgroundColor:
                                      theme.palette.secondary.contrastText,
                                  },
                                }}
                                disabled={loading}
                              >
                                {t("buttons.cancel")}
                              </Button>

                              <Button
                                variant="contained"
                                // onClick={onSaveDetails}
                                type="submit"
                                sx={{
                                  width: "120px",
                                  height: "40px",
                                  borderRadius: "4px",
                                }}
                                // disabled={loading}
                              >
                                {t("buttons.update")}
                              </Button>

                              <DeleteConfirmationDialog
                                deleteApiCall={deleteSchedule}
                                id={eventId}
                                token={token}
                              />
                            </>
                          ) : (
                            scheduleData.IsEditable &&
                            editJob && (
                              <Button
                                variant="contained"
                                onClick={handleEditDetails}
                                sx={{
                                  boxSizing: "border-box",
                                  color: theme.palette.primary.main,
                                  width: "120px",
                                  height: "40px",
                                  borderRadius: "4px",
                                  backgroundColor:
                                    theme.palette.secondary.contrastText,
                                  border: `1px solid ${theme.palette.primary.main}`,
                                  "&:hover": {
                                    backgroundColor:
                                      theme.palette.secondary.contrastText,
                                  },
                                }}
                              >
                                {t("buttons.edit")}
                              </Button>
                            )
                          )}
                        </Stack>
                      </div>
                    </Grid>
                  )}
                </Grid>

                <Grid
                  container
                  padding={{ xl: 3, md: 2 }}
                  // marginLeft={{ xs: 2, md: 0 }}
                  marginTop={{ xs: -3, md: -1, lg: -3 }}
                  variant="outlined"
                >
                  <Grid item xs={12}>
                    <Stack direction={isDownMd ? "column" : "row"}>
                      <Grid
                        item
                        md={12}
                        xs={12}
                        lg={profile.RoleId === 1 ? 8 : 12}
                        marginBottom={isLargeScreen ? 0 : 2}
                        spacing={2}
                        marginRight={{
                          md: "0px",
                          lg: "10px",
                          xl: "10px",
                        }}
                      >
                        <CustomerInfoGrid
                          getValues={getValues}
                          setValue={setValue}
                          theme={theme}
                          totalNewTotal={totalNewTotal}
                          CurrencySymbol={CurrencySymbol}
                          Title={`Title`}
                          Name={"CustomerName"}
                          Phone={"Phone"}
                          Email={"Email"}
                          AddressLine1={"AddressLine1"}
                          City={"City"}
                          StateName={"StateName"}
                          CountryName={"CountryName"}
                          PostCode={"ZipCode"}
                          Id={"JobNumber"}
                          Latitude={`Latitude`}
                          Longitude={`Longitude`}
                          Description={`Instructions`}
                          dateFormat={dateFormat}
                          isEditDetails={isEditDetails}
                          indexx={indexx}
                          register={register}
                          errors={errors}
                        />
                      </Grid>
                      <Grid
                        item
                        md={12}
                        lg={4}
                        sx={{
                          position: isLargeScreen ? "fixed" : "relative", // Adjust position based on screen size
                          // top: isLargeScreen ? 170 : "relative",
                          right: isLargeScreen ? 40 : "relative",
                          // width: { xl: 410, lg: 300, xs: "relative" },
                          maxHeight: "calc(100vh - 64px)", // Adjust based on header height
                        }}
                      >
                        <InvoiceSummary
                          t={t}
                          theme={theme}
                          CurrencySymbol={CurrencySymbol}
                          isEditDetails={isEditDetails}
                          subNewTotal={subNewTotal}
                          taxTotal={taxTotal}
                          taxDetails={taxDetails}
                          totalNewTotal={totalNewTotal}
                          serviceTotal={serviceTotal}
                          productTotal={productTotal}
                          packageTotal={packageTotal}
                          getValues={getValues}
                          register={register}
                          setValue={setValue}
                          handleDiscountChange={handleDiscountChange}
                          inputWidth={inputWidth}
                        />
                      </Grid>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={2} marginBottom={"20px"}>
                      <Grid item xs={12}>
                        <Grid container spacing={0}>
                          <Grid item container spacing={0}>
                            <Grid item xs={12}>
                              <Stack direction={isDownMd ? "column" : "row"}>
                                <Grid
                                  item
                                  md={12}
                                  lg={11.5}
                                  mb={2}
                                  marginRight={{
                                    md: "0px",
                                    lg: "10px",
                                    xl: "10px",
                                  }}
                                >
                                  <Grid item md={12}>
                                    <LineItemsComponent
                                      theme={theme}
                                      CurrencySymbol={CurrencySymbol}
                                      isEditDetails={isEditDetails}
                                      getValues={getValues}
                                      register={register}
                                      setValue={setValue}
                                      details={scheduleData?.ScheduleLineItems}
                                      lineItems={"ScheduleLineItems"}
                                      newServiceLineItems={"NewLineItems"}
                                      newProductLineItems={
                                        "NewProductLineItems"
                                      }
                                      newPackageLineItems={
                                        "NewPackageLineItems"
                                      }
                                      toggleCrossedOut={
                                        toggleLineItemsCrossedOut
                                      }
                                      services={services}
                                      handleRemoveClick={handleRemoveClick}
                                      products={products}
                                      packages={packages}
                                      addServiceRecord={addServiceRecord}
                                      handleRemoveProductClick={
                                        handleRemoveProductClick
                                      }
                                      addProductRecord={addProductRecord}
                                      addPackageRecord={addPackageRecord}
                                      handleRemovePackageClick={
                                        handleRemovePackageClick
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={12}>
                                    <Stack
                                      direction="column"
                                      spacing={1}
                                      gap={3}
                                    >
                                      <Grid
                                        item
                                        md={12}
                                        lg={12}
                                        marginRight={{
                                          md: "0px",
                                          lg: "10px",
                                          xl: "10px",
                                        }}
                                      >
                                        <Grid item xs={4} mt={2}>
                                          <Typography
                                            color="textSecondary"
                                            gutterBottom
                                            variant="h4"
                                            fontWeight={
                                              theme.typography.fontWeightBold
                                            }
                                          >
                                            {t("textFieldLables.schedule")}
                                          </Typography>
                                        </Grid>
                                        <Box
                                          sx={{
                                            border: "1px solid #CACACA",
                                            p: 3,
                                            borderRadius: 3,
                                            minWidth: 350,
                                            maxHeight: isDownMd ? 350 : 175,
                                            overflowY: "auto",
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <Grid
                                            container
                                            item
                                            spacing={1}
                                            md={12}
                                          >
                                            <Grid item xs={6} md={3.5}>
                                              <Typography
                                                color="textSecondary"
                                                gutterBottom
                                                variant="h6"
                                                fontWeight={
                                                  theme.typography
                                                    .fontWeightMedium
                                                }
                                              >
                                                {t("textFieldLables.startDate")}
                                              </Typography>
                                              <DateTimePickerTheme>
                                                <MobileDatePicker
                                                  label={t(`labels.startDate`)}
                                                  inputFormat={dateFormat}
                                                  onChange={(e) => {
                                                    if (!e) {
                                                      toast.info(
                                                        t(
                                                          "toasts.startDateCannotBeEmpty"
                                                        )
                                                      );
                                                    } else {
                                                      setValue(
                                                        `StartDate`,
                                                        moment(e).format(
                                                          "YYYY-MM-DDTHH:mm"
                                                        )
                                                      );
                                                    }
                                                  }}
                                                  value={getValues(`StartDate`)}
                                                  componentsProps={{
                                                    actionBar: {
                                                      actions: [
                                                        "clear",
                                                        "cancel",
                                                        "accept",
                                                      ],
                                                    },
                                                  }}
                                                  minDate={moment()}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      fullWidth
                                                      size="small"
                                                      {...params}
                                                      name={`StartDate`}
                                                      // label="Enter Date"

                                                      variant={"outlined"}
                                                      error={!!errors.StartDate}
                                                      helperText={
                                                        errors.StartDate
                                                          ?.message
                                                      }
                                                      sx={{
                                                        pointerEvents:
                                                          isEditDetails
                                                            ? "auto"
                                                            : "none",
                                                      }}
                                                      InputProps={{
                                                        sx: {
                                                          color:
                                                            theme.palette
                                                              .primary
                                                              .contrastText,
                                                          fontWeight:
                                                            theme.typography
                                                              .fontWeightMedium,
                                                        },

                                                        endAdornment: (
                                                          <InputAdornment position="end">
                                                            <CalendarMonthIcon />
                                                          </InputAdornment>
                                                        ),
                                                      }}
                                                    />
                                                  )}
                                                />
                                              </DateTimePickerTheme>
                                            </Grid>
                                            <Grid item xs={6} md={3.5}>
                                              <Typography
                                                color="textSecondary"
                                                gutterBottom
                                                variant="h6"
                                                fontWeight={
                                                  theme.typography
                                                    .fontWeightMedium
                                                }
                                              >
                                                {t("textFieldLables.endDate")}
                                              </Typography>
                                              <DateTimePickerTheme>
                                                <MobileDatePicker
                                                  label={t(`labels.endDate`)}
                                                  inputFormat={dateFormat}
                                                  minDate={moment(
                                                    getValues(`StartDate`)
                                                  )}
                                                  onChange={(e) => {
                                                    if (!e) {
                                                      toast.info(
                                                        t(
                                                          "toasts.endDateCannotBeEmpty"
                                                        )
                                                      );
                                                    } else {
                                                      setValue(
                                                        `EndDate`,
                                                        moment(e).format(
                                                          "YYYY-MM-DDTHH:mm"
                                                        )
                                                      );
                                                    }
                                                  }}
                                                  value={getValues(`EndDate`)}
                                                  componentsProps={{
                                                    actionBar: {
                                                      actions: [
                                                        "clear",
                                                        "cancel",
                                                        "accept",
                                                      ],
                                                    },
                                                  }}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      fullWidth
                                                      size="small"
                                                      {...params}
                                                      name={`EndDate`}
                                                      // label="Enter Date"
                                                      variant={"outlined"}
                                                      error={!!errors.EndDate}
                                                      helperText={
                                                        errors.EndDate?.message
                                                      }
                                                      sx={{
                                                        pointerEvents:
                                                          isEditDetails
                                                            ? "auto"
                                                            : "none",
                                                      }}
                                                      InputProps={{
                                                        sx: {
                                                          color:
                                                            theme.palette
                                                              .primary
                                                              .contrastText,
                                                          fontWeight:
                                                            theme.typography
                                                              .fontWeightMedium,
                                                        },
                                                        endAdornment: (
                                                          <InputAdornment position="end">
                                                            <CalendarMonthIcon />
                                                          </InputAdornment>
                                                        ),
                                                      }}
                                                    />
                                                  )}
                                                />
                                              </DateTimePickerTheme>
                                            </Grid>
                                            <Grid item xs={6} md={2.5}>
                                              <Typography
                                                color="textSecondary"
                                                gutterBottom
                                                variant="h6"
                                                fontWeight={
                                                  theme.typography
                                                    .fontWeightMedium
                                                }
                                              >
                                                {t("textFieldLables.startTime")}
                                              </Typography>
                                              <DateTimePickerTheme>
                                                <MobileTimePicker
                                                  label={t(`labels.startTime`)}
                                                  inputFormat={timeFormat}
                                                  onChange={(e) => {
                                                    const startDate = moment(
                                                      getValues(`StartDate`)
                                                    );
                                                    const endDate = moment(
                                                      getValues(`EndDate`)
                                                    );
                                                    const endTime = !!getValues(
                                                      `EndTime`
                                                    )
                                                      ? moment(
                                                          getValues(`EndTime`)
                                                        )
                                                      : moment();
                                                    const startTime =
                                                      moment(e).format(
                                                        "YYYY-MM-DDTHH:mm"
                                                      );

                                                    const sameDate =
                                                      startDate.isSame(
                                                        endDate,
                                                        "day"
                                                      );

                                                    const startTimeBeforeEndTime =
                                                      moment(
                                                        startTime
                                                      ).isSameOrBefore(
                                                        endTime,
                                                        "hour"
                                                      );
                                                    if (!e) {
                                                      toast.info(
                                                        t(
                                                          "toasts.startTimeCanNotBeEmpty"
                                                        )
                                                      );
                                                    } else if (
                                                      sameDate &&
                                                      !startTimeBeforeEndTime
                                                    ) {
                                                      toast.info(
                                                        t(
                                                          "toasts.startTimeCannotBeAfterEndTime"
                                                        )
                                                      );
                                                    } else {
                                                      setValue(
                                                        `StartTime`,
                                                        moment(e).format(
                                                          "YYYY-MM-DDTHH:mm"
                                                        )
                                                      );
                                                    }
                                                  }}
                                                  ampm={false}
                                                  value={getValues(`StartTime`)}
                                                  componentsProps={{
                                                    actionBar: {
                                                      actions: [
                                                        "clear",
                                                        "cancel",
                                                        "accept",
                                                      ],
                                                    },
                                                  }}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      fullWidth
                                                      size="small"
                                                      {...params}
                                                      name={`StartTime`}
                                                      // label="Enter Time"
                                                      variant={"outlined"}
                                                      error={!!errors.StartTime}
                                                      helperText={
                                                        errors.StartTime
                                                          ?.message
                                                      }
                                                      sx={{
                                                        pointerEvents:
                                                          isEditDetails
                                                            ? "auto"
                                                            : "none",
                                                      }}
                                                      InputProps={{
                                                        sx: {
                                                          color:
                                                            theme.palette
                                                              .primary
                                                              .contrastText,
                                                          fontWeight:
                                                            theme.typography
                                                              .fontWeightMedium,
                                                        },
                                                        endAdornment: (
                                                          <InputAdornment position="end">
                                                            <ScheduleIcon />
                                                          </InputAdornment>
                                                        ),
                                                      }}
                                                    />
                                                  )}
                                                />
                                              </DateTimePickerTheme>
                                            </Grid>
                                            <Grid item xs={6} md={2.5}>
                                              <Typography
                                                color="textSecondary"
                                                gutterBottom
                                                variant="h6"
                                                fontWeight={
                                                  theme.typography
                                                    .fontWeightMedium
                                                }
                                              >
                                                {t("textFieldLables.endTime")}
                                              </Typography>
                                              <DateTimePickerTheme>
                                                <MobileTimePicker
                                                  label={t(`labels.endTime`)}
                                                  inputFormat={timeFormat}
                                                  onChange={(e) => {
                                                    const startDate = moment(
                                                      getValues(`StartDate`)
                                                    );
                                                    const endDate = moment(
                                                      getValues(`EndDate`)
                                                    );
                                                    const startTime = moment(
                                                      getValues(`StartTime`)
                                                    );
                                                    const endTime =
                                                      moment(e).format(
                                                        "YYYY-MM-DDTHH:mm"
                                                      );

                                                    const sameDate =
                                                      startDate.isSame(
                                                        endDate,
                                                        "day"
                                                      );
                                                    const startTimeBeforeEndTime =
                                                      startTime.isSameOrBefore(
                                                        endTime,
                                                        "hour"
                                                      );

                                                    if (!e) {
                                                      toast.info(
                                                        t(
                                                          "toasts.endTimeCanNotBeEmpty"
                                                        )
                                                      );
                                                    } else if (
                                                      sameDate &&
                                                      !startTimeBeforeEndTime
                                                    ) {
                                                      toast.info(
                                                        t(
                                                          "toasts.endTimeCannotBeBeforeStartTime"
                                                        )
                                                      );
                                                    } else {
                                                      setValue(
                                                        `EndTime`,
                                                        moment(e).format(
                                                          "YYYY-MM-DDTHH:mm"
                                                        )
                                                      );
                                                    }
                                                  }}
                                                  ampm={false}
                                                  value={getValues(`EndTime`)}
                                                  componentsProps={{
                                                    actionBar: {
                                                      actions: [
                                                        "clear",
                                                        "cancel",
                                                        "accept",
                                                      ],
                                                    },
                                                  }}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      fullWidth
                                                      size="small"
                                                      {...params}
                                                      name={`EndTime`}
                                                      // label="Enter Time"
                                                      variant={"outlined"}
                                                      error={!!errors.EndTime}
                                                      helperText={
                                                        errors.EndTime?.message
                                                      }
                                                      sx={{
                                                        pointerEvents:
                                                          isEditDetails
                                                            ? "auto"
                                                            : "none",
                                                      }}
                                                      InputProps={{
                                                        sx: {
                                                          color:
                                                            theme.palette
                                                              .primary
                                                              .contrastText,
                                                          fontWeight:
                                                            theme.typography
                                                              .fontWeightMedium,
                                                        },
                                                        endAdornment: (
                                                          <InputAdornment position="end">
                                                            <ScheduleIcon />
                                                          </InputAdornment>
                                                        ),
                                                      }}
                                                    />
                                                  )}
                                                />
                                              </DateTimePickerTheme>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Grid>
                                      <Grid item md={12} lg={12} mt={1}>
                                        <>
                                          <Grid
                                            container
                                            item
                                            xs={12}
                                            mt={1}
                                            alignItems="center"
                                          >
                                            <Grid item xs={4}>
                                              <Typography
                                                color="textSecondary"
                                                gutterBottom
                                                variant="h4"
                                                fontWeight={
                                                  theme.typography
                                                    .fontWeightBold
                                                }
                                              >
                                                {t(
                                                  "subHeadings.assignedEmployees"
                                                )}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                              <Stack
                                                direction={"row"}
                                                justifyContent="flex-end"
                                                alignItems="center"
                                                mr={2}
                                              >
                                                {isEditDetails && (
                                                  <>
                                                    <IconButton
                                                      variant="contained"
                                                      sx={{
                                                        color:
                                                          theme.palette.primary
                                                            .main,
                                                        "&:hover": {
                                                          backgroundColor:
                                                            theme.palette
                                                              .primary.main,
                                                          color: "white",
                                                        },
                                                      }}
                                                      onClick={() =>
                                                        addEmployeeRecord()
                                                      }
                                                    >
                                                      <AddSharpIcon />
                                                    </IconButton>
                                                    <Typography
                                                      color="textSecondary"
                                                      variant="h4"
                                                      fontWeight={
                                                        theme.typography
                                                          .fontWeightBold
                                                      }
                                                      sx={{
                                                        // marginTop: "12px",
                                                        marginLeft: "5px",
                                                        marginRight: "10px",
                                                      }}
                                                    >
                                                      {t(
                                                        "buttons.addEmployees"
                                                      )}
                                                    </Typography>
                                                    <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                          name="IsFilterBySkillSet"
                                                          {...register(
                                                            "IsFilterBySkillSet",
                                                            {
                                                              onChange: (e) => {
                                                                setValue(
                                                                  `IsFilterBySkillSet`,
                                                                  e.target
                                                                    .checked
                                                                );

                                                                // Employees: [],
                                                              },
                                                            }
                                                          )}
                                                          value={getValues(
                                                            `IsFilterBySkillSet`
                                                          )}
                                                        />
                                                      }
                                                      label={
                                                        <Typography
                                                          variant="h4"
                                                          sx={{
                                                            fontWeight:
                                                              theme.typography
                                                                .fontWeightBold,
                                                          }}
                                                        >
                                                          {t(
                                                            "textFieldLables.filterBySkillSet"
                                                          )}
                                                        </Typography>
                                                      }
                                                    />
                                                  </>
                                                )}
                                              </Stack>
                                            </Grid>
                                          </Grid>
                                          <Box
                                            sx={{
                                              border: "1px solid #CACACA",
                                              p: 3,
                                              borderRadius: 3,
                                              minWidth: 350,
                                              maxHeight: isDownMd ? 350 : 175,
                                              overflowY: "auto",
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <Grid container spacing={0}>
                                              <Grid item container xs={12}>
                                                {getValues("Employees")?.map(
                                                  (employee, index) =>
                                                    !isCrossedOut[index] && (
                                                      <React.Fragment
                                                        key={
                                                          employee?.EmployeeId
                                                        }
                                                      >
                                                        <Grid item xs={6}>
                                                          <Stack direction="column">
                                                            {Math.ceil(
                                                              getValues(
                                                                "Employees"
                                                              ).length / 2
                                                            ) >= index ? (
                                                              <Grid
                                                                item
                                                                xs={12}
                                                              >
                                                                <Stack direction="row">
                                                                  <Tooltip
                                                                    title={
                                                                      <Typography variant="body2">
                                                                        <LocalPhoneOutlinedIcon fontSize="medium" />
                                                                        {getValues(
                                                                          `Employees[${index}].Phone`
                                                                        )}
                                                                      </Typography>
                                                                    }
                                                                    placement="right"
                                                                  >
                                                                    <Paper
                                                                      elevation={
                                                                        3
                                                                      }
                                                                      sx={{
                                                                        p: 1,
                                                                        borderRadius: 3,
                                                                        boxShadow:
                                                                          "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                                                        display:
                                                                          "flex",
                                                                        marginBottom:
                                                                          "10px",
                                                                        alignItems:
                                                                          "center",
                                                                        width:
                                                                          "90%",
                                                                        textDecoration:
                                                                          isCrossedOut[
                                                                            index
                                                                          ]
                                                                            ? "line-through red"
                                                                            : "none",
                                                                      }}
                                                                      onMouseOver={() =>
                                                                        setIsHovering(
                                                                          true
                                                                        )
                                                                      }
                                                                      onMouseOut={() =>
                                                                        setIsHovering(
                                                                          false
                                                                        )
                                                                      }
                                                                    >
                                                                      <BadgeIcon fontSize="medium" />
                                                                      <Typography
                                                                        color="textSecondary"
                                                                        gutterBottom
                                                                        variant="h6"
                                                                        margin={
                                                                          "5px 5px 0px 10px"
                                                                        }
                                                                        fontWeight={
                                                                          theme
                                                                            .typography
                                                                            .fontWeightMedium
                                                                        }
                                                                      >
                                                                        {getValues(
                                                                          `Employees[${index}].EmployeeName`
                                                                        )}
                                                                      </Typography>
                                                                    </Paper>
                                                                  </Tooltip>
                                                                  {isEditDetails && (
                                                                    <>
                                                                      <IconButton
                                                                        variant="contained"
                                                                        sx={{
                                                                          "&:hover":
                                                                            {
                                                                              backgroundColor:
                                                                                "none",
                                                                            },
                                                                          color:
                                                                            theme
                                                                              .palette
                                                                              .primary
                                                                              .main,
                                                                        }}
                                                                        onClick={() =>
                                                                          toggleCrossedOut(
                                                                            index
                                                                          )
                                                                        }
                                                                      >
                                                                        <ClearIcon />
                                                                      </IconButton>

                                                                      {isCrossedOut[
                                                                        index
                                                                      ] && (
                                                                        <Typography
                                                                          color={
                                                                            theme
                                                                              .palette
                                                                              .common
                                                                              .danger
                                                                          }
                                                                          gutterBottom
                                                                          variant="h7"
                                                                          margin={
                                                                            "15px 5px 0px 10px"
                                                                          }
                                                                          fontWeight={
                                                                            theme
                                                                              .typography
                                                                              .fontWeightMedium
                                                                          }
                                                                        >
                                                                          {t(
                                                                            "labels.removed"
                                                                          )}
                                                                        </Typography>
                                                                      )}
                                                                    </>
                                                                  )}
                                                                </Stack>
                                                              </Grid>
                                                            ) : (
                                                              <Grid
                                                                item
                                                                xs={12}
                                                              >
                                                                <Stack direction="row">
                                                                  <Tooltip
                                                                    title={
                                                                      <Typography variant="body2">
                                                                        <LocalPhoneOutlinedIcon fontSize="medium" />
                                                                        {getValues(
                                                                          `Employees[${index}].Phone`
                                                                        )}
                                                                      </Typography>
                                                                    }
                                                                    placement="right"
                                                                  >
                                                                    <Paper
                                                                      elevation={
                                                                        3
                                                                      }
                                                                      sx={{
                                                                        p: 1,
                                                                        borderRadius: 3,
                                                                        boxShadow:
                                                                          "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                                                        display:
                                                                          "flex",
                                                                        marginBottom:
                                                                          "10px",
                                                                        alignItems:
                                                                          "center",
                                                                        width:
                                                                          "90%",
                                                                        textDecoration:
                                                                          isCrossedOut[
                                                                            index
                                                                          ]
                                                                            ? "line-through red"
                                                                            : "none",
                                                                      }}
                                                                      onMouseOver={() =>
                                                                        setIsHovering(
                                                                          true
                                                                        )
                                                                      }
                                                                      onMouseOut={() =>
                                                                        setIsHovering(
                                                                          false
                                                                        )
                                                                      }
                                                                    >
                                                                      <BadgeIcon fontSize="medium" />
                                                                      <Typography
                                                                        color="textSecondary"
                                                                        gutterBottom
                                                                        variant="h6"
                                                                        margin={
                                                                          "5px 5px 0px 10px"
                                                                        }
                                                                        fontWeight={
                                                                          theme
                                                                            .typography
                                                                            .fontWeightMedium
                                                                        }
                                                                      >
                                                                        {getValues(
                                                                          `Employees[${index}].EmployeeName`
                                                                        )}
                                                                      </Typography>
                                                                    </Paper>
                                                                  </Tooltip>

                                                                  {isEditDetails && (
                                                                    <>
                                                                      <IconButton
                                                                        variant="contained"
                                                                        sx={{
                                                                          "&:hover":
                                                                            {
                                                                              backgroundColor:
                                                                                "none",
                                                                            },
                                                                          color:
                                                                            theme
                                                                              .palette
                                                                              .primary
                                                                              .main,
                                                                        }}
                                                                        onClick={() =>
                                                                          toggleCrossedOut(
                                                                            index
                                                                          )
                                                                        }
                                                                      >
                                                                        <ClearIcon />
                                                                      </IconButton>

                                                                      {isCrossedOut[
                                                                        index
                                                                      ] && (
                                                                        <Typography
                                                                          color={
                                                                            theme
                                                                              .palette
                                                                              .common
                                                                              .danger
                                                                          }
                                                                          gutterBottom
                                                                          variant="h7"
                                                                          margin={
                                                                            "15px 5px 0px 10px"
                                                                          }
                                                                          fontWeight={
                                                                            theme
                                                                              .typography
                                                                              .fontWeightMedium
                                                                          }
                                                                        >
                                                                          {t(
                                                                            "labels.removed"
                                                                          )}
                                                                        </Typography>
                                                                      )}
                                                                    </>
                                                                  )}
                                                                </Stack>
                                                              </Grid>
                                                            )}
                                                          </Stack>
                                                        </Grid>
                                                      </React.Fragment>
                                                    )
                                                )}
                                              </Grid>
                                              {isEditDetails && (
                                                <Grid item xs={12}>
                                                  {getValues(
                                                    `NewEmployees`
                                                  )?.map((item, index) =>
                                                    !item.IsDelete ? (
                                                      <>
                                                        <Stack
                                                          direction={"row"}
                                                        >
                                                          <Grid
                                                            item
                                                            xs={10}
                                                            mt={2}
                                                          >
                                                            <FormControl
                                                              fullWidth
                                                            >
                                                              <InputLabel
                                                                id="demo-simple-select-required-label"
                                                                size="small"
                                                              >
                                                                {t(
                                                                  "labels.employee"
                                                                )}
                                                              </InputLabel>
                                                              <Select
                                                                name={`NewEmployees[${index}].EmployeeId`}
                                                                {...register(
                                                                  `NewEmployees[${index}].EmployeeId`
                                                                )}
                                                                label="Select Employee"
                                                                variant="outlined"
                                                                size="small"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  setValue(
                                                                    `NewEmployees[${index}].EmployeeId`,
                                                                    e.target
                                                                      .value
                                                                  );
                                                                }}
                                                              >
                                                                {watch(
                                                                  `StartDate`
                                                                ) === "" ||
                                                                watch(
                                                                  `StartTime`
                                                                ) === "" ||
                                                                watch(
                                                                  `EndDate`
                                                                ) === "" ||
                                                                watch(
                                                                  `EndTime`
                                                                ) === "" ? (
                                                                  <MenuItem
                                                                    key={
                                                                      "employeeEmpty-id"
                                                                    }
                                                                    value={null}
                                                                    disabled
                                                                  >
                                                                    {t(
                                                                      "menuItem.jobScheduleNoRecords"
                                                                    )}
                                                                  </MenuItem>
                                                                ) : watch(
                                                                    "IsFilterBySkillSet"
                                                                  ) &&
                                                                  ([
                                                                    ...getValues(
                                                                      `ScheduleLineItems`
                                                                    ),
                                                                    ...getValues(
                                                                      "NewLineItems"
                                                                    ),
                                                                  ]
                                                                    ?.filter(
                                                                      (obj) =>
                                                                        parseInt(
                                                                          obj?.ServiceId
                                                                        ) > 0
                                                                    )
                                                                    ?.map(
                                                                      (item) =>
                                                                        String(
                                                                          item.ServiceId
                                                                        )
                                                                    ) ===
                                                                    null ||
                                                                    [
                                                                      ...getValues(
                                                                        `ScheduleLineItems`
                                                                      ),
                                                                      ...getValues(
                                                                        "NewLineItems"
                                                                      ),
                                                                    ]
                                                                      ?.filter(
                                                                        (obj) =>
                                                                          parseInt(
                                                                            obj?.ServiceId
                                                                          ) > 0
                                                                      )
                                                                      ?.map(
                                                                        (
                                                                          item
                                                                        ) =>
                                                                          String(
                                                                            item.ServiceId
                                                                          )
                                                                      )
                                                                      ?.length ===
                                                                      0) ? (
                                                                  <MenuItem
                                                                    key={
                                                                      "employeeEmpty-id"
                                                                    }
                                                                    value={null}
                                                                    disabled
                                                                  >
                                                                    {t(
                                                                      "menuItem.filterJobServiceNoRecords"
                                                                    )}
                                                                  </MenuItem>
                                                                ) : employeeList ===
                                                                    null ||
                                                                  employeeList?.length ===
                                                                    0 ? (
                                                                  <MenuItem
                                                                    key={
                                                                      "employeeEmpty-id"
                                                                    }
                                                                    value={null}
                                                                    disabled
                                                                  >
                                                                    {t(
                                                                      "menuItem.noRecords"
                                                                    )}
                                                                  </MenuItem>
                                                                ) : (
                                                                  employeeList?.map(
                                                                    (
                                                                      option
                                                                    ) => (
                                                                      <MenuItem
                                                                        key={
                                                                          option.EmployeeId
                                                                        }
                                                                        // disabled={employeeIds.includes(
                                                                        //   option.EmployeeId
                                                                        // )}
                                                                        value={
                                                                          option.EmployeeId
                                                                        }
                                                                        disabled={
                                                                          !!(
                                                                            getValues(
                                                                              `NewEmployees`
                                                                            )?.find(
                                                                              (
                                                                                p
                                                                              ) =>
                                                                                !p?.IsDelete &&
                                                                                p?.EmployeeId ===
                                                                                  option?.EmployeeId
                                                                            ) ||
                                                                            getValues(
                                                                              "Employees"
                                                                            )?.find(
                                                                              (
                                                                                p
                                                                              ) =>
                                                                                !p.IsDelete &&
                                                                                p.EmployeeId ===
                                                                                  option.EmployeeId
                                                                            )
                                                                          )
                                                                        }
                                                                        sx={{
                                                                          display:
                                                                            "flex",
                                                                          justifyContent:
                                                                            "space-between",
                                                                        }}
                                                                      >
                                                                        {
                                                                          option.EmployeeName
                                                                        }
                                                                        <span>
                                                                          {` #`}
                                                                          {
                                                                            option.EmployeeId
                                                                          }
                                                                        </span>
                                                                      </MenuItem>
                                                                    )
                                                                  )
                                                                )}
                                                              </Select>
                                                            </FormControl>
                                                          </Grid>
                                                          <Grid
                                                            item
                                                            xs={2}
                                                            mt={2}
                                                          >
                                                            <IconButton
                                                              variant="contained"
                                                              sx={{
                                                                color:
                                                                  theme.palette
                                                                    .primary
                                                                    .main,
                                                              }}
                                                              onClick={() =>
                                                                handleRemoveEmployee(
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <ClearIcon />
                                                            </IconButton>
                                                          </Grid>
                                                        </Stack>
                                                      </>
                                                    ) : null
                                                  )}
                                                </Grid>
                                              )}
                                            </Grid>
                                          </Box>
                                        </>
                                      </Grid>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} mt={1}>
                                    <Typography
                                      color="textSecondary"
                                      gutterBottom
                                      variant="h6"
                                      fontWeight={
                                        theme.typography.fontWeightMedium
                                      }
                                    >
                                      {t("textFieldLables.comments")}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                      // required
                                      name="Comment"
                                      {...register("Comment")}
                                      placeholder={t(
                                        "messages.enterDescription"
                                      )}
                                      fullWidth
                                      type="text"
                                      variant={
                                        isEditDetails ? "outlined" : "standard"
                                      }
                                      size="small"
                                      multiline
                                      rows={3}
                                      error={!!errors.Comment}
                                      helperText={errors.Comment?.message}
                                      disabled={!isEditDetails}
                                      InputProps={{
                                        readOnly: !isEditDetails,
                                        disableUnderline: true,
                                        style: {
                                          color:
                                            theme.palette.primary.contrastText,
                                          fontWeight:
                                            theme.typography.fontWeightMedium,
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>

                                <Grid item md={12} lg={6}></Grid>
                              </Stack>
                            </Grid>
                            {/* <Grid item xs={12} lg={7.8} mt={1} mb={1}>
                              <Divider />
                            </Grid> */}

                            {!isEditDetails && (
                              <Grid item container spacing={2}>
                                {/* <Grid item xs={12} lg={7.8}>
                                  <Grid item xs={12} md={4}>
                                    <Tabs
                                      value={valuess}
                                      onChange={handleTabChange}
                                      variant="fullWidth"
                                    >
                                      <Tab
                                        label={
                                          <Typography
                                            color="textSecondary"
                                            variant="h5"
                                            sx={{
                                              fontWeight:
                                                theme.typography.fontWeightBold,
                                              textTransform: "uppercase",
                                            }}
                                          >
                                            {t("labels.checkIn")}
                                          </Typography>
                                        }
                                        {...a11yPropsType(0)}
                                        onClick={() => {
                                          setValuess(0);
                                        }}
                                        sx={{
                                          boxShadow: 2,
                                          width: "100%",
                                          background:
                                            valuess === 0 ? "#F4A540" : "",
                                          border: "1px solid #CACACA",
                                        }}
                                      />
                                      <Tab
                                        label={
                                          <Typography
                                            color="textSecondary"
                                            variant="h5"
                                            sx={{
                                              fontWeight:
                                                theme.typography.fontWeightBold,
                                              textTransform: "uppercase",
                                            }}
                                          >
                                            {t("labels.checkOut")}
                                          </Typography>
                                        }
                                        {...a11yPropsType(1)}
                                        onClick={() => {
                                          setValuess(1);
                                        }}
                                        sx={{
                                          boxShadow: 2,
                                          width: "100%",
                                          background:
                                            valuess === 1 ? "#F4A540" : "",
                                          border: "1px solid #CACACA",
                                        }}
                                      />
                                    </Tabs>
                                  </Grid>
                                </Grid> */}
                                <Grid xs={12} lg={7.7} mt={4} mb={2} ml={2}>
                                  <Accordion
                                    sx={{ borderRadius: "10px" }}
                                    expanded={expanded2}
                                    onChange={handleAccordionChange2}
                                  >
                                    <AccordionSummary>
                                      <Typography
                                        color="textSecondary"
                                        variant="h6"
                                        fontWeight="medium"
                                      >
                                        {t("headings.documents")}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Grid container spacing={3}>
                                        <Grid item md={6} xs={12} lg={12}>
                                          <FileUpload
                                            Directory={`Job/JobId_${jobId}/RecurringJob_Out/EventId_${eventId}`}
                                            isFirst={false}
                                            FieldId={jobId}
                                            FieldTypeId={VISIT_IN_DOCUMENT_ID}
                                            FieldName="Job"
                                            Title={t("headings.jobInDocument")}
                                            IsEditable={
                                              scheduleData?.IsEditable
                                            }
                                          />
                                        </Grid>

                                        <Grid item md={6} xs={12} lg={12}>
                                          <FileUpload
                                            Directory={`Job/JobId_${jobId}/RecurringJob_In/EventId_${eventId}`}
                                            isFirst={false}
                                            FieldId={jobId}
                                            FieldTypeId={VISIT_OUT_DOCUMENT_ID}
                                            FieldName="Job"
                                            Title={t("headings.jobOutDocument")}
                                            IsEditable={
                                              scheduleData?.IsEditable
                                            }
                                          />
                                        </Grid>
                                      </Grid>
                                    </AccordionDetails>
                                  </Accordion>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          </form>
        </>
      )}
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    services: state.service.services,
    products: state.product.products,
    taxes: state.tax.taxes,
    employees: state.employee.employees,
    types: state.type.types,
    packages: state.package.packages,
  };
};

export default connect(mapStateToProps, null)(ViewEvent);
