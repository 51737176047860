import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PrintIcon from "@mui/icons-material/Print";
import {
  Box,
  ButtonGroup,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { AuthContext } from "src/auth/AuthProvider";
import {
  fDateShort,
  fDateShortTime,
  formatCurrency,
  formatText,
} from "src/components/Controls/formatUtils";
import { highlightKeyword } from "src/components/Controls/Utils";
import CustomPagination from "./CustomPagination";
import StatusChips from "./StatusChips";
import TableHeader from "./TableHeader";

export default function DataTable({
  columns,
  rows,
  rowsPerPageOptions,
  isLoading,
  tableHeading,
  pagination = true,
  page = null,
  setPage = null,
  rowsPerPage,
  setRowsPerPage,
  rowData = {},
  rowLastData = {},
  rowDataRest = { align: "right" },
  availablePriorities = [],
  handleTypeChange,
  activationList = [],
  handleActivationChange,
  labelAlignment,
  isArrow,
  setIsArrow,
  isCommonView = false,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const auth = useContext(AuthContext);
  const StyledRouterLink = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.default,
    fontWeight: 500,
  }));
  const currency = auth.getProfile().CurrencySymbol;
  const dateFormat = auth.getProfile().DateFormat;
  const timeFormat = auth.getProfile().TimeFormat;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [searchParams] = useSearchParams();
  const nameMaxCharacters = 25;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (!setPage) return;
    if (rows?.length < 1) {
      setPage(0);
    }
  }, [rows]);

  return (
    <React.Fragment>
      {!!tableHeading && (
        <React.Fragment>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ p: 2 }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: "1.2rem",
                textAlign: "center",
                justifyContent: "center",
                "@media (min-width:1536px)": {
                  fontSize: "1.4rem",
                },
              }}
            >
              {tableHeading}
            </Typography>
            <ButtonGroup>
              <IconButton
                sx={{ color: theme.palette.primary.inheritTextColor }}
                // onClick={handleClickExportCsv}
                component="span"
              >
                <FileDownloadIcon />
              </IconButton>
              <IconButton
                sx={{ color: theme.palette.primary.inheritTextColor }}
                onClick={() => {
                  window.print();
                }}
                component="span"
              >
                <PrintIcon />
              </IconButton>
            </ButtonGroup>
          </Stack>
        </React.Fragment>
      )}
      <>
        <Box sx={{ overflow: "auto" }}>
          <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
            <Table
              sx={{
                minWidth: isUpMd ? 650 : "",
                borderCollapse: "separate",
                borderSpacing: "0px 0px 10px 0px",
                border: "0px",
              }}
              aria-label="a dense table"
              className="tableBlur"
            >
              {isUpMd ? (
                <React.Fragment>
                  <TableHeader
                    columns={columns}
                    isArrow={isArrow}
                    setIsArrow={setIsArrow}
                  />

                  <TableBody
                    sx={{
                      margin: "10px 0",
                      padding: "10px",
                      border: "1px solid #ccc", // Add a border to the table container
                      borderRadius: "10px",
                    }}
                  >
                    {isLoading ? (
                      <TableRow>
                        <TableCell align="center" colSpan={columns.length - 1}>
                          <CircularProgress
                            color="primary"
                            disableShrink
                            size={30}
                          />
                        </TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {Object.keys(rowData).length > 0 ? (
                          <>
                            <TableRow
                              sx={{
                                background: "#D9D9D9",
                                borderRadius: "10px",
                                border: "1px solid #CACACA",
                              }}
                            >
                              {columns?.map((column, dataIndex) => (
                                <TableCell
                                  key={dataIndex}
                                  // {...rowDataRest}
                                  padding={"none"}
                                  sx={{
                                    p: isCommonView
                                      ? "0px 16px 0px 16px !important"
                                      : null,
                                  }}
                                  align={dataIndex === 0 ? "left" : "right"}
                                >
                                  {rowData[column.name]}
                                </TableCell>
                              ))}
                            </TableRow>
                            <TableRow
                              sx={{
                                height: "10px",
                              }}
                            ></TableRow>
                          </>
                        ) : null}
                        {rows?.length > 0 ? (
                          rows?.map((row, index) => (
                            <React.Fragment key={index}>
                              <TableRow
                                sx={{
                                  // "&:last-child td, &:last-child th": { border: 0 },
                                  background:
                                    theme.palette.mode === "default"
                                      ? theme.palette.background.darkBackground
                                      : theme.palette.background.background,

                                  borderRadius: "10px",
                                  border: "1px solid #CACACA",
                                }}
                              >
                                {columns?.map((column, dataIndex) => (
                                  <TableCell
                                    className={
                                      column.fieldRenderType === "status"
                                        ? "centered-cell"
                                        : ""
                                    }
                                    //
                                    key={dataIndex}
                                    align={
                                      !!column.bodyDataAlign
                                        ? column.bodyDataAlign
                                        : "left"
                                    }
                                    sx={{
                                      p: isCommonView
                                        ? "0px 16px 0px 16px !important"
                                        : null,
                                    }}
                                  >
                                    {!!column.fieldRenderType ? (
                                      column.fieldRenderType === "chip" ? (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            textAlign: "center",
                                          }}
                                        >
                                          <Chip
                                            label={
                                              row[column.name] &&
                                              formatText(row[column.name])
                                            }
                                            variant="outlined"
                                            sx={{
                                              width: "100%",
                                              border: "none",
                                              backgroundColor:
                                                row[column.name] === "New"
                                                  ? theme.palette.statusChips
                                                      .new.background
                                                  : row[column.name] === "Close"
                                                  ? theme.palette.statusChips
                                                      .closed.background
                                                  : theme.palette.statusChips
                                                      .ongoing.background,
                                              color:
                                                row[column.name] === "New"
                                                  ? theme.palette.statusChips
                                                      .new.color
                                                  : row[column.name] === "Close"
                                                  ? theme.palette.statusChips
                                                      .closed.color
                                                  : theme.palette.statusChips
                                                      .ongoing.color,
                                            }}
                                          />
                                        </Box>
                                      ) : column.fieldRenderType ===
                                        "select" ? (
                                        <Select
                                          sx={{ width: 100 }}
                                          value={row[column.name]}
                                          onChange={(e) =>
                                            handleTypeChange(e, row)
                                          }
                                          displayEmpty
                                        >
                                          <MenuItem value=""></MenuItem>
                                          {availablePriorities?.map(
                                            (option) => (
                                              <MenuItem
                                                key={option.TypeId}
                                                value={option.TypeId}
                                              >
                                                {option.TypeName}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                      ) : column.fieldRenderType ===
                                        "selectStatus" ? (
                                        <Select
                                          sx={{
                                            minWidth: "100%",
                                            backgroundColor: row[column.name]
                                              ? theme.palette.statusChips.active
                                                  .background
                                              : theme.palette.statusChips
                                                  .inactive.background,
                                          }}
                                          value={row[column.name]}
                                          onChange={(e) =>
                                            handleActivationChange(e, row)
                                          }
                                          displayEmpty
                                          size="small"
                                          disabled={
                                            !(activationList?.length > 0)
                                          }
                                        >
                                          {activationList?.map((option) => (
                                            <MenuItem
                                              key={option.IsActive}
                                              value={option.IsActive}
                                            >
                                              <Typography
                                                variant="h5"
                                                fontWeight={
                                                  theme.typography
                                                    .fontWeightMedium
                                                }
                                                textAlign="center"
                                                sx={{
                                                  color: option.IsActive
                                                    ? theme.palette.statusChips
                                                        .active.color
                                                    : theme.palette.statusChips
                                                        .inactive.color,
                                                }}
                                              >
                                                {formatText(option.TypeName)}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      ) : column.fieldRenderType ===
                                        "status" ? (
                                        <div className="centered-content">
                                          <Chip
                                            label={formatText(
                                              row[column.name]
                                                ? " Active "
                                                : "Inactive"
                                            )}
                                            variant="outlined"
                                            sx={{
                                              width: "90%",
                                              border: "none",
                                              textAlign: "center",
                                              backgroundColor: row[column.name]
                                                ? theme.palette.statusChips
                                                    .active.background
                                                : theme.palette.statusChips
                                                    .inactive.background,
                                              color: row[column.name]
                                                ? theme.palette.statusChips
                                                    .active.color
                                                : theme.palette.statusChips
                                                    .inactive.color,
                                            }}
                                          />
                                        </div>
                                      ) : column.fieldRenderType === "date" ? (
                                        !!row[column.name] ? (
                                          fDateShort(
                                            row[column.name],
                                            dateFormat
                                          )
                                        ) : (
                                          "N/A"
                                        )
                                      ) : column.fieldRenderType === "type" ? (
                                        <StatusChips
                                          status={row[column.name]}
                                        />
                                      ) : column.fieldRenderType ===
                                        "currency" ? (
                                        <Box sx={{ whiteSpace: "nowrap" }}>
                                          {formatCurrency(
                                            currency,
                                            row[column.name]
                                          )}
                                        </Box>
                                      ) : column.fieldRenderType ===
                                        "number" ? (
                                        row[column.name] || 0
                                      ) : column.fieldRenderType ===
                                        "dateTime" ? (
                                        fDateShortTime(
                                          row[column.name],
                                          dateFormat + "T" + timeFormat
                                        )
                                      ) : column.fieldRenderType ===
                                        "linkName" ? (
                                        <StyledRouterLink
                                          to={
                                            typeof column.toLink === "string"
                                              ? `${column.toLink}/${
                                                  row[column.idName]
                                                }`
                                              : column.toLink(row, index)
                                          }
                                          state={column?.toLinkState}
                                          underline="hover"
                                          sx={{ textDecoration: "none" }}
                                        >
                                          {row[column.secondName].length >
                                          nameMaxCharacters ? (
                                            <div>
                                              <Tooltip
                                                title={row[column.secondName]}
                                                placement="top"
                                              >
                                                <Box>
                                                  <Typography
                                                    fontWeight={
                                                      theme.typography
                                                        .fontWeightBold
                                                    }
                                                    sx={
                                                      theme.typography
                                                        .tableCName
                                                    }
                                                  >
                                                    {row[column.secondName]
                                                      .length >
                                                    nameMaxCharacters
                                                      ? `${highlightKeyword(
                                                          row[
                                                            column.secondName
                                                          ] || "-",
                                                          searchParams.get(
                                                            "Parameter"
                                                          )
                                                        ).slice(
                                                          0,
                                                          nameMaxCharacters
                                                        )}...`
                                                      : highlightKeyword(
                                                          row[
                                                            column.secondName
                                                          ] || "-",
                                                          searchParams.get(
                                                            "Parameter"
                                                          )
                                                        )}
                                                  </Typography>
                                                </Box>
                                              </Tooltip>
                                            </div>
                                          ) : (
                                            <Box>
                                              <Typography
                                                fontWeight={
                                                  theme.typography
                                                    .fontWeightBold
                                                }
                                                sx={theme.typography.tableCName}
                                              >
                                                {highlightKeyword(
                                                  row[column.secondName] || "-",
                                                  searchParams.get("Parameter")
                                                )}
                                              </Typography>
                                            </Box>
                                          )}
                                        </StyledRouterLink>
                                      ) : column.fieldRenderType === "time" ? (
                                        moment(
                                          row[column.name].Hours +
                                            ":" +
                                            row[column.name].Minutes,
                                          "hh:mm a"
                                        ).format(timeFormat)
                                      ) : (
                                        ""
                                      )
                                    ) : !!column.render ? (
                                      column.render(row, index)
                                    ) : (
                                      highlightKeyword(
                                        row[column.name] || "-N/A-",
                                        searchParams.get("Parameter")
                                      )
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                              <TableRow sx={{ height: "10px" }}></TableRow>
                            </React.Fragment>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              align="center"
                              sx={{
                                color: theme.palette.secondary.inheritTextColor,
                              }}
                              colSpan={columns.length - 1}
                            >
                              {t("messages.noRecordsFound")}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ width: "0px" }}
                            ></TableCell>
                          </TableRow>
                        )}
                        {Object.keys(rowLastData).length > 0 ? (
                          <>
                            <TableRow
                              sx={{
                                background: "#D9D9D9",
                                borderRadius: "10px",
                                border: "1px solid #CACACA",
                              }}
                            >
                              {columns?.map((column, dataIndex) => (
                                <TableCell
                                  key={dataIndex}
                                  {...rowDataRest}
                                  align="left"
                                >
                                  {rowLastData[column.name]}
                                </TableCell>
                              ))}
                            </TableRow>
                            <TableRow sx={{ height: "10px" }}></TableRow>
                          </>
                        ) : null}
                      </>
                    )}
                  </TableBody>
                </React.Fragment>
              ) : (
                <TableBody sx={{ width: "100%" }}>
                  {isLoading ? (
                    <TableRow>
                      <TableCell align="left" sx={{ width: "0px" }}></TableCell>
                      <TableCell
                        align="center"
                        colSpan={columns.length - 2}
                        sx={{ borderLeft: "0px" }}
                      >
                        <CircularProgress
                          color="primary"
                          disableShrink
                          size={30}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ width: "0px", border: "0px" }}
                      ></TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {Object.keys(rowData).length > 0 ? (
                        <>
                          {columns?.map((column, dataIndex) => (
                            <TableRow
                              key={dataIndex}
                              sx={{
                                background: "#D9D9D9",
                                borderRadius: "10px",
                                border: "1px solid #CACACA",
                              }}
                            >
                              <TableCell>{column.label}</TableCell>
                              <TableCell
                                {...rowDataRest}
                                align={
                                  !!column.bodyDataAlignDownMd
                                    ? column.bodyDataAlignDownMd
                                    : column.fieldRenderType === "status"
                                    ? "center"
                                    : "left"
                                }
                              >
                                {rowData[column.name]}
                              </TableCell>
                            </TableRow>
                          ))}

                          <TableRow sx={{ height: "10px" }}></TableRow>
                        </>
                      ) : null}
                      {rows?.length > 0 ? (
                        <>
                          {rows?.map((row, index) => (
                            <React.Fragment key={index}>
                              {columns.map((column, id) => (
                                <React.Fragment key={id}>
                                  <TableRow key={id}>
                                    <TableCell>{column.label}</TableCell>
                                    <TableCell
                                      key={index}
                                      align={
                                        !!column.bodyDataAlignDownMd
                                          ? column.bodyDataAlignDownMd
                                          : column.fieldRenderType === "status"
                                          ? "center"
                                          : "left"
                                      }
                                    >
                                      {!!column.fieldRenderType ? (
                                        column.fieldRenderType === "chip" ? (
                                          <Chip
                                            label={
                                              row[column.name] &&
                                              formatText(row[column.name])
                                            }
                                            variant="outlined"
                                            sx={{
                                              width: "100%",
                                              marginLeft: "-20px",
                                              border: "none",
                                              backgroundColor:
                                                row[column.name] === "New"
                                                  ? theme.palette.statusChips
                                                      .new.background
                                                  : row[column.name] === "Close"
                                                  ? theme.palette.statusChips
                                                      .closed.background
                                                  : theme.palette.statusChips
                                                      .ongoing.background,
                                              color:
                                                row[column.name] === "New"
                                                  ? theme.palette.statusChips
                                                      .new.color
                                                  : row[column.name] === "Close"
                                                  ? theme.palette.statusChips
                                                      .closed.color
                                                  : theme.palette.statusChips
                                                      .ongoing.color,
                                            }}
                                          />
                                        ) : column.fieldRenderType ===
                                          "selectStatus" ? (
                                          <Select
                                            sx={{
                                              minWidth: "60%",
                                              margin: "5px",
                                              backgroundColor: row[column.name]
                                                ? theme.palette.statusChips
                                                    .active.background
                                                : theme.palette.statusChips
                                                    .inactive.background,
                                            }}
                                            value={row[column.name]}
                                            onChange={(e) =>
                                              handleActivationChange(e, row)
                                            }
                                            displayEmpty
                                            size="small"
                                            disabled={
                                              !(activationList?.length > 0)
                                            }
                                          >
                                            {activationList?.map((option) => (
                                              <MenuItem
                                                key={option.IsActive}
                                                value={option.IsActive}
                                              >
                                                <Typography
                                                  variant="h5"
                                                  fontWeight={
                                                    theme.typography
                                                      .fontWeightMedium
                                                  }
                                                  textAlign="center"
                                                  sx={{
                                                    color: option.IsActive
                                                      ? theme.palette
                                                          .statusChips.active
                                                          .color
                                                      : theme.palette
                                                          .statusChips.inactive
                                                          .color,
                                                  }}
                                                >
                                                  {formatText(option.TypeName)}
                                                </Typography>
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        ) : column.fieldRenderType ===
                                          "status" ? (
                                          <Chip
                                            label={formatText(
                                              row[column.name]
                                                ? "Active"
                                                : "In Active"
                                            )}
                                            variant="outlined"
                                            sx={{
                                              width: "90%",
                                              marginLeft: "-10px",
                                              border: "none",
                                              backgroundColor: row[column.name]
                                                ? theme.palette.statusChips
                                                    .active.background
                                                : theme.palette.statusChips
                                                    .inactive.background,
                                              color: row[column.name]
                                                ? theme.palette.statusChips
                                                    .active.color
                                                : theme.palette.statusChips
                                                    .inactive.color,
                                            }}
                                          />
                                        ) : column.fieldRenderType ===
                                          "select" ? (
                                          <Select
                                            sx={{ width: 100, height: 23 }}
                                            value={row[column.name]}
                                            onChange={(e) =>
                                              handleTypeChange(e, row)
                                            }
                                            displayEmpty
                                          >
                                            <MenuItem value=""></MenuItem>
                                            {availablePriorities?.map(
                                              (option) => (
                                                <MenuItem
                                                  key={option.TypeId}
                                                  value={option.TypeId}
                                                >
                                                  {option.TypeName}
                                                </MenuItem>
                                              )
                                            )}
                                          </Select>
                                        ) : column.fieldRenderType ===
                                          "date" ? (
                                          fDateShort(
                                            row[column.name],
                                            dateFormat
                                          )
                                        ) : column.fieldRenderType ===
                                          "type" ? (
                                          <StatusChips
                                            status={row[column.name]}
                                          />
                                        ) : column.fieldRenderType ===
                                          "currency" ? (
                                          <Box sx={{ whiteSpace: "nowrap" }}>
                                            {formatCurrency(
                                              currency,
                                              row[column.name]
                                            )}
                                          </Box>
                                        ) : column.fieldRenderType ===
                                          "dateTime" ? (
                                          fDateShortTime(
                                            row[column.name],
                                            dateFormat + "T" + timeFormat
                                          )
                                        ) : column.fieldRenderType ===
                                          "linkName" ? (
                                          <StyledRouterLink
                                            to={
                                              typeof column.toLink === "string"
                                                ? `${column.toLink}/${
                                                    row[column.idName]
                                                  }`
                                                : column.toLink(row, index)
                                            }
                                            underline="hover"
                                            sx={{ textDecoration: "none" }}
                                          >
                                            {row[column.secondName].length >
                                            nameMaxCharacters ? (
                                              <div>
                                                <Tooltip
                                                  title={row[column.secondName]}
                                                  placement="top"
                                                >
                                                  <Box>
                                                    <Typography
                                                      fontWeight={
                                                        theme.typography
                                                          .fontWeightBold
                                                      }
                                                      sx={
                                                        theme.typography
                                                          .tableCName
                                                      }
                                                    >
                                                      {row[column.secondName]
                                                        .length >
                                                      nameMaxCharacters
                                                        ? `${highlightKeyword(
                                                            row[
                                                              column.secondName
                                                            ] || "-",
                                                            searchParams.get(
                                                              "Parameter"
                                                            )
                                                          ).slice(
                                                            0,
                                                            nameMaxCharacters
                                                          )}...`
                                                        : highlightKeyword(
                                                            row[
                                                              column.secondName
                                                            ] || "-",
                                                            searchParams.get(
                                                              "Parameter"
                                                            )
                                                          )}
                                                    </Typography>
                                                  </Box>
                                                </Tooltip>
                                              </div>
                                            ) : (
                                              <Box>
                                                <Typography
                                                  fontWeight={
                                                    theme.typography
                                                      .fontWeightBold
                                                  }
                                                  sx={
                                                    theme.typography.tableCName
                                                  }
                                                >
                                                  {highlightKeyword(
                                                    row[column.secondName] ||
                                                      "-",
                                                    searchParams.get(
                                                      "Parameter"
                                                    )
                                                  )}
                                                </Typography>
                                              </Box>
                                            )}
                                          </StyledRouterLink>
                                        ) : column.fieldRenderType ===
                                          "time" ? (
                                          moment(
                                            row[column.name].Hours +
                                              ":" +
                                              row[column.name].Minutes,
                                            "hh:mm a"
                                          ).format(timeFormat)
                                        ) : (
                                          ""
                                        )
                                      ) : !!column.render ? (
                                        column.render(row, id)
                                      ) : row[column.name]?.length >
                                        nameMaxCharacters ? (
                                        `${highlightKeyword(
                                          row[column.name] || "-",
                                          searchParams.get("Parameter")
                                        ).slice(0, nameMaxCharacters)}...`
                                      ) : (
                                        highlightKeyword(
                                          row[column.name] || "-",
                                          searchParams.get("Parameter")
                                        )
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              ))}
                              <TableRow>
                                <TableCell
                                  align="center"
                                  sx={{ p: 0 }}
                                  colSpan={2}
                                >
                                  <Divider key={index} />
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          ))}
                          {Object.keys(rowLastData).length > 0 ? (
                            <>
                              {columns?.map((column, dataIndex) => (
                                <TableRow
                                  key={dataIndex}
                                  sx={{
                                    background: "#D9D9D9",
                                    borderRadius: "10px",
                                    border: "1px solid #CACACA",
                                  }}
                                >
                                  <TableCell>{column.label}</TableCell>
                                  <TableCell
                                    {...rowDataRest}
                                    align={
                                      !!column.bodyDataAlignDownMd
                                        ? column.bodyDataAlignDownMd
                                        : column.fieldRenderType === "status"
                                        ? "center"
                                        : "left"
                                    }
                                  >
                                    {rowLastData[column.name]}
                                  </TableCell>
                                </TableRow>
                              ))}

                              <TableRow sx={{ height: "10px" }}></TableRow>
                            </>
                          ) : null}
                        </>
                      ) : (
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{ width: "0px" }}
                          ></TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: theme.palette.secondary.inheritTextColor,
                              borderRight: "0px",
                              borderLeft: "0px",
                            }}
                            colSpan={columns.length - 1}
                          >
                            {t("messages.noRecordsFound")}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "0px" }}
                          ></TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              )}
            </Table>
          </Box>
        </Box>
        {pagination && (
          <CustomPagination
            page={page}
            setPage={setPage}
            count={
              rows?.length > 0
                ? rows[0]?.TotalRows
                  ? rows[0]?.TotalRows
                  : rows?.length
                : 0
            }
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        )}
      </>
    </React.Fragment>
  );
}
