import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Card,
  Grid,
  TextField,
  Typography,
  Stack,
  Button,
  Alert,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { AuthContext } from "src/auth/AuthProvider";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";
import { signOut } from "src/redux/Slices/Auth";
import { useDispatch } from "src/redux/Store";
import { postLogout } from "src/api/authApi";
import { useTranslation } from "react-i18next";
import { resetPassword } from "src/api/userApi";
import moment from "moment";

const ResetPassword = () => {
  const auth = useContext(AuthContext);
  const profile = auth.getProfile();
  const token = auth.getAuthToken();
  const theme = useTheme();
  const { t } = useTranslation();
  const isMountedRef = useRef(true);
  const dispatch = useDispatch();
  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    []
  );
  const defaultValues = {
    UserName: "",
    NewPassword: "",
    OldPassword: "",
    ConPassword: "",
  };

  const validationSchema = yup.object().shape({
    UserName: yup.string().required(t("validations.userNameIsRequired")),
    OldPassword: yup.string().required(t("validations.passwordIsRequired")),
    NewPassword: yup.string().required(t("validations.passwordIsRequired")),
    ConPassword: yup
      .string()
      .required(t("validations.passwordIsRequired"))
      .oneOf(
        [yup.ref("NewPassword"), null],
        t("validations.newPasswordAndConfirmPasswordMustMatch")
      ),
  });

  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
    reset,
  } = useForm({
    defaultValues,
    mode: "onBlur",
    // reValidateMode: 'onBlur',
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const [loading, setLoading] = useState(false);
  const onSubmit = (values) => {
    setLoading(true);
    const data = {
      UserName: values.UserName,
      OldPassword: values.OldPassword,
      NewPassword: values.NewPassword,
      UpdatedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
    };
    resetPassword(token, data)
      .then(async (res) => {
        setLoading(false);
        if (res.STATUS_CODE === "200" && res.DATA.UserId > 0) {
          reset();
          toast.success(
            t("toasts.successfullyResetPassword", {
              position: toast.POSITION.BOTTOM_RIGHT,
            })
          );
          await postLogout(token, {
            TodayDateTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
          });
          dispatch(signOut());
        } else {
          setError("afterSubmit", {
            type: "custom",
            message: "Username or Old Password Invalid",
          })(res.ERROR_CODE);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    // if(isMountedRef.current){
    //     setError('afterSubmit', { type: 'custom', message: 'error' })
    // }
  };
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);

  return (
    <React.Fragment>
      <Typography
        color="textSecondary"
        variant="h2"
        align="left"
        gutterBottom
        sx={{ textTransform: "capitalize" }}
      >
        {t("headings.resetPassword")}
      </Typography>
      <Card sx={{ p: 2 }}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {!!errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit.message}</Alert>
              )}
            </Grid>
            <Grid item sm={6} xs={12}>
              <div>
                <Typography fontWeight="500" gutterBottom variant="h6">
                  {t("textFieldLables.userName")}
                </Typography>
                <TextField
                  fullWidth
                  type="text"
                  variant="outlined"
                  placeholder={t("textFieldLables.userName")}
                  size="small"
                  {...register("UserName")}
                  error={!!errors.UserName}
                  helperText={errors.UserName?.message}
                />
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <div>
                <Typography fontWeight="500" gutterBottom variant="h6">
                  {t("textFieldLables.oldPassword")}
                </Typography>
                <TextField
                  fullWidth
                  type={showOldPassword ? "text" : "password"}
                  variant="outlined"
                  placeholder={t("textFieldLables.oldPassword")}
                  size="small"
                  {...register("OldPassword")}
                  error={!!errors.OldPassword}
                  helperText={errors.OldPassword?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowOldPassword(!showOldPassword)}
                          edge="end"
                        >
                          {showOldPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <div>
                <Typography fontWeight="500" gutterBottom variant="h6">
                  {t("textFieldLables.newPassword")}
                </Typography>
                <TextField
                  fullWidth
                  type={showNewPassword ? "text" : "password"}
                  variant="outlined"
                  placeholder={t("textFieldLables.newPassword")}
                  size="small"
                  {...register("NewPassword")}
                  error={!!errors.NewPassword}
                  helperText={errors.NewPassword?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          edge="end"
                        >
                          {showNewPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <div>
                <Typography fontWeight="500" gutterBottom variant="h6">
                  {t("textFieldLables.confirmPassword")}
                </Typography>
                <TextField
                  fullWidth
                  type={showConPassword ? "text" : "password"}
                  variant="outlined"
                  placeholder={t("textFieldLables.confirmPassword")}
                  size="small"
                  {...register("ConPassword")}
                  error={!!errors.ConPassword}
                  helperText={errors.ConPassword?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowConPassword(!showConPassword)}
                          edge="end"
                        >
                          {showConPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} justifyContent="flex-end">
                <Button
                  variant="contained"
                  sx={{
                    boxSizing: "border-box",
                    color: theme.palette.primary.main,
                    width: "70px",
                    height: "40px",
                    backgroundColor: theme.palette.secondary.contrastText,
                    border: `1px solid ${theme.palette.primary.main}`,
                    "&:hover": {
                      backgroundColor: theme.palette.secondary.contrastText,
                    },
                    marginRight: "15px",
                  }}
                  disabled={loading}
                  onClick={() => reset()}
                >
                  {t("buttons.clear")}
                </Button>
                <Button variant="contained" type="submit">
                  {loading ? t("buttons.loading") : t("buttons.save")}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Card>
    </React.Fragment>
  );
};
export default ResetPassword;
