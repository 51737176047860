import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllTimeEntries,
  postTimeEntry,
  putTimeEntry,
} from "src/api/TimeEntryApi";
import { fTimeSpan } from "src/components/Controls/formatUtils";

export const fetchTimeEntries = createAsyncThunk(
  "timeEntry/fetchTimeEntries",
  async ({ token, filters }, { rejectWithValue }) => {
    return getAllTimeEntries(token, filters)
      .then((data) => {
        return data?.DATA;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const createTimeEntries = createAsyncThunk(
  "timeEntry/createTimeEntries",
  async ({ token, body }, { rejectWithValue }) => {
    return postTimeEntry(token, body)
      .then((data) => {
        return data?.DATA;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const editTimeEntry = createAsyncThunk(
  "timeEntry/editTimeEntry",
  async ({ token, timeEntryId, body }, { rejectWithValue }) => {
    return putTimeEntry(token, timeEntryId, body)
      .then((data) => {
        return data?.DATA;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

const initialState = {
  isLoading: false,
  error: null,
  entries: [],
};

const Slice = createSlice({
  name: "dayEntry",
  initialState,
  reducers: {
    // Add your reducers here if any
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTimeEntries.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createTimeEntries.fulfilled, (state, action) => {
        if (action.payload.TimeEntryId) {
          var value = action.meta.arg;
          value["TimeEntryId"] = action.payload.TimeEntryId;
          value["StartTime"] = {
            TotalMilliseconds: new Date(
              fTimeSpan(value["StartTime"])
            ).getTime(),
          };
          value["EndTime"] = {
            TotalMilliseconds: new Date(fTimeSpan(value["EndTime"])).getTime(),
          };
          value["Duration"] = {
            TotalMilliseconds: new Date(fTimeSpan(value["Duration"])).getTime(),
          };
          state.entries.push(value);
        }
        state.isLoading = false;
      })
      .addCase(createTimeEntries.rejected, (state, action) => {
        state.error = action.payload.STATUS_CODE;
        state.isLoading = false;
        throw action.payload;
      })
      .addCase(fetchTimeEntries.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchTimeEntries.fulfilled, (state, action) => {
        state.entries = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchTimeEntries.rejected, (state, action) => {
        state.error = action.payload.STATUS_CODE;
        state.isLoading = false;
        throw action.payload;
      })
      .addCase(editTimeEntry.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(editTimeEntry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.entries = state.entries.filter((value) => {
          return value.TimeEntryId !== action.payload.TimeEntryId;
        });
        if (action.meta.arg.IsDelete === 0 && action.payload.TimeEntryId) {
          var value = action.meta.arg;
          value["StartTime"] = {
            TotalMilliseconds: new Date(
              fTimeSpan(value["StartTime"])
            ).getTime(),
          };
          value["EndTime"] = {
            TotalMilliseconds: new Date(fTimeSpan(value["EndTime"])).getTime(),
          };
          value["Duration"] = {
            TotalMilliseconds: new Date(fTimeSpan(value["Duration"])).getTime(),
          };
          state.entries.push(value);
        }
      })
      .addCase(editTimeEntry.rejected, (state, action) => {
        state.error = action.payload.STATUS_CODE;
        state.isLoading = false;
        throw action.payload;
      });
  },
});

export default Slice.reducer;
