import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllPackages } from "src/api/packageApi";

export const fetchPackages = createAsyncThunk(
  "packages/fetchPackages",
  async ({ token }, { rejectWithValue }) => {
    return getAllPackages(token, { Activation: true })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

const initialState = {
  isLoading: false,
  statusCode: null,
  errorMessage: null,
  packages: [],
};

const Slice = createSlice({
  name: "packages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPackages.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchPackages.fulfilled, (state, action) => {
        state.statusCode = action.payload.STATUS_CODE;
        state.errorMessage = action.payload.ERROR_MESSAGE;
        state.packages = action.payload.DATA;
        state.isLoading = false;
      })
      .addCase(fetchPackages.rejected, (state, action) => {
        state.statusCode = action.payload.STATUS_CODE;
        state.errorMessage = action.payload.ERROR_MESSAGE;
        state.isLoading = false;
        throw action.payload;
      });
  },
});

export default Slice.reducer;
