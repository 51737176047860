import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getAllTaxes = async (token) => {
  const url = new URL(`${baseUrl}/Tax`);

  // url.searchParams.append("ClientId", clientId);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postTax = async (token, body) => {
  const url = new URL(`${baseUrl}/Tax`);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putTax = async (token, taxId, body) => {
  const url = new URL(`${baseUrl}/Tax/id?TaxId=${taxId}`);

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const deleteTax = async (token, id, updatedDate) => {
  const url = new URL(`${baseUrl}/Tax/${id}`);

  let options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ updatedDate }),
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
