import React, { createContext, useContext } from "react";

const PermissionContext = createContext([]);

export const usePermission = (permission) => {
  const permissions = useContext(PermissionContext);
  if (!permission) return;
  if (typeof permission === "number")
    return permission ? permissions.includes(permission) : false;
  else return permission?.some((item) => permissions.includes(item));
};

const PermissionProvider = ({ permissions, children }) => {
  return (
    <PermissionContext.Provider value={permissions || []}>
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionProvider;
