import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const putActivation = async (token, RefId, body) => {
  const url = new URL(`${baseUrl}/Common/ChangeActivation?RefId=${RefId}`);

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};
