import { Typography, TextField, Grid } from "@mui/material";

const CustomInput = ({
  label,
  placeholder,
  errors,
  type,
  inputProps,
  containerProps,
  name,
  props,
  register,
}) => {
  return (
    <Grid item {...containerProps} my={1}>
      <Typography gutterBottom variant="h6" fontWeight={500}>
        {label}
      </Typography>
      <TextField
        sx={{
          minheight: 48,
          borderRadius: 10,
        }}
        placeholder={placeholder}
        fullWidth
        name={name}
        type={type || "text"}
        {...register(name)}
        InputProps={inputProps}
        variant="outlined"
        size="small"
        {...props}
        error={errors && !!errors[name]}
        helperText={errors && errors[name]?.message}
      />
    </Grid>
  );
};

export default CustomInput;
