import AddCircleIcon from "@mui/icons-material/AddCircle";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import {
  Button,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { putActivation } from "src/api/commonApi";
import { getAllCustomers } from "src/api/customerApi";
import { AuthContext } from "src/auth/AuthProvider";
import AddNewButton from "src/components/Common/Button/AddNewButton";
import ColumnViewer from "src/components/Common/DataTable/ColumnViewer";
import CsvExport from "src/components/Common/TabTable/CsvExport";
import CustomToolbar from "src/components/Common/TabTable/CustomToolbar";
import DataTable from "src/components/Common/TabTable/DataTable";
import { buildAddress } from "src/components/Controls/formatUtils";
import RestrictedComponents from "src/components/Permission/RestrictedComponents";
import Permissions from "src/constants/Permissions";
import { changePageHeading } from "src/redux/Slices/Common";
import { useDispatch } from "src/redux/Store";

const MainContainer = styled("main")(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(3),
}));

// necessary for content to be below app bar
const ToolbarContainer = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

const Customers = ({ types }) => {
  const auth = useContext(AuthContext);

  const token = auth.getAuthToken();

  //////////////////////////
  const [customers, setCustomers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { t } = useTranslation();
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();

  const [columnVisibility, setColumnVisibility] = useState({});

  const activationList = [
    {
      IsActive: true,
      TypeName: t("labels.active"),
    },
    {
      IsActive: false,
      TypeName: t("labels.inactive"),
    },
  ];

  const handleColumnVisibilityChange = (columnName) => {
    setColumnVisibility((prevVisibility) => ({
      ...prevVisibility,
      [columnName]: !prevVisibility[columnName],
    }));
  };
  const handleClck = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    dispatch(changePageHeading("Customers"));
  }, [dispatch]);

  const [searchParams] = useSearchParams();
  const hanldeSetValues = () => {
    const obj = {};
    for (let [key, value] of searchParams) {
      if (key != "Due") {
        obj[key] = value;
      } else {
        obj["TodayDateTime"] = moment().format("YYYY-MM-DDTHH:mm:ss");
        obj[key] = value;
      }
    }
    return obj;
  };
  const [vals, setVals] = useState(hanldeSetValues);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleActivationChange = (e, row) => {
    toast.dismiss();
    putActivation(token, row.CustomerId, {
      RefActivation: row.IsActive ? 0 : 1,
      RefType: 6,
      UpdatedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
    })
      .then(async (response) => {
        if (response?.STATUS_CODE === "200") {
          toast.success(t("toasts.statusChangeSuccess"));
          fetchCustomers();
        } else {
          toast.error(t("toasts.statusChangeFail"));
        }
      })
      .catch(() => {
        toast.error(t("toasts.statusChangeFail"));
      });
  };

  const headCells = [
    {
      name: "CustomerName",
      label: t("tableHeadings.customerName"),
      isDefault: true,
      isView: true,
      isSortable: true,
      sortName: "FNAME",
      fieldRenderType: "linkName",
      secondName: "CustomerName",
      idName: "CustomerId",
      toLink: "/customers/view-customer",
    },
    {
      name: "AddressLine1",
      isView: true,
      label: t("tableHeadings.street"),
      render: (row, index) => {
        const addressMaxCharacters = 20;
        const addressTruncatedText =
          row.AddressLine1?.length > addressMaxCharacters
            ? `${row.AddressLine1.slice(0, addressMaxCharacters)}...`
            : row.AddressLine1;
        return (
          <div key={index}>
            {row.AddressLine1?.length > addressMaxCharacters ? (
              <Tooltip title={row.AddressLine1} placement="top">
                <div>
                  {buildAddress({
                    AddressLine1: addressTruncatedText,
                  })}
                </div>
              </Tooltip>
            ) : (
              <div>
                {buildAddress({
                  AddressLine1: addressTruncatedText,
                })}
              </div>
            )}
          </div>
        );
      },
    },
    {
      name: "City",
      isView: true,
      label: t("tableHeadings.city"),
      isSortable: true,
      sortName: "CITY",
    },
    {
      name: "StateName",
      isView: true,
      label: t("tableHeadings.state"),
      isSortable: true,
      sortName: "STATENAME",
    },
    // {
    //   name: "CountryName",
    //   isView: true,
    //   label: t("tableHeadings.country"),
    // },
    {
      name: "ZipCode",
      isView: true,
      label: t("tableHeadings.zipCode"),
    },
    {
      name: "PhoneNumber",
      isView: true,
      label: t("tableHeadings.phoneNumber"),
      isSortable: true,
      sortName: "PHONE",
    },
    {
      name: "Email",
      label: t("tableHeadings.email"),
      isView: false,
      render: (row, index) => {
        return (
          <div key={index}>{row?.EmailAddress ? row.EmailAddress : "-"}</div>
        );
      },
    },
    {
      name: "IsActive",
      isView: true,
      label: t("tableHeadings.status"),
      fieldRenderType: "selectStatus",
      headerLabelAlign: "center",
    },
  ];

  {
    /** ---------------------- sorting ----------------------- */
  }
  const [isArrow, setIsArrow] = useState({
    SortBy: null,
    IsAsc: null,
  });

  {
    /** ------------------------------------------------------ */
  }

  const [renderColumns, setRenderColumns] = useState(
    headCells.filter((col) =>
      typeof col.isView === "boolean" ? col.isView : true
    )
  );

  const filterOptions = [
    { label: "Active", TypeId: "ACTIVE", TypeName: t("labels.active") },
    { label: "Inactive", TypeId: "INACTIVE", TypeName: t("labels.inactive") },
  ];

  const sortByOptions = [
    { label: "phone number", TypeId: "PHONE", TypeName: "phone number" },
    { label: "first name", TypeId: "FNAME", TypeName: "first name" },
    { label: "last name", TypeId: "LNAME", TypeName: "last name" },
    { label: "email", TypeId: "EMAIL", TypeName: "email" },
    { label: "city", TypeId: "CITY", TypeName: "city" },
    { label: "state name", TypeId: "STATENAME", TypeName: "state name" },
  ];

  const [filters, setfilters] = useState({
    Filter: "",
  });

  const filterList = [
    {
      label: t("labels.status"),
      name: "Filter",
      list: filterOptions,
    },
  ];

  const [printDetails, setPrintDetails] = useState({
    Filter: false,
  });

  const menuItems = [
    {
      name: "Filter",
      label: t("labels.status"),
      checked: printDetails.Status,
    },
  ];

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    fetchCustomers();
  }, [
    page,
    rowsPerPage,
    filters,
    vals.sortBy,
    vals.Parameter,
    vals.Filter,
    isArrow.SortBy,
    isArrow.IsAsc,
  ]);

  const fetchCustomers = async () => {
    setLoading(true);
    await getAllCustomers(token, {
      Page: page + 1,
      Limit: rowsPerPage,
      ...{
        ...vals,
        ...isArrow,
      },
    }).then((response) => {
      setCustomers(response?.DATA);
    });
    setLoading(false);
  };

  return (
    <MainContainer>
      <ToolbarContainer />

      <Grid container>
        <Grid item container md={6} xs={12}>
          <Typography variant="h2" align="left" gutterBottom>
            {t("headings.customers")}
          </Typography>
        </Grid>

        {!isDownMd ? (
          <Grid
            container
            alignContent="flex-end"
            justifyContent="flex-end"
            item
            xs={12}
            mt={-9}
          >
            <Stack
              direction="row"
              alignItems="flex-end"
              justifyContent="flex-end"
              spacing={2}
            >
              <CsvExport
                renderColumns={{
                  CustomerName: t("tableHeadings.customerName"),
                  PhoneNumber: t("tableHeadings.phoneNumber"),
                  EmailAddress: t("tableHeadings.email"),
                  AddressLine1: t("tableHeadings.street"),
                  City: t("tableHeadings.city"),
                  StateName: t("tableHeadings.state"),
                  ZipCode: t("tableHeadings.zipCode"),
                  IsActive: t("tableHeadings.status"),
                }}
                filename={t("headings.customers")}
                CsvDataFn={getAllCustomers}
                token={token}
                disabled={!token || !getAllCustomers || !renderColumns}
              />

              <AddNewButton
                title={t("buttons.addCustomer")}
                redirectPath={"/customers/new"}
                icon={<AddCircleIcon />}
              />

              <Button
                variant="contained"
                onClick={handleClck}
                size="small"
                sx={{
                  textDecoration: "none",
                  borderRadius: "4px",
                  display: "flex",
                  placeItems: "center",
                }}
              >
                <VerticalSplitIcon />
              </Button>
            </Stack>
          </Grid>
        ) : (
          <Grid
            container
            alignItems="center"
            spacing={2}
            justifyContent="flex-end"
          >
            <Grid item>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
              >
                <CsvExport
                  renderColumns={{
                    CustomerName: t("tableHeadings.customerName"),
                    PhoneNumber: t("tableHeadings.phoneNumber"),
                    EmailAddress: t("tableHeadings.email"),
                    AddressLine1: t("tableHeadings.street"),
                    City: t("tableHeadings.city"),
                    StateName: t("tableHeadings.state"),
                    ZipCode: t("tableHeadings.zipCode"),
                    IsActive: t("tableHeadings.status"),
                  }}
                  filename={t("headings.customers")}
                  CsvDataFn={getAllCustomers}
                  token={token}
                  disabled={!token || !getAllCustomers || !renderColumns}
                  width={"100px"}
                />
                <RestrictedComponents permissions={Permissions.ADD_CUSTOMER}>
                  <AddNewButton
                    title={t("buttons.new")}
                    redirectPath={"/customers/new"}
                    icon={<AddCircleIcon />}
                    width={"100px"}
                  />
                </RestrictedComponents>

                <Button
                  variant="contained"
                  onClick={handleClck}
                  size="small"
                  sx={{
                    textDecoration: "none",
                    borderRadius: "4px",
                    marginLeft: "30px",
                  }}
                >
                  <VerticalSplitIcon />
                </Button>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Grid>

      <ColumnViewer
        columns={headCells}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        setRenderColumns={setRenderColumns}
      />

      <CustomToolbar
        menuItems={menuItems}
        printDetails={printDetails}
        setPrintDetails={setPrintDetails}
        filterList={filterList}
        setfilters={setfilters}
        vals={vals}
        setVals={setVals}
        headCells={headCells}
        placeholderValue={t("labels.searchByCustomer")}
        setIsArrow={setIsArrow}
      />

      <Grid
        container
        spacing={1}
        style={{
          marginTop: theme.spacing(1),
        }}
      >
        <Grid item xs={12}>
          <DataTable
            columns={renderColumns}
            rows={customers}
            rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
            isLoading={loading}
            tableHeading={null}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            onColumnVisibilityChange={handleColumnVisibilityChange}
            isArrow={isArrow}
            setIsArrow={setIsArrow}
            isCommonView={true}
            activationList={activationList}
            handleActivationChange={handleActivationChange}
          />
        </Grid>
      </Grid>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    types: state.type.types,
  };
};

export default connect(mapStateToProps, null)(Customers);
