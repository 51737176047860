import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

const AttachmentPrompt = ({ open, onClose, onAddAttachment }) => {
    const handleAddAttachments = () => { 
        onAddAttachment();
      };
    
    const {t} = useTranslation();

    const handleClose = () => {  
        onClose();
      };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("headings.addDocuments")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("messages.addAttachmentPrompt")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddAttachments} color="primary">
          {t("buttons.yes")}
        </Button>
        <Button onClick={handleClose} color="primary">
          {t("buttons.no")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AttachmentPrompt;
