import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllServices } from "src/api/serviceApi";

export const fetchServices = createAsyncThunk(
  "services/fetchServices",
  async ({ token }, { rejectWithValue }) => {
    return getAllServices(token, { Activation: true })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

const initialState = {
  isLoading: false,
  statusCode: null,
  errorMessage: null,
  services: [],
};

const Slice = createSlice({
  name: "services",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchServices.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchServices.fulfilled, (state, action) => {
        state.statusCode = action.payload.STATUS_CODE;
        state.errorMessage = action.payload.ERROR_MESSAGE;
        state.services = action.payload.DATA;
        state.isLoading = false;
      })
      .addCase(fetchServices.rejected, (state, action) => {
        state.statusCode = action.payload.STATUS_CODE;
        state.errorMessage = action.payload.ERROR_MESSAGE;
        state.isLoading = false;
        throw action.payload;
      });
  },
});

export default Slice.reducer;
