function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export function highlightKeyword(text, keyword = "") {
  let regex;
  if (keyword && keyword.trim() !== "") {
    const escapedKeyword = escapeRegExp(keyword);
    regex = new RegExp(`(${escapedKeyword})`, "gi");
  } else {
    regex = null;
  }

  return regex != null
    ? text
        ?.toString()
        ?.split(regex)
        ?.map((part, index) =>
          regex?.test(part) ? (
            <span
              key={index}
              style={{ fontWeight: "bold", backgroundColor: "yellow" }}
            >
              {part}
            </span>
          ) : (
            part
          )
        )
    : text;
}
