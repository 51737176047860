import { baseUrl, handleResponse, handleError } from "./apiUtils";
import { addingParams } from "./apiUtils";

export const getAllJobs = async (token, filters, roleId) => {
  const url =
    roleId === 1
      ? new URL(`${baseUrl}/Job`)
      : new URL(`${baseUrl}/Employee/EmployeeJob`);

  for (const [key, value] of Object.entries(filters)) {
    if (value !== null && value !== undefined && value !== "") {
      url.searchParams.append(key, value);
    }
  }

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getJobById = async (token, jobId, date, roleId) => {
  const url =
    roleId === 1
      ? new URL(`${baseUrl}/Job/id?JobId=${jobId}&TodayDateTime=${date}`)
      : new URL(`${baseUrl}/Employee/Job/${jobId}?TodayDate=${date}`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getJobByCustomerId = async (
  token,
  clientId,
  customerId,
  filters
) => {
  const url = addingParams(filters, `${baseUrl}/Job/Customer/${customerId}`);
  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postJob = async (token, body) => {
  const url = new URL(`${baseUrl}/Job`);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putJob = async (token, jobId, body) => {
  const url = new URL(`${baseUrl}/Job?JobId=${jobId}`);

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const deleteJob = async (token, id, updatedDate) => {
  const url = new URL(`${baseUrl}/Job/${id}`);

  let options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ updatedDate }),
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getJobsByEmployeeId = async (token, filters) => {
  const url = new URL(`${baseUrl}/Job/Employee`);

  for (const [key, value] of Object.entries(filters)) {
    if (value !== null && value !== undefined && value !== "") {
      url.searchParams.append(key, value);
    }
  }

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getAllJobQuestions = async (token, filters) => {
  const url = new URL(`${baseUrl}/Job/Request-Questionnaire`);

  for (const [key, value] of Object.entries(filters)) {
    if (value !== null && value !== undefined && value !== "") {
      url.searchParams.append(key, value);
    }
  }

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getAllJobSchedules = async (token, filters, roleId) => {
  const url =
    roleId === 1
      ? new URL(`${baseUrl}/Job/Job-Schedule`)
      : new URL(`${baseUrl}/Employee/Schedule`);

  for (const [key, value] of Object.entries(filters)) {
    if (value !== null && value !== undefined && value !== "") {
      url.searchParams.append(key, value);
    }
  }

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getJobDetails = async (token, jobId) => {
  const url = new URL(`${baseUrl}/Job/Schedules-details?FieldId=${jobId}`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
