import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllTypes } from "src/api/typeApi";

export const fetchAllTypes = createAsyncThunk(
  "types/fetchAllTypes",
  async ({ token }, { rejectWithValue }) => {
    return getAllTypes(token, {})
      .then((data) => {
        return data?.DATA;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

const initialState = {
  isLoading: false,
  error: null,
  types: [],
};

const Slice = createSlice({
  name: "types",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTypes.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchAllTypes.fulfilled, (state, action) => {
        state.types = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchAllTypes.rejected, (state, action) => {
        state.error = action.payload.STATUS_CODE;
        state.isLoading = false;
        throw action.payload;
      });
  },
});

export default Slice.reducer;
