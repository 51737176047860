import { Stack, Button, Typography, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useTranslation } from "react-i18next";

const SaveEditButton = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    isAddPage = true,
    loading,
    title = "Add New",
    redirectPath = "#",
    moduleName = "Job",
    onClickFunc,
    width,
    ...rest
  } = props;

  return (
    <Stack
      direction="row"
      alignItems="flex-end"
      justifyContent="flex-end"
      spacing={2}
    >
      <Button
        variant="contained"
        component={isAddPage ? Link : null}
        to={redirectPath}
        onClick={onClickFunc}
        sx={{
          boxSizing: "border-box",
          color: theme.palette.primary.main,
          width: {
            xs: width ? width : "120px",
            sm: width ? width : "110px",
            md: "120px",
          },
          height: "40px",
          borderRadius: "4px",
          backgroundColor: theme.palette.secondary.contrastText,
          border: `1px solid ${theme.palette.primary.main}`,
          "&:hover": {
            backgroundColor: theme.palette.secondary.contrastText,
          },
          "@media (max-width: 1320px) and (max-height: 950px)": {
            width: "90px",
          },
        }}
      >
        {t("buttons.cancel")}
      </Button>

      <Button
        variant="contained"
        type="submit"
        sx={{
          mr: 7,
          width: {
            xs: width ? width : "120px",
            sm: width ? width : "110px",
            md: "140px",
          },
          height: "40px",
          borderRadius: "4px",
          "@media (max-width: 1320px) and (max-height: 950px)": {
            width: "90px",
          },
        }}
        disabled={loading}
      >
        {t(isAddPage ? "buttons.saveButton" : "buttons.update", { moduleName })}
      </Button>
    </Stack>
  );
};
export default SaveEditButton;
