import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getAllHolidays = async (token, filters) => {
  const url = new URL(`${baseUrl}/holiday`);

  for (const [key, value] of Object.entries(filters)) {
    if (value !== null && value !== undefined && value !== "") {
      url.searchParams.append(key, value);
    }
  }

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getHolidayById = async (token, clientId, holidayId) => {
  const url = new URL(`${baseUrl}/holiday/id?HolidayId=${holidayId}`);

  url.searchParams.append("ClientId", clientId);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postHoliday = async (token, clientId, body) => {
  const url = new URL(`${baseUrl}/holiday`);

  url.searchParams.append("ClientId", clientId);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putHoliday = async (token, holidayId, body) => {
  const url = new URL(`${baseUrl}/holiday?HolidayId=${holidayId}`);

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};
