import { CONFIG } from "../constants/config";
import { dispatch } from "src/redux/Store";
import { tokenExpired } from "src/redux/Slices/Auth";
export const baseUrl = CONFIG.API_URL;

export const callAPI = async (url, options = {}) => {
  return await fetch(url, options).then(handleResponse).catch(handleError);
};

export const handleResponse = async (response) => {
  if (response.ok) {
    return await response.json();
  } else if (response.status === 401) {
    dispatch(tokenExpired());
    throw { ERROR_MESSAGE: false, STATUS_CODE: 401 };
  } else if (response.status === 404) {
    throw { ERROR_MESSAGE: false, STATUS_CODE: 404 };
  } else {
    throw { ERROR_MESSAGE: false, ERROR_DATA: await response.json() };
  }
};

export const addingParams = (filters, path) => {
  const url = new URL(path);

  for (const [key, value] of Object.entries(filters)) {
    if (value !== null && value !== undefined && value !== "") {
      url.searchParams.append(key, value);
    }
  }
  return url;
};

// In a real app, would likely call an error logging service.
export const handleError = (error) => {
  // eslint-disable-next-line no-console
  //console.error("API call failed: " + error.message);
  throw error;
};
