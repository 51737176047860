import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getAllInventory = async (token, filters) => {
  const url = new URL(`${baseUrl}/Inventory`);

  for (const [key, value] of Object.entries(filters)) {
    if (value !== null && value !== undefined && value !== "") {
      url.searchParams.append(key, value);
    }
  }

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getInventoryById = async (token, productId) => {
  const url = new URL(`${baseUrl}/Inventory/Id?ProductId=${productId}`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postInventory = async (token, body) => {
  const url = new URL(`${baseUrl}/Inventory`);

  let options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: body,
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putInventory = async (token, productId, body) => {
  const url = new URL(`${baseUrl}/Inventory/Id?ProductId=${productId}`);

  let options = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: body,
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const deleteInventory = async (token, id, updatedDate) => {
  const url = new URL(`${baseUrl}/Inventory/${id}`);

  let options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ updatedDate }),
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
