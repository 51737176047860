import { baseUrl, handleResponse, handleError } from "./apiUtils";
import { addingParams } from "./apiUtils";

export const getAllEmployees = async (token, filters) => {
  const url = new URL(`${baseUrl}/Employee`);

  for (const [key, value] of Object.entries(filters)) {
    if (value !== null && value !== undefined && value !== "") {
      url.searchParams.append(key, value);
    }
  }

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getEmployeeById = async (token, clientId, employeeId) => {
  const url = new URL(`${baseUrl}/Employee/id?EmployeeId=${employeeId}`);

  url.searchParams.append("ClientId", clientId);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postEmployee = async (token, body) => {
  const url = new URL(`${baseUrl}/Employee`);

  // url.searchParams.append("ClientId", clientId);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putEmployee = async (token, employeeId, body) => {
  const url = new URL(`${baseUrl}/Employee?EmployeeId=${employeeId}`);

  // url.searchParams.append("ClientId", clientId);

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putEmployeeResetPassword = async (
  token,
  clientId,
  userId,
  body
) => {
  const url = new URL(`${baseUrl}/Employee/resetpassword`);

  url.searchParams.append("ClientId", clientId);
  url.searchParams.append("UserId", userId);

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getJobByEmployeeId = async (
  token,
  clientId,
  employeeId,
  filters
) => {
  const url = addingParams(filters, `${baseUrl}/Employee/${employeeId}/Job`);

  for (const [key, value] of Object.entries(filters)) {
    if (value !== null && value !== undefined && value !== "") {
      url.searchParams.append(key, value);
    }
  }

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const deleteEmployee = async (token, id, updatedDate) => {
  const url = new URL(`${baseUrl}/Employee/${id}`);

  let options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ updatedDate }),
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getEmployeeMapSchedules = async (token, filters) => {
  const url = new URL(`${baseUrl}/Employee/MapSchedules`);

  for (const [key, value] of Object.entries(filters)) {
    if (value !== null && value !== undefined && value !== "") {
      url.searchParams.append(key, value);
    }
  }

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
