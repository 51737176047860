import { Stack, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useTranslation } from "react-i18next";

const EditButton = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { handleClick, ...rest } = props;

  return (
    <Button
      variant="contained"
      onClick={handleClick}
      sx={{
        boxSizing: "border-box",
        color: theme.palette.primary.main,
        width: "120px",
        height: "40px",
        borderRadius: "4px",
        backgroundColor: theme.palette.secondary.contrastText,
        border: `1px solid ${theme.palette.primary.main}`,
        "&:hover": {
          backgroundColor: theme.palette.secondary.contrastText,
        },
      }}
    >
      {t("buttons.edit")}
    </Button>
  );
};
export default EditButton;
