import { alpha } from "@mui/material";
import Bg_Light from "src/assets/light-login-background-concept.png";

const { createTheme } = require("@mui/material");

const primary = "#ffffff";
const black = "#08134A";
const darkBlack = "rgb(36, 40, 44)";
const background = "#F9F9F9";
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "rgba(253, 200, 69, 1)";
const warningDark = "rgba(253, 200, 69, .7)";
const danger = "#FF3D3D";
const darkBackground = "#f2f2f2";
const focusBackground = "#E9ECFF";

// border
const borderWidth = 2;
const borderColor = "rgba(0, 0, 0, 0.13)";

// spacing
const spacing = 8;
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  // 900: '#161C24',
  900: "#000000",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};

const themeDark = createTheme({
  palette: {
    primary: {
      light: "#39426e",
      //   main: "#08134A",
      default: "#F4A540",
      main: "#F1AD28",
      dark: "#050d33",
      contrastText: "#fff",
      inheritTextColor: "#818EA1",
    },
    secondary: {
      light: "#545454",
      main: "#457CCE",
      default: "#144061",
      // dark: "#31568f",
      dark: "#8B8B8B",
      contrastText: "#fff",
      inheritTextColor: "#f59b27",
    },
    input: {
      light: "#545454",
      // main: "#457CCE",
      default: "#1D2228",
      dark: "#8B8B8B",
      contrastText: "#fff",
      inheritTextColor: "#f59b27",
    },
    shadow: {
      shadow: "#B9B7BD",
      inheritTextColor: "#545454",
      contrastText: "orange",
    },
    transparent: {
      main: "transparent",
    },

    common: {
      black: black,
      darkBlack: darkBlack,
      danger: danger,
    },
    warning: {
      light: warningLight,
      main: warningMain,
      dark: warningDark,
      default: warningLight,
    },
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    background: {
      default: background,
      dark: darkBackground,
      button: focusBackground,
    },
    spacing,
    dropDown: {
      light: "black",
      dark: "white",
      default: "white",
    },
    revenue: {
      increase: "#38BC18",
      decrease: "red",
      contrastText: "#808080",
    },
    mode: "dark",
    text: {
      primary: "#fff",
      secondary: "#000",
      disabled: GREY[500],
      selectedTxtColor: "#fff",
    },
    background: { paper: "#fff", default: "#fff", neutral: GREY[200] },

    appBar: {
      backgroundColor: "black",
      background:
        "linear-gradient(89.78deg, rgba(178, 178, 178, 0.3) 0%, rgba(244, 244, 244, 0.1) 100%)",
      backdropFilter: "blur(25px)",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    },
    appBarShift: {
      backgroundColor: "black",
      backdropFilter: "blur(25px)",
      background:
        "linear-gradient(89.78deg, rgba(77, 77, 77, 0.3) 0%, rgba(24, 24, 24, 0.1) 100%)",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
    },
    searchbar: {
      backgroundColor: "transparent",
      backdropFilter: "blur(10px)",
      background:
        "linear-gradient(89.89deg, rgba(178, 178, 178, 0.3) 0%, rgba(209, 209, 209, 0.3) 100%)",
      "border-radius": "6px",
      textColor: "Black",
    },
    toolBar: {
      background:
        "linear-gradient(89.89deg, rgba(178, 178, 178, 0.3) 0%, rgba(209, 209, 209, 0.3) 100%)",
      borderRadius: "6px",
    },
    dropDown: {
      background:
        "linear-gradient(89.89deg, rgba(222, 222, 222, 0.8) 0%, rgba(244, 244, 244, 0.8) 100%)",
      color: "#181818",
    },
    background: {
      color: "#f59b27",
    },
    customToolbarTextfield: {
      color: "#F4F4F4",
      background: "#181818",
    },
    skelton: {
      rootBg:
        "linear-gradient(89.78deg, rgba(178, 178, 178, 0.3) 0%, rgba(244, 244, 244, 0.1) 100%)",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: ({ theme }) => ({
          // backgroundColor: focusBackground,
          ...(theme.palette.mode === "dark"
            ? {
                background: darkBlack,
              }
            : {
                background: background,
              }),
          borderRadius: "6px",
          borderColor: "none",
          color: theme.palette.primary.inheritTextColor,
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: "0.9rem",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              borderColor: "none",
            },
          },
          "& .MuiOutlinedInput-root:hover": {
            "& > fieldset": {
              borderColor: "none",
            },
          },
          "& .MuiOutlinedInput-root:focus": {
            "& > fieldset": {
              borderColor: "none",
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "0.9rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: "none",

          background: "#F4A540",
          variant: "contained",
          color: theme.palette.text.secondary,
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            background: "#F4A540",
            variant: "contained",
          },
        },
      },
    },
    MuiExpansionPanel: {
      styleOverrides: {
        root: {
          position: "static",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: borderColor,
          height: borderWidth,
        },
      },
    },
    MuiPrivateNotchedOutline: {
      styleOverrides: {
        root: {
          borderWidth: borderWidth,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "#fff !important",
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        divider: {
          borderBottom: `${0}px solid ${borderColor}`,
        },
        root: {
          "&:hover": {
            background: "#ffd966",
          },
          "&:selected": {
            backgroundColor: "red",
            color: "red",
            borderLeft: "4px solid #30D0B6",
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        divider: {
          borderBottom: `${0}px solid ${borderColor}`,
        },

        root: {
          "&:hover": {
            background:
              "linear-gradient(86.91deg, theme.palette.shadow.contrastText 0%, theme.palette.primary.main 100%)",
          },
          "&.Mui-selected": {
            background:
              "linear-gradient(86.91deg, theme.palette.shadow.contrastText 0%, theme.palette.primary.main 100%)",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          width: "100%",
          // maxWidth: 430,
          marginLeft: spacing,
          marginRight: spacing,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background:
            "linear-gradient(86.91deg, theme.palette.shadow.contrastText 0%, theme.palette.primary.main 100%)",
          fontSize: "12px",
        },
        arrow: {
          color: black,
        },
      },
    },
    MuiDropzoneArea: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.dropZoneAttach.color,
          border: `2px solid ${borderColor}`,
        }),
        icon: {
          color: "orange",
        },
        text: {
          padding: "3rem",
          fontSize: "1.1rem !important",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: { boxShadow: "none" },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: focusBackground,
          borderRadius: "4px",
        },
        expandIcon: {
          backgroundColor: "#abb2d4",
          color: "#fff",
          padding: "1px",
          marginRight: "1px",
          "&:hover": {
            backgroundColor: "#abb2d4",
          },
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        root: {
          fontSize: 15,
          color: "theme.palette.revenue.contrastText",
          "&$selected > $content $label": {
            backgroundColor: "transparent",
          },
          "&$selected > $content $label:hover, &$selected:focus > $content $label":
            {
              backgroundColor: "transparent",
            },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: primary,
        },
        colorSecondary: {
          "&$checked": {
            color: primary,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: "0.9rem",
        },
        select: {
          "&:focus": {
            backgroundColor: "none",
          },
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          fontWeight: 450,
        },
      },
    },
    TableContainer: {
      styleOverrides: {
        root: {
          "@media print": {
            overflow: "visible !important",
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          background: theme.palette.appBar.backgroundColor,
          color: theme.palette.dropDown.color,
          backdropFilter: "blur(50px)",
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          // backdropFilter:'blur(10px)',
          height: "57px",
          "& td:first-of-type": {
            [theme.breakpoints.up("md")]: {
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
            },
          },

          "& td:last-child": {
            [theme.breakpoints.up("md")]: {
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            },
          },

          ...(theme.palette.mode === "dark"
            ? {
                background:
                  "linear-gradient(89.89deg, rgba(40, 40, 40, 0.8) 0%, rgba(24, 24, 24, 0.8) 100%)",
                // backdropFilter:'blur(10px)',
                translateZ: "0px",
                backfaceVisibility: "hidden",
                // marginBottom:'2px'
                borderRadius: "10px",
                // height: '57px'
              }
            : theme.palette.mode === "light"
            ? {
                background:
                  "linear-gradient(89.89deg, rgba(146, 146, 146, 0.3) 0%, rgba(139, 139, 139, 0.3) 100%)",
                // backdropFilter:'blur(10px)'
              }
            : {
                background: "#fff",
              }),
        }),
        head: ({ ownerState, theme }) => ({
          height: "45px",
          borderRadius: "6px",

          "@media print": {
            backgroundColor: "#fff",
          },
          ...(theme.palette.mode === "dark"
            ? {
                background:
                  "linear-gradient(89.89deg, rgba(89, 89, 89, 0.3) 2%, rgba(24, 24, 24, 0.8) 60%)",
              }
            : {
                background:
                  "linear-gradient(89.89deg, rgba(89, 89, 89, 0.3) 2%, rgba(89, 89, 89, 0.3) 60%)",
                color: "#000000",
              }),
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderBottom: "none",
          fontSize: "1rem",
          fontWeight: 550,
          padding: "0px 0px 0px 16px",
          ...(theme.palette.mode === "dark"
            ? {
                color: "#fff",
              }
            : {
                color: "#545454",
              }),
        }),
        head: ({ ownerState, theme }) => ({
          "@media print": {
            color: "#000  !important",
          },
          ...(theme.palette.mode === "dark"
            ? {
                color: "#FFFFFF",
                maxHeight: "43px",
              }
            : {
                color: "#000000",
              }),
        }),
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          // color: "#fff !important",
          "@media print": {
            color: "#000  !important",
          },
        },
        icon: {
          color: "#fff !important",
          "@media print": {
            color: "#000  !important",
          },
        },
      },
    },

    MuiPagination: {
      styleOverrides: {
        root: {
          button: {
            color: "#fff",
          },
        },
      },
    },

    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: "#F1AD28",
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          background: theme.palette.dropDown.background,
          backdropFilter: "blur(10px)",
        }),
      },
    },

    MuiFab: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          color: "#F4F4F4",
          background:
            "linear-gradient(86.91deg, theme.palette.shadow.contrastText 0%, theme.palette.primary.main 100%)",
        }),
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          background: theme.palette.appBar.backgroundColor,
          color: theme.palette.dropDown.color,
          backdropFilter: "blur(25px)",
        }),
      },
    },

    MuiSkeleton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          background: theme.palette.skelton.rootBg,
          // color: theme.palette.dropDown.color,
          backdropFilter: "blur(25px)",
        }),
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          backgroundColor: "orange",
          color: "white",
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          backgroundColor: "orange",
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
    },
    // MuiChip: {
    //   styleOverrides: {
    //     root: ({ ownerState, theme }) => ({
    //       ...(theme.palette.mode === "dark"
    //         ? {
    //             background: "#F4F4F4",
    //           }
    //         : {
    //             background: "#181818",
    //           }),
    //     }),
    //   },
    // },
    MuiChip: {
      styleOverrides: {
        root: {
          color: "#181818",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          color: "#fff !important",
        }),
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(theme.palette.mode === "dark"
            ? {
                color: "white",
                background: "black",
              }
            : {
                color: "black",
                background: "white",
              }),
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(theme.palette.mode === "dark"
            ? {
                color: "darkorange",
              }
            : {
                color: "black",
              }),
        }),
      },
    },
    "MuiSwitch-thumb": {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(theme.palette.mode === "dark"
            ? {
                background: "red",
              }
            : {
                background: theme.palette.revenue.increase,
              }),
        }),
      },
    },
  },
  typography: {
    fontFamily: "'Poppins','Helvetica','Arial', sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontSize: "1.8rem",
      color: "theme.palette.primary.dark",
      fontWeight: 600,
      "@media (min-width:1536px)": {
        fontSize: "2rem",
      },
    },
    h2: {
      fontSize: "1.4rem",
      letterSpacing: "0px",
      fontWeight: 600,
      "@media (min-width:1536px)": {
        fontSize: "1.70rem",
      },
    },
    h3: {
      fontSize: "0.8rem",
      letterSpacing: "0px",
      fontWeight: 600,
      "@media (min-width:1536px)": {
        fontSize: "1.1rem",
      },
    },
    h4: {
      fontSize: "0.9rem",
      letterSpacing: "0px",
      fontWeight: 500,
      "@media (min-width:1536px)": {
        fontSize: "1rem",
      },
    },
    h5: {
      fontSize: "0.86rem",
      fontWeight: 400,
      "@media (min-width:1536px)": {
        fontSize: "0.96rem",
      },
    },
    h6: {
      fontSize: "0.75rem",
      fontWeight: 400,
      "@media (min-width:1536px)": {
        fontSize: "0.8rem",
      },
    },
    body1: {
      fontSize: "0.65rem",
      fontWeight: 200,
      "@media (min-width:1536px)": {
        fontSize: "0.8rem",
      },
    },
    subtitle1: {
      fontSize: "0.8rem",
      color: "theme.palette.secondary.dark",
      "@media (min-width:1536px)": {
        fontSize: "1.1rem",
      },
    },
    subtitle2: {
      fontSize: "0.4rem",
      color: "theme.palette.primary.dark",
      fontWeight: 500,
      opacity: 0.3,
      "@media (min-width:1536px)": {
        fontSize: "0.7rem",
      },
    },
    button: {
      fontSize: "0.9rem",
      fontWeight: 500,
      "@media (min-width:1536px)": {
        fontSize: "1rem",
      },
    },
  },
});
export default themeDark;
