import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllTaxes } from "src/api/taxApi";

export const fetchTaxes = createAsyncThunk(
  "taxes/fetchTaxes",
  async ({ token }, { rejectWithValue }) => {
    return getAllTaxes(token)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

const initialState = {
  isLoading: false,
  error: null,
  taxes: [],
};

const Slice = createSlice({
  name: "tax",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaxes.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchTaxes.fulfilled, (state, action) => {
        state.taxes = action.payload.DATA;
        state.isLoading = false;
      })
      .addCase(fetchTaxes.rejected, (state, action) => {
        state.error = action.payload.STATUS_CODE;
        state.isLoading = false;
        throw action.payload;
      });
  },
});

export default Slice.reducer;
