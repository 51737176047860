import { Backdrop, CircularProgress } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { Suspense, useContext, useEffect } from "react";
import CacheBuster from "react-cache-buster";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AuthContext } from "src/auth/AuthProvider";
import NavBar from "src/components/NavBar";
import ScrollToTop from "src/components/ScrollToTop";
import { fetchCustomers } from "src/redux/Slices/Customer";
import { fetchEmployees } from "src/redux/Slices/Employee";
import { fetchInventory } from "src/redux/Slices/Inventory";
import { fetchProducts } from "src/redux/Slices/Product";
import { fetchServices } from "src/redux/Slices/Service";
import { fetchTaxes } from "src/redux/Slices/Tax";
import { fetchAllTypes } from "src/redux/Slices/Type";
import { dispatch } from "src/redux/Store";
import * as packageInfo from "../package.json";
import PermissionProvider from "./context/PermissionContext";
import GlobalStyles from "./GlobalStyles";
import { fetchPackages } from "./redux/Slices/Package";
import {
  AuthRouter,
  EmployeeRouter,
  InitialRouter,
  Router,
  SessionRouter,
  TrialExpiredRouter,
} from "./routes";
import ThemeProvider from "./theme/index";

const StyledDiv = styled("div")(() => ({
  display: "flex",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  backgroundPosition: "20% 10%",
}));

const loading = (
  <Backdrop open={true}>
    <CircularProgress color="primary" size={60} />
  </Backdrop>
);

const App = ({
  loadCustomers,
  loadTypes,
  loadEmployees,
  loadServices,
  loadProducts,
  loadTaxes,
  loadPackages,
  loadInventory,
  loadFeatures,
}) => {
  const auth = useContext(AuthContext);

  const isProduction = process.env.NODE_ENV === "production";

  let pkgInfo = packageInfo;

  const handleOnIdle = () => {
    dispatch({ type: "LOGOUT" });
  };

  const isAuthenticated = auth.isAuthenticated();

  const isTokenAuthorized = auth.isTokenAuthorized();

  const profile = auth.getProfile();

  const token = auth.getAuthToken();

  const theme = useTheme();

  useEffect(() => {
    const applyStyles = () => {
      if (theme.palette.mode === "light") {
        import("src/components/Dispatch/DragDropDefault.css");
      } else {
        import("src/components/Dispatch/DragDropDefault.css");
      }
    };

    applyStyles();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        await loadCustomers({
          token: token,
        });
      } catch (error) {
        if (error.STATUS_CODE === 401) {
          return;
        }
        console.log("Error in storing Customers in Redux");
      }

      try {
        await loadTypes({
          token: token,
        });
      } catch (error) {
        if (error.STATUS_CODE === 401) {
          return;
        }
        console.log("Error in storing Types in Redux");
      }

      try {
        await loadEmployees({
          token: token,
        });
      } catch (error) {
        if (error.STATUS_CODE === 401) {
          return;
        }
        console.log("Error in storing Employees in Redux");
      }

      try {
        await loadServices({
          token: token,
        });
      } catch (error) {
        if (error.STATUS_CODE === 401) {
          return;
        }
        console.log("Error in storing Services in Redux");
      }

      try {
        await loadProducts({
          token: token,
        });
      } catch (error) {
        if (error.STATUS_CODE === 401) {
          return;
        }
        console.log("Error in storing Products in Redux");
      }

      try {
        await loadTaxes({
          token: token,
        });
      } catch (error) {
        if (error.STATUS_CODE === 401) {
          return;
        }
        console.log("Error in storing Taxes in Redux");
      }

      try {
        await loadPackages({
          token: token,
        });
      } catch (error) {
        if (error.STATUS_CODE === 401) {
          return;
        }
        console.log("Error in storing Packages in Redux");
      }

      try {
        await loadInventory({
          token: token,
        });
      } catch (error) {
        if (error.STATUS_CODE === 401) {
          return;
        }
        console.log("Error in storing Inventory in Redux");
      }
    }

    async function fetchDataForEmployee() {
      try {
        await loadServices({
          token: token,
        });
      } catch (error) {
        if (error.STATUS_CODE === 401) {
          return;
        }
        console.log("Error in storing Services in Redux");
      }

      try {
        await loadProducts({
          token: token,
        });
      } catch (error) {
        if (error.STATUS_CODE === 401) {
          return;
        }
        console.log("Error in storing Products in Redux");
      }

      try {
        await loadPackages({
          token: token,
        });
      } catch (error) {
        if (error.STATUS_CODE === 401) {
          return;
        }
        console.log("Error in storing Packages in Redux");
      }
    }
    if (profile?.IsFirstLogin) return;
    if (isAuthenticated && profile.RoleId != 1) {
      fetchDataForEmployee();
    } else {
      isAuthenticated && fetchData();
    }
  }, [isAuthenticated, profile?.IsFirstLogin]);

  const routerInfo = () => {
    return (
      <>
        <Suspense fallback={loading}>
          {isAuthenticated &&
            isTokenAuthorized &&
            profile?.UserName &&
            (profile.IsDemo ||
              moment(profile.AccountCreatedDate)
                .add(30, "days")
                .format("YYYY-MM-DDTHH:mm") >
                moment().format("YYYY-MM-DDTHH:mm")) && (
              <NavBar
                user={{ displayName: profile?.UserName }}
                IsFirstLogin={profile?.IsFirstLogin} //profile?.IsFirstLogin
              />
            )}
        </Suspense>
        <ScrollToTop />
        <Suspense fallback={loading}>
          {isAuthenticated && isTokenAuthorized ? (
            profile.IsDemo ||
            moment(profile.AccountCreatedDate)
              .add(30, "days")
              .format("YYYY-MM-DDTHH:mm") >
              moment().format("YYYY-MM-DDTHH:mm") ? (
              profile?.IsFirstLogin ? (
                <InitialRouter />
              ) : profile?.RoleId === 1 ? (
                <Router />
              ) : (
                <EmployeeRouter />
              )
            ) : (
              <TrialExpiredRouter />
            )
          ) : isTokenAuthorized !== null ? (
            <SessionRouter />
          ) : (
            <AuthRouter />
          )}
        </Suspense>
      </>
    );
  };

  return (
    <CacheBuster
      currentVersion={pkgInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
    >
      <ThemeProvider>
        <PermissionProvider permissions={profile?.Permissions}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <GlobalStyles />
            <BrowserRouter>
              {isAuthenticated ? (
                <StyledDiv>{routerInfo()}</StyledDiv>
              ) : (
                <div>{routerInfo()}</div>
              )}
            </BrowserRouter>
            <ToastContainer
              position="bottom-right"
              autoClose={3000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
          </LocalizationProvider>
        </PermissionProvider>
      </ThemeProvider>
    </CacheBuster>
  );
};

const mapDispatchToProps = {
  loadCustomers: fetchCustomers,
  loadTypes: fetchAllTypes,
  loadEmployees: fetchEmployees,
  loadServices: fetchServices,
  loadProducts: fetchProducts,
  loadTaxes: fetchTaxes,
  loadPackages: fetchPackages,
  loadInventory: fetchInventory,
};

export default connect(null, mapDispatchToProps)(App);
