import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getAllNotifications = async (token, filters) => {
  const url = new URL(`${baseUrl}/Notifications`);

  for (const [key, value] of Object.entries(filters)) {
    if (value !== null && value !== undefined && value !== "") {
      url.searchParams.append(key, value);
    }
  }

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getAllNotificationsCount = async (token) => {
  const url = new URL(`${baseUrl}/Notifications/Count`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putNotificationMarkRead = async (token, notificaionId) => {
  const url = new URL(
    `${baseUrl}/Notifications/MarkAsRead?NotificationId=${notificaionId}`
  );

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putNotificationMarkUnread = async (token, notificaionId) => {
  const url = new URL(
    `${baseUrl}/Notifications/MarkAsUnRead?NotificationId=${notificaionId}`
  );

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putNotificationDelete = async (token, notificaionId) => {
  const url = new URL(
    `${baseUrl}/Notifications/Delete?NotificationId=${notificaionId}`
  );

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putNotificationMarkallAsRead = async (token, body) => {
  const url = new URL(`${baseUrl}/Notifications/MarkAllAsRead`);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getAllNotificationRules = async (token) => {
  const url = new URL(`${baseUrl}/Notifications/PreferenceRules`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putNotificationPreference = async (token, body) => {
  const url = new URL(`${baseUrl}/Notifications/EditPreferences`);

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};
