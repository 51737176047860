import { styled } from "@mui/material/styles";
import ResetPassword from "./ResetPassword";

const MainContainer = styled("main")(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(3),
}));

// necessary for content to be below app bar
const ToolbarContainer = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

const Container = (children) => {
  return (
    <MainContainer>
      <ToolbarContainer />
      <ResetPassword />
    </MainContainer>
  );
};

export default Container;
