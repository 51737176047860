import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Buffer } from "buffer";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteFile, getAllFiles } from "src/api/fileUploadApi";
import { AuthContext } from "src/auth/AuthProvider";
import AttachmentPrompt from "./AttachmentPrompt.js";
import DocumentImage from "./File.js";
import FileUploadDialog from "./FileUploadDialog.js";
import { toast } from "react-toastify";
import moment from "moment/moment.js";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import DeleteConfirmationDialog from "src/components/Common/DeleteConfirmationDialog";
import {
  Add,
  CloudUpload,
  Delete,
  Download,
  Visibility,
  BrokenImage,
} from "@mui/icons-material";

const FileUpload = ({
  Directory,
  isFirst,
  FieldId,
  FieldTypeId,
  FieldName,
  Title,
  IsEditable = true,
  setValue,
  getValues,
}) => {
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [update, setIsUpdated] = useState(0);
  const [isLoading, setLoading] = useState(true);

  const { t } = useTranslation();

  const [isNew, setIsNew] = useState(isFirst);
  const theme = useTheme();
  const auth = useContext(AuthContext);
  const profile = auth.getProfile();
  const token = auth.getAuthToken();

  const fetchDocuments = () => {
    setLoading(true);
    if (FieldName === "Inventory") {
      setAcceptedFiles(getValues("ProductImages"));
      setLoading(false);
    } else {
      getAllFiles(token, {
        FieldId: FieldId,
        FieldTypeId: FieldTypeId,
        FieldName: FieldName,
      })
        .then((response) => {
          setLoading(false);
          setAcceptedFiles(response?.DATA);
        })
        .catch((err) => {
          toast.error(t("toasts.fetchDocumentFailed"));
          setLoading(false);
        });
    }
  };

  const [viewDialog, setViewDialog] = useState(false);
  const [id, setId] = useState(null);

  const deleteDocument = (file, index) => {
    setViewDialog(true);
    setLoading(true);
    setId({ fileId: file.FileId, fileIndex: index });
  };

  const [isHovered, setHovered] = useState(null);

  const handleMouseOver = (e, index) => {
    setHovered(index);
  };

  const handleMouseLeave = () => {
    setHovered(null);
  };

  const viewFile = (File) => {
    if (File.FileByte != null) {
      const binaryBuffer = Buffer.from(File.FileByte, "base64");
      const blob = new Blob([binaryBuffer], {
        type: `${File.ContentType};charset=utf-8`,
      });
      window.open(URL.createObjectURL(blob), File.FileName);
    } else {
      window.open(URL.createObjectURL(File.File), File.FileName);
    }
  };

  const downloadFile = (File) => {
    const downloadLink = document.createElement("a");

    if (File.FileByte != null) {
      const binaryBuffer = Buffer.from(File.FileByte, "base64");
      const blob = new Blob([binaryBuffer], {
        type: `${File.ContentType};charset=utf-8`,
      });

      downloadLink.href = URL.createObjectURL(blob);
    } else {
      downloadLink.href = URL.createObjectURL(File.File);
    }

    downloadLink.download = File.FileName;
    downloadLink.click();
  };

  // FileUpload Prompt handling code
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchDocuments();
  }, [update]);

  /** ---------------------- Google Service account permission----------------------- */

  const isPermissionGranted = profile.IsServiceAccount || false;

  /** --------------------------------------------- */

  return (
    <>
      {!!id && viewDialog && (
        <DeleteConfirmationDialog
          deleteApiCall={deleteFile}
          id={id.fileId}
          token={token}
          callback={() => {
            setLoading(false);
            setViewDialog(false);

            setAcceptedFiles((prevFiles) =>
              prevFiles.filter((file, i) => i !== id.fileIndex)
            );
          }}
          cancelCallBack={() => {
            setLoading(false);
            setViewDialog(false);
          }}
          viewDialog={viewDialog}
          isFileDelete={true}
        />
      )}

      <FileUploadDialog
        Directory={Directory}
        FieldId={FieldId}
        FieldTypeId={FieldTypeId}
        FieldName={FieldName}
        token={token}
        files={acceptedFiles}
        setIsUpdated={setIsUpdated}
        open={open}
        onClose={handleClose}
        setValue={setValue}
      />
      {!isNew && (
        <>
          <Grid item xs={12} mt={2}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h4"
              fontWeight={
                FieldName === "Inventory"
                  ? theme.typography.fontWeightBold
                  : theme.typography.fontWeightMedium
              }
            >
              {Title ? Title : t("headings.attachments")}
            </Typography>
          </Grid>

          <Grid item xs={12} alignItems="flex-start">
            <Container
              sx={{
                border: "1px solid #CACACA",
                p: 1,
                borderRadius: 3,
                height: 150,
              }}
            >
              {!isLoading ? (
                <>
                  {acceptedFiles?.filter((obj) => !obj.IsDelete)?.length > 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <ImageList
                        sx={{
                          overflowX: "auto",
                          WebkitOverflowScrolling: "touch",
                          scrollbarWidth: 1,
                          display: "flex",
                        }}
                        cols={acceptedFiles?.length}
                      >
                        {acceptedFiles?.map(
                          (file, index) =>
                            !file.IsDelete && (
                              <ImageListItem
                                key={index}
                                onMouseOver={(e) => handleMouseOver(e, index)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  height: 100,
                                  width: 100,
                                  marginBottom: 10,
                                }}
                              >
                                {isHovered != index ? (
                                  file.ContentType?.startsWith("image/") ? (
                                    file.FileByte || file?.File ? (
                                      <img
                                        style={{
                                          width: 100,
                                          height: 100,
                                          padding: 10,
                                        }}
                                        src={
                                          file.FileByte
                                            ? `data:image/png;base64,${file.FileByte}`
                                            : URL.createObjectURL(file?.File)
                                        }
                                        loading="lazy"
                                      />
                                    ) : (
                                      <BrokenImage
                                        fontSize="large"
                                        sx={{
                                          height: 100,
                                          width: 100,
                                          color: theme.palette.secondary.dark,
                                        }}
                                      />
                                    )
                                  ) : (
                                    <DocumentImage
                                      height={100}
                                      width={100}
                                      style={{ padding: 10 }}
                                    />
                                  )
                                ) : (
                                  <Paper
                                    sx={{
                                      position: "relative",
                                      height: 100,
                                      width: 100,
                                    }}
                                  >
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      justifyContent="center"
                                      sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        left: "50%",
                                        transform: "translateX(-50%)",
                                        paddingBottom: 1, // Optional: Add some padding to the bottom
                                      }}
                                    >
                                      <IconButton
                                        onClick={() => viewFile(file)}
                                        size="small"
                                      >
                                        <Visibility fontSize="small" />
                                      </IconButton>
                                      <IconButton
                                        onClick={() => downloadFile(file)}
                                        size="small"
                                      >
                                        <Download fontSize="small" />
                                      </IconButton>
                                      {FieldName !== "Inventory" && (
                                        <IconButton
                                          onClick={() =>
                                            deleteDocument(file, index)
                                          }
                                          size="small"
                                        >
                                          <Delete fontSize="small" />
                                        </IconButton>
                                      )}
                                    </Stack>
                                  </Paper>
                                )}
                              </ImageListItem>
                            )
                        )}
                      </ImageList>
                      {IsEditable && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "auto",
                          }}
                        >
                          <Tooltip title={t("toolTips.addDocument")} arrow>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleOpen}
                              width="40px"
                              sx={{
                                width: "40px!important",
                                height: "40px",
                                borderRadius: "20px",
                              }}
                            >
                              <Add />
                            </Button>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ minHeight: 150 }}
                    >
                      <IconButton fontSize="large">
                        <CloudUpload />
                      </IconButton>
                      <Typography variant="h6" color="textSecondary">
                        {!isPermissionGranted
                          ? t("messages.contactTheTeamForActivation")
                          : t("messages.emptyDocument")}
                      </Typography>
                      {IsEditable && isPermissionGranted && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpen}
                          sx={{ margin: 1 }}
                        >
                          {t("buttons.addDocument")}
                        </Button>
                      )}
                    </Stack>
                  )}
                </>
              ) : (
                <Box
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    minHeight: 150,
                  }}
                >
                  <CircularProgress color="primary" size={60} />
                </Box>
              )}
            </Container>
          </Grid>
        </>
      )}
    </>
  );
};

export default FileUpload;
