export function getStatusColor(status, theme) {
  switch (status) {
    case "Active":
      return theme.palette.statusChips.active.background;
    case "Inactive":
      return theme.palette.statusChips.inactive.background;
    case "New":
      return theme.palette.statusChips?.new?.background;
    case "Pending":
      return theme.palette.statusChips?.pending?.background;
    case "Ongoing":
      return theme.palette.statusChips?.ongoing?.background;
    case "Approved":
      return theme.palette.statusChips?.approved?.background;
    case "Completed":
      return theme.palette.statusChips?.completed?.background;
    case "Overdue":
      return theme.palette.statusChips?.overdue?.background;
    case "Void":
      return theme.palette.statusChips.void.background;
    case "Cancelled":
      return theme.palette.statusChips?.cancelled?.background;
    case "Denied":
      return theme.palette.statusChips.denied.background;
    case "Closed":
      return theme.palette.statusChips.closed.background;
    case "Converted to Jobs":
      return theme.palette.statusChips.convertedJob.background;
    case "Converted to Quote":
      return theme.palette.statusChips.convertedQuote.background;
    case "Converted to Invoice":
      return theme.palette.statusChips?.convertedInvoice?.background;
    case "Online booking":
      return theme.palette.statusChips.onlineBooking.background;
    case "Email Sent":
      return theme.palette.statusChips.emailSent.background;
    case "In Stock":
      return theme.palette.statusChips.inStock.background;
    case "Out of Stock":
      return theme.palette.statusChips.outOfStock.background;
    case "Low Stock":
      return theme.palette.statusChips.lowStock.background;
    default:
      return "";
  }
}

export function getStatusTextColor(status, theme) {
  switch (status) {
    case "Active":
      return theme.palette.statusChips.active.color;
    case "Inactive":
      return theme.palette.statusChips.inactive.color;
    case "New":
      return theme.palette.statusChips?.new?.color;
    case "Pending":
      return theme.palette.statusChips?.pending?.color;
    case "Ongoing":
      return theme.palette.statusChips?.ongoing?.color;
    case "Approved":
      return theme.palette.statusChips.approved.color;
    case "Completed":
      return theme.palette.statusChips?.completed?.color;
    case "Overdue":
      return theme.palette.statusChips?.overdue?.color;
    case "Void":
      return theme.palette.statusChips.void.color;
    case "Cancelled":
      return theme.palette.statusChips?.cancelled?.color;
    case "Denied":
      return theme.palette.statusChips.denied.color;
    case "Closed":
      return theme.palette.statusChips?.closed?.color;
    case "Converted to Jobs":
      return theme.palette.statusChips.convertedJob.color;
    case "Converted to Quote":
      return theme.palette.statusChips.convertedQuote.color;
    case "Converted to Invoice":
      return theme.palette.statusChips?.convertedInvoice?.color;
    case "Online booking":
      return theme.palette.statusChips.onlineBooking.color;
    case "Email Sent":
      return theme.palette.statusChips.emailSent.color;
    case "In Stock":
      return theme.palette.statusChips.inStock.color;
    case "Out of Stock":
      return theme.palette.statusChips.outOfStock.color;
    case "Low Stock":
      return theme.palette.statusChips.lowStock.color;
    default:
      return "";
  }
}
