import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  switchTheme: !!JSON.parse(localStorage.getItem("switchTheme")),
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.switchTheme = !state.switchTheme;
    },
    setTheme: (state, action) => {
      state.switchTheme = action.payload;
    },
  },
});

export const { toggleTheme, setTheme } = themeSlice.actions;

export const asyncToggleTheme = (switchTheme) => (dispatch) => {
  const isswitchTheme = !!JSON.parse(localStorage.getItem("switchTheme"));
  localStorage.setItem("switchTheme", !isswitchTheme);
  dispatch(toggleTheme());
};

export default themeSlice.reducer;
