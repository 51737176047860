import { Check, HighlightOff } from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  Alert,
  Chip,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { MobileTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import DateTimePickerTheme from "src/components/Controls/DateTimePickerTheme";

const Assessment = ({
  setRequestDetails,
  AvailableFirstDate,
  AvailableSecondDate,
  createRequestAssessmentRequest,
  errors,
  err,
  dateFormat,
  timeFormat,
  AvailableFirstDateStart,
  AvailableSecondDateStart,
  AvailableFirstDateEnd,
  AvailableSecondDateEnd,
  employeeList,
  Employees,
  gridRef,
  isView = false,
  isViewable = false,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const filterCommonEmployeeId = (employeeList) => {
    const counts = employeeList.reduce((acc, employee) => {
      const employeeId = employee.EmployeeId;
      acc[employeeId] = (acc[employeeId] || 0) + 1;
      return acc;
    }, {});

    return employeeList.filter((employee) => counts[employee.EmployeeId] === 1);
  };

  return (
    <Grid item container spacing={2} ref={gridRef}>
      <Grid item lg={isViewable ? 12 : 12} container spacing={2}>
        <Grid item lg={12} xs={12} sm={12}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
            fontWeight={theme.typography.fontWeightBold}
          >
            {t("textFieldLables.date")}
          </Typography>

          <Autocomplete
            disablePortal
            disableClearable
            fullWidth
            disabled={isViewable}
            options={[
              !!AvailableFirstDate && {
                AvailableDate: moment(AvailableFirstDate).format("YYYY-MM-DD"),
                AvailableStartTime: moment(AvailableFirstDateStart).format(
                  "YYYY-MM-DDTHH:mm"
                ),
                AvailableEndTime: moment(AvailableFirstDateEnd).format(
                  "YYYY-MM-DDTHH:mm"
                ),
              },
              !!AvailableSecondDate && {
                AvailableDate: moment(AvailableSecondDate).format("YYYY-MM-DD"),
                AvailableStartTime: moment(AvailableSecondDateStart).format(
                  "YYYY-MM-DDTHH:mm"
                ),
                AvailableEndTime: moment(AvailableSecondDateEnd).format(
                  "YYYY-MM-DDTHH:mm"
                ),
              },
            ]}
            defaultValue={{
              AvailableDate: moment(AvailableFirstDate).format("YYYY-MM-DD"),
              AvailableStartTime: moment(AvailableFirstDateStart).format(
                "YYYY-MM-DDTHH:mm"
              ),
              AvailableEndTime: moment(AvailableFirstDateEnd).format(
                "YYYY-MM-DDTHH:mm"
              ),
            }}
            value={{
              AvailableDate: moment(
                createRequestAssessmentRequest?.StartDate
              ).format("YYYY-MM-DD"),
              AvailableStartTime: moment(
                createRequestAssessmentRequest?.StartTime
              ).format("YYYY-MM-DDTHH:mm"),
              AvailableEndTime: moment(
                createRequestAssessmentRequest?.EndTime
              ).format("YYYY-MM-DDTHH:mm"),
            }}
            onChange={(e, newValue) => {
              if (!isView) {
                setRequestDetails((req) => ({
                  ...req,
                  createRequestAssessmentRequest: {
                    ...req.createRequestAssessmentRequest,
                    StartDate: moment(newValue?.AvailableDate).format(
                      "YYYY-MM-DDTHH:mm"
                    ),
                    StartTime: moment(newValue?.AvailableStartTime).format(
                      "YYYY-MM-DDTHH:mm"
                    ),
                    EndTime:
                      moment(newValue?.AvailableEndTime).diff(
                        moment(newValue?.AvailableStartTime),
                        "hours"
                      ) >= 1
                        ? moment(newValue?.AvailableStartTime)
                            .add(1, "hour")
                            .format("YYYY-MM-DDTHH:mm")
                        : moment(newValue?.AvailableEndTime).format(
                            "YYYY-MM-DDTHH:mm"
                          ),
                  },
                }));
              } else {
                setRequestDetails(
                  "Assessment[0].StartDate",
                  newValue?.AvailableDate
                );

                setRequestDetails(
                  "Assessment[0].StartTime",
                  moment(newValue?.AvailableStartTime).format(
                    "YYYY-MM-DDTHH:mm"
                  )
                );

                setRequestDetails(
                  "Assessment[0].EndTime",
                  moment(newValue?.AvailableEndTime).diff(
                    moment(newValue?.AvailableStartTime),
                    "hours"
                  ) >= 1
                    ? moment(newValue?.AvailableStartTime)
                        .add(1, "hour")
                        .format("YYYY-MM-DDTHH:mm")
                    : moment(newValue?.AvailableEndTime).format(
                        "YYYY-MM-DDTHH:mm"
                      )
                );
              }
            }}
            renderInput={(params) => <TextField {...params} error={!!err} />}
            getOptionLabel={(option) => option.AvailableDate}
            isOptionEqualToValue={(option, value) =>
              option.AvailableDate === value.AvailableDate
            }
            renderOption={(props, option) =>
              option.AvailableDate !== undefined && (
                <Grid
                  container
                  {...props}
                  key={
                    option.AvailableDate +
                    option.AvailableStartTime +
                    option.AvailableEndTime
                  }
                >
                  <Grid item xs={"auto"}>
                    {moment(option.AvailableDate).format(dateFormat)}
                  </Grid>
                  <Grid item xs={"auto"} pl={1} pr={1}>
                    {"-"}
                  </Grid>
                  <Grid item xs={"auto"} pr={1}>
                    {moment(option.AvailableStartTime).format(timeFormat)}
                  </Grid>
                  <Grid item xs={"auto"}>
                    {moment(option.AvailableEndTime).format(timeFormat)}
                  </Grid>
                </Grid>
              )
            }
          />
        </Grid>
        <Grid item lg={6} xs={12} md={6}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
            fontWeight={theme.typography.fontWeightBold}
          >
            {t("textFieldLables.startTime")}
          </Typography>

          <DateTimePickerTheme>
            <MobileTimePicker
              placeholder={t("labels.startTime")}
              inputFormat={timeFormat}
              ampm={false}
              disabled={isViewable}
              value={
                createRequestAssessmentRequest?.StartTime ||
                AvailableFirstDateStart
              }
              onChange={(event) => {
                if (!isView) {
                  setRequestDetails((req) => ({
                    ...req,
                    createRequestAssessmentRequest: {
                      ...req.createRequestAssessmentRequest,
                      StartTime: event?._d
                        ? moment(event?._d).format("YYYY-MM-DDTHH:mm")
                        : "",
                    },
                  }));
                } else {
                  setRequestDetails(
                    "Assessment[0].StartTime",
                    event?._d
                      ? moment(event?._d).format("YYYY-MM-DDTHH:mm")
                      : ""
                  );
                }
              }}
              componentsProps={{
                actionBar: {
                  actions: ["cancel", "accept"],
                },
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  size="small"
                  {...params}
                  name="StartTime"
                  placeholder={t("labels.enterTime")}
                  variant="outlined"
                  error={!!err}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccessTimeIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </DateTimePickerTheme>
        </Grid>
        <Grid item lg={6} xs={12} md={6}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
            fontWeight={theme.typography.fontWeightBold}
          >
            {t("textFieldLables.endTime")}
          </Typography>

          <DateTimePickerTheme>
            <MobileTimePicker
              placeholder={t("labels.endTime")}
              inputFormat={timeFormat}
              ampm={false}
              disabled={isViewable}
              value={createRequestAssessmentRequest?.EndTime}
              onChange={(event) => {
                if (!isView) {
                  setRequestDetails((req) => ({
                    ...req,
                    createRequestAssessmentRequest: {
                      ...req.createRequestAssessmentRequest,
                      EndTime: event?._d
                        ? moment(event?._d).format("YYYY-MM-DDTHH:mm")
                        : "",
                    },
                  }));
                } else {
                  setRequestDetails(
                    "Assessment[0].EndTime",
                    event?._d
                      ? moment(event?._d).format("YYYY-MM-DDTHH:mm")
                      : ""
                  );
                }
              }}
              componentsProps={{
                actionBar: {
                  actions: ["cancel", "accept"],
                },
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  size="small"
                  {...params}
                  name="EndTime"
                  placeholder={t("labels.enterTime")}
                  variant="outlined"
                  error={!!err}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccessTimeIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </DateTimePickerTheme>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        lg={isViewable ? 12 : 12}
        mt={isViewable ? 2 : 2}
      >
        <Typography
          color="textSecondary"
          gutterBottom
          variant="h6"
          fontWeight={theme.typography.fontWeightBold}
          sx={{ mb: isView ? 6 : 0, mt: -2 }}
        >
          {t("textFieldLables.employees")}
        </Typography>

        <Select
          name={"AddEmployee"}
          multiple
          placeholder={t("textFieldLables.employees")}
          disabled={isViewable}
          fullWidth={true}
          value={Employees?.filter((item) => !item?.IsDelete) || []}
          onChange={(e) => {
            if (!isView) {
              setRequestDetails((details) => ({
                ...details,
                createRequestAssessmentRequest: {
                  ...details.createRequestAssessmentRequest,
                  Employees: filterCommonEmployeeId(e.target.value),
                },
              }));
            } else {
              const selectedEmployee = e.target.value;

              let updatedEmployees = [];
              updatedEmployees = Employees?.map((employee) => {
                const isInSelected = selectedEmployee?.some(
                  (selected) => selected?.EmployeeId === employee?.EmployeeId
                );

                const shouldUpdate = !isInSelected;

                return {
                  ...employee,
                  IsDelete: shouldUpdate ? true : false,
                };
              });
              updatedEmployees = !updatedEmployees ? [] : updatedEmployees;
              selectedEmployee.forEach((selected) => {
                const isExistingEmployee = Employees?.some(
                  (employee) => employee?.EmployeeId === selected?.EmployeeId
                );

                if (!isExistingEmployee) {
                  const newEmployee = {
                    ...selected,
                    IsDelete: false,
                  };

                  updatedEmployees.push(newEmployee);
                }
              });
              const newSelectedEmployee = updatedEmployees.filter(
                (employee) =>
                  !employee.IsDelete ||
                  (employee?.ScheduleId !== undefined &&
                    employee.ScheduleId > 0)
              );

              setRequestDetails("Assessment[0].Employees", newSelectedEmployee);
            }
          }}
          error={errors}
          labelId="select-label-id"
          renderValue={(selected) => (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {selected?.map((value) => (
                <Chip
                  key={value?.EmployeeId}
                  label={value?.EmployeeName}
                  deleteIcon={
                    <HighlightOff
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                  sx={{
                    backgroundColor: theme.palette.input.dark,
                    color: theme.palette.primary.contrastText,
                    "& .MuiChip-deleteIcon": {
                      color: theme.palette.input.light,
                      "&:hover": {
                        color: theme.palette.statusChips.delete.color,
                      },
                    },
                  }}
                />
              ))}
            </Stack>
          )}
          sx={{
            minHeight: "5rem",
            borderRadius: "5px",
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary.main,
            },
            mt: isViewable ? -6 : 0,
          }}
        >
          {employeeList?.map((object, index) => (
            <MenuItem
              key={index}
              value={
                !isView
                  ? {
                      EmployeeId: object?.EmployeeId || 0,
                      EmployeeName: object?.EmployeeName || "",
                    }
                  : Employees?.find(
                      (item) => item?.EmployeeId === object?.EmployeeId
                    ) || {
                      EmployeeId: object?.EmployeeId || 0,
                      EmployeeName: object?.EmployeeName || "",
                      IsDelete: false,
                    }
              }
              sx={{ justifyContent: "space-between" }}
            >
              {object?.EmployeeName || ""}
              {Employees?.some(
                (item) =>
                  item?.EmployeeId === object?.EmployeeId && !item?.IsDelete
              ) && <Check sx={{ color: theme.palette.primary.dark }} />}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      {!!err && (
        <Grid
          container
          key={"error-rec"}
          item
          md={12}
          xs={12}
          spacing={2}
          sx={{ mt: 2, mb: 2 }}
        >
          <Alert severity="error" auto fullWidth>
            {err}
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default Assessment;
