export function calculateTotalAndNewTotal({
  lineItems,
  newServiceItems,
  newProductItems,
  newPackageItems,
  taxTotal = 0,
  getValues,
}) {
  let calculatedTotal = 0;
  let serviceTotal = 0;
  let productTotal = 0;
  let packageTotal = 0;
  let totalSubTotal = 0;
  let markupTotal = 0;

  // Calculate totals for lineItems
  lineItems?.length > 0 &&
    getValues(lineItems)?.forEach((item) => {
      if (!item.IsDelete) {
        const lineItemTotal = parseFloat(item.Total) || 0;
        calculatedTotal += lineItemTotal;
        totalSubTotal += lineItemTotal;
        const markUp = parseFloat(item?.MarkUp) || 0;
        const qty = parseFloat(item?.Qty) || 0;


        if (item.ServiceId > 0) {
          serviceTotal += lineItemTotal;
        } else if (item.ProductId > 0) {
          productTotal += lineItemTotal;
          markupTotal += markUp * qty;
        } else {
          packageTotal += lineItemTotal;
        }
      }
    });

  // Calculate totals for newServiceItems using reduce
  if (newServiceItems?.length > 0) {
    calculatedTotal += (getValues(newServiceItems) || [])
      ?.filter((item) => !item.IsDelete)
      ?.reduce((accumulator, item) => {
        const lineItemTotal = parseFloat(item.Total) || 0;
        totalSubTotal += lineItemTotal;
        serviceTotal += lineItemTotal;
        return accumulator + lineItemTotal;
      }, 0);
  }

  // Calculate totals for newProductItems using reduce
  if (newProductItems?.length > 0) {
    calculatedTotal += (getValues(newProductItems) || [])
      ?.filter((item) => !item.IsDelete)
      ?.reduce((accumulator, item) => {
        const lineItemTotal = parseFloat(item.Total) || 0;

        const markUp = parseFloat(item?.MarkUp) || 0;
        const qty = parseFloat(item?.Qty) || 0;

        totalSubTotal += lineItemTotal;
        productTotal += lineItemTotal;

        markupTotal += markUp * qty;
        return accumulator + lineItemTotal;
      }, 0);
  }

  // Calculate totals for newPackageItems using reduce
  if (newPackageItems?.length > 0) {
    calculatedTotal += (getValues(newPackageItems) || [])
      ?.filter((item) => !item.IsDelete)
      ?.reduce((accumulator, item) => {
        const lineItemTotal = parseFloat(item.Total) || 0;
        totalSubTotal += lineItemTotal;
        packageTotal += lineItemTotal;
        return accumulator + lineItemTotal;
      }, 0);
  }

  // Calculate totalNewTotal
  const totalNewTotal = totalSubTotal + taxTotal;

  return {
    subTotal: calculatedTotal,
    totalTotal: calculatedTotal + taxTotal,
    subNewTotal: totalSubTotal,
    totalNewTotal: totalNewTotal,
    serviceTotal: serviceTotal,
    productTotal: productTotal,
    markupTotal: markupTotal,
    packageTotal: packageTotal,
  };
}
