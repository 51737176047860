export function CsvBuilder(data, fileName, columns, setCsvLoading) {
  const csvContent = convertToCSV(data, columns);
  const excelContent =
    "data:application/vnd.ms-excel;base64," +
    btoa(unescape(encodeURIComponent(csvContent)));

  const link = document.createElement("a");
  link.href = excelContent;
  link.download = fileName || "exported_data.xls";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  setCsvLoading(false);
}

function convertToCSV(data, columns) {
  const header = getCSVHeader(data[0], columns) + "\n";
  const rows = data.map((row) => getCSVRow(row, columns) + "\n");
  return header + rows.join("");
}

function getCSVHeader(obj, columns) {
  const headers = [];
  for (const key in columns) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        const nestedHeaders = getCSVHeader(obj[key], columns[key]);

        if (Array.isArray(nestedHeaders)) {
          nestedHeaders.forEach((nestedHeader) => {
            headers.push(nestedHeader); //columns[key] + "." +
          });
        } else {
          headers.push(columns[key]);
        }
      } else {
        headers.push(columns[key]);
      }
    }
  }

  return headers;
}

function getCSVRow(obj, columns) {
  const values = [];
  for (const key in columns) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        const nestedValues = getCSVRow(obj[key], columns[key]);
        values.push(nestedValues.join(","));
      }
      if (key === "IsActive") {
        values.push(obj[key] ? "Active" : "In Active");
      } else {
        values.push(String(obj[key]));
      }
    }
  }

  return values;
}

//i have this for testing purpose once finished i will remove it
// let keys = [
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
//   "title",
//   "id",
//   "url",
//   "thumbnailUrl",
// ];
