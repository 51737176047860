import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllRequests, getRequestById } from "src/api/requestApi";

export const fetchRequests = createAsyncThunk(
  "requests/fetchRequests",
  async ({ token }, { rejectWithValue }) => {
    return getAllRequests(token, {})
      .then((data) => {
        return data.DATA;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const fetchFilterRequests = createAsyncThunk(
  "requests/fetchFilterRequests",
  async ({ token, filters }, { rejectWithValue }) => {
    return getAllRequests(token, filters)
      .then((data) => {
        return data.DATA;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const fetchRequest = createAsyncThunk(
  "requests/fetchRequest",
  async ({ token, requestId }, { rejectWithValue }) => {
    return getRequestById(token, requestId)
      .then((data) => {
        return data.DATA;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

const initialState = {
  isLoading: false,
  error: null,
  data: [],
  filters: {
    SortBy: "",
    Filter: "",
    Parameter: "",
  },
  requestLoading: false,
  request: null,
  selectedRequest: {},
};

const Slice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    filtersToolBar(state, action) {
      state.filters.SortBy = action.payload.SortBy;
      state.filters.Filter = action.payload.Filter;
      state.filters.Parameter = action.payload.Parameter;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequests.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchRequests.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchRequests.rejected, (state, action) => {
        state.error = action.payload.STATUS_CODE;
        state.isLoading = false;
        throw action.payload;
      })
      .addCase(fetchFilterRequests.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchFilterRequests.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchFilterRequests.rejected, (state, action) => {
        state.error = action.payload.STATUS_CODE;
        state.isLoading = false;
        throw action.payload;
      })
      .addCase(fetchRequest.pending, (state, action) => {
        state.requestLoading = true;
      })
      .addCase(fetchRequest.fulfilled, (state, action) => {
        state.request = action.payload;
        state.requestLoading = false;
      })
      .addCase(fetchRequest.rejected, (state, action) => {
        state.error = action.payload.STATUS_CODE;
        state.requestLoading = false;
        throw action.payload;
      });
  },
});

export const { filtersToolBar } = Slice.actions;

export default Slice.reducer;
