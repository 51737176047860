import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import customerReducer from "./Slices/Customer";
import typeReducer from "./Slices/Type";
import jobReducer from "./Slices/Job";
import invoiceReducer from "./Slices/Invoice";
import requestReducer from "./Slices/Request";
import QuoteReducer from "./Slices/Quote";
import commonReducer from "./Slices/Common";
import employeeReducer from "./Slices/Employee";
import serviceReducer from "./Slices/Service";
import productReducer from "./Slices/Product";
import storeHoursReducer from "./Slices/StoreHour";
import holidaysReducer from "./Slices/Holiday";
import termsReducer from "./Slices/TermsAndConditions";
import weekEntryReducer from "./Slices/Track/WeekTimeTrack";
import dayEntryReducer from "./Slices/Track/DayTimeTrack";
import taxReducer from "./Slices/Tax";
import scheduleReducer from "./Slices/Schedule";
import addressReducer from "./Slices/Address";
import themeReducer from "./Slices/Theme";
import authReducer from "./Slices/Auth";
import packageReducer from "./Slices/Package";
import InventoryReducer from "./Slices/Inventory";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: ["auth"],
};

const AppReducer = combineReducers({
  auth: authReducer,
  customer: customerReducer,
  type: typeReducer,
  job: jobReducer,
  invoice: invoiceReducer,
  request: requestReducer,
  quote: QuoteReducer,
  common: commonReducer,
  employee: employeeReducer,
  package: packageReducer,
  product: productReducer,
  service: serviceReducer,
  dayEntry: dayEntryReducer,
  weekEntry: weekEntryReducer,
  storeHour: storeHoursReducer,
  holiday: holidaysReducer,
  term: termsReducer,
  address: addressReducer,
  tax: taxReducer,
  schedule: scheduleReducer,
  theme: themeReducer,
  inventory: InventoryReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    localStorage.clear();
    state = {};
  }
  return AppReducer(state, action);
};

export { rootPersistConfig, rootReducer };
