import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getAllCustomers = async (token, filters = {}) => {
  const url = new URL(`${baseUrl}/Customer`);

  // url.searchParams.append("ClientId", clientId);

  for (const [key, value] of Object.entries(filters)) {
    if (value !== null && value !== undefined && value !== "") {
      url.searchParams.append(key, value);
    }
  }

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getCustomerById = async (token, customerId) => {
  const url = new URL(`${baseUrl}/Customer/id?CustomerId=${customerId}`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postCustomer = async (token, clientId, body) => {
  const url = new URL(`${baseUrl}/Customer`);

  url.searchParams.append("ClientId", clientId);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putCustomer = async (token, customerId, body) => {
  const url = new URL(`${baseUrl}/Customer/id?CustomerId=${customerId}`);

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const deleteCustomer = async (token, id, updatedDate) => {
  const url = new URL(`${baseUrl}/Customer/${id}`);

  let options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ updatedDate }),
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
