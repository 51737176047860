import { baseUrl, handleResponse, handleError } from "./apiUtils";

export const getAllUserRoles = async (token, clientId) => {
  const url = new URL(`${baseUrl}/UserRole`);

  // url.searchParams.append("ClientId", clientId);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getUserRoleById = async (token, clientId, userId) => {
  const url = new URL(`${baseUrl}/UserRole/id`);

  url.searchParams.append("ClientId", clientId);
  url.searchParams.append("UserId", userId);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postUserRole = async (token, body) => {
  const url = new URL(`${baseUrl}/UserRole`);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putUserRole = async (token, userId, body) => {
  const url = new URL(`${baseUrl}/UserRole/id`);

  url.searchParams.append("UserId", userId);

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};
