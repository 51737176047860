import { baseUrl, handleResponse, handleError } from "./apiUtils";
import { addingParams } from "./apiUtils";

export const getAllInvoices = async (token, filters) => {
  const url = new URL(`${baseUrl}/Invoice`);

  for (const [key, value] of Object.entries(filters)) {
    if (value !== null && value !== undefined && value !== "") {
      url.searchParams.append(key, value);
    }
  }

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getInvoiceById = async (token, invoiceId) => {
  const url = new URL(`${baseUrl}/Invoice/id?InvoiceId=${invoiceId}`);

  // url.searchParams.append("ClientId", clientId);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getInvoiceByCustomerId = async (
  token,
  clientId,
  customerId,
  filters
) => {
  const url = addingParams(
    filters,
    `${baseUrl}/Invoice/Customer/${customerId}`
  );
  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postInvoice = async (token, body) => {
  const url = new URL(`${baseUrl}/Invoice`);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putInvoice = async (token, invoiceId, body) => {
  const url = new URL(`${baseUrl}/Invoice/id?InvoiceId=${invoiceId}`);
  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const postPDFConfig = async (token, body) => {
  const url = new URL(`${baseUrl}/Invoice/SavePDF`);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getPDFConfiguration = async (token, ClientId) => {
  const url = new URL(`${baseUrl}/Invoice/PDFDetails?ClientId=${ClientId}`);

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const putPdf = async (token, pdfId, body) => {
  const url = new URL(`${baseUrl}/Invoice/Pdf?PDFLogId=${pdfId}`);
  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const savePDFDocument = async (data, token) => {
  const url = new URL(`${baseUrl}/Invoice/SavePDFDocuments`);

  let options = {
    method: "POST",
    body: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options)
    .then((response) => response.text())
    .catch(handleError);
};

export const getPDFDocuments = async (token, clientId, type) => {
  const url = new URL(`${baseUrl}/Invoice/GetPDFDocuments`);

  url.searchParams.append("ClientId", clientId);
  url.searchParams.append("TemplateType", type);

  let options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/pdf",
    },
  };

  return fetch(url, options).then((response) => {
    return response.blob();
  });
};

export const deleteInvoice = async (token, id, updatedDate) => {
  const url = new URL(`${baseUrl}/Invoice/${id}`);

  let options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ updatedDate }),
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
