import { useTheme } from "@mui/material/styles";
import { Chip, useMediaQuery } from "@mui/material";
import {
  getStatusColor,
  getStatusTextColor,
} from "src/components/Common/TabTable/StatusColor";

const StatusChips = ({ status }) => {
  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <div>
      <div className="centered-content">
        {isUpMd ? (
          <Chip
            label={status}
            variant="outlined"
            style={{
              width: "90%",
              border: "none",
              textAlign: "center",
              backgroundColor: getStatusColor(status, theme),
              color: getStatusTextColor(status, theme),
              opacity: "60%",
            }}
          />
        ) : (
          <Chip
            label={status}
            variant="outlined"
            style={{
              width: "90%",
              height: "99%",
              border: "none",
              textAlign: "center",
              backgroundColor: getStatusColor(status, theme),
              color: getStatusTextColor(status, theme),
              opacity: "60%",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default StatusChips;
