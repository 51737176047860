import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAddress } from "src/api/addressApi";

export const fetchFilterAddresses = createAsyncThunk(
  "address/fetchfilterAddresses",
  async ({ token, customerId, filters }, { rejectWithValue }) => {
    return getAddress(token, customerId, filters)
      .then((data) => {
        return data.DATA;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

const initialState = {
  isLoading: false,
  data: [],
  error: null,
};

const Slice = createSlice({
  name: "address",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilterAddresses.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchFilterAddresses.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchFilterAddresses.rejected, (state, action) => {
        state.error = action.payload.STATUS_CODE;
        state.isLoading = false;
        throw action.payload;
      });
  },
});

export default Slice.reducer;
