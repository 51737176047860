import {
  Inject,
  ResourceDirective,
  ResourcesDirective,
  ScheduleComponent,
  TimelineViews,
  ViewDirective,
  ViewsDirective,
} from "@syncfusion/ej2-react-schedule";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { getEmployeeScheduledJobs } from "src/api/dispatchApi";
import { AuthContext } from "src/auth/AuthProvider";

const ScheduleComponentCustom = ({
  datePicker = {
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  },
  employeesData = [],
  height,
  isEdit,
}) => {
  const auth = useContext(AuthContext);
  const token = auth.getAuthToken();
  const [isLoading, setIsLoading] = useState(false);
  const [dValue, setDValue] = useState(datePicker);

  useEffect(() => {
    fetchScheduledJobs();
  }, [
    datePicker?.endDate,
    datePicker?.startDate,
    employeesData,
    isEdit,
    dValue,
  ]);

  const [scheduleData, setScheduleData] = useState(null);

  const fetchScheduledJobs = async () => {
    setIsLoading(true);

    await getEmployeeScheduledJobs(token, {
      Date: dValue?.startDate || moment().format("YYYY-MM-DD"),
      Start: dValue?.startDate || moment().format("YYYY-MM-DD"),
      End: dValue?.endDate || moment().format("YYYY-MM-DD"),
      Type: "all",
    }).then((response) => {
      setScheduleData(response?.DATA?.length > 0 ? response?.DATA : []);
      setIsLoading(false);
    });
  };

  const fieldsData = {
    id: "Id",
    subject: { name: "Title" },
    description: { name: "Instructions" },
    startTime: { name: "StartTime" },
    endTime: { name: "EndTime" },
  };

  const eventSettings = {
    dataSource: scheduleData,
    fields: fieldsData,
  };

  const onActionComplete = (args) => {
    let scheduleElement = document.getElementById("schedule");

    if (
      args.requestType === "dateNavigate" ||
      args.requestType === "viewNavigate"
    ) {
      let dateArray = scheduleElement
        .querySelector(".e-tbar-btn-text")
        .textContent.split(" ");

      if (dateArray[2].toString() === "-") {
        let startDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[4]}`;
        let endDate = `${dateArray[0]} ${dateArray[3]} ${dateArray[4]}`;

        setDValue((datePicker) => ({
          ...datePicker,
          startDate: moment(startDate, "MMMM DD, YYYY").format("YYYY-MM-DD"),
          endDate: moment(endDate, "MMMM DD, YYYY").format("YYYY-MM-DD"),
        }));
      } else {
        let startDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]}`;

        setDValue((datePicker) => ({
          ...datePicker,
          startDate: moment(startDate, "MMMM DD, YYYY").format("YYYY-MM-DD"),
          endDate: moment(startDate, "MMMM DD, YYYY").format("YYYY-MM-DD"),
        }));
      }
    }
  };

  return (
    <ScheduleComponent
      id="schedule"
      height={`${height}rem`}
      selectedDate={
        new Date(datePicker?.startDate || moment().format("YYYY-MM-DD"))
      }
      currentView="TimelineDay"
      timeScale={{ interval: 60, slotCount: 2 }}
      group={{
        resources: ["Employees"],
      }}
      eventSettings={!isLoading && eventSettings}
      readonly={true}
      delayUpdate={true}
      actionComplete={onActionComplete}
    >
      <ResourcesDirective>
        <ResourceDirective
          field="EmployeeId"
          title="Employee"
          name="Employees"
          dataSource={employeesData}
          textField="EmployeeName"
          idField="EmployeeId"
          colorField="Color"
        />
      </ResourcesDirective>
      <ViewsDirective>
        <ViewDirective option="TimelineDay" />
        <ViewDirective option="TimelineWeek" />
      </ViewsDirective>
      <Inject services={[TimelineViews]} />
    </ScheduleComponent>
  );
};

export default ScheduleComponentCustom;
