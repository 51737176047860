import { baseUrl, handleResponse, handleError, addingParams } from "./apiUtils";

export const resetPassword = async (token, body) => {
  const url = new URL(`${baseUrl}/user/resetpassword`);

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const checkUserName = async (token, username) => {
  const url = new URL(`${baseUrl}/user/CheckUserName?UserName=${username}`);
  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(body),
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const getAllUsers = async (token, filters) => {
  const url = addingParams(filters, `${baseUrl}/User`);
  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const createUser = async (token, body) => {
  const url = new URL(`${baseUrl}/User`);

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const editUser = async (token, id, body) => {
  const url = new URL(`${baseUrl}/User/id?UserId=${id}`);

  let options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options).then(handleResponse).catch(handleError);
};

export const checkUserCount = async (token, username) => {
  const url = new URL(`${baseUrl}/user/checkUserCount`);
  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, options).then(handleResponse).catch(handleError);
};
