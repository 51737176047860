import {
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { toast } from "react-toastify";
// import { postService, putService } from "src/api/serviceApi";
import { Delete } from "@mui/icons-material";
import { AuthContext } from "src/auth/AuthProvider";
import DialogContainer from "src/components/Common/DialogContainer";
import { useDispatch } from "src/redux/Store";
import * as yup from "yup";
// import { setQuote } from "src/redux/Slices/Quote";
import { Checkbox } from "@mui/material";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllQuestions,
  getAllSurvey,
  postQuestion,
  putQuestion,
} from "src/api/QuestionApi";
import DateTimePickerTheme from "src/components/Controls/DateTimePickerTheme";
import { putJob } from "src/api/jobApi";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  deleteSchedule,
  getScheduleById,
  postSchedule,
  putSchedule,
  getAllSchedules,
} from "src/api/scheduleApi";
import { object } from "prop-types";
import CircularProgress from "@mui/material";
import Loader from "../Track/Loader";
import { yupResolver } from "@hookform/resolvers/yup";

const EditSchedule = ({
  open,
  setOpen,
  isEdit = true,
  scheduleId,
  allschedules,
  // service,
  index,
  events,
  jobId,
  jobDetails,
  setJobDetails,
  // setQuestion,
  // setService,
  // loadData,
  loadServices,
  loadQuestions,
  ScheduleTypeId,
  loading,
  setLoading,
  isSecondDialog,
  setIsSecondDialog,
  detailsApi,
}) => {
  const auth = useContext(AuthContext);
  const profile = auth.getProfile();
  const token = auth.getAuthToken();
  const dateFormat = auth.getProfile()?.DateFormat;
  const timeFormat = auth.getProfile()?.TimeFormat;

  const [scheduleData, setScheduleData] = useState({});

  const [fetchLoading, setFetchLoading] = useState(false);

  const theme = useTheme();
  const dispatch = useDispatch();
  const handleClose = () => {
    setTimeout(() => {
      setOpen(false);
      setIsSecondDialog(false);
      // setService({});
      // setQuestion({});
      // loadData();
    }, 1000);
  };

  const { t } = useTranslation();

  // const isEdit = Object.keys(events)?.length > 0;

  const handleShowDateTimeFields = true;
  const isMannual = true;

  const validationSchema = yup.object().shape({
    Title: yup
      .string()
      .max(500, "Character limit exceeded")
      .required("Title Required"),
    StartDate: yup.string().required("Date Required"),
    // .test(
    //   "test",
    //   "Start Date should be before End Date!!",
    //   (value, testContext) => {
    //     if (value > testContext.parent.EndDate) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   }
    // ),
    EndDate: yup.string().required("Date Required"),
    // .test(
    //   "test",
    //   "End Date should be after Start Date!!",
    //   (value, testContext) => {
    //     if (value < testContext.parent.StartDate) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   }
    // ),
    StartTime: yup.string().required("Time Required"),
    // .test(
    //   "test",
    //   "Start Time should be before End Time!!",
    //   (value, testContext) => {
    //     if (
    //       (testContext.parent.StartDate === testContext.parent.EndDate &&
    //         value >= testContext.parent.EndTime) ||
    //       value === testContext.parent.EndTime
    //     ) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   }
    // ),
    EndTime: yup.string().required("Time Required"),
    // .test(
    //   "test",
    //   "End Time should be after Start Time!!",
    //   (value, testContext) => {
    //     if (
    //       (testContext.parent.StartDate === testContext.parent.EndDate &&
    //         value <= testContext.parent.StartTime) ||
    //       value === testContext.parent.StartTime
    //     ) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   }
    // ),
  });
  const scheduleDetails = {
    FieldId: parseInt(jobId),
    NumberOfEmployee: 0,
    ScheduleType: ScheduleTypeId,
    TypeName: "Visit",
    TypeId: ScheduleTypeId,
    Module: "Schedule",
    Title: "",
    Instructions: "",
    IsScheduled: true,
    StartDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
    StartTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
    EndDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
    EndTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
    IsDelete: false,
    IsAnytime: false,
    Employees: [],
    StatusId: 108,
  };

  const [schedules, setSchedules] = useState({});
  const fetchSchedule = async () => {
    setFetchLoading(true);
    await getScheduleById(token, scheduleId)
      .then((response) => {
        if (response?.STATUS_CODE !== "200") {
          toast.error(t("toasts.leadDetailsLoadingFailed"));
          setFetchLoading(false);
        } else {
          setScheduleData(response?.DATA);
          setFetchLoading(false);
        }
      })
      .catch((ex) => {
        console.log(ex);
        setFetchLoading(false);
      });
  };

  useEffect(() => {
    isEdit && fetchSchedule();
  }, []);

  useEffect(() => {
    if (!!scheduleData) {
      for (const [key, value] of Object.entries(scheduleData)) {
        setValue(key, value);
      }
    }
  }, [scheduleData]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: scheduleDetails,
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  watch();

  const [isSumbit, setIsSubmit] = useState(false);

  // const isOverlap = allschedules.filter(
  //   (item) => item.StartDate != getValues(`StartDate`).format("YYYY-MM-DD")
  // );

  // const isOverlap = (newStartDate, newEndDate, newStartTime, newEndTime) => {
  //   return allschedules.some((schedule) => {
  //     // Combine date and time into one moment object
  //     const scheduleStart = moment(
  //       `${schedule.StartDate}T${schedule.StartTime}`
  //     );
  //     const scheduleEnd = moment(`${schedule.EndDate}T${schedule.EndTime}`);

  //     const newStart = moment(`${newStartDate}T${newStartTime}`);
  //     const newEnd = moment(`${newEndDate}T${newEndTime}`);

  //     // Check for overlap
  //     return newStart.isBefore(scheduleEnd) && newEnd.isAfter(scheduleStart);
  //   });
  // };

  const onSubmit = async (values) => {
    toast.dismiss();

    let startDate = moment(getValues(`StartDate`)).format("YYYY-MM-DD");
    let startTime = moment(getValues(`StartTime`)).format("HH:mm:ss");

    let endDate = moment(getValues(`EndDate`)).format("YYYY-MM-DD");
    let endTime = moment(getValues(`EndTime`)).format("HH:mm:ss");

    const isOverlap = allschedules.some((item) => {
      // Convert existing schedule's date and time values
      let existingStartDate = moment(item.StartDate).format("YYYY-MM-DD");
      let existingStartTime = moment(item.StartTime).format("HH:mm:ss");
      let existingEndDate = moment(item.EndDate).format("YYYY-MM-DD");
      let existingEndTime = moment(item.EndTime).format("HH:mm:ss");

      // Check if the new schedule overlaps with the existing one
      const isStartEqual =
        existingStartDate === startDate && existingStartTime === startTime;
      const isEndEqual =
        existingEndDate === endDate && existingEndTime === endTime;

      return isStartEqual && isEndEqual;
    });

    setValue(`IsScheduled`, true);

    const dataRecord = {
      ...values,
    };

    if (
      getValues(`StartDate`) === null ||
      getValues(`EndDate`) === null ||
      getValues(`StartTime`) === null ||
      getValues(`EndTime`) === null
    ) {
      toast.error("Enter values in all Fields!!");
    } else if (
      moment(getValues(`StartDate`)).startOf(`day`) >
      moment(getValues(`EndDate`)).startOf(`day`)
    ) {
      toast.error("Event End Date can not be set before Start Date!!");
    } else if (startDate === endDate && startTime === endTime) {
      toast.error("Starttiem and end time cannot be same!");
    } else if (isOverlap) {
      toast.error("This event time schedule already exist!");
    } else {
      setLoading(true);
      if (isEdit) {
        await putSchedule(token, scheduleId, {
          ...dataRecord,
          UpdatedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
        })
          .then((response) => {
            if (response?.STATUS_CODE === "200") {
              // getJob();
              toast.success("Schedule Updated Successfully!");
              detailsApi();

              setOpen(false);
              reset();
              setIsSubmit(false);
              // getSchedule();
              setLoading(false);
            } else {
              toast.error("Failed to Update Schedule!");
              setLoading(false);
              setOpen(true);
              setIsSubmit(false);
            }
          })
          .catch((e) => {
            toast.error("Failed to Update Schedule!");
            setLoading(false);
            setOpen(true);
            setIsSubmit(false);
          });
      } else {
        await postSchedule(token, {
          ...dataRecord,
          CreatedDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
        })
          .then((response) => {
            if (response?.STATUS_CODE === "200") {
              // getJob();
              toast.success("Schedule Created Successfully!");
              detailsApi();
              setLoading(false);
              setOpen(false);
              reset();
              setIsSubmit(false);
              // getSchedule();
            } else {
              toast.error("Failed to Create Schedule!");
              setLoading(false);
              setOpen(true);
              setIsSubmit(false);
            }
          })
          .catch((e) => {
            toast.error("Failed to Create Schedule!");
            setLoading(false);
            setOpen(true);
            setIsSubmit(false);
          });
      }
    }
  };

  const onFakeSubmit = () => {
    setIsSubmit(true);
  };

  return (
    <React.Fragment>
      <DialogContainer
        handleClose={handleClose}
        open={open}
        title={t(`headings.addNewSchedule`)}
        editTitle={t(`headings.reschedule`)}
        loading={fetchLoading}
        Loader={<Loader />}
        isEdit={isEdit}
        btnTitle={t(`buttons.update`)}
        // isEdit={false}
        submitLoading={loading}
        handleSubmit={handleSubmit}
        onSubmit={!isSecondDialog ? onSubmit : onFakeSubmit}
      >
        <DialogContent dividers>
          <Grid container key={index} item md={12} xs={12} spacing={2}>
            <Grid container item md={12} mt={1}>
              <TextField
                id="standard-basic"
                // label="Event Name"
                name={`Title`}
                {...register(`Title`)}
                placeholder={t(`labels.eventName`)}
                type="text"
                variant="standard"
                InputProps={{
                  style: {
                    color: theme.palette.primary.contrastText,
                    fontWeight: theme.typography.fontWeightMedium,
                  },
                }}
                error={!!errors.Title}
                helperText={errors?.Title?.message}
              />
            </Grid>
            <Grid container item spacing={2} md={12}>
              <Grid item xs={6} md={6}>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="h6"
                  fontWeight={theme.typography.fontWeightMedium}
                >
                  {t("textFieldLables.startDate")}
                </Typography>
                <DateTimePickerTheme>
                  <MobileDatePicker
                    label={t(`labels.selectDate`)}
                    inputFormat={dateFormat}
                    onChange={(e) => {
                      if (!e) {
                        toast.info(t(`toasts.startDateCannotBeEmpty`));
                      } else {
                        setValue(
                          `StartDate`,
                          moment(e).format("YYYY-MM-DDTHH:mm")
                        );
                        if (
                          moment(getValues(`StartDate`)).format("YYYY-MM-DD") >
                          moment(getValues(`EndDate`)).format("YYYY-MM-DD")
                        ) {
                          clearErrors(`StartDate`);
                        }
                      }
                    }}
                    value={getValues(`StartDate`)}
                    componentsProps={{
                      actionBar: {
                        actions: ["clear", "cancel", "accept"],
                      },
                    }}
                    minDate={moment(getValues(`CreatedDate`))}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        size="small"
                        {...params}
                        name={`StartDate`}
                        // {...register(`StartDate`)}
                        variant={"outlined"}
                        error={!!errors.StartDate}
                        helperText={errors.StartDate?.message}
                        InputProps={{
                          sx: {
                            color: theme.palette.primary.contrastText,
                            fontWeight: theme.typography.fontWeightMedium,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <CalendarMonthIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </DateTimePickerTheme>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="h6"
                  fontWeight={theme.typography.fontWeightMedium}
                >
                  {t("textFieldLables.endDate")}
                </Typography>
                <DateTimePickerTheme>
                  <MobileDatePicker
                    label={t(`labels.selectDate`)}
                    inputFormat={dateFormat}
                    minDate={moment(getValues(`StartDate`))}
                    onChange={(e) => {
                      if (!e) {
                        toast.info(t(`toasts.endDateCannotBeEmpty`));
                      } else {
                        setValue(
                          `EndDate`,
                          moment(e).format("YYYY-MM-DDTHH:mm")
                        );
                        if (
                          moment(getValues(`StartDate`)).format("YYYY-MM-DD") >
                          moment(getValues(`EndDate`)).format("YYYY-MM-DD")
                        ) {
                          clearErrors(`StartDate`);
                          clearErrors(`EndDate`);
                        }
                      }
                    }}
                    value={getValues(`EndDate`)}
                    componentsProps={{
                      actionBar: {
                        actions: ["clear", "cancel", "accept"],
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        size="small"
                        {...params}
                        name={`EndDate`}
                        // {...register(`EndDate`)}
                        variant={"outlined"}
                        error={!!errors.EndDate}
                        helperText={errors.EndDate?.message}
                        InputProps={{
                          sx: {
                            color: theme.palette.primary.contrastText,
                            fontWeight: theme.typography.fontWeightMedium,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <CalendarMonthIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </DateTimePickerTheme>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="h6"
                  fontWeight={theme.typography.fontWeightMedium}
                >
                  {t("textFieldLables.startTime")}
                </Typography>
                <DateTimePickerTheme>
                  <MobileTimePicker
                    label={t(`labels.selectTime`)}
                    inputFormat={timeFormat}
                    onChange={(e) => {
                      const startDate = moment(getValues(`StartDate`));
                      const endDate = moment(getValues(`EndDate`));
                      const endTime = !!getValues(`EndTime`)
                        ? moment(getValues(`EndTime`))
                        : moment();
                      const startTime = moment(e).format("YYYY-MM-DDTHH:mm");

                      const sameDate = startDate.isSame(endDate, "day");

                      const startTimeBeforeEndTime = moment(
                        startTime
                      ).isSameOrBefore(endTime, "hour");
                      if (!e) {
                        toast.info(t(`toasts.startDateCannotBeEmpty`));
                      } else if (sameDate && !startTimeBeforeEndTime) {
                        toast.info(t(`toasts.startTimeCannotBeAfterEndTime`));
                      } else {
                        setValue(
                          `StartTime`,
                          moment(e).format("YYYY-MM-DDTHH:mm")
                        );
                      }
                    }}
                    ampm={false}
                    value={getValues(`StartTime`)}
                    disabled={index != 0 && getValues("sameRange")}
                    componentsProps={{
                      actionBar: {
                        actions: ["clear", "cancel", "accept"],
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        size="small"
                        {...params}
                        name={`StartTime`}
                        // label="Enter Time"
                        variant={"outlined"}
                        error={!!errors.StartTime}
                        helperText={errors.StartTime?.message}
                        InputProps={{
                          sx: {
                            color: theme.palette.primary.contrastText,
                            fontWeight: theme.typography.fontWeightMedium,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <ScheduleIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </DateTimePickerTheme>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="h6"
                  fontWeight={theme.typography.fontWeightMedium}
                >
                  {t("textFieldLables.endTime")}
                </Typography>
                <DateTimePickerTheme>
                  <MobileTimePicker
                    label={t(`labels.selectTime`)}
                    inputFormat={timeFormat}
                    onChange={(e) => {
                      const startDate = moment(getValues(`StartDate`));
                      const endDate = moment(getValues(`EndDate`));
                      const startTime = moment(getValues(`StartTime`));
                      const endTime = moment(e).format("YYYY-MM-DDTHH:mm");

                      const sameDate = startDate.isSame(endDate, "day");
                      const startTimeBeforeEndTime = startTime.isSameOrBefore(
                        endTime,
                        "hour"
                      );
                      const sameTime = startTime.isSame(endTime, "hour");

                      if (!e) {
                        toast.info(t(`toasts.endDateCannotBeEmpty`));
                      } else if (sameDate && !startTimeBeforeEndTime) {
                        toast.info(t(`toasts.endTimeCannotBeBeforeStartTime`));
                      } else if (sameDate && sameTime) {
                        toast.info(t(`toasts.scheduleTimesCannotBeSame`));
                      } else {
                        setValue(
                          `EndTime`,
                          moment(e).format("YYYY-MM-DDTHH:mm")
                        );
                      }
                    }}
                    ampm={false}
                    value={getValues(`EndTime`)}
                    disabled={index != 0 && getValues("sameRange")}
                    componentsProps={{
                      actionBar: {
                        actions: ["clear", "cancel", "accept"],
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        size="small"
                        {...params}
                        name={`EndTime`}
                        // label="Enter Time"
                        variant={"outlined"}
                        error={!!errors.EndTime}
                        helperText={errors.EndTime?.message}
                        InputProps={{
                          sx: {
                            color: theme.palette.primary.contrastText,
                            fontWeight: theme.typography.fontWeightMedium,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <ScheduleIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </DateTimePickerTheme>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </DialogContainer>
      {isSecondDialog && isSumbit && (
        <DialogContainer
          handleClose={handleClose}
          open={isSecondDialog && isSumbit}
          title={t(`headings.addNewSchedule`)}
          editTitle={t(`headings.rescheduleConfirmation`)}
          loading={loading}
          isEdit={true}
          btnTitle={t(`buttons.confirm`)}
          handleSubmit={handleSubmit}
          onSubmit={isSecondDialog ? onSubmit : null}
        >
          <DialogContent dividers>
            <Grid container key={index} item md={12} xs={12} spacing={2}>
              <Grid container item md={12} mt={1}>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="h6"
                  fontWeight={theme.typography.fontWeightMedium}
                >
                  {t(`messages.rescheduleConfirmationMessage`)}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </DialogContainer>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  // loadServices: fetchServices,
};

export default connect(null, mapDispatchToProps)(EditSchedule);
