export function ViewTaxCalculator({
  lineItems,
  serviceItems,
  productItems,
  packageItems,
  services,
  products,
  taxes,
  getValues,
  details,
}) {
  let taxTotal = 0;
  let taxDetails = [];

  getValues(lineItems)?.length > 0 &&
    getValues(lineItems)?.map((item, index) => {
      let lineItemTaxObj = details?.[index]?.LineItemTaxDetail || [];
      getValues(lineItems)[index].LineItemTaxDetail = [];
      if (!item.IsDelete) {
        if (item?.ServiceId > 0) {
          const serviceObj = services?.find(
            (obj) => parseInt(obj.ServiceId) === parseInt(item.ServiceId)
          );

          const TaxIds = serviceObj?.TaxIds?.split(",")?.map((object) =>
            parseInt(object)
          );

          TaxIds?.map((ob) => {
            const isTaxIncluded =
              lineItemTaxObj?.some(
                (includeObj) => parseInt(includeObj.TaxId) === parseInt(ob)
              ) || false;

            const taxObj = taxes?.find(
              (o) => parseInt(o.TaxId) === parseInt(ob) && isTaxIncluded
            );

            if (
              taxObj &&
              typeof taxObj === "object" &&
              Object.keys(taxObj)?.length > 0
            ) {
              let foundLITaxObj = lineItemTaxObj?.find(
                (tax) => tax.TaxId === taxObj?.TaxId
              );

              const injectLITaxObj = {
                LineItemTaxId: foundLITaxObj?.LineItemTaxId || 0,
                TaxId: taxObj?.TaxId || 0,
                TaxName: taxObj?.TaxName || "",
                TaxDescription: taxObj?.TaxDescription || "",
                TaxRate: taxObj?.TaxRate || 0,
              };

              getValues(lineItems)[index].LineItemTaxDetail.push(
                injectLITaxObj
              );

              const injectTaxObj = {
                TaxId: taxObj?.TaxId || 0,
                TaxName: taxObj?.TaxName || "",
                TaxDescription: taxObj?.TaxDescription || "",
                TaxRate: taxObj?.TaxRate || 0,
                ItemId: serviceObj?.ServiceId || 0,
                ItemName: item?.Title || "",
                itemPrice: parseFloat(item?.Total || 0),
                TaxPrice: parseFloat(
                  (parseFloat(item?.Total || 0) / 100) *
                    parseFloat(taxObj?.TaxRate || 0)
                ),
              };

              taxDetails.push(injectTaxObj);

              taxTotal += parseFloat(
                (parseFloat(item?.Total || 0) / 100) *
                  parseFloat(taxObj?.TaxRate || 0)
              );
            }
          });
        } else if (item?.ProductId > 0) {
          const productObj = products?.find(
            (obj) => parseInt(obj.ProductId) === parseInt(item.ProductId)
          );

          const TaxIds = productObj?.TaxIds?.split(",")?.map((object) =>
            parseInt(object)
          );

          TaxIds?.map((ob) => {
            const isTaxIncluded =
              lineItemTaxObj?.some(
                (includeObj) => parseInt(includeObj.TaxId) === parseInt(ob)
              ) || false;
            const taxObj = taxes?.find(
              (o) => parseInt(o.TaxId) === parseInt(ob) && isTaxIncluded
            );

            if (
              taxObj &&
              typeof taxObj === "object" &&
              Object.keys(taxObj)?.length > 0
            ) {
              let foundLITaxObj = lineItemTaxObj?.find(
                (tax) => tax.TaxId === taxObj?.TaxId
              );

              const injectLITaxObj = {
                LineItemTaxId: foundLITaxObj?.LineItemTaxId || 0,
                TaxId: taxObj?.TaxId || 0,
                TaxName: taxObj?.TaxName || "",
                TaxDescription: taxObj?.TaxDescription || "",
                TaxRate: taxObj?.TaxRate || 0,
              };

              getValues(lineItems)[index].LineItemTaxDetail.push(
                injectLITaxObj
              );

              const injectTaxObj = {
                TaxId: taxObj?.TaxId || 0,
                TaxName: taxObj?.TaxName || "",
                TaxDescription: taxObj?.TaxDescription || "",
                TaxRate: taxObj?.TaxRate || 0,
                ItemId: productObj?.ProductId || 0,
                ItemName: item?.Title || "",
                itemPrice: parseFloat(item?.Total || 0),
                TaxPrice: parseFloat(
                  (parseFloat(item?.Total || 0) / 100) *
                    parseFloat(taxObj?.TaxRate || 0)
                ),
              };

              taxDetails.push(injectTaxObj);

              taxTotal += parseFloat(
                (parseFloat(item?.Total || 0) / 100) *
                  parseFloat(taxObj?.TaxRate || 0)
              );
            }
          });
        } else if (item?.PackageId > 0) {
          item?.LineItemPackageDetail?.map((itemIn) => {
            let packageObj = {};
            let TaxIds = [];

            if (itemIn.ServiceId > 0) {
              packageObj = services?.find(
                (obj) => parseInt(obj.ServiceId) === parseInt(itemIn.ServiceId)
              );
            } else {
              packageObj = products?.find(
                (obj) => parseInt(obj.ProductId) === parseInt(itemIn.ProductId)
              );
            }

            TaxIds = packageObj?.TaxIds?.split(",")?.map((object) =>
              parseInt(object)
            );

            TaxIds?.map((ob) => {
              const isTaxIncluded =
                lineItemTaxObj?.some(
                  (includeObj) => parseInt(includeObj.TaxId) === parseInt(ob)
                ) || false;

              const taxObj = taxes?.find(
                (o) => parseInt(o.TaxId) === parseInt(ob) && isTaxIncluded
              );

              if (
                taxObj &&
                typeof taxObj === "object" &&
                Object.keys(taxObj)?.length > 0
              ) {
                let foundLITaxObj = lineItemTaxObj?.find(
                  (tax) => tax.TaxId === taxObj?.TaxId
                );

                const injectLITaxObj = {
                  LineItemTaxId: foundLITaxObj?.LineItemTaxId || 0,
                  TaxId: taxObj?.TaxId || 0,
                  TaxName: taxObj?.TaxName || "",
                  TaxDescription: taxObj?.TaxDescription || "",
                  TaxRate: taxObj?.TaxRate || 0,
                };
                const taxDetailExists = getValues(lineItems)[
                  index
                ].LineItemTaxDetail?.some(
                  (taxDetail) => taxDetail?.TaxId === taxObj?.TaxId
                );

                if (!taxDetailExists) {
                  getValues(lineItems)[index].LineItemTaxDetail.push(
                    injectLITaxObj
                  );
                }

                const injectTaxObj = {
                  TaxId: taxObj?.TaxId || 0,
                  TaxName: taxObj?.TaxName || "",
                  TaxDescription: taxObj?.TaxDescription || "",
                  TaxRate: taxObj?.TaxRate || 0,
                  ItemId: item?.PackageId || 0,
                  ItemName: item?.Title || "",
                  itemPrice: parseFloat(itemIn?.Total || 0),
                  TaxPrice: parseFloat(
                    (parseFloat(itemIn?.Total || 0) / 100) *
                      parseFloat(taxObj?.TaxRate || 0)
                  ),
                };
                taxDetails.push(injectTaxObj);
                taxTotal += parseFloat(
                  (parseFloat(itemIn?.Total || 0) / 100) *
                    parseFloat(taxObj?.TaxRate || 0)
                );
              }
            });
          });
        }
      }
    });

  getValues(serviceItems)?.length > 0 &&
    getValues(serviceItems)?.map((item, index) => {
      getValues(serviceItems)[index].LineItemTaxDetail = [];

      if (!item.IsDelete) {
        const serviceObj = services?.find(
          (obj) => parseInt(obj.ServiceId) === parseInt(item.ServiceId)
        );

        const TaxIds = serviceObj?.TaxIds?.split(",")?.map((object) =>
          parseInt(object)
        );

        TaxIds?.map((ob) => {
          const taxObj = taxes?.find(
            (o) => parseInt(o.TaxId) === parseInt(ob) && o.IsActive
          );

          if (
            taxObj &&
            typeof taxObj === "object" &&
            Object.keys(taxObj)?.length > 0
          ) {
            const injectLITaxObj = {
              TaxId: taxObj?.TaxId || 0,
              TaxName: taxObj?.TaxName || "",
              TaxDescription: taxObj?.TaxDescription || "",
              TaxRate: taxObj?.TaxRate || 0,
            };

            getValues(serviceItems)[index].LineItemTaxDetail.push(
              injectLITaxObj
            );

            const injectTaxObj = {
              TaxId: taxObj?.TaxId || 0,
              TaxName: taxObj?.TaxName || "",
              TaxDescription: taxObj?.TaxDescription || "",
              TaxRate: taxObj?.TaxRate || 0,
              ItemId: serviceObj?.ServiceId || 0,
              ItemName: serviceObj?.ServiceName || "",
              itemPrice: parseFloat(item?.Total || 0),
              TaxPrice: parseFloat(
                (parseFloat(item?.Total || 0) / 100) *
                  parseFloat(taxObj?.TaxRate || 0)
              ),
            };

            taxDetails.push(injectTaxObj);

            taxTotal += parseFloat(
              (parseFloat(item?.Total || 0) / 100) *
                parseFloat(taxObj?.TaxRate || 0)
            );
          }
        });
      }
    });

  getValues(productItems)?.length > 0 &&
    getValues(productItems)?.map((item, index) => {
      getValues(productItems)[index].LineItemTaxDetail = [];
      if (!item.IsDelete) {
        const productObj = products?.find(
          (obj) => parseInt(obj.ProductId) === parseInt(item.ProductId)
        );

        const TaxIds = productObj?.TaxIds?.split(",")?.map((object) =>
          parseInt(object)
        );

        TaxIds?.map((ob) => {
          const taxObj = taxes?.find(
            (o) => parseInt(o.TaxId) === parseInt(ob) && o.IsActive
          );

          if (
            taxObj &&
            typeof taxObj === "object" &&
            Object.keys(taxObj)?.length > 0
          ) {
            const injectLITaxObj = {
              TaxId: taxObj?.TaxId || 0,
              TaxName: taxObj?.TaxName || "",
              TaxDescription: taxObj?.TaxDescription || "",
              TaxRate: taxObj?.TaxRate || 0,
            };

            getValues(productItems)[index].LineItemTaxDetail.push(
              injectLITaxObj
            );

            const injectTaxObj = {
              TaxId: taxObj?.TaxId || 0,
              TaxName: taxObj?.TaxName || "",
              TaxDescription: taxObj?.TaxDescription || "",
              TaxRate: taxObj?.TaxRate || 0,
              ItemId: productObj?.ProductId || 0,
              ItemName: productObj?.Name || "",
              itemPrice: parseFloat(item?.Total || 0),
              TaxPrice: parseFloat(
                (parseFloat(item?.Total || 0) / 100) *
                  parseFloat(taxObj?.TaxRate || 0)
              ),
            };

            taxDetails.push(injectTaxObj);

            taxTotal += parseFloat(
              (parseFloat(item?.Total || 0) / 100) *
                parseFloat(taxObj?.TaxRate || 0)
            );
          }
        });
      }
    });

  getValues(packageItems)?.length > 0 &&
    getValues(packageItems)?.map((item, index) => {
      getValues(packageItems)[index].LineItemTaxDetail = [];
      if (!item.IsDelete) {
        item?.LineItemPackageDetail?.map((itemIn) => {
          let packageObj = {};
          let TaxIds = [];

          if (itemIn.ServiceId > 0) {
            packageObj = services?.find(
              (obj) => parseInt(obj.ServiceId) === parseInt(itemIn.ServiceId)
            );
          } else {
            packageObj = products?.find(
              (obj) => parseInt(obj.ProductId) === parseInt(itemIn.ProductId)
            );
          }

          TaxIds = packageObj?.TaxIds?.split(",")?.map((object) =>
            parseInt(object)
          );

          TaxIds?.map((ob) => {
            const taxObj = taxes?.find(
              (o) => parseInt(o.TaxId) === parseInt(ob) && o.IsActive
            );

            if (
              taxObj &&
              typeof taxObj === "object" &&
              Object.keys(taxObj)?.length > 0
            ) {
              const injectLITaxObj = {
                TaxId: taxObj?.TaxId || 0,
                TaxName: taxObj?.TaxName || "",
                TaxDescription: taxObj?.TaxDescription || "",
                TaxRate: taxObj?.TaxRate || 0,
              };

              const taxDetailExists = getValues(packageItems)[
                index
              ].LineItemTaxDetail?.some(
                (taxDetail) => taxDetail?.TaxId === taxObj?.TaxId
              );

              if (!taxDetailExists) {
                getValues(packageItems)[index].LineItemTaxDetail.push(
                  injectLITaxObj
                );
              }

              const injectTaxObj = {
                TaxId: taxObj?.TaxId || 0,
                TaxName: taxObj?.TaxName || "",
                TaxDescription: taxObj?.TaxDescription || "",
                TaxRate: taxObj?.TaxRate || 0,
                ItemId: item?.PackageId || 0,
                ItemName: item?.Title || "",
                itemPrice: parseFloat(itemIn?.Total || 0),
                TaxPrice: parseFloat(
                  (parseFloat(itemIn?.Total || 0) / 100) *
                    parseFloat(taxObj?.TaxRate || 0)
                ),
              };

              taxDetails.push(injectTaxObj);

              taxTotal += parseFloat(
                (parseFloat(itemIn?.Total || 0) / 100) *
                  parseFloat(taxObj?.TaxRate || 0)
              );
            }
          });
        });
      }
    });

  return {
    taxTotal: taxTotal,
    taxDetails: taxDetails || [],
  };
}
